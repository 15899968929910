import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  subModuleLinks = [
    { title: 'Facilities', fragment: 'facilities' },
    { title: 'Users', fragment: 'users' },
    // { title: 'Concepts', fragment: 'concepts' },
    { title: 'NHSRC Seals', fragment: 'seal' },
    { title: 'Announcements', fragment: 'announcements' },
    // { title: 'Import', fragment: 'import' }
    { title: 'Feedback', fragment: 'feedback' }
  ];

  opened: boolean = true;

  constructor(public route: ActivatedRoute) {
  
  }

  ngOnInit(): void {
  
  }

}