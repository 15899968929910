<div class="container">
    <div class="card">
        <div class="card-header">
            {{isAddMode ? 'New' : 'Edit'}} Machine
        </div>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="type">Type</label>
                            <select class="form-control" formControlName="type">                      
                                <option value="ASSR">ASSR</option>
                                <option value="ABR">ABR</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="brand">Brand</label>
                            <input type="text" formControlName="brand" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="serialNumber">Serial Number</label>
                            <input type="text" formControlName="serialNumber" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="form-group col-4">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{isAddMode ? 'Add' : 'Save'}} Record
                    </button>
                    <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                </div>
            </form>
        </div>
    </div>
</div>