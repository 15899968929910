import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ExplantedDeviceService } from '../explanted-device.service';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { first } from 'rxjs/operators';
import { PrintService } from '@app/shared/print/print.service';
import { PatientService } from '@app/patient/patient.service';

@Component({ template: '' })
export class AddEditExplantedDeviceComponent implements OnInit {
        
    constructor(public dialog: MatDialog,
      private location: Location,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute
  ) { }

  ngOnInit() {
      this.openDialog();
  }

  openDialog(): void {
      const dialogRef = this.dialog.open(AddEditExplantedDeviceComponentDialog, {
          data: {
              formBuilder: this.formBuilder,
              id: this.route.snapshot.params['id']
          }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.location.back();
      });
  }

}

@Component({ templateUrl: 'add-edit-explanted-device.component.html' })
export class AddEditExplantedDeviceComponentDialog implements OnInit {
    
    form: FormGroup;
    loading = false;
    submitted = false;

    editable: boolean;
    editing: boolean;
    printable: boolean;

    isAddMode: any;

    id: string;
    patientId: string;
    modelId: string;
    versionId: string;

    //surgerySelect = '0';
    
    constructor(        
        public dialogRef: MatDialogRef<AddEditExplantedDeviceComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder,
            id: string
        },
        public dialog: MatDialog,
        private explantedDeviceService: ExplantedDeviceService, 
        private formBuilder: FormBuilder, 
        private alertService: AlertService,
        public printService: PrintService,
        private patientService: PatientService
    ) { 
        this.id = data.id;
    }

    ngOnInit() {
        this.patientId = this.patientService.recordSubject.getValue()["modelId"];

        this.isAddMode = !this.id;
    
        this.editable = this.id? true:false;
        this.editing = false;
        this.printable = true;
    
        this.form = this.formBuilder.group({
          patient: [''],
          explantedDevice: [''],
          explantedDeviceSpecify: [''],
          typeAndSerialNumber: [''],
          dateOfFirstImplantation: ['', Validators.required],
          reasonForExplantationInfection: [''],
          reasonForExplantationDeviceFailure: [''],
          reasonForExplantationOthers: [''],
          deviceFailure: [''],
          othersSpecify: ['']
        });
    
        
        if (!this.isAddMode) {
          var model = this.explantedDeviceService.recordsValue["results"].find(x => x.modelId == this.id);
          this.versionId = model["versionId"];
          this.modelId = model["modelId"];
          this.form.patchValue(Utils.mapModelToForm(model, ['dateOfFirstImplantation']));
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() { 
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createExplantedDevice();
        } else {
            this.updateExplantedDevice();
        }
    }

    createExplantedDevice() {
        this.form.get("patient").setValue(this.patientId)
        this.explantedDeviceService.add(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Explanted Device added successfully', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      
    updateExplantedDevice() {
        this.explantedDeviceService.update(this.form.value, this.modelId, this.versionId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}
