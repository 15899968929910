import { animate, animateChild, group, query, stagger, style, transition, trigger } from '@angular/animations';

export const sealAnimations =
  trigger('sealAnimations', [
    transition('* => *', [
      query(':enter', 
        [
          style({ opacity: 0 })
        ], 
        { optional: true }
      ),
      query(':leave', 
        [
          animateChild(), 
          style({ opacity: 1 }),
          animate(150, style({ opacity: 0 }))
        ], 
        { optional: true }
      ),
      query(':enter', 
        [
          style({ opacity: 0 }),
          animate(150, style({ opacity: 1 })),
          animateChild()
        ], 
        { optional: true }
      )
    ])
  ]);