import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class SexOfPatientsWhoUnderwentCochlearImplantReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Hearing Loss'
    })
    hearingLoss: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Male'
    })
    male: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Male %'
    })
    malePercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Female'
    })
    female: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Female %'
    })
    femalePercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Total'
    })
    total: string;
}
