<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Complications Outside Surgery
                <button type="button" (click)="this.printService.print(['details', print], 'Complications Outside Surgery')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group col 4">
                            <label for="complications">Complications</label>
                            <div>
                                <mat-radio-group formControlName="complications">
                                    <mat-radio-button #yes class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button [value]="'No'">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="yes.checked" class="form-row">
                        <div class="form-group col-6">
                            <label for="complicationsSpecify">Specify</label>
                            <div class="form-group col-8">
                                <input type="text" formControlName="complicationsSpecify" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="yes.checked" class="form-row">
                        <mat-checkbox formControlName="hospitalizedOrOPD" [ngClass]="{ 'is-invalid': submitted && f.privacy.errors }">
                            <label class="form-check-label" for="hospitalizedOrOPD">
                                Hospitalized or managed in OPD
                            </label>
                        </mat-checkbox>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 px-3">
                            <label for="notes">Notes</label>
                            <textarea class="form-control" rows="3" formControlName="notes"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>