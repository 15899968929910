import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecordHistoryService {

  private historySubject: BehaviorSubject<any>;
  private history: Observable<any>;

  constructor(
    public http: HttpClient,
    private userService: UserService) {
    this.historySubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('recordsHistory')));
    this.history = this.historySubject.asObservable();
  }

  public get historyValue() {
    return this.historySubject.value;
  }

  public get historyObservable() {
    return this.history;
  }

  getChanges(id, modelName) {
    return this.http.get(`${environment.apiUrl}/sync/pull/${modelName}/${id}`)
      .pipe(
        mergeMap(res => {
          var updates = res['fieldUpdates'];
          var forks = updates.map(x => this.userService.getById(x.userId));
          return forkJoin(forks).pipe(
            map(forkres => {
              forkres.forEach((e,i) => {
                res['fieldUpdates'][i]['fullName'] = e['firstName'] + " " + e['lastName'];
              });
              this.historySubject.next(res);
              localStorage.setItem('recordsHistory', JSON.stringify(res));
              return res;
            })
          );
        })
      )
  }
}
