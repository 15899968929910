import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import { debounceTime, first, map, startWith, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { PrintService } from '@app/shared/print/print.service';
import { UserService } from '@app/user/user.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { SealsService } from '@app/admin/seal/seals.service';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit {
  @Input() patient: any;

  form: FormGroup;
  loading = false;
  submitted = false;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  isAdd: boolean
  isAddMode: any;

  id: string;
  modelId: string;
  versionId: string;

  utils = Utils;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  sNumber = new FormControl();
  filteredSNumbers: Observable<string[]>;
  sNumbers: string[] = []
  allSNumbers$: Observable<any>;
  //facilityControl = new FormControl();
  sNumberOptions$: Observable<string[]>;

  @ViewChild('sNumberInput') sNumberInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private patientService: PatientService,
    public printService: PrintService,
    private userService: UserService,
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute,
    private location: Location,
    private alertService: AlertService,
    private ennhsrService: EnnhsrService,
    private sealsService: SealsService
  ) { }

  // print(e) {
  //   console.log("PRINT FUNCTION");
  //   Utils.print(e);
  // }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];

    this.isAdd = !this.id;
    this.isAddMode = !this.id;

    this.editable = this.id? true:false;
    this.editing = false;
    this.printable = true;

    this.form = this.formBuilder.group({
      patientNumber: [''],
      patientType: [''],
      status: [''],
      reason: [''],
      serialNumber: [''],
      facilityCode: [''],
      patientExpired: [''],
      hearingScreeningCenter: [''],
      hSC: [''],
      firstName: [''],
      middleName: [''],
      lastName: [''],
      sex: ['', Validators.required],
      philhealth: [''],
      philhealthPin: [''],
      philhealthSponsor: [''],
      philhealthExpiration: [''],
      dateOfBirth: ['', Validators.required],
      timeOfBirth: [''],
      birthOutcome: [''],
      multipleGestation: [''],
      birthOrder: [''],
      birthWeight: ['', Validators.required],
      gestationAge: [''],
      gestationNotes: [''],
      facilityOfBirth: [''],
      motherFirstName: ['', Validators.required],
      motherMiddleName: ['', Validators.required],
      motherLastName: ['', Validators.required],
      streetAddress: [''],
      psgcCode:[''],
      barangay: [''],
      cityMunicipality: [''],
      province: [''],
      phoneNumber: [''],
      initScreenDate: ['']
    }, { validators: groupValidator });
    /* this.allSNumbers$ = this.sealsService.recordsObservable.pipe(
      map(seals => seals["results"].filter(seal => seal["dataModel"]["patient"] === null ))
    ); */
    if (!this.isAddMode) {
      this.patientService.record.subscribe(patient => {
        this.versionId = patient["versionId"];
        this.modelId = patient["modelId"];
        this.form.patchValue(Utils.mapModelToForm(patient, ['dateOfBirth', 'philhealthExpiration', 'initScreenDate']));
      });
    }

    this.form.get('facilityCode').setValue(this.userService.userValue['facility']['facility'] + ' (' + this.userService.userValue['facility']['facilityCode'] + ')');

    this.sNumberOptions$ = this.form.controls['serialNumber'].valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value !== '') {
          return this.search(value);
        }
      })
    );
  }  
  
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    if (this.isAddMode) {
        this.createPatient();
    } else {
        this.updatePatient();
    }
  }

  createPatient() {
    this.patientService.add(this.form.value)
    .pipe(first())
    .subscribe({
        next: () => {
            this.alertService.success('Patient added successfully', { keepAfterRouteChange: true });
            this.location.back();
        },
        error: error => {
            this.alertService.error(error);
            console.log(error)
            this.loading = false;
        }
    });
  }

  updatePatient() {
    this.patientService.update(this.form.value, this.modelId, this.versionId)
    .pipe(first())
    .subscribe({
        next: () => {
            this.alertService.success('Update successful', { keepAfterRouteChange: true });
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;
        }
    });
  }

  cancelEdit() {
    if(confirm("Unsaved changes will be lost. Proceed?")){
      this.ngOnInit();
      this.editing = false;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.form.controls['serialNumber'].setValue(event.option.viewValue);
  }

  search(name): Observable<string[]> {
    return this.ennhsrService.sNum(name).pipe(
    map(sNumbers => sNumbers["results"]))
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category A') || user['facility']['roles'].includes('Category B') || user['facility']['roles'].includes('Category C') || user['facility']['roles'].includes('Category D')) 
      && (this.isAdd ? true : this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']));
  }

  phoneKeyDown(e){
		const keyCode = e.keyCode;  
		if (( (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode !=8) {
			e.preventDefault();
		} 	  
}
}

export const groupValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const dateOfBirth = control.get('dateOfBirth');
  const initScreenDate = control.get('initScreenDate');
  // console.log("QWEQWE",(new Date(initScreenDate.value).getTime() - new Date(dateOfBirth.value).getTime()) / (1000 * 3600 * 24) <= 30)
  return (new Date(initScreenDate.value).getTime() - new Date(dateOfBirth.value).getTime()) / (1000 * 3600 * 24) > 30 ? { invalidScreening: true } : null;
}
