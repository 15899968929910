import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AcousticReflexTestService } from './acoustic-reflex-test.service';
import { TympanometryService } from './tympanometry.service';

@Component({
  selector: 'app-confirmatory-other-tests',
  templateUrl: './confirmatory-other-tests.component.html',
  styleUrls: ['./confirmatory-other-tests.component.scss']
})
export class ConfirmatoryOtherTestsComponent implements OnInit {

  columnsTympanometry = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of Test'},
    {name: 'Name of Tester'},
    {name: 'ECV Right', prop:'ecvRight'},
    {name: 'Compliance Right', prop:'complianceRight'},
    {name: 'Peak Right', prop:'peakRight'},
    {name: 'Type Right'},
    {name: 'ECV Left', prop:'ecvLeft'},
    {name: 'Compliance Left', prop:'complianceLeft'},
    {name: 'Peak Left', prop:'peakLeft'},
    {name: 'Type Left'},
    {name: 'Notes'},
    {name: 'Upload File'}
  ]
  
  columnsART = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of Test'},
    {name: 'Name of Tester'},
    {name: 'Ipsi Left'},
    {name: 'Contra Left'},
    {name: 'Ipsi Right'},
    {name: 'Contra Right'},
    {name: 'Notes'},
    {name: 'Upload File'}
  ]

  dateControlNames = ['dateOfTest'];
  tympanometry$ : Observable<any>;
  tympanometryPage$ : Observable<any>;
  acousticReflexTest$ : Observable<any>;
  acousticReflexTestPage$ : Observable<any>;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;

  id: string;

  constructor(
    private tympanometryService: TympanometryService,
    private acousticReflexTestService: AcousticReflexTestService,
    private route: ActivatedRoute,
    private userService: UserService,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.editable = true;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )

    this.tympanometryService.getAll(this.id, 0).subscribe();
    
    this.tympanometry$ = this.tympanometryService.recordsObservable.pipe(
      map(tympanometry => tympanometry["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.tympanometryPage$ = this.tympanometryService.recordsObservable

    this.acousticReflexTestService.getAll(this.id, 0).subscribe();

    this.acousticReflexTest$ = this.acousticReflexTestService.recordsObservable.pipe(
      map(acousticReflexTest => acousticReflexTest["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.acousticReflexTestPage$ = this.acousticReflexTestService.recordsObservable
  }

  getPageTympanometry(page: string) {
    this.tympanometryService.getAll(this.id, page).subscribe();
  }

  getPageAcousticReflexTest(page: string) {
    this.acousticReflexTestService.getAll(this.id, page).subscribe();
  }

  deleteTympanometry(id: string) {
    this.tympanometryService.delete(id).pipe(first())
        .subscribe();
  }

  deleteAcousticReflexTest(id: string) {
    this.acousticReflexTestService.delete(id).pipe(first())
        .subscribe();
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category B') || user['facility']['roles'].includes('Category C') || user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }
}
