import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/shared/alert/alert.service';
import { UsersService } from '@app/admin/users/users.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {

  form: FormGroup;  
  loading = false;  
  submitted = false;

  constructor(private location: Location,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required]
    });
  }

  get f() { return this.form.controls; }

  back() {
    this.location.back();
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    this.createProfile();
  }
  private createProfile() {
    //this.constructFacilityAndRoles();
    var user = this.form.value
    user["firstName"] = "First Name";
    user["lastName"] = "Last Name";
    user["facilityAndRoles"] = [{
      "facility" : "NHSRC",
      "category" : "A",
      "facilityCode" : "12345",
      "facilityModelId" : "a2788a40-3298-4c85-bca5-66910c4a3386",
      "psgcCode": "133900000",
      "roles" : ["Category A"]
    }]

    this.usersService.register(user)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Profile added successfully', { keepAfterRouteChange: true });
                this.back();
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
}
}
