
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SealDistributionService } from './seal-distribution.service';



@Injectable({
  providedIn: 'root'
})
export class SealSummaryService {
  private modelName = "sealSummary";
  public sealSummarySubject: BehaviorSubject<any>;
  public sealSummary: Observable<any>;

  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService,
    private sealDistributionService: SealDistributionService
  ) {
    this.sealSummarySubject = new BehaviorSubject<any>(null);
    this.sealSummary = this.sealSummarySubject.asObservable();
   }

  public get recordsValue() {
    return this.sealSummarySubject.value;
  }
  public get recordsObservable() {
    return this.sealSummary;
  }

  getAll(){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}`)
    .pipe(
      map(res => {
        this.sealSummarySubject.next(res);
        return res;
      })
    );   
  }

  getByFacility(facility?: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?${facility ? "facility="+facility:""}&~resolveRef=1`)
    .pipe(
      map(res => {
        this.sealSummarySubject.next(res);
        return res;
      })
    );   
  }

  add(item: any){
    var guid = Utils.generateGuid()
    item = Utils.generateMeta(item)
    const user = this.userService.userValue;
    var sync = Utils.convertToSync(item, this.modelName, guid, null, user.id);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
  }

  update(item: any, modelId: string, versionId: string,original:any) {
    var original = original;
    item["meta"] = original["dataModel"]["meta"];
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = Date.now()
    const user = this.userService.userValue;
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        return res;
      })
    );
  } 
}
