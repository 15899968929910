import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/shared/alert/alert.service';
import { ProgressBarService } from '@app/shared/progress-bar/progress-bar.service';
import Utils from '@app/shared/utils';
import { FieldUpdate } from '@app/shared/_models/field-update';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private modelName = "sync";
  public sync: Observable<Sync>;
  public syncSubject: BehaviorSubject<any>;
  
  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService,
    private progressBarService: ProgressBarService,
    private alertService: AlertService
  ) {
    this.syncSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(this.modelName+'Record')));
    this.sync = this.syncSubject.asObservable();
  }

  public get recordValue() {
    return this.syncSubject.value;
  }

  public get recordObservable() {
    return this.sync;
  }

  getByFacility(facility: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}?facility=${facility}`)
    .pipe(
      map(res => {
        localStorage.setItem(this.modelName+'Record', JSON.stringify(res));
        this.syncSubject.next(res);
        return res;
      })
    );
  }

  getByUser(user: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}?user=${user}`)
    .pipe(
      map(res => {
        localStorage.setItem(this.modelName+'Record', JSON.stringify(res));
        this.syncSubject.next(res);
        return res;
      })
    );
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}`);
  }

  //GIT STATUS
  status(date? : Date, latest? : boolean){
    return this.http.get(`${environment.apiUrl}/${this.modelName}/get${latest ? `?~latestOnly=${latest}` : ""}${date ? `&~since=${date}` : ""}`)
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  //GIT PULL
  pull(date? : Date, latest? : boolean, progress?: BehaviorSubject<number>){
    //console.log("GIT PULL")
    let facilityCode = this.userService.userValue['facility']['facilityCode'];
    let models = ["abrTest",
    "acousticReflexTest",
    "assrTest",
    "complicationsOutsideSurgery",
    "educationalPlacement",
    "explantedDevice",
    "facility",
    "feedback",
    "firstFitting",
    "hearingAid",
    "hearingScreening",
    "intraOpTelemetry",
    "machine",
    "patient",
    "pedsScreening",
    "seal",
    "sealDistribution",
    "sessions",
    "speechPathologySession",
    "speechTherapy",
    "status",
    "surgicalHearingIntervention",
    "switchOn",
    "tympanometry"];
    //return this.http.get(`${environment.originUrl}/${this.modelName}/get${latest ? `?~latestOnly=${latest}` : ""}${date ? `&~since=${date}` : ""}`)
    var since = date ? new Date(parseInt(date.toString())) : new Date("04/01/2022");
    var now = new Date();
    let out = {currentModelIds: {}, fieldUpdates: []}

    //get new seal distributions, hide facilityCode for "new" sealDistribution, change dateCreated and dateUpdated of "new" sealDistribution
    return this.http.get<any>(`${environment.originUrl}/dashboard/models/getAll/sealDistribution?&meta.facilityCode=${facilityCode}&~since=${since.getTime()}`).pipe(switchMap(sealDistributionRes => {
      console.log("sealDistributionRes",sealDistributionRes)
      const sealDistributions = JSON.parse(JSON.stringify(sealDistributionRes))
      //insert seal distributions
      let insertSealDistribution = sealDistributions['count'] > 0 ? this.executeBatchInsert(sealDistributionRes['results'], this.userService.userValue, this.progressBarService) : of(null)
      return insertSealDistribution.pipe(switchMap(insertSealDistributionRes => {
        console.log("insertSealDistributionRes",insertSealDistributionRes)
        //get seals from new seal distribution
        let newSeals = insertSealDistributionRes ? forkJoin(sealDistributions['results'].map(e => this.http.get(`${environment.originUrl}/dashboard/models/getAll/seal?sealDistribution=${e.modelId}`))) : of(null)
        return newSeals.pipe(switchMap(newSealsRes => {
          console.log("newSealsRes",newSealsRes)
          //insert seals
          let insertSeals = newSealsRes?.reduce((sum,res) => res['count']+sum,0) > 0 ? this.executeBatchInsert(newSealsRes.reduce((all:[],res) => all.concat(res['results']),[]), this.userService.userValue, this.progressBarService, insertSealDistributionRes.insertedModelIds) : of(null)
          return insertSeals.pipe(switchMap(insertSealsRes => {
            console.log("insertSealsRes",insertSealsRes)
            let exemptSealDistribution = insertSealsRes ? sealDistributions['results'].map(e => e.modelId) : null
            console.log("exemptSealDistribution",exemptSealDistribution)
            //pull other updates
            return forkJoin(models.map(e => this.pullRecurssion(e, since, now, facilityCode, 0, {currentModelIds: {}, fieldUpdates: []}, progress, ((e == 'seal' || e == 'sealDistribution')? exemptSealDistribution : null))))
            .pipe(
              switchMap(allRes => {              
                allRes.forEach((res, j) => {
                  var resFieldUpdates = res['fieldUpdates']
                  resFieldUpdates.forEach((e,i) => {
                    var idx = resFieldUpdates.findIndex(f => f.modelId == e.modelId && f.fieldPath == e.fieldPath)
                    if(idx != i)
                      resFieldUpdates.splice(idx,1)
                  });
                  if(res['currentModelIds'][models[j]]) {
                    out.currentModelIds[models[j]] = {}
                    for(let [modelId, versionId] of Object.entries(res['currentModelIds'][models[j]]))
                      out.currentModelIds[models[j]][modelId] = versionId
                  }
                  if(resFieldUpdates.length > 0)
                    out.fieldUpdates = out.fieldUpdates.concat(resFieldUpdates)
                })
                //if no fieldUpdates and new sealDistribution exists, update sync
                let outOp = of(out);
                if(out.fieldUpdates.length == 0 && sealDistributions['count'] > 0) {
                  this.alertService.success('New seal data synced from Facility');
                  outOp = this.getByFacility(facilityCode).pipe(switchMap(latestSync => {
                    return this.update({}, latestSync["modelId"] , latestSync["versionId"] ).pipe(map(() => out))
                  }))
                }
                return outOp;
              })
            );
          }))
        }))
      }))
    }))
  }

  pullRecurssion(modelName: string, since: Date, now: Date, facilityCode: string, page, pullRes, progress: BehaviorSubject<number>, exemptSealDistribution?) {
    let startDate = new Date(since); 
    let endDate = new Date(since);
    endDate.setDate(endDate.getDate() + 1);
    var pullOp = this.http.get(`${environment.originUrl}/sync/get/${modelName}?~latestOnly=1&~since=${startDate.getTime()}&~until=${endDate.getTime()}&~page=${page}&meta.facilityCode=${facilityCode}`)
    if(exemptSealDistribution){
      if(modelName == 'seal')
        pullOp = this.http.get(`${environment.originUrl}/sync/get/${modelName}?~latestOnly=1&~since=${startDate.getTime()}&~until=${endDate.getTime()}&~page=${page}&meta.facilityCode=${facilityCode}&sealDistribution=!/${exemptSealDistribution.join('%7C')}/`)
      if(modelName == 'sealDistribution')
        pullOp = this.http.get(`${environment.originUrl}/sync/get/${modelName}?~latestOnly=1&~since=${startDate.getTime()}&~until=${endDate.getTime()}&~page=${page}&meta.facilityCode=${facilityCode}&$modelId=!/${exemptSealDistribution.join('%7C')}/`)
    }      
    return pullOp
    .pipe(
      concatMap(res => {
        var nextPage = page + 1;
        var nextDate = startDate;
        if(res['fieldUpdates'].length == 0) {
          if(startDate.getTime() > now.getTime()){
            progress.next(progress.value + (100/24))
            return of(pullRes);
          }
          nextDate = endDate;
          nextPage = 0;
        }
        for(var [key,value] of Object.entries(res['currentModelIds'])){ 
          if(!pullRes.currentModelIds[key])
            pullRes.currentModelIds[key] = {}
          for(var [modelId,versionId] of Object.entries(value))
            pullRes.currentModelIds[key][modelId] = versionId;
        }
        pullRes['fieldUpdates'] = pullRes['fieldUpdates'].concat(res['fieldUpdates'])
        return this.pullRecurssion(modelName, nextDate, now, facilityCode, nextPage, pullRes, progress, exemptSealDistribution)
      })
    ); 
  }

  initialSync() {
    let facilityCode = this.userService.userValue['facility']['facilityCode'];
    let models = [
      "patient",
      "sealDistribution",
      "abrTest",
      "acousticReflexTest",
      "assrTest",
      "complicationsOutsideSurgery",
      "educationalPlacement",
      "explantedDevice",
      "facility",
      "feedback",
      "firstFitting",
      "hearingAid",
      "hearingScreening",
      "intraOpTelemetry",
      "machine",
      "pedsScreening",
      "seal",
      "sessions",
      "speechPathologySession",
      "speechTherapy",
      "status",
      "surgicalHearingIntervention",
      "switchOn",
      "tympanometry"
    ];
    return forkJoin(models.map(e => this.http.get(`${environment.originUrl}/dashboard/models/getAll/${e}?&meta.facilityCode=${facilityCode}`).pipe(tap(() => { this.progressBarService.incrementProgress(0.5/models.length*100) })))).pipe(switchMap(allRes => {
      let totalCount = allRes.reduce((sum,res) => res['count'] + sum,0)
      if(totalCount > 0) {
        this.progressBarService.setIncrement(0.5/totalCount*100)
        if((allRes[0]['count'] + allRes[1]['count'] > 0) && totalCount > allRes[0]['count'] + allRes[1]['count']) {
          return this.executeBatchInsert((allRes[0]['results']).concat(allRes[1]['results']), this.userService.userValue, this.progressBarService).pipe(switchMap(initialRes => {
            return this.executeBatchInsert(allRes.slice(2).reduce((all:[],res) => all.concat(res['results']),[]), this.userService.userValue, this.progressBarService, initialRes['insertedModelIds']).pipe(switchMap(followupRes => {
              return of(followupRes)
            }))
          }))
        }
        return this.executeBatchInsert(allRes.reduce((all:[],res) => all.concat(res['results']),[]), this.userService.userValue, this.progressBarService).pipe(map(res => {
          return res
        }))
      }
      this.progressBarService.incrementProgress(50);
      return of(null);
    }))
  }

  executeBatchInsert(models, user, progress, insertedModelIds?){
    let insertSync = models.map(model => {
        model['sync'] = Utils.convertToSync(model.dataModel, model.modelName, model.modelId, null, user.id);
        return model
      }).reduce((allSyncs, model) => {
      if(!allSyncs.newModelIds)
        allSyncs.newModelIds = {};
      if(!allSyncs.newModelIds[model.modelName])
        allSyncs.newModelIds[model.modelName] = []
      allSyncs.newModelIds[model.modelName].push(model.modelId);
      if(!allSyncs.fieldUpdates)
        allSyncs.fieldUpdates = [];
      
      let originUpdatedByName = new FieldUpdate();
      originUpdatedByName.fieldPath = "originUpdatedByName";
      originUpdatedByName.value = model.updatedByName || model.createdByName;
      originUpdatedByName.modelName = model.modelName;
      originUpdatedByName.modelId = model.modelId;
      originUpdatedByName.dateUpdated = model.dateUpdated || model.dateCreated;
      originUpdatedByName.userId = model.updatedBy || model.createdBy;
      originUpdatedByName.dateSynced = model.dateUpdated || model.dateCreated;
      allSyncs.fieldUpdates.push(originUpdatedByName)  
      allSyncs.fieldUpdates = allSyncs.fieldUpdates.concat(model.sync.fieldUpdates.map(fieldUpdate => {
        if(insertedModelIds?.[fieldUpdate.fieldPath]?.[fieldUpdate.value])
          fieldUpdate.value = insertedModelIds[fieldUpdate.fieldPath][fieldUpdate.value]['assignedId']
        return fieldUpdate
      }));
      return allSyncs;
    }, new Sync())
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, insertSync).pipe(switchMap(insertRes => {
      console.log("insertRes",insertRes)
      let idMappingSync = new Sync();
      idMappingSync.newModelIds = { idMapping: [] }
      for(let model of models) {
        const modelName = model.modelName
        const originId = model.modelId
        const originVer = model.versionId
        var guid = Utils.generateGuid()
        idMappingSync.newModelIds.idMapping.push(guid)
        
        let field = new FieldUpdate();
        field.fieldPath = "modelName";
        field.value = modelName;
        field.modelName = "idMapping";
        field.modelId = guid;
        field.dateUpdated = new Date();
        field.userId = user.id;
        field.dateSynced = new Date();
        idMappingSync.fieldUpdates.push(field)
  
        let field2 = new FieldUpdate();
        field2.fieldPath = "localId";
        field2.value = insertRes['insertedModelIds'][modelName][originId]['assignedId'];
        field2.modelName = "idMapping";
        field2.modelId = guid;
        field2.dateUpdated = new Date();
        field2.userId = user.id;
        field2.dateSynced = new Date();
        idMappingSync.fieldUpdates.push(field2)
        
        let field3 = new FieldUpdate();
        field3.fieldPath = "originId";
        field3.value = originId;
        field3.modelName = "idMapping";
        field3.modelId = guid;
        field3.dateUpdated = new Date();
        field3.userId = user.id;
        field3.dateSynced = new Date();
        idMappingSync.fieldUpdates.push(field3);
  
        let field4 = new FieldUpdate();
        field4.fieldPath = "originVer";
        field4.value = originVer;
        field4.modelName = "idMapping";
        field4.modelId = guid;
        field4.dateUpdated = new Date();
        field4.userId = user.id;
        field4.dateSynced = new Date();
        idMappingSync.fieldUpdates.push(field4);
  
        let field5 = new FieldUpdate();
        field5.fieldPath = "localVer";
        field5.value = insertRes['insertedModelIds'][modelName][originId]['versionId'];
        field5.modelName = "idMapping";
        field5.modelId = guid;
        field5.dateUpdated = new Date();
        field5.userId = user.id;
        field5.dateSynced = new Date();
        idMappingSync.fieldUpdates.push(field5);

        progress.incrementProgress();
      }
      return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, idMappingSync).pipe(map(idMappingRes => {
        console.log("idMappingRes",idMappingRes)
        return insertRes
      }))
    }))
    
  }

  //GIT MERGE
  merge(sync, originIdMappings, userIdNames){
    for(let placeholderIds of Object.values(sync.newModelIds)){
      for(let placeholderId of placeholderIds as []) {
        let field = sync.fieldUpdates.find(e => e.modelId == placeholderId);
        let originUpdatedByName = new FieldUpdate();
        originUpdatedByName.fieldPath = "originUpdatedByName";
        originUpdatedByName.value = userIdNames.find(e => e.id == field.userId).name;
        originUpdatedByName.modelName = field.modelName;
        originUpdatedByName.modelId = field.modelId;
        originUpdatedByName.dateUpdated = field.dateUpdated;
        originUpdatedByName.userId = field.userId;
        originUpdatedByName.dateSynced = field.dateSynced;
        sync.fieldUpdates.push(originUpdatedByName);  
      }
    }
    for(let assignedVersionId of Object.values(sync.syncTokens)){
      for(let assignedId of Object.keys(assignedVersionId)) {
        let field = sync.fieldUpdates.find(e => e.modelId == assignedId);
        let originUpdatedByName = new FieldUpdate();
        originUpdatedByName.fieldPath = "originUpdatedByName";
        originUpdatedByName.value = userIdNames.find(e => e.id == field.userId).name;
        originUpdatedByName.modelName = field.modelName;
        originUpdatedByName.modelId = field.modelId;
        originUpdatedByName.dateUpdated = field.dateUpdated;
        originUpdatedByName.userId = field.userId;
        originUpdatedByName.dateSynced = field.dateSynced;
        sync.fieldUpdates.push(originUpdatedByName); 
      }
    }
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
      .pipe(
        switchMap(res => {
          console.log("merge res:", res)
          var syncs = []
          const user = this.userService.userValue;
          var insertedModelIds = res["insertedModelIds"];          
          var updatedModelVersionIds = res["updatedModelVersionIds"];
          if(insertedModelIds != null){
            for (let [modelName, placeholderIds] of Object.entries(insertedModelIds)){
              for (let [placeholderId, assignedIdVersionId] of Object.entries(placeholderIds)){
                var sync = new Sync();
                var guid = Utils.generateGuid()
                sync.newModelIds = { idMapping: [ guid ] }
                
                let field = new FieldUpdate();
                field.fieldPath = "modelName";
                field.value = modelName;
                field.modelName = "idMapping";
                field.modelId = guid;
                field.dateUpdated = new Date();
                field.userId = user.id;
                field.dateSynced = new Date();
                sync.fieldUpdates.push(field)

                let field2 = new FieldUpdate();
                field2.fieldPath = "localId";
                field2.value = assignedIdVersionId["assignedId"];
                field2.modelName = "idMapping";
                field2.modelId = guid;
                field2.dateUpdated = new Date();
                field2.userId = user.id;
                field2.dateSynced = new Date();
                sync.fieldUpdates.push(field2)
                
                let field3 = new FieldUpdate();
                field3.fieldPath = "originId";
                field3.value = placeholderId;
                field3.modelName = "idMapping";
                field3.modelId = guid;
                field3.dateUpdated = new Date();
                field3.userId = user.id;
                field3.dateSynced = new Date();
                sync.fieldUpdates.push(field3);

                let field4 = new FieldUpdate();
                field4.fieldPath = "originVer";
                field4.value = originIdMappings.find(e => e.originId == placeholderId).originVer;
                field4.modelName = "idMapping";
                field4.modelId = guid;
                field4.dateUpdated = new Date();
                field4.userId = user.id;
                field4.dateSynced = new Date();
                sync.fieldUpdates.push(field4);

                let field5 = new FieldUpdate();
                field5.fieldPath = "localVer";
                field5.value = assignedIdVersionId["versionId"];
                field5.modelName = "idMapping";
                field5.modelId = guid;
                field5.dateUpdated = new Date();
                field5.userId = user.id;
                field5.dateSynced = new Date();
                sync.fieldUpdates.push(field5);
                
                syncs.push(sync);
              }
            }
          }
          if(updatedModelVersionIds != null){
            for (let [modelName, assignedIdVersionIds] of Object.entries(updatedModelVersionIds)){
              for (let [assignedId, versionId] of Object.entries(assignedIdVersionIds)){
                var sync = new Sync();
                var guid = Utils.generateGuid()
                sync.newModelIds = { idMapping: [ guid ] }
                
                let field = new FieldUpdate();
                field.fieldPath = "modelName";
                field.value = modelName;
                field.modelName = "idMapping";
                field.modelId = guid;
                field.dateUpdated = new Date();
                field.userId = user.id;
                field.dateSynced = new Date();
                sync.fieldUpdates.push(field)

                let field2 = new FieldUpdate();
                field2.fieldPath = "localId";
                field2.value = assignedId;
                field2.modelName = "idMapping";
                field2.modelId = guid;
                field2.dateUpdated = new Date();
                field2.userId = user.id;
                field2.dateSynced = new Date();
                sync.fieldUpdates.push(field2)
                
                let field3 = new FieldUpdate();
                field3.fieldPath = "originId";
                field3.value = originIdMappings.find(e => e.localId == assignedId).originId;
                field3.modelName = "idMapping";
                field3.modelId = guid;
                field3.dateUpdated = new Date();
                field3.userId = user.id;
                field3.dateSynced = new Date();
                sync.fieldUpdates.push(field3)

                let field4 = new FieldUpdate();
                field4.fieldPath = "originVer";
                field4.value = originIdMappings.find(e => e.localId == assignedId).originVer;
                field4.modelName = "idMapping";
                field4.modelId = guid;
                field4.dateUpdated = new Date();
                field4.userId = user.id;
                field4.dateSynced = new Date();
                sync.fieldUpdates.push(field4);

                let field5 = new FieldUpdate();
                field5.fieldPath = "localVer";
                field5.value = versionId;
                field5.modelName = "idMapping";
                field5.modelId = guid;
                field5.dateUpdated = new Date();
                field5.userId = user.id;
                field5.dateSynced = new Date();
                sync.fieldUpdates.push(field5);
                
                syncs.push(sync);
              }
            }
          }
          return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
          // return forkJoin(syncs.map(sync => Utils.execSqliteInsert(this.dbService, sync, this.userService.userValue)))
        })
      )
  }
  //GIT PUSH
  push(sync, localIdMappings){
    sync.fieldUpdates = sync.fieldUpdates.filter(e => e.fieldPath != "originUpdatedByName");
    return this.http.post<Sync>(`${environment.originUrl}/sync/push`, sync)
      .pipe(
        switchMap(res => {
          console.log("push res:", res)
          var syncs = []
          const user = this.userService.userValue;
          var insertedModelIds = res["insertedModelIds"];
          var updatedModelVersionIds = res["updatedModelVersionIds"];
          if(insertedModelIds != null){
            for (let [modelName, placeholderIds] of Object.entries(insertedModelIds)){
              for (let [placeholderId, assignedIdVersionId] of Object.entries(placeholderIds)){
                var sync = new Sync();
                var guid = Utils.generateGuid()
                sync.newModelIds = { idMapping: [ guid ] }
                
                let field = new FieldUpdate();
                field.fieldPath = "modelName";
                field.value = modelName;
                field.modelName = "idMapping";
                field.modelId = guid;
                field.dateUpdated = new Date();
                field.userId = user.id;
                field.dateSynced = new Date();
                sync.fieldUpdates.push(field)

                let field2 = new FieldUpdate();
                field2.fieldPath = "localId";
                field2.value = placeholderId;
                field2.modelName = "idMapping";
                field2.modelId = guid;
                field2.dateUpdated = new Date();
                field2.userId = user.id;
                field2.dateSynced = new Date();
                sync.fieldUpdates.push(field2)
                
                let field3 = new FieldUpdate();
                field3.fieldPath = "originId";
                field3.value = assignedIdVersionId["assignedId"];
                field3.modelName = "idMapping";
                field3.modelId = guid;
                field3.dateUpdated = new Date();
                field3.userId = user.id;
                field3.dateSynced = new Date();
                sync.fieldUpdates.push(field3)

                let field4 = new FieldUpdate();
                field4.fieldPath = "originVer";
                field4.value = assignedIdVersionId["versionId"];
                field4.modelName = "idMapping";
                field4.modelId = guid;
                field4.dateUpdated = new Date();
                field4.userId = user.id;
                field4.dateSynced = new Date();
                sync.fieldUpdates.push(field4);

                let field5 = new FieldUpdate();
                field5.fieldPath = "localVer";
                field5.value = localIdMappings.find(e => e.localId == placeholderId).localVer;
                field5.modelName = "idMapping";
                field5.modelId = guid;
                field5.dateUpdated = new Date();
                field5.userId = user.id;
                field5.dateSynced = new Date();
                sync.fieldUpdates.push(field5);
                
                syncs.push(sync);
              }
            }
          }
          if(updatedModelVersionIds != null){
            for (let [modelName, assignedIdVersionIds] of Object.entries(updatedModelVersionIds)){
              for (let [assignedId, versionId] of Object.entries(assignedIdVersionIds)){
                var sync = new Sync();
                var guid = Utils.generateGuid()
                sync.newModelIds = { idMapping: [ guid ] }
                
                let field = new FieldUpdate();
                field.fieldPath = "modelName";
                field.value = modelName;
                field.modelName = "idMapping";
                field.modelId = guid;
                field.dateUpdated = new Date();
                field.userId = user.id;
                field.dateSynced = new Date();
                sync.fieldUpdates.push(field)

                let field2 = new FieldUpdate();
                field2.fieldPath = "localId";
                field2.value = localIdMappings.find(e => e.originId == assignedId).localId;
                field2.modelName = "idMapping";
                field2.modelId = guid;
                field2.dateUpdated = new Date();
                field2.userId = user.id;
                field2.dateSynced = new Date();
                sync.fieldUpdates.push(field2)
                
                let field3 = new FieldUpdate();
                field3.fieldPath = "originId";
                field3.value = assignedId;
                field3.modelName = "idMapping";
                field3.modelId = guid;
                field3.dateUpdated = new Date();
                field3.userId = user.id;
                field3.dateSynced = new Date();
                sync.fieldUpdates.push(field3)

                let field4 = new FieldUpdate();
                field4.fieldPath = "originVer";
                field4.value = versionId;
                field4.modelName = "idMapping";
                field4.modelId = guid;
                field4.dateUpdated = new Date();
                field4.userId = user.id;
                field4.dateSynced = new Date();
                sync.fieldUpdates.push(field4);
                
                let field5 = new FieldUpdate();
                field5.fieldPath = "localVer";
                field5.value = localIdMappings.find(e => e.originId == assignedId).localVer;
                field5.modelName = "idMapping";
                field5.modelId = guid;
                field5.dateUpdated = new Date();
                field5.userId = user.id;
                field5.dateSynced = new Date();
                sync.fieldUpdates.push(field5);

                syncs.push(sync);
              }
            }
          }
          return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
          // return forkJoin(syncs.map(sync => Utils.execSqliteInsert(this.dbService, sync, this.userService.userValue)))
        })
      )
  }

  add(){
    var guid = Utils.generateGuid();
    var item = {};
    const user = this.userService.userValue;
    item["facility"] = user['facility'].facilityCode;
    item["dateSync"] = Date.now()+5000;
    var sync = Utils.convertToSync(item, this.modelName, guid, null, user.id);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      switchMap(res => {
        return this.getById(res['insertedModelIds'][this.modelName][guid]['assignedId'])
        .pipe(
          map(newItem => {
            localStorage.setItem(this.modelName+'Record', JSON.stringify(newItem));
            this.syncSubject.next(newItem);
            return res;
          })
        );
      })
    );
  }

  update(item: any, modelId: string, versionId: string) {
    var original = this.syncSubject.value;
    const user = this.userService.userValue;
    item["facility"] = user['facility'].facilityCode;
    item["dateSync"] = Date.now()+5000;
    item["meta"] = original["dataModel"]["meta"];
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        localStorage.setItem(this.modelName+'Record', JSON.stringify(res));
        this.syncSubject.next(res);
        return res;
      })
    );
  }
}
