import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserService } from '@app/user/user.service';
import { FeedbackService } from '@app/admin/feedback/feedback.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private userService: UserService, private feedbackService: FeedbackService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
        this.feedbackService.pushError(err.message)
        if ([401, 403].includes(err.status) && this.userService.userValue) {
            // auto logout if 401 or 403 response returned from api
            this.userService.logout();
        }

        const error = err.error?.message || err.statusText;
        console.error(err);
        return throwError(error);
    }))
}
}
