<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Session
                <button type="button" (click)="this.printService.print(['details', print], 'Cochlear Implant Programming Session')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >            
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="audiologist">Audiologist</label>
                            <input type="text" class="form-control" formControlName="audiologist"/>
                        </div>
                        <div class="form-group col-4">
                            <label for="hospital">Hospital</label>
                            <input type="text" class="form-control" formControlName="hospital"/>
                        </div>
                        <div class="form-group col-4">
                            <label for="date">Date</label>
                            <div>
                                <input matInput [matDatepicker]="picker" formControlName="date" [ngClass]="{ 'is-invalid': submitted && f.date.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                    <div *ngIf="f.date.errors.required">Date is required</div>
                                </div>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="status">Status</label>
                            <div>
                                <mat-radio-group formControlName="status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                    <mat-radio-button class="mr-3" [value]="'done'">Done</mat-radio-button>
                                    <mat-radio-button #statusNotDone [value]="'not done'">Not Done</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                    <div *ngIf="f.status.errors.required">Status is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <!-- <div *ngIf="statusDone.checked" class="form-group col-4">
                            <label for="dateDone">Date Done</label>
                            <div>
                                <input matInput [matDatepicker]="picker2" formControlName="dateDone" [ngClass]="{ 'is-invalid': submitted && f.dateDone.errors }" />
                                <div *ngIf="submitted && f.dateDone.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateDone.errors.required">Date is required</div>
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </div>
                        </div>   -->                      
                        <div *ngIf="statusNotDone.checked" class="form-group col-4">
                            <label for="reason">Reason</label>
                            <input type="text" class="form-control" formControlName="reason"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="capScore">CAP Score</label>
                            <input type="text" formControlName="capScore" class="form-control"/>
                        </div>
                        <div class="form-group col-4">
                            <label for="peachScore">PEACH Score</label>
                            <input type="text" formControlName="peachScore" class="form-control"/>
                        </div>
                    </div>
                    <label>Electrode Status</label>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <mat-checkbox formControlName="leftEar">
                                <label class="form-check-label" for="leftEar">
                                    Left Ear
                                </label>
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-6">
                            <mat-checkbox formControlName="rightEar">
                                <label class="form-check-label" for="rightEar">
                                    Right Ear
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-group"> 
                        <div class="form-row">
                            <div class="form-group col-6">
                                <label for="brandLeft">Brand</label>
                                <div>
                                    <mat-radio-group class="d-flex flex-column" formControlName="brandLeft">
                                        <mat-radio-button class="mr-3" [value]="'Cochlear'">Cochlear</mat-radio-button>
                                        <mat-radio-button [value]="'MED-EL'">MED-EL</mat-radio-button>
                                        <mat-radio-button #brandLeftOthers [value]="'Other'">Other</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        
                            <div class="form-group col-6">
                                <label for="brandRight">Brand</label>
                                <div>
                                    <mat-radio-group class="d-flex flex-column" formControlName="brandRight">
                                        <mat-radio-button class="mr-3" [value]="'Cochlear'">Cochlear</mat-radio-button>
                                        <mat-radio-button [value]="'MED-EL'">MED-EL</mat-radio-button>
                                        <mat-radio-button #brandRightOthers [value]="'Other'">Other</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6">
                                <div *ngIf="brandLeftOthers.checked" class="ml-3">
                                    <label for="brandLeftOthersSpecify">Specify Brand on Left Ear</label>
                                    <input type="text" formControlName="brandLeftOthersSpecify" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <div *ngIf="brandRightOthers.checked" class="ml-3">
                                    <label for="brandRightOthersSpecify">Specify Brand on Right Ear</label>
                                    <input type="text" formControlName="brandRightOthersSpecify" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6">
                                <label for="serialLeft">Serial</label>
                                <input type="text" formControlName="serialLeft" class="form-control" />
                            </div>
                            <div class="form-group col-6">
                                <label for="serialRight">Serial</label>
                                <input type="text" formControlName="serialRight" class="form-control" />
                            </div>
                        </div>
                        <label>Electrodes</label>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft1 class="mr-3" style="width: 3rem" formControlName="elecLeft1">
                                    1
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft1.checked">
                                    <select class="form-control" formControlName="eLeft1">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight1 class="mr-3" style="width: 3rem" formControlName="elecRight1">
                                    1
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight1.checked">
                                    <select class="form-control" formControlName="eRight1">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft2 class="mr-3" style="width: 3rem" formControlName="elecLeft2">
                                    2
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft2.checked">
                                    <select class="form-control" formControlName="eLeft2">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight2 class="mr-3" style="width: 3rem" formControlName="elecRight2">
                                    2
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight2.checked">
                                    <select class="form-control" formControlName="eRight2">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft3 class="mr-3" style="width: 3rem" formControlName="elecLeft3">
                                    3
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft3.checked">
                                    <select class="form-control" formControlName="eLeft3">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight3 class="mr-3" style="width: 3rem" formControlName="elecRight3">
                                    3
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight3.checked">
                                    <select class="form-control" formControlName="eRight3">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft4 class="mr-3" style="width: 3rem" formControlName="elecLeft4">
                                    4
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft4.checked">
                                    <select class="form-control" formControlName="eLeft4">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight4 class="mr-3" style="width: 3rem" formControlName="elecRight4">
                                    4
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight4.checked">
                                    <select class="form-control" formControlName="eRight4">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft5 class="mr-3" style="width: 3rem" formControlName="elecLeft5">
                                    5
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft5.checked">
                                    <select class="form-control" formControlName="eLeft5">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight5 class="mr-3" style="width: 3rem" formControlName="elecRight5">
                                    5
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight5.checked">
                                    <select class="form-control" formControlName="eRight5">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft6 class="mr-3" style="width: 3rem" formControlName="elecLeft6">
                                    6
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft6.checked">
                                    <select class="form-control" formControlName="eLeft6">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight6 class="mr-3" style="width: 3rem" formControlName="elecRight6">
                                    6
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight6.checked">
                                    <select class="form-control" formControlName="eRight6">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft7 class="mr-3" style="width: 3rem" formControlName="elecLeft7">
                                    7
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft7.checked">
                                    <select class="form-control" formControlName="eLeft7">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight7 class="mr-3" style="width: 3rem" formControlName="elecRight7">
                                    7
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight7.checked">
                                    <select class="form-control" formControlName="eRight7">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft8 class="mr-3" style="width: 3rem" formControlName="elecLeft8">
                                    8
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft8.checked">
                                    <select class="form-control" formControlName="eLeft8">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight8 class="mr-3" style="width: 3rem" formControlName="elecRight8">
                                    8
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight8.checked">
                                    <select class="form-control" formControlName="eRight8">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft9 class="mr-3" style="width: 3rem" formControlName="elecLeft9">
                                    9
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft9.checked">
                                    <select class="form-control" formControlName="eLeft9">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight9 class="mr-3" style="width: 3rem" formControlName="elecRight9">
                                    9
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight9.checked">
                                    <select class="form-control" formControlName="eRight9">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft10 class="mr-3" style="width: 3rem" formControlName="elecLeft10">
                                    10
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft10.checked">
                                    <select class="form-control" formControlName="eLeft10">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight10 class="mr-3" style="width: 3rem" formControlName="elecRight10">
                                    10
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight10.checked">
                                    <select class="form-control" formControlName="eRight10">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft11 class="mr-3" style="width: 3rem" formControlName="elecLeft11">
                                    11
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft11.checked">
                                    <select class="form-control" formControlName="eLeft11">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight11 class="mr-3" style="width: 3rem" formControlName="elecRight11">
                                    11
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight11.checked">
                                    <select class="form-control" formControlName="eRight11">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft12 class="mr-3" style="width: 3rem" formControlName="elecLeft12">
                                    12
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft12.checked">
                                    <select class="form-control" formControlName="eLeft12">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight12 class="mr-3" style="width: 3rem" formControlName="elecRight12">
                                    12
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight12.checked">
                                    <select class="form-control" formControlName="eRight12">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft13 class="mr-3" style="width: 3rem" formControlName="elecLeft13">
                                    13
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft13.checked">
                                    <select class="form-control" formControlName="eLeft13">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight13 class="mr-3" style="width: 3rem" formControlName="elecRight13">
                                    13
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight13.checked">
                                    <select class="form-control" formControlName="eRight13">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft14 class="mr-3" style="width: 3rem" formControlName="elecLeft14">
                                    14
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft14.checked">
                                    <select class="form-control" formControlName="eLeft14">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight14 class="mr-3" style="width: 3rem" formControlName="elecRight14">
                                    14
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight14.checked">
                                    <select class="form-control" formControlName="eRight14">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft15 class="mr-3" style="width: 3rem" formControlName="elecLeft15">
                                    15
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft15.checked">
                                    <select class="form-control" formControlName="eLeft15">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight15 class="mr-3" style="width: 3rem" formControlName="elecRight15">
                                    15
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight15.checked">
                                    <select class="form-control" formControlName="eRight15">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft16 class="mr-3" style="width: 3rem" formControlName="elecLeft16">
                                    16
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft16.checked">
                                    <select class="form-control" formControlName="eLeft16">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight16 class="mr-3" style="width: 3rem" formControlName="elecRight16">
                                    16
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight16.checked">
                                    <select class="form-control" formControlName="eRight16">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft17 class="mr-3" style="width: 3rem" formControlName="elecLeft17">
                                    17
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft17.checked">
                                    <select class="form-control" formControlName="eLeft17">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight17 class="mr-3" style="width: 3rem" formControlName="elecRight17">
                                    17
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight17.checked">
                                    <select class="form-control" formControlName="eRight17">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft18 class="mr-3" style="width: 3rem" formControlName="elecLeft18">
                                    18
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft18.checked">
                                    <select class="form-control" formControlName="eLeft18">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight18 class="mr-3" style="width: 3rem" formControlName="elecRight18">
                                    18
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight18.checked">
                                    <select class="form-control" formControlName="eRight18">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft19 class="mr-3" style="width: 3rem" formControlName="elecLeft19">
                                    19
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft19.checked">
                                    <select class="form-control" formControlName="eLeft19">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight19 class="mr-3" style="width: 3rem" formControlName="elecRight19">
                                    19
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight19.checked">
                                    <select class="form-control" formControlName="eRight19">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft20 class="mr-3" style="width: 3rem" formControlName="elecLeft20">
                                    20
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft20.checked">
                                    <select class="form-control" formControlName="eLeft20">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight20 class="mr-3" style="width: 3rem" formControlName="elecRight20">
                                    20
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight20.checked">
                                    <select class="form-control" formControlName="eRight20">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft21 class="mr-3" style="width: 3rem" formControlName="elecLeft21">
                                    21
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft21.checked">
                                    <select class="form-control" formControlName="eLeft21">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight21 class="mr-3" style="width: 3rem" formControlName="elecRight21">
                                    21
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight21.checked">
                                    <select class="form-control" formControlName="eRight21">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft22 class="mr-3" style="width: 3rem" formControlName="elecLeft22">
                                    22
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft22.checked">
                                    <select class="form-control" formControlName="eLeft22">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight22 class="mr-3" style="width: 3rem" formControlName="elecRight22">
                                    22
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight22.checked">
                                    <select class="form-control" formControlName="eRight22">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft23 class="mr-3" style="width: 3rem" formControlName="elecLeft23">
                                    23
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft23.checked">
                                    <select class="form-control" formControlName="eLeft23">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight23 class="mr-3" style="width: 3rem" formControlName="elecRight23">
                                    23
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight23.checked">
                                    <select class="form-control" formControlName="eRight23">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecLeft24 class="mr-3" style="width: 3rem" formControlName="elecLeft24">
                                    24
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecLeft24.checked">
                                    <select class="form-control" formControlName="eLeft24">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="form-group col-6 d-flex align-items-center">
                                <mat-checkbox #elecRight24 class="mr-3" style="width: 3rem" formControlName="elecRight24">
                                    24
                                </mat-checkbox>
                                <fieldset class="w-100" [disabled]="!elecRight24.checked">
                                    <select class="form-control" formControlName="eRight24">
                                        <option>OK</option>
                                        <option>High Impedance</option>
                                        <option>Out of Compliance</option>
                                        <option>Short Circuit</option>
                                        <option>Others</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label for="additionalNotes">Additional Notes</label>
                                <textarea class="form-control" rows="6" formControlName="additionalNotes"></textarea>
                            </div>
                        </div>
                    </div>
                    <label>Aided Testing</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="dateOfTesting">Date of Testing</label>
                            <div>
                                <input matInput [matDatepicker]="picker3" formControlName="dateOfTesting">
                                <mat-datepicker-toggle matSuffix [for]="picker3">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="typeOfAT">Type of Aided Test</label>
                            <div>
                                <mat-radio-group class="d-flex flex-column" formControlName="typeOfAT">
                                    <mat-radio-button class="mr-3" [value]="'BOA'">BOA</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'VROA'">VROA</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'CPA'">CPA</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Conventional Audiometry'">Conventional Audiometry</mat-radio-button>
                                    <mat-radio-button #aidedTypeOthers class="mr-3" [value]="'Others'">Others</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3" *ngIf="aidedTypeOthers.checked">
                        <div class="form-group col-6 ml-3">
                            <label for="specifyType">Specify Type</label>
                            <input type="text" class="form-control" formControlName="specifyType"/>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-12">
                            <label for="testingConditions">Testing Conditions</label>
                            <input type="text" class="form-control" formControlName="testingConditions"/>
                        </div>
                    </div>
                    <label class="ml-3">Aided Thresholds</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <mat-checkbox formControlName="leftEarAT">
                                Left Ear
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <label for="left250Hz">250Hz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="left500Hz">500Hz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="left1kHz">1kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="left15kHz">1.5kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="left2kHz">2kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="left3kHz">3kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="left4kHz">4kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="left6kHz">6kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="left8kHz">8kHz</label>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <label>Threshold</label>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left250Hz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left500Hz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left1kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left15kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left2kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left3kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left4kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left6kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="left8kHz"/>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <label>Reached speech banana</label>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB250Hz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB500Hz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB1kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB15kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB2kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB3kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB4kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB6kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSB8kHz"></mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <label>Reached speech string bean</label>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB250Hz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB500Hz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB1kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB15kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB2kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB3kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB4kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB6kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="leftSSB8kHz"></mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <mat-checkbox formControlName="rightEarAT">
                                Right Ear
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <label for="right250Hz">250Hz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="right500Hz">500Hz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="right1kHz">1kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="right15kHz">1.5kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="right2kHz">2kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="right3kHz">3kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="right4kHz">4kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="right6kHz">6kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label for="right8kHz">8kHz</label>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <label>Threshold</label>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right250Hz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right500Hz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right1kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right15kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right2kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right3kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right4kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right6kHz"/>
                        </div>
                        <div class="form-group col-1">
                            <input type="text" class="form-control" formControlName="right8kHz"/>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <label>Reached speech banana</label>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB250Hz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB500Hz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB1kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB15kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB2kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB3kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB4kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB6kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSB8kHz"></mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <label>Reached speech string bean</label>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB250Hz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB500Hz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB1kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB15kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB2kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB3kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB4kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB6kHz"></mat-checkbox>
                        </div>
                        <div class="form-group col-1">
                            <mat-checkbox formControlName="rightSSB8kHz"></mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="reliability">Reliability</label>
                            <div>
                                <mat-radio-group class="d-flex flex-column" formControlName="reliability">
                                    <mat-radio-button class="mr-3" [value]="'Good'">Good</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Fair'">Fair</mat-radio-button>
                                    <mat-radio-button [value]="'Poor'">Poor</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="fileUpload">Upload</label>
                            <input type="file" formControlName="fileUpload" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="notes">Notes</label>
                            <textarea class="form-control" formControlName="notes" rows="6"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>