import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class FacilityStatusReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Facility Name'
    })
    facilityName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Accreditation Number'
    })
    accreditationNumber: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'List of Partners'
    })
    listOfPartners: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Address'
    // })
    // psgc: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Active Status'
    })
    activeInactiveStatus: string;
}
