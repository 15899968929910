import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SealsService } from '@app/admin/seal/seals.service';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { environment } from '@environments/environment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SealDistributionService {
  private modelName = "sealDistribution";
  public sealDistributionSubject: BehaviorSubject<any>;
  public sealDistribution: Observable<any>;

  constructor(
    public router: Router,
    public http: HttpClient,
    private sealsService: SealsService 
  ) {
    this.sealDistributionSubject = new BehaviorSubject<any>(null);
    this.sealDistribution = this.sealDistributionSubject.asObservable();
   }

  public get recordsValue() {
    return this.sealDistributionSubject.value;
  }
  public get recordsObservable() {
    return this.sealDistribution;
  }

  getAll(facility?: string, status?: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}${facility ? "?facility="+facility : ""}${status ? "&requestStatus="+status : ""}`)
    .pipe(
      mergeMap(res => {
        if(res['count'] == 0){
          return of(res);
        }
        var resForks = res['results'].map(x => {
          var innerFork = [this.sealsService.getAll(x["modelId"])]
           return forkJoin(innerFork)
        })
        return forkJoin(resForks)
        .pipe(
          map(forkRes => {
            res['results'].forEach((e,i) => {
              e.dataModel['seals'] = forkRes[res['results'].indexOf(e)][0]
            });
            // localStorage.setItem(this.modelName+'Records', JSON.stringify(res));
            this.sealDistributionSubject.next(res);
            return res;
          })
        )
      })
      /* map(res => {
        localStorage.setItem(this.modelName+'Records', JSON.stringify(res));
        this.sealDistributionSubject.next(res);
        return res;
      }) */
    );
  }

  getAllCount(facility?: string, status?: string, used?: boolean){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}${facility ? "?facility="+facility : ""}${status ? "&~requestStatus="+status : ""}`)
    .pipe(
      mergeMap(res => {
        if(res['count'] == 0){
          return of(res);
        }
        var resForks = res['results'].map(x => {
          var innerFork = [this.sealsService.getAllCount(x["modelId"], 1, used)]
           return forkJoin(innerFork)
        })
        return forkJoin(resForks)
        .pipe(
          map(forkRes => {
            res['results'].forEach((e,i) => {
              e.dataModel['seals'] = forkRes[res['results'].indexOf(e)][0]
            });
            return res;
          })
        )
      })
    );
  }

  getById(id: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}`)
  }
  add(item: any){
    item = Utils.generateMeta(item)
    var guid = Utils.generateGuid()
    var sync = Utils.convertToSync(item, this.modelName, guid);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      mergeMap(res => {
        return this.getById(res['insertedModelIds'][this.modelName][guid]['assignedId'])
        .pipe(
          map(newItem => {
            var records = this.sealDistributionSubject.value;
            if(records == null){
              records = {
                "count": 0,
                "limit": 20,
                "offset": 0,
                "results": [newItem]
              }
            }
            else
              records['results'].splice(0,0,newItem);
            localStorage.setItem(this.modelName+'Records', JSON.stringify(records));
            this.sealDistributionSubject.next(records);
            return res;
          })
        );
      })
    );
  }

  cancel(modelId: string){
    const original = this.sealDistributionSubject.value['results'].find(x => x.modelId === modelId)
    var item = {
      "requestStatus" : "CANCELED"
    }
    var sync = Utils.getDiff(Utils.convertToSync(this.sealDistributionSubject.value['results'].find(x => x.modelId === modelId)['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName,  original['modelId'], original['versionId']));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.sealDistributionSubject.value;
        var idx = records["results"].findIndex(x => x.modelId === modelId);
        records["results"][idx].dataModel = item;
        records["results"][idx].versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        localStorage.setItem(this.modelName+'Records', JSON.stringify(records));
        this.sealDistributionSubject.next(records);
        return res;
      })
    );
  }
  receive(modelId: string){
    const original = this.sealDistributionSubject.value['results'].find(x => x.modelId === modelId)
    var now = Date.now()
    var item = {
      "requestStatus" : "RECEIVED",
      "dateReceived" : now,
    }
    var sync = Utils.getDiff(Utils.convertToSync(this.sealDistributionSubject.value['results'].find(x => x.modelId === modelId)['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName,  original['modelId'], original['versionId']));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.sealDistributionSubject.value;
        var idx = records["results"].findIndex(x => x.modelId === modelId);
        records["results"][idx].dataModel["requestStatus"] = "RECEIVED";
        records["results"][idx].dataModel["dateReceived"] = now;
        records["results"][idx].versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        // localStorage.setItem(this.modelName+'Records', JSON.stringify(records));
        this.sealDistributionSubject.next(records);
        return res;
      })
    );
  }
}
