import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackRoutingModule } from './feedback-routing.module';
import { AddEditComponent, AddEditComponentDialog } from './add-edit/add-edit.component';
import { ListComponent } from './list/list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatTreeModule } from '@angular/material/tree';


@NgModule({
  declarations: [
    ListComponent,    
    AddEditComponent,
    AddEditComponentDialog
  ],
  imports: [
    CommonModule,
    FeedbackRoutingModule,
    ReactiveFormsModule,
    SharedModule,

    MatTreeModule
  ]
})
export class FeedbackModule { }
