<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Intervention
                <button type="button" (click)="this.printService.print(['details', print], 'Intervention')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="dateOfImplantation">Date of Implantation</label>
                            <div>   
                                <input matInput [matDatepicker]="picker" formControlName="dateOfImplantation" [ngClass]="{ 'is-invalid': submitted && f.dateOfImplantation.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <div *ngIf="submitted && f.dateOfImplantation.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfImplantation.errors.required">Date of Implantation is required</div>
                                </div>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                        <div class="form-group col-8">
                            <label for="hospitalName">Hospital Name</label>
                            <input type="text" formControlName="hospitalName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.hospitalName.errors }" />
                            <div *ngIf="submitted && f.hospitalName.errors" class="invalid-feedback">
                                <div *ngIf="f.hospitalName.errors.required">Hospital Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <!-- <app-address [addressLabel]="'Hospital Address'" [streetControlName]="'hospitalAddress'" [psgcControlName]="'psgcCode'"></app-address> -->
                            <app-address [addressLabel]="'Hospital Address'" [psgcControl]="f.psgcCode" [streetControl]="f.hospitalAddress" [submitted]="submitted"></app-address>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="surgeonsName">Surgeon's Name</label>
                            <input type="text" formControlName="surgeonsName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.surgeonsName.errors }" />
                            <div *ngIf="submitted && f.surgeonsName.errors" class="invalid-feedback">
                                <div *ngIf="f.surgeonsName.errors.required">Surgeon's Name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="clinicalAudiologistsName">Clinical Audiologist's Name</label>
                            <input type="text" formControlName="clinicalAudiologistsName" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="laterality">Laterality</label>
                            <div>
                                <mat-radio-group formControlName="laterality">
                                    <mat-radio-button class="mr-3" [value]="'Right'">Right</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Left'">Left</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="type">Type</label>
                            <input type="text" formControlName="type" class="form-control" />
                        </div>
                        <div class="form-group col-4">
                            <label for="model">Model</label>
                            <input type="text" formControlName="model" class="form-control" />
                        </div>
                        <div class="form-group col-4">
                            <label for="serialNumber">Serial Number</label>
                            <input type="text" formControlName="serialNumber" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Diagnosis</label>
                            <!-- <input type="text" formControlName="diagnosis" class="form-control" /> -->
                            <mat-chip-list #chipList aria-label="Diagnosis selection">
                                <mat-chip
                                  *ngFor="let diagnosis of diagnosisICD"
                                  [selectable]="selectable"
                                  [removable]="removable"
                                  (removed)="remove(diagnosis)">
                                  {{diagnosis}}
                                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input
                                  placeholder="Add new Diagnosis"
                                  #diagnosisInput
                                  [formControl]="diagnosis"
                                  [matAutocomplete]="auto"
                                  [matChipInputFor]="chipList"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                  (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                              <mat-option *ngFor="let diagnosis of diagnosisOptions$ | async" [value]="diagnosis.id">
                                {{diagnosis.icdCode}} - {{diagnosis.description}}
                              </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <div class="form-row">
                        <label >PhilHealth RVS Codes</label>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="firstCaseRate">First Case Rate</label>
                            <input type="text" #rvsFirstInput formControlName="firstCaseRate" class="form-control" [matAutocomplete]="autoRVSFirst" />
                            <mat-autocomplete #autoRVSFirst="matAutocomplete" (optionSelected)="selectedRVSFirst($event)">
                              <mat-option *ngFor="let rvsFirst of rvsFirstOptions$ | async" [value]="rvsFirst.id">
                                ({{rvsFirst.rvsCode}}) PHP {{rvsFirst.caseRate}}.00 - {{rvsFirst.description}}
                              </mat-option>
                            </mat-autocomplete>
                        </div>
                        <div class="form-group col-6">
                            <label for="secondCaseRate">Second Case Rate</label>
                            <input type="text" #rvsSecondInput formControlName="secondCaseRate" class="form-control" [matAutocomplete]="autoRVSSecond" />
                            <mat-autocomplete #autoRVSSecond="matAutocomplete" (optionSelected)="selectedRVSSecond($event)">
                              <mat-option *ngFor="let rvsSecond of rvsSecondOptions$ | async" [value]="rvsSecond.id">
                                ({{rvsSecond.rvsCode}}) PHP {{rvsSecond.caseRate}}.00 - {{rvsSecond.description}}
                              </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="procedure">Procedure</label>
                            <textarea #rvsFirstInput class="form-control" rows="5" formControlName="procedureFirst" [matAutocomplete]="autoRVSFirst"></textarea>
                            <mat-autocomplete #autoRVSFirst="matAutocomplete" (optionSelected)="selectedRVSFirst($event)">
                                <mat-option *ngFor="let rvsFirst of rvsFirstOptions$ | async" [value]="rvsFirst.id">
                                  {{rvsFirst.description}}
                                </mat-option>
                              </mat-autocomplete>
                        </div>
                        <div class="form-group col-6">
                            <label for="procedure">Procedure</label>
                            <textarea #rvsSecondInputP class="form-control" rows="5" formControlName="procedureSecond" [matAutocomplete]="autoRVSSecondP"></textarea>
                            <mat-autocomplete #autoRVSSecondP="matAutocomplete" (optionSelected)="selectedRVSSecond($event)">
                                <mat-option *ngFor="let rvsSecond of rvsSecondOptions$ | async" [value]="rvsSecond.id">
                                  {{rvsSecond.description}}
                                </mat-option>
                              </mat-autocomplete>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <mat-checkbox formControlName="withComplication" #complication class="mr-3">With Complication</mat-checkbox>
                            <div *ngIf="complication.checked">
                                <div class="form-row col-12">
                                    <div class="form-row ml-3">
                                        <label for="timing">Timing</label>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-2 ml-3">
                                            <mat-radio-group formControlName="timing">
                                                <mat-radio-button class="mr-3" #w24 [value]="'within 24 hours'">within 24 hours</mat-radio-button>
                                                <mat-radio-button class="mr-3" #w7 [value]="'within 7 days'">within 7 days</mat-radio-button>
                                                <mat-radio-button class="mr-3" #w1 [value]="'within 1 month'">within 1 month</mat-radio-button>
                                                <mat-radio-button class="mr-3" #w3 [value]="'within 3 months'">within 3 months</mat-radio-button>
                                                <mat-radio-button class="mr-3" #w6 [value]="'within 6 months'">within 6 months</mat-radio-button>
                                                <mat-radio-button class="mr-3" #g6 [value]="'greater than 6 months'">greater than 6 months</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <!-- <div class="form-group col-4">
                                            <div class="form-row" *ngIf="w24.checked">
                                                <label for="w24description">Description</label>
                                                <input type="text" formControlName="w24description" class="form-control"/>
                                            </div>
                                            <div class="form-row" *ngIf="w7.checked">
                                                <label for="w7description">Description</label>
                                                <input type="text" formControlName="w7description" class="form-control"/>
                                            </div>
                                            <div class="form-row" *ngIf="w1.checked">
                                                <label for="w1description">Description</label>
                                                <input type="text" formControlName="w1description" class="form-control"/>
                                            </div>
                                            <div class="form-row" *ngIf="w3.checked">
                                                <label for="w3description">Description</label>
                                                <input type="text" formControlName="w3description" class="form-control"/>
                                            </div>
                                            <div class="form-row" *ngIf="w6.checked">
                                                <label for="w6description">Description</label>
                                                <input type="text" formControlName="w6description" class="form-control"/>
                                            </div>
                                            <div class="form-row" *ngIf="g6.checked">
                                                <label for="g6description">Description</label>
                                                <input type="text" formControlName="g6description" class="form-control"/>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="form-row ml-3">
                                        <label for="description">Description</label>
                                        <input type="text" formControlName="description" class="form-control"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="surgeonsNotes">Surgeon's notes</label>
                            <textarea class="form-control" rows="3" formControlName="surgeonsNotes"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="uploadOrTechnique">Upload OR Technique</label>
                            <input type="file" formControlName="uploadOrTechnique" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>