import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class TotalNoOfScreeningPersonnelReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Region'
    })
    region: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Number of Personnel'
    })
    count: number;
    @autoserializeAs(String)
    @Column({
        columnName: 'Percent'
    })
    percent: number;
}