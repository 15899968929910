<div class="container">
    <div class="card">
        <div class="card-header">
            <span>Link Account</span>
        </div>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <!--  <label><b>IF NO ACCOUNT</b></label> -->
                <div *ngIf="!isSynced && !isMultipleFacility">
                     <div class="form-group">
                         <label for="username">Username</label>
                         <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': f.username.errors }" />
                         <div *ngIf="f.username.errors" class="invalid-feedback">
                             <div *ngIf="f.username.errors.required">Username is required</div>
                         </div>
                     </div>
                     <div class="form-group">
                         <label for="password">Password</label>
                         <div class="input-group mr-3 ml-1">
                             <span class="input-group-prepend" (click)="hide = !hide"><i class="mdi mdi-24px" [ngClass]="hide == true ? 'mdi-eye-off' : 'mdi-eye'"></i></span>
                             <input [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': f.password.errors }" />
                             <div *ngIf="f.password.errors" class="invalid-feedback">
                                 <div *ngIf="f.password.errors.required">Password is required</div>
                             </div>
                         </div>
                     </div>
                     <!-- <div class="form-group">
                         <input type="checkbox" formControlName="privacy" class="form-check-input" [ngClass]="{ 'is-invalid': f.privacy.errors }" />
                         <mat-checkbox formControlName="privacy" [ngClass]="{ 'is-invalid': f.privacy.errors }">
                             <label class="form-check-label" for="privacy">
                                 I agree to the <a routerLink="/privacy">privacy policy<sup><i class="mdi mdi-sm mdi-help-circle-outline"></i></sup></a>
                             </label>
                         </mat-checkbox>
                         <div *ngIf="f.privacy.errors" class="invalid-feedback">
                             <div *ngIf="f.privacy.errors.required">You must agree to the privacy policy</div>
                         </div>
                     </div> -->
                     <div class="form-group">
                         <div class="d-flex justify-content-center">
                             <div class="col-md-10">
                                 <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                     <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                     Link Account
                                 </button>
                                 <div class="d-flex justify-content-around flex-wrap mt-2">
                                     <a (click)="cancelLink()">Cancel</a>
                                 </div>
                             </div>
                         </div>
                     </div> 
                </div>
                <div *ngIf="isMultipleFacility">
                    <div class="form-group">
                        <label class="mr-3">Select facility to link</label>
                        <select class="facility-select form-control" (change)="changeFacility($event.target.value)">
                            <!-- <option selected disabled>select facility</option> -->
                            <option *ngFor="let facility of facilityAndRoles; index as i" [value]="i">{{facility.facility}}</option>
                        </select>
                    </div>
                    <button [disabled]="loading" (click)="selectFacility()" type="button" class="btn btn-primary btn-block text-nowrap">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Select
                    </button>
                </div>
                <!-- <label><b>IF WITH ACCOUNT</b></label> -->
                <div *ngIf="isSynced">
                    <div class="form-group">
                        <label class="mr-3">Profile linked to {{linkedAccount}}</label>
                        <button type="button" (click)="unlink()" [disabled]="loading" class="btn btn-primary text-nowrap">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Unlink Account
                        </button>
                    </div>
                </div>
            </form> 
            <div class="progress" *ngIf="progress">
                <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" [style.width]="progress+'%'"></div>
            </div>
        </div>
    </div>
</div>