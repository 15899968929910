import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class TopFacilitiesInTotalNoOfLiveBirthsReport {
    @autoserializeAs(Number)
    @Column({
        columnName: 'Rank'
    })
    order: number;
    @autoserializeAs(String)
    @Column({
        columnName: 'Facility Name'
    })
    facilityName: string;
    @autoserializeAs(Number)
    @Column({
        columnName: 'Live Births'
    })
    liveBirths: number;
}
