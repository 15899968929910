<div class="container h-100 m-0 p-3">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                <span>Set Announcements</span>
            </div>
            <div class="card-body">   
                <fieldset>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <textarea class="form-control" rows="15" formControlName="html">
                                <p>Welcome to ENNHSR</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Dui faucibus in ornare quam. Suspendisse interdum consectetur libero id. Nibh ipsum consequat nisl vel pretium lectus quam id leo. Et netus et malesuada fames ac turpis. Diam sit amet nisl suscipit. Feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Luctus venenatis lectus magna fringilla urna. Volutpat sed cras ornare arcu dui vivamus. Convallis a cras semper auctor neque vitae tempus. Non quam lacus suspendisse faucibus. Molestie a iaculis at erat pellentesque adipiscing commodo. Feugiat in fermentum posuere urna nec tincidunt. Cras ornare arcu dui vivamus arcu felis. Donec et odio pellentesque diam volutpat commodo sed egestas egestas. Molestie nunc non blandit massa.</p>
                                <p>Risus feugiat in ante metus dictum at tempor. Pellentesque nec nam aliquam sem et tortor consequat. Sit amet commodo nulla facilisi nullam vehicula ipsum. Id diam vel quam elementum pulvinar etiam non quam. Lobortis mattis aliquam faucibus purus. Pretium lectus quam id leo in vitae turpis massa sed. Odio ut enim blandit volutpat maecenas volutpat blandit. Eget arcu dictum varius duis at consectetur lorem donec. Maecenas accumsan lacus vel facilisis volutpat est velit egestas. Vulputate enim nulla aliquet porttitor lacus luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Convallis aenean et tortor at risus viverra adipiscing at in. Lorem dolor sed viverra ipsum nunc. Ut etiam sit amet nisl. A diam sollicitudin tempor id eu nisl. Nullam non nisi est sit.</p>
                                <p>Facilisis leo vel fringilla est ullamcorper eget nulla. Eu lobortis elementum nibh tellus molestie. Dictum sit amet justo donec enim diam vulputate ut. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Velit scelerisque in dictum non. Bibendum at varius vel pharetra vel turpis nunc eget lorem. Cras pulvinar mattis nunc sed. Dictum varius duis at consectetur lorem. Quam lacus suspendisse faucibus interdum posuere. Condimentum id venenatis a condimentum vitae.</p>
                                <p>Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Amet massa vitae tortor condimentum. Gravida in fermentum et sollicitudin ac orci phasellus egestas. Elementum nisi quis eleifend quam adipiscing vitae. Curabitur vitae nunc sed velit dignissim sodales. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet. Etiam non quam lacus suspendisse faucibus interdum. Aliquet nec ullamcorper sit amet risus. Eu non diam phasellus vestibulum lorem sed risus ultricies. Faucibus turpis in eu mi bibendum neque. Sit amet nulla facilisi morbi tempus. Risus in hendrerit gravida rutrum quisque non tellus orci. Faucibus vitae aliquet nec ullamcorper sit. Ac tortor dignissim convallis aenean et.</p>
                                <p>Venenatis tellus in metus vulputate eu. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Vitae tortor condimentum lacinia quis vel eros. Commodo odio aenean sed adipiscing diam. Lacus luctus accumsan tortor posuere ac ut consequat. Tincidunt dui ut ornare lectus sit amet. Arcu dui vivamus arcu felis. Nulla pharetra diam sit amet. Netus et malesuada fames ac turpis egestas maecenas. Placerat vestibulum lectus mauris ultrices eros in cursus turpis massa. Vel pharetra vel turpis nunc eget lorem dolor. Mi eget mauris pharetra et ultrices neque ornare. Libero nunc consequat interdum varius sit amet mattis vulputate enim.</p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Dui faucibus in ornare quam. Suspendisse interdum consectetur libero id. Nibh ipsum consequat nisl vel pretium lectus quam id leo. Et netus et malesuada fames ac turpis. Diam sit amet nisl suscipit. Feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Luctus venenatis lectus magna fringilla urna. Volutpat sed cras ornare arcu dui vivamus. Convallis a cras semper auctor neque vitae tempus. Non quam lacus suspendisse faucibus. Molestie a iaculis at erat pellentesque adipiscing commodo. Feugiat in fermentum posuere urna nec tincidunt. Cras ornare arcu dui vivamus arcu felis. Donec et odio pellentesque diam volutpat commodo sed egestas egestas. Molestie nunc non blandit massa.</p>
                                <p>Risus feugiat in ante metus dictum at tempor. Pellentesque nec nam aliquam sem et tortor consequat. Sit amet commodo nulla facilisi nullam vehicula ipsum. Id diam vel quam elementum pulvinar etiam non quam. Lobortis mattis aliquam faucibus purus. Pretium lectus quam id leo in vitae turpis massa sed. Odio ut enim blandit volutpat maecenas volutpat blandit. Eget arcu dictum varius duis at consectetur lorem donec. Maecenas accumsan lacus vel facilisis volutpat est velit egestas. Vulputate enim nulla aliquet porttitor lacus luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Convallis aenean et tortor at risus viverra adipiscing at in. Lorem dolor sed viverra ipsum nunc. Ut etiam sit amet nisl. A diam sollicitudin tempor id eu nisl. Nullam non nisi est sit.</p>
                                <p>Facilisis leo vel fringilla est ullamcorper eget nulla. Eu lobortis elementum nibh tellus molestie. Dictum sit amet justo donec enim diam vulputate ut. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Velit scelerisque in dictum non. Bibendum at varius vel pharetra vel turpis nunc eget lorem. Cras pulvinar mattis nunc sed. Dictum varius duis at consectetur lorem. Quam lacus suspendisse faucibus interdum posuere. Condimentum id venenatis a condimentum vitae.</p>
                                <p>Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Amet massa vitae tortor condimentum. Gravida in fermentum et sollicitudin ac orci phasellus egestas. Elementum nisi quis eleifend quam adipiscing vitae. Curabitur vitae nunc sed velit dignissim sodales. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet. Etiam non quam lacus suspendisse faucibus interdum. Aliquet nec ullamcorper sit amet risus. Eu non diam phasellus vestibulum lorem sed risus ultricies. Faucibus turpis in eu mi bibendum neque. Sit amet nulla facilisi morbi tempus. Risus in hendrerit gravida rutrum quisque non tellus orci. Faucibus vitae aliquet nec ullamcorper sit. Ac tortor dignissim convallis aenean et.</p>
                                <p>Venenatis tellus in metus vulputate eu. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Vitae tortor condimentum lacinia quis vel eros. Commodo odio aenean sed adipiscing diam. Lacus luctus accumsan tortor posuere ac ut consequat. Tincidunt dui ut ornare lectus sit amet. Arcu dui vivamus arcu felis. Nulla pharetra diam sit amet. Netus et malesuada fames ac turpis egestas maecenas. Placerat vestibulum lectus mauris ultrices eros in cursus turpis massa. Vel pharetra vel turpis nunc eget lorem dolor. Mi eget mauris pharetra et ultrices neque ornare. Libero nunc consequat interdum varius sit amet mattis vulputate enim.</p>
                            </textarea>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <div class="d-flex justify-content-center">
                            <div class="col-md-10">
                                <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>               
                </fieldset>
            </div>
        </form>
    </div>
</div>
