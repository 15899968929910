import { Component, Host, Injectable, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { debounceTime, first, subscribeOn, switchMap, take, concatMap, delay, takeUntil } from 'rxjs/operators';
import {CheckboxRequiredValidator, FormControl, FormGroup, FormGroupDirective, FormArray} from '@angular/forms';

// Cerializr Imports
import {Observable, Observer, of, from, BehaviorSubject} from 'rxjs';
import { map } from 'rxjs/operators';
import { startWith} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DeserializeArray } from 'cerializr';
import { FacilityStatusReport } from './_models/facility-status-report';
import { Subject, timer } from 'rxjs';

// API
import { UsersService } from '@app/admin/users/users.service';
import { ActiveUsersReport } from './_models/active-users-report';
import { TotalNoOfScreeningPersonnelReport } from './_models/total-no-of-screening-personnel-report';
import { UserService } from '@app/user/user.service';
import { TopFacilitiesInTotalNoOfLiveBirthsReport } from './_models/top-facilities-in-total-no-of-live-births-report';
import { TopFacilitiesInTotalNoScreenedReport } from './_models/top-facilities-in-total-no-screened-report';
import { ReasonsForNotFittingHearingAidDevicesReport } from './_models/reasons-for-not-fitting-hearing-aid-devices-report';
import { NoOfScreeningFacilitiesByRegionReport } from './_models/no-of-screening-facilities-by-region-report';
import Utils from '@app/shared/utils';
import { EducationalPlacementOfPatientsReport } from './_models/educational-placement-of-patients-report';
import { NoScreenedAccScreeningMethodReport } from './_models/no-screened-acc-screening-method-report';
import { InterventionApproachOfPatientsReport } from './_models/intervention-approach-of-patients-report';
import { PsgcService } from '../../shared/address/psgc.service'
import * as moment from 'moment';
import { TimelinessOfScreeningReport } from './_models/timeliness-of-screening-report';
import { TimelinessOfABRConfirmatoryTestingReport } from './_models/timeliness-of-abrconfirmatory-testing-report';
import { TimelinessOfABRConfirmatoryTestingOriginReport } from './_models/timeliness-of-abrconfirmatory-testing-origin-report';
import { TimelinessOfAudiologicalInterventionReport } from './_models/timeliness-of-audiological-intervention-report';
import { PrintService } from '@app/shared/print/print.service';
import { PatientsReceivingSpeechAndLanguageInterventionReport } from './_models/patients-receiving-speech-and-language-intervention-report';
import { ScreeningRegistryReport } from './_models/screening-registry-report';
import { NoOfPatientsInPathwayAReport } from './_models/no-of-patients-in-pathway-A-report';
import { AreasOfConcernReport } from './_models/areas-of-concern-report';
import { NoOfPatientsWhoUnderwentCochlearImplantReport } from './_models/no-of-patients-who-underwent-cochlear-implant-report';
import { ScreeningResultsReport } from './_models/screening-results-report';
import { RiskFactorAggregateByFacilityReport } from './_models/risk-factor-aggregate-by-facility-report';
import { NoOfPatientsWhoReachedSpeechBananaReport } from './_models/no-of-patients-who-reached-speech-banana-report';
import { NoOfPatientsWhoReachedSpeechStringBeanReport } from './_models/no-of-patients-who-reached-speech-string-bean-report';
import { TotalLiveBirthsScreenedReport } from './_models/total-live-births-screened-report';
import { AgeOfPatientsWhoUnderwentCochlearImplantReport } from './_models/age-of-patients-who-underwent-cochlear-implant-report';
import { SexOfPatientsWhoUnderwentCochlearImplantReport } from './_models/sex-of-patients-who-underwent-cochlear-implant-report';
import { RiskFactorAggregateReport } from './_models/risk-factor-aggregate-report';
import { MasterlistOfSurgicalInterventionReport } from './_models/masterlist-of-surgical-intervention-report';
import { PatientDetailsReport } from './_models/patient-details-report';
import * as _ from "lodash";
import { CoMorbidityReport } from './_models/co-morbidity-report';
import { PrimaryDiagnosisReport } from './_models/primary-diagnosis-report';
// import { parse } from 'node:path';
import { AlertService } from '@app/shared/alert/alert.service';
import { tableSymbol } from './decorators/column';
import { filter } from 'lodash';

enum AreaOptions {
  All = "all",
  City = "city",
  Province = "province",
  Region = "region",
  regionDL = "regionDL"
}

enum FacilityOptions {
  Uncategorized = "uncategorized",
  Category = "category",
  FacilitiyCode = "facilityCode"
}

enum DateOptions {
  AllDates = "allDates",
  Duration = "duration",
  Periodical = "periodical"
}

enum PeriodOptions {
  Monthly = "monthly",
  Quarterly = "quarterly",
  Yearly = "yearly"
}

enum MonthOptions {
  January = "01",
  February = "02",
  March = "03",
  April = "04",
  May = "05",
  June = "06",
  July = "07",
  August = "08",
  September = "09",
  October = "10",
  November = "11",
  December = "12",
}

enum QuarterOptions {
  Q1 = "1",
  Q2 = "4",
  Q3 = "7",
  Q4 = "10"
}

interface DateFilter {
  filterType : DateOptions;
  duration : {
    from : any,
    to : any
  };
  periodical : {
    period : PeriodOptions,
    month : MonthOptions,
    quarter : QuarterOptions,
    year : any
  };
}

interface AreaFilter {
  filterType : AreaOptions;
  city: string;
  province: string;
  region: string;
}

interface FacilityFilter {
  filterType : FacilityOptions;
  category : {
    all : boolean,
    a : boolean,
    b : boolean,
    c : boolean,
    d : boolean,
  };
  facilityCode : string;
}

@Component({
  selector: 'app-test-chart',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
@Injectable({
  providedIn: 'root'
})
export class ReportsComponent implements OnInit {
  private stop$ = new Subject<boolean>();
  timer$: Observable<number>;
  timerShown: boolean = false;

  reportTitle: string;
  filterLabel: string = "";
  resultLabel: string = "Number of Results: ";
  resultCount: number = 0;
  isList = true;
  data: Observable<any[]>;
  editSection = true;
  report = null;
  
  dateFilter: DateFilter = {
    filterType: DateOptions.AllDates,
    duration: {
      from: "",
      to: ""
    },
    periodical : {
      period : PeriodOptions.Monthly,
      month : MonthOptions.January,
      quarter : QuarterOptions.Q1,
      year : ""
    }
  };

  areaFilter: AreaFilter = {
    filterType : AreaOptions.All,
    city: "",
    province: "",
    region: ""
  }

  facilityFilter: FacilityFilter = {
    filterType : FacilityOptions.Uncategorized,
    category : {
      all : true,
      a : true,
      b : true,
      c : true,
      d : true,
    },
    facilityCode : ""
  };

  regionText : string;

  reportShown = false;
  reportInput: string;
  
  reportControl = new FormControl();
  reportOptions: any; 
  reportFilteredOptions: Observable<string[]>;

  cityMunControl = new FormControl();
  cityMunOptions$: Observable<string[]>;

  provinceControl = new FormControl();
  provinceOptions$: Observable<string[]>;
  
  regControl = new FormControl();
  regOptions$: Observable<string[]>;

  psgcFilter: number;
  psgcCheck : string = "0";

  dateDisable = false;
  regionDisable = false;
  facilityDisable = false;
  facilityCodeDisable = false;
  dlAllDisable = false;

  showDateFacilityFilter = false;
  notAdmin = false;

  loading = false;

  dateLabel: string;
  areaLabel: string;
  facilityLabel: string;

  reportAggregate : boolean = false;
  
  seconds: number = 0;
  milliseconds: number = 0;
  milliDisplay: number = 0;

  progress: number = 0; // Initial progress value
  
  increaseProgress(): void {
    if (this.progress < 95) {
      this.progress += getRandomInt(3); // adjust increment amount
      setTimeout(() => this.increaseProgress(), 100); // repeat for animation
    }

    function getRandomInt(max: number) {
      return Math.floor(Math.random() * max);
    }
  }

  constructor(
    private usersService: UsersService, 
    private dashboardService: DashboardService, 
    private http: HttpClient,
    private psgcService: PsgcService,
    public printService: PrintService,
    private userService: UserService,
    private alertService: AlertService,
    ) {
    if(this.userService.userValue['isAdmin'] == true) {
      this.showDateFacilityFilter = true;
      this.notAdmin = false;
    } else {
      this.facilityFilter.facilityCode = this.userService.userValue['facility']['facilityCode'];
      this.notAdmin = true
    }

    this.reportOptions = [
      ['Age of Patients Who Underwent Cochlear Implant', this.getAgeOfPatientsCochlearImplantReport],
      ['Areas of Concern', this.getAreasOfConcernReport],
      ['Comorbidity',this.getComorbidityReport],
      ['Educational Placement of Patients', this.getEducationalPlacementOfPatientsReport],
      ['Intervention Approach of Patients', this.getInterventionApproachOfPatientsReport],
      ['Number of Patients in Pathway A', this.getNoOfPatientsInPathwayAReport],
      ['Number of Patients Who Reached Speech Banana', this.getNoOfPatientsWhoReachedSpeechBananaReport],
      ['Number of Patients Who Reached Speech String Bean', this.getNoOfPatientsWhoReachedSpeechStringBeanReport],
      ['Number of Patients Who Underwent Cochlear Implant', this.getNoOfPatientsWhoUnderwentCochlearImplantReport],
      ['Number Screened According to Screening Method', this.getNoScreenedAccScreeningMethodOptimized],
      ['Patient Details', this.getPatientDetailsReport],
      ['Patients Receiving Speech and Language Intervention', this.getPatientsReceivingSpeechAndLanguageInterventionReport],
      ['Primary Diagnosis', this.getPrimaryDiagnosisReport],
      ['Reasons for Not Fitting Hearing Aid or Devices', this.getReasonsForNotFittingHearingAidDevicesReport],
      ['Screening Registry', this.getScreeningRegistryReport],
      ['Screening Results', this.getScreeningResultsOptimized],
      ['Sex of Patients Who Underwent Cochlear Implant', this.getSexOfPatientsWhoUnderwentCochlearImplantReport],
      ['Timeliness of Audiological Intervention', this.getTimelinessOfAudiologicalInterventionReport],
      ['Timeliness of Confirmatory Testing (ABR)', this.getTimelinessOfABRConfirmatoryTestingReport],
      ['Timeliness of Screening', this.getTimelinessOfScreeningOptimized],
      ['Total Live Births Screened', this.getTotalLiveBirthsScreenedOptimized],
    ];

    if(this.userService.userValue['isAdmin'] == true) {
      this.reportOptions.push(
        ['Active Users', this.getActiveUsersReport],
        ['Facility Status', this.getFacilityStatusReport],
        ['Masterlist of Surgical Intervention', this.getMasterlistOfSurgicalInterventionReport],
        ['Number of Screening Facilities (By Region)', this.getNoOfScreeningFacilitiesByRegionReport],
        ['Risk Factor Aggregate (All)', this.getRiskFactorAggregateAllReport],
        ['Risk Factor Aggregate (Facility)', this.getRiskFactorAggregateFacilityReport],
        ['Timeliness of Confirmatory Testing (ABR) by Origin Facility', this.getTimelinessOfABRConfirmatoryTestingOriginReport],
        ['Top 10 Health Facilities in Total Number of Live Births', this.getTopFacilitiesInTotalNoOfLiveBirthsReport],
        ['Top 10 Health Facilities in Total Number Screened', this.getTopFacilitiesInTotalNoScreenedReport],
        ['Total Number of Certified Hearing Screening Personnel (By Region)', this.getTotalNoOfScreeningPersonnelReport]
      );

      this.reportOptions.sort(function(a, b){
        if(a[0] < b[0]) { return -1; }
        if(a[0] > b[0]) { return 1; }
        return 0;
    })
    }
   }

  ngOnInit(): void {
    this.reportFilteredOptions = this.reportControl.valueChanges
      .pipe(
      startWith(''),
      map(value => this.filterReport(value))
    );
    this.cityMunOptions$ = this.cityMunControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value !== '') {
          return this.search('municipality', value);
        } else {
          return of(null);
        }
      })
    );
    this.provinceOptions$ = this.provinceControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value !== '' && !this.provinceControl.disabled) {
          return this.search('province', value);
        } else {
          return of(null);
        }
      })
    );
    this.regOptions$ = this.regControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value !== '' && !this.regControl.disabled) {
          return this.search('region', value);
        } else {
          return of(null);
        }
      })
    );
  }

  private filterReport(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.reportOptions.map(x => x[0]).filter(reportOptions => reportOptions.toLowerCase().includes(filterValue));
  }

  isDisabled() {
    return true;
  }

  deleteText() {
    this.reportInput = "";
  }

  startTimer() {
    if(this.reportInput == "Total Live Births Screened" || this.reportInput == "Total Live Births Screened - Optimized") {
      this.timerShown = true;
      this.seconds = 0;
      this.milliseconds = 0;
      this.milliDisplay = 0;
      // this.timer$ = timer(0, 10).pipe(takeUntil(this.stop$));
      timer(0, 10).pipe(
        takeUntil(this.stop$)
      ).subscribe(() => {
        this.milliseconds += 10;
        this.milliDisplay = this.milliseconds/10;

        // Update seconds when milliseconds reach 1000
        if (this.milliseconds === 1000) {
          this.milliseconds = 0;
          this.seconds++;
        }
      });
    }
  }

  stopTimer() {
    this.stop$.next(true);
  }

  // change() {
  //   this.showModal$.next(true);
  // }

  reportChange(){
    this.dateFilter.filterType = DateOptions.AllDates;
    this.areaFilter.filterType = AreaOptions.All;
    this.facilityFilter.filterType = FacilityOptions.Uncategorized;
    this.restoreDateFiltToDefault();
    this.restoreAreaFiltToDefault();
    this.restoreFacilityFiltToDefault();

    switch (this.reportInput){
      case "Active Users":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Age of Patients Who Underwent Cochlear Implant":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Areas of Concern":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Comorbidity":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Educational Placement of Patients":
        this.dateDisable = true;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Masterlist of Surgical Intervention":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = true;
        break;
      case "Number Screened According to Screening Method":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Number of Patients Who Reached Speech Banana":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Number of Patients Who Reached Speech String Bean":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Number of Patients Who Underwent Cochlear Implant":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Number of Patients in Pathway A":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Number of Screening Facilities (By Region)":
        this.dateDisable = false;
        this.regionDisable = true;
        this.facilityDisable = false;
        this.facilityCodeDisable = true;
        this.dlAllDisable = true;
        break;
      case "Patient Details":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = true;
        break;
      case "Patients Receiving Speech and Language Intervention":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = true;
        break;
      case "Primary Diagnosis":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Reasons for Not Fitting Hearing Aid or Devices":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Risk Factor Aggregate (All)":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Risk Factor Aggregate (Facility)":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = true;
        this.dlAllDisable = false;
        break;
      case "Screening Registry":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = true;
        break;
      case "Screening Results":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Sex of Patients Who Underwent Cochlear Implant":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Timeliness of Audiological Intervention":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Timeliness of Confirmatory Testing (ABR)":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Timeliness of Screening":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Top 10 Health Facilities in Total Number Screened":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = true;
        this.dlAllDisable = false;
        break;
      case "Top 10 Health Facilities in Total Number of Live Births":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = true;
        this.dlAllDisable = false;
        break;
      case "Total Live Births Screened":
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
        break;
      case "Total Number of Certified Hearing Screening Personnel (By Region)":
        this.dateDisable = false;
        this.regionDisable = true;
        this.facilityDisable = false;
        this.facilityCodeDisable = true;
        this.dlAllDisable = true;
        break;
      default:
        this.dateDisable = false;
        this.regionDisable = false;
        this.facilityDisable = false;
        this.facilityCodeDisable = false;
        this.dlAllDisable = false;
    }
  }

  assignYear(datePicker, dp: any) {
    this.dateFilter.periodical.year = moment(datePicker).year();
    dp.select(new Date(this.dateFilter.periodical.year, 0));

    dp.close();
  }
  
  restoreDateFiltToDefault() {
    for(var key in this.dateFilter.duration)
      this.dateFilter.duration[key] = "";
    
    this.dateFilter.periodical.period = PeriodOptions.Monthly;
    this.dateFilter.periodical.month = MonthOptions.January;
    this.dateFilter.periodical.quarter = QuarterOptions.Q1;
    this.dateFilter.periodical.year = "";
  }

  disableFacCode(){
    // if(this.areaFilter.filterType == AreaOptions.regionDL)
    //   this.facilityCodeDisable = true;
  }

  restoreAreaFiltToDefault() {
    this.areaFilter.city = "";
    this.areaFilter.province = "";
    this.areaFilter.region = "";
  }

  restoreFacilityFiltToDefault() {
    for(var key in this.facilityFilter.category)
      this.facilityFilter.category[key] = true;
    
    this.facilityFilter.facilityCode = "";
  }

  controlCatFilt(cat? : string) {
    if(cat === "all"){
      if(this.facilityFilter.category.all == true){
        for(var key in this.facilityFilter.category)
          this.facilityFilter.category[key] = true;
      }
      else{
        for(var key in this.facilityFilter.category)
          this.facilityFilter.category[key] = false;
      }
    } else {
      if(this.facilityFilter.category.a && this.facilityFilter.category.b && this.facilityFilter.category.c && this.facilityFilter.category.d === true)
        this.facilityFilter.category.all = true;
      else
        this.facilityFilter.category.all = false;
    }
  }

  setCity(code, name) {
    this.cityMunControl.setValue(name);
    
    var provpsgc = Utils.psgcUtils.convertToProvPsgc(code);
    this.psgcService.get(provpsgc)
      .pipe(first())
      .subscribe(x => {
        this.setProv(code,x.name);
      });
  }

  setProv(code, name) {
    this.provinceControl.setValue(name);
    
    var regpsgc = Utils.psgcUtils.convertToRegPsgc(code);
    this.psgcService.get(regpsgc)
      .pipe(first())
      .subscribe(x => {
        this.setReg(code, x.name)
      });
  }

  setReg(code, name) {
    this.psgcFilter = code;
    this.regControl.setValue(name);
  }

  search(level: string, value: string): Observable<string[]> {
    return this.psgcService.search(level, value).pipe(
      map(result => {
        this.psgcCheck = result;
        return result;
      })
    );
  }

  clearReport() {
    this.reportInput = "";
    this.dateFilter.filterType = DateOptions.AllDates;
    this.areaFilter.filterType = AreaOptions.All;
    this.facilityFilter.filterType = FacilityOptions.Uncategorized;
    this.restoreAreaFiltToDefault();
    this.restoreDateFiltToDefault();
    this.restoreFacilityFiltToDefault();
  }

  generateReport() {
    this.progress = 0;
    
    this.timerShown = false;
    // this.startTimer();

    this.isList = true;
    this.resultLabel = "";
    let errorMessage = "";
    this.resultCount = 0;
    
    let reportValid = this.reportOptions.findIndex(x => x[0] === this.reportInput)
    if(reportValid == -1)
      errorMessage = "Select a valid report"

    if (this.dateFilter.duration.from !== "" || this.dateFilter.duration.to !== "") {
      if (moment(this.dateFilter.duration.from, true).isValid() == false || moment(this.dateFilter.duration.to, true).isValid() == false)
        errorMessage = "Enter valid period dates"
      if (moment(this.dateFilter.duration.from).isAfter(this.dateFilter.duration.to) == true)
        errorMessage = "From Date must be earlier than / equal to To Date"
    }

    if (this.dateFilter.filterType == DateOptions.Periodical) {
      if (moment(this.dateFilter.periodical.year, true).isValid() == false)
        errorMessage = "Enter a valid year"
    }
    if (this.facilityFilter.filterType == FacilityOptions.FacilitiyCode) {
      if (this.facilityFilter.facilityCode === "")
        errorMessage = "Enter Facility Code"
    }

    if(errorMessage != "")
      this.alertService.error(errorMessage);
    else{
      this.data = of([]);
      this.reportShown = false;
      this.reportTitle = null;
      this.loading = true;
  
      var option = this.reportOptions.find(x => x[0] === this.reportInput);
      this.reportTitle = option[0];

      if(this.reportTitle == "Primary Diagnosis" || this.reportTitle == "Comorbidity" || this.reportTitle == "Masterlist of Surgical Intervention" || this.reportTitle == "Patients Receiving Speech and Language Intervention" || this.reportTitle == "Screening Registry" || this.reportTitle == "Patient Details")
        this.reportAggregate = true;
      else
        this.reportAggregate = false;

      if(this.areaFilter.filterType === AreaOptions.regionDL){
        this.getReportsGroupByRegion(option[1]);
      }
      else{
        option[1](this.addFilters())
        setTimeout(() => {
          this.increaseProgress();
        }, 1000);
      }

        
      
      if(this.data != null){
        this.reportShown = true;
      }
        
    }
  }
  
  filterActiveReports(users, filt){
    var filteredReport = users;
    
    switch (this.dateFilter.filterType){
      case "duration":
        if (this.dateFilter.duration.from && this.dateFilter.duration.to) {
          filteredReport = filteredReport.filter(path => {
            return moment(moment(path["periodDate"]))
              .isBetween(
                moment(this.dateFilter.duration.from).startOf('day'),
                moment(this.dateFilter.duration.to).endOf('day'));
          });
        }
        break;
      case "periodical":
        if(this.dateFilter.periodical.year){
          switch (this.dateFilter.periodical.period){
            case "monthly":
              filteredReport = filteredReport.filter(path => {
                return moment(moment(path["periodDate"]))
                  .isBetween(
                    moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.month + "-01", "YYYY-MM-DD").startOf('month'),
                    moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.month + "-01", "YYYY-MM-DD").endOf('month'));
              });
              break;
            case "quarterly":
              filteredReport = filteredReport.filter(path => {
                return moment(moment(path["periodDate"]))
                  .isBetween(
                    moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.quarter + "-01", "YYYY-MM-DD").startOf('month'),
                    moment(this.dateFilter.periodical.year + "-" + (parseInt(this.dateFilter.periodical.quarter) + 2) + "-01", "YYYY-MM-DD").endOf('month'));
              });
              break;
            case "yearly":
              filteredReport = filteredReport.filter(path => {
                return moment(moment(path["periodDate"]))
                  .isBetween(
                    moment(this.dateFilter.periodical.year, "YYYY").startOf('year'),
                    moment(this.dateFilter.periodical.year, "YYYY").endOf('year'));
              });
              break;
          }
        }
        break;
    }

    var psgcLocal = (this.psgcCheck.length != 0) ? this.psgcCheck[0]["code"] : this.psgcFilter.toString();  
    
    switch (this.areaFilter.filterType){
      case AreaOptions.City:
        filteredReport = filteredReport.filter(path => {
          let areaCondition = false;
          for (var i = 0; i < path["psgcCode"].length; i++) {
            if (Utils.psgcUtils.cleanPsgc(path["psgcCode"][i]).substring(0, 6) === Utils.psgcUtils.cleanPsgc(psgcLocal.toString()).substring(0, 6))
              areaCondition = true;
          }
          return areaCondition == true;
        });
        break;
      case AreaOptions.Province:
        filteredReport = filteredReport.filter(path => {
          let areaCondition = false;
          for(var i=0; i<path["psgcCode"].length; i++) {
            if(Utils.psgcUtils.cleanPsgc(path["psgcCode"][i]).substring(0,4) == Utils.psgcUtils.cleanPsgc(psgcLocal.toString()).substring(0,4))
              areaCondition = true;
          }
          return areaCondition == true;
        });
        break;
      case AreaOptions.Region:
        filteredReport = filteredReport.filter(path => {
          let areaCondition = false;
          for(var i=0; i<path["psgcCode"].length; i++) {
            if(Utils.psgcUtils.cleanPsgc(path["psgcCode"][i]).substring(0,2) == Utils.psgcUtils.cleanPsgc(this.psgcFilter.toString()).substring(0,2))
              areaCondition = true;
          }
          return areaCondition == true;
        });
        break;
      case AreaOptions.regionDL:
        filteredReport = filteredReport.filter(path => {
          let areaCondition = false;
          for(var i=0; i<path["psgcCode"].length; i++) {
            if(Utils.psgcUtils.cleanPsgc(path["psgcCode"][i]).substring(0,2) == filt["psgc"]){
              
              areaCondition = true;
            }
          }
          return areaCondition == true;
        });
        break;
    }

    switch (this.facilityFilter.filterType){
      case "category":
        let selectedCategory: string[] = [];
        if (this.facilityFilter.category.all === false) {
          this.facilityFilter.category.a === true ? selectedCategory.push("A") : null;
          this.facilityFilter.category.b === true ? selectedCategory.push("B") : null;
          this.facilityFilter.category.c === true ? selectedCategory.push("C") : null;
          this.facilityFilter.category.d === true ? selectedCategory.push("D") : null;

          filteredReport = filteredReport.filter(path => {
            let categoryCondtion = false;
            for(var i=0; i<path["category"].length; i++) {
              if(selectedCategory.indexOf(path["category"][i]) != -1)
                categoryCondtion = true;
            }
            return categoryCondtion == true;
          });
        }
        break;
      case "facilityCode":
        filteredReport = filteredReport.filter(path => {
          let isValid = false;
            for(var i=0; i<path["facilityCode"].length; i++) {
              if(path["facilityCode"][i] == this.facilityFilter.facilityCode){
                isValid = true;
              }
            }
          return isValid == true;
        });
        break;
    }
    if(this.notAdmin == true){
      filteredReport = filteredReport.filter(path => {
        let isValid = false;
          for(var i=0; i<path["facilityCode"].length; i++) {
            if(path["facilityCode"][i] == this.facilityFilter.facilityCode){
              isValid = true;
            }
          }
        return isValid == true;
      });
    }
    return filteredReport;
  }

  getActiveUsersReport = (filters?) => {
    this.usersService.getAll().pipe(first())
    .subscribe(user => {
      let filteredUser = this.filterActiveReports(user.map(val => {
        let obj = {};
        obj["lastName"] = val["lastName"];
        obj["firstName"] = val["firstName"];
        obj["middleName"] = val["middleName"];
        if(val["nhsrcAccredDate"] != null)
          obj["accreditationValidity"] = moment.unix(val["nhsrcAccredDate"]/1000).format('MM/DD/YYYY');
        else
          obj["accreditationValidity"] = "";
        let categoryArray = [];
        let facilityNameArray = [];
        let facilityCodeArray = [];
        let psgcCodeArray = [];
        let roleArray = [];
        if(val["facilityAndRoles"] != null){
          for(var i=0; i<val["facilityAndRoles"].length; i++) {
            categoryArray.indexOf(val["facilityAndRoles"][i]["category"]) === -1 ? categoryArray.push(val["facilityAndRoles"][i]["category"]) : null;
            facilityNameArray.indexOf(val["facilityAndRoles"][i]["facility"]) === -1 ? facilityNameArray.push(val["facilityAndRoles"][i]["facility"]) : null;
            facilityCodeArray.indexOf(val["facilityAndRoles"][i]["facilityCode"]) === -1 ? facilityCodeArray.push(val["facilityAndRoles"][i]["facilityCode"]) : null;
            psgcCodeArray.indexOf(val["facilityAndRoles"][i]["psgcCode"]) === -1 ? psgcCodeArray.push(val["facilityAndRoles"][i]["psgcCode"]) : null;
            if(val["facilityAndRoles"][i]["roles"]){
              for(var j=0; j<val["facilityAndRoles"][i]["roles"].length; j++) {
                roleArray.indexOf(val["facilityAndRoles"][i]["roles"][j]) === -1 ? roleArray.push(val["facilityAndRoles"][i]["roles"][j]) : null;
              }
            }
          }
        }
        obj["category"] = categoryArray;
        obj["facility"] = facilityNameArray;
        obj["facilityCode"] = facilityCodeArray;
        obj["periodDate"] = val["dateCreated"];
        obj["psgcCode"] = psgcCodeArray;
        obj["role"] = roleArray;
        obj["role"] = obj["role"].toString().replace(/,/g, ', ');
        return obj;
      }), filters);
      
      this.isList = false;
      this.resultLabel = "Number of Results: " + filteredUser.length;
      let transform = filteredUser.map(val => {
        let obj = {};
        obj["lastName"] = val["lastName"];
        obj["firstName"] = val["firstName"];
        obj["middleName"] = val["middleName"];
        obj["accreditationValidity"] = val["accreditationValidity"];
        obj["role"] = val["role"];
        obj["assignedFacility"] = val["facility"].toString().replace(/,/g, ', ');
        
        return obj;
      });
      
      if(filteredUser.length == 0){
        let obj = {};
        obj["lastName"] = "";
        transform.push(obj);
      }

      this.loading = false;
    
      const obs = new Observable<any>((observer) => {
      observer.next(DeserializeArray(transform, ActiveUsersReport))
    });
    this.data = obs;
    this.downloadReportGroupByRegion(filters["psgc"]);
  });
}

  addFilters(region? : string){
    this.filterLabel = "";
    // this.isList = true;
    // this.resultLabel = "Number of Results: ";
    
    let obj = {};
    obj["from"] = null;
    obj["to"] = null;
    obj["psgc"] = null;
    obj["area"] = null;
    obj["category"] = null;
    obj["facilityCode"] = null;

    switch (this.dateFilter.filterType){
      case "duration":
        if(this.dateFilter.duration.from !== "" && this.dateFilter.duration.to !== ""){
          obj["from"] = moment(this.dateFilter.duration.from).startOf('day').format('x');
          obj["to"] = moment(this.dateFilter.duration.to).endOf('day').format('x');
          
          this.dateLabel = "dated " + moment(this.dateFilter.duration.from).format('MM/DD/YYYY') + " to " + moment(this.dateFilter.duration.to).format('MM/DD/YYYY');
          this.filterLabel = this.filterLabel + this.dateLabel;
        }

        break;
      case "periodical":
        switch (this.dateFilter.periodical.period){
          case "monthly":
            obj["from"] = moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.month + "-01", "YYYY-MM-DD").startOf('month').format('x');
            obj["to"] = moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.month + "-01", "YYYY-MM-DD").endOf('month').format('x');
            
            this.dateLabel = "dated " + moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.month + "-01", "YYYY-MM-DD").startOf('month').format('MM/DD/YYYY') + " to " + moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.month + "-01", "YYYY-MM-DD").endOf('month').format('MM/DD/YYYY');
            this.filterLabel = this.filterLabel + this.dateLabel;

            break;
          case "quarterly":
            obj["from"] = moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.quarter + "-01", "YYYY-MM-DD").startOf('month').format('x');
            obj["to"] = moment(this.dateFilter.periodical.year + "-" + (parseInt(this.dateFilter.periodical.quarter) + 2) + "-01", "YYYY-MM-DD").endOf('month').format('x');
            
            this.dateLabel = "dated " + moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.quarter + "-01", "YYYY-MM-DD").startOf('month').format('MM/DD/YYYY') + " to " + moment(this.dateFilter.periodical.year + "-" + (parseInt(this.dateFilter.periodical.quarter) + 2) + "-01", "YYYY-MM-DD").endOf('month').format('MM/DD/YYYY');
            this.filterLabel = this.filterLabel + this.dateLabel;

            break;
          case "yearly":
            obj["from"] = moment(this.dateFilter.periodical.year, "YYYY").startOf('year').format('x');
            obj["to"] = moment(this.dateFilter.periodical.year, "YYYY").endOf('year').format('x');

            this.dateLabel = "dated " + moment(this.dateFilter.periodical.year, "YYYY").startOf('year').format('MM/DD/YYYY') + " to " + moment(this.dateFilter.periodical.year, "YYYY").endOf('year').format('MM/DD/YYYY');
            this.filterLabel = this.filterLabel + this.dateLabel;

            break;
        }
    }

    var psgcLocal : string;
    if(this.psgcCheck.length != 0 )
      psgcLocal = this.psgcCheck[0]["code"];
    else
      psgcLocal = this.psgcFilter.toString();
    
    switch (this.areaFilter.filterType){
      case AreaOptions.City:
        obj["psgc"] = Utils.psgcUtils.cleanPsgc(psgcLocal.toString());
        obj["area"] = "municipal";

        this.areaLabel = " located in " + this.areaFilter.city + ", " + this.areaFilter.province + ", " + this.areaFilter.region;
        this.filterLabel = this.filterLabel + this.areaLabel;
        break;
      case AreaOptions.Province:
        obj["psgc"] = Utils.psgcUtils.cleanPsgc(psgcLocal.toString());
        obj["area"] = "provincial";

        this.areaLabel = " located in " + this.areaFilter.province + ", " + this.areaFilter.region;
        this.filterLabel = this.filterLabel + this.areaLabel;
        break;
      case AreaOptions.Region:
        obj["psgc"] = Utils.psgcUtils.cleanPsgc(psgcLocal.toString());
        obj["area"] = "regional"; 

        this.areaLabel = " located in " + this.areaFilter.region;
        this.filterLabel = this.filterLabel + this.areaLabel;
        break;
      case AreaOptions.regionDL:
        obj["psgc"] = region;
        obj["area"] = "all regions";

        this.areaLabel = " located in Region " + region;
        
        this.filterLabel = this.filterLabel + this.areaLabel;
        break;
    }
    

    switch (this.facilityFilter.filterType){
      case "category":
        let selectedCategory: string[] = [];
        if(this.facilityFilter.category.all === false){
          if(this.facilityFilter.category.a === true)
            selectedCategory.push("A")
          if(this.facilityFilter.category.b === true)
            selectedCategory.push("B")
          if(this.facilityFilter.category.c === true)
            selectedCategory.push("C")
          if(this.facilityFilter.category.d === true)
            selectedCategory.push("D")
        } else
          selectedCategory.push("A","B","C","D");

        obj["category"] = selectedCategory;

        this.facilityLabel = " from Category " + selectedCategory.toString().replace(/,/g, ', ');
        this.filterLabel = this.filterLabel + this.facilityLabel;
        
        break;
      case "facilityCode":
        obj["facilityCode"] = this.facilityFilter.facilityCode;

        this.facilityLabel = " from Facility " + this.facilityFilter.facilityCode;
        this.filterLabel = this.filterLabel + this.facilityLabel;
        break;
    }
    
    if(this.notAdmin == true)
      obj["facilityCode"] = this.userService.userValue['facility']['facilityCode'];
    
    return obj;
  }

  // getFacilityStatusReport = (filters? : object) => {
  //   this.dashboardService.getFacilityStatus(filters).pipe(first())
  //   .subscribe(facilities => {

  getTopFacilitiesInTotalNoOfLiveBirthsReport = (filters? : object) => {
    this.dashboardService.getTopFacilitiesInTotalNoOfLiveBirths(filters).pipe(first())
    .subscribe(user => {
      // let filteredUser = this.filterActiveReports(user, null)
      let filteredUser = user;
      let transform = [
        {
          order:0,
          facilityName: "z",
          liveBirths: 0
        }];
      for(var i=0; i<filteredUser["results"].length; i++) {
        if(filteredUser["results"][i]["dataModel"]['facilityCode'] !== null){
          let idx = transform.findIndex(({facilityName}) => facilityName.toUpperCase() == filteredUser["results"][i]["dataModel"]['facilityCode'].toUpperCase());
          if(idx > -1)
            transform[idx].liveBirths++;
          else
            transform.push({order:0,facilityName: filteredUser["results"][i]["dataModel"]["facilityCode"],liveBirths: 1});
        }
      }
      transform.splice(0,1);
      transform = transform.sort(function(a,b) {
        return b.liveBirths - a.liveBirths;
      })
      transform.splice(10);
      transform.map(x => {
        x['order'] = transform.indexOf(x) + 1;
        return x;
      })
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TopFacilitiesInTotalNoOfLiveBirthsReport))
      });
      this.data = obs;
    });
  }

  getPrimaryDiagnosisReport = (filters? : object) => {
    this.dashboardService.getPrimaryDiagnosis(filters).pipe(first())
    .subscribe(peds => {
      let preTransform = [
        {
          primaryDiagnosis: "",
          lessThan12Male: 0,
          lessThan12Female: 0,
          twelveTo18Male: 0,
          twelveTo18Female: 0,
          eighteenTo30Male: 0,
          eighteenTo30Female: 0,
          thirtyTo42Male: 0,
          thirtyTo42Female: 0,
          fortytwoTo60Male: 0,
          fortytwoTo60Female: 0,
          sixtyAboveMale: 0,
          sixtyAboveFemale: 0,
          totalMale: 0,
          totalFemale: 0,
          grandTotal: 0
        }];
      for(var i=0; i<peds["results"].length; i++) {
        let primaryDiagnosis= 0;
        let lessThan12Male= 0;
        let lessThan12Female= 0;
        let twelveTo18Male= 0;
        let twelveTo18Female= 0;
        let eighteenTo30Male= 0;
        let eighteenTo30Female= 0;
        let thirtyTo42Male= 0;
        let thirtyTo42Female= 0;
        let fortytwoTo60Male= 0;
        let fortytwoTo60Female= 0;
        let sixtyAboveMale= 0;
        let sixtyAboveFemale= 0;
        let totalMale= 0;
        let totalFemale= 0;
        let grandTotal= 0;

        let currentAge = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(peds["results"][i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days');
        let idx = preTransform.findIndex(({primaryDiagnosis}) => primaryDiagnosis === peds["results"][i]["dataModel"]["primaryDevelopmentalDiagnosis"]);
        if(idx > -1){
          preTransform[idx].grandTotal++;
          switch (true){
            case currentAge <= 365 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              preTransform[idx].lessThan12Male++;
              preTransform[idx].totalMale++;
              break;
            case currentAge <= 365 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              preTransform[idx].lessThan12Female++;
              preTransform[idx].totalFemale++;
              break;
            case currentAge >= 366 && currentAge < 547 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              preTransform[idx].twelveTo18Male++;
              preTransform[idx].totalMale++;
              break;
            case currentAge >= 366 && currentAge < 547 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              preTransform[idx].twelveTo18Female++;
              preTransform[idx].totalFemale++;
              break;
            case currentAge >= 548 && currentAge < 912 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              preTransform[idx].eighteenTo30Male++;
              preTransform[idx].totalMale++;
              break;
            case currentAge >= 548 && currentAge < 912 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              preTransform[idx].eighteenTo30Female++;
              preTransform[idx].totalFemale++;
              break;
            case currentAge >= 913 && currentAge < 1277 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              preTransform[idx].thirtyTo42Male++;
              preTransform[idx].totalMale++;
              break;
            case currentAge >= 913 && currentAge < 1277 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              preTransform[idx].thirtyTo42Female++;
              preTransform[idx].totalFemale++;
              break;
            case currentAge >= 1278 && currentAge < 1825 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              preTransform[idx].fortytwoTo60Male++;
              preTransform[idx].totalMale++;
              break;
            case currentAge >= 1278 && currentAge < 1825 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              preTransform[idx].fortytwoTo60Female++;
              preTransform[idx].totalFemale++;
              break;
            case currentAge >= 1826 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              preTransform[idx].sixtyAboveMale++;
              preTransform[idx].totalMale++;
              break;
            case currentAge >= 1826 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              preTransform[idx].sixtyAboveFemale++;
              preTransform[idx].totalFemale++;
              break;
          }
        }
        else{
          switch (true){
            case currentAge <= 365 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              lessThan12Male = 1;
              totalMale += 1;
              break;
            case currentAge <= 365 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              lessThan12Female = 1;
              totalFemale += 1;
              break;
            case currentAge >= 366 && currentAge < 547 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              twelveTo18Male = 1;
              totalMale += 1;
              break;
            case currentAge >= 366 && currentAge < 547 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              twelveTo18Female = 1;
              totalFemale += 1;
              break;
            case currentAge >= 548 && currentAge < 912 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              eighteenTo30Male = 1;
              totalMale += 1;
              break;
            case currentAge >= 548 && currentAge < 912 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              eighteenTo30Female = 1;
              totalFemale += 1;
              break;
            case currentAge >= 913 && currentAge < 1277 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              thirtyTo42Male = 1;
              totalMale += 1;
              break;
            case currentAge >= 913 && currentAge < 1277 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              thirtyTo42Female = 1;
              totalFemale += 1;
              break;
            case currentAge >= 1278 && currentAge < 1825 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              fortytwoTo60Male = 1;
              totalMale += 1;
              break;
            case currentAge >= 1278 && currentAge < 1825 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              fortytwoTo60Female = 1;
              totalFemale += 1;
              break;
            case currentAge >= 1826 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
              sixtyAboveMale = 1;
              totalMale += 1;
              break;
            case currentAge >= 1826 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
              sixtyAboveFemale = 1;
              totalFemale += 1;
              break;
          }
          preTransform.push({primaryDiagnosis: peds["results"][i]["dataModel"]["primaryDevelopmentalDiagnosis"],
            lessThan12Male: lessThan12Male,
            lessThan12Female: lessThan12Female,
            twelveTo18Male: twelveTo18Male,
            twelveTo18Female: twelveTo18Female,
            eighteenTo30Male: eighteenTo30Male,
            eighteenTo30Female: eighteenTo30Female,
            thirtyTo42Male: thirtyTo42Male,
            thirtyTo42Female: thirtyTo42Female,
            fortytwoTo60Male: fortytwoTo60Male,
            fortytwoTo60Female: fortytwoTo60Female,
            sixtyAboveMale: sixtyAboveMale,
            sixtyAboveFemale: sixtyAboveFemale,
            totalMale: totalMale,
            totalFemale: totalFemale,
            grandTotal: 1})
        }
      }
      preTransform.splice(0,1);
      preTransform = preTransform.sort(function(a,b) {
        return b.grandTotal - a.grandTotal;
      })    

      let transform = preTransform.map(val => {
        let obj = {};
        obj["primaryDiagnosis"] = val["primaryDiagnosis"];
        obj["lessThan12Male"] = val["lessThan12Male"];
        obj["lessThan12Female"] = val["lessThan12Female"];
        obj["twelveTo18Male"] = val["twelveTo18Male"];
        obj["twelveTo18Female"] = val["twelveTo18Female"];
        obj["eighteenTo30Male"] = val["eighteenTo30Male"];
        obj["eighteenTo30Female"] = val["eighteenTo30Female"];
        obj["thirtyTo42Male"] = val["thirtyTo42Male"];
        obj["thirtyTo42Female"] = val["thirtyTo42Female"];
        obj["fortytwoTo60Male"] = val["fortytwoTo60Male"];
        obj["fortytwoTo60Female"] = val["fortytwoTo60Female"];
        obj["sixtyAboveMale"] = val["sixtyAboveMale"];
        obj["sixtyAboveFemale"] = val["sixtyAboveFemale"];
        obj["totalMale"] = val["totalMale"] + " (" + (val["totalMale"]/val["grandTotal"]*100).toFixed(2) + "%)";
        obj["totalFemale"] = val["totalFemale"] + " (" + (val["totalFemale"]/val["grandTotal"]*100).toFixed(2) + "%)";
        obj["grandTotal"] = val["grandTotal"];
        
        return obj;
      });
      
      transform.unshift({
        primaryDiagnosis: "",
        lessThan12Male: "Male",
        lessThan12Female: "Female",
        twelveTo18Male: "Male",
        twelveTo18Female: "Female",
        eighteenTo30Male: "Male",
        eighteenTo30Female: "Female",
        thirtyTo42Male: "Male",
        thirtyTo42Female: "Female",
        fortytwoTo60Male: "Male",
        fortytwoTo60Female: "Female",
        sixtyAboveMale: "Male",
        sixtyAboveFemale: "Female",
        totalMale: "Male",
        totalFemale: "Female",
        grandTotal: ""
      })
      this.isList = false;
      this.resultLabel = "Number of Results: " + (transform.length - 1);
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, PrimaryDiagnosisReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getComorbidityReport = (filters? : object) => {
    this.dashboardService.getComorbidity(filters).pipe(first())
    .subscribe(peds => {
      let preTransform = [
        {
          comorbidity: "",
          lessThan12Male: 0,
          lessThan12Female: 0,
          twelveTo18Male: 0,
          twelveTo18Female: 0,
          eighteenTo30Male: 0,
          eighteenTo30Female: 0,
          thirtyTo42Male: 0,
          thirtyTo42Female: 0,
          fortytwoTo60Male: 0,
          fortytwoTo60Female: 0,
          sixtyAboveMale: 0,
          sixtyAboveFemale: 0,
          totalMale: 0,
          totalFemale: 0,
          grandTotal: 0
        }];

      for(var i=0; i<peds["results"].length; i++) {
        let comorbidity= 0;
        let lessThan12Male= 0;
        let lessThan12Female= 0;
        let twelveTo18Male= 0;
        let twelveTo18Female= 0;
        let eighteenTo30Male= 0;
        let eighteenTo30Female= 0;
        let thirtyTo42Male= 0;
        let thirtyTo42Female= 0;
        let fortytwoTo60Male= 0;
        let fortytwoTo60Female= 0;
        let sixtyAboveMale= 0;
        let sixtyAboveFemale= 0;
        let totalMale= 0;
        let totalFemale= 0;
        let grandTotal= 0;
        
        let currentAge = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(peds["results"][i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days');
        if(peds["results"][i]["dataModel"]["comorbidity"])
        {
          for(var j=0; j<peds["results"][i]["dataModel"]["comorbidity"].length; j++){
            let idx = preTransform.findIndex(({comorbidity}) => comorbidity === peds["results"][i]["dataModel"]["comorbidity"][j]);

            if(idx > -1){
              preTransform[idx].grandTotal++;
              switch (true){
                case currentAge <= 365 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  preTransform[idx].lessThan12Male++;
                  preTransform[idx].totalMale++;
                  break;
                case currentAge <= 365 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  preTransform[idx].lessThan12Female++;
                  preTransform[idx].totalFemale++;
                  break;
                case currentAge >= 366 && currentAge < 547 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  preTransform[idx].twelveTo18Male++;
                  preTransform[idx].totalMale++;
                  break;
                case currentAge >= 366 && currentAge < 547 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  preTransform[idx].twelveTo18Female++;
                  preTransform[idx].totalFemale++;
                  break;
                case currentAge >= 548 && currentAge < 912 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  preTransform[idx].eighteenTo30Male++;
                  preTransform[idx].totalMale++;
                  break;
                case currentAge >= 548 && currentAge < 912 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  preTransform[idx].eighteenTo30Female++;
                  preTransform[idx].totalFemale++;
                  break;
                case currentAge >= 913 && currentAge < 1277 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  preTransform[idx].thirtyTo42Male++;
                  preTransform[idx].totalMale++;
                  break;
                case currentAge >= 913 && currentAge < 1277 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  preTransform[idx].thirtyTo42Female++;
                  preTransform[idx].totalFemale++;
                  break;
                case currentAge >= 1278 && currentAge < 1825 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  preTransform[idx].fortytwoTo60Male++;
                  preTransform[idx].totalMale++;
                  break;
                case currentAge >= 1278 && currentAge < 1825 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  preTransform[idx].fortytwoTo60Female++;
                  preTransform[idx].totalFemale++;
                  break;
                case currentAge >= 1826 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  preTransform[idx].sixtyAboveMale++;
                  preTransform[idx].totalMale++;
                  break;
                case currentAge >= 1826 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  preTransform[idx].sixtyAboveFemale++;
                  preTransform[idx].totalFemale++;
                  break;
              }
            }
            else{
              switch (true){
                case currentAge <= 365 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  lessThan12Male = 1;
                  totalMale += 1;
                  break;
                case currentAge <= 365 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  lessThan12Female = 1;
                  totalFemale += 1;
                  break;
                case currentAge >= 366 && currentAge < 547 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  twelveTo18Male = 1;
                  totalMale += 1;
                  break;
                case currentAge >= 366 && currentAge < 547 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  twelveTo18Female = 1;
                  totalFemale += 1;
                  break;
                case currentAge >= 548 && currentAge < 912 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  eighteenTo30Male = 1;
                  totalMale += 1;
                  break;
                case currentAge >= 548 && currentAge < 912 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  eighteenTo30Female = 1;
                  totalFemale += 1;
                  break;
                case currentAge >= 913 && currentAge < 1277 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  thirtyTo42Male = 1;
                  totalMale += 1;
                  break;
                case currentAge >= 913 && currentAge < 1277 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  thirtyTo42Female = 1;
                  totalFemale += 1;
                  break;
                case currentAge >= 1278 && currentAge < 1825 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  fortytwoTo60Male = 1;
                  totalMale += 1;
                  break;
                case currentAge >= 1278 && currentAge < 1825 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  fortytwoTo60Female = 1;
                  totalFemale += 1;
                  break;
                case currentAge >= 1826 && peds["results"][i]["dataModel"]["patient"]["sex"] == "male":
                  sixtyAboveMale = 1;
                  totalMale += 1;
                  break;
                case currentAge >= 1826 && peds["results"][i]["dataModel"]["patient"]["sex"] == "female":
                  sixtyAboveFemale = 1;
                  totalFemale += 1;
                  break;
              }
              preTransform.push({comorbidity: peds["results"][i]["dataModel"]["comorbidity"][j],
                lessThan12Male: lessThan12Male,
                lessThan12Female: lessThan12Female,
                twelveTo18Male: twelveTo18Male,
                twelveTo18Female: twelveTo18Female,
                eighteenTo30Male: eighteenTo30Male,
                eighteenTo30Female: eighteenTo30Female,
                thirtyTo42Male: thirtyTo42Male,
                thirtyTo42Female: thirtyTo42Female,
                fortytwoTo60Male: fortytwoTo60Male,
                fortytwoTo60Female: fortytwoTo60Female,
                sixtyAboveMale: sixtyAboveMale,
                sixtyAboveFemale: sixtyAboveFemale,
                totalMale: totalMale,
                totalFemale: totalFemale,
                grandTotal: 1})
            }
          }  
        }
      }
      preTransform.splice(0,1);
      preTransform = preTransform.sort(function(a,b) {
        return b.grandTotal - a.grandTotal;
      })    

      let transform = preTransform.map(val => {
        let obj = {};
        obj["comorbidity"] = val["comorbidity"];
        obj["lessThan12Male"] = val["lessThan12Male"];
        obj["lessThan12Female"] = val["lessThan12Female"];
        obj["twelveTo18Male"] = val["twelveTo18Male"];
        obj["twelveTo18Female"] = val["twelveTo18Female"];
        obj["eighteenTo30Male"] = val["eighteenTo30Male"];
        obj["eighteenTo30Female"] = val["eighteenTo30Female"];
        obj["thirtyTo42Male"] = val["thirtyTo42Male"];
        obj["thirtyTo42Female"] = val["thirtyTo42Female"];
        obj["fortytwoTo60Male"] = val["fortytwoTo60Male"];
        obj["fortytwoTo60Female"] = val["fortytwoTo60Female"];
        obj["sixtyAboveMale"] = val["sixtyAboveMale"];
        obj["sixtyAboveFemale"] = val["sixtyAboveFemale"];
        obj["totalMale"] = val["totalMale"] + " (" + (val["totalMale"]/val["grandTotal"]*100).toFixed(2) + "%)";
        obj["totalFemale"] = val["totalFemale"] + " (" + (val["totalFemale"]/val["grandTotal"]*100).toFixed(2) + "%)";
        obj["grandTotal"] = val["grandTotal"];
        
        return obj;
      });
      
      transform.unshift({
        comorbidity: "",
        lessThan12Male: "Male",
        lessThan12Female: "Female",
        twelveTo18Male: "Male",
        twelveTo18Female: "Female",
        eighteenTo30Male: "Male",
        eighteenTo30Female: "Female",
        thirtyTo42Male: "Male",
        thirtyTo42Female: "Female",
        fortytwoTo60Male: "Male",
        fortytwoTo60Female: "Female",
        sixtyAboveMale: "Male",
        sixtyAboveFemale: "Female",
        totalMale: "Male",
        totalFemale: "Female",
        grandTotal: ""
      })
      
      this.isList = false;
      this.resultLabel = "Number of Results: " + (transform.length - 1);
      this.loading = false;

      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, CoMorbidityReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }
  
  getTotalLiveBirthsScreenedReport = (filters? : object) => {
    this.dashboardService.getTotalLiveBirthsScreened(filters).pipe(first())
    .subscribe(user => {
      let transform = [
        {
          totalLiveBirth: user["patient"],
          noOfBabiesScreened: 0,
          noOfBabiesScreenedPercent: "",
          screenedUsingPhilhealth: 0,
          screenedUsingPhilhealthPercent: ""
        }];

      
      // transform[0].noOfBabiesScreened = user["status"].length;
      for(var i=0; i<user["status"].length; i++) {
        console.log(user["status"][i]["dataModel"]["patient"])
        if(typeof user["status"][i]["dataModel"]["patient"] === 'object')
          transform[0].noOfBabiesScreened++

        if(user["status"][i]["dataModel"]["patient"] && user["status"][i]["dataModel"]["patient"]["philhealth"] == "true"){
          transform[0].screenedUsingPhilhealth++;
        }
      }
      
      if(transform[0].noOfBabiesScreened != 0 && transform[0].totalLiveBirth != 0)
        transform[0].noOfBabiesScreenedPercent = (transform[0].noOfBabiesScreened / transform[0].totalLiveBirth*100).toFixed(2) + "%";
      
      if(transform[0].screenedUsingPhilhealth != 0 && transform[0].totalLiveBirth != 0)
        transform[0].screenedUsingPhilhealthPercent = (transform[0].screenedUsingPhilhealth / transform[0].totalLiveBirth*100).toFixed(2) + "%";
      

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TotalLiveBirthsScreenedReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
      this.stopTimer();
    });
  }

  getTotalLiveBirthsScreenedOptimized = (filters? : object) => {
    this.dashboardService.getTotalLiveBirthsScreenedOptimized(filters).pipe(first())
    .subscribe(res => {
      let transform = [
        {
          totalLiveBirth: res[0],
          noOfBabiesScreened: res[1],
          noOfBabiesScreenedPercent: "",
          screenedUsingPhilhealth: res[2],
          screenedUsingPhilhealthPercent: ""
        }];

      if(transform[0].noOfBabiesScreened != 0 && transform[0].totalLiveBirth != 0)
        transform[0].noOfBabiesScreenedPercent = (transform[0].noOfBabiesScreened / transform[0].totalLiveBirth*100).toFixed(2) + "%";
      
      if(transform[0].screenedUsingPhilhealth != 0 && transform[0].totalLiveBirth != 0)
        transform[0].screenedUsingPhilhealthPercent = (transform[0].screenedUsingPhilhealth / transform[0].totalLiveBirth*100).toFixed(2) + "%";
      

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TotalLiveBirthsScreenedReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
      this.stopTimer();
    });
  }

  getAgeOfPatientsCochlearImplantReport = (filters? : object) => {
    this.dashboardService.getAgeOfPatientsCochlearImplant(filters).pipe(first())
    .subscribe(cochlear => {
      let transform = [
        {
          hearingLoss: "Unilateral",
          zeroToThree: 0,
          zeroToThreePercent: "",
          fourToSeven: 0,
          fourToSevenPercent: "",
          eightAndAbove: 0,
          eightAndAbovePercent: "",
          total: 0
        },
        {
          hearingLoss: "Bilateral",
          zeroToThree: 0,
          zeroToThreePercent: "",
          fourToSeven: 0,
          fourToSevenPercent: "",
          eightAndAbove: 0,
          eightAndAbovePercent: "",
          total: 0
        },
        {
          hearingLoss: "TOTAL (Unilateral and Bilateral)",
          zeroToThree: 0,
          zeroToThreePercent: "",
          fourToSeven: 0,
          fourToSevenPercent: "",
          eightAndAbove: 0,
          eightAndAbovePercent: "",
          total: 0
        }];
      for(var i=0; i<cochlear["results"].length; i++) {
        if(cochlear["results"][i]["dataModel"]["dateLeftEar"] != "" && cochlear["results"][i]["dataModel"]["dateRightEar"] != "" && cochlear["results"][i]["dataModel"]["dateLeftEar"] ==  cochlear["results"][i]["dataModel"]["dateRightEar"]){
          let currentAge = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(cochlear["results"][i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days');
          switch (true){
            case currentAge <= 1459:
              transform[1].zeroToThree++;
              transform[1].total++;
              transform[2].zeroToThree++;
              transform[2].total++;
              break;
            case currentAge >= 1460 && currentAge < 2920:
              transform[1].fourToSeven++;
              transform[1].total++;
              transform[2].fourToSeven++;
              transform[2].total++;
              break;
            case currentAge >= 2920:
              transform[1].eightAndAbove++;
              transform[1].total++;
              transform[2].eightAndAbove++;
              transform[2].total++;
              break;
          }
        } else if((cochlear["results"][i]["dataModel"]["dateLeftEar"] != "" && cochlear["results"][i]["dataModel"]["dateRightEar"] == "") || (cochlear["results"][i]["dataModel"]["dateLeftEar"] == "" && cochlear["results"][i]["dataModel"]["dateRightEar"] != "") || (cochlear["results"][i]["dataModel"]["dateLeftEar"] != cochlear["results"][i]["dataModel"]["dateRightEar"])){
          let currentAge = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(cochlear["results"][i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days');
          switch (true){
            case currentAge <= 1459:
              transform[0].zeroToThree++;
              transform[0].total++;
              transform[2].zeroToThree++;
              transform[2].total++;
              break;
            case currentAge >= 1460 && currentAge < 2920:
              transform[0].fourToSeven++;
              transform[0].total++;
              transform[2].fourToSeven++;
              transform[2].total++;
              break;
            case currentAge >= 2920:
              transform[0].eightAndAbove++;
              transform[0].total++;
              transform[2].eightAndAbove++;
              transform[2].total++;
              break;
          }
        }
      }
      if(transform[0].total != 0){
        transform[0].zeroToThreePercent = (transform[0].zeroToThree/transform[0].total*100).toFixed(2) + "%";
        transform[0].fourToSevenPercent = (transform[0].fourToSeven/transform[0].total*100).toFixed(2) + "%";
        transform[0].eightAndAbovePercent = (transform[0].eightAndAbove/transform[0].total*100).toFixed(2) + "%";
      }
      if(transform[1].total != 0){
        transform[1].zeroToThreePercent = (transform[1].zeroToThree/transform[1].total*100).toFixed(2) + "%";
        transform[1].fourToSevenPercent = (transform[1].fourToSeven/transform[1].total*100).toFixed(2) + "%";
        transform[1].eightAndAbovePercent = (transform[1].eightAndAbove/transform[1].total*100).toFixed(2) + "%";
      }
      if(transform[2].total != 0){
        transform[2].zeroToThreePercent = (transform[2].zeroToThree/transform[2].total*100).toFixed(2) + "%";
        transform[2].fourToSevenPercent = (transform[2].fourToSeven/transform[2].total*100).toFixed(2) + "%";
        transform[2].eightAndAbovePercent = (transform[2].eightAndAbove/transform[2].total*100).toFixed(2) + "%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, AgeOfPatientsWhoUnderwentCochlearImplantReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getSexOfPatientsWhoUnderwentCochlearImplantReport = (filters? : object) => {
    this.dashboardService.getAgeOfPatientsCochlearImplant(filters).pipe(first())
    .subscribe(cochlear => {
      let transform = [
        {
          hearingLoss: "Unilateral",
          male: 0,
          malePercent: "",
          female: 0,
          femalePercent: "",
          total: 0
        },
        {
          hearingLoss: "Bilateral",
          male: 0,
          malePercent: "",
          female: 0,
          femalePercent: "",
          total: 0
        },
        {
          hearingLoss: "TOTAL (Unilateral and Bilateral)",
          male: 0,
          malePercent: "",
          female: 0,
          femalePercent: "",
          total: 0
        }];
      for(var i=0; i<cochlear["results"].length; i++) {
        if(cochlear["results"][i]["dataModel"]["dateLeftEar"] != "" && cochlear["results"][i]["dataModel"]["dateRightEar"] != "" && cochlear["results"][i]["dataModel"]["dateLeftEar"] ==  cochlear["results"][i]["dataModel"]["dateRightEar"]){
          switch (true){
            case cochlear["results"][i]["dataModel"]["patient"]["sex"] === "male":
              transform[1].male++;
              transform[1].total++;
              transform[2].male++;
              transform[2].total++;
              break;
            case cochlear["results"][i]["dataModel"]["patient"]["sex"] === "female":
              transform[1].female++;
              transform[1].total++;
              transform[2].female++;
              transform[2].total++;
              break;
          }
        } else if((cochlear["results"][i]["dataModel"]["dateLeftEar"] != "" && cochlear["results"][i]["dataModel"]["dateRightEar"] == "") || (cochlear["results"][i]["dataModel"]["dateLeftEar"] == "" && cochlear["results"][i]["dataModel"]["dateRightEar"] != "") || (cochlear["results"][i]["dataModel"]["dateLeftEar"] != cochlear["results"][i]["dataModel"]["dateRightEar"])){
          switch (true){
            case cochlear["results"][i]["dataModel"]["patient"]["sex"] === "male":
              transform[0].male++;
              transform[0].total++;
              transform[2].male++;
              transform[2].total++;
              break;
            case cochlear["results"][i]["dataModel"]["patient"]["sex"] === "female":
              transform[0].female++;
              transform[0].total++;
              transform[2].female++;
              transform[2].total++;
              break;
          }
        }
      }
      if(transform[0].total != 0){
        transform[0].malePercent = (transform[0].male/transform[0].total*100).toFixed(2) + "%";
        transform[0].femalePercent = (transform[0].female/transform[0].total*100).toFixed(2) + "%";
      }
      if(transform[1].total != 0){
        transform[1].malePercent = (transform[1].male/transform[1].total*100).toFixed(2) + "%";
        transform[1].femalePercent = (transform[1].female/transform[1].total*100).toFixed(2) + "%";
      }
      if(transform[2].total != 0){
        transform[2].malePercent = (transform[2].male/transform[2].total*100).toFixed(2) + "%";
        transform[2].femalePercent = (transform[2].female/transform[2].total*100).toFixed(2) + "%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, SexOfPatientsWhoUnderwentCochlearImplantReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getTopFacilitiesInTotalNoScreenedReport = (filters? : object) => {
    this.dashboardService.getTopFacilitiesInTotalNoScreened(filters).pipe(first())
    .subscribe(user => {
      let transform = [
        {
          order:0,
          facilityName: "",
          numberScreened: 0
        }];
      for(var i=0; i<user["results"].length; i++) {
        if(user["results"][i]["dataModel"]['hSC'] !== null){
          var idx = transform.findIndex(({facilityName}) => facilityName.toUpperCase() === user["results"][i]["dataModel"]['hSC'].toUpperCase());
          if(idx > -1)
            transform[idx].numberScreened++;
          else
            transform.push({order:0,facilityName: user["results"][i]["dataModel"]["hSC"],numberScreened: 1});
        }
      }
      transform.splice(0,1);
      transform = transform.sort(function(a,b) {
        return b.numberScreened - a.numberScreened;
      })
      transform.splice(10);
      transform.map(x => {
        x['order'] = transform.indexOf(x) + 1;
        return x;
      })
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TopFacilitiesInTotalNoScreenedReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getFacilityStatusReport = (filters? : object) => {
    this.dashboardService.getFacilityStatus(filters).pipe(first())
    .subscribe(facilities => {
      let transform = facilities["results"].map(val => {
        let obj = {};
        obj["facilityName"] = val["dataModel"]["facilityName"];
        obj["accreditationNumber"] = val["dataModel"]["nshrcFacilityAccreditationNumber"];
        let facilityPartnerArray = [];
        if(val["dataModel"]["facilityPartners"] != null){
          for(var i=0; i<val["dataModel"]["facilityPartners"].length; i++)
            facilityPartnerArray.indexOf(val["dataModel"]["facilityPartners"][i]["facilityName"]) === -1 ? facilityPartnerArray.push(val["dataModel"]["facilityPartners"][i]["facilityName"]) : null;
        }
        obj["listOfPartners"] = facilityPartnerArray;
        obj["listOfPartners"] = obj["listOfPartners"].toString().replace(/,/g, ', ');
        if(val["dataModel"]["fullAddress"] === undefined || val["dataModel"]["fullAddress"] == null)
          obj["psgc"] = "";
        else
          obj["psgc"] = val["dataModel"]["fullAddress"];
        obj["category"] = val["dataModel"]["category"];
        let activeConvert = (val["dataModel"]["active"] === true) ? "Active" : "Inactive";
        obj["activeInactiveStatus"] = activeConvert;
        return obj;
      });
      if(facilities["count"] == 0){
        let obj = {};
        obj["facilityName"] = "";
        transform.push(obj);
      }
      
      this.isList = false;
      this.resultLabel = "Number of Results: " + facilities["count"];
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, FacilityStatusReport))
      });
      this.data = obs;

      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getPatientsReceivingSpeechAndLanguageInterventionReport = () => {
    var transform = []
    this.getPatientsReceivingSpeechAndLanguageInterventionReportRecursion(0, transform)
  }

  getPatientsReceivingSpeechAndLanguageInterventionReportRecursion(page: number, transform: any[]) {
    this.dashboardService.getAllSpeechPathologySession(this.addFilters(), page).pipe(first())
      .subscribe(speechPathologySession => {
        if(speechPathologySession["count"] > 500 && page === 0) {
          if(confirm("Selected range will generate " + speechPathologySession['count'] + " rows. Would you like to directly download instead?")) {
            console.log("Implement delete functionality here");
            this.reportShown = false;
            this.getPatientsReceivingSpeechAndLanguageInterventionReportDownload(this.addFilters());
            return
          }
        }
        
        if(speechPathologySession["results"].length == 0 && page > 0)
          return
        transform = transform.concat(speechPathologySession["results"].map(val => {
          let obj = {};
          obj["lastName"] = val["dataModel"]["patient"]["lastName"];
          obj["firstName"] = val["dataModel"]["patient"]["firstName"];
          obj["middleName"] = val["dataModel"]["patient"]["middleName"];
          obj["evaluationOrTherapySession"] = val["dataModel"]["evaluationOrTherapy"];
          obj["noOfSessionAttended"] = val["dataModel"]["numberOfSession"];
          obj["dateOfSession"] = moment.unix(val["dataModel"]["dateOfSession"]/1000).format('MM/DD/YYYY');
          obj["audition"] = val["dataModel"]["audition"];
          obj["chronologicalAge"] = val["dataModel"]["chronologicalAge"];
          obj["hearingAge"] = val["dataModel"]["hearingAge"];
          obj["speech"] = val["dataModel"]["speech"];
          obj["receptiveLanguage"] = val["dataModel"]["receptiveLanguage"];
          obj["expressiveLanguage"] = val["dataModel"]["expressiveLanguage"];
          obj["interventionApproach"] = val["dataModel"]["interventionApproach"];
          return obj;
        }));
        if(speechPathologySession["results"].length == 0 && page == 0){
          let obj = {};
          obj["lastName"] = "";
          transform.push(obj);
        }

        this.isList = false;
        this.resultCount = this.resultCount + speechPathologySession["results"].length;
        this.resultLabel = "Number of Results: " + this.resultCount;
        this.loading = false;
        // observer.next(DeserializeArray(transform, PatientsReceivingSpeechAndLanguageInterventionReport))

        const obs = new Observable<any>((observer) => {
          observer.next(DeserializeArray(transform, PatientsReceivingSpeechAndLanguageInterventionReport))
        });
        this.data = obs;

        this.getPatientsReceivingSpeechAndLanguageInterventionReportRecursion(++page, transform) 
    });
  }

  getPatientsReceivingSpeechAndLanguageInterventionReportDownload = (filters? : object) => {
    this.dashboardService.getAllSpeechPathologySession(filters).pipe(first())
      .subscribe(speechPathologySession => {
        let transform = speechPathologySession["results"].map(val => {
          let obj = {};
          obj["lastName"] = val["dataModel"]["patient"]["lastName"];
          obj["firstName"] = val["dataModel"]["patient"]["firstName"];
          obj["middleName"] = val["dataModel"]["patient"]["middleName"];
          obj["evaluationOrTherapySession"] = val["dataModel"]["evaluationOrTherapy"];
          obj["noOfSessionAttended"] = val["dataModel"]["numberOfSession"];
          obj["dateOfSession"] = moment.unix(val["dataModel"]["dateOfSession"]/1000).format('MM/DD/YYYY');
          obj["audition"] = val["dataModel"]["audition"];
          obj["chronologicalAge"] = val["dataModel"]["chronologicalAge"];
          obj["hearingAge"] = val["dataModel"]["hearingAge"];
          obj["speech"] = val["dataModel"]["speech"];
          obj["receptiveLanguage"] = val["dataModel"]["receptiveLanguage"];
          obj["expressiveLanguage"] = val["dataModel"]["expressiveLanguage"];
          obj["interventionApproach"] = val["dataModel"]["interventionApproach"];
          return obj;
        });
        
        this.isList = false;
        this.resultCount = this.resultCount + speechPathologySession["results"].length;
        this.resultLabel = "Number of Results: " + this.resultCount;
        this.loading = false;
        

        const obs = new Observable<any>((observer) => {
          observer.next(DeserializeArray(transform, PatientsReceivingSpeechAndLanguageInterventionReport))
        });
        this.data = obs;

        this.exportToExcel(null);
    });
  }

  getPatientDetailsReportDownload = (filters? : object) => {
    this.dashboardService.getPatientDetailsDownload(filters).pipe(first())
      .subscribe(patient => {
        let transform = patient["resutls"].map(val => {
          let obj = {};
          obj["lastName"] = val["dataModel"]["lastName"];
          obj["firstName"] = val["dataModel"]["firstName"];
          obj["middleName"] = val["dataModel"]["middleName"];
          obj["sex"] = val["dataModel"]["sex"];
          switch (val["dataModel"]["sex"]){
            case "male":
              obj["sex"] = "Male";
              break;
            case "female":
              obj["sex"] = "Female";
              break;
            case "unknown":
              obj["sex"] = "Unknown";
              break;
          }
          switch (val["dataModel"]["philhealth"]) {
            case "true":
              obj["philhealth"] = "True";
              break;
            case "false":
            case "":
              obj["philhealth"] = "False";
              break;
            case null:
              obj["philhealth"] = "";
              break;
          }
          obj["dateOfBirth"] = moment.unix(val["dataModel"]["dateOfBirth"]/1000).format('MM/DD/YYYY');
          obj["timeOfBirth"] = val["dataModel"]["timeOfBirth"];
          obj["birthOutcome"] = val["dataModel"]["birthOutcome"];
          obj["birthOrder"] = val["dataModel"]["birthOrder"];
          obj["birthWeight"] = val["dataModel"]["birthWeight"];
          obj["birthWeight"] != null ? obj["birthWeight"] += " kg" : null;
          obj["gestationAge"] = val["dataModel"]["gestationAge"];
          obj["gestationAge"] != null ? obj["gestationAge"] += " weeks" : null;
          obj["facilityOfBirth"] = val["dataModel"]["facilityOfBirth"];
          obj["mLastName"] = val["dataModel"]["motherLastName"];
          obj["mFirstName"] = val["dataModel"]["motherFirstName"];
          obj["mMiddleName"] = val["dataModel"]["motherMiddleName"];
          obj["address"] = (val["dataModel"]["streetAddress"] + ", " + val["dataModel"]["psgcCode"]);
          obj["phoneNo"] = val["dataModel"]["phoneNumber"];
          return obj;
        });
        
        this.isList = false;
        this.resultCount = this.resultCount + patient["results"].length;
        this.resultLabel = "Number of Results: " + this.resultCount;
        this.loading = false;
        // observer.next(DeserializeArray(transform, PatientDetailsReport))
        const obs = new Observable<any>((observer) => {
          observer.next(DeserializeArray(transform, PatientDetailsReport))
        });
        this.data = obs;

        this.exportToExcel(null);
    });
  }

  getPatientDetailsReport = () => {
    var transform = []
    this.getPatientDetailsReportRecursion(0, transform)
  }

  getPatientDetailsReportRecursion(page: number, transform: any[]) {
    this.dashboardService.getPatientDetails(page, this.addFilters()).pipe(first())
      .subscribe(patient => {
        if(patient["count"] > 500 && page === 0) {
          if(confirm("Selected range will generate " + patient['count'] + " rows. Would you like to directly download instead?")) {
            console.log("Implement delete functionality here");
            this.reportShown = false;
            this.getPatientDetailsReportDownload(this.addFilters());
            return
          }
        }

        if(patient["results"].length == 0 && page > 0)
          return
        // let hoistedMeta = patient["results"].map(val => {
        //   let obj = {};
        //   obj["data"] = val;
        //   return obj;
        // });
        transform = transform.concat(patient["results"].map(val => {
          let obj = {};
          obj["lastName"] = val["dataModel"]["lastName"];
          obj["firstName"] = val["dataModel"]["firstName"];
          obj["middleName"] = val["dataModel"]["middleName"];
          obj["sex"] = val["dataModel"]["sex"];
          switch (val["dataModel"]["sex"]){
            case "male":
              obj["sex"] = "Male";
              break;
            case "female":
              obj["sex"] = "Female";
              break;
            case "unknown":
              obj["sex"] = "Unknown";
              break;
          }
          switch (val["dataModel"]["philhealth"]) {
            case "true":
              obj["philhealth"] = "True";
              break;
            case "false":
            case "":
              obj["philhealth"] = "False";
              break;
            case null:
              obj["philhealth"] = "";
              break;
          }
          obj["dateOfBirth"] = moment.unix(val["dataModel"]["dateOfBirth"]/1000).format('MM/DD/YYYY');
          obj["timeOfBirth"] = val["dataModel"]["timeOfBirth"];
          obj["birthOutcome"] = val["dataModel"]["birthOutcome"];
          obj["birthOrder"] = val["dataModel"]["birthOrder"];
          obj["birthWeight"] = val["dataModel"]["birthWeight"];
          obj["birthWeight"] != null ? obj["birthWeight"] += " kg" : null;
          obj["gestationAge"] = val["dataModel"]["gestationAge"];
          obj["gestationAge"] != null ? obj["gestationAge"] += " weeks" : null;
          obj["facilityOfBirth"] = val["dataModel"]["facilityOfBirth"];
          obj["mLastName"] = val["dataModel"]["motherLastName"];
          obj["mFirstName"] = val["dataModel"]["motherFirstName"];
          obj["mMiddleName"] = val["dataModel"]["motherMiddleName"];
          val["dataModel"]["streetAddress"] != "" ? obj["address"] = (val["dataModel"]["streetAddress"] + ", " + val["dataModel"]["fullAddress"]) : obj["address"] = val["dataModel"]["fullAddress"];
          obj["phoneNo"] = val["dataModel"]["phoneNumber"];
          return obj;
        }));
        if(patient["results"].length == 0 && page == 0){
          let obj = {};
          obj["lastName"] = "";
          transform.push(obj);
        }

        this.isList = false;
        this.resultCount = this.resultCount + patient["results"].length;
        this.resultLabel = "Number of Results: " + this.resultCount;
        this.loading = false;
        // observer.next(DeserializeArray(transform, PatientDetailsReport))
        const obs = new Observable<any>((observer) => {
          observer.next(DeserializeArray(transform, PatientDetailsReport))
        });
        this.data = obs;

        this.getPatientDetailsReportRecursion(++page, transform) 
    });
  }

  getScreeningRegistryReport = () => {
    var transform = []
    this.getScreeningRegistryReportRecursion(0, transform)
  }

  getScreeningRegistryReportRecursion(page: number, transform: any[]) {
    this.dashboardService.getScreeningRegistry(this.addFilters(), page).pipe(first())
      .subscribe(screeningRegistry => {
        if(screeningRegistry["count"] > 500 && page === 0) {
          if(confirm("Selected range will generate " + screeningRegistry['count'] + " rows. Would you like to directly download instead?")) {
            console.log("Implement delete functionality here");
            this.reportShown = false;
            this.getScreeningRegistryReportDownload(this.addFilters());
            return
          }
        }

        if(screeningRegistry["results"].length == 0 && page > 0){
          return
        }

        let filteredScreeningRegistry = screeningRegistry["results"].filter(path => typeof path["dataModel"]["patient"] !== 'undefined' && path["dataModel"]["patient"] != null);
        let hoistedMeta = filteredScreeningRegistry.map(val => {
          let obj = {};
          obj["data"] = val;
          return obj;
        });
        

        transform = transform.concat(hoistedMeta.map(val => {
          let obj = {};
          obj["screenedBy"] = val["data"]["dataModel"]["screenerName"];
          obj["dateOfScreening"] = moment.unix(val["data"]["dataModel"]["dateOfScreening"]/1000).format('MM/DD/YYYY');
          obj["typeOfScreening"] = val["data"]["dataModel"]["typeOfScreening"];
          obj["lastName"] = val["data"]["dataModel"]["patient"]["lastName"];
          obj["firstName"] = val["data"]["dataModel"]["patient"]["firstName"];
          obj["middleName"] = val["data"]["dataModel"]["patient"]["middleName"];
          obj["philhealthPin"] = val["data"]["dataModel"]["patient"]["philhealthPin"];
          obj["dateOfBirth"] = moment.unix(val["data"]["dataModel"]["patient"]["dateOfBirth"]/1000).format('MM/DD/YYYY');
          obj["timeOfBirth"] = val["data"]["dataModel"]["patient"]["timeOfBirth"];
          obj["birthOrder"] = val["data"]["dataModel"]["patient"]["birthOrder"];
          obj["gestationAge"] = val["data"]["dataModel"]["patient"]["gestationAge"];
          obj["birthWeight"] = val["data"]["dataModel"]["patient"]["birthWeight"];
          let sexConvert = "";
          switch (val["data"]["dataModel"]["patient"]["sex"]){
            case "male":
              sexConvert = "Male";
              break;
            case "female":
              sexConvert = "Female";
              break;
            case "unknown":
              sexConvert = "Unknown";
              break;
          }
          obj["sex"] = sexConvert;
          obj["hyperbilirubinemia"] = val["data"]["dataModel"]["hyperbilirubinemia"];
          obj["ventilation"] = val["data"]["dataModel"]["ventilation"];
          obj["nicu"] = val["data"]["dataModel"]["nicu"];
          obj["ototoxicMedication"] = val["data"]["dataModel"]["ototoxicMedication"];
          obj["familyHistoryHearingLoss"] = val["data"]["dataModel"]["familyHistoryHearingLoss"];
          obj["craniofacialAnomalies"] = val["data"]["dataModel"]["craniofacialAnomalies"];
          obj["featuresAssociated"] = val["data"]["dataModel"]["featuresAssociated"];
          obj["inUteroInfections"] = val["data"]["dataModel"]["inUteroInfections"];

          return obj;
        }));
        if(screeningRegistry["results"].length == 0 && page == 0){
          let obj = {};
          obj["screenedBy"] = "";
          transform.push(obj);
        }
  

        this.isList = false;
        this.resultCount += hoistedMeta.length;
        this.resultLabel = "Number of Results: " + this.resultCount;
        this.loading = false;
        
        const obs = new Observable<any>((observer) => {
          observer.next(DeserializeArray(transform, ScreeningRegistryReport))
        });
        this.data = obs;

        this.getScreeningRegistryReportRecursion(++page, transform) 
      });
  }

  getScreeningRegistryReportDownload = (filters? : object) => {
    this.dashboardService.getScreeningRegistry(filters).pipe(first())
      .subscribe(screeningRegistry => {
        let filteredScreeningRegistry = screeningRegistry["results"].filter(path => typeof path["dataModel"]["patient"] !== 'undefined' && path["dataModel"]["patient"] != null);
        let hoistedMeta = filteredScreeningRegistry.map(val => {
          let obj = {};
          obj["data"] = val;
          return obj;
        });

        let transform = hoistedMeta.map(val => {
          let obj = {};
          obj["screenedBy"] = val["data"]["dataModel"]["screenerName"];
          obj["dateOfScreening"] = moment.unix(val["data"]["dataModel"]["dateOfScreening"]/1000).format('MM/DD/YYYY');
          obj["typeOfScreening"] = val["data"]["dataModel"]["typeOfScreening"];
          obj["lastName"] = val["data"]["dataModel"]["patient"]["lastName"];
          obj["firstName"] = val["data"]["dataModel"]["patient"]["firstName"];
          obj["middleName"] = val["data"]["dataModel"]["patient"]["middleName"];
          obj["philhealthPin"] = val["data"]["dataModel"]["patient"]["philhealthPin"];
          obj["dateOfBirth"] = moment.unix(val["data"]["dataModel"]["patient"]["dateOfBirth"]/1000).format('MM/DD/YYYY');
          obj["timeOfBirth"] = val["data"]["dataModel"]["patient"]["timeOfBirth"];
          obj["birthOrder"] = val["data"]["dataModel"]["patient"]["birthOrder"];
          obj["gestationAge"] = val["data"]["dataModel"]["patient"]["gestationAge"];
          obj["birthWeight"] = val["data"]["dataModel"]["patient"]["birthWeight"];
          let sexConvert = "";
          switch (val["data"]["dataModel"]["patient"]["sex"]){
            case "male":
              sexConvert = "Male";
              break;
            case "female":
              sexConvert = "Female";
              break;
            case "unknown":
              sexConvert = "Unknown";
              break;
          }
          obj["sex"] = sexConvert;
          obj["hyperbilirubinemia"] = val["data"]["dataModel"]["hyperbilirubinemia"];
          obj["ventilation"] = val["data"]["dataModel"]["ventilation"];
          obj["nicu"] = val["data"]["dataModel"]["nicu"];
          obj["ototoxicMedication"] = val["data"]["dataModel"]["ototoxicMedication"];
          obj["familyHistoryHearingLoss"] = val["data"]["dataModel"]["familyHistoryHearingLoss"];
          obj["craniofacialAnomalies"] = val["data"]["dataModel"]["craniofacialAnomalies"];
          obj["featuresAssociated"] = val["data"]["dataModel"]["featuresAssociated"];
          obj["inUteroInfections"] = val["data"]["dataModel"]["inUteroInfections"];

          return obj;
        });

        this.isList = false;
        this.resultCount += hoistedMeta.length;
        this.resultLabel = "Number of Results: " + this.resultCount;
        this.loading = false;
        
        const obs = new Observable<any>((observer) => {
          observer.next(DeserializeArray(transform, ScreeningRegistryReport))
        });
        this.data = obs;

        this.exportToExcel(null);
      });
  }

  getRiskFactorAggregateAllReport = (filters? : object) => {
    this.dashboardService.getRiskFactorAggregate(filters).pipe(first())
    .subscribe(riskFactorAggregate => {
      let transform = [
        {
          riskFactors: "Hyperbilirubinemia Requiring Transfusion",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        },
        {
          riskFactors: "Ventilation More Than 48 Hours",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        },
        {
          riskFactors: "NICU Admission More Than 48 Hours",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        },
        {
          riskFactors: "Ototoxic Medication",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        },
        {
          riskFactors: "Family History of Permanent Childhood Hearing Loss",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        },
        {
          riskFactors: "Craniofacial Anomalies with Deformed Pinna or Ear Canal",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        },
        {
          riskFactors: "Features Associated with Syndrome",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        },
        {
          riskFactors: "In-Utero Infections",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        },
        {
          riskFactors: "TOTAL",
          categoryA: 0,
          categoryAPercent: "",
          categoryB: 0,
          categoryBPercent: "",
          categoryC: 0,
          categoryCPercent: "",
          categoryD: 0,
          categoryDPercent: "",
          total: 0
        }];
      for(var i=0; i<riskFactorAggregate["results"].length; i++) {
        if(typeof riskFactorAggregate["results"][i]["dataModel"]["meta"] !== 'undefined' && riskFactorAggregate["results"][i]["dataModel"]["meta"] != null && typeof riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"] !== 'undefined' && riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"] != null){
          if(riskFactorAggregate["results"][i]["dataModel"]["hyperbilirubinemia"] == "Yes"){
            switch (riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"]){
              case "A":
                transform[0].categoryA++;
                transform[0].total++;
                break;
              case "B":
                transform[0].categoryB++;
                transform[0].total++;
                break;
              case "C":
                transform[0].categoryC++;
                transform[0].total++;
                break;
              case "D":
                transform[0].categoryD++;
                transform[0].total++;
                break;
            }
          }
        
          if(riskFactorAggregate["results"][i]["dataModel"]["ventilation"] == "Yes"){
            switch (riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"]){
              case "A":
                transform[1].categoryA++;
                transform[1].total++;
                break;
              case "B":
                transform[1].categoryB++;
                transform[1].total++;
                break;
              case "C":
                transform[1].categoryC++;
                transform[1].total++;
                break;
              case "D":
                transform[1].categoryD++;
                transform[1].total++;
                break;
            }
          } 
          if(riskFactorAggregate["results"][i]["dataModel"]["nicu"] == "Yes"){
            switch (riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"]){
              case "A":
                transform[2].categoryA++;
                transform[2].total++;
                break;
              case "B":
                transform[2].categoryB++;
                transform[2].total++;
                break;
              case "C":
                transform[2].categoryC++;
                transform[2].total++;
                break;
              case "D":
                transform[2].categoryD++;
                transform[2].total++;
                break;
            }
          }
          if(riskFactorAggregate["results"][i]["dataModel"]["ototoxicMedication"] == "Yes"){
            switch (riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"]){
              case "A":
                transform[3].categoryA++;
                transform[3].total++;
                break;
              case "B":
                transform[3].categoryB++;
                transform[3].total++;
                break;
              case "C":
                transform[3].categoryC++;
                transform[3].total++;
                break;
              case "D":
                transform[3].categoryD++;
                transform[3].total++;
                break;
            }
          }
          if(riskFactorAggregate["results"][i]["dataModel"]["familyHistoryHearingLoss"] == "Yes"){
            switch (riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"]){
              case "A":
                transform[4].categoryA++;
                transform[4].total++;
                break;
              case "B":
                transform[4].categoryB++;
                transform[4].total++;
                break;
              case "C":
                transform[4].categoryC++;
                transform[4].total++;
                break;
              case "D":
                transform[4].categoryD++;
                transform[4].total++;
                break;
            }
          }
          if(riskFactorAggregate["results"][i]["dataModel"]["craniofacialAnomalies"] == "Yes"){
            switch (riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"]){
              case "A":
                transform[5].categoryA++;
                transform[5].total++;
                break;
              case "B":
                transform[5].categoryB++;
                transform[5].total++;
                break;
              case "C":
                transform[5].categoryC++;
                transform[5].total++;
                break;
              case "D":
                transform[5].categoryD++;
                transform[5].total++;
                break;
            }
          }
          if(riskFactorAggregate["results"][i]["dataModel"]["featuresAssociated"] == "Yes"){
            switch (riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"]){
              case "A":
                transform[6].categoryA++;
                transform[6].total++;
                break;
              case "B":
                transform[6].categoryB++;
                transform[6].total++;
                break;
              case "C":
                transform[6].categoryC++;
                transform[6].total++;
                break;
              case "D":
                transform[6].categoryD++;
                transform[6].total++;
                break;
            }
          }
          if(riskFactorAggregate["results"][i]["dataModel"]["inUteroInfections"] == "Yes"){
            switch (riskFactorAggregate["results"][i]["dataModel"]["meta"]["category"]){
              case "A":
                transform[7].categoryA++;
                transform[7].total++;
                break;
              case "B":
                transform[7].categoryB++;
                transform[7].total++;
                break;
              case "C":
                transform[7].categoryC++;
                transform[7].total++;
                break;
              case "D":
                transform[7].categoryD++;
                transform[7].total++;
                break;
            }
          }
        }
      }
      for(var i=0; i<8; i++) {
        transform[8].categoryA = transform[8].categoryA + transform[i].categoryA;
        transform[8].categoryB = transform[8].categoryB + transform[i].categoryB;
        transform[8].categoryC = transform[8].categoryC + transform[i].categoryC;
        transform[8].categoryD = transform[8].categoryD + transform[i].categoryD;
        transform[8].total = transform[8].total + transform[i].total;
      }
      if(transform[0].total != 0){
        transform[0].categoryAPercent = (transform[0].categoryA/transform[0].total*100).toFixed(2) + "%";
        transform[0].categoryBPercent = (transform[0].categoryB/transform[0].total*100).toFixed(2) + "%";
        transform[0].categoryCPercent = (transform[0].categoryC/transform[0].total*100).toFixed(2) + "%";
        transform[0].categoryDPercent = (transform[0].categoryD/transform[0].total*100).toFixed(2) + "%";
      }
      if(transform[1].total != 0){
        transform[1].categoryAPercent = (transform[1].categoryA/transform[1].total*100).toFixed(2) + "%";
        transform[1].categoryBPercent = (transform[1].categoryB/transform[1].total*100).toFixed(2) + "%";
        transform[1].categoryCPercent = (transform[1].categoryC/transform[1].total*100).toFixed(2) + "%";
        transform[1].categoryDPercent = (transform[1].categoryD/transform[1].total*100).toFixed(2) + "%";
      }
      if(transform[2].total != 0){
        transform[2].categoryAPercent = (transform[2].categoryA/transform[2].total*100).toFixed(2) + "%";
        transform[2].categoryBPercent = (transform[2].categoryB/transform[2].total*100).toFixed(2) + "%";
        transform[2].categoryCPercent = (transform[2].categoryC/transform[2].total*100).toFixed(2) + "%";
        transform[2].categoryDPercent = (transform[2].categoryD/transform[2].total*100).toFixed(2) + "%";
      }
      if(transform[3].total != 0){
        transform[3].categoryAPercent = (transform[3].categoryA/transform[3].total*100).toFixed(2) + "%";
        transform[3].categoryBPercent = (transform[3].categoryB/transform[3].total*100).toFixed(2) + "%";
        transform[3].categoryCPercent = (transform[3].categoryC/transform[3].total*100).toFixed(2) + "%";
        transform[3].categoryDPercent = (transform[3].categoryD/transform[3].total*100).toFixed(2) + "%";
      }
      if(transform[4].total != 0){
        transform[4].categoryAPercent = (transform[4].categoryA/transform[4].total*100).toFixed(2) + "%";
        transform[4].categoryBPercent = (transform[4].categoryB/transform[4].total*100).toFixed(2) + "%";
        transform[4].categoryCPercent = (transform[4].categoryC/transform[4].total*100).toFixed(2) + "%";
        transform[4].categoryDPercent = (transform[4].categoryD/transform[4].total*100).toFixed(2) + "%";
      }
      if(transform[5].total != 0){
        transform[5].categoryAPercent = (transform[5].categoryA/transform[5].total*100).toFixed(2) + "%";
        transform[5].categoryBPercent = (transform[5].categoryB/transform[5].total*100).toFixed(2) + "%";
        transform[5].categoryCPercent = (transform[5].categoryC/transform[5].total*100).toFixed(2) + "%";
        transform[5].categoryDPercent = (transform[5].categoryD/transform[5].total*100).toFixed(2) + "%";
      }
      if(transform[6].total != 0){
        transform[6].categoryAPercent = (transform[6].categoryA/transform[6].total*100).toFixed(2) + "%";
        transform[6].categoryBPercent = (transform[6].categoryB/transform[6].total*100).toFixed(2) + "%";
        transform[6].categoryCPercent = (transform[6].categoryC/transform[6].total*100).toFixed(2) + "%";
        transform[6].categoryDPercent = (transform[6].categoryD/transform[6].total*100).toFixed(2) + "%";
      }
      if(transform[7].total != 0){
        transform[7].categoryAPercent = (transform[7].categoryA/transform[7].total*100).toFixed(2) + "%";
        transform[7].categoryBPercent = (transform[7].categoryB/transform[7].total*100).toFixed(2) + "%";
        transform[7].categoryCPercent = (transform[7].categoryC/transform[7].total*100).toFixed(2) + "%";
        transform[7].categoryDPercent = (transform[7].categoryD/transform[7].total*100).toFixed(2) + "%";
      }
      if(transform[8].total != 0){
        transform[8].categoryAPercent = (transform[8].categoryA/transform[8].total*100).toFixed(2) + "%";
        transform[8].categoryBPercent = (transform[8].categoryB/transform[8].total*100).toFixed(2) + "%";
        transform[8].categoryCPercent = (transform[8].categoryC/transform[8].total*100).toFixed(2) + "%";
        transform[8].categoryDPercent = (transform[8].categoryD/transform[8].total*100).toFixed(2) + "%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, RiskFactorAggregateReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getMasterlistOfSurgicalInterventionReport = () => {
    var transform = []
    this.getMasterlistOfSurgicalInterventionReportRecursion(0, transform)
  }

  getMasterlistOfSurgicalInterventionReportRecursion(page: number, transform: any[]) {
    this.dashboardService.getMasterlistOfSurgicalIntervention(this.addFilters(), page).pipe(take(1))
      .subscribe(surgicalIntervention => {
        if(surgicalIntervention["count"] > 500 && page === 0) {
          if(confirm("Selected range will generate " + surgicalIntervention['count'] + " rows. Would you like to directly download instead?")) {
            console.log("Implement delete functionality here");
            this.reportShown = false;
            this.getMasterlistOfSurgicalInterventionReportDownload(this.addFilters());
            return
          }
        }
      
      if(surgicalIntervention["results"].length == 0 && page > 0)
          return
      transform = transform.concat(surgicalIntervention["results"].map(val => {
        let obj = {};
        obj["dateOfImplantation"] = moment.unix(val["dataModel"]["dateOfImplantation"]/1000).format('MM/DD/YYYY');
        obj["hospitalName"] = val["dataModel"]["hospitalName"];
        obj["psgc"] = val["dataModel"]["fullAddress"];
        obj["surgeonsName"] = val["dataModel"]["surgeonsName"];
        obj["clinicalAudiologistsName"] = val["dataModel"]["clinicalAudiologistsName"];
        obj["laterality"] = val["dataModel"]["laterality"];
        obj["type"] = val["dataModel"]["type"];
        obj["model"] = val["dataModel"]["model"];
        obj["serialNumber"] = val["dataModel"]["serialNumber"];
        obj["diagnosis"] = val["dataModel"]["diagnosis"];
        obj["firstCaseRate"] = val["dataModel"]["firstCaseRate"];
        obj["secondCaseRate"] = val["dataModel"]["secondCaseRate"];
        obj["procedureFirst"] = val["dataModel"]["procedureFirst"];
        obj["procedureSecond"] = val["dataModel"]["procedureSecond"];
        let complicationConvert = (val["dataModel"]["withComplication"] == "true") ? "With" : "None";
        obj["withComplication"] = complicationConvert;
        let timingConvert = "";
        switch (val["dataModel"]["timing"]){
          case "within 24 hours":
            timingConvert = "Within 24 Hours"
            break;
          case "within 7 days":
            timingConvert = "Within 7 Days"
            break;
          case "within 1 month":
            timingConvert = "Within 1 Month"
            break;
          case "within 3 months":
            timingConvert = "Within 3 Months"
            break;
          case "within 6 months":
            timingConvert = "Within 6 Months"
            break;
          case "greater than 6 months":
            timingConvert = "Greater Than 6 Months"
            break;
          case undefined:
            timingConvert = ""
            break;
        }
        obj["timing"] = timingConvert;
        obj["description"] = val["dataModel"]["description"];
        obj["surgeonsNotes"] = val["dataModel"]["surgeonsNotes"];
        return obj;
      }));
      if(surgicalIntervention["results"].length == 0 && page == 0){
        let obj = {};
        obj["dateOfImplantation"] = "";
        transform.push(obj);
      }

      this.isList = false;
      this.resultCount += surgicalIntervention["results"].length;
      this.resultLabel = "Number of Results: " + this.resultCount;
      // this.resultLabel = "WORKS"
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, MasterlistOfSurgicalInterventionReport))
      });
      this.data = obs;

      this.getMasterlistOfSurgicalInterventionReportRecursion(++page, transform) 
    });
  }

  getMasterlistOfSurgicalInterventionReportDownload = (filters? : object) => {
    this.dashboardService.getMasterlistOfSurgicalIntervention(this.addFilters()).pipe(take(1))
      .subscribe(surgicalIntervention => {
        let transform = surgicalIntervention["results"].map(val => {
          let obj = {};
          obj["dateOfImplantation"] = moment.unix(val["dataModel"]["dateOfImplantation"]/1000).format('MM/DD/YYYY');
          obj["hospitalName"] = val["dataModel"]["hospitalName"];
          obj["psgc"] = val["dataModel"]["fullAddress"];
          obj["surgeonsName"] = val["dataModel"]["surgeonsName"];
          obj["clinicalAudiologistsName"] = val["dataModel"]["clinicalAudiologistsName"];
          obj["laterality"] = val["dataModel"]["laterality"];
          obj["type"] = val["dataModel"]["type"];
          obj["model"] = val["dataModel"]["model"];
          obj["serialNumber"] = val["dataModel"]["serialNumber"];
          obj["diagnosis"] = val["dataModel"]["diagnosis"];
          obj["firstCaseRate"] = val["dataModel"]["firstCaseRate"];
          obj["secondCaseRate"] = val["dataModel"]["secondCaseRate"];
          obj["procedureFirst"] = val["dataModel"]["procedureFirst"];
          obj["procedureSecond"] = val["dataModel"]["procedureSecond"];
          let complicationConvert = (val["dataModel"]["withComplication"] == "true") ? "With" : "None";
          obj["withComplication"] = complicationConvert;
          let timingConvert = "";
          switch (val["dataModel"]["timing"]){
            case "within 24 hours":
              timingConvert = "Within 24 Hours"
              break;
            case "within 7 days":
              timingConvert = "Within 7 Days"
              break;
            case "within 1 month":
              timingConvert = "Within 1 Month"
              break;
            case "within 3 months":
              timingConvert = "Within 3 Months"
              break;
            case "within 6 months":
              timingConvert = "Within 6 Months"
              break;
            case "greater than 6 months":
              timingConvert = "Greater Than 6 Months"
              break;
            case undefined:
              timingConvert = ""
              break;
          }
          obj["timing"] = timingConvert;
          obj["description"] = val["dataModel"]["description"];
          obj["surgeonsNotes"] = val["dataModel"]["surgeonsNotes"];
          return obj;
        });
      

      this.isList = false;
      this.resultCount += surgicalIntervention["results"].length;
      this.resultLabel = "Number of Results: " + this.resultCount;
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, MasterlistOfSurgicalInterventionReport))
      });
      this.data = obs;

      this.exportToExcel(null);
    });
  }

  getRiskFactorAggregateFacilityReport = (filters?: object) => {
    this.dashboardService.getRiskFactorAggregate(filters).pipe(first())
    .subscribe(riskFactorAggregate => {
      let transform = [
        {
          facilityName: "",
          riskFactor1: 0,
          riskFactor2: 0,
          riskFactor3: 0,
          riskFactor4: 0,
          riskFactor5: 0,
          riskFactor6: 0,
          riskFactor7: 0,
          riskFactor8: 0
        }];
      if(riskFactorAggregate["count"] != 0){
        for(var i=0; i<riskFactorAggregate["results"].length; i++) {
          if(riskFactorAggregate["results"][i]["dataModel"]["hearingScreeningCenter"] !== null){
            var idx = transform.findIndex(({facilityName}) => facilityName.toUpperCase() === riskFactorAggregate["results"][i]["dataModel"]["hearingScreeningCenter"].toUpperCase());
            let rF1 = 0, rF2 = 0, rF3 = 0, rF4 = 0, rF5 = 0, rF6 = 0, rF7 = 0, rF8 = 0;
            if(riskFactorAggregate["results"][i]["dataModel"]["hyperbilirubinemia"] == "Yes")
              rF1++;
            if(riskFactorAggregate["results"][i]["dataModel"]["ventilation"] == "Yes")
              rF2++;
            if(riskFactorAggregate["results"][i]["dataModel"]["nicu"] == "Yes")
              rF3++;
            if(riskFactorAggregate["results"][i]["dataModel"]["ototoxicMedication"] == "Yes")
              rF4++;
            if(riskFactorAggregate["results"][i]["dataModel"]["familyHistoryHearingLoss"] == "Yes")
              rF5++;
            if(riskFactorAggregate["results"][i]["dataModel"]["craniofacialAnomalies"] == "Yes")
              rF6++;
            if(riskFactorAggregate["results"][i]["dataModel"]["featuresAssociated"] == "Yes")
              rF7++;
            if(riskFactorAggregate["results"][i]["dataModel"]["inUteroInfections"] == "Yes")
              rF8++;

            if(idx < 0){
              transform.push({facilityName: riskFactorAggregate["results"][i]["dataModel"]["hearingScreeningCenter"], riskFactor1: rF1, riskFactor2: rF2, riskFactor3: rF3, riskFactor4: rF4, riskFactor5: rF5, riskFactor6: rF6, riskFactor7: rF7, riskFactor8: rF8});
            } else {
              transform[idx].riskFactor1 += rF1;
              transform[idx].riskFactor2 += rF2;
              transform[idx].riskFactor3 += rF3;
              transform[idx].riskFactor4 += rF4;
              transform[idx].riskFactor5 += rF5;
              transform[idx].riskFactor6 += rF6;
              transform[idx].riskFactor7 += rF7;
              transform[idx].riskFactor8 += rF8;
            }
          }
        }
        transform.splice(0,1);
      }
      this.isList = false;
      this.resultLabel = "Number of Results: " + (transform.length - 1);

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, RiskFactorAggregateByFacilityReport))
      });
      this.data = obs;
      
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getTotalNoOfScreeningPersonnelReport = (filters?) => {
    this.usersService.getAll().pipe(first())
    .subscribe(user => {
      let filteredUser = this.filterActiveReports(user.map(val => {
        let obj = {};
        obj["lastName"] = val["lastName"];
        obj["firstName"] = val["firstName"];
        obj["middleName"] = val["middleName"];
        if(val["nhsrcAccredDate"] != null)
          obj["accreditationValidity"] = moment.unix(val["nhsrcAccredDate"]/1000).format('MM/DD/YYYY');
        else
          obj["accreditationValidity"] = "";
        let categoryArray = [];
        let facilityNameArray = [];
        let facilityCodeArray = [];
        let psgcCodeArray = [];
        let roleArray = [];
        if(val["facilityAndRoles"] != null){
          for(var i=0; i<val["facilityAndRoles"].length; i++) {
            categoryArray.indexOf(val["facilityAndRoles"][i]["category"]) === -1 ? categoryArray.push(val["facilityAndRoles"][i]["category"]) : null;
            facilityNameArray.indexOf(val["facilityAndRoles"][i]["facility"]) === -1 ? facilityNameArray.push(val["facilityAndRoles"][i]["facility"]) : null;
            facilityCodeArray.indexOf(val["facilityAndRoles"][i]["facilityCode"]) === -1 ? facilityCodeArray.push(val["facilityAndRoles"][i]["facilityCode"]) : null;
            psgcCodeArray.indexOf(val["facilityAndRoles"][i]["psgcCode"]) === -1 ? psgcCodeArray.push(val["facilityAndRoles"][i]["psgcCode"]) : null;
            if(val["facilityAndRoles"][i]["roles"]){
              for(var j=0; j<val["facilityAndRoles"][i]["roles"].length; j++) {
                roleArray.indexOf(val["facilityAndRoles"][i]["roles"][j]) === -1 ? roleArray.push(val["facilityAndRoles"][i]["roles"][j]) : null;
              }
            }
          }
        }
        obj["category"] = categoryArray;
        obj["facility"] = facilityNameArray;
        obj["facilityCode"] = facilityCodeArray;
        obj["periodDate"] = val["dateCreated"];
        obj["psgcCode"] = psgcCodeArray;
        obj["role"] = roleArray;
        obj["role"] = obj["role"].toString().replace(/,/g, ', ');
        return obj;
      }), filters);

      let transform = [
        {
          region: "National Capital Region (NCR)",
          count: 0,
          percent: ""
        },
        {
          region: "Cordillera Administrative Region (CAR)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION I (ILOCOS REGION)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION II (CAGAYAN VALLEY)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION III (CENTRAL LUZON)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION IV-A (CALABARZON)",
          count: 0,
          percent: ""
        },
        {
          region: "MIMAROPA REGION",
          count: 0,
          percent: ""
        },
        {
          region: "REGION V (BICOL REGION)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION VI (WESTERN VISAYAS)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION VII (CENTRAL VISAYAS)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION VIII (EASTERN VISAYAS)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION IX (ZAMBOANGA PENINSULA)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION X (NORTHERN MINDANAO)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION XI (DAVAO REGION)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION XII (SOCCSKSARGEN)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION XIII (Caraga)",
          count: 0,
          percent: ""
        },
        {
          region: "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)",
          count: 0,
          percent: ""
        },
        {
          region: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      
      for (var i = 0; i < filteredUser.length; i++) {
        
          for(var j=0; j<filteredUser[i]["facilityCode"].length; j++) {
            let psgcRegion = (Utils.psgcUtils.cleanPsgc(filteredUser[i]["psgcCode"][j]).substring(0,2))
            switch (psgcRegion){
              case "13":
                transform[0].count++;
                transform[17].count++;
                break;
              case "14":
                transform[1].count++;
                transform[17].count++;
                break;
              case "01":
                transform[2].count++;
                transform[17].count++;
                break;
              case "02":
                transform[3].count++;
                transform[17].count++;
                break;
              case "03":
                transform[4].count++;
                transform[17].count++;
                break;
              case "04":
                transform[5].count++;
                transform[17].count++;
                break;
              case "17":
                transform[6].count++;
                transform[17].count++;
                break;
              case "05":
                transform[7].count++;
                transform[17].count++;
                break;
              case "06":
                transform[8].count++;
                transform[17].count++;
                break;
              case "07":
                transform[9].count++;
                transform[17].count++;
                break;
              case "08":
                transform[10].count++;
                transform[17].count++;
                break;
              case "09":
                transform[11].count++;
                transform[17].count++;
                break;
              case "10":
                transform[12].count++;
                transform[17].count++;
                break;
              case "11":
                transform[13].count++;
                transform[17].count++;
                break;
              case "12":
                transform[14].count++;
                transform[17].count++;
                break;
              case "16":
                transform[15].count++;
                transform[17].count++;
                break;
              case "15":
                transform[16].count++;
                transform[17].count++;
                break;
            }
          }
        
      }
      if(transform[17].count != 0){
        transform[0].percent = (transform[0].count/transform[17].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[17].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[17].count*100).toFixed(2) + "%";
        transform[3].percent = (transform[3].count/transform[17].count*100).toFixed(2) + "%";
        transform[4].percent = (transform[4].count/transform[17].count*100).toFixed(2) + "%";
        transform[5].percent = (transform[5].count/transform[17].count*100).toFixed(2) + "%";
        transform[6].percent = (transform[6].count/transform[17].count*100).toFixed(2) + "%";
        transform[7].percent = (transform[7].count/transform[17].count*100).toFixed(2) + "%";
        transform[8].percent = (transform[8].count/transform[17].count*100).toFixed(2) + "%";
        transform[9].percent = (transform[9].count/transform[17].count*100).toFixed(2) + "%";
        transform[10].percent = (transform[10].count/transform[17].count*100).toFixed(2) + "%";
        transform[11].percent = (transform[11].count/transform[17].count*100).toFixed(2) + "%";
        transform[12].percent = (transform[12].count/transform[17].count*100).toFixed(2) + "%";
        transform[13].percent = (transform[13].count/transform[17].count*100).toFixed(2) + "%";
        transform[14].percent = (transform[14].count/transform[17].count*100).toFixed(2) + "%";
        transform[15].percent = (transform[15].count/transform[17].count*100).toFixed(2) + "%";
        transform[16].percent = (transform[16].count/transform[17].count*100).toFixed(2) + "%";
        transform[17].percent = "100.00%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TotalNoOfScreeningPersonnelReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getNoOfScreeningFacilitiesByRegionReport = (filters?: object) => {
    this.dashboardService.getFacilityStatus(filters).pipe(first())
    .subscribe(facilities => {
      let transform = [
        {
          region: "National Capital Region (NCR)",
          count: 0,
          percent: ""
        },
        {
          region: "Cordillera Administrative Region (CAR)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION I (ILOCOS REGION)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION II (CAGAYAN VALLEY)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION III (CENTRAL LUZON)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION IV-A (CALABARZON)",
          count: 0,
          percent: ""
        },
        {
          region: "MIMAROPA REGION",
          count: 0,
          percent: ""
        },
        {
          region: "REGION V (BICOL REGION)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION VI (WESTERN VISAYAS)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION VII (CENTRAL VISAYAS)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION VIII (EASTERN VISAYAS)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION IX (ZAMBOANGA PENINSULA)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION X (NORTHERN MINDANAO)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION XI (DAVAO REGION)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION XII (SOCCSKSARGEN)",
          count: 0,
          percent: ""
        },
        {
          region: "REGION XIII (Caraga)",
          count: 0,
          percent: ""
        },
        {
          region: "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)",
          count: 0,
          percent: ""
        },
        {
          region: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<facilities["results"].length; i++) {
        transform[17].count++;
        let psgcRegion = (Utils.psgcUtils.cleanPsgc(facilities["results"][i]["dataModel"]["psgcCode"]).substring(0,2))
        switch (psgcRegion){
          case "13":
            transform[0].count++;
            break;
          case "14":
            transform[1].count++;
            break;
          case "01":
            transform[2].count++;
            break;
          case "02":
            transform[3].count++;
            break;
          case "03":
            transform[4].count++;
            break;
          case "04":
            transform[5].count++;
            break;
          case "17":
            transform[6].count++;
            break;
          case "05":
            transform[7].count++;
            break;
          case "06":
            transform[8].count++;
            break;
          case "07":
            transform[9].count++;
            break;
          case "08":
            transform[10].count++;
            break;
          case "09":
            transform[11].count++;
            break;
          case "10":
            transform[12].count++;
            break;
          case "11":
            transform[13].count++;
            break;
          case "12":
            transform[14].count++;
            break;
          case "16":
            transform[15].count++;
            break;
          case "15":
            transform[16].count++;
            break;
          default:
            this.editSection = true;
        }
      }
      if(transform[17].count != 0){
        transform[0].percent = (transform[0].count/transform[17].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[17].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[17].count*100).toFixed(2) + "%";
        transform[3].percent = (transform[3].count/transform[17].count*100).toFixed(2) + "%";
        transform[4].percent = (transform[4].count/transform[17].count*100).toFixed(2) + "%";
        transform[5].percent = (transform[5].count/transform[17].count*100).toFixed(2) + "%";
        transform[6].percent = (transform[6].count/transform[17].count*100).toFixed(2) + "%";
        transform[7].percent = (transform[7].count/transform[17].count*100).toFixed(2) + "%";
        transform[8].percent = (transform[8].count/transform[17].count*100).toFixed(2) + "%";
        transform[9].percent = (transform[9].count/transform[17].count*100).toFixed(2) + "%";
        transform[10].percent = (transform[10].count/transform[17].count*100).toFixed(2) + "%";
        transform[11].percent = (transform[11].count/transform[17].count*100).toFixed(2) + "%";
        transform[12].percent = (transform[12].count/transform[17].count*100).toFixed(2) + "%";
        transform[13].percent = (transform[13].count/transform[17].count*100).toFixed(2) + "%";
        transform[14].percent = (transform[14].count/transform[17].count*100).toFixed(2) + "%";
        transform[15].percent = (transform[15].count/transform[17].count*100).toFixed(2) + "%";
        transform[16].percent = (transform[16].count/transform[17].count*100).toFixed(2) + "%";
        transform[17].percent = "100.00%";
      }
      this.loading = false;
      // observer.next(DeserializeArray(transform, NoOfScreeningFacilitiesByRegionReport))
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, NoOfScreeningFacilitiesByRegionReport))
      });
      this.data = obs;
    });
  }

  getReasonsForNotFittingHearingAidDevicesReport = (filters? : object) => {
    this.dashboardService.getReasonsForNotFittingHearingAidDevices(filters).pipe(first())
    .subscribe(hearingAid => {
      let hoistedMeta = hearingAid["results"].map(val => {
        let obj = {};
        obj["data"] = val;
        return obj;
      });
      
      let transform = [
        {
          reason: "Meningitis",
          count: 0,
          percent: ""
        },
        {
          reason: "Atresia",
          count: 0,
          percent: ""
        },
        {
          reason: "Others",
          count: 0,
          percent: ""
        },
        {
          reason: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<hoistedMeta.length; i++) {
        var idx = transform.findIndex(({reason}) => reason === hoistedMeta[i]["data"]["dataModel"]['notDoneReason']);
        if(idx > -1)
          transform[idx].count++;
      }
      transform[3].count = transform[0].count + transform[1].count + transform[2].count;
      if(transform[3].count != 0){
        transform[0].percent = (transform[0].count/transform[3].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[3].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[3].count*100).toFixed(2) + "%";
        transform[3].percent = "100.00%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, ReasonsForNotFittingHearingAidDevicesReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

getEducationalPlacementOfPatientsReport = (filters? : object) => {
    this.dashboardService.getEducationalPlacementOfPatients(filters).pipe(first())
    .subscribe(educationalPlacement => {
      let transform = [
        {
          educationalPlacement: "Full Mainstream",
          count: 0,
          percent: ""
        },
        {
          educationalPlacement: "Partial Mainstream",
          count: 0,
          percent: ""
        },
        {
          educationalPlacement: "Special Education",
          count: 0,
          percent: ""
        },
        {
          educationalPlacement: "Alternative Learning System",
          count: 0,
          percent: ""
        },
        {
          educationalPlacement: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<educationalPlacement["results"].length; i++) {
        if(educationalPlacement["results"][i]["dataModel"]["educationalPlacementMainstream"] == true){
          if(educationalPlacement["results"][i]["dataModel"]["mainstream"] == "full"){
            transform[0].count++;
            transform[4].count++;
          }
          else if(educationalPlacement["results"][i]["dataModel"]["mainstream"] == "partial"){
            transform[1].count++;
            transform[4].count++;
          }
        }
        if(educationalPlacement["results"][i]["dataModel"]["educationalPlacementSpecialEducation"] == true){
          transform[2].count++;
          transform[4].count++;            
        }
        if(educationalPlacement["results"][i]["dataModel"]["educationalPlacementALS"] == true){
          transform[3].count++;
          transform[4].count++;            
        }
      }
      if(transform[4].count != 0){
        transform[0].percent = (transform[0].count/transform[4].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[4].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[4].count*100).toFixed(2) + "%";
        transform[3].percent = (transform[3].count/transform[4].count*100).toFixed(2) + "%";
        transform[4].percent = "100.00%";
      }

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, EducationalPlacementOfPatientsReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

getNoScreenedAccScreeningMethodReport = (filters? : object) => {
    this.dashboardService.getNoScreenedAccScreeningMethod(filters).pipe(first())
    .subscribe(hearingScreening => {
      let transform = [
        {
          screeningMethod: "AABR",
          count: 0,
          percent: ""
        },
        {
          screeningMethod: "OAE",
          count: 0,
          percent: ""
        },
        {
          screeningMethod: "Others",
          count: 0,
          percent: ""
        },
        {
          screeningMethod: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<hearingScreening["results"].length; i++) {
        switch (hearingScreening["results"][i]["dataModel"]["methodOfScreening"]){
          case "AABR":
            transform[0].count++;
            transform[3].count++;
            break;
          case "OAE":
            transform[1].count++;
            transform[3].count++;
            break;
          case "Others":
            transform[2].count++;
            transform[3].count++;
            break;
        }
      }
      if(transform[3].count != 0){
        transform[0].percent = (transform[0].count/transform[3].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[3].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[3].count*100).toFixed(2) + "%";
        transform[3].percent = "100.00%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, NoScreenedAccScreeningMethodReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }
  
  getNoScreenedAccScreeningMethodOptimized = (filters? : object) => {
    this.dashboardService.getNoScreenedAccScreeningMethodOptimized(filters).pipe(first())
    .subscribe(res => {
      let transform = [
        {
          screeningMethod: "AABR",
          count: res[0],
          percent: ""
        },
        {
          screeningMethod: "OAE",
          count: res[1],
          percent: ""
        },
        {
          screeningMethod: "Others",
          count: res[2],
          percent: ""
        },
        {
          screeningMethod: "TOTAL",
          count: res[0] + res[1] + res[2],
          percent: ""
        }
      ]

      if(transform[3].count != 0){
        transform[0].percent = (transform[0].count/transform[3].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[3].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[3].count*100).toFixed(2) + "%";
        transform[3].percent = "100.00%";
      }

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, NoScreenedAccScreeningMethodReport))
      });

      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);      
    });
  }

  getNoOfPatientsInPathwayAReport = (filters? : object) => {
    this.dashboardService.getNoOfPatientsInPathwayA(filters).pipe(first())
    .subscribe(noOfPatientsInPathwayA => {
      let filteredPathway = noOfPatientsInPathwayA["results"].filter(path => typeof path["dataModel"]["patient"] !== 'undefined' && path["dataModel"]["patient"] != null);
      filteredPathway = filteredPathway.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t["dataModel"]["patient"]["_modelId"] === value["dataModel"]["patient"]["_modelId"]
        ))
      );
      let transform = [
        {
          age: "Less Than 12 Months",
          count: 0,
          percent: ""
        },
        {
          age: "12 Months and 1 Day to 18 Months",
          count: 0,
          percent: ""
        },
        {
          age: "18 Months and 1 Day to 30 Months",
          count: 0,
          percent: ""
        },
        {
          age: "30 Months and 1 Day to 42 Months",
          count: 0,
          percent: ""
        },
        {
          age: "42 Months and 1 Day to 60 Months",
          count: 0,
          percent: ""
        },
        {
          age: "60 Months and 1 Day Above",
          count: 0,
          percent: ""
        },
        {
          age: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<filteredPathway.length; i++) {
        transform[6].count++;
        let currentAge = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(filteredPathway[i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days');
        switch (true){
          case currentAge < 365:
            transform[0].count++;
            break;
          case currentAge >= 365 && currentAge < 547:
            transform[1].count++;
            break;
          case currentAge >= 547 && currentAge < 912:
            transform[2].count++;
            break;
          case currentAge >= 912 && currentAge < 1277:
            transform[3].count++;
            break;
          case currentAge >= 1277 && currentAge < 1826:
            transform[4].count++;
            break;
          case currentAge >= 1826:
            transform[5].count++;
            break;
        }
      }
      if(transform[6].count != 0){
        transform[0].percent = (transform[0].count/transform[6].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[6].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[6].count*100).toFixed(2) + "%";
        transform[3].percent = (transform[3].count/transform[6].count*100).toFixed(2) + "%";
        transform[4].percent = (transform[4].count/transform[6].count*100).toFixed(2) + "%";
        transform[5].percent = (transform[5].count/transform[6].count*100).toFixed(2) + "%";
        transform[6].percent = "100.00%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, NoOfPatientsInPathwayAReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getNoOfPatientsWhoReachedSpeechBananaReport = (filters? : object) => {
    this.dashboardService.getNoOfPatientsWhoReachedSpeechBananaStringBean(filters).pipe(first())
    .subscribe(speechBanana => {
      console.log(speechBanana)
      let transform = [
        {
          hearingLoss: "Unilateral",
          switchToSix: 0,
          switchToSixPercent: "",
          sixToOneYear: 0,
          sixToOneYearPercent: "",
          oneYearAndBeyond: 0,
          oneYearAndBeyondPercent: "",
          total: 0
        },
        {
          hearingLoss: "Bilateral",
          switchToSix: 0,
          switchToSixPercent: "",
          sixToOneYear: 0,
          sixToOneYearPercent: "",
          oneYearAndBeyond: 0,
          oneYearAndBeyondPercent: "",
          total: 0
        },
        {
          hearingLoss: "TOTAL (Unilateral and Bilateral)",
          switchToSix: 0,
          switchToSixPercent: "",
          sixToOneYear: 0,
          sixToOneYearPercent: "",
          oneYearAndBeyond: 0,
          oneYearAndBeyondPercent: "",
          total: 0
        }
      ]
      for(var i=0; i<speechBanana.length; i++) {
        if(speechBanana[i][0]["dataModel"]["dateLeftEar"] == speechBanana[i][0]["dataModel"]["dateRightEar"] && (speechBanana[i][0]["dataModel"]["dateLeftEar"] != null && speechBanana[i][0]["dataModel"]["dateRightEar"] != null)){
          let leftBanana = false, rightBanana = false, earliestDate = 0;
          for(var j=0; j<speechBanana[i][1].length; j++){
            if(speechBanana[i][1][j]["dataModel"]["leftSB250Hz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB500Hz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB1kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB15kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB2kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB3kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB4kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB6kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB8kHz"] == true){
              leftBanana= true;
              if(earliestDate == 0)
                earliestDate = speechBanana[i][1][j]["dataModel"]["dateOfTesting"];
            }
            if(speechBanana[i][1][j]["dataModel"]["rightSB250Hz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB500Hz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB1kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB15kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB2kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB3kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB4kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB6kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB8kHz"] == true){
              rightBanana= true;
              if(earliestDate == 0)
                earliestDate = speechBanana[i][1][j]["dataModel"]["dateOfTesting"];
            }
          }
          if(leftBanana == true && rightBanana == true){
            let switchToCurrent = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(earliestDate/1000).format('YYYY-MM-DD')), 'days');
            switch (true){
              case switchToCurrent < 182:
                transform[1].switchToSix++;
                transform[1].total++;
                transform[2].switchToSix++;
                transform[2].total++;
                break;
              case switchToCurrent >= 182 && switchToCurrent < 366:
                transform[1].sixToOneYear++;
                transform[1].total++;
                transform[2].sixToOneYear++;
                transform[2].total++;
                break;
              case switchToCurrent >= 366:
                transform[1].oneYearAndBeyond++;
                transform[1].total++;
                transform[2].oneYearAndBeyond++;
                transform[2].total++;
                break;
            }
            earliestDate = 0;
          } else
            earliestDate = 0;
        } else if(speechBanana[i][0]["dataModel"]["dateLeftEar"] != speechBanana[i][0]["dataModel"]["dateRightEar"]){
          let leftBanana = false, rightBanana = false, earliestDateLeft = 0, earliestDateRight = 0;
          for(var j=0; j<speechBanana[i][1].length; j++){
            if(speechBanana[i][1][j]["dataModel"]["leftSB250Hz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB500Hz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB1kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB15kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB2kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB3kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB4kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB6kHz"] == true || speechBanana[i][1][j]["dataModel"]["leftSB8kHz"] == true){
              leftBanana= true;
              if(earliestDateLeft == 0)
                earliestDateLeft = speechBanana[i][1][j]["dataModel"]["dateOfTesting"];
            }
            if(speechBanana[i][1][j]["dataModel"]["rightSB250Hz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB500Hz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB1kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB15kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB2kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB3kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB4kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB6kHz"] == true || speechBanana[i][1][j]["dataModel"]["rightSB8kHz"] == true){
              rightBanana= true;
              if(earliestDateRight == 0)
                earliestDateRight = speechBanana[i][1][j]["dataModel"]["dateOfTesting"];
            }
          }
          if(leftBanana == true){
            let switchToCurrent = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(earliestDateLeft/1000).format('YYYY-MM-DD')), 'days');
            switch (true){
              case switchToCurrent < 182:
                transform[0].switchToSix++;
                transform[0].total++;
                transform[2].switchToSix++;
                transform[2].total++;
                break;
              case switchToCurrent >= 182 && switchToCurrent < 366:
                transform[0].sixToOneYear++;
                transform[0].total++;
                transform[2].sixToOneYear++;
                transform[2].total++;
                break;
              case switchToCurrent >= 366:
                transform[0].oneYearAndBeyond++;
                transform[0].total++;
                transform[2].oneYearAndBeyond++;
                transform[2].total++;
                break;
            }
            earliestDateLeft = 0;
          } else
            earliestDateLeft = 0;
          if(rightBanana == true){
            let switchToCurrent = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(earliestDateRight/1000).format('YYYY-MM-DD')), 'days');
            switch (true){
              case switchToCurrent < 182:
                transform[0].switchToSix++;
                transform[0].total++;
                transform[2].switchToSix++;
                transform[2].total++;
                break;
              case switchToCurrent >= 182 && switchToCurrent < 366:
                transform[0].sixToOneYear++;
                transform[0].total++;
                transform[2].sixToOneYear++;
                transform[2].total++;
                break;
              case switchToCurrent >= 366:
                transform[0].oneYearAndBeyond++;
                transform[0].total++;
                transform[2].oneYearAndBeyond++;
                transform[2].total++;
                break;
            }
            earliestDateRight = 0;
          } else
          earliestDateRight = 0;
        }
      }
      if(transform[0].total != 0){
        transform[0].switchToSixPercent = (transform[0].switchToSix/transform[0].total*100).toFixed(2) + "%";
        transform[0].sixToOneYearPercent = (transform[0].sixToOneYear/transform[0].total*100).toFixed(2) + "%";
        transform[0].oneYearAndBeyondPercent = (transform[0].oneYearAndBeyond/transform[0].total*100).toFixed(2) + "%";
      }
      if(transform[1].total != 0){
        transform[1].switchToSixPercent = (transform[1].switchToSix/transform[1].total*100).toFixed(2) + "%";
        transform[1].sixToOneYearPercent = (transform[1].sixToOneYear/transform[1].total*100).toFixed(2) + "%";
        transform[1].oneYearAndBeyondPercent = (transform[1].oneYearAndBeyond/transform[1].total*100).toFixed(2) + "%";
      }
      if(transform[2].total != 0){
        transform[2].switchToSixPercent = (transform[2].switchToSix/transform[2].total*100).toFixed(2) + "%";
        transform[2].sixToOneYearPercent = (transform[2].sixToOneYear/transform[2].total*100).toFixed(2) + "%";
        transform[2].oneYearAndBeyondPercent = (transform[2].oneYearAndBeyond/transform[2].total*100).toFixed(2) + "%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, NoOfPatientsWhoReachedSpeechBananaReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getNoOfPatientsWhoReachedSpeechStringBeanReport = (filters? : object) => {
    this.dashboardService.getNoOfPatientsWhoReachedSpeechBananaStringBean(filters).pipe(first())
    .subscribe(speechStringBean => {
      let transform = [
        {
          hearingLoss: "Unilateral",
          switchToSixMonths: 0,
          switchToSixMonthsPercent: "",
          sixMonthsToOneYear: 0,
          sixMonthsToOneYearPercent: "",
          oneYearToTwoYears: 0,
          oneYearToTwoYearsPercent: "",
          twoYearsAndBeyond: 0,
          twoYearsAndBeyondPercent: "",
          total: 0
        },
        {
          hearingLoss: "Bilateral",
          switchToSixMonths: 0,
          switchToSixMonthsPercent: "",
          sixMonthsToOneYear: 0,
          sixMonthsToOneYearPercent: "",
          oneYearToTwoYears: 0,
          oneYearToTwoYearsPercent: "",
          twoYearsAndBeyond: 0,
          twoYearsAndBeyondPercent: "",
          total: 0
        },
        {
          hearingLoss: "TOTAL (Unilateral and Bilateral)",
          switchToSixMonths: 0,
          switchToSixMonthsPercent: "",
          sixMonthsToOneYear: 0,
          sixMonthsToOneYearPercent: "",
          oneYearToTwoYears: 0,
          oneYearToTwoYearsPercent: "",
          twoYearsAndBeyond: 0,
          twoYearsAndBeyondPercent: "",
          total: 0
        }
      ]
      for(var i=0; i<speechStringBean.length; i++) {
        if(speechStringBean[i][0]["dataModel"]["dateLeftEar"] == speechStringBean[i][0]["dataModel"]["dateRightEar"] && (speechStringBean[i][0]["dataModel"]["dateLeftEar"] != null && speechStringBean[i][0]["dataModel"]["dateRightEar"] != null)){
          let leftBanana = false, rightBanana = false, earliestDate = 0;
          for(var j=0; j<speechStringBean[i][1].length; j++){
            if(speechStringBean[i][1][j]["dataModel"]["leftSSB250Hz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB500Hz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB1kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB15kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB2kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB3kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB4kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB6kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB8kHz"] == true){
              leftBanana= true;
              if(earliestDate == 0)
                earliestDate = speechStringBean[i][1][j]["dataModel"]["dateOfTesting"];
            }
            if(speechStringBean[i][1][j]["dataModel"]["rightSSB250Hz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB500Hz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB1kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB15kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB2kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB3kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB4kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB6kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB8kHz"] == true){
              rightBanana= true;
              if(earliestDate == 0)
                earliestDate = speechStringBean[i][1][j]["dataModel"]["dateOfTesting"];
            }
          }
          if(leftBanana == true && rightBanana == true){
            let switchToCurrent = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(earliestDate/1000).format('YYYY-MM-DD')), 'days');
            switch (true){
              case switchToCurrent < 182:
                transform[1].switchToSixMonths++;
                transform[1].total++;
                transform[2].switchToSixMonths++;
                transform[2].total++;
                break;
              case switchToCurrent >= 182 && switchToCurrent < 365:
                transform[1].sixMonthsToOneYear++;
                transform[1].total++;
                transform[2].sixMonthsToOneYear++;
                transform[2].total++;
                break;
              case switchToCurrent >= 365 && switchToCurrent < 731:
                transform[1].oneYearToTwoYears++;
                transform[1].total++;
                transform[2].oneYearToTwoYears++;
                transform[2].total++;
                break;
              case switchToCurrent >= 731:
                transform[1].oneYearToTwoYears++;
                transform[1].total++;
                transform[2].oneYearToTwoYears++;
                transform[2].total++;
                break;
            }
            earliestDate = 0;
          } else
            earliestDate = 0;
        } else if(speechStringBean[i][0]["dataModel"]["dateLeftEar"] != speechStringBean[i][0]["dataModel"]["dateRightEar"]){
          let leftBanana = false, rightBanana = false, earliestDateLeft = 0, earliestDateRight = 0;
          for(var j=0; j<speechStringBean[i][1].length; j++){
            if(speechStringBean[i][1][j]["dataModel"]["leftSSB250Hz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB500Hz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB1kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB15kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB2kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB3kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB4kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB6kHz"] == true || speechStringBean[i][1][j]["dataModel"]["leftSSB8kHz"] == true){
              leftBanana= true;
              if(earliestDateLeft == 0)
                earliestDateLeft = speechStringBean[i][1][j]["dataModel"]["dateOfTesting"];
              
            } 
              
            if(speechStringBean[i][1][j]["dataModel"]["rightSSB250Hz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB500Hz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB1kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB15kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB2kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB3kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB4kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB6kHz"] == true || speechStringBean[i][1][j]["dataModel"]["rightSSB8kHz"] == true){
              rightBanana= true;
              if(earliestDateRight == 0)
                earliestDateRight = speechStringBean[i][1][j]["dataModel"]["dateOfTesting"];
            } 
              
          }
          if(leftBanana == true){
            let switchToCurrent = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(earliestDateLeft/1000).format('YYYY-MM-DD')), 'days');
            switch (true){
              case switchToCurrent < 182:
                transform[0].switchToSixMonths++;
                transform[0].total++;
                transform[2].switchToSixMonths++;
                transform[2].total++;
                break;
              case switchToCurrent >= 182 && switchToCurrent < 365:
                transform[0].sixMonthsToOneYear++;
                transform[0].total++;
                transform[2].sixMonthsToOneYear++;
                transform[2].total++;
                break;
              case switchToCurrent >= 365 && switchToCurrent < 731:
                transform[0].oneYearToTwoYears++;
                transform[0].total++;
                transform[2].oneYearToTwoYears++;
                transform[2].total++;
                break;
              case switchToCurrent >= 731:
                transform[0].oneYearToTwoYears++;
                transform[0].total++;
                transform[2].oneYearToTwoYears++;
                transform[2].total++;
                break;
            }
            earliestDateLeft = 0;
          } else
            earliestDateLeft = 0;
          if(rightBanana == true){
            let switchToCurrent = moment(moment().format('YYYY-MM-DD')).diff(moment(moment.unix(earliestDateRight/1000).format('YYYY-MM-DD')), 'days');
            switch (true){
              case switchToCurrent < 182:
                transform[0].switchToSixMonths++;
                transform[0].total++;
                transform[2].switchToSixMonths++;
                transform[2].total++;
                break;
              case switchToCurrent >= 182 && switchToCurrent < 365:
                transform[0].sixMonthsToOneYear++;
                transform[0].total++;
                transform[2].sixMonthsToOneYear++;
                transform[2].total++;
                break;
              case switchToCurrent >= 365 && switchToCurrent < 731:
                transform[0].oneYearToTwoYears++;
                transform[0].total++;
                transform[2].oneYearToTwoYears++;
                transform[2].total++;
                break;
              case switchToCurrent >= 731:
                transform[0].oneYearToTwoYears++;
                transform[0].total++;
                transform[2].oneYearToTwoYears++;
                transform[2].total++;
                break;
            }
            earliestDateRight = 0;
          } else
            earliestDateRight = 0;
        }
      }
      if(transform[0].total != 0){
        transform[0].switchToSixMonthsPercent = (transform[0].switchToSixMonths/transform[0].total*100).toFixed(2) + "%";
        transform[0].sixMonthsToOneYearPercent = (transform[0].sixMonthsToOneYear/transform[0].total*100).toFixed(2) + "%";
        transform[0].oneYearToTwoYearsPercent = (transform[0].oneYearToTwoYears/transform[0].total*100).toFixed(2) + "%";
        transform[0].twoYearsAndBeyondPercent = (transform[0].twoYearsAndBeyond/transform[0].total*100).toFixed(2) + "%";
      }
      if(transform[1].total != 0){
        transform[1].switchToSixMonthsPercent = (transform[1].switchToSixMonths/transform[1].total*100).toFixed(2) + "%";
        transform[1].sixMonthsToOneYearPercent = (transform[1].sixMonthsToOneYear/transform[1].total*100).toFixed(2) + "%";
        transform[1].oneYearToTwoYearsPercent = (transform[1].oneYearToTwoYears/transform[1].total*100).toFixed(2) + "%";
        transform[1].twoYearsAndBeyondPercent = (transform[1].twoYearsAndBeyond/transform[1].total*100).toFixed(2) + "%";
      }
      if(transform[2].total != 0){
        transform[2].switchToSixMonthsPercent = (transform[2].switchToSixMonths/transform[2].total*100).toFixed(2) + "%";
        transform[2].sixMonthsToOneYearPercent = (transform[2].sixMonthsToOneYear/transform[2].total*100).toFixed(2) + "%";
        transform[2].oneYearToTwoYearsPercent = (transform[2].oneYearToTwoYears/transform[2].total*100).toFixed(2) + "%";
        transform[2].twoYearsAndBeyondPercent = (transform[2].twoYearsAndBeyond/transform[2].total*100).toFixed(2) + "%";
      }

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, NoOfPatientsWhoReachedSpeechStringBeanReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getScreeningResultsReport = (filters? : object) => {
    this.dashboardService.getScreeningResults(filters).pipe(first())
    .subscribe(screeningResults => {
      let transform = [
        {
          result: "Bilateral Pass",
          initial: 0,
          initialPercent: "",
          rescreen: 0,
          rescreenPercent: "",
          total: 0
        },
        {
          result: "Bilateral Refer",
          initial: 0,
          initialPercent: "",
          rescreen: 0,
          rescreenPercent: "",
          total: 0
        },
        {
          result: "Unilateral Refer",
          initial: 0,
          initialPercent: "",
          rescreen: 0,
          rescreenPercent: "",
          total: 0
        },
        {
          result: "Not Performed - Bilateral",
          initial: 0,
          initialPercent: "",
          rescreen: 0,
          rescreenPercent: "",
          total: 0
        },
        {
          result: "Not Performed - Unilateral",
          initial: 0,
          initialPercent: "",
          rescreen: 0,
          rescreenPercent: "",
          total: 0
        },
        {
          result: "Not Performed - Refusal",
          initial: null,
          initialPercent: "",
          rescreen: null,
          rescreenPercent: "",
          total: 0
        }
      ]
      transform[5].total = screeningResults["status"];
      
      for(var i=0; i<screeningResults["hearingScreening"].length; i++) {
        if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "pass" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "pass"){
          transform[0].total++;
          if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
            transform[0].initial++;
          else
            transform[0].rescreen++;
        } else if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "refer" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "refer"){
          transform[1].total++;
          if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
            transform[1].initial++;
          else
            transform[1].rescreen++;
        } else if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "not performed" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "not performed"){
          transform[3].total++;
          if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
            transform[3].initial++;
          else
            transform[3].rescreen++;
        } else if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "refer" || screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "refer"){
          transform[2].total++;
          if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
            transform[2].initial++;
          else
            transform[2].rescreen++;
        }
        if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "not performed" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] != "not performed"){
          transform[4].total++;
          if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
            transform[4].initial++;
          else
            transform[4].rescreen++;
        } else if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] != "not performed" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "not performed"){
          transform[4].total++;
          if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
            transform[4].initial++;
          else
            transform[4].rescreen++;
        }
      }
      if(transform[0].total != 0){
        transform[0].initialPercent = (transform[0].initial/transform[0].total*100).toFixed(2) + "%";
        transform[0].rescreenPercent = (transform[0].rescreen/transform[0].total*100).toFixed(2) + "%";
      }
      if(transform[1].total != 0){
        transform[1].initialPercent = (transform[1].initial/transform[1].total*100).toFixed(2) + "%";
        transform[1].rescreenPercent = (transform[1].rescreen/transform[1].total*100).toFixed(2) + "%";
      }
      if(transform[2].total != 0){
        transform[2].initialPercent = (transform[2].initial/transform[2].total*100).toFixed(2) + "%";
        transform[2].rescreenPercent = (transform[2].rescreen/transform[2].total*100).toFixed(2) + "%";
      }
      if(transform[3].total != 0){
        transform[3].initialPercent = (transform[3].initial/transform[3].total*100).toFixed(2) + "%";
        transform[3].rescreenPercent = (transform[3].rescreen/transform[3].total*100).toFixed(2) + "%";
      }
      if(transform[4].total != 0){
        transform[4].initialPercent = (transform[4].initial/transform[4].total*100).toFixed(2) + "%";
        transform[4].rescreenPercent = (transform[4].rescreen/transform[4].total*100).toFixed(2) + "%";
      }
      
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, ScreeningResultsReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getScreeningResultsOptimized = (filters? : object) => {
    this.dashboardService.getScreeningResultsOptimized(filters).pipe(first())
    .subscribe(res => {
      let transform = [
        {
          result: "Bilateral Pass",
          initial: res[0],
          initialPercent: "",
          rescreen: res[1],
          rescreenPercent: "",
          total: res[0] + res[1]
        },
        {
          result: "Bilateral Refer",
          initial: res[2],
          initialPercent: "",
          rescreen: res[3],
          rescreenPercent: "",
          total: res[2] + res[3]
        },
        {
          result: "Unilateral Refer",
          initial: res[4],
          initialPercent: "",
          rescreen: res[5],
          rescreenPercent: "",
          total: res[4] + res[5]
        },
        {
          result: "Not Performed - Bilateral",
          initial: res[6],
          initialPercent: "",
          rescreen: res[7],
          rescreenPercent: "",
          total: res[6] + res[7]
        },
        {
          result: "Not Performed - Unilateral",
          initial: res[8],
          initialPercent: "",
          rescreen: res[9],
          rescreenPercent: "",
          total: res[8] + res[9]
        },
        {
          result: "Not Performed - Refusal",
          initial: null,
          initialPercent: "",
          rescreen: null,
          rescreenPercent: "",
          total: res[10]
        }
      ]
      
      if(transform[0].total != 0){
        transform[0].initialPercent = (transform[0].initial/transform[0].total*100).toFixed(2) + "%";
        transform[0].rescreenPercent = (transform[0].rescreen/transform[0].total*100).toFixed(2) + "%";
      }
      if(transform[1].total != 0){
        transform[1].initialPercent = (transform[1].initial/transform[1].total*100).toFixed(2) + "%";
        transform[1].rescreenPercent = (transform[1].rescreen/transform[1].total*100).toFixed(2) + "%";
      }
      if(transform[2].total != 0){
        transform[2].initialPercent = (transform[2].initial/transform[2].total*100).toFixed(2) + "%";
        transform[2].rescreenPercent = (transform[2].rescreen/transform[2].total*100).toFixed(2) + "%";
      }
      if(transform[3].total != 0){
        transform[3].initialPercent = (transform[3].initial/transform[3].total*100).toFixed(2) + "%";
        transform[3].rescreenPercent = (transform[3].rescreen/transform[3].total*100).toFixed(2) + "%";
      }
      if(transform[4].total != 0){
        transform[4].initialPercent = (transform[4].initial/transform[4].total*100).toFixed(2) + "%";
        transform[4].rescreenPercent = (transform[4].rescreen/transform[4].total*100).toFixed(2) + "%";
      }
      
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, ScreeningResultsReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getTimelinessOfScreeningRecusionIni = () =>{
    // var transform = []
    var transform = [
      {
        compliance: "Compliant",
        count: 0,
        percent: ""
      },
      {
        compliance: "Non-Compliant",
        count: 0,
        percent: ""
      },
      {
        compliance: "TOTAL",
        count: 0,
        percent: ""
      }
    ]
    this.getTimelinessOfScreeningRecusion(0, transform)
  }

  getTimelinessOfScreeningRecusion(page: number, transform: any[]) {
    this.dashboardService.getAllTimelinessOfScreeningRecur(page, this.addFilters()).pipe(first())
      .subscribe(speechPathologySession => {
        if(speechPathologySession["results"].length == 0 && page > 0)
          return
        
        let filteredScreening = speechPathologySession["results"].filter(path => typeof path["dataModel"]["patient"] !== 'undefined' && path["dataModel"]["patient"] != null);
    
        
        for(var i=0; i<filteredScreening.length; i++) {
          transform[2].count++;
  
          if(moment(moment.unix(filteredScreening[i]["dataModel"]["dateOfScreening"]/1000).format('YYYY-MM-DD')).diff(moment(moment.unix(filteredScreening[i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days') > 30)
            transform[1].count++
          else
            transform[0].count++
          
        }
        if(transform[2].count != 0){
          transform[0].percent = (transform[0].count/transform[2].count*100).toFixed(2) + "%";
          transform[1].percent = (transform[1].count/transform[2].count*100).toFixed(2) + "%";
          transform[2].percent = "100.00%";
        }

        this.isList = false;
        
        const obs = new Observable<any>((observer) => {
          observer.next(DeserializeArray(transform, TimelinessOfScreeningReport))
        });
        this.data = obs;

        this.getTimelinessOfScreeningRecusion(++page, transform) 
    });
  }

  getTimelinessOfScreeningReport = (filters? : object) => {
    this.dashboardService.getTimelinessOfScreening(filters).pipe(first())
    .subscribe(timelinessOfScreening => {
      let filteredScreening = timelinessOfScreening["results"].filter(path => typeof path["dataModel"]["patient"] !== 'undefined' && path["dataModel"]["patient"] != null);
      
      let transform = [
        {
          compliance: "Compliant",
          count: 0,
          percent: ""
        },
        {
          compliance: "Non-Compliant",
          count: 0,
          percent: ""
        },
        {
          compliance: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<filteredScreening.length; i++) {
        transform[2].count++;

        if(moment(moment.unix(filteredScreening[i]["dataModel"]["dateOfScreening"]/1000).format('YYYY-MM-DD')).diff(moment(moment.unix(filteredScreening[i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days') > 30)
          transform[1].count++
        else
          transform[0].count++
        
      }
      if(transform[2].count != 0){
        transform[0].percent = (transform[0].count/transform[2].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[2].count*100).toFixed(2) + "%";
        transform[2].percent = "100.00%";
      }

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TimelinessOfScreeningReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getTimelinessOfScreeningOptimized = (filters? : object) => {
    this.dashboardService.getTimelinessOfScreeningOptimized(filters).pipe(first())
    .subscribe(res => {
      let transform = [
        {
          compliance: "Compliant",
          count: res[0],
          percent: ""
        },
        {
          compliance: "Non-Compliant",
          count: res[1],
          percent: ""
        },
        {
          compliance: "TOTAL",
          count: res[0] + res[1],
          percent: ""
        }
      ]
      
      if(transform[2].count != 0){
        transform[0].percent = (transform[0].count/transform[2].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[2].count*100).toFixed(2) + "%";
        transform[2].percent = "100.00%";
      }

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TimelinessOfScreeningReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  // this.dashboardService.getNoScreenedAccScreeningMethodOptimized(filters).pipe(first())
  //   .subscribe(res => {
  //     let transform = [
  //       {
  //         screeningMethod: "AABR",
  //         count: res[0],
  //         percent: ""
  //       },
  //       {
  //         screeningMethod: "OAE",
  //         count: res[1],
  //         percent: ""
  //       },
  //       {
  //         screeningMethod: "Others",
  //         count: res[2],
  //         percent: ""
  //       },
  //       {
  //         screeningMethod: "TOTAL",
  //         count: res[0] + res[1] + res[2],
  //         percent: ""
  //       }
  //     ]

  //     if(transform[3].count != 0){
  //       transform[0].percent = (transform[0].count/transform[3].count*100).toFixed(2) + "%";
  //       transform[1].percent = (transform[1].count/transform[3].count*100).toFixed(2) + "%";
  //       transform[2].percent = (transform[2].count/transform[3].count*100).toFixed(2) + "%";
  //       transform[3].percent = "100.00%";
  //     }

  //     this.loading = false;
      
  //     const obs = new Observable<any>((observer) => {
  //       observer.next(DeserializeArray(transform, NoScreenedAccScreeningMethodReport))
  //     });

  //     this.data = obs;
  //     this.downloadReportGroupByRegion(filters["psgc"]);      
  //   });

  getTimelinessOfABRConfirmatoryTestingReport = (filters? : object) => {
    this.dashboardService.getTimelinessOfABRConfirmatoryTesting(filters).pipe(first())
    .subscribe(timelinessOfConfTest => {
      let transform = [
        {
          compliance: "Compliant",
          count: 0,
          percent: ""
        },
        {
          compliance: "Non-Compliant",
          count: 0,
          percent: ""
        },
        {
          compliance: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<timelinessOfConfTest["results"].length; i++) {
        transform[2].count++;

        if(moment(moment.unix(timelinessOfConfTest["results"][i]["dataModel"]["dateOfTest"]/1000).format('YYYY-MM-DD')).diff(moment(moment.unix(timelinessOfConfTest["results"][i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days') > 90)
          transform[1].count++
        else
          transform[0].count++
      }
      if(transform[2].count != 0){
        transform[0].percent = (transform[0].count/transform[2].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[2].count*100).toFixed(2) + "%";
        transform[2].percent = "100.00%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TimelinessOfABRConfirmatoryTestingReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
      this.stopTimer();
    });
  }

  getTimelinessOfABRConfirmatoryTestingOriginReport = (filters? : object) => {
    this.dashboardService.getTimelinessOfABRConfirmatoryTesting(filters).pipe(first())
    .subscribe(timelinessOfConfTest => {
      let transform = [
        {
          facility: "qwe",
          compliant: 0,
          nonCompliant: 0,
          total: 0
        }];

      for(var i=0; i<timelinessOfConfTest["results"].length; i++) {
        if(timelinessOfConfTest["results"][i]["dataModel"]["patient"]['facilityCode']){
          var idx = transform.findIndex(({facility}) => facility.toUpperCase() === timelinessOfConfTest["results"][i]["dataModel"]["patient"]['facilityCode'].toUpperCase());
          if(idx > -1){
            if(moment(moment.unix(timelinessOfConfTest["results"][i]["dataModel"]["dateOfTest"]/1000).format('YYYY-MM-DD')).diff(moment(moment.unix(timelinessOfConfTest["results"][i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days') > 90)
              transform[idx].nonCompliant++;
            else
              transform[idx].compliant++;
            transform[idx].total++;
          }
            
          else{
            if(moment(moment.unix(timelinessOfConfTest["results"][i]["dataModel"]["dateOfTest"]/1000).format('YYYY-MM-DD')).diff(moment(moment.unix(timelinessOfConfTest["results"][i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days') > 90)
              // transform[idx].compliant++;
              transform.push({facility: timelinessOfConfTest["results"][i]["dataModel"]["patient"]['facilityCode'], compliant: 0, nonCompliant: 1, total: 1})
            else
              transform.push({facility: timelinessOfConfTest["results"][i]["dataModel"]["patient"]['facilityCode'], compliant: 1, nonCompliant: 0, total: 1})
          }
        }
      }
      transform.splice(0,1);
      transform = transform.sort(function(a,b) {
        return b.total - a.total;
      })

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TimelinessOfABRConfirmatoryTestingOriginReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
      this.stopTimer();
    });
  }

  getTimelinessOfAudiologicalInterventionReport = (filters? : object) => {
    this.dashboardService.getTimelinessOfAudiologicalIntervention(filters).pipe(first())
    .subscribe(timelinessOfAudInt => {
      // let hoistedMeta = timelinessOfAudInt["results"].map(val => {
      //   let obj = {};
      //   obj["data"] = val;
      //   return obj;
      // });
      let transform = [
        {
          compliance: "Compliant",
          count: 0,
          percent: ""
        },
        {
          compliance: "Non-Compliant",
          count: 0,
          percent: ""
        },
        {
          compliance: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<timelinessOfAudInt["results"].length; i++) {
          transform[2].count++;

          if(moment(moment.unix(timelinessOfAudInt["results"][i]["dataModel"]["dateOfFirstFitting"]/1000).format('YYYY-MM-DD')).diff(moment(moment.unix(timelinessOfAudInt["results"][i]["dataModel"]["patient"]["dateOfBirth"]/1000).format('YYYY-MM-DD')), 'days') > 180)
            transform[1].count++
          else
            transform[0].count++
      }
      if(transform[2].count != 0){
        transform[0].percent = (transform[0].count/transform[2].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[2].count*100).toFixed(2) + "%";
        transform[2].percent = "100.00%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, TimelinessOfAudiologicalInterventionReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getInterventionApproachOfPatientsReport = (filters? : object) => {
    this.dashboardService.getInterventionApproachOfPatients(filters).pipe(first())
    .subscribe(speechPathologySession => {
      let transform = [
        {
          interventionApproach: "Auditory Verbal Therapy",
          count: 0,
          percent: ""
        },
        {
          interventionApproach: "Auditory Oral Therapy",
          count: 0,
          percent: ""
        },
        {
          interventionApproach: "Total Communication",
          count: 0,
          percent: ""
        },
        {
          interventionApproach: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<speechPathologySession["results"].length; i++) {
        switch (speechPathologySession["results"][i]["dataModel"]["interventionApproach"]){
          case "Auditory Verbal Therapy":
            transform[0].count++;
            transform[3].count++;
            break;
          case "Auditory Oral Therapy":
            transform[1].count++;
            transform[3].count++;
            break;
          case "Total Communication":
            transform[2].count++;
            transform[3].count++;
            break;
        }
      }
      if(transform[3].count != 0){
        transform[0].percent = (transform[0].count/transform[3].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[3].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[3].count*100).toFixed(2) + "%";
        transform[3].percent = "100.00%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, InterventionApproachOfPatientsReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getNoOfPatientsWhoUnderwentCochlearImplantReport = (filters? : object) => {
    this.dashboardService.getNoOfPatientsWhoUnderwentCochlearImplant(filters).pipe(first())
    .subscribe(noOfPatients => {
      let transform = [
        {
          brand: "Advanced Bionics",
          count: 0,
          percent: ""
        },
        {
          brand: "Cochlear",
          count: 0,
          percent: ""
        },
        {
          brand: "MED-EL",
          count: 0,
          percent: ""
        },
        {
          brand: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<noOfPatients["results"].length; i++) {
        switch (noOfPatients["results"][i]["dataModel"]["explantedDevice"]){
          case "Advanced Bionics":
            transform[0].count++;
            transform[3].count++;
            break;
          case "Cochlear":
            transform[1].count++;
            transform[3].count++;
            break;
          case "MED-EL":
            transform[2].count++;
            transform[3].count++;
            break;
        }
      }
      if(transform[3].count != 0){
        transform[0].percent = (transform[0].count/transform[3].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[3].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[3].count*100).toFixed(2) + "%";
        transform[3].percent = "100.00%";
      }

      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, NoOfPatientsWhoUnderwentCochlearImplantReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  getAreasOfConcernReport = (filters? : object) => {
    this.dashboardService.getAreasOfConcern(filters).pipe(first())
    .subscribe(areasOfConcern => {
      let transform = [
        {
          areasOfConcern: "GC - Global/Cognitive",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "ELA - Expressive Language and Articulation",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "RL - Receptive Language",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "FM - Fine Motor",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "GM - Gross Motor",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "B - Behavior",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "SE - Social-Emotional",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "SH - Self-Help",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "S - School",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "O/H - Other/Health Concerns",
          count: 0,
          percent: ""
        },
        {
          areasOfConcern: "TOTAL",
          count: 0,
          percent: ""
        }
      ]
      for(var i=0; i<areasOfConcern["results"].length; i++) {
        if(areasOfConcern["results"][i]["dataModel"]["aOFGC"] == true){
          transform[0].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFELA"] == true){
          transform[1].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFRL"] == true){
          transform[2].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFFM"] == true){
          transform[3].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFGM"] == true){
          transform[4].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFB"] == true){
          transform[5].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFSE"] == true){
          transform[6].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFSH"] == true){
          transform[7].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFS"] == true){
          transform[8].count++;
          transform[10].count++;
        }
        if(areasOfConcern["results"][i]["dataModel"]["aOFOH"] == true){
          transform[9].count++;
          transform[10].count++;
        }
      }
      if(transform[10].count != 0){
        transform[0].percent = (transform[0].count/transform[10].count*100).toFixed(2) + "%";
        transform[1].percent = (transform[1].count/transform[10].count*100).toFixed(2) + "%";
        transform[2].percent = (transform[2].count/transform[10].count*100).toFixed(2) + "%";
        transform[3].percent = (transform[3].count/transform[10].count*100).toFixed(2) + "%";
        transform[4].percent = (transform[4].count/transform[10].count*100).toFixed(2) + "%";
        transform[5].percent = (transform[5].count/transform[10].count*100).toFixed(2) + "%";
        transform[6].percent = (transform[6].count/transform[10].count*100).toFixed(2) + "%";
        transform[7].percent = (transform[7].count/transform[10].count*100).toFixed(2) + "%";
        transform[8].percent = (transform[8].count/transform[10].count*100).toFixed(2) + "%";
        transform[9].percent = (transform[9].count/transform[10].count*100).toFixed(2) + "%";
        transform[10].percent = "100.00%";
      }
      this.loading = false;
      
      const obs = new Observable<any>((observer) => {
        observer.next(DeserializeArray(transform, AreasOfConcernReport))
      });
      this.data = obs;
      this.downloadReportGroupByRegion(filters["psgc"]);
    });
  }

  async getReportsGroupByRegion(report) {
    const regions = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17"];
    for(let region in regions) {
      let filter_with_region = this.addFilters(regions[region])
      await this.resolveAfter2Seconds(report, filter_with_region);
    }
  }

  resolveAfter2Seconds(report, filter_with_region) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          report(filter_with_region)
        );
      }, 1000);
    });
  }

  downloadReportGroupByRegion(region?: string){
    if(this.areaFilter.filterType === AreaOptions.regionDL)
      this.exportToExcel(region);
  }

  printShort(printSectionId: string){
    let popupWinindow
    let innerContents = document.getElementById(printSectionId).innerHTML;
    popupWinindow = window.open('', '_blank', 'scrollbars=no');
    popupWinindow.document.open();
    // popupWinindow.document.title() = "ENNHSR";
    popupWinindow.document.write('<html><head><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"><link rel="stylesheet" type="text/css" href="styles.css"><link rel="stylesheet" href="styles.9365bbfd58e498c89d23.css"><style>.mat-table{width:100% !important}</style></head><body > <div class="print-header row m-0 w-100 p-3" *ngIf="printWithHeader"><div class="col-2 p-0"><img src="assets/img/NHSRC-logo-HD.png" style="height: 36px;"></div><div class="col-8 p-0 d-flex align-items-end justify-content-center"><h4 class="mb-0"><b>' + this.reportTitle + '</b></h4></div><div class="col-2 p-0 d-flex align-items-end justify-content-end"><b style="pointer-events: none;"><i class="mdi mdi-ear-hearing"></i>ENNHSR</b></div></div>' + innerContents + '<script>window.onbeforeprint = function(){ document.title = "ENNHSR";};window.onafterprint = function(){ window.close()};window.print();</script></html>');
    popupWinindow.document.close()
  }

  convertToPrintData(data) {
    let values = [];
    data.forEach(e => {
      values.push(Object.values(e));
    })
    var headers = data[0][tableSymbol].columns.map(e => { return { prop: e.key, name: e.columnName }; });
    var removeFirst = false;
    headers.forEach((e,i) => {
      if(e.name == undefined) {
        removeFirst = true;
        headers[i]['name'] = headers[i-1]['name']+' - Female';
        headers[i-1]['name'] += ' - Male';
      }
    });
    if(removeFirst) {
      data.splice(0,1);
    }
    var out = [headers,data];
    return out;
  }

  print2CSV(){
    this.data.subscribe(val =>{
      let stringJson = JSON.stringify(val);
      this.dashboardService.downloadFile(stringJson, val[0][tableSymbol].columns.map(e => {return e.columnName}), (this.reportTitle).replace(/\s+/g, '').trim().toLowerCase() + "-" + Math.floor(new Date().getTime() / 1000));
    });
  }

  exportToExcel(region?: string) {
    this.data.subscribe(val =>{
      let dataForExcel = [];
      let stringJson = JSON.stringify(val);
      JSON.parse(stringJson).forEach((row: any) => {
        dataForExcel.push(Object.values(row))
      })

      // let filters: {
      //   date: string | null,
      //   area: string | null,
      //   facility: string | null
      // }

      let filters = {
        date: "",
        area: "",
        facility: ""
      };
      
      switch (this.dateFilter.filterType){
        case "duration":
          if(this.dateFilter.duration.from && this.dateFilter.duration.to !== 'undefined'){
            filters.date = "dated " + moment(this.dateFilter.duration.from).format('MM/DD/YYYY') + " to " + moment(this.dateFilter.duration.to).format('MM/DD/YYYY');
          }
  
          break;
        case "periodical":
          switch (this.dateFilter.periodical.period){
            case "monthly":
              filters.date = "dated " + moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.month + "-01", "YYYY-MM-DD").startOf('month').format('MM/DD/YYYY') + " to " + moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.month + "-01", "YYYY-MM-DD").endOf('month').format('MM/DD/YYYY');

              break;
            case "quarterly":
              filters.date = "dated " + moment(this.dateFilter.periodical.year + "-" + this.dateFilter.periodical.quarter + "-01", "YYYY-MM-DD").startOf('month').format('MM/DD/YYYY') + " to " + moment(this.dateFilter.periodical.year + "-" + (parseInt(this.dateFilter.periodical.quarter) + 2) + "-01", "YYYY-MM-DD").endOf('month').format('MM/DD/YYYY');

              break;
            case "yearly":
              filters.date = "dated " + moment(this.dateFilter.periodical.year, "YYYY").startOf('year').format('MM/DD/YYYY') + " to " + moment(this.dateFilter.periodical.year, "YYYY").endOf('year').format('MM/DD/YYYY');

              break;
          }
      }
  
      switch (this.areaFilter.filterType){
        case AreaOptions.City:
          filters.area = " located in " + this.areaFilter.city + ", " + this.areaFilter.province + ", " + this.areaFilter.region;
          
          break;
        case AreaOptions.Province:
          filters.area = " located in " + this.areaFilter.province + ", " + this.areaFilter.region;
          
          break;
        case AreaOptions.Region:
          filters.area = " located in " + this.areaFilter.region;
          
          break;
        case AreaOptions.regionDL:
          filters.area = " located in Region " + region;
          
          break;
      }
  
      switch (this.facilityFilter.filterType){
        case "category":
          let selectedCategory: string[] = [];
          if(this.facilityFilter.category.all === false){
            if(this.facilityFilter.category.a === true)
              selectedCategory.push("A")
            if(this.facilityFilter.category.b === true)
              selectedCategory.push("B")
            if(this.facilityFilter.category.c === true)
              selectedCategory.push("C")
            if(this.facilityFilter.category.d === true)
              selectedCategory.push("D")
          } else
            selectedCategory.push("A","B","C","D");
  
          filters.facility = " from Category " + selectedCategory.toString().replace(/,/g, ', ');
          
          break;
        case "facilityCode":
          filters.facility = " from Facility" + this.facilityFilter.facilityCode;
  
          break;
      }
      
      let reportData = {
        title: this.reportTitle,
        data: dataForExcel,
        headers: val[0][tableSymbol].columns.map(e => {return e.columnName}),
        filters: filters.date + filters.area + filters.facility
      }
      this.dashboardService.exportExcel(reportData);
    });
  }
}