import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Sync } from '@app/shared/_models/sync';
import { FieldUpdate } from '@app/shared/_models/field-update';
import Utils from '@app/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  public facilitySubject: BehaviorSubject<any>;
  public facility: Observable<any>;

  constructor(
    public router: Router,
    public http: HttpClient
  ) {
      this.facilitySubject =  new BehaviorSubject<any>(null);//new BehaviorSubject<any>(JSON.parse(localStorage.getItem('facilityRecords')));
      this.facility = this.facilitySubject.asObservable();
   }

  public get recordValue() {
    return this.facilitySubject.value;
  }

  public get recordObservable() {
    return this.facility;
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/facility/${id}?~resolveRef=1`).pipe(map(res => {
      this.facilitySubject.next(res);
      return res;
    }));
  }

  getByFacilityName(name: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/facility?facilityName=${name}&~resolveRef=1`)
    .pipe(map(res => {
      //localStorage.setItem('facilityRecords', JSON.stringify(res));
      this.facilitySubject.next(res);
      return res;
    }));
  }
  
  protected push(sync: Sync){
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
  }

  updateDetails(facility: any, modelId: string, versionId: string) {
    var original = this.facilitySubject.value;
    facility["meta.category"] = facility["category"];
    facility["meta.facilityCode"] = facility["nshrcFacilityAccreditationNumber"];
    facility["meta.psgcCode"] = facility["psgcCode"];
    facility["meta.periodDate"] = Date.now();
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], 'facility', original['modelId'], original['versionId']), 
                              Utils.convertToSync(facility, 'facility', modelId, versionId));
    return this.push(sync)
    .pipe(
      map(res => {
        var records = this.facilitySubject.value;
        records.dataModel = facility;
        records.versionId = res['updatedModelVersionIds']['facility'][modelId];
        //localStorage.setItem('facilityRecords', JSON.stringify(records));
        this.facilitySubject.next(records);
        return res;
      })
    );
   }
}
