import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SessionsService } from '../sessions.service';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { first } from 'rxjs/operators';
import { PrintService } from '@app/shared/print/print.service';
import { PatientService } from '@app/patient/patient.service';

@Component({ template: '' })
export class AddEditSessionsComponent implements OnInit {
      
  constructor(
    public dialog: MatDialog,
    private location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
      this.openDialog();
  }

  openDialog(): void {
      const dialogRef = this.dialog.open(AddEditSessionsComponentDialog, {
          data: {
              formBuilder: this.formBuilder,
              id: this.route.snapshot.params['id']
          }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.location.back();
      });
  }

}

@Component({ templateUrl: 'add-edit-sessions.component.html' })
export class AddEditSessionsComponentDialog implements OnInit {
        
    form: FormGroup;
    loading = false;
    submitted = false;

    editable: boolean;
    editing: boolean;
    printable: boolean;

    isAddMode: any;

    id: string;
    patientId: string;
    modelId: string;
    versionId: string;

    constructor(        
        public dialogRef: MatDialogRef<AddEditSessionsComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder,
            id: string
        },
        public dialog: MatDialog,
        private sessionsService: SessionsService, 
        private formBuilder: FormBuilder, 
        private alertService: AlertService,
        public printService: PrintService,
        private patientService: PatientService
    ) { 
        this.id = data.id;
    }

    ngOnInit() {
        this.patientId = this.patientService.recordSubject.getValue()["modelId"];

        this.isAddMode = !this.id;
    
        this.editable = this.id? true:false;
        this.editing = false;
        this.printable = true;
    
        this.form = this.formBuilder.group({
          patient: [''],
          audiologist: [''],
          hospital: [''],
          date: ['', Validators.required],
          status: ['', Validators.required],
          dateDone: [''],
          reason: [''],
          capScore: [''],
          peachScore: [''],
          leftEar: [''],
          rightEar: [''],
          brandLeft: [''],
          brandRight: [''],
          brandLeftOthersSpecify: [''],
          brandRightOthersSpecify: [''],
          serialLeft: [''],
          serialRight: [''],
          elecLeft1: [''],
          elecLeft2: [''],
          elecLeft3: [''],
          elecLeft4: [''],
          elecLeft5: [''],
          elecLeft6: [''],
          elecLeft7: [''],
          elecLeft8: [''],
          elecLeft9: [''],
          elecLeft10: [''],
          elecLeft11: [''],
          elecLeft12: [''],
          elecLeft13: [''],
          elecLeft14: [''],
          elecLeft15: [''],
          elecLeft16: [''],
          elecLeft17: [''],
          elecLeft18: [''],
          elecLeft19: [''],
          elecLeft20: [''],
          elecLeft21: [''],
          elecLeft22: [''],
          elecLeft23: [''],
          elecLeft24: [''],
          eLeft1: [''],
          eLeft2: [''],
          eLeft3: [''],
          eLeft4: [''],
          eLeft5: [''],
          eLeft6: [''],
          eLeft7: [''],
          eLeft8: [''],
          eLeft9: [''],
          eLeft10: [''],
          eLeft11: [''],
          eLeft12: [''],
          eLeft13: [''],
          eLeft14: [''],
          eLeft15: [''],
          eLeft16: [''],
          eLeft17: [''],
          eLeft18: [''],
          eLeft19: [''],
          eLeft20: [''],
          eLeft21: [''],
          eLeft22: [''],
          eLeft23: [''],
          eLeft24: [''],
          elecRight1: [''],
          elecRight2: [''],
          elecRight3: [''],
          elecRight4: [''],
          elecRight5: [''],
          elecRight6: [''],
          elecRight7: [''],
          elecRight8: [''],
          elecRight9: [''],
          elecRight10: [''],
          elecRight11: [''],
          elecRight12: [''],
          elecRight13: [''],
          elecRight14: [''],
          elecRight15: [''],
          elecRight16: [''],
          elecRight17: [''],
          elecRight18: [''],
          elecRight19: [''],
          elecRight20: [''],
          elecRight21: [''],
          elecRight22: [''],
          elecRight23: [''],
          elecRight24: [''],
          eRight1: [''],
          eRight2: [''],
          eRight3: [''],
          eRight4: [''],
          eRight5: [''],
          eRight6: [''],
          eRight7: [''],
          eRight8: [''],
          eRight9: [''],
          eRight10: [''],
          eRight11: [''],
          eRight12: [''],
          eRight13: [''],
          eRight14: [''],
          eRight15: [''],
          eRight16: [''],
          eRight17: [''],
          eRight18: [''],
          eRight19: [''],
          eRight20: [''],
          eRight21: [''],
          eRight22: [''],
          eRight23: [''],
          eRight24: [''],
          additionalNotes: [''],
          dateOfTesting: [''],
          typeOfAT: [''],
          specifyType: [''],
          testingConditions: [''],
          leftEarAT: [''],
          left250Hz: [''],
          left500Hz: [''],
          left1kHz: [''],
          left15kHz: [''],
          left2kHz: [''],
          left3kHz: [''],
          left4kHz: [''],
          left6kHz: [''],
          left8kHz: [''],
          leftSB250Hz: [''],
          leftSB500Hz: [''],
          leftSB1kHz: [''],
          leftSB15kHz: [''],
          leftSB2kHz: [''],
          leftSB3kHz: [''],
          leftSB4kHz: [''],
          leftSB6kHz: [''],
          leftSB8kHz: [''],
          leftSSB250Hz: [''],
          leftSSB500Hz: [''],
          leftSSB1kHz: [''],
          leftSSB15kHz: [''],
          leftSSB2kHz: [''],
          leftSSB3kHz: [''],
          leftSSB4kHz: [''],
          leftSSB6kHz: [''],
          leftSSB8kHz: [''],
          rightEarAT: [''],
          right250Hz: [''],
          right500Hz: [''],
          right1kHz: [''],
          right15kHz: [''],
          right2kHz: [''],
          right3kHz: [''],
          right4kHz: [''],
          right6kHz: [''],
          right8kHz: [''],
          rightSB250Hz: [''],
          rightSB500Hz: [''],
          rightSB1kHz: [''],
          rightSB15kHz: [''],
          rightSB2kHz: [''],
          rightSB3kHz: [''],
          rightSB4kHz: [''],
          rightSB6kHz: [''],
          rightSB8kHz: [''],
          rightSSB250Hz: [''],
          rightSSB500Hz: [''],
          rightSSB1kHz: [''],
          rightSSB15kHz: [''],
          rightSSB2kHz: [''],
          rightSSB3kHz: [''],
          rightSSB4kHz: [''],
          rightSSB6kHz: [''],
          rightSSB8kHz: [''],
          reliability: [''],
          fileUpload: [''],
          notes: ['']
        });
    
        if (!this.isAddMode) {
          var model = this.sessionsService.recordsValue["results"].find(x => x.modelId == this.id);
          this.versionId = model["versionId"];
          this.modelId = model["modelId"];
          this.form.patchValue(Utils.mapModelToForm(model, ['date','dateDone','dateOfTesting']));
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() { 
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createSessions();
        } else {
            this.updateSessions();
        }
    }

    createSessions() {
        this.form.get("patient").setValue(this.patientId)
        this.sessionsService.add(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Sessions added successfully', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      
    updateSessions() {
        this.sessionsService.update(this.form.value, this.modelId, this.versionId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}
