import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddEditComponentForm } from '@app/admin/facilities/add-edit/add-edit.component';
import { LayoutComponent } from './layout/layout.component';
import { SealComponent } from './seal/seal.component';

const usersModule = () => import('src/app/admin/users/users.module').then(x => x.UsersModule);
const machinesModule = () => import('./machines/machines.module').then(x => x.MachinesModule);

const routes: Routes = [
  { path: '' , component: LayoutComponent ,
    children: [
      { path: 'users', loadChildren: usersModule },
      { path: 'details', component: AddEditComponentForm },
      { path: 'machines', loadChildren: machinesModule },
      { path: 'seal', component: SealComponent }
    ]  
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UnitRoutingModule { }
