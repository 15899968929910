<!-- <h1 *ngIf="isAddMode">Add User</h1>
<h1 *ngIf="!isAddMode">Edit User</h1> -->
<div class="container">
    <div class="card">
        <div class="card-header">
            Concepts
        </div>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="primaryName">Primary Name</label>
                            <input type="text" formControlName="primaryName" class="form-control" />
                        </div>
                        <div class="form-group col-4">
                            <label for="shortName">Short Name</label>
                            <input type="text" formControlName="shortName" class="form-control" />
                        </div>
                        <div class="form-group col-4 d-flex align-items-end">
                            <mat-checkbox formControlName="retired" >
                                <label class="form-check-label" for="retired">
                                    Retired
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-8">
                            <label for="description">Description</label>
                            <input type="text" formControlName="description" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="dataClass">Data Class</label>
                            <input type="text" formControlName="dataClass" class="form-control" />
                        </div>
                        <div class="form-group col-4">
                            <label for="dataType">Data Type</label>
                            <select class="form-control" formControlName="dataType">                                
                                <option value="Double">Double</option>
                                <option value="boolean">Boolean</option>
                                <option value="Float">Float</option>
                                <option value="Integer">Integer</option>
                                <option value="string">String</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <mat-checkbox formControlName="isSet" >
                                <label class="form-check-label" for="isSet">
                                    Is Set
                                </label>
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-4">
                            <mat-checkbox formControlName="required" >
                                <label class="form-check-label" for="required">
                                    Required
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="form-group col-4">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                    <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                </div>
            </form>
        </div>
    </div>
</div>