import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class TimelinessOfScreeningReport{
    @autoserializeAs(String)
    @Column({
        columnName: 'Compliance'
    })
    compliance: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Count'
    })
    count: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Percent'
    })
    percent: string;
}
