import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { SelectorService } from '@app/shared/selector/selector.service';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';
import { User } from '@app/user/_models/user';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { formsAnimations } from './layout.animations';

@Component({
  selector: 'app-forms',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    formsAnimations
  ] 
})
export class LayoutComponent implements OnInit {
  items = [
    { title: 'Back to Top', fragment: 'form1' },
    { title: 'Patient Details', fragment: 'form1' },
    { title: 'Hearing Screening', fragment: 'form2' },
    { title: 'Confirmatory Testing', fragment: 'form3' },
    { title: 'Hearing Aid/ Devices', fragment: 'form4' },
    { title: 'Speech Therapy', fragment: 'form5' },
    { title: 'Developmental Monitoring', fragment: 'form6' },
    { title: 'Surgical Hearing Interventions', fragment: 'form7' },
    { title: 'Cochlear Implant Programming', fragment: 'form8' }
  ];

  id: string;
  patient$: Observable<any>;
  
  showForm$: Observable<boolean[]>;
  allRoles: string[] = ['Admin', 'User', 'Unit Manager', 'Screener', 'Audiologist', 'Speech Therapist', 
  'Pedia', 'ENT Surgeon', 'CI Programmer'];

  constructor(
    private patientService: PatientService, 
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() { 
    this.showForm$ = this.userService.userSubject
      .pipe(
        map(res => {
          console.log(res);
          return [true];
        })
      );
    this.id = this.route.snapshot.params['id'];
    this.patientService.getById(this.route.snapshot.params['id']).subscribe();
    this.patient$ = this.patientService.record;
  }

}
