import { Component } from '@angular/core';

import { User } from '@app/user/_models/user';
import { UserService } from '@app/user/user.service';
import { Router } from '@angular/router';
import { SetPasswordComponent } from '@app/user/account/set-password/set-password.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { environment } from '@environments/environment';
import { LinkComponent } from '@app/user/profile/link/link.component';
import { AlertService } from '@app/shared/alert/alert.service';
/* import 'src/assets/js/smtp.js';
declare let Email: any; */
/* import { Message, SMTPClient } from 'emailjs'; */

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    user: User;

    constructor(private router: Router, 
        public dialog: MatDialog, 
        private userService: UserService,
        private alertService: AlertService,
        private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {

        /* var nodemailer = require('nodemailer');

        var transporter = nodemailer.createTransport({
        service: 'gmail',
            auth: {
                user: 'ennhsr.development@gmail.com',
                pass: 'ENNHSRdevelopment'
            }
        });

        var mailOptions = {
            from: 'ennhsr.development@gmail.com',
            to: 'abmmoldon@gmail.com',
            subject: 'Sending Email using Node.js',
            text: `Hi, thank you for your nice Node.js Email.`
        };

        transporter.sendMail(mailOptions, function(error, info) {
            if (error) {
                console.log(error);
            } else {
                console.log('Email sent: ' + info.response);
            }
        }); */

        /* console.log(Email)

        Email.send({
            Host : "smtp.gmail.com",
            Username : "ennhsr.development@gmail.com",
            Password : "ENNHSRdevelopment",
            To : 'abmmoldon@gmail.com',
            From : "ennhsr.development@gmail.com",
            Subject : "This is the subject",
            Body : "And this is the body"
        }).then(
          message => alert(message)
        ); */

        /* const client = new SMTPClient({
            user: 'ennhsr.development@gmail.com',
            password: 'ENNHSRdevelopment',
            host: 'smtp.gmail.com',
            ssl: true,
        });

        const message = new Message({
            text: 'i hope this works',
            from: 'ennhsr.development@gmail.com',
            to: 'abmmoldon@gmail.com',
            subject: 'testing emailjs',
        });

        client.send(
            message,
            (err, message) => {
                console.log(err || message);
            }
        ); */

        this.userService.userSubject.subscribe(user => {
            this.user = user
        });
        if(!environment.isDesktop && this.user['hasTemporaryPassword'] == true) {
            this.openDialog();
        } 
        if(environment.isDesktop &&  this.user.linkAccountId == null){
            this.initialLink();
        }
        else {
            this.rerouteOnRole();
        }        
    }

    rerouteOnRole() {        
        if(this.user['isAdmin'] || 
        this.user['isGuest'] || 
        this.user['facility']['roles'].includes('Category A') || 
        this.user['facility']['roles'].includes('Category B') || 
        this.user['facility']['roles'].includes('Category C') || 
        this.user['facility']['roles'].includes('Category D')) {
            this.router.navigateByUrl("/patient"); 
        } else {
            this.router.navigateByUrl("/facility"); 
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(SetPasswordComponent, {
            disableClose: true,
            data: {
                formBuilder: this.formBuilder
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.rerouteOnRole();
        });
    }

    initialLink(): void {
        this.alertService.info("Initial account link",{ keepAfterRouteChange: true })
        const dialogRef = this.dialog.open(LinkComponent, {
            disableClose: true,
            data: {
                formBuilder: this.formBuilder
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            document.getElementsByTagName("nav")[0].className = document.getElementsByTagName("nav")[0].className.replace(" sticky-top","");
            this.userService.logout();
            localStorage.clear();
        });
    }

    ngOnDestroy() {
        
    }
}