import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class EducationalPlacementOfPatientsReport {
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Full Mainstream'
    // })
    // fullMainstream: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Partial Mainstream'
    // })
    // partialMainstream: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Special Education'
    // })
    // specialEducation: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Alternative Learning System'
    // })
    // alternativeLearningSystem: string;

    @autoserializeAs(String)
    @Column({
        columnName: 'Educational Placement'
    })
    educationalPlacement: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Count'
    })
    count: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Percent'
    })
    percent: string;
}
