import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, materialize, dematerialize } from 'rxjs/operators';
import { SampleReportItem } from '@app/dashboard/_models/sample-report-item';
import { SampleReport } from '@app/dashboard/_models/sample-report';

// array in local storage for registered users
const usersKey = 'angular-10-registration-login-example-users';
let users = JSON.parse(localStorage.getItem(usersKey)) || [];
let facilities  = [
    {
        id: 1,
        syncTokens: null,
        newModelIds: {
            facility: [
                "ac8946f6-2dca-42e8-ad58-caf8f2756c27"
            ]
        },
        fieldUpdates: [
            {
                modelName: "facility",
                modelId: "ac8946f6-2dca-42e8-ad58-caf8f2756c27",
                fieldPath: "facilityName",
                value: "Test Facility 1",
                dateUpdated: "2021-1-1T17:00:00.000Z",
                dateSynced: null,
                userId: null
            }
        ]
    }
];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    constructor() {
        users.push({
            firstName: "nhsrc", 
            lastName: "nhsrc", 
            username: "nhsrc", 
            password: "nhsrc123", 
            id: users.length + 1}  
        )
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        return handleRoute(request,next);

        function handleRoute(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            switch (true) {
                case url.endsWith('/dashboard/samplereport') && method === 'GET':
                    return getSampleReport();
                case url.endsWith('/users/authenticate') && method === 'POST':
                    return authenticate(request, next);
                case url.endsWith('/users/register') && method === 'POST':
                    return register(request, next);
                case url.endsWith('/users') && method === 'GET':
                    return getUsers();
                case url.match(/\/users\/\d+$/) && method === 'GET':
                    return getUserById(request, next);
                case url.match(/\/users\/\d+$/) && method === 'PUT':
                    return updateUser(request, next);
                case url.match(/\/users\/\d+$/) && method === 'DELETE':
                    return deleteUser();
                case url.endsWith('/facilities') && method === 'GET':
                    return getFacilities();
                case url.match(/\/facilities\/\d+$/) && method === 'GET':
                    return getFacilityById();
                case url.endsWith('/dashboard/facilities/add') && method === 'POST':
                        return addFacility(request, next);
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }    
        }

        // route functions

        function getSampleReport() {
            // let item1: SampleReportItem;
            // item1.column1 = "qwe";
            // item1.column2 = "asd";
            // item1.column3 = "zxc";

            // let item2: SampleReportItem;
            // item2.column1 = "qwe2";
            // item2.column2 = "asd2";
            // item2.column3 = "zxc2";

            // let report: SampleReport;
            // report.items.push(item1);
            // report.items.push(item2);
            // // const { items } = report;

            return ok({
                items: [
                    {
                        column1: "qwe",
                        column2: "asd",
                        column3: "zxc"
                    },
                    {
                        column1: "qwe2",
                        column2: "asd2",
                        column3: "zxc2"
                    }
                ]
            });
        }

        function authenticate(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            /* const { username, password } = body;
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) return error('Username or password is incorrect');
            return ok({
                ...basicDetails(user),
                token: `${localStorage.getItem('token')}`
            }) */
            return next.handle(request);
        }

        function addFacility(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            /* const user = body

            if (users.find(x => x.username === user.username)) {
                return error('Username "' + user.username + '" is already taken')
            }

            user.id = users.length ? Math.max(...users.map(x => x.id)) + 1 : 1;
            users.push(user);
            localStorage.setItem(usersKey, JSON.stringify(users));
            return ok(); */
            /* request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              }); */
              return next.handle(request);
        }

        function register(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            /* const user = body

            if (users.find(x => x.username === user.username)) {
                return error('Username "' + user.username + '" is already taken')
            }

            user.id = users.length ? Math.max(...users.map(x => x.id)) + 1 : 1;
            users.push(user);
            localStorage.setItem(usersKey, JSON.stringify(users));
            return ok(); */
            /* request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              }); */
              return next.handle(request);
        }

        function getUsers() {
            if (!isLoggedIn()) return unauthorized();
            return ok(users.map(x => basicDetails(x)));
        }
        
        function getFacilities() {
           //if (!isLoggedIn()) return unauthorized();
            return ok(facilities);
        }

        function getUserById(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            /* if (!isLoggedIn()) return unauthorized();

            const user = users.find(x => x.id === idFromUrl());
            return ok(basicDetails(user)); */
            return next.handle(request);
        }

        function getFacilityById(){
            /* if (!isLoggedIn()) return unauthorized();

            const user = users.find(x => x.id === idFromUrl());
            return ok(basicDetails(user)); */
            const facility = facilities.find(x => x.id === idFromUrl())
            return ok(facility)
        }

        function updateUser(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            /* if (!isLoggedIn()) return unauthorized();

            let params = body;
            let user = users.find(x => x.id === idFromUrl());

            // only update password if entered
            if (!params.password) {
                delete params.password;
            }

            // update and save user
            Object.assign(user, params);
            localStorage.setItem(usersKey, JSON.stringify(users)); */

            return next.handle(request);
        }

        function deleteUser() {
            if (!isLoggedIn()) return unauthorized();

            users = users.filter(x => x.id !== idFromUrl());
            localStorage.setItem(usersKey, JSON.stringify(users));
            return ok();
        }

        // helper functions

        function ok(body?) {
            return of(new HttpResponse({ status: 200, body }))
                .pipe(delay(500)); // delay observable to simulate server api call
        }

        function error(message) {
            return throwError({ error: { message } })
                .pipe(materialize(), delay(500), dematerialize()); // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648);
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'Unauthorized' } })
                .pipe(materialize(), delay(500), dematerialize());
        }

        function basicDetails(user) {
           /*  const { id, username, firstName, lastName } = user;
            return { id, username, firstName, lastName }; */
            return user;
        }

        function isLoggedIn() {
            return headers.get('Authorization') === `Bearer ${localStorage.getItem('token')}`;
        }

        function idFromUrl() {
            const urlParts = url.split('/');
            return parseInt(urlParts[urlParts.length - 1]);
        }
    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};