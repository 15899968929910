import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SelectorService } from './selector.service';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent implements OnInit {
  private tags: Array<string> = ['BUTTON', 'A', 'INPUT', 'SELECT'];
  private classes: Array<string> = ['mat-checkbox-inner-container','mat-radio-outer-circle','btn'];
  top: number;
  left: number;
  width: number;
  height: number;
  zindex: number;
  transition: string;
  outline: string;
  outlineoffset: string;
  private firstSelect: boolean;
  private selectedElement: Element;
  private enabled: boolean;
  selectorSubscription: Subscription;

  constructor(private router: Router, private selectorService: SelectorService) {
    this.enabled = true;
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {        
        this.reset()
      }
    });
    this.selectorSubscription = this.selectorService.onControl().subscribe(command => {
      switch(command) {
        case -1:
          this.reset();
          break;
        case 0:
          this.disable();
          break;
        case 1:
          this.enable();
          break;
        case 2:
          this.onScroll(null);
          break;
      }
    });
  }

  ngOnInit(): void {
    this.reset()
  }

  public reset() {
    this.top = 0;
    this.left = 0;
    this.width = 0;
    this.height = 0;
    this.zindex = 0;
    this.transition = 'none';
    this.outline = 'none';
    this.outlineoffset = "0.2rem"
    this.firstSelect = true;
    this.selectedElement = null;
  }
  
  public disable() {
    this.enabled = false;
  }

  public enable() {
    this.enabled = true;
  }

  @HostListener('document:mousemove', ['$event']) 
  onMouseMove(e: MouseEvent) {
    if(!document.body.contains(this.selectedElement))
      this.reset()
    var element = document.elementFromPoint(e.clientX, e.clientY)
    if(element != null)
      this.selectElement(element, false);
  }

  @HostListener('document:keyup', ['$event']) 
  onTab(e: KeyboardEvent) {
    if(e.keyCode == 9 || (e.shiftKey && e.keyCode == 9))
      this.selectElement(document.activeElement, false);
  }

  @HostListener('document:submit', ['$event']) 
  onSubmit(e: Event) {
    this.reset();
  }

  @HostListener('window:resize', ['$event']) 
  onResize(e: Event) {
    this.reset()
  }

  @HostListener('window:scroll', ['$event']) 
  onScroll(e: Event) {
    if(this.selectedElement != null)
      this.selectElement(this.selectedElement, true);
  }

  selectElement(element: Element, resize: boolean) {
    // console.log(element)
    if(this.enabled) {
      if(this.tags.includes(element.tagName) || (element.className.split(" ").filter(e => this.classes.includes(e)).length > 0)) {
        this.selectedElement = element;
        if(resize) {
          this.transition = 'none';
        } else {
          if(this.firstSelect)
            this.transition= 'width 0.15s, height 0.15s';
          else
            this.transition= 'width 0.15s, height 0.15s, left 0.15s, top 0.15s';
        }
        this.outline = '2px solid rgba(0, 0, 0, 0.1)';
        
        this.top = element.getBoundingClientRect().top + window.scrollY;
        this.left = element.getBoundingClientRect().left + window.scrollX;
        this.width = element.getBoundingClientRect().width;
        this.height = element.getBoundingClientRect().height;
        this.outlineoffset = "0.2rem"

        if(element.attributes['type'] !== undefined && element.attributes['type'].value === "checkbox") {
          this.width += 15;
          this.height += 15;
          this.top -= 16;
          this.left -= 7;
        }

        if(element.attributes['type'] !== undefined && element.attributes['type'].value === "radio") {
          this.width += 19;
          this.height += 19;
          this.top -= 20;
          this.left -= 9;
        }

        this.firstSelect = false;
        if(this.selectedElement.className.includes('main-nav-item')) {
          this.zindex = 1021;
        } else {
          this.zindex = 1021;
        }
      }
    }
  }
}
