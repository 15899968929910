import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '@app/admin/users/users.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { User } from '@app/user/_models/user';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { IdMappingService } from '../idMapping.service';
import { SyncService } from '../sync.service';
import { formsAnimations } from './layout.animations';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    formsAnimations
  ] 
})
export class LayoutComponent implements OnInit {

  showIncoming: boolean;
  latestSync: any;
  updatesLocal: any;
  updatesOrigin: any;
  user: User;
  syncDataLocal: any;
  syncDataOrigin: any;
  daysSinceLastSync: number;
  enableSync: boolean = false;
  loading = false;
  syncing = false;
  originIdMappings = [];
  localIdMappings = [];
  userIdNames = [];
  
  originProgressSubject = new BehaviorSubject<number>(0);
  originProgress = this.originProgressSubject.asObservable();
  
  constructor(private syncService: SyncService,
    private router: Router,
    private ennhsrService: EnnhsrService,
    private idMappingService: IdMappingService,
    private userService: UserService,
    private usersService: UsersService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.showIncoming = false;
    this.user = this.userService.userValue;
    forkJoin([this.syncService.getByFacility(this.user['facility'].facilityCode),this.usersService.getAllByFacility(this.user['facility'].facilityCode)]).subscribe(latestSyncFacilityUsers => { 
      let latestSync = latestSyncFacilityUsers[0]
      let users = latestSyncFacilityUsers[1]
      this.latestSync = latestSync;
      this.daysSinceLastSync = this.latestSync ? this.calculateDayDiff(this.latestSync["dataModel"]["dateSync"]) : 0;
      this.ennhsrService.getToken()
      .subscribe({
        next: () => {
            forkJoin(
              [this.syncService.pull(this.latestSync ? this.latestSync["dataModel"]["dateSync"] : null, true, this.originProgressSubject),
              this.syncService.status(this.latestSync ? this.latestSync["dataModel"]["dateSync"] : null, true)]
              ).subscribe(pullStatusRes => {
                console.log("pullStatusRes", pullStatusRes);
                let pullRes = pullStatusRes[0];
                let statusRes = pullStatusRes[1];

                let originCurrentModelIds = pullRes["currentModelIds"];
                Object.keys(originCurrentModelIds).forEach(key => {
                  if(key === "sync")
                    delete originCurrentModelIds[key]
                });
                let localCurrentModelIds = statusRes["currentModelIds"];
                Object.keys(localCurrentModelIds).forEach(key => {
                  if(key === "sync")
                    delete localCurrentModelIds[key]
                });

                let noOriginChange = ((pullRes["fieldUpdates"] as []).length == 0 || Object.keys(originCurrentModelIds).length == 0)
                let noLocalChange = ((statusRes["fieldUpdates"] as []).length == 0 || Object.keys(localCurrentModelIds).length == 0)

                this.updatesOrigin = this.summarizeUpdates(pullRes["currentModelIds"]);
                this.updatesLocal = this.summarizeUpdates(statusRes["currentModelIds"])
                
                if(noOriginChange && noLocalChange) {
                  this.enableSync = false
                } else {                  
                  let idMappingForks = []
                  if(!noOriginChange) {
                    var originFieldUpdates = pullRes["fieldUpdates"];
                    var originSync = new Sync();
                    originSync.newModelIds = {};
                    originSync.syncTokens = {};
                    let originIdMappingForks = [];
                    for(let [modelName, modelVersionIds] of Object.entries(pullRes['currentModelIds'])){
                      for(let [modelId, versionId] of Object.entries(modelVersionIds))
                      originIdMappingForks.push(this.idMappingService.getByOriginId(modelId))
                    }
                    idMappingForks.push(forkJoin(originIdMappingForks));
                  } else {
                    idMappingForks.push(of(null));
                  }                  
                  if(!noLocalChange) {
                    var localFieldUpdates = statusRes["fieldUpdates"];
                    var localSync = new Sync();
                    localSync.newModelIds = {};    
                    localSync.syncTokens = {};
                    let localIdMappingForks = [];                  
                    for(let [modelName, modelVersionIds] of Object.entries(statusRes['currentModelIds'])){
                      for(let [modelId, versionId] of Object.entries(modelVersionIds))
                      localIdMappingForks.push(this.idMappingService.getByLocalId(modelId))
                    }
                    idMappingForks.push(forkJoin(localIdMappingForks));
                  } else {
                    idMappingForks.push(of(null));
                  } 

                  forkJoin(idMappingForks).subscribe(idMappingForksRes => {  
                    console.log("idMappingForksRes", idMappingForksRes);
                    this.originIdMappings = (idMappingForksRes[0] as [])?.filter(e => e).map(e => e['dataModel']);
                    this.localIdMappings = (idMappingForksRes[1] as [])?.filter(e => e).map(e => e['dataModel']);
                    console.log("originIdMappings", this.originIdMappings);
                    console.log("localIdMappings", this.localIdMappings);
                    
                    if(!noOriginChange) {
                      for(let [modelName, modelVersionIds] of Object.entries(pullRes['currentModelIds'])){
                        for(let [modelId, versionId] of Object.entries(modelVersionIds)) {
                          let originIdMapping = this.originIdMappings.find(e => e.originId == modelId)
                          if(originIdMapping)
                            originIdMapping.originVer = versionId;
                          else 
                            this.originIdMappings.push({originId: modelId, originVer: versionId})
                          if(this.localIdMappings) {
                            let localIdMapping = this.localIdMappings.find(e => e.originId == modelId)
                            if(localIdMapping)
                              localIdMapping.originVer = versionId;
                          }  
                        }
                      }
                    }                  
                    if(!noLocalChange) {
                      for(let [modelName, modelVersionIds] of Object.entries(statusRes['currentModelIds'])){
                        for(let [modelId, versionId] of Object.entries(modelVersionIds)){
                          let localIdMapping = this.localIdMappings.find(e => e.localId == modelId)
                          if(localIdMapping)
                            localIdMapping.localVer = versionId;
                          else 
                            this.localIdMappings.push({localId: modelId, localVer: versionId})
                          if(this.originIdMappings) {
                            let originIdMapping = this.originIdMappings.find(e => e.localId == modelId)
                            if(originIdMapping)
                              originIdMapping.localVer = versionId;
                          }  
                        }
                      }
                    }
                    let convertForks = [];
                    let userIds = []
                    if(!noOriginChange) {
                      originFieldUpdates.forEach(fieldUpdate => {
                        // fieldUpdate.userId = users.find(e => e.linkAccountId == fieldUpdate.userId)?.id || fieldUpdate.userId
                        if(!userIds.includes(fieldUpdate.userId))
                          userIds.push(fieldUpdate.userId)
                        let localIdMapping = this.originIdMappings?.find(e => e?.originId == fieldUpdate["modelId"])
                        if(!localIdMapping?.localId){
                          if(originSync.newModelIds[fieldUpdate["modelName"]] === undefined){
                            originSync.newModelIds[fieldUpdate["modelName"]] = [ fieldUpdate["modelId"] ]
                          } else {
                            originSync.newModelIds[fieldUpdate["modelName"]].includes(fieldUpdate["modelId"]) ? '' : originSync.newModelIds[fieldUpdate["modelName"]].push(fieldUpdate["modelId"])
                          }
                        } else {
                          fieldUpdate['modelId'] = localIdMapping.localId;
                          if(!originSync.syncTokens[fieldUpdate["modelName"]])
                            originSync.syncTokens[fieldUpdate["modelName"]] = {};
                          originSync.syncTokens[fieldUpdate["modelName"]][localIdMapping.localId] = localIdMapping.localVer;
                        }
                        originSync.fieldUpdates.push(fieldUpdate);
                      })
                      let originConvertForks = originSync.fieldUpdates.filter(e => e.fieldPath == "patient" || e.fieldPath == "sealDistribution").map(e => forkJoin([of(e),this.idMappingService.getByOriginId(e['value'])]))
                      convertForks.push(originConvertForks.length == 0 ? of([]): forkJoin(originConvertForks))
                    } else {
                      convertForks.push(of([]))
                    }  
                    if(!noLocalChange) {
                      localFieldUpdates.forEach(fieldUpdate => {
                        fieldUpdate.userId = users.find(e => e.id == fieldUpdate.userId).linkAccountId
                        let originIdMapping = this.localIdMappings?.find(e => e?.localId == fieldUpdate["modelId"])
                        if(!originIdMapping?.originId){
                          if(localSync.newModelIds[fieldUpdate["modelName"]] === undefined){
                            localSync.newModelIds[fieldUpdate["modelName"]] = [ fieldUpdate["modelId"] ]
                          } else {
                            localSync.newModelIds[fieldUpdate["modelName"]].includes(fieldUpdate["modelId"]) ? '' : localSync.newModelIds[fieldUpdate["modelName"]].push(fieldUpdate["modelId"])
                          }
                        } else {
                          fieldUpdate['modelId'] = originIdMapping.originId;
                          if(!localSync.syncTokens[fieldUpdate["modelName"]])
                            localSync.syncTokens[fieldUpdate["modelName"]] = {};
                          localSync.syncTokens[fieldUpdate["modelName"]][originIdMapping.originId] = originIdMapping.originVer;
                        }
                        localSync.fieldUpdates.push(fieldUpdate);
                      })
                      let localconvertForks = localSync.fieldUpdates.filter(e => e.fieldPath == "patient" || e.fieldPath == "sealDistribution").map(e => forkJoin([of(e),this.idMappingService.getByLocalId(e['value'])]))
                      convertForks.push(localconvertForks.length == 0 ? of([]): forkJoin(localconvertForks))
                    } else {
                      convertForks.push(of([]))
                    }
                    let userIdsForks = userIds.length == 0 ? of(null) : forkJoin(userIds.map(e => this.usersService.getOriginById(e)))
                    forkJoin([forkJoin(convertForks), userIdsForks]).subscribe(userIdsConvertForksRes => {
                      let convertForksRes = userIdsConvertForksRes[0];
                      console.log("userIdsConvertForksRes[1]",userIdsConvertForksRes[1])
                      this.userIdNames = userIdsConvertForksRes[1]?.map(e => {return {id: e.id, name: `${e.firstName} ${e.lastName}`}})
                      let originAllRes = convertForksRes[0] as [any];
                      let localAllRes = convertForksRes[1] as [any]; 
                      if(!noOriginChange) {
                        originAllRes.forEach(originFieldUpdateRes => {
                          let originFieldUpdate = originFieldUpdateRes[0]
                          let originRes = originFieldUpdateRes[1]
                          let localId = originRes?.dataModel.localId;
                          if(localId) {
                            originFieldUpdate['value'] = localId;
                          }
                        })
                        this.syncDataOrigin = originSync;
                        console.log("syncDataOrigin: ")
                        console.log(this.syncDataOrigin)
                      }
                      if(!noLocalChange) {
                        localAllRes.forEach(localFieldUpdateRes => {
                          let localFieldUpdate = localFieldUpdateRes[0]
                          let localRes = localFieldUpdateRes[1]
                          let originId = localRes?.dataModel.originId;
                          if(originId) {
                            localFieldUpdate['value'] = originId;
                          }
                        })
                        this.syncDataLocal = localSync;
                        console.log("syncDataLocal")
                        console.log(this.syncDataLocal)
                      }
                      this.enableSync = true;
                    });
                  });
                }                
            });
        },
        error: error => {
          this.alertService.error("Web services unavailable", { keepAfterRouteChange: true });
        }
      }); 
    });
  }

  newSync(){
    if(this.latestSync === null){
      this.createSync();
    } else {
      this.updateSync();
    }
  }

  createSync(){
    this.syncService.add()
          .pipe(first())
          .subscribe({
              next: () => {
                  //this.alertService.success('Sync data added successfully', { keepAfterRouteChange: true });
              },
              error: error => {
                  console.log("createSync() error: ", error)
                  this.alertService.error(error);
              }
          });
  }

  updateSync(){
    this.syncService.update({}, this.latestSync["modelId"] , this.latestSync["versionId"] )
    .pipe(first())
    .subscribe({
        next: () => {
            //this.alertService.success('Update successful', { keepAfterRouteChange: true });
        },
        error: error => {
            console.log("updateSync() error: ", error)
            this.alertService.error(error);
        }
    });
  }

  push(){
    this.idMappingService.getAll().subscribe(idMappings => {
      let idMappingRes = (idMappings['results'] as []).map(e => e['dataModel']);
      console.log("idMappingRes",idMappingRes)
      this.alertService.info("Data syncing.. Please wait..");
      this.loading = true;
      this.enableSync = false;
      this.syncing = true;
      let originSyncCopy = this.syncDataOrigin ? JSON.parse(JSON.stringify(this.syncDataOrigin)) : null;
      let localSyncCopy = this.syncDataLocal ? JSON.parse(JSON.stringify(this.syncDataLocal)) : null;
      let mergePushOp = []
      if(this.syncDataOrigin){
        if(this.syncDataLocal){
          let originConflictOriginArr: [] = JSON.parse(JSON.stringify(originSyncCopy))["fieldUpdates"];
          let originConflictLocalArr: [] = JSON.parse(JSON.stringify(localSyncCopy))["fieldUpdates"];
          const resolvedOriginFieldUpdates = originConflictOriginArr.reduce((newArr,originFieldUpdate)=>{
            const conflict = originConflictLocalArr.find(localFieldUpdates => localFieldUpdates["fieldPath"] === originFieldUpdate["fieldPath"] && localFieldUpdates["modelId"] === idMappingRes.find(e => e['localId'] == originFieldUpdate["modelId"])?.['originId'])
            if(conflict){
              this.syncDataOrigin.syncTokens[originFieldUpdate["modelName"]][originFieldUpdate["modelId"]] = this.localIdMappings.find(e => e.localId == originFieldUpdate["modelId"]).localVer
              if( new Date(originFieldUpdate["dateUpdated"]).getTime() > new Date(conflict["dateUpdated"]).getTime()) {
                newArr.push(originFieldUpdate)
              }
            } else { newArr.push(originFieldUpdate) }
            return newArr;
          },[]);
          this.syncDataOrigin["fieldUpdates"] = resolvedOriginFieldUpdates;
          for(let [modelName, modelIdsVersionIds] of Object.entries(this.syncDataOrigin.syncTokens)) {
            for(let modelId of Object.keys(modelIdsVersionIds)) {
              if(!this.syncDataOrigin["fieldUpdates"].map(e => e.modelId).includes(modelId))
                delete this.syncDataOrigin.syncTokens[modelName][modelId]
            }
            if(Object.keys(this.syncDataOrigin.syncTokens[modelName]).length == 0)
              delete this.syncDataOrigin.syncTokens[modelName]
          }
        }      
        if((this.syncDataOrigin.newModelIds.patient || this.syncDataOrigin.newModelIds.sealDistribution) && !(Object.keys(this.syncDataOrigin.newModelIds).every(e => e == "patient" || e == "sealDistribution"))) {          
          let originInitialPushSync = new Sync();
          let originFollowupPushSync = new Sync();
          originInitialPushSync.newModelIds = {}
          if(this.syncDataOrigin.newModelIds.patient) {
            originInitialPushSync.newModelIds.patient = this.syncDataOrigin.newModelIds.patient
            originInitialPushSync.fieldUpdates.push(...this.syncDataOrigin.fieldUpdates.filter(e => originInitialPushSync.newModelIds.patient.includes(e.modelId)))
          }
          if(this.syncDataOrigin.newModelIds.sealDistribution) {
            originInitialPushSync.newModelIds.sealDistribution = this.syncDataOrigin.newModelIds.sealDistribution
            originInitialPushSync.fieldUpdates.push(...this.syncDataOrigin.fieldUpdates.filter(e => originInitialPushSync.newModelIds.sealDistribution.includes(e.modelId)))
          }
          originFollowupPushSync.syncTokens = this.syncDataOrigin.syncTokens;
          originFollowupPushSync.fieldUpdates = this.syncDataOrigin.fieldUpdates.filter(e => !this.syncDataOrigin.newModelIds.patient?.includes(e.modelId) && !this.syncDataOrigin.newModelIds.sealDistribution?.includes(e.modelId))
          originFollowupPushSync.newModelIds = this.syncDataOrigin.newModelIds
          delete originFollowupPushSync.newModelIds.patient
          delete originFollowupPushSync.newModelIds.sealDistribution
          mergePushOp.push(this.syncService.merge(originInitialPushSync, this.originIdMappings, this.userIdNames).pipe(switchMap(() => {
            let convertForks = originFollowupPushSync.fieldUpdates.filter(e => e.fieldPath == "patient" || e.fieldPath == "sealDistribution").map(e => forkJoin([of(e),this.idMappingService.getByOriginId(e['value'])]))
            let convertOp = convertForks.length == 0 ? of([]): forkJoin(convertForks)
            return convertOp.pipe(switchMap(allRes => {
              allRes.forEach(fieldUpdateRes => {
                let fieldUpdate = fieldUpdateRes[0]
                let idMapping = fieldUpdateRes[1]
                let localId = idMapping?.dataModel.localId || null;
                if(localId){
                  fieldUpdate['value'] = localId;
                }
              })       
              return this.syncService.merge(originFollowupPushSync, this.originIdMappings, this.userIdNames)  
            }))
          })))
        } else {
          if(this.syncDataOrigin.fieldUpdates.length > 0)
            mergePushOp.push(this.syncService.merge(this.syncDataOrigin, this.originIdMappings, this.userIdNames))
          else
            mergePushOp.push(of(null))
        }   
      }
      if(this.syncDataLocal){
        if(this.syncDataOrigin){
          let localConflictOriginArr: [] = JSON.parse(JSON.stringify(originSyncCopy))["fieldUpdates"];
          let localConflictLocalArr: [] = JSON.parse(JSON.stringify(localSyncCopy))["fieldUpdates"];
          const resolvedLocalFieldUpdates = localConflictLocalArr.reduce((newArr,localFieldUpdate)=>{
            const conflict = localConflictOriginArr.find(originFieldUpdates => originFieldUpdates["fieldPath"] === localFieldUpdate["fieldPath"] && originFieldUpdates["modelId"] === idMappingRes.find(e => e['originId'] == localFieldUpdate["modelId"])?.['localId'])
            if(conflict){
              this.syncDataLocal.syncTokens[localFieldUpdate["modelName"]][localFieldUpdate["modelId"]] = this.originIdMappings.find(e => e.originId == localFieldUpdate["modelId"]).originVer
              if( new Date(localFieldUpdate["dateUpdated"]).getTime() > new Date(conflict["dateUpdated"]).getTime()) {
                newArr.push(localFieldUpdate)
              }
            } else { newArr.push(localFieldUpdate) }
            return newArr;
          },[]);
          this.syncDataLocal["fieldUpdates"] = resolvedLocalFieldUpdates;
          for(let [modelName, modelIdsVersionIds] of Object.entries(this.syncDataLocal.syncTokens)) {
            for(let modelId of Object.keys(modelIdsVersionIds)) {
              if(!this.syncDataLocal["fieldUpdates"].map(e => e.modelId).includes(modelId))
                delete this.syncDataLocal.syncTokens[modelName][modelId]
            }
            if(Object.keys(this.syncDataLocal.syncTokens[modelName]).length == 0)
              delete this.syncDataLocal.syncTokens[modelName]
          }
        }
        if((this.syncDataLocal.newModelIds.patient || this.syncDataLocal.newModelIds.sealDistribution) && !(Object.keys(this.syncDataLocal.newModelIds).every(e => e == "patient" || e == "sealDistribution"))) {
          let localInitialPushSync = new Sync();
          let localFollowupPushSync = new Sync();
          localInitialPushSync.newModelIds = {}
          if(this.syncDataLocal.newModelIds.patient) {
            localInitialPushSync.newModelIds.patient = this.syncDataLocal.newModelIds.patient
            localInitialPushSync.fieldUpdates.push(...this.syncDataLocal.fieldUpdates.filter(e => localInitialPushSync.newModelIds.patient.includes(e.modelId)))
          }
          if(this.syncDataLocal.newModelIds.sealDistribution) {
            localInitialPushSync.newModelIds.sealDistribution = this.syncDataLocal.newModelIds.sealDistribution
            localInitialPushSync.fieldUpdates.push(...this.syncDataLocal.fieldUpdates.filter(e => localInitialPushSync.newModelIds.sealDistribution.includes(e.modelId)))
          }
          localFollowupPushSync.syncTokens = this.syncDataLocal.syncTokens;
          localFollowupPushSync.fieldUpdates = this.syncDataLocal.fieldUpdates.filter(e => !this.syncDataLocal.newModelIds.patient?.includes(e.modelId) && !this.syncDataLocal.newModelIds.sealDistribution?.includes(e.modelId))
          localFollowupPushSync.newModelIds = this.syncDataLocal.newModelIds
          delete localFollowupPushSync.newModelIds.patient
          delete localFollowupPushSync.newModelIds.sealDistribution
          mergePushOp.push(this.syncService.push(localInitialPushSync, this.localIdMappings).pipe(switchMap(() => {
            let convertForks = localFollowupPushSync.fieldUpdates.filter(e => e.fieldPath == "patient" || e.fieldPath == "sealDistribution").map(e => forkJoin([of(e),this.idMappingService.getByLocalId(e['value'])]))
            let convertOp = convertForks.length == 0 ? of([]): forkJoin(convertForks)
            return convertOp.pipe(switchMap(allRes => {
              allRes.forEach(fieldUpdateRes => {
                let fieldUpdate = fieldUpdateRes[0]
                let idMapping = fieldUpdateRes[1]
                let originId = idMapping?.dataModel.originId || null;
                if(originId){
                  fieldUpdate['value'] = originId;
                }
              })       
              return this.syncService.push(localFollowupPushSync, this.localIdMappings)  
            }))
          })))
        } else {
          if(this.syncDataLocal.fieldUpdates.length > 0)
            mergePushOp.push(this.syncService.push(this.syncDataLocal, this.localIdMappings))
          else
            mergePushOp.push(of(null))
        }
      }
      forkJoin(mergePushOp)
      .subscribe(mergePushOpRes => {
        // next: () => {
          this.loading = false;
          this.alertService.clear();
          this.alertService.success('Data synced. Returning to home page in 3s', { keepAfterRouteChange: true });
          this.newSync();
          setTimeout(() => {
            this.router.navigateByUrl("/");
          }, 3000);
        // },
        // error: error => {
        //     this.loading = false;
        //     this.alertService.error(error);
        // }
      })
    });    
  }

  summarizeUpdates(item: any){
    var summary = {}
    summary["patient"] = 0;
    summary["record"] = 0;
    for (let [key, value] of Object.entries(item)) {
      if(key === "patient")
        summary["patient"] = summary["patient"] + Object.keys(value).length
      else { summary["record"] = summary["record"] + Object.keys(value).length }
    }
    return summary;
  }

  calculateDayDiff(data){
    let date = new Date(parseInt(data.toString()));
    let currentDate = new Date();
    let days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
}
