<div class="container mt-3">
    <div *ngIf="isHidden" class="card">
        <div class="mb-3">
            <button (click)="back()" class="btn btn-success"><i class="mdi mdi-arrow-left"></i> Back</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                Validate Patient Number
            </div>
            <div class="card-body"> 
                <fieldset>
                    <div class="form-row">
                        <div class="form-group col-12 px-3">
                            <!-- <label for="notes">Patient Number</label> -->
                            <input type="text" formControlName="patientNumber" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button class="btn btn-primary btn-block text-nowrap">
                                    <!-- <button class="btn btn-primary btn-block text-nowrap"> -->
                                        <!-- <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> -->
                                        <!-- <span class="spinner-border spinner-border-sm mr-1"></span> -->
                                        Validate
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <!-- <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
    <div *ngIf="isShown" class="card row container-fluid" id="divshow">
        <div class="mb-3">
            <button (click)="back()" class="btn btn-success"><i class="mdi mdi-arrow-left"></i> Back</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                Patient Details
            </div>
            <div class="card-body"> 
                <fieldset>
                    <div class="form-row">
                        <div class="form-row col-12 px-3">
                            <label for="notes">Patient Number</label>
                            <input disabled type="text" formControlName="patientNumber" class="form-control"/>
                        </div>
                        <div class="form-row col-12 px-3">
                            <label for="notes">Seal Number</label>
                            <input disabled type="text" formControlName="sealNumber" class="form-control"/>
                        </div>
                        <div class="form-row col-12 px-3">
                            <label for="notes">Facility of Birth</label>
                            <input disabled type="text" formControlName="facilityOfBirth" class="form-control"/>
                        </div>
                        <div class="form-row col-12 px-3">
                            <label for="notes">Facility</label>
                            <input disabled type="text" formControlName="currFacility" class="form-control"/>
                        </div>
                        <div class="form-row col-12 px-3">
                            <label for="notes">Facility Address</label>
                            <input disabled type="text" formControlName="facilityAddress" class="form-control"/>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>