import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/user/user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  subModuleLinks = [
    // { title: 'Test', fragment: 'test' },
    { title: 'Reports', fragment: 'reports' },
    // { title: 'Analytics', fragment: 'analytics' }
    // { title: 'Cars', fragment: 'cars' }
  ];

  opened: boolean = true;

  constructor(public route: ActivatedRoute, private userService: UserService) { 
    if(this.userService.userValue['isAdmin'])
      this.subModuleLinks.push({ title: 'Analytics', fragment: 'analytics' });
  }

  ngOnInit(): void { }

}