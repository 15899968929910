import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirstFittingService {

  private modelName = "firstFitting";

  public recordsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public records: Observable<any> = this.recordsSubject.asObservable();

  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService
  ) { }

  public get recordValue() {
    return this.recordsSubject.value;
  }

  public get recordObservable() {
    return this.records;
  }

  add(item: any){
    var guid = Utils.generateGuid()
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = item["dateOfFirstFitting"]
    const user = this.userService.userValue;
    var sync = Utils.convertToSync(item, this.modelName, guid, null, user.id);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      mergeMap(res => {
        return this.getById(res['insertedModelIds'][this.modelName][guid]['assignedId'])
        .pipe(
          map(newItem => {
            this.recordsSubject.next(newItem);
            return res;
          })
        );
      })
    );
  }

  getAll(id: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?patient=${id}`)
    .pipe(
      map(res => {
        this.recordsSubject.next(res);
        return res;
      })
    );
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}`);
  }

  getByPatient(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}?patient=${id}`)
    .pipe(
      map(res => {
        this.recordsSubject.next(res);
        return res;
      })
    );
  }

  update(item: any, modelId: string, versionId: string) {
    var original = this.recordsSubject.value;
    item["meta"] = original["dataModel"]["meta"];
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = item["dateOfFirstFitting"]
    item["patient"] = original["dataModel"]["patient"];
    const user = this.userService.userValue;
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var record = this.recordsSubject.value;
        record.dataModel = item;
        record.versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        record.updatedBy = JSON.parse(localStorage.getItem('user')).id
        record.updatedByName = JSON.parse(localStorage.getItem('user')).firstName + " " + JSON.parse(localStorage.getItem('user')).lastName
        this.recordsSubject.next(record);
        return res;
      })
    );
  }

}