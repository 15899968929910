import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class MasterlistOfSurgicalInterventionReport {
  @autoserializeAs(String)
  @Column({
    columnName: 'Date of Implantation'
  })
  dateOfImplantation: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Hospitals Name'
  })
  hospitalName: string;
  @autoserializeAs(String)
  @Column({
      columnName: 'Hospitals Address'
  })
  psgc: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Surgeons Name'
  })
  surgeonsName: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Clinical Audiologists Name'
  })
  clinicalAudiologistsName: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Laterality'
  })
  laterality: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Type'
  })
  type: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Model'
  })
  model: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Serial Number'
  })
  serialNumber: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Diagnosis'
  })
  diagnosis: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'First Case Rate'
  })
  firstCaseRate: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Second Case Rate'
  })
  secondCaseRate: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Procedure 1st'
  })
  procedureFirst: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Procedure 2nd'
  })
  procedureSecond: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'With Complication'
  })
  withComplication: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Timing'
  })
  timing: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Description'
  })
  description: string;
  @autoserializeAs(String)
  @Column({
    columnName: 'Surgeons Notes'
  })
  surgeonsNotes: string;
}

