import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HearingScreeningService } from '../hearing-screening.service';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { first } from 'rxjs/operators';
import { PrintService } from '@app/shared/print/print.service';
import { PatientService } from '@app/patient/patient.service';
import { StatusService } from '../status.service';

@Component({ template: '' })
export class AddEditHearingScreeningComponent implements OnInit {
      
  constructor(
    public dialog: MatDialog,
    private location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
      this.openDialog();
  }

  openDialog(): void {
      let id = this.route.snapshot.params['id']
      const dialogRef = this.dialog.open(AddEditHearingScreeningComponentDialog, {
          data: {
              formBuilder: this.formBuilder,
              id: this.route.snapshot.params['id']
          }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.location.back();
      });
  }

}

@Component({ templateUrl: 'add-edit-hearing-screening.component.html' })
export class AddEditHearingScreeningComponentDialog implements OnInit {
    
    form: FormGroup;
    loading = false;
    submitted = false;

    editable: boolean;
    editing: boolean;
    printable: boolean;

    isAddMode: any;

    id: string;
    patientId: string;
    modelId: string;
    versionId: string;
    nhsrcSealControlNumber: string;
    
    constructor(        
        public dialogRef: MatDialogRef<AddEditHearingScreeningComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder,
            id: string
        },
        public dialog: MatDialog,
        private hearingScreeningService: HearingScreeningService, 
        private patientService: PatientService,
        private formBuilder: FormBuilder, 
        private alertService: AlertService,
        public printService: PrintService,
        private statusService: StatusService
    ) { 
        this.id = data.id;
    }

    ngOnInit() {
      this.patientId = this.patientService.recordSubject.getValue()["modelId"];

      this.isAddMode = !this.id;
  
      this.editable = this.id? true:false;
      this.editing = false;
      this.printable = true;
  
      this.form = this.formBuilder.group({
        patient: [''],
        screeningOffered: [''],
        screeningStatus: [''],
        hyperbilirubinemia: ['', Validators.required],
        ventilation: ['', Validators.required],
        nicu: ['', Validators.required],
        ototoxicMedication: ['', Validators.required],
        familyHistoryHearingLoss: ['', Validators.required],
        craniofacialAnomalies: ['', Validators.required],
        featuresAssociated: ['', Validators.required],
        inUteroInfections: ['', Validators.required],
        none: [''],
        dateOfScreening: ['', [Validators.required,initScreeningValidator(this.hearingScreeningService.recordsValue.count, this.patientService.recordSubject.value.dataModel.dateOfBirth)]],
        timeOfScreening: ['', Validators.required],
        typeOfScreening: ['', Validators.required],
        typeOfScreeningIndicate: [''],
        methodOfScreening: ['', Validators.required],
        methodOfScreeningIndicate: [''],
        resultRight: ['', Validators.required],
        resultLeft: ['', Validators.required],
        screenerName: ['', Validators.required],
        registryCardNumber: [''],
        nhsrcSealControlNumber: [this.statusService.recordsSubject.getValue()["dataModel"]["serialNumber"]],
        hearingScreeningCenter: ['']
      });
  
      if (!this.isAddMode) {
        var model = this.hearingScreeningService.recordsValue["results"].find(x => x.modelId == this.id);
        this.versionId = model["versionId"];
        this.modelId = model["modelId"];
        this.form.patchValue(Utils.mapModelToForm(model, ['dateOfScreening']));
      }

      this.nhsrcSealControlNumber = this.statusService.recordsSubject.getValue()["dataModel"]["serialNumber"];
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createHearingScreening();
        } else {
            this.updateHearingScreening();
        }
    }

    createHearingScreening() {
        this.form.get("patient").setValue(this.patientId)
        this.form.get("hearingScreeningCenter").setValue(JSON.parse(localStorage.getItem('user')).facility.facility)
        this.hearingScreeningService.add(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Hearing Screening added successfully', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      
    updateHearingScreening() {
        this.form.get("hearingScreeningCenter").setValue(JSON.parse(localStorage.getItem('user')).facility.facility)
        this.hearingScreeningService.update(this.form.value, this.modelId, this.versionId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    setSeal() {
        var seal = JSON.parse(localStorage.getItem('statusRecord')).dateModel.serialNumber
        this.form.get("")
    }
}

export function initScreeningValidator(count:number, dob: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return count > 0 ? null : (new Date(control.value).getTime() - new Date(dob).getTime()) / (1000 * 3600 * 24) > 167 ? {invalidScreeningDate: true} : null;
    };
  }