import { animate, animateChild, group, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

export const alertAnimations = 
  trigger('alertAnimations', [
    transition(':enter',[
      style({ opacity: 0 }),
      animate(75, style({ opacity: 1 })),
      animate(150, keyframes(
        [
         style({ filter: 'brightness(1)' }),
         style({ filter: 'brightness(1.25)' }),
         style({ filter: 'brightness(1)' })
        ]))
    ]),
    transition(':leave',[
      style({ opacity: 1 }),
      animate(75, style({ opacity: 0 }))
    ])
  ]);