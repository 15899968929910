<table mat-table [dataSource]="data" matSort (matSortChange)="sortData($event)" class="table-bordered">
  <ng-container [matColumnDef]="column.key" *ngFor="let column of columns">
    <ng-container *ngIf="column.canSort; else noSort">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="{{ column.key }}">
        {{ column.columnName }}
      </th>
    </ng-container>
    <ng-template #noSort>
      <th mat-header-cell [attr.colspan]="column.colSpan" [hidden]="column.isHidden" *matHeaderCellDef class="pl-2 pr-2 text-center">{{ column.columnName }}</th>
    </ng-template>
    <td mat-cell *matCellDef="let element" class="pl-2 pr-2">{{ element[column.key] }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
