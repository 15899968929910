import { query } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { SealsService } from '@app/admin/seal/seals.service';
import { PatientService } from '@app/patient/patient.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { PrintService } from '@app/shared/print/print.service';
import Utils from '@app/shared/utils';
import { SealDistributionService } from '@app/unit/seal/seal-distribution.service';
import { UserService } from '@app/user/user.service';
import { merge } from 'lodash';
import { Observable, of } from 'rxjs';
import { debounceTime, first, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { threadId } from 'worker_threads';
import { HearingScreeningService } from './hearing-screening.service';
import { StatusService } from './status.service';

@Component({
  selector: 'app-hearing-screening',
  templateUrl: './hearing-screening.component.html',
  styleUrls: ['./hearing-screening.component.scss']
})
export class HearingScreeningComponent implements OnInit {

  columns = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of Screening'},
    {name: 'Hyperbilirubinemia requiring transfusion', prop: 'hyperbilirubinemia'},
    {name: 'Ventilation more than 48 hours', prop: 'ventilation'},
    {name: 'NICU admission more than 48 hours', prop: 'nicu'},
    {name: 'Ototoxic medication'},
    {name: 'Family history of permanent childhood hearing loss', prop: 'familyHistoryHearingLoss'},
    {name: 'Craniofacial anomalies with deformed pinna or ear canal', prop: 'craniofacialAnomalies'},
    {name: 'Features associated with syndrome', prop: 'featuresAssociated'},
    {name: 'In-utero infections', prop: 'inUteroInfections'},
    {name: 'None'},
    {name: 'Type of Screening'},
    {name: 'Type of Screening (Specify)', prop: 'typeOfScreeningIndicate'},
    {name: 'Method of Screening'},
    {name: 'Method of Screening (Specify)', prop: 'methodOfScreeningIndicate'},
    {name: 'Result Right'},
    {name: 'Result Left'},
    {name: 'Screener Name'},
    {name: 'Registry Card Number'},
    {name: 'NHSRC Seal Control Number', prop: 'nhsrcSealControlNumber'}
  ]

  dateControlNames = ['dateOfScreening'];
  hearingScreening$ : Observable<any>;
  hearingScreeningPage$ : Observable<any>;

  form: FormGroup;
  loading = false;
  submitted = false;
  nsNumber : boolean;
  refused : boolean;
  showScreening : boolean = false;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;

  id: string;
  patientId: string;
  modelId: string;
  versionId: string;
  statusId: string;
  vnsNumber: any;

  selectedNumber: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  sNumber = new FormControl();
  filteredSNumbers$: Observable<string[]>;
  //sNumbers: string[] = ['1', '2', '3']
  @Input() allSNumbers$: Observable<any>;
  //facilityControl = new FormControl();
  sNumberOptions: Observable<string[]>;
  sealArray = [];

  @ViewChild('sNumberInput') sNumberInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private formBuilder: FormBuilder,
    private hearingScreeningService: HearingScreeningService,
    private statusService: StatusService,
    private route: ActivatedRoute,
    private printService: PrintService,
    private alertService: AlertService,
    private ennhsrService: EnnhsrService,
    private userService: UserService,
    private patientService: PatientService,
    private sealsService: SealsService,
    private sealDistributionService: SealDistributionService
  ) { }

  ngOnInit() {
    this.patientId = this.patientService.recordSubject.getValue()["modelId"]
    this.id = this.route.snapshot.params['id'];

    this.editable = true;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )

    this.form = this.formBuilder.group({
      patient: [''],
      status: ['', Validators.required],
      notDoneReason: [''],
      refusedReason: [''],
      serialNumber: [''], 
      hSC:[this.userService.userValue["facility"]["facility"]]
    });

    /* this.allSNumbers$ = this.sealDistributionService.recordsObservable.pipe(
      map(seals => {
        seals["results"].forEach(element => {
          element["dataModel"]["seals"]["results"].forEach(seal => {
            if(seal["dataModel"]["patient"] === null)
              this.sealArray.push(seal["dataModel"]["batchCode"] + seal["dataModel"]["serialNumber"])
          });
        });
        return this.sealArray
      })
    ); */
    
    /* this.allSNumbers$.subscribe(s => {
      s = this.sealArray;
    })
 */
    this.sNumberOptions = this.form.controls['serialNumber'].valueChanges.pipe(
      startWith(''),
      //debounceTime(300),
      mergeMap(value => this.searchSeal(value))
    );
    this.hearingScreeningService.getAll(this.id, 0).subscribe();

    this.hearingScreening$ = this.hearingScreeningService.recordsObservable.pipe(
      map(hearingScreening => hearingScreening["results"].map(x => {
        var data = Utils.mapModelToColumns(x,this.dateControlNames) 
        //data['nhsrcSealControlNumber'] = JSON.parse(localStorage.getItem('patientRecord'))["dataModel"]["serialNumber"];
        return data;
        }
      ))
    )

    this.hearingScreeningPage$ = this.hearingScreeningService.recordsObservable

    this.statusService.getByPatient(this.id).subscribe(
      res => {
        if(res) {
          this.versionId = res["versionId"];
          this.modelId = res["modelId"];
          this.form.patchValue(Utils.mapModelToForm(res));
          if(res['dataModel']['status'] == "done" && res['dataModel']['status'] == "not done" && res['dataModel']['status'] !== "refused") {
            this.refused = false;
          } else if (res['dataModel']['status'] == "refused") {
            this.refused = true;
          }
          if(res['dataModel']['serialNumber'] && res['dataModel']['serialNumber']!= '' && res['dataModel']['serialNumber'].length == 11) {
            this.form.get('serialNumber').disable();
            this.nsNumber = true;
          }
        }
      }
    );

    this.statusService.recordObservable.subscribe(
      res => {
        if(res && res['dataModel']['status'] != 'refused'){
          this.showScreening = true;
        } else { this.showScreening = false }
      }
    )
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (!this.statusService.recordValue) {
      this.createStatus();
      this.checkValue()
    } else {
      this.updateStatus();
      this.checkValue()
    }    
  }

  getPageHearingScreening(page: string) {
    this.hearingScreeningService.getAll(this.id, page).subscribe();
  }

  createStatus() {
    this.form.get("patient").setValue(this.patientId)
    this.form.get("hSC").setValue(this.userService.userValue["facility"]["facility"]);
    this.statusService.add(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success('Patient Screening Details added successfully', { keepAfterRouteChange: true });
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
      
  updateStatus() {
    this.form.get("hSC").setValue(this.userService.userValue["facility"]["facility"]);
    this.statusService.update(this.form.value, this.modelId, this.versionId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success('Update successful', { keepAfterRouteChange: true });
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  deleteHearingScreening(id: string) {
    this.hearingScreeningService.delete(id).pipe(first())
        .subscribe();
  }

  cancelEdit() {
    if(confirm("Unsaved changes will be lost. Proceed?")){
      this.ngOnInit();
      this.editing = false;
      this.alertService.clear();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.sealArray.filter(sNumber => sNumber.toLowerCase().includes(filterValue)).sort();
  }
  
  checkValue() {
    setTimeout(() => {
      this.statusService.getByPatient(this.id).subscribe(
        res => {
          if(res) {
            this.versionId = res["versionId"];
            this.modelId = res["modelId"];
            this.form.patchValue(Utils.mapModelToForm(res));
            if(res['dataModel']['status'] == "done" && res['dataModel']['status'] == "not done" && res['dataModel']['status'] !== "refused") {
              this.refused = false;
            } else if (res['dataModel']['status'] == "refused") {
              this.refused = true;
            }
            if(res['dataModel']['serialNumber'] && res['dataModel']['serialNumber']!= '' && res['dataModel']['serialNumber'].length == 11) {
              this.form.get('serialNumber').disable();
              this.nsNumber = true;
            }
          }
        }
      );
    }, 500)
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(confirm("Selected Serial Number will be permanent or cannot be changed. Proceed?")) { 
      this.form.controls['serialNumber'].setValue(event.option.viewValue);
      this.selectedNumber = event.option.viewValue;
      this.nsNumber = true;
    }
  }

  checkOptions() {
    setTimeout(() => {
      if(!this.selectedNumber || this.selectedNumber !== this.form.get("serialNumber").value) {
        this.alertService.warn('Please select valid Serial Number', { keepAfterRouteChange: true });
        this.form.get("serialNumber").setValue(null);
        this.selectedNumber = '';
        this.submitted = false;
      }
    }, 500);
  }

  search(name): Observable<string[]> {
    return this.ennhsrService.sNum(name).pipe(
    map(sNumbers => sNumbers["results"]))
  }

  searchSeal(seal): Observable<string[]> {
    if(!seal || seal.length < 10)
      return of([]);
    return this.sealsService.getAllBySerialNumber2(seal.substring(1), seal.charAt(0)).pipe(
      map(sNumbers => sNumbers["results"].map(x => x.dataModel['batchCode'] + x.dataModel['serialNumber']).sort()))
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category A') || user['facility']['roles'].includes('Category B') || user['facility']['roles'].includes('Category C') || user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }
  
}
