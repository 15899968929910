import { Component, Host, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable, Observer, of } from 'rxjs';
import { debounceTime, first, map, startWith, switchMap } from 'rxjs/operators';
import Utils from '../utils';
import { PsgcService } from './psgc.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() streetControlName: string;
  @Input() psgcControl: FormControl;
  @Input() psgcControlName: string; //for removal
  @Input() addressLabel: string = "Address";
  @Input() streetControl: FormControl;
  @Input() submitted: boolean;
  @Input() cityMunRequired: boolean;
  @Input() brgyRequired: boolean;

  provinceControl = new FormControl();
  provinceFilter = new FormControl();
  provinceOptions;
  provinceOptionsFiltered;

  cityMunControl = new FormControl({value: null, disabled: true});
  cityMunFilter = new FormControl();
  cityMunOptions;
  cityMunOptionsFiltered;

  brgyControl = new FormControl({value: null, disabled: true});
  brgyFilter = new FormControl();
  brgyOptions;
  brgyOptionsFiltered;

  constructor(
    private psgcService: PsgcService
  ) { }

  ngOnInit() {
    if(this.psgcControl?.errors?.required){
      this.provinceControl = new FormControl(null, Validators.required);
      if(this.cityMunRequired)
        this.cityMunControl = new FormControl(null, Validators.required);
      if(this.brgyRequired)
        this.brgyControl = new FormControl(null, Validators.required);
    }

    if(this.psgcControl.value == ''){
      this.psgcControl.valueChanges.pipe(first()).subscribe(value => {
        this.provinceControl.setValue(`${value.substring(0,4)}00000`);
        if(!(value.substring(4,6) === '00'))
          this.cityMunControl.setValue(`${value.substring(0,6)}000`);            
        if(!(value.substring(6) === '000'))
          this.brgyControl.setValue(value);
      })
    }
    
    this.psgcService.getAll('province').subscribe(res => {
      this.provinceOptionsFiltered = this.provinceOptions = res.result.sort((a, b) => a.name.localeCompare(b.name));
    })

    this.provinceControl.valueChanges.subscribe(value => {
      this.cityMunControl.reset({value: null, disabled: !value});
      this.psgcControl.setValue(value);
      if(value)
        this.psgcService.getAll('municipality-city',value.substring(0,4)).subscribe(res => this.cityMunOptions = this.cityMunOptionsFiltered = res.result.sort((a, b) => a.name.localeCompare(b.name)));
    })

    this.cityMunControl.valueChanges.subscribe(value => {
      this.brgyControl.reset({value: null, disabled: !value});
      this.psgcControl.setValue(value || this.provinceControl.value);
      if(value)
        this.psgcService.getAll('barangay',value.substring(0,6)).subscribe(res => this.brgyOptions = this.brgyOptionsFiltered = res.result.sort((a, b) => a.name.localeCompare(b.name)))
    })

    this.brgyControl.valueChanges.subscribe(value => {
      this.psgcControl.setValue(value || this.cityMunControl.value);
    })

    this.provinceFilter.valueChanges.subscribe(val => {
      this.provinceOptionsFiltered = this.provinceOptions.filter(e => e.name.toLowerCase().includes(val?.toLowerCase() || ''))
    })

    this.cityMunFilter.valueChanges.subscribe(val => {
      this.cityMunOptionsFiltered = this.cityMunOptions.filter(e => e.name.toLowerCase().includes(val?.toLowerCase() || ''))
    })

    this.brgyFilter.valueChanges.subscribe(val => {
      this.brgyOptionsFiltered = this.brgyOptions.filter(e => e.name.toLowerCase().includes(val?.toLowerCase() || ''))
    })    

    var psgc = this.psgcControl.value
    if(psgc) {
      this.provinceControl.setValue(`${psgc.substring(0,4)}00000`);
      if(!(psgc.substring(4,6) === '00'))
        this.cityMunControl.setValue(`${psgc.substring(0,6)}000`);            
      if(!(psgc.substring(6) === '000'))
        this.brgyControl.setValue(psgc);
    }
  }
}
