import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AddPatientComponent } from './forms/add-patient/add-patient.component';
const formsModule = () => import('./forms/forms.module').then(x => x.FormsModule);

const routes: Routes = [
  { path: '' , component: LayoutComponent },
  { path: 'add' , component: AddPatientComponent },
  { path: ':id' , loadChildren: formsModule }  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientRoutingModule { }
