<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Screening
                <button type="button" (click)="this.printService.print(['details', print], 'Screening Form')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>
            </div>
            <div #print class="card-body"> 
                <fieldset>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="status">Screening Status</label>
                            <div class="py-2">
                                <div class="form-row form-check form-check-inline">
                                    <mat-checkbox formControlName="screeningOffered">
                                        Screening Offered?
                                    </mat-checkbox>
                                </div>
                                <div class="form-row">
                                    <mat-radio-group formControlName="screeningStatus">
                                        <mat-radio-button class="mr-3" [value]="'accepted'">Accepted</mat-radio-button>
                                        <mat-radio-button [value]="'refused'">Refused</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label>Risk Factors</label>
                    <div *ngIf="hyperbilirubinemiaNo.checked && ventilationNo.checked && nicuNo.checked && ototoxicMedicationNo.checked && familyHistoryHearingLossNo.checked && craniofacialAnomaliesNo.checked && featuresAssociatedNo.checked && inUteroInfectionsNo.checked" class="ml-3 pb-3 print-red d-none">NO RISK FACTORS</div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="hyperbilirubinemia">Hyperbilirubinemia requiring transfusion</label>
                            <div>
                                <mat-radio-group formControlName="hyperbilirubinemia" [ngClass]="{ 'is-invalid': submitted && f.hyperbilirubinemia.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'" #hyperbilirubinemiaNo>No</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.hyperbilirubinemia.errors" class="invalid-feedback">
                                    <div *ngIf="f.hyperbilirubinemia.errors.required">Risk Factors are required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="ventilation">Ventilation more than 48 hours</label>
                            <div>
                                <mat-radio-group formControlName="ventilation" [ngClass]="{ 'is-invalid': submitted && f.ventilation.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'" #ventilationNo>No</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.ventilation.errors" class="invalid-feedback">
                                    <div *ngIf="f.ventilation.errors.required">Risk Factors are required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="nicu">NICU admission more than 48 hours</label>
                            <div>
                                <mat-radio-group formControlName="nicu" [ngClass]="{ 'is-invalid': submitted && f.nicu.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'" #nicuNo>No</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.nicu.errors" class="invalid-feedback">
                                    <div *ngIf="f.nicu.errors.required">Risk Factors are required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="ototoxicMedication">Ototoxic medication</label>
                            <div>
                                <mat-radio-group formControlName="ototoxicMedication" [ngClass]="{ 'is-invalid': submitted && f.ototoxicMedication.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'" #ototoxicMedicationNo>No</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.ototoxicMedication.errors" class="invalid-feedback">
                                    <div *ngIf="f.ototoxicMedication.errors.required">Risk Factors are required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="familyHistoryHearingLoss">Family history of permanent childhood hearing loss</label>
                            <div>
                                <mat-radio-group formControlName="familyHistoryHearingLoss" [ngClass]="{ 'is-invalid': submitted && f.familyHistoryHearingLoss.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'" #familyHistoryHearingLossNo>No</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.familyHistoryHearingLoss.errors" class="invalid-feedback">
                                    <div *ngIf="f.familyHistoryHearingLoss.errors.required">Risk Factors are required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="craniofacialAnomalies">Craniofacial anomalies with deformed pinna or ear canal</label>
                            <div>
                                <mat-radio-group formControlName="craniofacialAnomalies" [ngClass]="{ 'is-invalid': submitted && f.craniofacialAnomalies.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'" #craniofacialAnomaliesNo>No</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.craniofacialAnomalies.errors" class="invalid-feedback">
                                    <div *ngIf="f.craniofacialAnomalies.errors.required">Risk Factors are required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="featuresAssociated">Features associated with syndrome</label>
                            <div>
                                <mat-radio-group formControlName="featuresAssociated" [ngClass]="{ 'is-invalid': submitted && f.featuresAssociated.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'" #featuresAssociatedNo>No</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.featuresAssociated.errors" class="invalid-feedback">
                                    <div *ngIf="f.featuresAssociated.errors.required">Risk Factors are required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="inUteroInfections">In-utero infections</label>
                            <div>
                                <mat-radio-group formControlName="inUteroInfections" [ngClass]="{ 'is-invalid': submitted && f.inUteroInfections.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'" #inUteroInfectionsNo>No</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.inUteroInfections.errors" class="invalid-feedback">
                                    <div *ngIf="f.inUteroInfections.errors.required">Risk Factors are required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="none">No Risk Factors</label>&nbsp;
                            <mat-icon matTooltipPosition="right"
                                matTooltip="This validates that the patient has no underlying risk factors"
                                matTooltipClass="custom-tooltip-blue">
                                <i class="mdi mdi-help-circle"></i>
                            </mat-icon>
                            <div>
                                <mat-radio-group formControlName="none">
                                    <mat-radio-button class="mr-3" [value]="'Yes'">Yes</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'No'">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div> -->
                    <label>Screening</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="dateOfScreening">Date of Screening</label>
                            <div>
                                <input formControlName="dateOfScreening" matInput [matDatepicker]="picker" [ngClass]="{ 'is-invalid': submitted && f.dateOfScreening.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <div *ngIf="submitted && f.dateOfScreening.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfScreening.errors.required">Date of Screening is required</div>
                                    <div *ngIf="f.dateOfScreening.errors.invalidScreeningDate">Date of Screening must be within 167 days of the patient's date of birth</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="timeOfScreening">Time of Screening</label>
                            <!-- <input type="text" class="form-control" formControlName="timeOfBirth" /> -->
                            <!-- <ngx-timepicker-field [controlOnly]="true" formControlName="timeOfBirth" class="form-control p-1 h-auto bg-white" [disabled]="this.editable && !this.editing ? true: null"></ngx-timepicker-field> -->
                            <input-time formControlName="timeOfScreening" class="d-flex form-control px-2" [ngClass]="{ 'is-invalid': submitted && f.timeOfScreening.errors }"></input-time> 
                            <div *ngIf="submitted && f.timeOfScreening.errors" class="invalid-feedback">
                                <div *ngIf="f.timeOfScreening.errors.required">Time of Screening is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="typeOfScreening">Type of Screening</label>
                            <div>
                                <mat-radio-group formControlName="typeOfScreening" [ngClass]="{ 'is-invalid': submitted && f.typeOfScreening.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Initial'">Initial</mat-radio-button>
                                    <mat-radio-button class="mr-3" #rescreen [value]="'Rescreen'">Rescreen</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.typeOfScreening.errors" class="invalid-feedback">
                                    <div *ngIf="f.typeOfScreening.errors.required">Type of Screening is required</div>
                                </div>
                            </div>
                            <div *ngIf="rescreen.checked">
                                <label for="typeOfScreeningIndicate">Indicate Reason</label>
                                <textarea rows="2" formControlName="typeOfScreeningIndicate" class="form-control" ></textarea>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="">Method of Screening</label>
                            <div>
                                <mat-radio-group formControlName="methodOfScreening" [ngClass]="{ 'is-invalid': submitted && f.methodOfScreening.errors }">
                                    <mat-radio-button class="mr-3" [value]="'OAE'">OAE</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'AABR'">AABR</mat-radio-button>
                                    <mat-radio-button class="mr-3" #others [value]="'Others'">Others</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.methodOfScreening.errors" class="invalid-feedback">
                                    <div *ngIf="f.methodOfScreening.errors.required">Method of Screening is required</div>
                                </div>
                            </div>
                            <div *ngIf="others.checked">
                                <label for="methodOfScreeningIndicate">Specify</label>
                                <input type="text" formControlName="methodOfScreeningIndicate" onfocus="this.value=''" class="form-control"/>
                            </div>
                            <!-- <ng-template #othersEmpty>
                                <input type="hidden" formControlName="methodOfScreeningIndicate" value="''" class="form-control"/>
                            </ng-template> -->
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="resultRight">Result Right</label>
                            <div>
                                <mat-radio-group formControlName="resultRight" [ngClass]="{ 'is-invalid': submitted && f.resultRight.errors }">
                                    <mat-radio-button class="mr-3" [value]="'pass'">Pass</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'refer'" #resRightNo><span *ngIf="resRightNo.checked" class="print-red">Refer</span><span *ngIf="!resRightNo.checked">Refer</span></mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'not performed'">Not Performed</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.resultRight.errors" class="invalid-feedback">
                                    <div *ngIf="f.resultRight.errors.required">Result Right is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="resultLeft">Result Left</label>
                            <div>
                                <mat-radio-group formControlName="resultLeft" [ngClass]="{ 'is-invalid': submitted && f.resultLeft.errors }">
                                    <mat-radio-button class="mr-3" [value]="'pass'">Pass</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'refer'" #resLeftNo><span *ngIf="resLeftNo.checked" class="print-red">Refer</span><span *ngIf="!resLeftNo.checked">Refer</span></mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'not performed'">Not Performed</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.resultLeft.errors" class="invalid-feedback">
                                    <div *ngIf="f.resultLeft.errors.required">Result Left is required</div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="screenerName">Screener Name</label>
                            <input type="text" formControlName="screenerName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.screenerName.errors }" />
                            <div *ngIf="submitted && f.screenerName.errors" class="invalid-feedback">
                                <div *ngIf="f.screenerName.errors.required">Screener Name is required</div>
                            </div>
                        </div>
                        <!-- <div class="form-group col-4">
                            <label for="registryCardNumber">Registry Card Number</label>
                            <input type="number" formControlName="registryCardNumber" class="form-control"/>
                        </div> -->
                        <div class="form-group col-6">
                            <label for="nhsrcSealControlNumber">NHSRC Seal Control Number</label>
                            <input disabled type="text" value="{{nhsrcSealControlNumber}}" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>