import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@app/shared/utils';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FeedbackService } from '../feedback.service';
import { Location } from '@angular/common';

@Component({ templateUrl: 'list.component.html' })
export class ListComponent implements OnInit {
    feedback$ : Observable<any>;
    feedbackPage$ : Observable<any>;
    columns = [{ name: 'Priority' }, { name: 'Date Reported', prop: 'date' }, { name: 'Reported By                    ', prop: 'from' }, { name: 'Facility                    ', prop: 'facility' }, { name: 'Role          ', prop: 'role' }, { name: 'Email                    ', prop: 'email' }, { name: 'Contact          ', prop: 'contact' }, { name: 'Topic                    ', prop: 'topic' } , { name: 'Status' }];

    constructor(private feedbackService: FeedbackService, private router: Router, private location: Location) {}

    ngOnInit() {
        this.feedbackService.getAll(0).subscribe();
        this.feedback$ = this.feedbackService.recordsObservable.pipe(
          map(feedback => feedback["results"].map(x => Utils.mapModelToForm(x,['date'])))
        )
        this.feedbackPage$ = this.feedbackService.recordsObservable;
    }

    deleteFeedback(id: string) {
        this.feedbackService.delete(id).pipe(first())
        .subscribe();
    }

    getPage(page: string) {
        this.feedbackService.getAll(page).subscribe();
    }

    searchFeedback(keyword: string) {
        if(keyword != '')
            this.feedbackService.search(keyword).subscribe();
        else
            this.feedbackService.getAll(0).subscribe();
    }
}
