import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class ScreeningRegistryReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Screened By'
    })
    screenedBy: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Date of Screening'
    })
    dateOfScreening: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Type of Screening'
    })
    typeOfScreening: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Last Name'
    })
    lastName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'First Name'
    })
    firstName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Middle Name'
    })
    middleName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Philhealth PIN'
    })
    philhealthPin: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Date of Birth'
    })
    dateOfBirth: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Time of Birth'
    })
    timeOfBirth: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Birth Order'
    })
    birthOrder: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Age of Gestation'
    })
    gestationAge: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Birth Weight'
    })
    birthWeight: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Sex'
    })
    sex: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Hyperbilirubinemia Requiring Transfusion'
    })
    hyperbilirubinemia: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Ventilation More Than 48 Hours'
    })
    ventilation: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'NICU Admission More Than 48 Hours'
    })
    nicu: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Ototoxic Medication'
    })
    ototoxicMedication: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Family History of Permanent Childhood Hearing Loss'
    })
    familyHistoryHearingLoss: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Craniofacial Anomalies with Deformed Pinna or Ear Canal'
    })
    craniofacialAnomalies: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Features Associated with Syndrome'
    })
    featuresAssociated: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'In-Utero Infections'
    })
    inUteroInfections: string;
}
