import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PsgcService {

  constructor(public http: HttpClient) { }

  getAll(level, code?) {
    return this.http.get<any>(`${environment.apiUrl}/api/psgc/${level}${code ? '?code='+code : ''}`)
    // return this.http.get<any>(`${environment.apiUrl}/api/psgc/code/133900000`)
  }

  search(level: string, keyword: string) {
    return this.http.get<any>(`${environment.apiUrl}/api/psgc/search/${level}?keyword=${keyword}`)
    .pipe(
      map(res=> {
        return res.result;
      })
    );
  }

  get(code: any) {
    if(code == '133900000' || code == '133900000-1') {
      return this.search('municipality', 'CITY OF MANILA').pipe(
        map(res=> {
          return res[0];
        })
      );
    }      
    if(code == '133900000-2') {
      return this.search('province', 'NCR, CITY OF MANILA').pipe(
        map(res=> {
          return res[0];
        })
      );
    }
    return this.http.get<any>(`${environment.apiUrl}/api/psgc/code/${code}`)
    .pipe(
      map(res=> {
        return res.result;
      })
    );
  }
}
