import { ErrorHandler, Injectable } from '@angular/core';
import { FeedbackService } from '@app/admin/feedback/feedback.service';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandlerService extends ErrorHandler {

  constructor(private feedbackService: FeedbackService) {
    super();
  }

  handleError(error) {
    if(error.rejection?.stack)
      this.feedbackService.pushError(error.rejection.stack);
    super.handleError(error);
  }
}
