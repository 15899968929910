import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SealRoutingModule } from './seal-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { RequestsComponent } from './requests/requests.component';
import { ProcessingComponent } from './processing/processing.component';
import { ShippingComponent } from './shipping/shipping.component';
import { CompletedComponent } from './completed/completed.component';
import { AddEditComponent, AddEditComponentDialog } from './processing/add-edit/add-edit.component';

import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { AddEditSealComponent, AddEditSealComponentDialog } from './processing/add-edit-seal/add-edit-seal.component';
import { CanceledComponent } from './canceled/canceled.component';


@NgModule({
  declarations: [LayoutComponent, RequestsComponent, ProcessingComponent, ShippingComponent,CompletedComponent, CanceledComponent,AddEditComponent, AddEditComponentDialog, AddEditSealComponent, AddEditSealComponentDialog],
  imports: [
    CommonModule,
    SealRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    MatRadioModule
  ]
})
export class SealModule { }
