import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';

export const resultsAnimations =
  trigger('resultsAnimations', [
    transition(':enter', 
      [
        style({ opacity: 0 }),
        animate(150, style({ opacity: 1 }))
      ]
    )
  ]);