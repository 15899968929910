<!-- <p>add-edit works!</p> -->
<div class="container">
    <div class="card">
        <div class="card-header">
            Select a request
        </div>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group col-4">
                    <label for="referenceID">Reference ID</label>
                    <select class="form-control" formControlName="referenceID" (change)="changeRequest($event.target.value)">
                        <option value="">Choose reference ID</option>
                        <option *ngFor="let request of sealRequest$ | async" [value]="request.dataModel.referenceID">{{request.dataModel.facility.facilityName}}: {{request.dataModel.referenceID | shortId | uppercase }}
                    </select>
                </div>
                <div class="form-group col-4">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Process
                    </button>
                    <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                </div>
            </form>
        </div>
    </div>
</div>