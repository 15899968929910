import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddEditABRComponent } from './auditory-evoked-potentials/add-edit-abr/add-edit-abr.component';
import { AddEditASSRComponent } from './auditory-evoked-potentials/add-edit-assr/add-edit-assr.component';
import { AddEditTympanometryComponent } from './confirmatory-other-tests/add-edit-tympanometry/add-edit-tympanometry.component';
import { AddEditAcousticReflexTestComponent } from './confirmatory-other-tests/add-edit-acoustic-reflex-test/add-edit-acoustic-reflex-test.component';
import { LayoutComponent } from './layout/layout.component';
import { ScreeningResultsComponent } from './screening-results/screening-results.component';
import { AddEditSpeechPathologySessionComponent } from './speech-therapy/add-edit-speech-pathology-session/add-edit-speech-pathology-session.component';
import { AddEditPEDSScreeningComponent } from './developmental-monitoring/add-edit-peds-screening/add-edit-peds-screening.component';
import { AddEditComplicationsOutsideSurgeryComponent } from './surgical-hearing-interventions-events/add-edit-complications-outside-surgery/add-edit-complications-outside-surgery.component';
import { AddEditExplantedDeviceComponent } from './surgical-hearing-interventions-events/add-edit-explanted-device/add-edit-explanted-device.component';
import { AddEditIntraOpTelemetryComponent } from './cochlear-implant-programming/add-edit-intra-op-telemetry/add-edit-intra-op-telemetry.component';
import { AddEditSessionsComponent } from './cochlear-implant-programming/add-edit-sessions/add-edit-sessions.component';
import { AddEditHearingScreeningComponent } from './hearing-screening/add-edit-hearing-screening/add-edit-hearing-screening.component';
import { AddEditHearingAidDevicesComponent } from './hearing-aid-devices/add-edit-hearing-aid-devices/add-edit-hearing-aid-devices.component';
import { AddEditInterventionComponent } from './surgical-hearing-interventions/add-edit-intervention/add-edit-intervention.component';

const routes: Routes = [
  { path: '' , component: LayoutComponent,
    children: [
      { path: 'auditory-evoked-potentials/add-abr', component: AddEditABRComponent },
      { path: 'auditory-evoked-potentials/edit-abr/:id', component: AddEditABRComponent },
      { path: 'auditory-evoked-potentials/add-assr', component: AddEditASSRComponent },
      { path: 'auditory-evoked-potentials/edit-assr/:id', component: AddEditASSRComponent },
      { path: 'confirmatory-other-tests/add-tympanometry', component: AddEditTympanometryComponent },
      { path: 'confirmatory-other-tests/edit-tympanometry/:id', component: AddEditTympanometryComponent },
      { path: 'confirmatory-other-tests/add-acoustic-reflex-test', component: AddEditAcousticReflexTestComponent },
      { path: 'confirmatory-other-tests/edit-acoustic-reflex-test/:id', component: AddEditAcousticReflexTestComponent },
      { path: 'speech-therapy/add-speech-pathology-session', component: AddEditSpeechPathologySessionComponent },
      { path: 'speech-therapy/edit-speech-pathology-session/:id', component: AddEditSpeechPathologySessionComponent },
      { path: 'developmental/add-peds-screening', component: AddEditPEDSScreeningComponent },
      { path: 'developmental/edit-peds-screening/:id', component: AddEditPEDSScreeningComponent },
      { path: 'surgical-hearing-interventions-events/add-complications-outside-surgery', component: AddEditComplicationsOutsideSurgeryComponent },
      { path: 'surgical-hearing-interventions-events/edit-complications-outside-surgery/:id', component: AddEditComplicationsOutsideSurgeryComponent },
      { path: 'surgical-hearing-interventions-events/add-explanted-device', component: AddEditExplantedDeviceComponent },
      { path: 'surgical-hearing-interventions-events/edit-explanted-device/:id', component: AddEditExplantedDeviceComponent },
      { path: 'cochlear-implant-programming/add-intra-op-telemetry', component: AddEditIntraOpTelemetryComponent },
      { path: 'cochlear-implant-programming/edit-intra-op-telemetry/:id', component: AddEditIntraOpTelemetryComponent },
      { path: 'cochlear-implant-programming/add-sessions', component: AddEditSessionsComponent },
      { path: 'cochlear-implant-programming/edit-sessions/:id', component: AddEditSessionsComponent },
      { path: 'hearing-screening/add-hearing-screening', component: AddEditHearingScreeningComponent },
      { path: 'hearing-screening/edit-hearing-screening/:id', component: AddEditHearingScreeningComponent },
      { path: 'hearing-aid-devices/add-hearing-aid-devices', component: AddEditHearingAidDevicesComponent },
      { path: 'hearing-aid-devices/edit-hearing-aid-devices/:id', component: AddEditHearingAidDevicesComponent },
      { path: 'surgical-hearing-interventions/add-intervention', component: AddEditInterventionComponent },
      { path: 'surgical-hearing-interventions/edit-intervention/:id', component: AddEditInterventionComponent },
      { path: 'screening-results/:id', component: ScreeningResultsComponent },
    ]  
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormsRoutingModule { }
