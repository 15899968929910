import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class RiskFactorAggregateByFacilityReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Facility Name'
    })
    facilityName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Hyperbilirubinemia Requiring Transfusion'
    })
    riskFactor1: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Ventilation More Than 48 Hours'
    })
    riskFactor2: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'NICU Admission More Than 48 Hours'
    })
    riskFactor3: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Ototoxic Medication'
    })
    riskFactor4: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Family History of Permanent Childhood Hearing Loss'
    })
    riskFactor5: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Craniofacial Anomalies with Deformed Pinna or Ear Canal'
    })
    riskFactor6: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Features Associated with Syndrome'
    })
    riskFactor7: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'In-Utero Infections'
    })
    riskFactor8: string;
}
