import { Component, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { UsersService } from '@app/admin/users/users.service';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({ templateUrl: 'list.component.html' })
export class ListComponent implements OnInit, OnDestroy {
    users = null;
    noAdd: boolean;
    columns = [{ name: 'First Name' }, { name: 'Middle Name' }, { name: 'Last Name' }, { name: 'Username' }, { name: 'NSHRC Accreditation', prop: 'nhsrcAccredNo' }, { name: 'Accreditation Validity', prop: 'accredValidity' }, { name: 'Designation' }, { name: 'Profession' }, { name: 'PRC Number' , prop: 'prcNo'} ];
    private routeSubscription: Subscription;

    constructor(private usersService: UsersService, private router: Router, private location: Location,private route: ActivatedRoute) {}

    ngOnInit() {
        this.noAdd = this.route.snapshot['_routerState'].url.startsWith("/facility/users")
        
        if(this.noAdd)
            this.loadUsersByFacility();
        else
            this.loadUsers()
        let url = this.location.path();
        this.routeSubscription = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd && val.url == url) {
                if(this.noAdd)
                    this.loadUsersByFacility();
                else
                    this.loadUsers()
            }
        });
    } 

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    loadUsers() {
        this.usersService.getAll()
            .pipe(first())
            .subscribe(users => this.users = users);
    }

    loadUsersByFacility(){
        this.usersService.getAllByFacility(JSON.parse(localStorage.getItem('user')).facility.facilityCode)
            .pipe(first())
            .subscribe(users => this.users = users);
    }
    deleteUser(id: string) {
        this.usersService.delete(id)
            .pipe(first())
            .subscribe(() => this.users = this.users.filter(x => x.id !== id));
    }
}