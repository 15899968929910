<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Explanted Device Record
                <button type="button" (click)="this.printService.print(['details', print], 'Explanted Device Record')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group col 10">
                            <label for="explantedDevice">Explanted Device</label>
                            <div>
                                <mat-radio-group formControlName="explantedDevice">
                                    <mat-radio-button class="mr-3" [value]="'Advanced Bionics'">Advanced Bionics</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Cochlear'">Cochlear</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'MED-EL'">MED-EL</mat-radio-button>
                                    <mat-radio-button #explantedDeviceOthers [value]="'Others'">Others</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="explantedDeviceOthers.checked" class="form-group col-4">
                                    <label for="explantedDeviceSpecify">Specify</label>
                                    <input type="text" formControlName="explantedDeviceSpecify" class="form-control"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="typeAndSerialNumber">Type and Serial Number</label>
                            <input type="text" formControlName="typeAndSerialNumber" class="form-control"/>
                        </div>
                        <div class="form-group col-4">
                            <label for="dateOfFirstImplantation">Date of First Implantation</label>
                            <div>
                                <input matInput [matDatepicker]="picker" formControlName="dateOfFirstImplantation" [ngClass]="{ 'is-invalid': submitted && f.dateOfFirstImplantation.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <div *ngIf="submitted && f.dateOfFirstImplantation.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfFirstImplantation.errors.required">Date of First Implantation is required</div>
                                </div>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-10">
                            <label>Reason for Explantation</label>
                            <div class="form-row">
                                <mat-checkbox formControlName="reasonForExplantationInfection">
                                    <label class="form-check-label" for="reasonForExplantationInfection">
                                        Infection
                                    </label>
                                </mat-checkbox>
                            </div>
                            <div class="form-row">
                                <mat-checkbox #deviceFailure formControlName="reasonForExplantationDeviceFailure">
                                    <label class="form-check-label" for="reasonForExplantationDeviceFailure">
                                        Device Failure
                                    </label>
                                </mat-checkbox>
                            </div>
                            <div *ngIf="deviceFailure.checked" class="form-row">
                                <div class="form-group col-6">
                                    <mat-radio-group formControlName="deviceFailure">
                                        <mat-radio-button class="mr-3" [value]="'Soft'">Soft</mat-radio-button>
                                        <mat-radio-button [value]="'Hard'">Hard</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="form-row">
                                <mat-checkbox #others formControlName="reasonForExplantationOthers">
                                    <label class="form-check-label" for="reasonForExplantationOthers">
                                        Others
                                    </label>
                                </mat-checkbox>
                            </div>
                            <div class="form-row ml-3">
                                <div *ngIf="others.checked" class="form-group col-4">
                                    <label for="othersSpecify">Specify</label>
                                    <input type="text" formControlName="othersSpecify" class="form-control"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>