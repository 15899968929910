<div id="" class="form-header w-100 p-3 mb-2" style="z-index: 1;">
    <div class="d-flex justify-content-around text-center">
        <div class="col-2">
            <div>Active Users</div>
            <div *ngIf="activeUsersLoading" class="spinner-border spinner-border-sm mr-1"></div>
            <div *ngIf="!activeUsersLoading"><h5><b>{{activeUsers}}</b></h5></div>
            <!-- <br><h5><b>{{activeUsers}}</b></h5> -->
        </div>
        <div class="col-3">
            <div>Active Health Facilities</div>
            <div *ngIf="activeHealthFacilitiesLoading" class="spinner-border spinner-border-sm mr-1"></div>
            <div *ngIf="!activeHealthFacilitiesLoading"><h5><b>{{activeHealthFacilities}}</b></h5></div>
            <!-- <br><h5><b>{{activeHealthFacilities}}</b></h5> -->
        </div>
        <div class="col-3">
            <div>Patients Screened</div>
            <div *ngIf="patientsScreenedLoading" class="spinner-border spinner-border-sm mr-1"></div>
            <div *ngIf="!patientsScreenedLoading"><h5><b>{{patientsScreened}}</b></h5></div>
        </div>
        <div class="col-3">
            <div>Live Births</div>
            <div *ngIf="liveBirthsScreenedLoading" class="spinner-border spinner-border-sm mr-1"></div>
            <div *ngIf="!liveBirthsScreenedLoading"><h5><b>{{liveBirthsScreened}}</b></h5></div>
            <!-- <br><h5><b>{{liveBirthsScreened}}</b></h5> -->
        </div>
        <div class="col-1 d-flex align-items-center">
            <!-- <button type="button" (click)="export2Pdf()" class="text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-file-pdf"></i></button>
            <button type="button" (click)="export2Png()" class="text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-image"></i></button> -->
        </div>
    </div>
</div>
<div class=" pl-2 container">
    <div class="row h-100 pb-2 text-center align-items-center">
        <div id="getScreeningResultsReportContainer" class="col-8 pl-1 pr-1"><label *ngIf="screeningResultsReportLoading"><span class="spinner-border spinner-border-sm"></span><br>Loading Screening Results</label></div>
        <div id="getTotalLiveBirthsScreenedReportContainer" class="col-4 pl-1 pr-1"><label *ngIf="totalLiveBirthsScreenedReportLoading"><span class="spinner-border spinner-border-sm"></span><br>Loading Live Births Screened</label></div>
    </div>
    <div class="row h-100 pb-2 text-center align-items-center">
        <div id="getNoOfScreeningFacilitiesByRegionReportContainer" class="col-8 pl-1 pr-1"><label *ngIf="noOfScreeningFacilitiesByRegionReportLoading"><span class="spinner-border spinner-border-sm"></span><br>Loading Screening Facilities</label></div>
        <div id="getNoScreenedAccScreeningMethodReportContainer" class="col-4 pl-1 pr-1"><label *ngIf="noScreenedAccScreeningMethodReportLoading"><span class="spinner-border spinner-border-sm"></span><br>Loading Number Screened by Method</label></div>
    </div>
    <div class="row h-100 pb-2 text-center align-items-center">
        <div id="getTopFacilitiesInTotalNoOfLiveBirthsReportContainer" class="col-6 pl-1 pr-1"><label *ngIf="topFacilitiesInTotalNoOfLiveBirthsReportLoading"><span class="spinner-border spinner-border-sm"></span><br>Loading Top 10 Health Facilities in Number of Live Births</label></div>
        <div id="getTopFacilitiesInTotalNoScreenedReportContainer" class="col-6 pl-1 pr-1"><label *ngIf="topFacilitiesInTotalNoScreenedReportLoading"><span class="spinner-border spinner-border-sm"></span><br>Loading Top 10 Health Facilities in Number Screened</label></div>
    </div>
</div>