<div class="w-100 h-100">
    <div id="" class="form-header w-100 p-3 mb-3 sticky-top" style="z-index: 1;">
        <div class="ml-0">
            <div class="form-row">
                <div class="form-group col-md-10 col-lg-7 col-xl-7">
                    <label for="">Report</label>
                    <form>                       
                        <input type="text"
                                placeholder="Select Report"
                                aria-label="Number"
                                matInput
                                [formControl]="reportControl"
                                [matAutocomplete]="auto"     
                                [(ngModel)]="reportInput">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="reportChange()">
                        <mat-option *ngFor="let option of reportFilteredOptions | async" [value]="option" >
                            {{option}}
                        </mat-option>
                        </mat-autocomplete>
                        <button class="btn btn-primary text-dark position-fixed" type="button" (click)="clearReport()" matSuffix><i class="mdi mdi-close"></i></button>
                    </form>
                </div>
            </div>
            <fieldset [disabled]="reportInput == null || reportInput == ''">
                <div class="container ml-0 px-0">
                    <div class="row">
                        <div class="form-group col-4 pr-0">
                            <div class="pr-3">
                                <label for="">Date</label>
                                <div>
                                    <mat-radio-group class="formControl" [disabled]="dateDisable" [(ngModel)]="dateFilter.filterType">
                                        <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="allDates" (change)="restoreDateFiltToDefault()">All</mat-radio-button>
                                        <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="duration" (change)="restoreDateFiltToDefault()">Duration</mat-radio-button>
                                        <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="periodical" (change)="restoreDateFiltToDefault()">Periodical</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div *ngIf="dateFilter.filterType == 'duration'" class="pl-1">
                                <label for="">Duration</label>
                                <div class="pr-3">
                                    <div class="mb-2">
                                        <input matInput [matDatepicker]="picker" placeholder="From" [(ngModel)]="dateFilter.duration.from">
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                            <i class="mdi mdi-calendar"></i>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>
                                    <div>
                                        <input matInput [matDatepicker]="picker2" placeholder="To" [(ngModel)]="dateFilter.duration.to">
                                        <mat-datepicker-toggle matSuffix [for]="picker2">
                                            <i class="mdi mdi-calendar"></i>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="dateFilter.filterType == 'periodical'" class="pl-1">
                                <label for="">Periodical</label>
                                <div class="pr-3">
                                    <mat-radio-group class="formControl" [(ngModel)]="dateFilter.periodical.period">
                                        <mat-radio-button class="pr-2" value="monthly" (change)="restoreDateFiltToDefault()">Monthly</mat-radio-button>
                                        <mat-radio-button class="pr-2" value="quarterly" (change)="restoreDateFiltToDefault()">Quarterly</mat-radio-button>
                                        <mat-radio-button class="pr-2" value="yearly" (change)="restoreDateFiltToDefault()">Yearly</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="dateFilter.periodical.period == 'monthly'" class="pl-1">
                                    <div>
                                        <label for="">Month</label>
                                    </div>
                                    <mat-radio-group class="formControl container d-flex" [(ngModel)]="dateFilter.periodical.month">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button  [disabled]="facilityDisable" class="pr-2" value="01">January</mat-radio-button>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="02">February</mat-radio-button>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="03">March</mat-radio-button>
                                            </div>
                                        <!-- </div>
                                        <div class="row"> -->
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button class="col-4" [disabled]="facilityDisable" class="pr-2" value="04">April</mat-radio-button>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button class="col-4" [disabled]="facilityDisable" class="pr-2" value="05">May</mat-radio-button>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button class="col-4" [disabled]="facilityDisable" class="pr-2" value="06">June</mat-radio-button>
                                            </div>
                                        <!-- </div>
                                        <div class="row"> -->
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="07">July</mat-radio-button>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="08">August</mat-radio-button>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="09">September</mat-radio-button>
                                            </div>
                                        <!-- </div>
                                        <div class="row"> -->
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="10">October</mat-radio-button>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="11">November</mat-radio-button>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-4 p-0">
                                                <mat-radio-button [disabled]="facilityDisable" value="12">December</mat-radio-button>
                                            </div>
                                        </div>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="dateFilter.periodical.period == 'quarterly'" class="pl-1">
                                    <label for="">Quarter</label>
                                    <div>
                                        <mat-radio-group class="formControl" [(ngModel)]="dateFilter.periodical.quarter">
                                            <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="1">Q1</mat-radio-button>
                                            <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="4">Q2</mat-radio-button>
                                            <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="7">Q3</mat-radio-button>
                                            <mat-radio-button [disabled]="facilityDisable" value="10">Q4</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div *ngIf="dateFilter.periodical.period == 'yearly'" class="pl-1">
                                    <label for="">Year</label>
                                </div>
                                <div class="pr-3 pb-2 period-date d-flex">
                                    <div>
                                        <input class="form-control" type="text" placeholder="Year" [(ngModel)]="dateFilter.periodical.year">
                                    </div>
                                    <div class="d-inline-flex">
                                        <input matInput class="invisible" [matDatepicker]="picker3" [(ngModel)]="temp">
                                        <mat-datepicker-toggle class="ml-1" matSuffix [for]="picker3">
                                            <i class="mdi mdi-calendar"></i>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker3
                                            startView="multi-year"
                                            (yearSelected)="assignYear($event, picker3)">
                                        </mat-datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-4 px-0">
                            <div class="pr-1">
                                <div class="pr-3" *ngIf="showDateFacilityFilter">
                                    <label for="">Area</label>
                                    <div>
                                        <mat-radio-group class="formControl" [(ngModel)]="areaFilter.filterType">
                                            <mat-radio-button [disabled]="regionDisable" class="pr-2" value="all" (change)="restoreAreaFiltToDefault()">All</mat-radio-button>
                                            <mat-radio-button [disabled]="regionDisable" class="pr-2" value="city" (change)="restoreAreaFiltToDefault()" (click)="this.provinceControl.disable(); this.regControl.disable();">City / Municipal</mat-radio-button>
                                            <mat-radio-button [disabled]="regionDisable" class="pr-2" value="province" (change)="restoreAreaFiltToDefault()" (click)="this.provinceControl.enable(); this.regControl.disable();">Provincial</mat-radio-button>
                                            <mat-radio-button [disabled]="regionDisable" class="pr-2" value="region" (change)="restoreAreaFiltToDefault()" (click)="this.regControl.enable();">Regional</mat-radio-button>
                                            <mat-radio-button [disabled]="dlAllDisable" value="regionDL" (change)="restoreAreaFiltToDefault()" (click)="this.regControl.enable();"><i>Download all regions</i></mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row ml-3">
                                <div class="form-group" *ngIf="areaFilter.filterType == 'city'" [ngClass]="{ 'col-6': areaFilter.filterType == 'city' }">
                                    <label [for]="cityMunControl">City / Municipality</label>
                                    <input type="text"
                                        aria-label="Number"
                                        placeholder="City"
                                        [formControl]="cityMunControl"
                                        [matAutocomplete]="auto2"
                                        class="form-control"
                                        [(ngModel)]="areaFilter.city"
                                    >
                                    <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="setCity($event.option.value.code, $event.option.value.name)">
                                        <mat-option *ngFor="let option of cityMunOptions$ | async" [value]="option">
                                            {{option.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div class="form-group" *ngIf="areaFilter.filterType == 'city' || areaFilter.filterType == 'province'" [ngClass]="{ 'col-6': areaFilter.filterType == 'city', 'col-12': areaFilter.filterType == 'province' }">
                                    <label [for]="provinceControl">Province</label>
                                    <input type="text"
                                        aria-label="Number"
                                        placeholder="Province"
                                        [formControl]="provinceControl"
                                        [matAutocomplete]="auto3"
                                        class="form-control"   
                                        [(ngModel)]="areaFilter.province"
                                    >
                                    <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="setProv($event.option.value.code, $event.option.value.name)">
                                        <mat-option *ngFor="let option of provinceOptions$ | async" [value]="option">
                                            {{option.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="form-row ml-3" *ngIf="areaFilter.filterType == 'city' || areaFilter.filterType == 'province' || areaFilter.filterType == 'region'">
                                <div class="form-group col-12">
                                    <label [for]="regControl">Region</label>
                                    <input type="text"
                                        aria-label="Number"
                                        placeholder="Region"
                                        [formControl]="regControl"
                                        [matAutocomplete]="auto"
                                        class="form-control"   
                                        [(ngModel)]="areaFilter.region"
                                    >
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setReg($event.option.value.code, $event.option.value.name)">
                                        <mat-option *ngFor="let option of regOptions$ | async" [value]="option">
                                            {{option.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-4 pr-0">
                            <div class="pr-3" *ngIf="showDateFacilityFilter">
                                <label for="">Facility</label>
                                <div>
                                    <mat-radio-group class="formControl" [(ngModel)]="facilityFilter.filterType">
                                        <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="uncategorized" (change)="restoreFacilityFiltToDefault()">Uncategorized</mat-radio-button>
                                        <mat-radio-button [disabled]="facilityDisable" class="pr-2" value="category" (change)="restoreFacilityFiltToDefault()">Category</mat-radio-button>
                                        <mat-radio-button [disabled]="facilityCodeDisable" value="facilityCode" (change)="restoreFacilityFiltToDefault()">NHSRC Facility Code</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div *ngIf="facilityFilter.filterType == 'category'">
                                <label for="">Category</label>
                                <div class="pl-1">
                                    <mat-checkbox [(ngModel)]="facilityFilter.category.all" (change)="controlCatFilt('all')">
                                        <label class="form-check-label mr-3" for="">
                                            All
                                        </label>
                                    </mat-checkbox>
                                    <mat-checkbox [(ngModel)]="facilityFilter.category.a" (change)="controlCatFilt()">
                                        <label class="form-check-label mr-3" for="">
                                            A
                                        </label>
                                    </mat-checkbox>
                                    <mat-checkbox [(ngModel)]="facilityFilter.category.b" (change)="controlCatFilt()">
                                        <label class="form-check-label mr-3" for="">
                                            B
                                        </label>
                                    </mat-checkbox>
                                    <mat-checkbox [(ngModel)]="facilityFilter.category.c" (change)="controlCatFilt()">
                                        <label class="form-check-label mr-3" for="">
                                            C
                                        </label>
                                    </mat-checkbox>
                                    <mat-checkbox [(ngModel)]="facilityFilter.category.d" (change)="controlCatFilt()">
                                        <label class="form-check-label mr-3" for="">
                                            D
                                        </label>
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div *ngIf="facilityFilter.filterType == 'facilityCode'">
                                <label for="">NHSRC Facility Code</label>
                                <div>
                                    <input class="form-control" type="text" placeholder="Facility Code" [(ngModel)]="facilityFilter.facilityCode" oninput="this.value = this.value.toUpperCase()">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <a class="btn btn-primary text-nowrap text-dark mt-2" [ngClass]="{ 'isDisabled': loading == true}" (click)="generateReport()">
                        <!-- <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> -->
                        <i class="mdi mdi-file-excel"></i>
                        Generate
                    </a>
                    <span *ngIf="timerShown" class="timer pl-2">{{ seconds }}.{{ milliDisplay }}s</span>
                </div>
                
            </fieldset>
        </div>
    </div>
    <div class="pl-5 pr-5" *ngIf="loading">
        <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
    </div>
    <div class="" *ngIf="reportShown" [hidden]="loading">
        <div class="card bg-white m-3 p-3 " #print >
            <div class="d-flex align-items-between mb-3">
                <div class="flex-grow-1 text-center">
                    <h5><b>{{reportTitle}}</b></h5>
                    <div><small>{{filterLabel}}</small></div>
                    <small >{{resultLabel}}</small>
                </div>
                <div class='printHidden' *ngIf="data | async as printdata">
                    <button type="button" *ngIf="!reportAggregate" (click)="printShort('printSectionId')" class="text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-printer"></i></button>
                    <button type="button" *ngIf="reportAggregate" (click)="printService.print([convertToPrintData(printdata)], reportTitle)" class="text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-printer"></i></button>
                    <button type="button" (click)="exportToExcel()" class="text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-file-excel"></i></button>  
                    <button type="button" (click)="print2CSV()" class="text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-file-delimited"></i></button>    
                </div>
            </div>
            <app-report id="printSectionId" class="overflow-auto printable" [data]="data | async"></app-report>
            <div  class="d-flex flex-row-reverse">
                <small>Report generated in <b style="pointer-events: none;"><i class="mdi mdi-ear-hearing"></i>ENNHSR</b></small>
            </div>
        </div>
    </div>
</div>

