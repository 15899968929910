import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedRoutingModule } from './shared-routing.module';
import { TableComponent } from './table/table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { FormComponent } from './form/form.component'; 
import { ReactiveFormsModule } from '@angular/forms';

import { MatCheckboxModule  } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input'
import { Field } from './_models/field';
import { AddressComponent } from './address/address.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AgePipe } from './age.pipe';
import { BoyGirlPipe } from './boy-girl.pipe';
import { AgePickerComponent } from './age-picker/age-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { PrintComponent } from './print/print.component';
import { RecordHistoryComponent } from './table/record-history/record-history.component';
import { ShortIdPipe } from './short-id.pipe';
import { FileComponent } from './file/file.component';
import { FileHistoryComponent } from './file/file-history/file-history.component';
import { MatTableModule } from '@angular/material/table';
import { dateAdapterProvider, dateformatProvider } from '@app/shared/date-format';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DisplayPsgcPipe } from './address/display-psgc.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

@NgModule({
  declarations: [
    SidebarComponent,
    TableComponent,
    CalendarComponent,
    FormComponent,
    AddressComponent,
    AgePipe,
    BoyGirlPipe,
    AgePickerComponent,
    TimePickerComponent,
    PrintComponent,
    RecordHistoryComponent,
    ShortIdPipe,
    FileComponent,
    FileHistoryComponent,
    DisplayPsgcPipe,    
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedRoutingModule,
    
    NgxDatatableModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    ReactiveFormsModule,

    MatInputModule,    
    MatRadioModule,
    MatSidenavModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    
    MatCheckboxModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  exports: [
    SidebarComponent,
    TableComponent,
    CalendarComponent,
    FormComponent,
    AddressComponent,
    AgePipe,
    BoyGirlPipe,
    ShortIdPipe,
    AgePickerComponent,
    TimePickerComponent,
    PrintComponent,
    FileComponent,
    MatProgressSpinnerModule,
    DisplayPsgcPipe,
    MatProgressBarModule
  ],
  providers: [
    dateAdapterProvider, dateformatProvider
  ]
})
export class SharedModule { }
