import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-file-history',
  templateUrl: './file-history.component.html',
  styleUrls: ['./file-history.component.scss']
})
export class FileHistoryComponent implements OnInit {

  files: any

  displayedColumns = ['fileName', 'fileType', 'dateUploaded', 'refId'];
  constructor(
    private ennhsrService: EnnhsrService,
    public dialogRef: MatDialogRef<FileHistoryComponent>,        
    @Inject(MAT_DIALOG_DATA) public injectData: {
        files: any
    }
  ) { 
    this.files = injectData.files.map(e => {
      var date = new Date(e.dateUploaded);
      return {
        'dateUploaded' : ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear(),
        'fileName' : e.fileName,
        'fileType' : e.fileType,
        'refId' : e.refId
      }}
      );
  }

  ngOnInit(): void {}

  download(file: any){
    this.ennhsrService.downloadFile(file["refId"]).pipe(
      map(res => {
        const blob = new Blob([res], { type: file["fileType"] });
        const url= URL.createObjectURL(blob);
        open(url);
      })
    ).subscribe();
  }

}
