import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from '@app/admin/users/users.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { ProgressBarService } from '@app/shared/progress-bar/progress-bar.service';
import { Sync } from '@app/shared/_models/sync';
import { IdMappingService } from '@app/sync/idMapping.service';
import { SyncService } from '@app/sync/sync.service';
import { UserService } from '@app/user/user.service';
import { User } from '@app/user/_models/user';
import { unlink } from 'fs';
import { combineLatest, concat, defer, forkJoin, from, interval, of } from 'rxjs';
import { bufferCount, concatAll, delay, first, map, mergeAll, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  isSynced: boolean;
  linkedAccount: string;
  isMultipleFacility: boolean = false;
  userAccount: any;
  facilityAndRoles: any;
  facility: any;
  hide = true;
  progress;

  constructor(public dialogRef: MatDialogRef<LinkComponent>,
    private userService: UserService,
    private usersService: UsersService,
    private alertService: AlertService, 
    private ennhsrService: EnnhsrService, 
    private syncService: SyncService,
    private progressBarService: ProgressBarService,
    @Inject(MAT_DIALOG_DATA) public data: {
      formBuilder: FormBuilder, 
      id: string, 
      userService: UserService,
      alertService: AlertService,
  }) { }

  ngOnInit(): void {
    this.progressBarService.reset()
    this.progressBarService.progress.subscribe(progress => {
      this.progress = progress
    })

    this.form = this.data.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      // privacy: ['', Validators.requiredTrue]
    });

    const user = this.userService.userValue;
    this.isSynced = user.linkAccountId != null ? true : false;
    
    if(this.isSynced){
      this.linkedAccount = user.firstName + " " + user.lastName + " (" + user.username+ ")";
    } else { this.getToken() }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
    this.loading = true;
    this.checkAccount();
  }
  
  checkAccount() {
    this.userService.checkAccount(this.f.username.value, this.f.password.value)
    .pipe(first())
      .subscribe({
          next: res => {
              if(res["success"] == false ) {
                  this.alertService.error(res["message"]);
                  this.loading = false;
              } else {
                if((res["facilityAndRoles"] as []).length > 1){
                  this.userAccount = res
                  this.facilityAndRoles = res["facilityAndRoles"];
                  this.isMultipleFacility = true;
                  this.loading = false;
                } else {
                  this.linkAccount(res);
                }
              }
          },
          error: error => {
              this.alertService.error(error);
              this.loading = false;
          }
      });
  }

  selectFacility(){
    this.loading = false;
    let facilityArray = [];
    facilityArray.push(this.facility);
    this.userAccount["facilityAndRoles"] = facilityArray;
    this.userAccount["facility"] = this.facility;
    this.linkAccount(this.userAccount);
  }

  changeFacility(e){
    this.facility = (this.facilityAndRoles as [])[e];
  }
  
  linkAccount(userAccount) {
    var user = this.userService.userValue;
    user.linkAccountId = userAccount["id"];
    user.firstName = userAccount["firstName"];
    user.middleName = userAccount["middleName"]; 
    user.lastName = userAccount["lastName"]; 
    user['facilityAndRoles'] =  userAccount['facilityAndRoles'];
    var facility = userAccount['facilityAndRoles'][0];
    delete user['facility'];
    this.userService.linkAccount(user.id,user).pipe(first())
    .subscribe({
        next: () => {
          this.syncService.getByFacility(facility.facilityCode).subscribe(sync => {
            if(!sync) {
              this.alertService.success('Account link successful, performing initial Sync');
              user['facility'] = facility;
              localStorage.setItem('user', JSON.stringify(user));
              this.syncService.initialSync().subscribe(() => {
                this.syncService.add().subscribe(() => {
                  this.alertService.success('Initial Sync complete');
                  setTimeout(() => {
                    this.dialogRef.close();  
                  }, 1000);
                })
              })
            } else {
              this.alertService.success('Account link successful');
              user['facility'] = facility;
              localStorage.setItem('user', JSON.stringify(user));
              setTimeout(() => {
                this.dialogRef.close();  
              }, 1000);
            }
            
          })
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;
        }
    });;
  }

  initialSync() {
    
  }

  unlink() {
    if(window.confirm("You will be logged out when you unlink your ENNHSR account, proceed?")) {
      var user = this.userService.userValue;
      user.linkAccountId = null;
      var facility = user['facility'];
      delete user['facility'];
      this.userService.linkAccount(user.id,user).pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Account unlinked');
              user['facility'] = facility;
              localStorage.setItem('user', JSON.stringify(user));
              this.userService.userSubject.next(user);
              this.dialogRef.close();
              this.userService.logout();
          },
          error: error => {
              this.alertService.error(error);
              this.loading = false;
          }
      });
    }
  }

  getToken(){
    this.ennhsrService.getToken()
      .subscribe({
        next: () => {
            //this.alertService.success('Sync data pulled successfully', { keepAfterRouteChange: true });
          },
          error: error => {
            this.alertService.error("Web services unavailable", { keepAfterRouteChange: true });
            this.dialogRef.close();
            this.userService.logout();            
          }
    });
  }

  cancelLink() {
    this.dialogRef.close();
    this.userService.logout();
  }
}
