import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Utils from '@app/shared/utils';
import * as moment from 'moment';
import { first, map } from 'rxjs/operators';
import { RecordHistoryService } from './record-history.service';

@Component({
  selector: 'app-record-history',
  templateUrl: './record-history.component.html',
  styleUrls: ['./record-history.component.scss']
})
export class RecordHistoryComponent implements OnInit {
  id;
  data$: any;
  columns = [
    {name: 'Date',  prop: 'date', width: '126'},
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Changes', prop:'change', width: '775'}
  ];
  model: any[];
  modelName: string;

  constructor(
    private recordHistoryService: RecordHistoryService,
    public dialogRef: MatDialogRef<RecordHistoryComponent>,        
    @Inject(MAT_DIALOG_DATA) public injectData: {
        id: string, 
        columns: any,
        modelName: string
    }
  ) {
    this.id = injectData.id;
    this.model = injectData.columns;
    this.modelName = injectData.modelName;
  }

  ngOnInit(): void {
    this.recordHistoryService.getChanges(this.id, this.modelName).subscribe();
    this.data$ = this.recordHistoryService.historyObservable
    .pipe(
      map(res => {
        if(res) {
          var raw = Utils.groupBy((res['fieldUpdates'] as []).sort((a,b) => {
            return b['dateUpdated'] - a['dateUpdated']
          }), "dateUpdated");
          var data = []
          for(let [key, value] of Object.entries(raw)) {
            var date = new Date(parseInt(key));
            data.push({
              date: (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + " " + date.getHours()%12 + ":" + date.getMinutes() + (date.getHours() > 11 ? "PM" : "AM"),
              updatedBy: value[0].fullName,
              change: (value as any[]).map(e => {
                  if(this.model.find(f => (f['prop'] == undefined ? Utils.camelize(f['name']) : f['prop']) === e['fieldPath']) !== undefined)                
                    return "\"" + 
                    this.model.find(f => (f['prop'] == undefined ? Utils.camelize(f['name']) : f['prop']) === e['fieldPath'])['name'] + 
                    ( e['value'] !== "" ?
                      ("\" to \"" + 
                      (moment(e['value']).isValid() ? moment(e['value']).format("MM/DD/YYYY") : e['value']) + "\"") :
                      "\" to blank"
                    );
                  return null;
                })
            });
          }
          return data;     
        }
      })
    )
  }
}
