<ng-template #formsTemplate>
    <ng-template #loading>
        <div class="d-flex justify-content-center w-100 p-4">
            <span class="spinner-border text-secondary"></span>
        </div>
    </ng-template>
    <div class="d-flex flex-column w-100" *ngIf="patient$ | async as subject; else loading" [@formsAnimations]>
        <div id="details" class="form-header p-3 d-flex align-items-center justify-content-between" style="z-index: 1;">
            <div class="d-flex flex-column flex-lg-row">
                <div class="d-flex flex-column justify-content-center mr-2">
                    <h5 class="mb-0 text-nowrap"><b>{{
                        (subject.dataModel.firstName && subject.dataModel.firstName !== '' && subject.dataModel.lastName && subject.dataModel.lastName !== '') ? 
                            (subject.dataModel.lastName + ', ' + subject.dataModel.firstName + ' ' + (subject.dataModel.middleName? subject.dataModel.middleName:'')) 
                            : 'Baby ' + (subject.dataModel.sex && subject.dataModel.sex !== '' ? (subject.dataModel.sex | boyGirl | titlecase) : '') + ' ' +
                                (subject.dataModel.lastName && subject.dataModel.lastName !== '' ? subject.dataModel.lastName : subject.dataModel.motherLastName)
                        }}</b></h5>
                    <span class="text-nowrap" *ngIf="!subject.dataModel.firstName || subject.dataModel.firstName === '' || !subject.dataModel.lastName || subject.dataModel.lastName === ''">(child of {{subject.dataModel.motherFirstName}} {{subject.dataModel.motherLastName}})</span>
                    <div><span class="badge badge-success">{{subject.dataModel.patientNumber}}</span></div>
                </div>
                <div class="d-none mr-2 d-lg-block" style="border-left: 1px solid gray;"></div>
                <div class="d-flex flex-column justify-content-center">
                    <span class="text-nowrap"><i class="mdi mdi-account-details mr-1"></i> {{subject.dataModel.sex | titlecase}}, Born: {{subject.dataModel.dateOfBirth | date:'longDate'}} ({{subject.dataModel.dateOfBirth | age}} y.o.)</span>
                    <span class="d-flex"><i class="mdi mdi-home mr-1"></i> {{subject.dataModel.fullAddress}}</span>
                </div>
            </div>
            <button routerLink="screening-results/{{id}}" class="btn btn-primary text-dark text-nowrap"><i class="mdi mdi-qrcode mr-2"></i> <b>Screening Results</b></button>
        </div>        
        <div class="w-100 h-100 overflow-auto">
            <div class="container">
                <app-patient-details id="form1" [patient]="subject"></app-patient-details>
                <app-hearing-screening id="form2"></app-hearing-screening>
                <app-auditory-evoked-potentials id="form3"></app-auditory-evoked-potentials>
                <app-confirmatory-other-tests></app-confirmatory-other-tests>
                <app-hearing-aid-devices id="form4"></app-hearing-aid-devices>
                <app-speech-therapy id="form5"></app-speech-therapy>
                <app-developmental-monitoring id="form6"></app-developmental-monitoring>
                <app-surgical-hearing-interventions id="form7"></app-surgical-hearing-interventions>
                <app-surgical-hearing-interventions-events></app-surgical-hearing-interventions-events>
                <app-cochlear-implant-programming id="form8"></app-cochlear-implant-programming>
            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
</ng-template>
<app-sidebar [items]="items" [template]="formsTemplate"></app-sidebar>