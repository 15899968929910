import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { first, map } from 'rxjs/operators';
import Utils from '@app/shared/utils';
import { AlertService } from '@app/shared/alert/alert.service';
import { AbrTestService } from '../abr-test.service';
import { PrintService } from '@app/shared/print/print.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { MachinesService } from '@app/unit/machines/machines.service';
import { PatientService } from '@app/patient/patient.service';
import { EnnhsrService } from '@app/shared/ennhsr.service';

@Component({ template: '' })
export class AddEditABRComponent implements OnInit {
    
    constructor(
        public dialog: MatDialog,
        private location: Location,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.openDialog();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(AddEditABRComponentDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: this.route.snapshot.params['id']
            }
        });
        dialogRef.afterClosed().subscribe(() => {
            this.location.back();
        });
    }
}

@Component({ templateUrl: 'add-edit-abr.component.html' })
export class AddEditABRComponentDialog implements OnInit {
    
    form: FormGroup;
    loading = false;
    submitted = false;

    editable: boolean;
    editing: boolean;
    printable: boolean;

    isAddMode: any;

    fileToUpload: File = null;

    id: string;
    patientId: string;
    facilityId: string;
    modelId: string;
    versionId: string;
    allMachine$: Observable<any>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    
    constructor(        
        public dialogRef: MatDialogRef<AddEditABRComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder,
            id: string
        },
        public dialog: MatDialog,
        private abrTestService: AbrTestService, 
        private machinesService: MachinesService, 
        private formBuilder: FormBuilder, 
        private alertService: AlertService,
        public printService: PrintService,
        private ennhsrService: EnnhsrService,
        private patientService: PatientService
    ) { 
        this.id = data.id;
    }

    ngOnInit() {
      this.patientId = this.patientService.recordSubject.getValue()["modelId"];
      this.facilityId = JSON.parse(localStorage.getItem('user'))["facility"]["facilityModelId"];
      this.isAddMode = !this.id;
  
      this.editable = this.id? true:false;
      this.editing = false;
      this.printable = true;
  
      this.form = this.formBuilder.group({
        patient: [''],
        dateOfTest: ['', Validators.required],
        nameOfTester: ['', Validators.required],
        reader: ['', Validators.required],
        device: [''],
        stimulusClick: [''],
        stimulusToneBurst: [''],
        stimulusChirp: [''],
        testingConditions: [''],
        
        leftEarDone: [''],
        transducersLeftEar: [''],
        polarityLeftRarefaction: [''],
        polarityLeftCondensation: [''],
        polarityLeftAlternating: [''],  
        findingsLeftEar: [''],  
        clickThresholdLeft: [''],
        findingsLeftEarToneBurst250: [''],
        findingsLeftEarToneBurst500: [''],
        findingsLeftEarToneBurst1000: [''],
        findingsLeftEarToneBurst1500: [''],
        findingsLeftEarToneBurst2000: [''],
        findingsLeftEarToneBurst3000: [''],
        findingsLeftEarToneBurst4000: [''],
        findingsLeftEarToneBurst6000: [''],
        findingsLeftEarToneBurst8000: [''],

        rightEarDone: [''],
        transducersRightEar: [''],
        polarityRightRarefaction: [''],
        polarityRightCondensation: [''],
        polarityRightAlternating: [''],
        findingsRightEar: [''],  
        clickThresholdRight: [''],
        findingsRightEarToneBurst250: [''],
        findingsRightEarToneBurst500: [''],
        findingsRightEarToneBurst1000: [''],
        findingsRightEarToneBurst1500: [''],
        findingsRightEarToneBurst2000: [''],
        findingsRightEarToneBurst3000: [''],
        findingsRightEarToneBurst4000: [''],
        findingsRightEarToneBurst6000: [''],
        findingsRightEarToneBurst8000: [''],
        
        notes: [''],
        uploadFile: ['']
      });
      this.machinesService.getAll(this.facilityId).subscribe();
      this.allMachine$ = this.machinesService.recordsObservable.pipe(
      map(machines => machines["results"].filter(machines => machines["dataModel"]["type"] === "ABR" ))
      )
      if (!this.isAddMode) {
        var model = this.abrTestService.recordsValue["results"].find(x => x.modelId == this.id);
        this.versionId = model["versionId"];
        this.modelId = model["modelId"];
        this.form.patchValue(Utils.mapModelToForm(model, ['dateOfTest']));
      }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() { 
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createABRTest();
        } else {
            this.updateABRTest();
        }
    }

    createABRTest() {
        this.form.get("patient").setValue(this.patientId)
        this.abrTestService.add(this.form.value, this.fileToUpload)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('ABR Test added successfully', { keepAfterRouteChange: true });
                this.dialogRef.close();
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
      }
      
      updateABRTest() {
        this.abrTestService.update(this.form.value, this.modelId, this.versionId, this.fileToUpload)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Update successful', { keepAfterRouteChange: true });
                this.dialogRef.close();
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
      }

      handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        //console.log("handleFileInput", files.item(0))
      }

      download(id: string){
        this.ennhsrService.downloadFile(id).pipe(
          map(res => {
            /* const link = document.createElement('a');
            link.href = URL.createObjectURL(res);
            link.download = Date.now().toString()+Utils.generateGuid();//to give a random file name
            document.body.append(link);
            link.click();
            link.remove(); */
            
            const blob = new Blob([res], { type: 'application/pdf' });
            const url= URL.createObjectURL(blob);
            console.log({url})
            open(url);
    
          })
        ).subscribe();
      }

      selected(event: MatAutocompleteSelectedEvent): void {
        this.form.controls['device'].setValue(event.option.viewValue);
      }
}