import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { SelectComponent } from './select/select.component';
import { LinkComponent } from './link/link.component';


@NgModule({
  declarations: [
    SelectComponent,
    CreateProfileComponent,
    LinkComponent
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatRippleModule
  ]
})
export class ProfileModule { }
