<div class="container ml-0" [ngClass]="{ 'pt-3': !isModal}">
    <div class="card">
        <div class="card-header">
            Facility Details
        </div>
        <div class="card-body"> 
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="facilityName">Facility Name</label>
                            <input type="text" formControlName="facilityName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.facilityName.errors }" />
                            <div *ngIf="submitted && f.facilityName.errors" class="invalid-feedback">
                                <div *ngIf="f.facilityName.errors.required">Date is required</div>
                            </div>
                        </div>
                        <div *ngIf="!isFacilityDetails" class="form-group col-6 d-flex align-items-end">
                            <mat-checkbox formControlName="active">
                                <label class="form-check-label" for="active">
                                    Active
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>   
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="nshrcFacilityAccreditationNumber">NHSRC Facility Code</label>
                            <input type="text" formControlName="nshrcFacilityAccreditationNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nshrcFacilityAccreditationNumber.errors }" />
                            <div *ngIf="submitted && f.nshrcFacilityAccreditationNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.nshrcFacilityAccreditationNumber.errors.required">NHSRC Facility Code is required</div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="category">Category</label>
                            <select class="form-control" formControlName="category" [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                            </select>
                            <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                                <div *ngIf="f.category.errors.required">Category is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="philhealthFacilityAccreditationNumber">Philhealth Provider Accreditation Number</label>
                            <input type="text" formControlName="philhealthFacilityAccreditationNumber" class="form-control"/>
                        </div>
                        <div class="form-group col-4">
                            <label for="dohHealthFacilityId">DOH Health Facility ID</label>
                            <input type="text" formControlName="dohHealthFacilityId" class="form-control"/>
                        </div>
                    </div>
            </div>
            <!-- <app-address [streetControlName]="'streetAddress'" [psgcControlName]="'psgcCode'"></app-address> -->
            <app-address [psgcControl]="f.psgcCode" [streetControl]="f.streetAddress" [submitted]="submitted"></app-address>
            <div class="form-group">
                <div class="form-group">
                    <label>Facility Partners</label>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <mat-chip-list #chipList aria-label="">
                                <mat-chip
                                  *ngFor="let facility of facilities"
                                  [selectable]="selectable"
                                  [removable]="removable"
                                  (removed)="remove(facility)">
                                  {{facility}}
                                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input
                                  placeholder="Add new facility partner"
                                  #facilityInput
                                  [formControl]="facility"
                                  [matAutocomplete]="auto"
                                  [matChipInputFor]="chipList"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                  (matChipInputTokenEnd)="add($event)"
                                  (focusout)="clearFacilityInput()">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                              <mat-option *ngFor="let facility of facilityOptions$ | async" [value]="facility.id">
                                {{facility.name}}
                              </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
                <div class="form-group col-4">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                    <a *ngIf="isModal" (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                </div>
            </form>
        </div>
    </div>
</div>