<app-selector></app-selector>
<ng-template appPrint></ng-template>
<nav *ngIf="user" [@navAnimations] (@navAnimations.done)="navAnimationsDone($event)" class="navbar navbar-expand-md navbar-dark w-100 position-fixed">
  <div class="navbar-header d-flex align-items-center">
    <button class="navbar-toggler main-nav-item" type="button" (click)="navToggle()">
      <i class="mdi mdi-24px mdi-menu"></i>
    </button>
    <a class="navbar-brand text-nowrap pl-1 pr-2 main-nav-item" routerLink="./" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <b style="pointer-events: none;"><i class="mdi mdi-ear-hearing"></i>ENNHSR <span *ngIf="isDesktop" style="font-size: 14px; font-weight: normal;">Lite</span></b>
    </a>
  </div>
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" (hidden)="onHidden()">
    <hr [ngbCollapse]="isMenuCollapsed" class="mb-0 mt-2" />
    <ul ngbNav [activeId]="route.fragment | async" class="navbar-nav mr-auto">
      <li [ngbNavItem]="link.fragment" *ngFor="let link of moduleLinks" class="nav-item" >
        <a ngbNavLink *ngIf="validatePrivilege(link.fragment)" routerLink="./{{link.fragment}}" class="nav-link main-nav-item" routerLinkActive="active" (click)="navclick()">{{ link.title }}</a>
      </li>
    </ul>
    <hr [ngbCollapse]="isMenuCollapsed" class="my-0" />
    <ul class="navbar-nav navbar-right">
      <li class="nav-item px-1" *ngIf="user.facilityAndRoles && user.facilityAndRoles.length > 1">
        <select [formControl]="facilitySelect" class="facility-select form-control text-light" (change)="changeFacility($event.target.value)">
          <option *ngFor="let facility of user.facilityAndRoles; index as i" class="text-light" [value]="i">{{facility.facility}}</option>
        </select>
      </li>
      <li class="nav-item" *ngIf="!isDesktop">
        <a (click)="openAccountDialog()" class="nav-link d-flex flex-nowrap align-items-center main-nav-item"><i class="mdi mdi-account-circle mr-1"></i><b>{{user.firstName + " " + user.lastName | uppercase}}</b></a>
      </li>
      <li class="nav-item" *ngIf="isDesktop">
        <a class="nav-link d-flex flex-nowrap align-items-center main-nav-item" (click)="openLinkDialog()"><i class="mdi mdi-link mr-1"></i><b>LINK ACCOUNT</b></a>
      </li>
      <li class="nav-item">
        <a (click)="openFeedbackDialog()" class="nav-link d-flex flex-nowrap align-items-center main-nav-item"><i class="mdi mdi-flag-triangle mr-1"></i><b>SUPPORT</b></a>
      </li>
      <li class="nav-item">
        <a class="nav-link d-flex flex-nowrap align-items-center main-nav-item" (click)="logout()"><i class="mdi mdi-logout mr-1"></i><b>LOGOUT</b></a>
      </li>
    </ul>
  </div>
</nav>
<alert></alert>
<!-- main app container -->
<div [@routeAnimations]="prepareRoute(outlet)" class="app-container" (scroll)="onScroll()">
  <!-- <div class="wallpaper position-fixed d-flex align-items-center justify-content-center"><b><i class="mdi mdi-ear-hearing"></i>ENNHSR</b></div> -->
  <div class="wallpaper position-fixed d-flex align-items-center justify-content-center"><img src="assets/img/NHSRC-logo-HD.png" class="wallpaper-image"></div>
  <router-outlet #outlet="outlet"></router-outlet>
</div>
<footer *ngIf="!isDesktop" class="footer position-fixed w-100">
  <div class="container text-center">
      <span class="text-muted">
        <small>
          <a href="http://www.nshrc.ph/" target="_blank">NHSRC</a> · 
          <a href="https://telehealth.ph/" target="_blank">UPM NTHC</a> · 
          <a routerLink="/privacy">Privacy Policy</a> · 
          <a routerLink="/validate_pn">Validate PN</a> · 
          <span *ngIf="this.user && (this.user['isAdmin'] || this.user['facility']['roles'].includes('Unit Manager'))"><a routerLink="/download_lite">ENNHSR Lite</a> · </span>
          ENNHSR © 2020
        </small>
      </span>
  </div>
</footer>