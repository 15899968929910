import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanceledSealDistributionService } from './canceled-seal-distribution.service';

@Component({
  selector: 'app-canceled',
  templateUrl: './canceled.component.html',
  styleUrls: ['./canceled.component.scss']
})
export class CanceledComponent implements OnInit {
  form: FormGroup;
  sealCanceled$ : Observable<any>;
  sealCanceledPage$ : Observable<any>;
  columns = [{ name: 'Date Requested' , prop: 'dateCreated'},
              { name: 'Reference ID' , prop: 'referenceID'},
              { name: 'Facility Name' } , { name: 'Quantity' },
              { name: 'Date Canceled' }
            ];
  
  constructor(private formBuilder: FormBuilder,private canceledSealDistributionService: CanceledSealDistributionService, private alertService: AlertService ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      quantity: [''],
      referenceID: [''],
      requestStatus: ['']
    });
    this.canceledSealDistributionService.getAll().subscribe();
    this.sealCanceled$ =  this.canceledSealDistributionService.recordsObservable.pipe(
      map(x => {
        if(x){
          x["results"].forEach(element => {
            element["dataModel"]["facilityName"] = element["dataModel"]["facility"]["facilityName"];
            element["dataModel"]["dateCreated"] = element["dateCreated"];
            element["dataModel"]["dateUpdated"] = element["dateUpdated"];
            element["dataModel"]["sealsData"] = []
            element["dataModel"]["seals"]["results"].forEach(element2 => {
              element["dataModel"]["sealsData"].push(Utils.mapModelToColumns(element2))
            });
            var groupedData = [];
            for (let [key, value] of Object.entries(Utils.groupBy(element["dataModel"]["sealsData"], "groupId"))) {
              if((value as []).length > 1)
                groupedData.push({"id": key, "serialNumber": (value[0]["batchCode"] ? value[0]["batchCode"] : "")+Math.min.apply(null, (value as []).map(e => e["serialNumber"]))+"-"+(value[0]["batchCode"] ? value[0]["batchCode"] : "")+Math.max.apply(null, (value as []).map(e => e["serialNumber"]))});
              else 
                groupedData.push({"id": key, "serialNumber": (value[0]["batchCode"] ? value[0]["batchCode"] : "")+value[0]["serialNumber"]});
            }
            element["dataModel"]["sealsData"] = groupedData;
            
            element["dataModel"]["sealsData"] = element["dataModel"]["sealsData"].map(x => x.serialNumber);
            element["dataModel"]["referenceID"] = element["dataModel"]["referenceID"].substring(24,36).toUpperCase( );
          });
          return x["results"].map(x => Utils.mapModelToForm(x,['dateCreated','dateCanceled']));
        }
        return of(null);
      })
    )
    this.sealCanceledPage$ = this.canceledSealDistributionService.recordsObservable;
  }

  getPage(page: string) {
    this.canceledSealDistributionService.getAll(page).subscribe();
  }

  search(keyword: string) {
    if(keyword != '')
        this.canceledSealDistributionService.searchAndRefresh(keyword).subscribe();
    else
        this.canceledSealDistributionService.getAll(0).subscribe();
  }
}
