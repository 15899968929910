import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { utils } from 'protractor';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SealsService } from '../seals.service';
import { ProcessingSealDistributionService } from './processing-seal-distribution.service';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.scss']
})
export class ProcessingComponent implements OnInit {
  processedRequest$ : Observable<any>;
  seals$ : Observable<any>;
  columns = [{ name: 'Serial Number'}];
  isLoading: boolean = true;

  constructor( private processingSealDistributionService: ProcessingSealDistributionService, private sealsService: SealsService, private alertService: AlertService ) { }

  ngOnInit(): void {
    this.processingSealDistributionService.getAll().subscribe();
    this.processedRequest$ = this.processingSealDistributionService.recordsObservable.pipe(
      map(x => {
        if(x){
          x["results"].forEach(element => {
            element["addLink"] = "add/seal/"+element["modelId"];
            element["dataModel"]["sealsData"] = []
            element["dataModel"]["seals"]["results"].forEach(element2 => {
              element["dataModel"]["sealsData"].push(Utils.mapModelToColumns(element2))
            });
            var groupedData = [];
            for (let [key, value] of Object.entries(Utils.groupBy(element["dataModel"]["sealsData"], "groupId"))) {
              if((value as []).length > 1)
                groupedData.push({"id": key, "serialNumber": (value[0]["batchCode"] ? value[0]["batchCode"] : "") + String(Math.min.apply(null, (value as []).map(e => e["serialNumber"]))).padStart(10,'0')+"-"+(value[0]["batchCode"] ? value[0]["batchCode"] : "")+ String(Math.max.apply(null, (value as []).map(e => e["serialNumber"]))).padStart(10,'0')});
              else 
                groupedData.push({"id": key, "serialNumber": (value[0]["batchCode"] ? value[0]["batchCode"] : "") +value[0]["serialNumber"]});
            }
            element["dataModel"]["sealsData"] = groupedData;
          });
          this.isLoading = false;
        }
        return x;
      })
    );
  }

  deleteSeal(groupId: string, modelId: string) {
    this.processingSealDistributionService.deleteSeals(groupId, modelId).pipe(first())
    .subscribe();
  }

  cancelRequest(request: any): void {
    if(confirm("Are you sure you want to cancel processing this request?")){
      this.processingSealDistributionService.updateStatus(request,"REQUESTED")
      .pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Request canceled', { keepAfterRouteChange: true });
          },
          error: error => {
              this.alertService.error(error);
          }
      });
    }
  }

  shipRequest(request: any): void {
    this.processingSealDistributionService.updateStatus(request,"IN TRANSIT")
    .pipe(first())
    .subscribe({
        next: () => {
            this.alertService.success('Request shipped', { keepAfterRouteChange: true });
        },
        error: error => {
            this.alertService.error(error);
        }
    });
  }
}
