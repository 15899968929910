<div class="container h-100 m-0 p-3" *ngIf="isLoadingSeals" >
    <div [style.width]="'100%'" class="d-flex justify-content-center">
        <mat-spinner [diameter]="25"></mat-spinner>
    </div>
</div>
<div class="container h-100 m-0 p-3" *ngIf="!isLoadingSeals" >
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                <span>NHSRC Seals</span>
            </div>
            <div class="card-body">   
                <fieldset>
                    <label>Remaining NHSRC Seals</label>
                    <div class="form-row ml-3 mb-3">
                        <div *ngIf="consumedSeals > 0" class="rounded-left p-2 bg-danger text-light text-center" [style.width]="consumedSeals*100/(consumedSeals+availableSeals)+'%'">
                            {{consumedSeals}}
                        </div>
                        <div *ngIf="availableSeals" class="rounded-right p-2 bg-success text-center" [style.width]="availableSeals == 0 ? 100 : 100.0-consumedSeals*100/(consumedSeals+availableSeals)+'%'">
                            {{availableSeals}}
                        </div>
                        <div *ngIf="consumedSeals == 0 && availableSeals == 0">
                            <span class="text-danger"><b>This facility has no seals</b></span>
                        </div>
                    </div>
                    <label>Request Seal from NHSRC</label>
                    <fieldset class="form-row ml-3" [disabled]="disableRequest">
                        <div class="form-group col-4">
                            <label for="quantity">Quantity</label> 
                            <input type="number" [min]="0" formControlName="quantity" class="form-control mr-3" [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }"/>
                            <div *ngIf="submitted && f.quantity.errors" class="invalid-feedback">
                                <div *ngIf="f.quantity.errors.required">Quantity is required</div>
                            </div>
                            <div *ngIf="f.quantity.hasError('lessThan300') && f.quantity.touched" class="invalid-feedback">
                                <div>Order quantity is 300 minimum</div>
                            </div>
                        </div>
                        <div class="form-group col-4 ">
                            <label>&nbsp;</label> 
                            <button class="btn btn-primary btn-block">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Send Request
                            </button>
                        </div>
                    </fieldset> 
                    <label *ngIf="sealRequest$ | async">Seal Request Tracker</label>
                    <div *ngIf="sealRequest$ | async as sealRequests">
                        <div *ngFor="let request of sealRequests.results">
                            <div *ngIf="request.dataModel.requestStatus === 'REQUESTED'" class="card card-body d-flex align-items-center flex-row p-3 mb-3">
                                <div class="d-flex flex-column mr-3">
                                    <small>{{request.dataModel.referenceID | shortId | uppercase }}</small>
                                    <small class="ml-auto"><b>{{request.dateCreated | date}}</b></small>
                                </div>
                                <span class="mr-3">Qty: {{request.dataModel.quantity}}</span>
                                <span class="ml-auto">Status:&nbsp;</span>
                                <span><i class="mdi mdi-send mr-1"></i><b>{{request.dataModel.requestStatus}}</b></span>
                                <a [disabled]="loadingTracker" class="btn btn-danger ml-2" (click)="cancelRequest(request.modelId)">
                                    <span *ngIf="loadingTracker"  class="spinner-border spinner-border-sm mr-1"></span>
                                    Cancel Order
                                </a >
                            </div>
                            <div *ngIf="request.dataModel.requestStatus === 'PROCESSED'" class="card card-body d-flex align-items-center flex-row p-3 mb-3">
                                <div class="d-flex flex-column mr-3">
                                    <small>{{request.dataModel.referenceID | shortId | uppercase }}</small>
                                    <small class="ml-auto"><b>{{request.dateCreated | date}}</b></small>
                                </div>
                                <span class="mr-3">Qty: {{request.dataModel.quantity}}</span>
                                <span class="ml-auto">Status:&nbsp;</span>
                                <span><i class="mdi mdi-cog mr-1"></i><b>{{request.dataModel.requestStatus}}</b></span>
                            </div>
                            <div *ngIf="request.dataModel.requestStatus === 'IN TRANSIT'" class="card card-body d-flex align-items-center flex-row p-3 mb-3">
                                <div class="d-flex flex-column mr-3">
                                    <small>{{request.dataModel.referenceID | shortId | uppercase }}</small>
                                    <small class="ml-auto"><b>{{request.dateCreated | date}}</b></small>
                                </div>
                                <span class="mr-3">Qty: {{request.dataModel.quantity}}</span>
                                <span class="ml-auto">Status:&nbsp;</span>
                                <span><i class="mdi mdi-truck-fast mr-1"></i><b>{{request.dataModel.requestStatus}}</b></span>
                                <a [disabled]="loadingTracker" class="btn btn-success ml-2" (click)="receiveOrder(request)">Order Received

                                </a >
                            </div> 
                        </div>
                    </div>
                    <!-- <div class="card card-body d-flex align-items-center flex-row p-3 mb-3">
                        <div class="d-flex flex-column mr-3">
                            <small>A0000307096</small>
                            <small class="ml-auto"><b>2/10/2021</b></small>
                        </div>
                        <span class="mr-3">Qty: 100</span>
                        <span class="ml-auto">Status:&nbsp;</span>
                        <span><i class="mdi mdi-send mr-1"></i><b>REQUESTED</b></span>
                        <button class="btn btn-danger ml-2">Cancel Order</button>
                    </div>
                    <div class="card card-body d-flex align-items-center flex-row p-3 mb-3">
                        <div class="d-flex flex-column mr-3">
                            <small>A0000307097</small>
                            <small class="ml-auto"><b>2/10/2021</b></small>
                        </div>
                        <span class="mr-3">Qty: 110</span>
                        <span class="ml-auto">Status:&nbsp;</span>
                        <span><i class="mdi mdi-cog mr-1"></i><b>PROCESSED</b></span>
                    </div>
                    <div class="card card-body d-flex align-items-center flex-row p-3 mb-3">
                        <div class="d-flex flex-column mr-3">
                            <small>A0000307098</small>
                            <small class="ml-auto"><b>2/10/2021</b></small>
                        </div>
                        <span class="mr-3">Qty: 120</span>
                        <span class="ml-auto">Status:&nbsp;</span>
                        <span><i class="mdi mdi-truck-fast mr-1"></i><b>IN TRANSIT</b></span>
                        <button class="btn btn-success ml-2">Order Recieved</button>
                    </div> 
                    <div class="card card-body d-flex align-items-center flex-row p-3 mb-3">
                        <div class="d-flex flex-column mr-3">
                            <small>A0000307099</small>
                            <small class="ml-auto"><b>2/10/2021</b></small>
                        </div>
                        <span class="mr-3">Qty: 120</span>
                        <span class="ml-auto">Status:&nbsp;</span>
                        <span><i class="mdi mdi-cog-refresh mr-1"></i><b>REPROCESSED</b></span>
                    </div>   -->               
                </fieldset>
            </div>
        </form>
    </div>
    <div class="card my-3" *ngIf="completedRequests$ | async as completedRequests">
        <div class="card-header d-flex justify-content-between">
            <span>Completed NHSRC Seal Requests</span>
        </div>
        <div class="card-body" #print>
            <app-table [data]="completedRequests" [columns]="columns" [isWriteMode]="false" [forChangeHistory]="true"></app-table>
        </div>
    </div>
    <div class="card my-3" *ngIf="canceledRequests$ | async as canceledRequests">
        <div class="card-header d-flex justify-content-between">
            <span>Canceled NHSRC Seal Requests</span>
        </div>
        <div class="card-body" #print>
            <app-table [data]="canceledRequests" [columns]="columns2" [isWriteMode]="false" [forChangeHistory]="true"></app-table>
        </div>
    </div>
</div>