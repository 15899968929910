import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private increment = 0
  
  private progressSubject: BehaviorSubject<any> = new BehaviorSubject(null)
  public progress: Observable<any> = this.progressSubject.asObservable()

  constructor() { }

  reset() {
    this.progressSubject.next(null)
  }

  getProgress() {
    return this.progressSubject.value || 0
  }

  setProgress(value) {
    this.progressSubject.next(value > 100 ? 100 : (value < 0 ? 0 : value))
  }

  incrementProgress(value?) {
    this.progressSubject.next((this.getProgress() + (value || this.increment)) > 100 ? 100 : (this.getProgress() + (value || this.increment)))
  }

  setIncrement(value) {
    this.increment = value;
  }
}
