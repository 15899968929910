<!-- <p>add-edit-seal works!</p> -->
<div class="container">
    <div class="card">
        <div class="card-header">
           NHSRC Seal
        </div>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group col-4">
                    <mat-radio-group formControlName="single">
                        <mat-radio-button  #isSingle class="mr-3" [value]="true">Single</mat-radio-button>
                        <mat-radio-button #isMultiple checked class="mr-3" [value]="false">Multiple</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="form-row">
                    <div class="form-group col-2">
                        <label>Input batch code</label>
                        <input type="text" formControlName="batchCode" class="form-control" maxlength=1 oninput="this.value = this.value.toUpperCase()" [ngClass]="{ 'is-invalid': submitted && f.batchCode.errors }"/>
                        <div *ngIf="submitted && f.batchCode.errors" class="invalid-feedback">
                            <div *ngIf="f.batchCode.errors.required">Batch code is required</div>
                        </div>
                    </div>
                    <div *ngIf="isSingle.checked" class="form-group col-4">
                        <label>Input serial number</label>
                        <input type="number" [min]="0000000001" [max]="9999999999" formControlName="serialNumber" class="form-control" maxlength="10" oninput="this.value=this.value.slice(0,this.maxLength)"[ngClass]="{ 'is-invalid': submitted && f.serialNumber.errors }"/>
                        <div *ngIf="submitted && f.serialNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.serialNumber.errors.required">Seal number is required</div>
                        </div>
                    </div>
                    
                    <div *ngIf="isMultiple.checked" class="form-group col-10">
                        <label>Input serial number range</label>
                        <div class="form-row">
                            <div class="form-group col-4">
                                <input type="number" formControlName="rangeFrom" [min]="0000000001" [max]="9999999999" maxlength="10" oninput="this.value=this.value.slice(0,this.maxLength)" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.rangeFrom.errors }"/>
                                <div *ngIf="submitted && f.rangeFrom.errors" class="invalid-feedback">
                                    <div *ngIf="f.rangeFrom.errors.required">Starting seal number is required</div>
                                </div>
                            </div>
                            -
                            <div class="form-group col-4">
                                <input type="number" formControlName="rangeTo" [min]="0000000001" [max]="9999999999" maxlength="10" oninput="this.value=this.value.slice(0,this.maxLength)" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.rangeTo.errors }"/>
                                <div *ngIf="submitted && f.rangeTo.errors" class="invalid-feedback">
                                    <div *ngIf="f.rangeTo.errors.required">End seal number is requiredd</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div class="form-group col-4">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Add
                    </button>
                    <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                </div>
            </form>
        </div>
    </div>
</div>