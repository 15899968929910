import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class CoMorbidityReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Comorbidity'
    })
    comorbidity: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Less Than 12 Months',
        colSpan: 2
    })
    lessThan12Male: string;
    @autoserializeAs(String)
    @Column({
        isHidden: true
    })
    lessThan12Female: string;
    @autoserializeAs(String)
    @Column({
        columnName: '12 Months and 1 Day to 18 Months',
        colSpan: 2
    })
    twelveTo18Male: string;
    @autoserializeAs(String)
    @Column({
        isHidden: true
    })
    twelveTo18Female: string;
    @autoserializeAs(String)
    @Column({
        columnName: '18 Months and 1 Day to 30 Months',
        colSpan: 2
    })
    eighteenTo30Male: string;
    @autoserializeAs(String)
    @Column({
        isHidden: true
    })
    eighteenTo30Female: string;
    @autoserializeAs(String)
    @Column({
        columnName: '30 Months and 1 Day to 42 Months',
        colSpan: 2
    })
    thirtyTo42Male: string;
    @autoserializeAs(String)
    @Column({
        isHidden: true
    })
    thirtyTo42Female: string;
    @autoserializeAs(String)
    @Column({
        columnName: '42 Months and 1 Day to 60 Months',
        colSpan: 2
    })
    fortytwoTo60Male: string;
    @autoserializeAs(String)
    @Column({
        isHidden: true
    })
    fortytwoTo60Female: string;
    @autoserializeAs(String)
    @Column({
        columnName: '60 Months and 1 Day Above',
        colSpan: 2
    })
    sixtyAboveMale: string;
    @autoserializeAs(String)
    @Column({
        isHidden: true
    })
    sixtyAboveFemale: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Total',
        colSpan: 2
    })
    totalMale: string;
    @autoserializeAs(String)
    @Column({
        isHidden: true
    })
    totalFemale: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Grand Total'
    })
    grandTotal: string;
}
