import { animate, animateChild, group, query, stagger, style, transition, trigger } from '@angular/animations';

export const searchAnimations =
  trigger('searchAnimations', [
    transition(':enter', 
      [
        style({ height: 0 }),
        animate(150, style({ height: '67px' }))
      ]
    ),
    transition(':leave', 
      [
        style({ height: '67px' }),
        animate(150, style({ height: 0 }))
      ]
    )
  ]);

export const rowAnimations =
  trigger('rowAnimations', [
    // transition(':enter', 
    //   [
    //     style({ width: 0 }),
    //     animate(100, style({ width: '90px' }))
    //   ]
    // ),
    // transition(':leave', 
    //   [
    //     style({ width: '90px' }),
    //     animate(100, style({ width: 0 }))
    //   ]
    // )
  ]);