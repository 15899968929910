import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IntraOpTelemetryService } from '../intra-op-telemetry.service';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { first } from 'rxjs/operators';
import { PrintService } from '@app/shared/print/print.service';
import { PatientService } from '@app/patient/patient.service';

@Component({ template: '' })
export class AddEditIntraOpTelemetryComponent implements OnInit {
      
  constructor(
    public dialog: MatDialog,
    private location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
      this.openDialog();
  }

  openDialog(): void {
      const dialogRef = this.dialog.open(AddEditIntraOpTelemetryComponentDialog, {
          data: {
              formBuilder: this.formBuilder,
              id: this.route.snapshot.params['id']
          }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.location.back();
      });
  }

}

@Component({ templateUrl: 'add-edit-intra-op-telemetry.component.html' })
export class AddEditIntraOpTelemetryComponentDialog implements OnInit {
    
    form: FormGroup;
    loading = false;
    submitted = false;

    editable: boolean;
    editing: boolean;
    printable: boolean;

    isAddMode: any;

    id: string;
    patientId: string;
    modelId: string;
    versionId: string;

    constructor(        
        public dialogRef: MatDialogRef<AddEditIntraOpTelemetryComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder,
            id: string
        },
        public dialog: MatDialog,
        private intraOpTelemetryService: IntraOpTelemetryService, 
        private formBuilder: FormBuilder, 
        private alertService: AlertService,
        public printService: PrintService,
        private patientService: PatientService
    ) { 
        this.id = data.id;
    }

    ngOnInit() {
      this.patientId = this.patientService.recordSubject.getValue()["modelId"];

      this.isAddMode = !this.id;
  
      this.editable = this.id? true:false;
      this.editing = false;
      this.printable = true;
  
      this.form = this.formBuilder.group({
        patient: [''],
        surgeon: [''],
        hospital: [''],
        audiologist: [''],
        status: ['', Validators.required],
        dateDone: [''],
        reason: [''],
        leftEar: [''],
        rightEar: [''],
        brandLeft: [''],
        brandRight: [''],
        brandLeftOthersSpecify: [''],
        brandRightOthersSpecify: [''],
        serialLeft: [''],
        serialRight: [''],
        elecLeft1: [''],
        elecLeft2: [''],
        elecLeft3: [''],
        elecLeft4: [''],
        elecLeft5: [''],
        elecLeft6: [''],
        elecLeft7: [''],
        elecLeft8: [''],
        elecLeft9: [''],
        elecLeft10: [''],
        elecLeft11: [''],
        elecLeft12: [''],
        elecLeft13: [''],
        elecLeft14: [''],
        elecLeft15: [''],
        elecLeft16: [''],
        elecLeft17: [''],
        elecLeft18: [''],
        elecLeft19: [''],
        elecLeft20: [''],
        elecLeft21: [''],
        elecLeft22: [''],
        elecLeft23: [''],
        elecLeft24: [''],
        eLeft1: [''],
        eLeft2: [''],
        eLeft3: [''],
        eLeft4: [''],
        eLeft5: [''],
        eLeft6: [''],
        eLeft7: [''],
        eLeft8: [''],
        eLeft9: [''],
        eLeft10: [''],
        eLeft11: [''],
        eLeft12: [''],
        eLeft13: [''],
        eLeft14: [''],
        eLeft15: [''],
        eLeft16: [''],
        eLeft17: [''],
        eLeft18: [''],
        eLeft19: [''],
        eLeft20: [''],
        eLeft21: [''],
        eLeft22: [''],
        eLeft23: [''],
        eLeft24: [''],
        elecRight1: [''],
        elecRight2: [''],
        elecRight3: [''],
        elecRight4: [''],
        elecRight5: [''],
        elecRight6: [''],
        elecRight7: [''],
        elecRight8: [''],
        elecRight9: [''],
        elecRight10: [''],
        elecRight11: [''],
        elecRight12: [''],
        elecRight13: [''],
        elecRight14: [''],
        elecRight15: [''],
        elecRight16: [''],
        elecRight17: [''],
        elecRight18: [''],
        elecRight19: [''],
        elecRight20: [''],
        elecRight21: [''],
        elecRight22: [''],
        elecRight23: [''],
        elecRight24: [''],
        eRight1: [''],
        eRight2: [''],
        eRight3: [''],
        eRight4: [''],
        eRight5: [''],
        eRight6: [''],
        eRight7: [''],
        eRight8: [''],
        eRight9: [''],
        eRight10: [''],
        eRight11: [''],
        eRight12: [''],
        eRight13: [''],
        eRight14: [''],
        eRight15: [''],
        eRight16: [''],
        eRight17: [''],
        eRight18: [''],
        eRight19: [''],
        eRight20: [''],
        eRight21: [''],
        eRight22: [''],
        eRight23: [''],
        eRight24: [''],
        additionalNotes: ['']
      });
  
      if (!this.isAddMode) {
        var model = this.intraOpTelemetryService.recordsValue["results"].find(x => x.modelId == this.id);
        this.versionId = model["versionId"];
        this.modelId = model["modelId"];
        this.form.patchValue(Utils.mapModelToForm(model, ['dateDone']));
      }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() { 
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createIntraOpTelemetry();
        } else {
            this.updateIntraOpTelemetry();
        }
    }

    createIntraOpTelemetry() {
        this.form.get("patient").setValue(this.patientId)
        this.intraOpTelemetryService.add(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Intra-Op Telemetry added successfully', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      
    updateIntraOpTelemetry() {
        this.intraOpTelemetryService.update(this.form.value, this.modelId, this.versionId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}
