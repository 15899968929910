import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { EnnhsrService } from '../ennhsr.service';
import { FileHistoryComponent } from './file-history/file-history.component';

@Component({
  selector: 'input-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileComponent),
    multi: true
  }]
})
export class FileComponent implements ControlValueAccessor {

  onChange: (e: any) => void;
  onTouched: () => void;
  disabled: boolean;
  
  newFile: boolean;

  files: any;
  latestFileIndex: number;

  constructor(
    private ennhsrService: EnnhsrService,
    private dialog: MatDialog
    ) {
      this.files = null;
  }

  writeValue(files: any): void {
    this.newFile = !files
    this.files = files;
    this.latestFileIndex = (files as []).length - 1
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  update(files: FileList) {       
    this.onChange(files);
  }

  download(file: any){
    this.ennhsrService.downloadFile(file["refId"]).pipe(
      map(res => {
        const blob = new Blob([res], { type: file["fileType"] });
        const url= URL.createObjectURL(blob);
        open(url);
      })
    ).subscribe();
  }

  viewHistory() {
    this.dialog.open(FileHistoryComponent, {
      data: {
          files: this.files
      }
  });
  }

}
