import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsRoutingModule } from './forms-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { HearingScreeningComponent } from './hearing-screening/hearing-screening.component';
import { HearingAidDevicesComponent } from './hearing-aid-devices/hearing-aid-devices.component';
import { SpeechTherapyComponent } from './speech-therapy/speech-therapy.component';
import { DevelopmentalMonitoringComponent } from './developmental-monitoring/developmental-monitoring.component';
import { CochlearImplantProgrammingComponent } from './cochlear-implant-programming/cochlear-implant-programming.component';
import { AuditoryEvokedPotentialsComponent } from './auditory-evoked-potentials/auditory-evoked-potentials.component';
import { ConfirmatoryOtherTestsComponent } from './confirmatory-other-tests/confirmatory-other-tests.component';
import { SurgicalHearingInterventionsComponent } from './surgical-hearing-interventions/surgical-hearing-interventions.component';
import { SurgicalHearingInterventionsEventsComponent } from './surgical-hearing-interventions-events/surgical-hearing-interventions-events.component';
import { AddEditABRComponent, AddEditABRComponentDialog } from './auditory-evoked-potentials/add-edit-abr/add-edit-abr.component';
import { AddEditASSRComponent, AddEditASSRComponentDialog } from './auditory-evoked-potentials/add-edit-assr/add-edit-assr.component';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { ScreeningResultsComponent, ScreeningResultsComponentDialog } from './screening-results/screening-results.component';
import { AddEditTympanometryComponent, AddEditTympanometryComponentDialog} from './confirmatory-other-tests/add-edit-tympanometry/add-edit-tympanometry.component';
import { AddEditAcousticReflexTestComponent, AddEditAcousticReflexTestComponentDialog } from './confirmatory-other-tests/add-edit-acoustic-reflex-test/add-edit-acoustic-reflex-test.component';
import { AddEditSpeechPathologySessionComponent, AddEditSpeechPathologySessionComponentDialog } from './speech-therapy/add-edit-speech-pathology-session/add-edit-speech-pathology-session.component';
import { AddEditPEDSScreeningComponent, AddEditPEDSScreeningComponentDialog } from './developmental-monitoring/add-edit-peds-screening/add-edit-peds-screening.component';
import { AddEditComplicationsOutsideSurgeryComponent, AddEditComplicationsOutsideSurgeryComponentDialog } from './surgical-hearing-interventions-events/add-edit-complications-outside-surgery/add-edit-complications-outside-surgery.component';
import { AddEditExplantedDeviceComponent, AddEditExplantedDeviceComponentDialog } from './surgical-hearing-interventions-events/add-edit-explanted-device/add-edit-explanted-device.component';
import { AddEditIntraOpTelemetryComponent, AddEditIntraOpTelemetryComponentDialog } from './cochlear-implant-programming/add-edit-intra-op-telemetry/add-edit-intra-op-telemetry.component';
import { AddEditSessionsComponent, AddEditSessionsComponentDialog } from './cochlear-implant-programming/add-edit-sessions/add-edit-sessions.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AddEditHearingScreeningComponent, AddEditHearingScreeningComponentDialog } from './hearing-screening/add-edit-hearing-screening/add-edit-hearing-screening.component';
import { AddEditHearingAidDevicesComponent, AddEditHearingAidDevicesComponentDialog } from './hearing-aid-devices/add-edit-hearing-aid-devices/add-edit-hearing-aid-devices.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AddEditInterventionComponent, AddEditInterventionComponentDialog } from './surgical-hearing-interventions/add-edit-intervention/add-edit-intervention.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { dateAdapterProvider, dateformatProvider } from '@app/shared/date-format';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    LayoutComponent,
    PatientDetailsComponent,
    HearingScreeningComponent,
    HearingAidDevicesComponent,
    SpeechTherapyComponent,
    DevelopmentalMonitoringComponent,
    CochlearImplantProgrammingComponent,
    AuditoryEvokedPotentialsComponent,
    ConfirmatoryOtherTestsComponent,
    SurgicalHearingInterventionsComponent,
    SurgicalHearingInterventionsEventsComponent,
    AddEditABRComponent,
    AddEditABRComponentDialog,
    AddEditASSRComponent,
    AddEditASSRComponentDialog,
    AddPatientComponent,
    ScreeningResultsComponent,
    ScreeningResultsComponentDialog,
    AddEditTympanometryComponent,
    AddEditTympanometryComponentDialog,
    AddEditAcousticReflexTestComponent,
    AddEditAcousticReflexTestComponentDialog,
    AddEditSpeechPathologySessionComponent,
    AddEditSpeechPathologySessionComponentDialog,
    AddEditPEDSScreeningComponent,
    AddEditPEDSScreeningComponentDialog,
    AddEditComplicationsOutsideSurgeryComponent,
    AddEditComplicationsOutsideSurgeryComponentDialog,
    AddEditExplantedDeviceComponent,
    AddEditExplantedDeviceComponentDialog,
    AddEditIntraOpTelemetryComponent,
    AddEditIntraOpTelemetryComponentDialog,
    AddEditSessionsComponent,
    AddEditSessionsComponentDialog,
    AddEditHearingScreeningComponent,
    AddEditHearingScreeningComponentDialog,
    AddEditHearingAidDevicesComponent,
    AddEditHearingAidDevicesComponentDialog,
    AddEditInterventionComponent,
    AddEditInterventionComponentDialog
  ],
  imports: [
    CommonModule,
    FormsRoutingModule,
    
    ReactiveFormsModule,
    AngularFormsModule,
    
    SharedModule,

    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTooltipModule,

    NgxMaterialTimepickerModule,

    QRCodeModule
  ],
  providers: [
    dateAdapterProvider, dateformatProvider
  ]
})
export class FormsModule { }
