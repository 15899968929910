import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AddEditComponent } from './processing/add-edit/add-edit.component';
import { ProcessingComponent } from './processing/processing.component';
import { RequestsComponent } from './requests/requests.component';
import { ShippingComponent } from './shipping/shipping.component';
import { CompletedComponent } from './completed/completed.component';
import { AddEditSealComponent } from './processing/add-edit-seal/add-edit-seal.component';
import { CanceledComponent } from './canceled/canceled.component';

const routes: Routes = [
  { path: '', component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'requests', pathMatch: 'full' },
      { path: 'requests', component: RequestsComponent },
      { path: 'processing', component: ProcessingComponent,
        children: [
          { path: 'add', component: AddEditComponent },
          { path: 'edit/:id', component: AddEditComponent },
          { path: 'add/seal/:id', component: AddEditSealComponent },
          { path: 'edit/seal/:id', component: AddEditSealComponent }
        ] 
      },
      { path: 'shipping', component: ShippingComponent },
      { path: 'completed', component: CompletedComponent },
      { path: 'canceled', component: CanceledComponent },
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SealRoutingModule { }
