import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { SyncRoutingModule } from './sync-routing.module';
import { dateAdapterProvider, dateformatProvider } from '@app/shared/date-format';



@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    SyncRoutingModule
  ],
  providers: [
    dateAdapterProvider, dateformatProvider
  ]
})
export class SyncModule { }
