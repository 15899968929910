import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { UsersModule } from './users/users.module';
import { SharedModule } from '@app/shared/shared.module';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipList, MatChipsModule} from '@angular/material/chips';
import { ConceptsModule } from './concepts/concepts.module';
import { FacilitiesModule } from './facilities/facilities.module';
import { SealModule } from './seal/seal.module';
import { AnnouncementsModule } from './announcements/announcements.module';
import { FeedbackModule } from './feedback/feedback.module';

@NgModule({
  declarations: [
    LayoutComponent
    
  ],
  imports: [
    AdminRoutingModule,
    SharedModule,

    UsersModule,
    ConceptsModule,
    FacilitiesModule,
    SealModule,
    AnnouncementsModule,
    FeedbackModule,
    
    CommonModule,
    ReactiveFormsModule,

    MatChipsModule,
    MatRadioModule
  ]
})
export class AdminModule { }
