import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@app/user/user.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { debounceTime, startWith } from 'rxjs/operators';
import { PrintService } from '../print/print.service';
import { SelectorService } from '../selector/selector.service';
import Utils from '../utils';
import { RecordHistoryComponent } from './record-history/record-history.component';
import { searchAnimations, rowAnimations } from './table.animations';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    searchAnimations,
    rowAnimations
  ] 
})
export class TableComponent implements OnInit {  
  @Input() data: any;
  @Input() pageData: any;
  @Input() search: boolean;
  @Input() columns: any[];
  @Input() addLink: string;
  @Input() editLink: string;
  @Input() noAdd: boolean;
  @Input() isWriteMode: boolean;
  @Input() isDeletable: boolean;
  @Input() isEditable: boolean;
  @Input() modelName: string;
  @Input() printTitle: string;
  @Input() forChangeHistory: boolean;
  @Output() deleteRowEvent = new EventEmitter<string>();
  @Output() pageEvent = new EventEmitter<string>();
  @Output() searchEvent = new EventEmitter<string>();
  @ViewChild(DatatableComponent) table: DatatableComponent;
  private dataCache: any;
  private dataCached = false;
  public showHistory: boolean;
  
  filterControl = new FormControl();
  opColWidth = 64;
  user;

  constructor(
    private selectorService: SelectorService,
    private userService: UserService,
    public printService: PrintService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if(this.isEditable == null) {
      this.isEditable = true;
    }

    this.user = this.userService.userValue;

    this.showHistory = this.modelName != null && this.user.isAdmin == true;
    this.opColWidth += (this.isDeletable ? 28 : 0) + (this.showHistory ? 28 : 0);

    var c = document.createElement("canvas");
    c.width = 150;
    c.height = 57;
    var ctx = c.getContext("2d");
    ctx.font = "1rem Inter";
    this.columns.forEach(function (column) {
      if(!column.width)
        column.width = (ctx.measureText(column.name).width * 1.15) + 54;
    })
    if(this.isWriteMode === undefined)
      this.isWriteMode = true;

    this.filterControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300)
    ).subscribe(
      val => {
        if(this.search == true){
          this.searchEvent.emit(val);
        } else {
          if(!this.dataCached) {
            this.dataCache = this.data;
            this.dataCached = true;
          }
          this.data = this.dataCache;
          val = val.toLowerCase();
          
          var temp = this.data.filter(function (d) {
            var inprops = false;
            for(let key in d) {
              if(d[key] !== null && d[key].toString().toLowerCase().indexOf(val) !== -1) {
                inprops = true;
              }
            }
            return inprops || !val;
          })
          this.data = temp;
          this.table.offset = 0;
        }
      }
    )
  }

  editRow(id: string) {
    return this.editLink + "/" + id;
  }

  viewHistory(id: string) {
    this.dialog.open(RecordHistoryComponent, {
      data: {
          id: id,
          columns: this.columns,
          modelName: this.modelName
      }
  });
  }

  deleteRow(id: string) {
    if(confirm("Are you sure you want to delete this record?")){
      const row = this.data.find(x => x.id === id);
      row.isDeleting = true;
      this.deleteRowEvent.emit(id);
    }
  }

  selectPage(page: number) {
    this.pageEvent.emit(page.toString());
  }

  onScroll(e: Event) {
    // this.selectorService.scroll();
  }

  displayValue(val: any) {
    if(typeof val === 'object' && val !== null) {
      var date = (<Date>val)
      val = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
    }
    return val;
  }

  isArray(val) {
    return Array.isArray(val);
  }

  setPages(count, limit, offset) {
    if(limit > 0){
      var page = offset/limit;
      var last = Math.ceil(count/limit);
      var start = Math.floor(page/10)*10;
      var end = start+10 > last ? last : start + 10;
      var arr = []
      for(var i = start; i < end; i++) {
        arr.push(i);
      }
      return arr;
      // return Array.from(Array(Math.ceil(count/limit)).keys());
    }
    return null;
  }

  floor(n) {
    return Math.floor(n);
  }

  ceil(n) {
    return Math.ceil(n);
  }
}
