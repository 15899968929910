import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class TopFacilitiesInTotalNoScreenedReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Rank'
    })
    order: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Facility Name'
    })
    facilityName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Number Screened'
    })
    numberScreened: string;
}
