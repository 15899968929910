import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SealDistributionService } from '../../seal-distribution.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertService } from '@app/shared/alert/alert.service';
import { RequestsSealDistributionService } from '../../requests/requests-seal-distribution.service';
import { ProcessingSealDistributionService } from '../processing-seal-distribution.service';

@Component({ template: '' })
export class AddEditComponent implements OnInit {
    
    constructor(public dialog: MatDialog,
        private location: Location,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.openDialog();
    }

    openDialog(): void {
        let id = this.route.snapshot.params['id']
        const dialogRef = this.dialog.open(AddEditComponentDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: id,
                isAddMode: !id
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.location.back();
        });
    }
}

@Component({ templateUrl: 'add-edit.component.html' })
export class AddEditComponentDialog implements OnInit {

    form: FormGroup;
    loading = false;
    submitted = false;
    sealRequest$ : Observable<any>;
    private versionId: string;
    private modelId: string;

    constructor(        
        //private sealDistributionService: SealDistributionService,
        private requestsSealDistributionService: RequestsSealDistributionService,
        private processingSealDistributionService: ProcessingSealDistributionService,
        private alertService: AlertService,
        public dialogRef: MatDialogRef<AddEditComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder, 
        }
    ) { }

    ngOnInit() {
        this.requestsSealDistributionService.getAll().subscribe();
        this.sealRequest$ =  this.requestsSealDistributionService.recordsObservable.pipe(
            map(sealRequests => {
                const sortedResults = sealRequests["results"].slice(); 
                sortedResults.sort((a, b) => {
                  const facilityNameA = a['dataModel']['facility']['facilityName'];
                  const facilityNameB = b['dataModel']['facility']['facilityName'];
                  return facilityNameA.localeCompare(facilityNameB); 
                });
                return sortedResults;
            }))
        this.form = this.data.formBuilder.group({
            referenceID: [''],
            requestStatus: ['']
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        this.alertService.clear();

        this.loading = true;
        this.processRequest();
    }

    changeRequest(e) {
        var selectedRequest = this.requestsSealDistributionService.recordsValue["results"].find(x => x.dataModel.referenceID == e);
        this.versionId = selectedRequest["versionId"];
        this.modelId = selectedRequest["modelId"];
    }

    processRequest(): void {
        this.requestsSealDistributionService.updateStatus(this.modelId, "PROCESSED")
        .pipe(first())
        .subscribe({
            next: () => {
                this.processingSealDistributionService.getAll().subscribe();
                this.alertService.success('Processed request', { keepAfterRouteChange: true });
                this.loading = false;
                this.dialogRef.close();
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
                this.dialogRef.close();
            }
        });
    }
}
