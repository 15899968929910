<div class="container pb-3">
    <div class="d-flex flex-column h-100 m-3">
        <div class="mb-3">
            <button (click)="back()" class="btn btn-success"><i class="mdi mdi-arrow-left"></i> Back</button>
        </div>
        <h5><i class="mdi mdi-lock-check"></i> <b>Data Privacy Policy and Agreement</b></h5>
        <div class="ml-3 mt-2">
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Dui faucibus in ornare quam. Suspendisse interdum consectetur libero id. Nibh ipsum consequat nisl vel pretium lectus quam id leo. Et netus et malesuada fames ac turpis. Diam sit amet nisl suscipit. Feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Luctus venenatis lectus magna fringilla urna. Volutpat sed cras ornare arcu dui vivamus. Convallis a cras semper auctor neque vitae tempus. Non quam lacus suspendisse faucibus. Molestie a iaculis at erat pellentesque adipiscing commodo. Feugiat in fermentum posuere urna nec tincidunt. Cras ornare arcu dui vivamus arcu felis. Donec et odio pellentesque diam volutpat commodo sed egestas egestas. Molestie nunc non blandit massa.</p>
            <p>Risus feugiat in ante metus dictum at tempor. Pellentesque nec nam aliquam sem et tortor consequat. Sit amet commodo nulla facilisi nullam vehicula ipsum. Id diam vel quam elementum pulvinar etiam non quam. Lobortis mattis aliquam faucibus purus. Pretium lectus quam id leo in vitae turpis massa sed. Odio ut enim blandit volutpat maecenas volutpat blandit. Eget arcu dictum varius duis at consectetur lorem donec. Maecenas accumsan lacus vel facilisis volutpat est velit egestas. Vulputate enim nulla aliquet porttitor lacus luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Convallis aenean et tortor at risus viverra adipiscing at in. Lorem dolor sed viverra ipsum nunc. Ut etiam sit amet nisl. A diam sollicitudin tempor id eu nisl. Nullam non nisi est sit.</p>
            <p>Facilisis leo vel fringilla est ullamcorper eget nulla. Eu lobortis elementum nibh tellus molestie. Dictum sit amet justo donec enim diam vulputate ut. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Velit scelerisque in dictum non. Bibendum at varius vel pharetra vel turpis nunc eget lorem. Cras pulvinar mattis nunc sed. Dictum varius duis at consectetur lorem. Quam lacus suspendisse faucibus interdum posuere. Condimentum id venenatis a condimentum vitae.</p>
            <p>Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Amet massa vitae tortor condimentum. Gravida in fermentum et sollicitudin ac orci phasellus egestas. Elementum nisi quis eleifend quam adipiscing vitae. Curabitur vitae nunc sed velit dignissim sodales. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet. Etiam non quam lacus suspendisse faucibus interdum. Aliquet nec ullamcorper sit amet risus. Eu non diam phasellus vestibulum lorem sed risus ultricies. Faucibus turpis in eu mi bibendum neque. Sit amet nulla facilisi morbi tempus. Risus in hendrerit gravida rutrum quisque non tellus orci. Faucibus vitae aliquet nec ullamcorper sit. Ac tortor dignissim convallis aenean et.</p>
            <p>Venenatis tellus in metus vulputate eu. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Vitae tortor condimentum lacinia quis vel eros. Commodo odio aenean sed adipiscing diam. Lacus luctus accumsan tortor posuere ac ut consequat. Tincidunt dui ut ornare lectus sit amet. Arcu dui vivamus arcu felis. Nulla pharetra diam sit amet. Netus et malesuada fames ac turpis egestas maecenas. Placerat vestibulum lectus mauris ultrices eros in cursus turpis massa. Vel pharetra vel turpis nunc eget lorem dolor. Mi eget mauris pharetra et ultrices neque ornare. Libero nunc consequat interdum varius sit amet mattis vulputate enim.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Dui faucibus in ornare quam. Suspendisse interdum consectetur libero id. Nibh ipsum consequat nisl vel pretium lectus quam id leo. Et netus et malesuada fames ac turpis. Diam sit amet nisl suscipit. Feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Luctus venenatis lectus magna fringilla urna. Volutpat sed cras ornare arcu dui vivamus. Convallis a cras semper auctor neque vitae tempus. Non quam lacus suspendisse faucibus. Molestie a iaculis at erat pellentesque adipiscing commodo. Feugiat in fermentum posuere urna nec tincidunt. Cras ornare arcu dui vivamus arcu felis. Donec et odio pellentesque diam volutpat commodo sed egestas egestas. Molestie nunc non blandit massa.</p>
            <p>Risus feugiat in ante metus dictum at tempor. Pellentesque nec nam aliquam sem et tortor consequat. Sit amet commodo nulla facilisi nullam vehicula ipsum. Id diam vel quam elementum pulvinar etiam non quam. Lobortis mattis aliquam faucibus purus. Pretium lectus quam id leo in vitae turpis massa sed. Odio ut enim blandit volutpat maecenas volutpat blandit. Eget arcu dictum varius duis at consectetur lorem donec. Maecenas accumsan lacus vel facilisis volutpat est velit egestas. Vulputate enim nulla aliquet porttitor lacus luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Convallis aenean et tortor at risus viverra adipiscing at in. Lorem dolor sed viverra ipsum nunc. Ut etiam sit amet nisl. A diam sollicitudin tempor id eu nisl. Nullam non nisi est sit.</p>
            <p>Facilisis leo vel fringilla est ullamcorper eget nulla. Eu lobortis elementum nibh tellus molestie. Dictum sit amet justo donec enim diam vulputate ut. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Velit scelerisque in dictum non. Bibendum at varius vel pharetra vel turpis nunc eget lorem. Cras pulvinar mattis nunc sed. Dictum varius duis at consectetur lorem. Quam lacus suspendisse faucibus interdum posuere. Condimentum id venenatis a condimentum vitae.</p>
            <p>Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Amet massa vitae tortor condimentum. Gravida in fermentum et sollicitudin ac orci phasellus egestas. Elementum nisi quis eleifend quam adipiscing vitae. Curabitur vitae nunc sed velit dignissim sodales. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet. Etiam non quam lacus suspendisse faucibus interdum. Aliquet nec ullamcorper sit amet risus. Eu non diam phasellus vestibulum lorem sed risus ultricies. Faucibus turpis in eu mi bibendum neque. Sit amet nulla facilisi morbi tempus. Risus in hendrerit gravida rutrum quisque non tellus orci. Faucibus vitae aliquet nec ullamcorper sit. Ac tortor dignissim convallis aenean et.</p>
            <p>Venenatis tellus in metus vulputate eu. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Vitae tortor condimentum lacinia quis vel eros. Commodo odio aenean sed adipiscing diam. Lacus luctus accumsan tortor posuere ac ut consequat. Tincidunt dui ut ornare lectus sit amet. Arcu dui vivamus arcu felis. Nulla pharetra diam sit amet. Netus et malesuada fames ac turpis egestas maecenas. Placerat vestibulum lectus mauris ultrices eros in cursus turpis massa. Vel pharetra vel turpis nunc eget lorem dolor. Mi eget mauris pharetra et ultrices neque ornare. Libero nunc consequat interdum varius sit amet mattis vulputate enim.</p> -->
            <p align="justify"><b>BACKGROUND</b></p>
            <p align="justify">It is the responsibility of all the system users: administration, end-users (medical and allied health), and technical support staff, to preserve and protect confidential data of patients, facility and user accounts of the Electronic National Newborn Hearing Registry (ENNHSR).</p>
            <p align="justify"><b>DATA PRIVACY PROTECTION</b></p>
            <p align="justify">The Data Privacy Act of 2012, also known as RA 10173, governs the protection of your rights and the rights of your patients/data subjects in the collection, processing, sharing and releasing of any individual’s personal and sensitive information. This law was established to protect and preserve the confidentiality, integrity, and availability of various personal, sensitive, including health information and such information may not be processed nor disclosed EXCEPT as authorized by law or through the informed consent of the specific patient or data subject.</p>
            <p align="justify"><b>PROTECTION of PATIENT CARE INFORMATION</b></p>
            <p align="justify">This includes any individual personal information (Pl), sensitive personal information (SPI), and health information in possession of or derived from the providers of health care regarding a patient's medical history, mental, emotional, or physical condition or treatment, as well as the patient's and/or their family members' records, test results, conversations, research records, financial information, and other pertinent personal data.</p>
            <p align="justify">As such therefore, I fully understand and voluntarily acknowledge the following:</p>
            <p align="justify">1.       I recognize both my rights to data privacy and the rights of my data subjects;</p>
            <p align="justify">2.    I am mandated by law to protect the personal information (PI), sensitive personal information (SPI), and health information in my possession and those I derive from other health care providers of the patient and/or other patients/data subjects in the system;</p>
            <p align="justify" class="ml-5">a.       It is my legal and ethical responsibility to protect the privacy, confidentiality, integrity, and security of all health records and patient information;</p>
            <p align="justify" class="ml-5">b.   I shall respect and maintain the confidentiality of all patients' information and records and any other information generated in connection with individual patient care, research, training, risk management and/or peer review activities and all the transactions with other ENNHSR clients;</p>
            <p align="justify">3.       I shall only collect, access, and disseminate patient care information in accordance with the performance of my assigned duties and where required or permitted by law in a manner consistent with the data privacy policies of my affiliated facility and this system or where no officially adopted policy exists, only with the express and written prior approval of my supervisor or his/her designate;</p>
            <p align="justify" class="ml-5">a.       I will only access the minimum necessary information required of my job/role or of the need requested for the specific information;</p>
            <p align="justify" class="ml-5">b.   I am bound to obtain a signed informed consent from the patients or legally appointed representative whose information I shall authorize another individual or groups to collect and process for Medical/Surgical/ Ancillary/Nursing Services, Research, and Training and Education.;</p>
            <p align="justify">4.       I agree to discuss confidential information only within my designated facility, intervention team members, and only for job-related purposes and not to discuss such information outside of the facility or within hearing of other people who do not have a need to know about the information;</p>
            <p align="justify">5.       I recognize that the Newborn Hearing Screening Reference Center (NHSRC) authorizes internal and external system audits and reviews of patient records and other clients documented information in order to identify inappropriate and/or unauthorized disclosure, archiving, disposal of such documents and records;</p>
            <p align="justify">6.      I understand that any and all computer system access codes and passwords that are assigned to me are strictly confidential and for use only in line with my assigned tasks and duty hours;</p>
            <p align="justify" class="ml-5">a.       I will not disclose nor share my access code/s and passwords to anyone;</p>
            <p align="justify" class="ml-5">b.   Upon termination of my employment/assignment/duties at my assigned facility or transfer to another facility, I understand that any and all access codes and passwords that have been assigned to me will be voided and archived under the supervision of the NHSRC management through the ENNHSR Administrators, from the corresponding computer system/s including from my personal gadgets, for example but not limited to, USB, external drives, and any other storage devices;</p>
            <p align="justify">7.       My obligation to safeguard the patient/s and other data subject/s confidentiality and integrity continues even after my separation from service and/or my contractual obligations have ended.</p>
            <p align="justify">8.     I understand that failure to adhere to these agreements and policies shall be penalized as stated in the Chapter VIII, Sections 25 – 37 of the Republic Act 10173 or Data Privacy Act of 2012.   </p>
            <p align="justify">Upon clicking the “I agree to the privacy policy” checkbox, I hereby acknowledge that I have read and understood the foregoing information and that I signify my agreement to comply with and abide by the above terms of my own free will and volition. In the event of a breach or potential/threatened breach of the Non­ Disclosure/Confidentiality Agreement, I acknowledge that NHSRC may, as applicable and as it deems appropriate, pursue any and all actions allowed by law.</p>

        </div>
    </div>
</div>
