import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UnitRoutingModule } from './unit-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from '@app/shared/shared.module';
import { SealComponent } from './seal/seal.component';
import { MachinesModule } from './machines/machines.module';
import { dateAdapterProvider, dateformatProvider } from '@app/shared/date-format';


@NgModule({
  declarations: [LayoutComponent, SealComponent],
  imports: [
    UnitRoutingModule,
    SharedModule,
    MachinesModule,
    CommonModule,
    ReactiveFormsModule,   
  ],
  providers: [
    dateAdapterProvider, dateformatProvider
  ]
})
export class UnitModule { }
