import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
import HC_more from "highcharts/highcharts-more";
import HC_exporting from "highcharts/modules/exporting";

import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);
import { first } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';
import { FacilitiesService } from '@app/admin/facilities/facilities.service';
import Utils from '@app/shared/utils';
import { ReportsComponent } from '../reports/reports.component';
import { UsersService } from '@app/admin/users/users.service';
import { eachWeekendOfYear, isThursday } from 'date-fns';
// import { ConsoleReporter } from 'jasmine';

(function (Highcharts: any) {
    Highcharts.getSVG = function (charts) {
        var svgArr = [],
            top = 0,
            width = 0;

        Highcharts.each(charts, function (chart) {
            var svg = chart.getSVG(),
                // Get width/height of SVG for export
                svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1],
                svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];

            svg = svg.replace(
                "<svg",
                '<g transform="translate(0,' + top + ')" ');
            svg = svg.replace("</svg>", "</g>");

            top += svgHeight;
            width = Math.max(width, svgWidth);

            svgArr.push(svg);
        });

        return (
            '<svg height="' +
            top +
            '" width="' +
            width +
            '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
            svgArr.join("") +
            "</svg>"
        );
    };

    Highcharts.exportCharts = function (charts, options) {
        //   charts.forEach(function(chart) {
        //     chart.update({
        //       chart: {
        //         borderColor: "red",
        //         borderWidth: 2
        //       }
        //     });
        //   });
        options = Highcharts.merge(Highcharts.getOptions().exporting, options);

        Highcharts.post(options.url, {
            filename: options.filename || "ennhsr_charts_" + new Date().getTime(),
            type: options.type,
            width: options.width,
            svg: Highcharts.getSVG(charts)
        });

        charts.forEach(function (chart) {
            chart.update({
                chart: {
                    borderWidth: 1
                }
            });
        });
    };
})(Highcharts);

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
    patientsScreened = 0;
    liveBirthsScreened = 0;
    activeHealthFacilities = "0 / 0";
    activeUsers = 0;

    patientsScreenedLoading = true;
    liveBirthsScreenedLoading = true;
    activeHealthFacilitiesLoading = true;
    activeUsersLoading = true;

    screeningResultsReportLoading = true;
    noOfScreeningFacilitiesByRegionReportLoading = true;
    topFacilitiesInTotalNoOfLiveBirthsReportLoading = true;
    topFacilitiesInTotalNoScreenedReportLoading = true;
    noScreenedAccScreeningMethodReportLoading = true;
    totalLiveBirthsScreenedReportLoading = true;
    activeUsersReportLoading = true;

    constructor(
        private dashboardService: DashboardService,
        private facilitiesService: FacilitiesService,
        private reportsComponent: ReportsComponent,
        private usersService: UsersService
    ) { }

    ngOnInit(): void {
        // this.getNoOfScreeningFacilitiesByRegionReport();
        // this.getTopFacilitiesInTotalNoOfLiveBirthsReport();
        // this.getTopFacilitiesInTotalNoScreenedReport();
        // this.getScreeningResultsReport();
        // this.getNoScreenedAccScreeningMethodReport();
        // this.getTotalLiveBirthsScreenedReport();
        this.getActiveUsersReport();
    }

    getScreeningResultsReport() {
        this.dashboardService.getScreeningResultsOptimized([]).pipe(first())
            .subscribe(res => {
                // this.getNoOfScreeningFacilitiesByRegionReport();
                this.getTotalLiveBirthsScreenedReport();
                let transform = [
                    {
                        result: "Bilateral Pass",
                        initial: res[0],
                        initialPercent: "",
                        rescreen: res[1],
                        rescreenPercent: "",
                        total: res[0] + res[1]
                    },
                    {
                        result: "Bilateral Refer",
                        initial: res[2],
                        initialPercent: "",
                        rescreen: res[3],
                        rescreenPercent: "",
                        total: res[2] + res[3]
                    },
                    {
                        result: "Unilateral Refer",
                        initial: res[4],
                        initialPercent: "",
                        rescreen: res[5],
                        rescreenPercent: "",
                        total: res[4] + res[5]
                    },
                    {
                        result: "Not Performed - Bilateral",
                        initial: res[6],
                        initialPercent: "",
                        rescreen: res[7],
                        rescreenPercent: "",
                        total: res[6] + res[7]
                    },
                    {
                        result: "Not Performed - Unilateral",
                        initial: res[8],
                        initialPercent: "",
                        rescreen: res[9],
                        rescreenPercent: "",
                        total: res[8] + res[9]
                    },
                    {
                        result: "Not Performed - Refusal",
                        initial: null,
                        initialPercent: "",
                        rescreen: null,
                        rescreenPercent: "",
                        total: res[10]
                    }
                ]
                this.screeningResultsReportLoading = false;

                Highcharts.setOptions({
                    chart: {
                        style: {
                            fontFamily: 'Inter'
                        }
                    }
                });

                Highcharts.chart('getScreeningResultsReportContainer', {
                    exporting: {
                        filename: 'screeningresults'
                    },
                    chart: {
                        borderColor: '#2b87b8',
                        borderWidth: 1,
                        type: 'column'
                    },
                    title: {
                        text: 'Screening Results',
                        style: {
                            fontSize: '15'
                        },
                        margin: 60,
                        y: 30
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        categories: [
                            'Bilateral Pass',
                            'Bilateral Refer',
                            'Unilateral Refer',
                            'Not Performed - Bilateral',
                            'Not Performed - Unilateral',
                            'Not Performed - Refusal'
                        ],
                        crosshair: true,
                        title: {
                            text: 'Screening Results'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Number Screened'
                        },
                        allowDecimals: false
                    },
                    tooltip: {
                        valueDecimals: 0,
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        },
                        series: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Initial',
                        type: 'column',
                        data: transform.map(val => val.initial),
                    }, {
                        name: 'Rescreen',
                        type: 'column',
                        data: transform.map(val => val.rescreen),
                    }, {
                        name: 'Total',
                        type: 'column',
                        data: transform.map(val => val.total)
                    }],
                });

            });
    }

    // getScreeningResultsReportLegacy(){
    //     this.dashboardService.getScreeningResults(null)
    //     .subscribe(screeningResults => {
    //     this.getNoOfScreeningFacilitiesByRegionReport();
    //     let transform = [
    //         {
    //           result: "Bilateral Pass",
    //           initial: 0,
    //           initialPercent: "",
    //           rescreen: 0,
    //           rescreenPercent: "",
    //           total: 0
    //         },
    //         {
    //           result: "Bilateral Refer",
    //           initial: 0,
    //           initialPercent: "",
    //           rescreen: 0,
    //           rescreenPercent: "",
    //           total: 0
    //         },
    //         {
    //           result: "Unilateral Refer",
    //           initial: 0,
    //           initialPercent: "",
    //           rescreen: 0,
    //           rescreenPercent: "",
    //           total: 0
    //         },
    //         {
    //           result: "Not Performed - Bilateral",
    //           initial: 0,
    //           initialPercent: "",
    //           rescreen: 0,
    //           rescreenPercent: "",
    //           total: 0
    //         },
    //         {
    //           result: "Not Performed - Unilateral",
    //           initial: 0,
    //           initialPercent: "",
    //           rescreen: 0,
    //           rescreenPercent: "",
    //           total: 0
    //         },
    //         {
    //           result: "Not Performed - Refusal",
    //           initial: null,
    //           initialPercent: "",
    //           rescreen: null,
    //           rescreenPercent: "",
    //           total: 0
    //         }
    //       ]
    //       transform[5].total = screeningResults["status"];

    //       for(var i=0; i<screeningResults["hearingScreening"].length; i++) {
    //         if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "pass" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "pass"){
    //           transform[0].total++;
    //           if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
    //             transform[0].initial++;
    //           else
    //             transform[0].rescreen++;
    //         } else if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "refer" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "refer"){
    //           transform[1].total++;
    //           if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
    //             transform[1].initial++;
    //           else
    //             transform[1].rescreen++;
    //         } else if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "not performed" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "not performed"){
    //           transform[3].total++;
    //           if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
    //             transform[3].initial++;
    //           else
    //             transform[3].rescreen++;
    //         } else if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "refer" || screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "refer"){
    //           transform[2].total++;
    //           if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
    //             transform[2].initial++;
    //           else
    //             transform[2].rescreen++;
    //         }
    //         if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] == "not performed" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] != "not performed"){
    //           transform[4].total++;
    //           if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
    //             transform[4].initial++;
    //           else
    //             transform[4].rescreen++;
    //         } else if(screeningResults["hearingScreening"][i]["dataModel"]["resultLeft"] != "not performed" && screeningResults["hearingScreening"][i]["dataModel"]["resultRight"] == "not performed"){
    //           transform[4].total++;
    //           if(screeningResults["hearingScreening"][i]["dataModel"]["typeOfScreening"] == "Initial")
    //             transform[4].initial++;
    //           else
    //             transform[4].rescreen++;
    //         }
    //       }
    //     this.screeningResultsReportLoading = false;

    //     Highcharts.setOptions({
    //         chart: {
    //             style: {
    //                 fontFamily: 'Inter'
    //             }
    //         }
    //     });

    //     Highcharts.chart('getScreeningResultsReportContainer', {
    //         exporting: {
    //             filename: 'screeningresults'
    //         },
    //         chart: {
    //             borderColor: '#2b87b8',
    //             borderWidth: 1,
    //             type: 'column'
    //         },
    //         title: {
    //             text: 'Screening Results',
    //             style: {
    //                 fontSize: '15'
    //             },
    //             margin: 60,
    //             y: 30
    //         },
    //         legend: {
    //             enabled: false
    //         },
    //         xAxis: {
    //             categories: [
    //                 'Bilateral Pass',
    //                 'Bilateral Refer',
    //                 'Unilateral Refer',
    //                 'Not Performed - Bilateral',
    //                 'Not Performed - Unilateral',
    //                 'Not Performed - Refusal'
    //             ],
    //             crosshair: true,
    //             title: {
    //                 text: 'Screening Results'
    //             }
    //         },
    //         yAxis: {
    //             min: 0,
    //             title: {
    //                 text: 'Number Screened'
    //             },
    //             allowDecimals: false
    //         },
    //         tooltip: {
    //             valueDecimals: 0,
    //             shared: true,
    //             useHTML: true
    //         },
    //         plotOptions: {
    //             column: {
    //                 pointPadding: 0.2,
    //                 borderWidth: 0
    //             },
    //             series: {
    //                 dataLabels: {
    //                     enabled: true
    //                 }
    //             }
    //         },
    //         credits: {
    //             enabled: false
    //         },
    //         series: [{
    //             name: 'Initial',
    //             type: 'column',
    //             data: transform.map(val => val.initial),
    //         }, {
    //             name: 'Rescreen',
    //             type: 'column',
    //             data: transform.map(val => val.rescreen),
    //         }, {
    //             name: 'Total',
    //             type: 'column',
    //             data: transform.map(val => val.total)
    //         }],
    //     });

    //   });
    // }


    getNoOfScreeningFacilitiesByRegionReport() {
        this.facilitiesService.getAll()
            .subscribe(facilities => {
                // this.getTotalLiveBirthsScreenedReport();
                this.getNoScreenedAccScreeningMethodReport();
                let transform = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                let aHF = 0
                for (var i = 0; i < facilities["results"].length; i++) {
                    if (facilities["results"][i]["dataModel"]["active"] == true)
                        aHF++;
                    let psgcRegion = (Utils.psgcUtils.cleanPsgc(facilities["results"][i]["dataModel"]["psgcCode"]).substring(0, 2))
                    switch (psgcRegion) {
                        case "13":
                            transform[0]++;
                            break;
                        case "14":
                            transform[1]++;
                            break;
                        case "01":
                            transform[2]++;
                            break;
                        case "02":
                            transform[3]++;
                            break;
                        case "03":
                            transform[4]++;
                            break;
                        case "04":
                            transform[5]++;
                            break;
                        case "17":
                            transform[6]++;
                            break;
                        case "05":
                            transform[7]++;
                            break;
                        case "06":
                            transform[8]++;
                            break;
                        case "07":
                            transform[9]++;
                            break;
                        case "08":
                            transform[10]++;
                            break;
                        case "09":
                            transform[11]++;
                            break;
                        case "10":
                            transform[12]++;
                            break;
                        case "11":
                            transform[13]++;
                            break;
                        case "12":
                            transform[14]++;
                            break;
                        case "16":
                            transform[15]++;
                            break;
                        case "15":
                            transform[16]++;
                            break;
                    }
                }
                this.activeHealthFacilities = aHF + " / " + facilities["results"].length;
                this.activeHealthFacilitiesLoading = false;
                this.noOfScreeningFacilitiesByRegionReportLoading = false;

                Highcharts.setOptions({
                    chart: {
                        style: {
                            fontFamily: 'Inter',
                            fontSize: '10'
                        }
                    }
                });

                Highcharts.chart('getNoOfScreeningFacilitiesByRegionReportContainer', {
                    exporting: {
                        filename: 'screeningfacilities'
                    },
                    chart: {
                        borderColor: '#2b87b8',
                        borderWidth: 1,
                        type: 'column'
                    },
                    title: {
                        text: 'Screening Facilities',
                        style: {
                            fontSize: '15'
                        },
                        margin: 60,
                        y: 30
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        categories: [
                            'NCR',
                            'CAR',
                            'I',
                            'II',
                            'III',
                            'IV-A',
                            'IV-B',
                            'V',
                            'VI',
                            'VII',
                            'VIII',
                            'IX',
                            'X',
                            'XI',
                            'XII',
                            'XIII',
                            'ARMM'
                        ],
                        crosshair: true,
                        title: {
                            text: 'Regions'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Number of Screening Facilities'
                        },
                        allowDecimals: false
                    },
                    tooltip: {
                        shared: true,
                        useHTML: true,
                        valueDecimals: 0
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        },
                        series: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Screening Facilities',
                        type: 'column',
                        data: transform
                    }]
                });
            });
    }

    getTopFacilitiesInTotalNoOfLiveBirthsReport() {
        this.dashboardService.getTopFacilitiesInTotalNoOfLiveBirthsReport()
            .subscribe(user => {
                this.getTopFacilitiesInTotalNoScreenedReport();
                let transform = [
                    {
                        order: 0,
                        facilityName: "z",
                        liveBirths: 0
                    }];
                for (var i = 0; i < user["results"].length; i++) {
                    if (user["results"][i]["dataModel"]['facilityCode'] !== null) {
                        let idx = transform.findIndex(({ facilityName }) => facilityName.toUpperCase() == user["results"][i]["dataModel"]['facilityCode'].toUpperCase());
                        if (idx > -1)
                            transform[idx].liveBirths++;
                        else
                            transform.push({ order: 0, facilityName: user["results"][i]["dataModel"]["facilityCode"], liveBirths: 1 });
                    }
                }
                transform.splice(0, 1);
                transform = transform.sort(function (a, b) {
                    return b.liveBirths - a.liveBirths;
                })
                transform.splice(10);
                transform.map(x => {
                    x['order'] = transform.indexOf(x) + 1;
                    return x;
                })
                this.topFacilitiesInTotalNoOfLiveBirthsReportLoading = false;

                Highcharts.setOptions({
                    chart: {
                        style: {
                            fontFamily: 'Inter'
                        }
                    }
                });

                Highcharts.chart('getTopFacilitiesInTotalNoOfLiveBirthsReportContainer', {
                    exporting: {
                        filename: 'top10healthfacilitiesinnumberoflivebirths'
                    },
                    chart: {
                        borderColor: '#2b87b8',
                        borderWidth: 1,
                        type: 'bar'
                    },
                    title: {
                        text: 'Top 10 Health Facilities in Number of Live Births',
                        style: {
                            fontSize: '15'
                        },
                        margin: 60,
                        y: 30
                    },
                    xAxis: {
                        categories: transform.map(val => val.facilityName),
                        title: {
                            text: "Health Facility"
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Live Births'
                        },
                        labels: {
                            overflow: 'justify'
                        },
                        allowDecimals: false
                    },
                    tooltip: {
                        valueDecimals: 0,
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: -40,
                        y: 80,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor:
                            Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        shadow: true,
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Live Births',
                        type: 'bar',
                        data: transform.map(val => val.liveBirths)
                    }]
                });
            });
    }

    getTopFacilitiesInTotalNoScreenedReport() {
        this.dashboardService.getTopFacilitiesInTotalNoScreenedReport()
            .subscribe(user => {
                // this.getTotalLiveBirthsScreenedReport();
                let transform = [
                    {
                        order: 0,
                        facilityName: "",
                        numberScreened: 0
                    }];
                for (var i = 0; i < user["results"].length; i++) {
                    if (user["results"][i]["dataModel"]['hSC'] && user["results"][i]["dataModel"]) {
                        var idx = transform.findIndex(({ facilityName }) => facilityName.toUpperCase() === user["results"][i]["dataModel"]['hSC'].toUpperCase());
                        if (idx > -1)
                            transform[idx].numberScreened++;
                        else
                            transform.push({ order: 0, facilityName: user["results"][i]["dataModel"]["hSC"], numberScreened: 1 });
                    }
                }
                transform.splice(0, 1);
                transform = transform.sort(function (a, b) {
                    return b.numberScreened - a.numberScreened;
                })
                transform.splice(10);
                transform.map(x => {
                    x['order'] = transform.indexOf(x) + 1;
                    return x;
                })
                this.topFacilitiesInTotalNoScreenedReportLoading = false;

                Highcharts.setOptions({
                    chart: {
                        style: {
                            fontFamily: 'Inter'
                        }
                    }
                });

                Highcharts.chart('getTopFacilitiesInTotalNoScreenedReportContainer', {
                    exporting: {
                        filename: 'top10healthfacilitiesinnumberscreened'
                    },
                    chart: {
                        borderColor: '#2b87b8',
                        borderWidth: 1,
                        type: 'bar'
                    },
                    title: {
                        text: 'Top 10 Health Facilities in Number Screened',
                        style: {
                            fontSize: '15'
                        },
                        margin: 60,
                        y: 30
                    },
                    xAxis: {
                        categories: transform.map(val => val.facilityName),
                        title: {
                            text: "Health Facility"
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Number Screened'
                        },
                        labels: {
                            overflow: 'justify'
                        },
                        allowDecimals: false
                    },
                    tooltip: {
                        valueDecimals: 0,
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: -40,
                        y: 80,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor:
                            Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        shadow: true,
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Number Screened',
                        type: 'bar',
                        data: transform.map(val => val.numberScreened)
                    }]
                });
            });
    }

    // getNoScreenedAccScreeningMethodReport = (filters? : object) => {
    //     this.dashboardService.getNoScreenedAccScreeningMethod(filters).pipe(first())
    //     .subscribe(hearingScreening => {
    //       let transform = [
    //         {
    //           screeningMethod: "AABR",
    //           count: 0,
    //           percent: ""
    //         },
    //         {
    //           screeningMethod: "OAE",
    //           count: 0,
    //           percent: ""
    //         },
    //         {
    //           screeningMethod: "Others",
    //           count: 0,
    //           percent: ""
    //         },
    //         {
    //           screeningMethod: "TOTAL",
    //           count: 0,
    //           percent: ""
    //         }
    //       ]
    //       for(var i=0; i<hearingScreening["results"].length; i++) {
    //         switch (hearingScreening["results"][i]["dataModel"]["methodOfScreening"]){
    //           case "AABR":
    //             transform[0].count++;
    //             transform[3].count++;
    //             break;
    //           case "OAE":
    //             transform[1].count++;
    //             transform[3].count++;
    //             break;
    //           case "Others":
    //             transform[2].count++;
    //             transform[3].count++;
    //             break;
    //         }
    //       }
    //       if(transform[3].count != 0){
    //         transform[0].percent = (transform[0].count/transform[3].count*100).toFixed(2) + "%";
    //         transform[1].percent = (transform[1].count/transform[3].count*100).toFixed(2) + "%";
    //         transform[2].percent = (transform[2].count/transform[3].count*100).toFixed(2) + "%";
    //         transform[3].percent = "100.00%";
    //       }
    //       this.loading = false;

    //       const obs = new Observable<any>((observer) => {
    //         observer.next(DeserializeArray(transform, NoScreenedAccScreeningMethodReport))
    //       });
    //       this.data = obs;
    //       this.downloadReportGroupByRegion(filters["psgc"]);
    //     });
    //   }

    getNoScreenedAccScreeningMethodReport() {
        this.dashboardService.getNoScreenedAccScreeningMethodOptimized([]).pipe(first())
            .subscribe(res => {
                this.getTopFacilitiesInTotalNoOfLiveBirthsReport();
                let transform = [
                    {
                        screeningMethod: "AABR",
                        count: res[0],
                        percent: ""
                    },
                    {
                        screeningMethod: "OAE",
                        count: res[1],
                        percent: ""
                    },
                    {
                        screeningMethod: "Others",
                        count: res[2],
                        percent: ""
                    },
                    {
                        screeningMethod: "TOTAL",
                        count: res[0] + res[1] + res[2],
                        percent: ""
                    }
                ]
                
                this.noScreenedAccScreeningMethodReportLoading = false;

                Highcharts.setOptions({
                    chart: {
                        style: {
                            fontFamily: 'Inter'
                        }
                    }
                });

                Highcharts.chart('getNoScreenedAccScreeningMethodReportContainer', {
                    exporting: {
                        filename: 'numberscreenedbymethod'
                    },
                    chart: {
                        borderColor: '#2b87b8',
                        borderWidth: 1,
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: 'Number Screened by Method',
                        style: {
                            fontSize: '15'
                        },
                        margin: 60,
                        y: 30
                    },
                    tooltip: {
                        pointFormat: 'Percent: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}: {point.y}',
                                style: {
                                    fontWeight: 'normal'
                                }
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Number Screened',
                        type: 'pie',
                        data: [{
                            name: transform[0].screeningMethod,
                            y: transform[0].count
                        }, {
                            name: transform[1].screeningMethod,
                            y: transform[1].count
                        }, {
                            name: transform[2].screeningMethod,
                            y: transform[2].count
                        }]
                    }]
                });
            });
    }

    // getNoScreenedAccScreeningMethodReportLegacy() {
    //     this.dashboardService.getNoScreenedAccScreeningMethod(null).pipe(first())
    //         .subscribe(hearingScreening => {
    //             this.getTopFacilitiesInTotalNoOfLiveBirthsReport();
    //             let transform = [
    //                 {
    //                     screeningMethod: "AABR",
    //                     count: 0,
    //                     percent: ""
    //                 },
    //                 {
    //                     screeningMethod: "OAE",
    //                     count: 0,
    //                     percent: ""
    //                 },
    //                 {
    //                     screeningMethod: "Others",
    //                     count: 0,
    //                     percent: ""
    //                 },
    //                 {
    //                     screeningMethod: "TOTAL",
    //                     count: 0,
    //                     percent: ""
    //                 }
    //             ]
    //             for (var i = 0; i < hearingScreening["results"].length; i++) {
    //                 switch (hearingScreening["results"][i]["dataModel"]["methodOfScreening"]) {
    //                     case "AABR":
    //                         transform[0].count++;
    //                         transform[3].count++;
    //                         break;
    //                     case "OAE":
    //                         transform[1].count++;
    //                         transform[3].count++;
    //                         break;
    //                     case "Others":
    //                         transform[2].count++;
    //                         transform[3].count++;
    //                         break;
    //                 }
    //             }
    //             this.noScreenedAccScreeningMethodReportLoading = false;

    //             Highcharts.setOptions({
    //                 chart: {
    //                     style: {
    //                         fontFamily: 'Inter'
    //                     }
    //                 }
    //             });

    //             Highcharts.chart('getNoScreenedAccScreeningMethodReportContainer', {
    //                 exporting: {
    //                     filename: 'numberscreenedbymethod'
    //                 },
    //                 chart: {
    //                     borderColor: '#2b87b8',
    //                     borderWidth: 1,
    //                     plotBackgroundColor: null,
    //                     plotBorderWidth: null,
    //                     plotShadow: false,
    //                     type: 'pie'
    //                 },
    //                 title: {
    //                     text: 'Number Screened by Method',
    //                     style: {
    //                         fontSize: '15'
    //                     },
    //                     margin: 60,
    //                     y: 30
    //                 },
    //                 tooltip: {
    //                     pointFormat: 'Percent: <b>{point.percentage:.1f}%</b>'
    //                 },
    //                 accessibility: {
    //                     point: {
    //                         valueSuffix: '%'
    //                     }
    //                 },
    //                 plotOptions: {
    //                     pie: {
    //                         allowPointSelect: true,
    //                         cursor: 'pointer',
    //                         dataLabels: {
    //                             enabled: true,
    //                             format: '{point.name}: {point.y}',
    //                             style: {
    //                                 fontWeight: 'normal'
    //                             }
    //                         }
    //                     }
    //                 },
    //                 credits: {
    //                     enabled: false
    //                 },
    //                 series: [{
    //                     name: 'Number Screened',
    //                     type: 'pie',
    //                     data: [{
    //                         name: transform[0].screeningMethod,
    //                         y: transform[0].count
    //                     }, {
    //                         name: transform[1].screeningMethod,
    //                         y: transform[1].count
    //                     }, {
    //                         name: transform[2].screeningMethod,
    //                         y: transform[2].count
    //                     }]
    //                 }]
    //             });
    //         });
    // }

    getTotalLiveBirthsScreenedReport() {
        this.dashboardService.getTotalLiveBirthsScreenedOptimized([]).pipe(first())
            .subscribe(res => {
                // this.getTopFacilitiesInTotalNoOfLiveBirthsReport();
                this.getNoOfScreeningFacilitiesByRegionReport();
                let transform = [
                    {
                        totalLiveBirth: res[0],
                        noOfBabiesScreened: res[1],
                        screenedUsingPhilhealth: res[2],
                    }];

                this.patientsScreened = transform[0].noOfBabiesScreened;
                this.patientsScreenedLoading = false;
                this.liveBirthsScreened = transform[0].totalLiveBirth;
                this.liveBirthsScreenedLoading = false;
                this.totalLiveBirthsScreenedReportLoading = false;

                Highcharts.setOptions({
                    chart: {
                        style: {
                            fontFamily: 'Inter'
                        }
                    }
                });

                Highcharts.chart('getTotalLiveBirthsScreenedReportContainer', {
                    exporting: {
                        filename: 'livebirthsscreened'
                    },
                    chart: {
                        borderColor: '#2b87b8',
                        borderWidth: 1,
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: 'Live Births Screened',
                        style: {
                            fontSize: '15'
                        },
                        margin: 60,
                        y: 30
                    },
                    tooltip: {
                        pointFormat: 'Percent: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}: {point.y}',
                                style: {
                                    fontWeight: 'normal'
                                }
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Number Screened',
                        type: 'pie',
                        data: [{
                            name: 'Live Births',
                            y: transform[0].totalLiveBirth
                        }, {
                            name: 'Babies Screened',
                            y: transform[0].noOfBabiesScreened
                        }, {
                            name: 'Screened Using Philhealth',
                            y: transform[0].screenedUsingPhilhealth
                        }]
                    }]
                });
            });
    }

    getActiveUsersReport() {
        this.usersService.getAll().pipe(first())
            .subscribe(user => {
                this.getScreeningResultsReport();
                this.activeUsers = user.length;
                this.activeUsersLoading = false;
            });
    }

    export2Png() {
        (Highcharts as any).exportCharts([Highcharts.charts[0], Highcharts.charts[1], Highcharts.charts[2], Highcharts.charts[3], Highcharts.charts[4], Highcharts.charts[5]]);
    }

    export2Pdf() {
        (Highcharts as any).exportCharts([Highcharts.charts[0], Highcharts.charts[1], Highcharts.charts[2], Highcharts.charts[3], Highcharts.charts[4], Highcharts.charts[5]], {
            type: "application/pdf"
        });
    }

}
