<div class="position-absolute p-3">
    <div style="width: fit-content;">
        <div *ngIf="sealRequest$ | async as sealRequests">
            <div *ngIf="sealRequests.results.length > 0 else zeroRequest">
                <div *ngFor="let request of sealRequests.results">
                    <div class="card card-body d-flex align-items-center flex-row p-3 mb-3">
                        <div class="d-flex flex-column mr-3">
                            <small title="{{request.dataModel.referenceID}}">{{request.dataModel.referenceID | shortId | uppercase }}</small>
                            <small class="ml-auto"><b>{{request.dateCreated | date}}</b></small>
                        </div>
                        <h5 class="mr-2 mb-0"><b>{{request.dataModel.facility.facilityName}}</b></h5>
                        <span class="mr-3"> - Qty: {{request.dataModel.quantity}}</span>
                        <a class="btn btn-danger ml-auto" (click)="cancelRequest(request.modelId)">Cancel Order</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="card card-body d-flex align-items-center flex-row p-3 mb-3">
            <div class="d-flex flex-column mr-3">
                <small>A0000307096</small>
                <small class="ml-auto"><b>2/10/2021</b></small>
            </div>
            <h5 class="mr-2 mb-0"><b>Facility Name</b></h5>
            <span class="mr-3"> - Qty: 500</span>
            <button class="btn btn-danger ml-auto">Cancel Order</button>
        </div>
        <div class="card card-body d-flex align-items-center flex-row p-3 mb-3">
            <div class="d-flex flex-column mr-3">
                <small>A0000307096</small>
                <small class="ml-auto"><b>2/10/2021</b></small>
            </div>
            <h5 class="mr-2 mb-0"><b>Facility Name 2</b></h5>
            <span class="mr-3"> - Qty: 550</span>
            <button class="btn btn-danger ml-auto">Cancel Order</button>
        </div> -->
        <ng-template #zeroRequest>
            <div style="width: fit-content;" class="text-danger">
                No request found
            </div>
        </ng-template>
    </div>
</div>
