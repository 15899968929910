import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  subModuleLinks = [
    { title: 'Users', fragment: 'users' },
    { title: 'Facility Details', fragment: 'details' },
    { title: 'Machines Used', fragment: 'machines' },
    { title: 'NHSRC Seals', fragment: 'seal' }
  ];

  opened: boolean = true;
  constructor(public route: ActivatedRoute) { }

  ngOnInit(): void {
  }

}
