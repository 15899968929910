import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-age',
  templateUrl: './age-picker.component.html',
  styleUrls: ['./age-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AgePickerComponent),
    multi: true
  }]
})
export class AgePickerComponent implements ControlValueAccessor {
  
  valueYears: number;
  valueMonths: number;
  onChange: (e: any) => void;
  onTouched: () => void;
  disabled: boolean;

  constructor() {}

  writeValue(value: number): void {
    this.valueYears = Math.floor(value / 12);
    this.valueMonths = value % 12;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  
  update(valueYears, valueMonths) {
    var val = parseInt(valueYears.toString()) * 12 + parseInt(valueMonths.toString());
    this.writeValue(val);
    this.onChange(val);
  }
}
