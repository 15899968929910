import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PedsScreeningService } from '../peds-screening.service';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { debounceTime, first, map, startWith, switchMap } from 'rxjs/operators';
import { PrintService } from '@app/shared/print/print.service';
import { PatientService } from '@app/patient/patient.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, of } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({ template: '' })
export class AddEditPEDSScreeningComponent implements OnInit {
    
    constructor(public dialog: MatDialog,
      private location: Location,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute
  ) { }

  ngOnInit() {
      this.openDialog();
  }

  openDialog(): void {
      let id = this.route.snapshot.params['id']
      const dialogRef = this.dialog.open(AddEditPEDSScreeningComponentDialog, {
          data: {
              formBuilder: this.formBuilder,
              id: this.route.snapshot.params['id']
          }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.location.back();
      });
  }

}

@Component({ templateUrl: 'add-edit-peds-screening.component.html' })
export class AddEditPEDSScreeningComponentDialog implements OnInit {
    
    form: FormGroup;
    loading = false;
    submitted = false;

    editable: boolean;
    editing: boolean;
    printable: boolean;

    isAddMode: any;

    id: string;
    patientId: string;
    modelId: string;
    versionId: string;

    patient: any;
    dateOfScreening: any;

    separatorKeysCodesPDD: number[] = [ENTER, COMMA];
    pDD = new FormControl();
    filteredPDD: Observable<string[]>;
    pDDList: string[] = []
    allPDD: string[] = ['NTHC', 'NHSRC', 'PGH - Ear Unit'];

    pDDOptions$: Observable<string[]>;

    @ViewChild('pDDInput') pDDInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoPDD') matAutocompletePDD: MatAutocomplete;

    separatorKeysCodesComorbidity: number[] = [ENTER, COMMA];
    comorbidity = new FormControl();
    filteredComorbidity: Observable<string[]>;
    comorbidityList: string[] = []
    allComorbidity: string[] = ['NTHC', 'NHSRC', 'PGH - Ear Unit'];

    comorbidityOptions$: Observable<string[]>;

    @ViewChild('comorbidityInput') comorbidityInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoComorbidity') matAutocompleteComorbidity: MatAutocomplete;
    
    constructor(        
        public dialogRef: MatDialogRef<AddEditPEDSScreeningComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder,
            id: string
        },
        public dialog: MatDialog,
        private pedsScreeningService: PedsScreeningService, 
        private patientService: PatientService, 
        private formBuilder: FormBuilder, 
        private alertService: AlertService,
        public printService: PrintService,
        public ennhsrService: EnnhsrService
    ) { 
        this.id = data.id;
        /* this.filteredPDD = this.pDD.valueChanges.pipe(
            startWith(null),
            map((pDD: string | null) => pDD ? this._filter(pDD) : this.allPDD.slice())); */
        this.filteredComorbidity = this.comorbidity.valueChanges.pipe(
            startWith(null),
            map((comorbidity: string | null) => comorbidity ? this._filter(comorbidity) : this.allComorbidity.slice()));
    }

    ngOnInit() {
      this.patientId = this.patientService.recordSubject.getValue()["modelId"];

      this.isAddMode = !this.id;
  
      this.editable = this.id? true:false;
      this.editing = false;
      this.printable = true;
  
      this.form = this.formBuilder.group({
        patient: [''],
        dateOfScreening: ['', Validators.required],
        currentAge: [''],
        informant: ['', Validators.required],
        informantSpecify: [''],
        dPA: [''],
        dPB: [''],
        dPC: [''],
        dPD: [''],
        dPE: [''],
        aOFGC: [''],
        aOFGCReferTo: [''],
        aOFELA: [''],
        aOFELAReferTo: [''],
        aOFRL: [''],
        aOFRLReferTo: [''],
        aOFFM: [''],
        aOFFMReferTo: [''],
        aOFGM: [''],
        aOFGMReferTo: [''],
        aOFB: [''],
        aOFBReferTo: [''],
        aOFSE: [''],
        aOFSEReferTo: [''],
        aOFSH: [''],
        aOFSHReferTo: [''],
        aOFS: [''],
        aOFSReferTo: [''],
        aOFOH: [''],
        aOFOHReferTo: [''],
        referredToDevPed: [''],
        dateReferredToDevPed: [''],
        dateSeenByDevPed: [''],
        toolsUsed: [''],
        toolsUsedSpecify: [''],
        primaryDevelopmentalDiagnosis: ['', Validators.required],
        comorbidity: [''],
        referredForMentalHealthScreening: [''],
        screeningDate: ['']
      });
  
      this.patient = this.patientService.recordSubject.value["dataModel"];

      //this.dateOfScreening = this.form.controls['dateOfScreening'].value;

      this.form.patchValue({currentAge: Utils.computeAge(this.patient.dateOfBirth, Date.now())});

      if (!this.isAddMode) {
        var model = this.pedsScreeningService.recordsValue["results"].find(x => x.modelId == this.id);
        this.versionId = model["versionId"];
        this.modelId = model["modelId"];
        this.form.patchValue(Utils.mapModelToForm(model, ['dateOfScreening', 'dateReferredToDevPed', 'dateSeenByDevPed', 'screeningDate']));
        this.comorbidityList = model["dataModel"]["comorbidity"]
      }

      this.pDDOptions$ = this.form.controls['primaryDevelopmentalDiagnosis'].valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => {
          if (value !== '') {
            return this.searchPDD(value);
          }
          return of(null);
        })
      );

      this.comorbidityOptions$ = this.comorbidity.valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => {
          if (value !== '') {
            return this.searchComorbidity(value);
          }
          return of(null);
        })
      );
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() { 
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createPedsScreening();
        } else {
            this.updatePedsScreening();
        }
    }

    createPedsScreening() {
        this.form.get("patient").setValue(this.patientId)
        this.form.controls['comorbidity'].setValue(this.comorbidityList);
        this.pedsScreeningService.add(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('PEDS Screening added successfully', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      
    updatePedsScreening() {
        this.form.controls['comorbidity'].setValue(this.comorbidityList);
        this.pedsScreeningService.update(this.form.value, this.modelId, this.versionId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    selectedPDD(event: MatAutocompleteSelectedEvent): void {
        this.form.controls['primaryDevelopmentalDiagnosis'].setValue(event.option.viewValue);
    }

    searchPDD(name) {
        return this.ennhsrService.icd(name).pipe()
    }

    addComorbidity(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
    
        if ((value || '').trim()) {
          this.comorbidityList.push(value.trim());
        }
    
        if (input) {
          input.value = '';
        }
    
        this.comorbidity.setValue(null);
    }

    removeComorbidity(comorbidity: string): void {
        const index = this.comorbidityList.indexOf(comorbidity);
    
        if (index >= 0) {
          this.comorbidityList.splice(index, 1);
        }
    }

    selectedComorbidity(event: MatAutocompleteSelectedEvent): void {
        this.comorbidityList.push(event.option.viewValue);
        this.comorbidityInput.nativeElement.value = '';
        this.comorbidity.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.allComorbidity.filter(comorbidity => comorbidity.toLowerCase().indexOf(filterValue) === 0);
      }

    searchComorbidity(name) {
        return this.ennhsrService.icd(name).pipe()
    }
}
