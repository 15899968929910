import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, first, map, startWith, switchMap } from 'rxjs/operators';
import { AlertService } from '@app/shared/alert/alert.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { FacilitiesService } from '../facilities.service';
import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';

@Component({ template: '' })
export class AddEditComponent implements OnInit {
            
    constructor(public dialog: MatDialog,
        private location: Location,
        private formBuilder: FormBuilder,
        private facilitiesService: FacilitiesService,
        private alertService: AlertService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.openDialog();
    }

    openDialog(): void {
        let id = this.route.snapshot.params['id']
        const dialogRef = this.dialog.open(AddEditComponentDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: id,
                isAddMode: !id,
                alertService: this.alertService,
                facilitiesService: this.facilitiesService,
                userService: this.userService,
                route: this.route,
                router: this.router,
                matDialog: this.matDialog
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.location.back();
        });
    }
}

@Component({ templateUrl: 'add-edit.component.html' })
export class AddEditComponentForm implements OnInit {
    form: FormGroup;
    id: string;
    isAddMode: boolean;
    isFacilityDetails: boolean;
    loading = false;
    submitted = false;
    isModal: boolean;
    private versionId: string;
    private modelId: string;
    
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    facility = new FormControl();
    filteredFacilities: Observable<string[]>;
    facilities: string[] = []
    facilityPartners: string[] = []
    allFacilities: string[] = ['NTHC', 'NHSRC', 'PGH - Ear Unit'];

    //facilityControl = new FormControl();
    facilityOptions$: Observable<string[]>;

    @ViewChild('facilityInput') facilityInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private facilitiesService: FacilitiesService,
        private userService: UserService,
        private matDialog: MatDialog
        
    ) {
        this.isModal = false;
        this.filteredFacilities = this.facility.valueChanges.pipe(
            startWith(null),
            map((facility: string | null) => facility ? this._filter(facility) : this.allFacilities.slice()));
    }

    ngOnInit() {  
        this.id = this.route.snapshot.params['id'];
        this.isFacilityDetails = this.route.snapshot.url[0].path === "details" ? true : false;
        this.isAddMode = !this.id;
        // password not required in edit mode
        const passwordValidators = [Validators.minLength(6)];
        if (this.isAddMode) {
            passwordValidators.push(Validators.required);
        }

        this.form = this.formBuilder.group({
            facilityName: ['', Validators.required],
            nshrcFacilityAccreditationNumber: ['', Validators.required],
            philhealthFacilityAccreditationNumber: [''],
            dohHealthFacilityId: [''],
            streetAddress: [''],
            psgcCode: ['', Validators.required],
            active: [''],
            category: ['', Validators.required],
            sealDistributionControlNumber: [''],
            facilityPartnerName: [''],
            startRange: [''],
            endRange: [''],
            machine: [''],
            facilityPartners: ['']
        });
        if (!this.isAddMode) {
            var facility = this.facilitiesService.recordsValue["results"].find(x => x.modelId == this.id);
            this.versionId = facility["versionId"];
            this.modelId = facility["modelId"];
            facility["dataModel"]["facilityPartners"]?.forEach(element => {
                this.facilities.push(element["facilityName"]);
                this.facilityPartners.push(element["_modelId"]);
            });
            this.form.patchValue(Utils.mapModelToForm(facility));
        } else {
            if(this.isFacilityDetails) {
                this.facilities = [] ;
                this.facilityPartners = [];
                //var facilityName = this.userService.userValue["facility"]["facility"]
                //this.facilitiesService.getByFacilityName(facilityName).subscribe();
                this.facilitiesService.getById(this.userService.userValue["facility"]["facilityModelId"]).subscribe(res => {
                    if(res){
                        this.versionId = res["versionId"];
                        this.modelId = res["modelId"];
                        res["dataModel"]["facilityPartners"]?.forEach(element => {
                            this.facilities.push(element["facilityName"]);
                            this.facilityPartners.push(element["_modelId"]);
                        });
                        this.form.patchValue(Utils.mapModelToForm(res));
                    }
                });
                /* this.facilitiesService.recordObservable.subscribe(res => {
                    if(res){
                        this.versionId = res["versionId"];
                        this.modelId = res["modelId"];
                        res["dataModel"]["facilityPartners"]?.forEach(element => {
                            this.facilities.push(element["facilityName"]);
                            this.facilityPartners.push(element["_modelId"]);
                        });
                        this.form.patchValue(Utils.mapModelToForm(res));
                    }
                }) */
            }
        }

        this.facilityOptions$ = this.facility.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            switchMap(value => {
              if (value !== '') {
                return this.search(value);
              }
              return of(null);
            })
          );
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode && !this.isFacilityDetails) {
            this.createFacility();
        } else {
            this.updateFacility();
        }
    }

    private createFacility() {
        this.form.controls['facilityPartners'].setValue(this.facilityPartners);
        this.facilitiesService.add(this.form.value)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Facility added successfully', { keepAfterRouteChange: true });
                this.matDialog.closeAll();
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
    }

    private updateFacility() {
        this.form.controls['facilityPartners'].setValue(this.facilityPartners);
        var updateOp = this.isFacilityDetails ? this.facilitiesService.updateDetails(this.form.value, this.modelId, this.versionId) : this.facilitiesService.update(this.form.value, this.modelId, this.versionId)
        updateOp
        .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.loading = false;
                    this.matDialog.closeAll();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    clearFacilityInput(): void {
        this.facility.setValue(null);
    }
     
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
    
        // Add our facility
        if ((value || '').trim()) {
          this.facilities.push(value.trim());
        }
    
        // Reset the input value
        if (input) {
          input.value = '';
        }
    
        this.facility.setValue(null);
      }
    
      remove(facility: string): void {
        const index = this.facilities.indexOf(facility);
    
        if (index >= 0) {
          this.facilities.splice(index, 1);
          this.facilityPartners.splice(index, 1);
        }

      }
    
      selected(event: MatAutocompleteSelectedEvent): void {
        this.facilityPartners.push(event.option.value);
        this.facilities.push(event.option.viewValue);
        this.facilityInput.nativeElement.value = '';
        this.clearFacilityInput();
      }
    
      private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.allFacilities.filter(facility => facility.toLowerCase().indexOf(filterValue) === 0);
      }

      search(name): Observable<string[]> {
          if(name){
            return this.facilitiesService.search(name).pipe(
                map(facilities => facilities["results"].map(x => ({id: x.modelId, name: x.dataModel.facilityName}))))
          }
          else
            return of(null)
      }
}

@Component({ templateUrl: 'add-edit.component.html' })
export class AddEditComponentDialog extends AddEditComponentForm {

    constructor(
        public dialogRef: MatDialogRef<AddEditComponentForm>,
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder, 
            id: string, 
            isAddMode: boolean,
            facilitiesService: FacilitiesService,
            alertService: AlertService,
            userService: UserService,
            route: ActivatedRoute,
            router: Router,
            matDialog: MatDialog,
        }
    ) {
        super(data.formBuilder, data.route, data.router, data.alertService, data.facilitiesService, data.userService, data.matDialog);
        this.isModal = true;
    }   
}