import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { InterventionsService } from './interventions.service';

@Component({
  selector: 'app-surgical-hearing-interventions',
  templateUrl: './surgical-hearing-interventions.component.html',
  styleUrls: ['./surgical-hearing-interventions.component.scss']
})
export class SurgicalHearingInterventionsComponent implements OnInit {
  
  columns = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date Of Implantation', prop: 'dateOfImplantation'},
    {name: 'Hospital Name', prop: 'hospitalName'},
    {name: 'Hospital Address', prop: 'fullAddress'},
    {name: 'Surgeon&#x27s Name', prop: 'surgeonsName'},
    {name: 'Clinical Audiologist&#x27s Name', prop: 'clinicalAudiologistsName'},
    {name: 'Laterality', prop: 'laterality'},
    {name: 'Type', prop: 'type'},
    {name: 'Model', prop: 'model'},
    {name: 'Serial Number', prop: 'serialNumber'},
    {name: 'Diagnosis', prop: 'diagnosis'},
    {name: 'First Case Rate', prop: 'firstCaseRate'},
    {name: 'Procedure', prop: 'procedureFirst'},
    {name: 'Second Case Rate', prop: 'secondCaseRate'},
    {name: 'Procedure', prop: 'procedureSecond'},
    {name: 'With Complication', prop: 'withComplication'},
    {name: 'Timing', prop: 'timing'},
    {name: 'Description', prop: 'description'},
    {name: 'Surgeon&#x27s Notes', prop: 'surgeonsNotes'}
  ]

  dateControlNames = ['dateOfImplantation'];
  interventions$ : Observable<any>;
  interventionsPage$ : Observable<any>;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;

  id: string;

  constructor(
    private interventionsService: InterventionsService,
    private route: ActivatedRoute,
    private userService: UserService,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.editable = true;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )

    this.interventionsService.getAll(this.id, 0).subscribe();

    this.interventions$ = this.interventionsService.recordsObservable.pipe(
      map(intervention => intervention["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.interventionsPage$ = this.interventionsService.recordsObservable
  }

  getPageInterventions(page: string) {
    this.interventionsService.getAll(this.id, page).subscribe();
  }

  delete(id: string) { }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }
  
}
