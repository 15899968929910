import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from '@app/shared/alert/alert.service';
import { UserService } from '@app/user/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  hide = true;
  hide2 = true;

  formBuilder: FormBuilder

  constructor( public dialogRef: MatDialogRef<SetPasswordComponent>,        
    private alertService: AlertService,  
    private userService: UserService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: {
        formBuilder: FormBuilder
    }) { 
      this.formBuilder = data.formBuilder;
    }

  ngOnInit(): void {
    this.form = this.data.formBuilder.group({
      password: ['', Validators.required],
      password2: ['', Validators.required]      
    }, { validators: groupValidator });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    var user = this.userService.userValue
    user.password = this.form.get('password').value;
    user['hasTemporaryPassword'] = false;
    var facility = user['facility'];
    delete user['facility'];
    this.userService.update(user.id,user).pipe(first())
    .subscribe({
        next: () => {
            this.alertService.success('Update successful', { keepAfterRouteChange: true });
            user['facility'] = facility;
            localStorage.setItem('user', JSON.stringify(user));
            this.userService.userSubject.next(user);
            this.router.navigate(['/']); 
            this.dialogRef.close();

        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;
        }
    });;
  }

  cancel() {
    this.userService.logout();
    this.dialogRef.close();
  }

}

export const groupValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password').value;
  const password2 = control.get('password2').value;
  return password !== password2 ? { matching: true } : null;
}
