import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { AuthenticateDirective } from './authenticate.directive';
import { environment } from '@environments/environment';
import { SelectComponent } from '../profile/select/select.component';
import { LoginComponent } from '../account/login/login.component';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit {
  @ViewChild(AuthenticateDirective, {static: true}) authenticateHost: AuthenticateDirective;
  isDesktop: boolean;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.isDesktop = environment.isDesktop;
  }

  ngOnInit(): void {
    if(environment.isDesktop) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectComponent);

      const viewContainerRef = this.authenticateHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent<SelectComponent>(componentFactory);
    } else {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoginComponent);

      const viewContainerRef = this.authenticateHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent<LoginComponent>(componentFactory);
    }
  }

}
