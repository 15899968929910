<div class="container ml-0 pt-3">
    <div class="card">
        <div class="card-header">
            {{ isAddMode ? 'Submit ' : 'View '}}Ticket
        </div>
        <div class="card-body"> 
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <div class="form-row" *ngIf="!isAddMode">
                        <div class="form-group col-4">
                            <label for="priority">Priority</label>
                            <select class="form-control" formControlName="priority">
                                <option disabled>select priority</option>
                                <option>Critical</option>
                                <option>High</option>
                                <option>Normal</option>
                                <option>Low</option>
                            </select>
                        </div>
                        <div class="form-group col-4">
                            <label for="status">Status</label>
                            <select class="form-control" formControlName="status">
                                <option disabled>select status</option>
                                <option>Open</option>
                                <option>Closed</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="!isAddMode">
                        <div class="form-group col-4">
                            <label for="from">From</label>
                            <input type="text" formControlName="from" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" disabled />
                        </div>
                        <div class="form-group col-4">
                            <label for="facility">Facility</label>
                            <input type="text" formControlName="facility" class="form-control" disabled />
                        </div>
                        <div class="form-group col-4">
                            <label for="role">Role</label>
                            <input type="text" formControlName="role" class="form-control" disabled />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="email">Email Address</label>
                            <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">An Email address is required</div>
                                <div *ngIf="f.email.errors.email">Email is an invalid format</div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="contact">Contact Number</label>
                            <input type="text" formControlName="contact" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="topic">Topic</label>                             
                            <input type="text" formControlName="topic" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.topic.errors }" disabled/>
                            <div *ngIf="submitted && f.topic.errors" class="invalid-feedback">
                                <div *ngIf="f.topic.errors.required">A Topic is required</div>
                            </div>
                            <br *ngIf="isAddMode">                   
                            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" *ngIf="isAddMode">
                                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                                    <a (click)="setTopic(node.value)">{{node.name}}</a>
                                </mat-tree-node>
                                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                                    <button matTreeNodeToggle type="button" class="btn btn-link">
                                        <i class="mdi mdi-chevron-down" *ngIf="treeControl.isExpanded(node)"></i>
                                        <i class="mdi mdi-chevron-right" *ngIf="!treeControl.isExpanded(node)"></i>
                                    </button>
                                    <a (click)="setTopic(node.value)">{{node.name}}</a>
                                </mat-tree-node>
                            </mat-tree>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="description">Description</label>
                            <textarea rows="4" formControlName="description" class="form-control" placeholder="Please provide additional information i.e. steps performed, expected outcome, and actual outcome prompting to submit a report."></textarea>
                        </div>
                    </div> 
                    <div class="form-row" *ngIf="!isAddMode">
                        <div class="form-group col-12">
                            <label for="notes">Notes</label>
                            <textarea rows="4" formControlName="notes" class="form-control"></textarea>
                        </div>
                    </div> 
                    <div class="form-row" *ngIf="!isAddMode">
                        <label for="notes">Diagnostics</label>
                        <div class="form-group col-12">
                            <label for="url">URL</label>
                            <input type="text" formControlName="url" class="form-control" disabled />
                        </div>
                    </div> 
                    <div class="form-row" *ngIf="!isAddMode">
                        <div class="form-group col-12">
                            <label for="logs">Logs</label>
                            <textarea rows="4" formControlName="logs" class="form-control" disabled></textarea>
                        </div>
                    </div> 
                </div>
                <div class="form-group mt-3">
                    <div class="d-flex justify-content-center">
                        <div class="col-md-10">
                            <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                {{isAddMode ? 'Submit': 'Save'}}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>