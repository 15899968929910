<div class="print-header row m-0 w-100 p-3" *ngIf="printWithHeader">
  <div class="col-2 p-0">
    <img src="assets/img/NHSRC-logo-HD.png" style="height: 36px;">
  </div>
  <div class="col-8 p-0 d-flex align-items-end justify-content-center">
    <h4 class="mb-0"><b>{{title}}</b></h4>
  </div>
  <div class="col-2 p-0 d-flex align-items-end justify-content-end">
    <b style="pointer-events: none;"><i class="mdi mdi-ear-hearing"></i>ENNHSR</b>
  </div>
</div>
