import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class AreasOfConcernReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Areas of Concern'
    })
    areasOfConcern: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Count'
    })
    count: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Percent'
    })
    percent: string;
}
