import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AuthGuard } from './user/authenticate/auth.guard';
import { AdminGuard } from './admin/admin.guard';
import { RegisterComponent } from './user/account/register/register.component';
import { AuthenticateComponent } from './user/authenticate/authenticate.component';
import { ForgotPasswordComponent } from './user/account/forgot-password/forgot-password.component';
import { PrivacyComponent } from './user/authenticate/privacy/privacy.component';
// import { AddEditComponent } from './admin/users/add-edit/add-edit.component';
import { SyncGuard } from './sync/sync.guard';
import { DashboardGuard } from './dashboard/dashboard.guard';
import { UnitGuard } from './unit/unit.guard';
import { PatientGuard } from './patient/patient.guard';
import { PatientSearch } from './patient/patient-search/patient-search.component';
import { CreateProfileComponent } from './user/profile/create-profile/create-profile.component';
import { DownloadLiteComponent } from './home/download-lite/download-lite.component';
import { AddEditComponent } from '@app/admin/feedback/add-edit/add-edit.component';

const patientModule = () => import('./patient/patient.module').then(x => x.PatientModule);
const unitModule = () => import('./unit/unit.module').then(x => x.UnitModule);
const dashboardModule = () => import('./dashboard/dashboard.module').then(x => x.DashboardModule);
const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const syncModule = () => import('./sync/sync.module').then(x => x.SyncModule);

const userModule = () => import('./user/user.module').then(x => x.UserModule);

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard]},
   
    { path: 'login', component: AuthenticateComponent},
    { path: 'register', component: RegisterComponent},
    { path: 'create_profile', component: CreateProfileComponent, canActivate: [SyncGuard]},
    { path: 'forgot_password', component: ForgotPasswordComponent},
    { path: 'privacy', component: PrivacyComponent},
    { path: 'validate_pn', component: PatientSearch},
    { path: 'validate_pn/:pn', component: PatientSearch},
    { path: 'download_lite', component: DownloadLiteComponent, canActivate: [UnitGuard]},

    
    { path: 'user', loadChildren: userModule, canActivate: [AuthGuard]},
    
    { path: 'patient', loadChildren: patientModule, canActivate: [AuthGuard, PatientGuard]},
    { path: 'facility', loadChildren: unitModule, canActivate: [AuthGuard, UnitGuard]},
    { path: 'dashboard', loadChildren: dashboardModule, canActivate: [AuthGuard, DashboardGuard]},
    { path: 'admin', loadChildren: adminModule, canActivate: [AuthGuard, AdminGuard]},
    { path: 'sync', loadChildren: syncModule, canActivate: [AuthGuard, SyncGuard]},

    // { path: '', redirectTo: 'patient', pathMatch: 'prefix'},
    // { path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }