<div class="card my-3">
    <div class="card-header d-flex justify-content-between">
        <span>Interventions</span>
        <div *ngIf="validatePrivilege()">
            <!-- <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                             -->
            <button type="button" *ngIf="this.editable && !this.editing && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
            <button type="button" *ngIf="this.editable && this.editing" (click)="this.editing = false;" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
        </div>
    </div>
    <div class="card-body">    
        <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
            <app-table [isWriteMode]="this.editing" [data]="interventions$ | async" [pageData]="interventionsPage$ | async" [printTitle]="'Surgical Hearing Interventions'" [columns]="columns" [addLink]="'surgical-hearing-interventions/add-intervention'" [editLink]="'surgical-hearing-interventions/edit-intervention'" (pageEvent)="getPageInterventions($event)" (deleteRowEvent)="delete($event)"></app-table>
        </fieldset>
    </div>
</div>