import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class ScreeningResultsReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Result'
    })
    result: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Initial'
    })
    initial: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Initial %'
    })
    initialPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Rescreen'
    })
    rescreen: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Rescreen %'
    })
    rescreenPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Total'
    })
    total: string;
}

