<div class="card">
    <div class="card-header">
        <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <button
                    class="btn btn-primary"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                    title="Previous"
                >
                    <i class="mdi mdi-calendar-arrow-left"></i><small class="d-none d-md-inline"> Previous</small>
                </button>
            </div>
            <div>
                <div class="d-flex flex-column flex-md-row align-items-center justify-content-center p-2">
                    <button
                    class="btn btn-link px-1 py-0"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                    title="Today"
                    >
                        <i class="mdi mdi-calendar-blank"></i>
                    </button>
                    <div>
                        <h5 class="m-0"><b>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</b></h5>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center">
                    <div class="btn-group">
                        <button
                            class="btn btn-primary py-0"
                            (click)="setView(CalendarView.Month)"
                            [class.active]="view === CalendarView.Month"
                        >
                            <i class="mdi mdi-calendar-month"></i><small class="d-none d-md-inline"> Month</small>
                        </button>
                        <button
                            class="btn btn-primary py-0"
                            (click)="setView(CalendarView.Week)"
                            [class.active]="view === CalendarView.Week"
                        >
                            <i class="mdi mdi-calendar-week"></i><small class="d-none d-md-inline"> Week</small>
                        </button>
                        <button
                            class="btn btn-primary py-0"
                            (click)="setView(CalendarView.Day)"
                            [class.active]="view === CalendarView.Day"
                        >
                            <i class="mdi mdi-calendar-today"></i><small class="d-none d-md-inline"> Day</small>
                        </button>
                    </div>
                </div>    
            </div>
            <div class="d-flex align-items-center">
                <button
                    class="btn btn-primary"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                    title="Next"
                >
                    <small class="d-none d-md-inline">Next </small><i class="mdi mdi-calendar-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
    <div [ngSwitch]="view" class="card-body">
        <mwl-calendar-month-view
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
            *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
            *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view>
    </div>
    
    <!-- Everything you see below is just for the demo, you don't need to include it in your app -->
    
    <!-- <br /><br /><br />
    
    <h3>
        Edit events
        <button class="btn btn-primary float-right" (click)="addEvent()">
            Add new
        </button>
        <div class="clearfix"></div>
    </h3>
    
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Primary color</th>
                    <th>Secondary color</th>
                    <th>Starts at</th>
                    <th>Ends at</th>
                    <th>Remove</th>
                </tr>
            </thead>
    
            <tbody>
                <tr *ngFor="let event of events">
                    <td>
                        <input
                            type="text"
                            class="form-control"
                            [(ngModel)]="event.title"
                            (keyup)="refresh.next()"
                        />
                    </td>
                    <td>
                        <input
                            type="color"
                            [(ngModel)]="event.color.primary"
                            (change)="refresh.next()"
                        />
                    </td>
                    <td>
                        <input
                            type="color"
                            [(ngModel)]="event.color.secondary"
                            (change)="refresh.next()"
                        />
                    </td>
                    <td>
                        <input
                            class="form-control"
                            type="text"
                            mwlFlatpickr
                            [(ngModel)]="event.start"
                            (ngModelChange)="refresh.next()"
                            [altInput]="true"
                            [convertModelValue]="true"
                            [enableTime]="true"
                            dateFormat="Y-m-dTH:i"
                            altFormat="F j, Y H:i"
                            placeholder="Not set"
                        />
                    </td>
                    <td>
                        <input
                            class="form-control"
                            type="text"
                            mwlFlatpickr
                            [(ngModel)]="event.end"
                            (ngModelChange)="refresh.next()"
                            [altInput]="true"
                            [convertModelValue]="true"
                            [enableTime]="true"
                            dateFormat="Y-m-dTH:i"
                            altFormat="F j, Y H:i"
                            placeholder="Not set"
                        />
                    </td>
                    <td>
                        <button class="btn btn-danger" (click)="deleteEvent(event)">
                            Delete
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div> -->
    
    <ng-template #modalContent let-close="close">
        <div class="modal-header">
            <h5 class="modal-title">Event action occurred</h5>
            <button type="button" class="close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                Action:
                <pre>{{ modalData?.action }}</pre>
            </div>
            <div>
                Event:
                <pre>{{ modalData?.event | json }}</pre>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="close()">
                OK
            </button>
        </div>
    </ng-template>
</div>