import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/shared/alert/alert.service';
import { PatientService } from '../patient.service';
import { Location } from '@angular/common';
import { FacilitiesService } from '@app/admin/facilities/facilities.service';
import { StatusService } from '@app/patient/forms/hearing-screening/status.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss']
})
export class PatientSearch implements OnInit {

  form: FormGroup;
  isShown: boolean;
  isHidden: boolean;
  facilAdd: any;

  screening$: Observable<any>;
  sealNo: number;

  constructor(
    private patientService: PatientService,
    private facilitiesService: FacilitiesService,
    private statusService: StatusService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isShown = false;
    this.isHidden = true;
    
    this.form = this.formBuilder.group({
      patientNumber: [this.route.snapshot.params['pn'], Validators.required],
      sealNumber: [''],
      facilityOfBirth: [''],
      currFacility: [''],
      facilityAddress: ['']
    })

    if(this.route.snapshot.params['pn']){
      this.searchPatient();
    }
  }

  onSubmit() {
    this.searchPatient()
  }

  searchPatient() {
    this.patientService.getByPatientNumber(this.form.get('patientNumber').value)
    .subscribe(res => {
      if(res){
        this.alertService.success('Patient Number is valid')
        this.toggleHide()
        this.toggleShow()
        this.form.get('facilityOfBirth').setValue(this.patientService.recordSubject.getValue()["dataModel"]["facilityOfBirth"])
        this.form.get('currFacility').setValue(this.patientService.recordSubject.getValue()["dataModel"]["facilityCode"])
        var sealNumber = this.statusService.getByPatient(this.patientService.recordSubject.getValue()["modelId"]).subscribe(res => {
          return this.form.get('sealNumber').setValue(res["dataModel"]["serialNumber"]);
        });
        this.searchFacilityAddress()
      } else {
        this.alertService.error('Patient Number is not valid');
      }
    });
  }

  searchFacilityAddress() {
    var facility = this.patientService.recordSubject.getValue()["dataModel"]["facilityCode"]
    facility = facility.substring(0, facility.indexOf('('));
    this.facilitiesService.getByFacilityName(facility.trim()).subscribe(res => {
      if(res) {
        if(JSON.parse(localStorage.getItem('facilityRecords'))["dataModel"]["streetAddress"] !== null && JSON.parse(localStorage.getItem('facilityRecords'))["dataModel"]["streetAddress"] !== "") {
          this.form.get('facilityAddress').setValue(JSON.parse(localStorage.getItem('facilityRecords'))["dataModel"]["streetAddress"])
        } else {
          this.form.get('facilityAddress').setValue("No Address Available")
        }

      }
    });
  }

  toggleShow() {
    this.isShown = ! this.isShown;
  }

  toggleHide() {
    this.isHidden = ! this.isHidden;
  }

  back() {
    this.location.back();
  }

}
