import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdMappingService {
  private modelName = "idMapping";
  public idMapping: Observable<Sync>;
  public idMappingSubject: BehaviorSubject<any>;
  
  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService,
  ) {
    this.idMappingSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(this.modelName+'Records')));
    this.idMapping = this.idMappingSubject.asObservable();
  }

  public get recordValue() {
    return this.idMappingSubject.value;
  }

  public get recordObservable() {
    return this.idMapping;
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}`);
  }

  getAll(){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}`)
    .pipe(
      map(res => {
        this.idMappingSubject.next(res);
        return res;
      })
    );
  }

  getByLocalId(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?localId=${id}&~sort=$dateCreated:-1`).pipe(map(res => res['results'][0]));
  }

  getByOriginId(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?originId=${id}&~sort=$dateCreated:-1`).pipe(map(res => res['results'][0]));
  }

  add(){
  }
}
