import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component'
//import { AnnouncementsComponent } from './announcements/announcements.component';

const usersModule = () => import('./users/users.module').then(x => x.UsersModule);
const facilitiesModule = () => import('./facilities/facilities.module').then(x => x.FacilitiesModule);
const conceptsModule = () => import('./concepts/concepts.module').then(x => x.ConceptsModule);
const announcementsModule = () => import('./announcements/announcements.module').then(x => x.AnnouncementsModule);
const importModule = () => import('./import/import.module').then(x => x.ImportModule);
const sealModule = () => import('./seal/seal.module').then(x => x.SealModule);
const feedbackModule = () => import('./feedback/feedback.module').then(x => x.FeedbackModule);

const routes: Routes = [
  { path: '' , component: LayoutComponent,
    children: [
      { path: 'users', loadChildren: usersModule },
      { path: 'facilities', loadChildren: facilitiesModule },
      { path: 'concepts', loadChildren: conceptsModule },
      { path: 'seal', loadChildren: sealModule },
      { path: 'announcements', loadChildren: announcementsModule },
      //{ path: 'announcements', component: AnnouncementsComponent ,},
      { path: 'import', loadChildren: importModule },
      { path: 'feedback', loadChildren: feedbackModule }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
