import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class AgeOfPatientsWhoUnderwentCochlearImplantReport{
    @autoserializeAs(String)
    @Column({
        columnName: 'Hearing Loss'
    })
    hearingLoss: string;
    @autoserializeAs(String)
    @Column({
        columnName: '0 - 3 y/o'
    })
    zeroToThree: string;
    @autoserializeAs(String)
    @Column({
        columnName: '0 - 3 %'
    })
    zeroToThreePercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: '4 - 7 y/o'
    })
    fourToSeven: string;
    @autoserializeAs(String)
    @Column({
        columnName: '4 - 7 %'
    })
    fourToSevenPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: '8 above'
    })
    eightAndAbove: string;
    @autoserializeAs(String)
    @Column({
        columnName: '8 above %'
    })
    eightAndAbovePercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Total'
    })
    total: string;
}
