import { ComponentFactoryResolver, Directive, ViewContainerRef } from '@angular/core';
import Utils from '../utils';
import { PrintComponent } from './print.component';
import { PrintService } from './print.service';

@Directive({
  selector: '[appPrint]'
})
export class PrintDirective {

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private printService: PrintService
  ) {
    this.printService.onPrint().subscribe(res => {
      this.print(res.body, res.title)
    })
  }

  print(body: any[], title?: string) {
    if(title) {
      let component = this.viewContainerRef.createComponent<PrintComponent>(this.componentFactoryResolver.resolveComponentFactory(PrintComponent));
      component.instance.title = title;
      component.changeDetectorRef.detectChanges();
      document.getElementsByClassName('print-header').item(0).parentElement.classList.add('print-parent');
    }
    var top = title ? 68 : 0;
    body.forEach((e, i) => {    
      if(Array.isArray(e)){
        var bodyEl = document.createElement("div")
        bodyEl.classList.add('print-body');
        bodyEl.classList.add('print-table-body');
        bodyEl.classList.add('d-flex');
        bodyEl.classList.add('flex-column');        
        bodyEl.style.top = top + 2 + 'px';        
        document.getElementsByClassName('print-header').item(0).parentElement.after(bodyEl);
        
        e[1].forEach((f,j) => {
          var num = document.createElement("div");
          num.classList.add('d-flex');
          num.classList.add('flex-column');
          num.classList.add('border-dark');
          num.classList.add('border');
          num.classList.add('p-4');
          num.classList.add('mt-4');
          num.innerHTML = '<b>'+(j+1)+'</b>';
          bodyEl.appendChild(num)
          e[0].forEach(g => {
            var col = document.createElement("span");
            col.classList.add('ml-4')
            var val = f[g.prop != undefined ? g.prop : Utils.camelize(g.name)];
            if(typeof val == 'object'){
              var date = new Date(val);
              val = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
            }
            col.innerHTML = g.name +': <b>' + val + '</b>';
            num.appendChild(col)
          });
        })
        bodyEl.style.height = 'fit-content';

        var currEl : HTMLElement = bodyEl.parentElement;
        while(currEl.tagName !== "BODY") {
          currEl.classList.add("print-parent");
          currEl = currEl.parentElement;
        } 
        top += bodyEl.getBoundingClientRect().height;
      } else {
        var el : HTMLElement = typeof e === 'string' ? document.getElementById(e) : e;
        el.classList.add('print-body');
        el.style.top = top + 'px';
        var currEl : HTMLElement = el.parentElement;
        while(currEl.tagName !== "BODY") {
          currEl.classList.add("print-parent");
          currEl = currEl.parentElement;
        } 
        top += el.id === "details" ? 80 : el.getBoundingClientRect().height;
      }
    })
    window.print();
    if(document.getElementsByClassName('print-table-body').item(0))
      document.getElementsByClassName('print-table-body').item(0).remove()
    body.forEach((e, i) => {
      if(!Array.isArray(e)){
        var el = typeof e === 'string' ? document.getElementById(e) : e;
        el.classList.remove('print-body');
        el.style.top = '';
        var currEl : HTMLElement = el.parentElement;
        while(currEl.tagName !== "BODY") {
          currEl.classList.remove("print-parent");
          currEl = currEl.parentElement;
        } 
      }
    })
    if(title) {
      document.getElementsByClassName('print-header').item(0).parentElement.classList.remove('print-parent');
    }
    this.viewContainerRef.clear();
  }
}
