import { Component, OnDestroy, OnInit } from '@angular/core';
import Utils from '@app/shared/utils';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { HearingAidDevicesService } from './hearing-aid-devices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/shared/alert/alert.service';
import { FirstFittingService } from './first-fitting.service';
import { UserService } from '@app/user/user.service';
import { PatientService } from '@app/patient/patient.service';

@Component({
  selector: 'app-hearing-aid-devices',
  templateUrl: './hearing-aid-devices.component.html',
  styleUrls: ['./hearing-aid-devices.component.scss']
})
export class HearingAidDevicesComponent implements OnInit {
  
  //duplicate this if multiple tables
  columns = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of Current Fitting', prop:'dateOfFitting'},
    {name: 'Not Done', prop:'notDone'},
    {name: 'Reason', prop:'notDoneReason'},
    {name: 'Indicate Ear', prop:'indicateEar'},
    {name: 'Hearing Aid Trial', prop:'hearingAidTrial'},
    {name: 'Ear Amplified', prop:'earAmplified'},
    {name: 'Type of Hearing Aid', prop:'typeOfHearingAid'},
    {name: 'Specific Type of Hearing Aid', prop:'typeOfHearingAidSpecify'},
    {name: 'Type of Amplification', prop:'typeOfAmplification'},
    {name: 'Specific Type of Amplification', prop:'typeOfAmplificationSpecify'},
    {name: 'Brand', prop:'brand'},
    {name: 'Model', prop:'model'},
    {name: 'Serial Number', prop:'serialNumber'},
    {name: 'Bands', prop:'bands'},
    {name: 'Channels', prop:'channels'},
    {name: 'Warranty', prop:'warranty'},
    {name: 'Battery Size', prop:'batterySize'},
    {name: 'File', prop:'uploadFile'},
    {name: 'Notes', prop:'notes'}
  ]

  columns2 = [
    {name: 'Hearing Aid Changed', prop:'hearingAidChanged'},
    {name: 'Date of Testing', prop:'dateOfTesting'},
    {name: 'Type of Aided Test', prop:'typeOfAidedTest'},
    {name: 'Specific Type of Aided Test', prop:'typeOfAidedTestSpecify'},
    {name: 'Testing Conditions', prop:'testingConditions'},

    {name: 'Left Ear Done', prop:'leftEar'},

    {name: '250Hz', prop:'leftEar250'},
    {name: '- Speech Banana', prop:'leftEar250SpeechBanana'},
    {name: '- Speech String Bean', prop:'leftEar250SpeechStringBean'},

    {name: '500Hz', prop:'leftEar500'},
    {name: '- Speech Banana', prop:'leftEar500SpeechBanana'},
    {name: '- Speech String Bean', prop:'leftEar500SpeechStringBean'},
    
    {name: '1kHz', prop:'leftEar1000'},
    {name: '- Speech Banana', prop:'leftEar1000SpeechBanana'},
    {name: '- Speech String Bean', prop:'leftEar1000SpeechStringBean'},

    {name: '1.5kHz', prop:'leftEar1500'},
    {name: '- Speech Banana', prop:'leftEar1500SpeechBanana'},
    {name: '- Speech String Bean', prop:'leftEar1500SpeechStringBean'},

    {name: '2kHz', prop:'leftEar2000'},
    {name: '- Speech Banana', prop:'leftEar2000SpeechBanana'},
    {name: '- Speech String Bean', prop:'leftEar2000SpeechStringBean'},

    {name: '3kHz', prop:'leftEar3000'},
    {name: '- Speech Banana', prop:'leftEar3000SpeechBanana'},
    {name: '- Speech String Bean', prop:'leftEar3000SpeechStringBean'},

    {name: '4kHz', prop:'leftEar4000'},
    {name: '- Speech Banana', prop:'leftEar4000SpeechBanana'},
    {name: '- Speech String Bean', prop:'leftEar4000SpeechStringBean'},

    {name: '6kHz', prop:'leftEar6000'},
    {name: '- Speech Banana', prop:'leftEar6000SpeechBanana'},    
    {name: '- Speech String Bean', prop:'leftEar6000SpeechStringBean'},

    {name: '8kHz', prop:'leftEar8000'},
    {name: '- Speech Banana', prop:'leftEar8000SpeechBanana'},
    {name: '- Speech String Bean', prop:'leftEar8000SpeechStringBean'},

    {name: 'Right Ear Done', prop:'rightEar'},

    {name: '250Hz', prop:'rightEar250'},
    {name: '- Speech Banana', prop:'rightEar250SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar250SpeechStringBean'},

    {name: '500Hz', prop:'rightEar500'},
    {name: '- Speech Banana', prop:'rightEar500SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar500SpeechStringBean'},

    {name: '1kHz', prop:'rightEar1000'},
    {name: '- Speech Banana', prop:'rightEar1000SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar1000SpeechStringBean'},

    {name: '1.5kHz', prop:'rightEar1500'},
    {name: '- Speech Banana', prop:'rightEar1500SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar1500SpeechStringBean'},

    {name: '2kHz', prop:'rightEar2000'},
    {name: '- Speech Banana', prop:'rightEar2000SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar2000SpeechStringBean'},

    {name: '3kHz', prop:'rightEar3000'},
    {name: '- Speech Banana', prop:'rightEar3000SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar3000SpeechStringBean'},

    {name: '4kHz', prop:'rightEar4000'},
    {name: '- Speech Banana', prop:'rightEar4000SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar4000SpeechStringBean'},

    {name: '6kHz', prop:'rightEar6000'},
    {name: '- Speech Banana', prop:'rightEar6000SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar6000SpeechStringBean'},

    {name: '8kHz', prop:'rightEar8000'},
    {name: '- Speech Banana', prop:'rightEar8000SpeechBanana'},
    {name: '- Speech String Bean', prop:'rightEar8000SpeechStringBean'},
    
    {name: 'Reliability', prop:'reliability'}
  ]
  
  dateControlNames = ['dateOfFirstFitting', 'dateOfFitting', 'dateOfTesting'];  
  hearingAidDevices$ : Observable<any>;
  hearingAidDevicesPage$ : Observable<any>;
  //end duplicate

  form: FormGroup;
  loading = false;
  submitted = false;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;

  id: string;
  patientId: string;
  modelId: string;
  versionId: string;
  firstFittingId: string;

  constructor(
    private formBuilder: FormBuilder,
    //duplicate this if multiple tables
    private hearingAidDevicesService: HearingAidDevicesService, 
    private firstFittingService: FirstFittingService,
    //end duplicate
    private route: ActivatedRoute,
    private alertService: AlertService,
    private userService: UserService,
    private patientService: PatientService,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];

    this.editable = true;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )

    this.form = this.formBuilder.group({
      patient: [''],
      dateOfFirstFitting: ['', Validators.required]
    });

    this.hearingAidDevicesService.getAll(this.id, 0).subscribe();
    
    this.hearingAidDevices$ = this.hearingAidDevicesService.recordsObservable.pipe(
      map(hearingAidDevices => hearingAidDevices["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.hearingAidDevicesPage$ = this.hearingAidDevicesService.recordsObservable

    this.firstFittingService.getByPatient(this.id).subscribe();

    this.firstFittingService.recordObservable.subscribe(
      res => {
        if(res) {
          this.versionId = res["versionId"];
          this.modelId = res["modelId"];
          this.form.patchValue(Utils.mapModelToForm(res, ['dateOfFirstFitting']));
        }
      }
    );
  }

  getPageHearingAidDevices(page: string) {
    this.hearingAidDevicesService.getAll(this.id, page).subscribe();
  }

  //duplicate this if multiple tables
  deleteHearingAidDevices(id: string) {
    this.hearingAidDevicesService.delete(id).pipe(first())
        .subscribe();
  }
  //end duplicate  

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (!this.firstFittingService.recordValue) {
      this.createFirstFitting();
    } else {
      this.updateFirstFitting();
    }    
  }

  createFirstFitting() {
    this.form.get("patient").setValue(this.id)
    this.firstFittingService.add(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success('Date of First Fitting added successfully', { keepAfterRouteChange: true });
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
      
  updateFirstFitting() {
    this.firstFittingService.update(this.form.value, this.modelId, this.versionId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success('Update successful', { keepAfterRouteChange: true });
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  cancelEdit() {
    if(confirm("Unsaved changes will be lost. Proceed?")){
      this.ngOnInit();
      this.editing = false;
    }
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((((user['facility']['roles'].includes('Category C') || user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode'])) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }
}
