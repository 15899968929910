<div class="container h-100 pb-4">
    <!-- <div class="card col-10 offset-1 p-0">
        <div class="card-header">
            Welcome to ENNHSR
        </div>
        <div class="card-body d-flex flex-column justify-content-center"> -->
            <div class="d-flex justify-content-center mb-2">
                <h5><i class="mdi mdi-account-switch"></i> Select User Profile</h5>    
            </div>
            <div class="d-flex flex-wrap justify-content-center mb-2">
                <div *ngFor="let profile of profiles$ | async" class="profile-item d-flex align-items-end m-2">
                    <button class="btn flex-grow-1 py-4 bg-white" (click)="selectProfile(profile)">
                        <i class="mdi mdi-account-circle"></i>
                        <b>{{profile.username}}</b>
                    </button>
                    <button class="btn btn-link p-2 pl-1 h-50" (click)= "deleteProfile(profile)" title="Delete Profile">
                        <i class="mdi mdi-sm mdi-delete"></i>
                    </button>
                </div>
                
                <!-- <button class="btn m-2 py-4 bg-white profile-item">
                    <i class="mdi mdi-account-circle"></i>
                    <b>Peter Lazo</b>
                </button>
                <button class="btn m-2 py-4 bg-white profile-item">
                    <i class="mdi mdi-account-circle"></i>
                    <b>Terrence Mendoza</b>
                </button>
                <button class="btn m-2 py-4 bg-white profile-item">
                    <i class="mdi mdi-account-circle"></i>
                    <b>Art Moldon</b>
                </button>
                <button class="btn m-2 py-4 bg-white profile-item">
                    <i class="mdi mdi-account-circle"></i>
                    <b>Josh Lazo</b>
                </button>
                <button class="btn m-2 py-4 bg-white profile-item">
                    <i class="mdi mdi-account-circle"></i>
                    <b>Aldrich Manzano</b>
                </button>
                <button class="btn m-2 py-4 bg-white profile-item">
                    <i class="mdi mdi-account-circle"></i>
                    <b>Jonathan Fabia</b>
                </button> -->
                
                <button class="btn btn-link m-2 bg-white profile-item" routerLink="/create_profile">
                    <i class="mdi mdi-account-plus"></i><br/>
                    Create Profile
                </button>
            </div>
            <div class="d-flex justify-content-center">
                <p>by selecting a user profile to sign in, you agree to the <a routerLink="/privacy">privacy policy<sup><i class="mdi mdi-sm mdi-help-circle-outline"></i></sup></a></p>
            </div>
        <!-- </div>
    </div> -->
</div>
