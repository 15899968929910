import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { Location } from '@angular/common';
import { FeedbackService } from '../feedback.service';
import { first, map, startWith } from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Console } from 'console';

@Component({ template: '' })
export class AddEditComponent implements OnInit {
        
    constructor(public dialog: MatDialog,
        private location: Location,
        private formBuilder: FormBuilder,
        private feedbacksService: FeedbackService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private router: Router,
        private matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.openDialog();
    }

    openDialog(): void {
        let id = this.route.snapshot.params['id']
        const dialogRef = this.dialog.open(AddEditComponentDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: id,
                isAddMode: !id,
                feedbacksService: this.feedbacksService,
                alertService: this.alertService,
                route: this.route,
                router: this.router,
                matDialog: this.matDialog
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.location.back();
        });
    }
}

@Component({ templateUrl: 'add-edit.component.html' })
export class AddEditComponentDialog implements OnInit {

    form: FormGroup;
    id: string;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    isModal: boolean;
    private versionId: string;
    private modelId: string;
    private route: ActivatedRoute;
    private router: Router;

    constructor(
        public dialogRef: MatDialogRef<AddEditComponentDialog>,
        private feedbacksService: FeedbackService,
        private alertService: AlertService,
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder, 
            id: string, 
            isAddMode: boolean,
            feedbacksService: FeedbackService,
            alertService: AlertService,
            router: Router
        }
    ) {
        this.id = data.id;
        this.isAddMode = data.isAddMode;
        this.feedbacksService = data.feedbacksService;
        this.alertService = data.alertService;
        this.router = data.router;
        this.dataSource.data = TREE_DATA;
    }

    ngOnInit() {
        var user = JSON.parse(localStorage.getItem('user'))
        this.form = this.data.formBuilder.group({
            priority: [this.isAddMode ? 'Normal': ''],
            date: [new Date()],
            
            from: [`${user['firstName']} ${user['lastName']} (${user['username']})`],
            facility: [`${user['facility']['facility']} (${user['facility']['facilityCode']}, Category ${user['facility']['category']})`],
            role: [`${user['facility']['roles'][0]}`],

            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            
            topic: ['', Validators.required],
            status: ['Open'],
            
            description: [''],
            notes: [''],

            url: [this.router.url],
            logs: [this.feedbacksService.errorsValue.join('\n')]
        });
        if (!this.isAddMode) {
            var model = this.feedbacksService.recordsValue["results"].find(x => x.modelId == this.id);
            this.versionId = model["versionId"];
            this.modelId = model["modelId"];
            this.form.patchValue(Utils.mapModelToForm(model));
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createFeedback();
        } else {
            this.updateFeedback();
        }
    }

    private createFeedback() {
        this.feedbacksService.add(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Ticket submitted. A Technical Support representative will contact you within 24 hours. Thank you!', { keepAfterRouteChange: true });
                    this.dialogRef.close()
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    private updateFeedback() {
        this.feedbacksService.update(this.form.value,this.modelId, this.versionId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close()
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }    

    private _transformer = (node: Node, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            value: node.value,
            level: level,
        };
    };
    
    treeControl = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: FlatNode) => node.expandable;

    setTopic(topic) {
        this.form.get('topic').setValue(topic);
    }
}

const TREE_DATA: Node[] = [
    {
        name: 'Patient', value: 'Patient',        
        children: [
            { 
                name: 'Search Patient', value: 'Patient > Search Patient', 
            }, 
            { 
                name: 'Open Patient Records', value: 'Patient > Open Patient Records',
                children: [
                    { 
                        name: 'Patient Details', value: 'Patient > Open Patient Records > Patient Details' 
                    },
                    { 
                        name: 'Hearing Screening', value: 'Patient > Open Patient Records > Hearing Screening' 
                    },
                    { 
                        name: 'Confirmatory Testing', value: 'Patient > Open Patient Records > Confirmatory Testing' 
                    },
                    { 
                        name: 'Hearing Aid/ Devices', value: 'Patient > Open Patient Records > Hearing Aid/ Devices' 
                    },
                    { 
                        name: 'Speech Therapy', value: 'Patient > Open Patient Records > Speech Therapy' 
                    },
                    { 
                        name: 'Developmental Monitoring', value: 'Patient > Open Patient Records > Developmental Monitoring' 
                    },
                    { 
                        name: 'Surgical Hearing Interventions', value: 'Patient > Open Patient Records > Surgical Hearing Interventions' 
                    },
                    { 
                        name: 'Cochlear Implant Programming', value: 'Patient > Open Patient Records > Cochlear Implant Programming' 
                    },
                    { 
                        name: 'Screening Results', value: 'Patient > Open Patient Records > Screening Results' 
                    },
                ] 
            }, 
            { 
                name: 'Register Patient', value: 'Patient > Register Patient' ,
                children: [
                    { 
                        name: 'Load Patient', value: 'Patient > Register Patient > Load Patient' 
                    }, 
                    { 
                        name: 'New Patient', value: 'Patient > Register Patient > New Patient' 
                    }
                ]
            }
        ]
    },
    {
        name: 'Facility', value: 'Facility',
        children: [
            {
                name: 'Users', value: 'Facility > Users',
            },
            {
                name: 'Facility Details', value: 'Facility > Facility Details',
            },
            {
                name: 'Machines Used', value: 'Facility > Machines Used',
            },
            {
                name: 'NHSRC Seals', value: 'Facility > NHSRC Seals',
            },
        ],
    },
    {
        name: 'Dashboard', value: 'Dashboard',
        children: [
            {
                name: 'Reports', value: 'Dashboard > Reports',
            },
            {
                name: 'Analytics', value: 'Dashboard > Analytics',
            },
        ],
    },
    {
        name: 'Admin', value: 'Admin',
        children: [
            {
                name: 'Facilities', value: 'Admin > Facilities',
            },
            {
                name: 'Users', value: 'Admin > Users',
            },
            {
                name: 'NHSRC Seals', value: 'Admin > NHSRC Seals',
            },
            {
                name: 'Announcements', value: 'Admin > Announcements',
            }
        ],
    },
    {
        name: 'Others', value: 'Others',
        children: [
            {
                name: 'Switch Facility', value: 'Others > Switch Facility',
            },
            {
                name: 'Account', value: 'Others > Account',
            },
            {
                name: 'Validate PN', value: 'Others > Validate PN',
            },
            {
                name: 'ENNHSR Lite', value: 'Others > ENNHSR Lite',
            }
        ],
    },  
];

interface Node {
    name: string;
    value?: string;
    children?: Node[];
}

interface FlatNode {
    expandable: boolean;
    name: string;
    level: number;
}
