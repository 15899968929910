import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class PatientsReceivingSpeechAndLanguageInterventionReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Last Name'
    })
    lastName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'First Name'
    })
    firstName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Middle Name'
    })
    middleName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Type of Session'
    })
    evaluationOrTherapySession: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Number of Session Attended'
    })
    noOfSessionAttended: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Date of Session'
    })
    dateOfSession: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Audition'
    })
    audition: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Chronological Age'
    })
    chronologicalAge: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Hearing Age'
    })
    hearingAge: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Speech'
    })
    speech: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Receptive Language'
    })
    receptiveLanguage: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Expressive Language'
    })
    expressiveLanguage: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Intervention Approach'
    })
    interventionApproach: string;
}
