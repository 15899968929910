import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { PedsScreeningService } from './peds-screening.service';

@Component({
  selector: 'app-developmental-monitoring',
  templateUrl: './developmental-monitoring.component.html',
  styleUrls: ['./developmental-monitoring.component.scss']
})
export class DevelopmentalMonitoringComponent implements OnInit {

  columns = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of Screening'},
    {name: 'Current Age'},
    {name: 'Informant'},
    {name: 'Informant (Specify)', prop: 'informantSpecify'},
    {name: 'Decision Pathways (A)', prop: 'dPA'},
    {name: 'Decision Pathways (B)', prop: 'dPB'},
    {name: 'Decision Pathways (C)', prop: 'dPC'},
    {name: 'Decision Pathways (D)', prop: 'dPD'},
    {name: 'Decision Pathways (E)', prop: 'dPE'},
    {name: 'Areas of Concern (GC)', prop: 'aOFGC'},
    {name: 'Areas of Concern (ELA)', prop: 'aOFELA'},
    {name: 'Areas of Concern (RL)', prop: 'aOFRL'},
    {name: 'Areas of Concern (FM)', prop: 'aOFFM'},
    {name: 'Areas of Concern (GM)', prop: 'aOFGM'},
    {name: 'Areas of Concern (B)', prop: 'aOFB'},
    {name: 'Areas of Concern (SE)', prop: 'aOFSE'},
    {name: 'Areas of Concern (SH)', prop: 'aOFSH'},
    {name: 'Areas of Concern (S)', prop: 'aOFS'},
    {name: 'Areas of Concern (OH)', prop: 'aOFOH'},
    {name: 'Referred to Dev PED', prop:'referredToDevPed'},
    {name: 'Date Referred to Dev PED', prop:'dateReferredToDevPed'},
    {name: 'Date Seen by Dev PED', prop:'dateSeenByDevPed'},
    {name: 'Tools Used', prop: 'toolsUsed'},
    {name: 'Tools Used (Specify)', prop:'toolsUsedSpecify'},
    {name: 'Primary Developmental Diagnosis'},
    {name: 'Comorbidity'},
    {name: 'Referred for Mental Health Screening'},
    {name: 'Screening Date'}
  ]

  dateControlNames = ['dateOfScreening', 'dateReferredToDevPed', 'dateSeenByDevPed', 'screeningDate'];
  pedsScreening$ : Observable<any>;
  pedsScreeningPage$ : Observable<any>;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;

  id: string;

  constructor(
    private pedsScreeningService: PedsScreeningService,
    private route: ActivatedRoute,
    private userService: UserService,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.editable = true;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )

    this.pedsScreeningService.getAll(this.id, 0).subscribe();
    
    this.pedsScreening$ = this.pedsScreeningService.recordsObservable.pipe(
      map(pedsScreening => pedsScreening["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.pedsScreeningPage$ = this.pedsScreeningService.recordsObservable
  }
  
  getPagePEDSScreening(page: string) {
    this.pedsScreeningService.getAll(this.id, page).subscribe();
  }

  deletePedsScreening(id: string) {
    this.pedsScreeningService.delete(id).pipe(first())
        .subscribe();
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }

}
