<div class="container p-4">
    <div class="d-flex justify-content-center"><h3>Download <b><i class="mdi mdi-ear-hearing"></i>ENNHSR <span style="font-size: 1.6rem; font-weight: normal;">Lite</span></b></h3><br></div>
    <div class="d-flex justify-content-center"><span>ENNHSR for Desktop. Work offline. Sync data online.</span></div>
    <div class="d-flex justify-content-center mt-5">
        <div class="col-6">
            <div class="d-flex justify-content-center"><h4><b>For Windows 64-bit</b></h4></div>
            <div class="d-flex justify-content-center"><a class="btn btn-secondary btn-block" href="https://cinque.chits.ph/owncloud/index.php/s/mzdGPW2PtMF2iCp/download?path=%2F&files=ennhsr-lite-1.1.1.zip"><i class="mdi mdi-download"></i>ver 1.1.1</a></div>
            <!-- <div class="d-flex justify-content-center"><a>Older versions</a></div> -->
            <div class="mt-3">
                <ul class="list-group">
                    <li class="list-group-item"><b>Minimum System Requirements</b></li>
                    <li class="list-group-item">Disk Space - 2GB</li>
                    <li class="list-group-item">Processor - Intel i3 or AMD counterpart</li>                    
                    <li class="list-group-item">RAM - 8GB</li>
                    <li class="list-group-item">Internet Connection - optional for Sync feature</li>
                </ul>
            </div>
        </div>
    </div>
</div>
