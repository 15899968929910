export class FieldUpdate {
    modelName: string
    modelId: string
    fieldPath: string
    value: any
    dateUpdated: Date
    dateSynced: Date
    userId: string
    indexIds: string[]
}
