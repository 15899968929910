import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SealsService } from '@app/admin/seal/seals.service';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { Observable } from 'rxjs';
import { concatMap, first, map } from 'rxjs/operators';
import { SealDistributionService } from './seal-distribution.service';
import { SealSummaryService } from '@app/admin/seal/seal-summary.service';

@Component({
  selector: 'app-seal',
  templateUrl: './seal.component.html',
  styleUrls: ['./seal.component.scss']
})
export class SealComponent implements OnInit {

  form: FormGroup;
  loading = false;
  isLoadingSeals: boolean = true;
  loadingTracker = false;
  submitted = false;
  facilityId: string;
  sealRequest$ : Observable<any>;
  availableSeals: number = 0;
  consumedSeals: number = 0;
  disableRequest: boolean;
  completedRequests$ : Observable<any>;
  canceledRequests$ : Observable<any>;
  columns = [{ name: 'Date Requested' }, { name: 'Reference ID   ' , prop: 'shortReferenceID'}, { name: 'Quantity' }, { name: 'Date Received' }, { name: 'Received Seals' , prop: 'sealsData'}];
  columns2 = [{ name: 'Date Requested' }, { name: 'Reference ID   ' , prop: 'shortReferenceID'}, { name: 'Quantity' }, { name: 'Date Canceled' }];
  noAdd: boolean = true;
  constructor(private formBuilder: FormBuilder, private alertService: AlertService, private sealDistributionService: SealDistributionService, private sealsService: SealsService, private sealSummaryService: SealSummaryService) { 
    
  }

  ngOnInit() {
    this.sealDistributionService.sealDistributionSubject.next(null)
    localStorage.removeItem('sealDistributionRecords');
    this.facilityId = JSON.parse(localStorage.getItem('user'))["facility"]["facilityModelId"];
    this.form = this.formBuilder.group({
      facility:  [''],
      quantity: ['', [Validators.required, quantityValidator()]],
      referenceID: [''],
      requestStatus: ['']
    });
    this.sealDistributionService.getAll(this.facilityId).subscribe(
      res => {
        this.isLoadingSeals = false;
      }
    );
    this.sealRequest$ = this.sealDistributionService.recordsObservable.pipe(map(x => {
      this.availableSeals = 0;
      this.consumedSeals = 0;
      if(x){
        x["results"].forEach(element => {
          element["dataModel"]["sealsData"] = []
          if(element["dataModel"]["requestStatus"] == "RECEIVED"){
            element["dataModel"]["seals"]["results"].forEach(element2 => {
              if(element2["dataModel"]["patient"] === null)
               this.availableSeals++
              else 
                this.consumedSeals++
            element["dataModel"]["sealsData"].push(Utils.mapModelToColumns(element2))
            });
            var groupedData = [];
            for (let [key, value] of Object.entries(Utils.groupBy(element["dataModel"]["sealsData"], "groupId"))) {
              if((value as []).length > 1)
                groupedData.push({"id": key, "serialNumber": value[0]["batchCode"]+ String(Math.min.apply(null, (value as []).map(e => e["serialNumber"]))).padStart(10, '0') +"-"+value[0]["batchCode"]+String(Math.max.apply(null, (value as []).map(e => e["serialNumber"]))).padStart(10, '0')});
              else 
                groupedData.push({"id": key, "serialNumber": value[0]["batchCode"]+value[0]["serialNumber"]});
            }
            element["dataModel"]["sealsData"] = groupedData;
            element["dataModel"]["sealsData"] = element["dataModel"]["sealsData"].map(x => x.serialNumber);
          }
        });
        console.log(x["results"].length == 0)
        this.disableRequest = x["results"].length == 0 ? ((this.consumedSeals == 0 && this.availableSeals == 0) ? false : (this.consumedSeals / (this.availableSeals + this.consumedSeals) >= 0.5) ? false : true) : false
       
        return x;
      }
    }));
    this.completedRequests$ = this.sealDistributionService.recordsObservable.pipe(
     map(x => {
       if(x){
        x["results"].forEach(element => {
          element["dataModel"]["shortReferenceID"] = element["dataModel"]["referenceID"].substring(24,36).toUpperCase();
          element["dataModel"]["dateRequested"] = element["dateCreated"];
        });
      return x["results"].filter(req => req["dataModel"]["requestStatus"] === "RECEIVED" ).map(x => Utils.mapModelToColumns(x, ['dateReceived','dateRequested']));
       }
    }));
    this.canceledRequests$ = this.sealDistributionService.recordsObservable.pipe(
      map(x => {
        if(x){
         x["results"].forEach(element => {
           element["dataModel"]["shortReferenceID"] = element["dataModel"]["referenceID"].substring(24,36).toUpperCase();
           element["dataModel"]["dateRequested"] = element["dateCreated"];
        });
       return x["results"].filter(req => req["dataModel"]["requestStatus"] === "ADMIN_CANCELED" ).map(x => Utils.mapModelToColumns(x,['dateCanceled','dateRequested']));
        }
     }));

     this.sealSummaryService.getByFacility(this.facilityId).subscribe()
  };

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
    this.loading = true;
    this.createRequest();
  }

  deleteRecord(id: string){

  }

  private createRequest() {
    //console.log("this.form.value",this.form.value)
    this.form.get("facility").setValue(this.facilityId)
    this.form.controls['referenceID'].setValue(Utils.generateGuid());
    this.form.controls['requestStatus'].setValue("REQUESTED");
    this.sealDistributionService.add(this.form.value)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Request sent successfully', { keepAfterRouteChange: true });
                this.form.get("quantity").reset()
                this.loading = false;
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
  }

  cancelRequest(request: any): void {
    if(confirm("Are you sure you want to cancel this request?")){
      this.loadingTracker = true;
      this.sealDistributionService.cancel(request)
      .pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Request canceled', { keepAfterRouteChange: true });
              this.loadingTracker = false;
          },
          error: error => {
              this.alertService.error(error);
              this.loadingTracker = false;
          }
      });
    }
  }

  receiveOrder(request: any): void {
    //console.log("request:", request)
    //console.log("request:", request.modelId)
    const sealSummaryRecord = this.sealSummaryService.recordsValue?.results[0];
    //console.log("sealSummaryRecord:", sealSummaryRecord)
    //console.log("add model: ", {facility: this.facilityId, used: sealSummaryRecord.dataModel.used, total: String(Number(request.dataModel.quantity)+Number(sealSummaryRecord.dataModel.total))})
    this.loadingTracker = true;
    this.sealDistributionService.receive(request.modelId)
    .pipe(first(),
    concatMap(() => {
      if(sealSummaryRecord && sealSummaryRecord.dataModel.total != 0){
        return this.sealSummaryService.update({ total: String(Number(request.dataModel.quantity)+Number(sealSummaryRecord.dataModel.total)) },sealSummaryRecord.modelId,  sealSummaryRecord.versionId,sealSummaryRecord);
      } else {
        return this.sealSummaryService.add({facility: this.facilityId, used: '0', total: request.dataModel.quantity });
      }
    })
    )
    .subscribe({
        next: () => {
            this.alertService.success('Order received', { keepAfterRouteChange: true });
            this.loadingTracker = false;
        },
        error: error => {
            this.alertService.error(error);
            this.loadingTracker = false;
        }
    }); 
  }

}

export function quantityValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (value !== null && value !== undefined && value < 300) {
      return { lessThan300: true };
    }

    return null;
  };
}