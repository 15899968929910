import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SealDistributionService } from '../seal-distribution.service';
import { ShippingSealDistributionService } from './shipping-seal-distribution.service';
import { SealsService } from '../seals.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
  sealInTransit$ : Observable<any>;
  columns = [{ name: 'Serial Number'}];
  isLoading: boolean = true;
  progressTotal = 0;
  progress = 0;
  private requestCountSubscription: Subscription;
  
  constructor(private shippingSealDistributionService: ShippingSealDistributionService, private alertService: AlertService, private sealsService: SealsService) { }

  ngOnInit(): void {
    this.requestCountSubscription = this.sealsService.requestCount$.subscribe((value: number) => {
      this.progress = value;
    });
    this.shippingSealDistributionService.requestTotalCount$.subscribe((value: number) => {
      this.progressTotal = value;
    });
    this.shippingSealDistributionService.getAll().subscribe();
    this.sealInTransit$ = this.shippingSealDistributionService.recordsObservable.pipe(
      map(x => {
        if(x){
          this.progressTotal = x["results"].length;
          x["results"].forEach(element => {
            element["dataModel"]["sealsData"] = []
            element["dataModel"]["seals"]["results"].forEach(element2 => {
              element["dataModel"]["sealsData"].push(Utils.mapModelToColumns(element2))
            });
            var groupedData = [];
            for (let [key, value] of Object.entries(Utils.groupBy(element["dataModel"]["sealsData"], "groupId"))) {
              if((value as []).length > 1)
                groupedData.push({"id": key, "serialNumber": (value[0]["batchCode"] ? value[0]["batchCode"] : "")+ String(Math.min.apply(null, (value as []).map(e => e["serialNumber"]))).padStart(10, '0')+"-"+(value[0]["batchCode"] ? value[0]["batchCode"] : "")+ String(Math.max.apply(null, (value as []).map(e => e["serialNumber"]))).padStart(10, '0')});
              else 
                groupedData.push({"id": key, "serialNumber": (value[0]["batchCode"] ? value[0]["batchCode"] : "")+value[0]["serialNumber"]});
            }
            element["dataModel"]["sealsData"] = groupedData;
          });
          this.isLoading = false;
        }
        return x;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.requestCountSubscription) {
      this.sealsService.resetCounterObservableValue();
      this.requestCountSubscription.unsubscribe();
    }
  }

  cancelShipping(request: any): void {
    if(confirm("Are you sure you want to cancel shipping this request?")){
      this.shippingSealDistributionService.updateStatus(request,"PROCESSED")
      .pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Shipping canceled', { keepAfterRouteChange: true });
          },
          error: error => {
              this.alertService.error(error);
          }
      });
    }
  }

}
