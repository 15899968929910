
<div class="card">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card-header d-flex justify-content-between">
            <span>Form Name</span>
            <div>
                <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
                <button type="button" *ngIf="this.editable && !this.editing" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
                <button type="button" *ngIf="this.editable && this.editing" (click)="this.editing = false;" class="btn btn-success ml-1 px-2 py-0"><i class="mdi mdi-content-save"></i></button>
                <button type="button" *ngIf="this.editable && this.editing" (click)="this.editing = false;" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
            </div>
        </div>
        <div class="card-body">   
            <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
                <div class="form-row">
                    <div class="form-group col-6">
                        <label for="username">Patient Type</label>
                        <select class="form-control">
                            <option disabled selected>Choose...</option>
                            <option>New</option>
                            <option>Old</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="username">Status</label>
                        <div class="py-2">
                            <mat-radio-group>
                                <mat-radio-button class="mr-3" [value]="'done'">Done</mat-radio-button>
                                <mat-radio-button class="mr-3" [value]="'not done'">Not Done</mat-radio-button>
                                <mat-radio-button [value]="'refused'">Refused</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="username">Serial Number</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                    <div class="form-group col-4">
                        <label for="username">Registration Facility Name and Code</label>
                        <input disabled placeholder="Auto Generated" type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                    <div class="form-group col-4">
                        <label for="username">Hearing Screening Center</label>
                        <input disabled placeholder="Auto Generated" type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                </div>
                <label>Patient's Name</label>
                <div class="form-row ml-3">
                    <div class="form-group col-4">
                        <label for="username">Given</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                    <div class="form-group col-4">
                        <label for="username">Middle</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                    <div class="form-group col-4">
                        <label for="username">Last</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                </div>
                <div class="form-group">
                    <label for="username">Sex</label>
                    <div>
                        <mat-radio-group>
                            <mat-radio-button class="mr-3" [value]="'male'">Male</mat-radio-button>
                            <mat-radio-button [value]="'female'">Female</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="form-group">
                    <mat-checkbox formControlName="privacy" [ngClass]="{ 'is-invalid': submitted && f.privacy.errors }">
                        <label class="form-check-label" for="privacy">
                            Philhealth
                        </label>
                    </mat-checkbox>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="username">Date of Birth</label>
                        <div>
                            <input matInput [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <i class="mdi mdi-calendar"></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="username">Time of Birth</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label for="username">Birth Outcome</label>
                        <select class="form-control">
                            <option>Outcome 1</option>
                            <option>Outcome 2</option>
                            <option>Outcome 3</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="username">Birth Order</label>
                        <input type="number" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label for="username">Birth Weight</label>
                        <input type="number" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                    <div class="form-group col-6">
                        <label for="username">Gestation Age</label>
                        <input type="number" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                </div>
                <div class="form-group">
                    <label>Mother's Name</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="username">Given</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        </div>
                        <div class="form-group col-4">
                            <label for="username">Middle</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        </div>
                        <div class="form-group col-4">
                            <label for="username">Last</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Address</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-12">
                            <label for="username">Street Address</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="username">Barangay</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        </div>
                        <div class="form-group col-4">
                            <label for="username">City/Municipality</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        </div>
                        <div class="form-group col-4">
                            <label for="username">Province</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="username">Phone Number</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                </div>                 
                <div class="form-group mt-3">
                    <div class="d-flex justify-content-center">
                        <div class="col-md-10">
                            <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Register Patient
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div> 
    </form>
</div>
