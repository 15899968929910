<div class="address-container">
    <input type="hidden" [formControl]="psgcControl"/>
    <label>{{addressLabel}}</label>
    <div class="form-row ml-3">
        <div class="form-group col-6">
            <label for="provinceControl">Province</label>
            <select class="form-control" [formControl]="provinceControl" (selectionChange)="provinceFilter.reset()" [ngClass]="{ 'is-invalid': submitted && provinceControl.errors }">
                <div class="form-group">
                    <label>Filter</label>
                    <input [formControl]="provinceFilter" (keydown)="$event.stopPropagation()">
                </div>
                <option selected *ngIf="!(provinceOptionsFiltered?.length > 0)"><mat-spinner [style.margin]="'0 auto'" [diameter]="36"></mat-spinner></option>
                <option *ngIf="!provinceFilter.value && provinceOptionsFiltered?.length > 0">None</option>
                <option *ngFor="let option of provinceOptionsFiltered" value="{{option.code}}">{{option.name}}</option>
            </select>
            <div *ngIf="submitted && provinceControl.errors" class="invalid-feedback d-block">
                <span *ngIf="provinceControl.errors.required">Province is required</span>
            </div>
        </div>
        <div class="form-group col-6">
            <label>City/Municipality</label>
            <select class="form-control" [formControl]="cityMunControl"  (selectionChange)="cityMunFilter.reset()">
                <div class="form-group">
                    <label>Filter</label>
                    <input [formControl]="cityMunFilter"  (keydown)="$event.stopPropagation()">
                </div>
                <option selected *ngIf="!(cityMunOptionsFiltered?.length > 0)"><mat-spinner [style.margin]="'0 auto'" [diameter]="36"></mat-spinner></option>
                <option *ngIf="!cityMunFilter.value && cityMunOptionsFiltered?.length > 0">None</option>
                <option *ngFor="let option of cityMunOptionsFiltered" value="{{option.code}}">{{option.name}}</option>
            </select>
            <div *ngIf="submitted && cityMunControl.errors">
                <span *ngIf="cityMunControl.errors.required">City/Municipality is required</span>
            </div>
        </div>
    </div>
    <div class="form-row ml-3">
        <div class="form-group col-6">
            <label>Barangay</label>
            <select class="form-control" [formControl]="brgyControl"  (selectionChange)="brgyFilter.reset()">
                <div class="form-group">
                    <label>Filter</label>
                    <input [formControl]="brgyFilter"  (keydown)="$event.stopPropagation()">
                </div>
                <option selected *ngIf="!(brgyOptionsFiltered?.length > 0)"><mat-spinner [style.margin]="'0 auto'" [diameter]="36"></mat-spinner></option>
                <option *ngIf="!brgyFilter.value && brgyOptionsFiltered?.length > 0">None</option>
                <option *ngFor="let option of brgyOptionsFiltered" value="{{option.code}}">{{option.name}}</option>
            </select>
            <mat-error *ngIf="submitted && brgyControl.errors">
                <span *ngIf="brgyControl.errors.required">Barangay is required</span>
            </mat-error>
        </div>
        <div class="form-group col-6" *ngIf="streetControl">
            <label>Street</label>
            <input class="form-control" [formControl]="streetControl">
            <div *ngIf="submitted && streetControl.errors">
                <span *ngIf="streetControl.errors.required">Street Address is required</span>
            </div>
        </div>
    </div>
</div>