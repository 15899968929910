import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { PrintService } from '@app/shared/print/print.service';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SpeechPathologySessionService} from './speech-pathology-session.service';
import { SpeechTherapyService } from './speech-therapy.service';

@Component({
  selector: 'app-speech-therapy',
  templateUrl: './speech-therapy.component.html',
  styleUrls: ['./speech-therapy.component.scss']
})
export class SpeechTherapyComponent implements OnInit {

  columnsSPS = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of Session', prop:'dateOfSession'},
    {name: 'Evaluation or Therapy Session', prop:'evaluationOrTherapy'},
    {name: 'Number of Session attended', prop:'numberOfSession'},
    
    {name: 'Chronological Age (Months)', prop:'chronologicalAge'},
    {name: 'Hearing Age (Months)', prop:'hearingAge'},

    {name: 'Audition (Chronological)', prop:'audition'},
    {name: 'Audition (Hearing)', prop:'auditionHA'},
    {name: 'Audition notes'},
    
    {name: 'Speech (Chronological)', prop:'speech'},
    {name: 'Speech (Hearing)', prop:'speechHA'},
    {name: 'Speech notes'},

    {name: 'Receptive Language (Chronological)', prop:'receptiveLanguage'},
    {name: 'Receptive Language (Hearing)', prop:'receptiveLanguageHA'},
    {name: 'Receptive Language notes'},

    {name: 'Expressive Language (Chronological)', prop:'expressiveLanguage'},
    {name: 'Expressive Language (Hearing)', prop:'expressiveLanguageHA'},
    {name: 'Expressive Language notes'},

    {name: 'Recommendation/General notes', prop: 'recommendationGeneralNotes'},
    {name: 'Intervention Approach'},
    {name: 'Specific Intervention Approach', prop: 'interventionApproachSpecify'}
  ]

  dateControlNames = ['dateOfSession'];
  speechPathologySession$ : Observable<any>;
  speechPathologySessionPage$ : Observable<any>;

  form: FormGroup;
  loading = false;
  submitted = false;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;

  isAddMode: any;

  id: string;
  patientId: string;
  modelId: string;
  versionId: string;
  speechTherapyId: string;

  fileToUpload: File = null;
  
  constructor(
    private formBuilder: FormBuilder,
    private speechPathologySessionService: SpeechPathologySessionService,
    private speechTherapyService: SpeechTherapyService,
    private patientService: PatientService,
    private ennhsrService: EnnhsrService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private printService: PrintService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.editable = this.id? true:false;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )

    this.form = this.formBuilder.group({
      patient: [''],
      educationalPlacementMainstream: [''],
      mainstream: [''],
      mainstreamSpecify: [''],
      educationalPlacementSpecialEducation: [''],
      specialEducationSpecify: [''],
      educationalPlacementALS: [''],
      alsSpecify: [''],
      educationalPlacementNa: [''],
      naReason: [''],
      uploadFile: ['']
    });

    this.speechPathologySessionService.getAll(this.id,0).subscribe();
    
    this.speechPathologySession$ = this.speechPathologySessionService.recordsObservable.pipe(
      map(speechPathologySession => speechPathologySession["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )
    this.speechPathologySessionPage$ = this.speechPathologySessionService.recordsObservable;

    this.speechTherapyService.getByPatient(this.id).subscribe();

    this.speechTherapyService.recordObservable.subscribe(
      res => {
        if(res) {
          this.versionId = res["versionId"];
          this.modelId = res["modelId"];
          this.form.patchValue(Utils.mapModelToForm(res));
        }
      }
    );
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    if (!this.speechTherapyService.recordValue) {
        this.createSpeechTherapy();
    } else {
        this.updateSpeechTherapy();
    }
  }

  getPage(page: string) {
    this.speechPathologySessionService.getAll(this.id, page).subscribe();
  }

  createSpeechTherapy() {
      this.form.get("patient").setValue(this.id)
      this.speechTherapyService.add(this.form.value,this.fileToUpload)
          .pipe(first())
          .subscribe({
              next: () => {
                  this.alertService.success('Educational Placement added successfully', { keepAfterRouteChange: true });
              },
              error: error => {
                  this.alertService.error(error);
                  this.loading = false;
              }
          });
  }
    
  updateSpeechTherapy() {
    this.speechTherapyService.update(this.form.value, this.modelId, this.versionId,this.fileToUpload)
      .pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Update successful', { keepAfterRouteChange: true });
          },
          error: error => {
              this.alertService.error(error);
              this.loading = false;
          }
      });
  }

  deleteSpeechPathologySession(id: string) {
    this.speechPathologySessionService.delete(id).pipe(first())
        .subscribe();
  }

  cancelEdit() {
    if(confirm("Unsaved changes will be lost. Proceed?")){
      this.ngOnInit();
      this.editing = false;
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    //console.log("handleFileInput", files.item(0))
  }

  download(id: string){
    this.ennhsrService.downloadFile(id).pipe(
      map(res => {
        /* const link = document.createElement('a');
        link.href = URL.createObjectURL(res);
        link.download = Date.now().toString()+Utils.generateGuid();//to give a random file name
        document.body.append(link);
        link.click();
        link.remove(); */
        
        const blob = new Blob([res], { type: 'application/pdf' });
        const url= URL.createObjectURL(blob);
        console.log({url})
        open(url);

      })
    ).subscribe();
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category C') || user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }
}
