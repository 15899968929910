<div 
[style.left.px]="left"
[style.top.px]="top"
[style.width.px]="width"
[style.height.px]="height"
[style.transition]="transition"
[style.z-index]="zindex"
[style.outline]="outline"
[style.outline-offset]="outlineoffset"
></div>
