import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ConceptsService } from '../concepts.service';
import Utils from '@app/shared/utils';

@Component({ templateUrl: 'list.component.html' })
export class ListComponent implements OnInit {
    concepts$ : Observable<any>;
    columns = [{ name: 'Primary Name' }, { name: 'Short Name' }, { name: 'Description' }, { name: 'Data Class' }, { name: 'Is Set' }, { name: 'Data Type' }, { name: 'Required' }, { name: 'Retired' }];
    
    constructor(private conceptsService: ConceptsService) {}

    ngOnInit() {
        this.conceptsService.getAll().subscribe();
        this.concepts$ = this.conceptsService.recordsObservable.pipe(
        map(concepts => concepts["results"].map(x => Utils.mapModelToForm(x)))
        )
    }

    deleteConcept(id: string) {
        this.conceptsService.delete(id).pipe(first())
        .subscribe();
    } 
}