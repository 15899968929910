import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tokenize } from '@angular/compiler/src/ml_parser/lexer';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnnhsrService {

  constructor(
    public http: HttpClient
  ) { }

  getAnnouncement(){
    return this.http.get<any[]>(`${environment.apiUrl}/dashboard/announcement`);
  }

  downloadFile(id: string){
    return this.http.get(`${environment.apiUrl}/file/get/${id}`,{responseType: 'blob'});
  }

  getFileMeta(id: string){
    return this.http.get(`${environment.apiUrl}/file/get/${id}/metadata`);
  }

  icd(name: string) {
    return this.http.get(`${environment.apiUrl}/icd10/find?q=${name}`);
  }

  rvsFirst(name: string) {
    return this.http.get(`${environment.apiUrl}/rvs/find?q=${name}`);
  }

  rvsSecond(name: string) {
    return this.http.get(`${environment.apiUrl}/rvs/find?q=${name}`);
  }

  sNum(name: string) {
    return (this.http.get(`${environment.apiUrl}/icd10/find?q=${name}`));
  }

  getToken() {
    const grant_type= 'client_credentials';
    const client_id= 'ennhsr-client';
    const client_secret= 'ennhsr-secret';
    const body = new HttpParams()
     .set('grant_type', grant_type)
     .set('client_id', client_id)
     .set('client_secret', client_secret);
    return this.http.post(`${environment.originUrl}/oauth/token`,body,{headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })})
    .pipe(
      map(res => {
        localStorage.setItem('originToken', res['access_token']);
      })
    )
  }
}
