<div class="container">
    <div class="card">
        <div id="email" #print class="card-body bg-white" *ngIf="patient$ | async as p">
            <div class="w-100 d-flex justify-content-end" style="z-index:999;">
                <div>
                    <div *ngIf="!emailResults" class="">
                        <button *ngIf="validatePrivilege()" type="button" (click)="printService.print([print],'Screening Results')" class="bg-white text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-printer"></i></button>
                        <!-- <button type="button" class="text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-file-pdf"></i></button>   -->
                        <button *ngIf="validatePrivilege() && !isDesktop" (click)="this.emailResults = true; exportAsPDF('email');" type="button" class="bg-white text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-email"></i></button>
                        <button (click)="this.dialogRef.close()" type="button" class="bg-white text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-close"></i></button>
                    </div>
                    <form [formGroup]="form">
                        <div *ngIf="emailResults" class="form-row align-items-center">
                            <div class="col-auto">
                                <input placeholder="Enter email address" class="form-control" type="text" formControlName="emailAdd">
                                <input class="form-control" type="hidden" formControlName="pdf">
                            </div>
                            <div class="col-auto">
                                <button (click)="this.emailResults = false; sendEmail()" type="button" class="bg-white text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-send"></i></button>
                                <button (click)="this.emailResults = false" type="button" class="bg-white text-dark border border-info btn btn-primary px-2 py-0 mr-2"><i class="mdi mdi-cancel"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="mb-4 d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    <qrcode *ngIf="!isDesktop" [qrdata]="value" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"></qrcode>            
                    <div>
                        <span class="badge pl-0">{{p.patientNumber}}</span>
                        <h5 class="mb-0"><b>{{
                            (p.firstName && p.firstName !== '' && p.lastName && p.lastName !== '') ? 
                                (p.lastName + ', ' + p.firstName + ' ' + p.middleName) 
                                : 'Baby ' + (p.sex && p.sex !== '' ? (p.sex | boyGirl | titlecase) : '') + ' ' +
                                    (p.lastName && p.lastName !== '' ? p.lastName : p.motherLastName)
                            }}</b></h5>
                        <span><i class="mdi mdi-account-details mr-1"></i> {{p.sex | titlecase}}, Born: {{p.dateOfBirth | date:'longDate'}} ({{p.dateOfBirth | age}} y.o.)</span><br>
                        <span><i class="mdi mdi-home mr-1"></i> {{p.fullAddress}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="bg-grey mt-2 mr-3 d-flex justify-content-center align-items-center seal-box" style="height: 96px; width: 78px;"><span class="text-white"><b>SEAL</b></span></div>
                </div>
            </div>
            <div>
                <fieldset disabled>
                    <label>Patient Details</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="">Birth Outcome</label>
                            <input type="text" class="form-control" value="{{p.birthOutcome}}">
                        </div>
                        <div class="form-group col-6">
                            <label for="">Birth Order</label>
                            <input type="text" class="form-control" value="{{p.birthOrder}}">
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6">
                            <label for="">Birth Weight</label>
                            <input type="text" class="form-control" value="{{p.birthWeight}}">
                        </div>
                        <div class="form-group col-6">
                            <label for="">Gestation Age</label>
                            <input type="text" class="form-control" value="{{p.gestationAge}}">
                        </div>
                    </div>
                    <label>Philhealth</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="">PIN</label>
                            <input type="text" class="form-control" value="{{p.philhealthPin}}">
                        </div>
                        <div class="form-group col-4">
                            <label for="">Sponsor</label>
                            <input type="text" class="form-control" value="{{p.philhealthSponsor}}">
                        </div>
                        <div class="form-group col-4">
                            <label for="">Expiration Date</label>
                            <input type="text" class="form-control" value="{{p.philhealthExpiration | date:'longDate'}}">
                        </div>
                    </div>
                    <label>Mother's Name</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="username">Last</label>
                            <input type="text" class="form-control" value="{{p.motherLastName}}">
                        </div>
                        <div class="form-group col-4">
                            <label for="">Given</label>
                            <input type="text" class="form-control" value="{{p.motherFirstName}}">
                        </div>
                        <div class="form-group col-4">
                            <label for="username">Middle</label>
                            <input type="text" class="form-control" value="{{p.motherMiddleName}}">
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="">Phone Number</label>
                            <input type="text" class="form-control" value="{{p.phoneNumber}}">
                        </div>
                    </div>
                    <div class="mb-4" *ngIf="(screening$ | async).results[0].dataModel as s">
                        <label>Screening</label>
                        <div class="form-row ml-3">
                            <div class="form-group col-4">
                                <label for="">Date of Screening</label>
                                <input type="text" class="form-control" value="{{s.dateOfScreening | date:'longDate'}}">
                            </div>
                            <div class="form-group col-4">
                                <label for="">Type of Screening</label>
                                <input type="text" class="form-control" value="{{s.typeOfScreening}}">
                                <div *ngIf="s.typeOfScreening == 'Rescreen'" class="form-group ml-3 mt-2">
                                    <label for="">Reason</label>
                                    <input type="text" class="form-control" value="{{s.typeOfScreeningIndicate}}"/>
                                </div>
                            </div>
                            <div class="form-group col-4">
                                <label for="">Method of Screening</label>
                                <input type="text" class="form-control" value="{{s.methodOfScreening}}">
                                <div *ngIf="s.methodOfScreening == 'Others'" class="form-group ml-3 mt-2">
                                    <label for="">Specify</label>
                                    <input type="text" class="form-control" value="{{s.methodOfScreeningIndicate}}"/>
                                </div>
                            </div>
                        </div>    
                        <div class="form-row ml-3">
                            <div class="form-group col-6">
                                <label for="">Result Right</label>
                                <input type="text" class="form-control" value="{{s.resultRight | titlecase}}">
                            </div>
                            <div class="form-group col-6">
                                <label for="">Result Left</label>
                                <input type="text" class="form-control" value="{{s.resultLeft | titlecase}}">
                            </div>
                        </div>           
                        <div class="form-row ml-3">
                            <div class="form-group col-6">
                                <label>Machine Results</label>
                                <textarea class="form-control" rows="9">{{s.dateOfScreening | date:'longDate'}} - {{s.timeOfScreening}}
                                </textarea>
                            </div>
                            <div class="form-group col-6">
                                <label>Risk Factors</label>
                                <textarea class="form-control" rows="9">{{
                                    (s.hyperbilirubinemia === 'Yes' ? 'Hyperbilirubinemia requiring transfusion&#13;&#10;' : '') +
                                    (s.ventilation === 'Yes' ? 'Ventilation more than 48 hours&#13;&#10;' : '') +
                                    (s.nicu === 'Yes' ? 'NICU admission more than 48 hours&#13;&#10;' : '') +
                                    (s.ototoxicMedication === 'Yes' ? 'Ototoxic medication&#13;&#10;' : '') +
                                    (s.familyHistoryHearingLoss === 'Yes' ? 'Family history of permanent childhood hearing loss&#13;&#10;' : '') +
                                    (s.craniofacialAnomalies === 'Yes' ? 'Craniofacial anomalies with deformed pinna or ear canal&#13;&#10;' : '') +
                                    (s.featuresAssociated === 'Yes' ? 'Features associated with syndrome&#13;&#10;' : '') +
                                    (s.inUteroInfections === 'Yes' ? 'In-utero infections&#13;&#10;' : '') +
                                    (s.none === 'Yes' ? 'None' : '')
                                }}</textarea>
                            </div>
                            
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6">
                                <label for="screenerName">Screener Name</label>
                                <input type="text" class="form-control" value="{{s.screenerName}}">
                            </div>
                            <!-- <div class="form-group col-4">
                                <label for="registryCardNumber">Registry Card Number</label>
                                <input type="text" class="form-control" value="{{s.registryCardNumber}}">
                            </div> -->
                            <div class="form-group col-6">
                                <label for="nhsrcSealControlNumber">NHSRC Seal Control Number</label>
                                <input type="text" class="form-control" value="{{sealNo}}">
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <!-- <div class="d-flex flex-row-reverse">
                <small>generated in <b style="pointer-events: none;"><i class="mdi mdi-ear-hearing"></i>ENNHSR</b></small>
            </div> -->
        </div>
    </div>
</div>
