import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditComponent } from './account/edit/edit.component';
import { SelectComponent } from './profile/select/select.component';
import { AddEditComponent } from './profile/add-edit/add-edit.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { AuthenticateDirective } from './authenticate/authenticate.directive';
import { AccountModule } from './account/account.module';
import { ProfileModule } from './profile/profile.module';
import { PrivacyComponent } from './authenticate/privacy/privacy.component';
import { SharedModule } from '@app/shared/shared.module';
import { dateAdapterProvider, dateformatProvider } from '@app/shared/date-format';

@NgModule({
  declarations: [ 
    AddEditComponent, 
    AuthenticateComponent, 
    AuthenticateDirective, 
    PrivacyComponent
  ],
  imports: [
    UserRoutingModule,
    SharedModule,

    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    AccountModule,
    ProfileModule
  ],
  providers: [
    dateAdapterProvider, dateformatProvider
  ]
})
export class UserModule { }
