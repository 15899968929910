import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { environment } from '@environments/environment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { FieldUpdate } from '@app/shared/_models/field-update';
import { map, mergeMap } from 'rxjs/operators';
import { UserService } from '@app/user/user.service';
import { PsgcService } from '@app/shared/address/psgc.service';

@Injectable({
  providedIn: 'root'
})
export class InterventionsService {
  private modelName = "surgicalHearingIntervention";

  public recordsSubject: BehaviorSubject<any> = new BehaviorSubject({"count":0,"offset":null,"limit":null,"results":[]});
  public records: Observable<any> = this.recordsSubject.asObservable();

  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService,
    private psgcService: PsgcService
  ) { }

  public get recordsValue() {
    return this.recordsSubject.value;
  }

  public get recordsObservable() {
    return this.records;
  }

  add(item: any){
    var guid = Utils.generateGuid()
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = item["dateOfImplantation"]
    const user = this.userService.userValue;
    var sync = Utils.convertToSync(item, this.modelName, guid, null, user.id);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      mergeMap(res => {
        return this.getById(res['insertedModelIds'][this.modelName][guid]['assignedId'])
        .pipe(
          map(newItem => {
            var records = this.recordsSubject.value;
            records['results'].splice(0,0,newItem);
            this.recordsSubject.next(records);
            return res;
          })
        );
      })
    );
  }

  getAll(id: string, page?){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?patient=${id}${page != null ? '&~page='+page : ''}`)
    .pipe(
      mergeMap(res => {
        let results = res['results']        
        var forks = results.map(x => {
          if(x.dataModel.psgcCode == null || x.dataModel.psgcCode == "" || x.dataModel.psgcCode == "None")
            return of(x);
          var psgc = Utils.psgcUtils.cleanPsgc(x.dataModel.psgcCode)
          var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x.name))]
          if(!Utils.psgcUtils.isCityMunPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x.name)))
          if(!Utils.psgcUtils.isProvPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x.name)))
          return forkJoin(psgcForks);
        })
        return forkJoin(forks)
          .pipe(
            map(fork => {
              res['results'].forEach(element => {
                var model = element.dataModel;
                if(model.psgcCode != null && model.psgcCode != "" && model.psgcCode != "None") {
                  var fullAddress = [];
                  if(model.hospitalAddress && model.hospitalAddress !== "")
                    fullAddress.push(model.hospitalAddress);
                  fullAddress = fullAddress.concat(fork[res['results'].indexOf(element)]);
                  element.dataModel['fullAddress'] = fullAddress.join(', ');
                } else {
                  element.dataModel['fullAddress'] = "No address provided"
                }
              });
              this.recordsSubject.next(res);
              return res;
            })
          )
      })
      // map(res => {
      //   this.recordsSubject.next(res);
      //   return res;
      // })
    );
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}?~resolveRef=1`);
  }

  update(item: any, modelId: string, versionId: string) {
    var original = this.recordsSubject.value['results'].find(x => x.modelId === modelId);
    item["meta"] = original["dataModel"]["meta"];
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = item["dateOfImplantation"]
    item["patient"] = original["dataModel"]["patient"];
    const user = this.userService.userValue;
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.recordsSubject.value;
        var idx = records["results"].findIndex(x => x.modelId === modelId);
        records["results"][idx].dataModel = item;
        records["results"][idx].versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        records["results"][idx].updatedBy = JSON.parse(localStorage.getItem('user')).id
        records["results"][idx].updatedByName = JSON.parse(localStorage.getItem('user')).firstName + " " + JSON.parse(localStorage.getItem('user')).lastName
        this.recordsSubject.next(records);
        return res;
      })
    );
  }

  delete(id){
    var model = this.recordsSubject.value['results'].find(x => x.modelId === id)
    let sync = new Sync();
    let field = new FieldUpdate();
    field.fieldPath = "meta.voided";
    field.value = true;
    field.modelName = this.modelName;
    field.modelId = id;
    field.dateUpdated = new Date();
    field.dateSynced = null;
    field.userId = JSON.parse(localStorage.getItem('user')).id;
    sync.fieldUpdates.push(field);
    let token = {};
    token[this.modelName] = {};
    token[this.modelName][id] = model.versionId;
    sync.syncTokens = token;
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.recordsSubject.value;
        records['results'].splice(records['results'].findIndex(x => x.modelId === model.modelId),1);
        this.recordsSubject.next(records);
        return res;
      })
    );
  }
}
