<div class="container">
    <div *ngIf="!isDesktop" class="card card-body m-3">
        <div class="form-row align-items-center">
            <div class="col-auto">
                <i class="mdi mdi-folder-account mr-2"></i> Enter Patient Number
            </div>
            <div class="col-auto">
                <input class="form-control" type="text" #serial>
            </div>
            <div class="col-auto">
                <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap" (click)="loadPatient(serial.value)">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Load Patient
                </button>
            </div>
        </div>
    </div>
    <app-patient-details></app-patient-details>
</div>
