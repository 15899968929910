import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class TotalLiveBirthsScreenedReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Live Births'
    })
    totalLiveBirth: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Number of Babies Screened'
    })
    noOfBabiesScreened: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Babies Screened %'
    })
    noOfBabiesScreenedPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Screened Using Philhealth'
    })
    screenedUsingPhilhealth: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Screened Using Philhealth %'
    })
    screenedUsingPhilhealthPercent: string;
}
