import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { debounceTime, first, map, startWith, switchMap } from 'rxjs/operators';
import { InterventionsService } from '../interventions.service';
import { PrintService } from '@app/shared/print/print.service';
import { Observable, of } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ElementRef } from '@angular/core';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { PatientService } from '@app/patient/patient.service';

@Component({ template: '' })
export class AddEditInterventionComponent implements OnInit {
    
  constructor(public dialog: MatDialog,
    private location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
      this.openDialog();
  }

  openDialog(): void {
      let id = this.route.snapshot.params['id']
      const dialogRef = this.dialog.open(AddEditInterventionComponentDialog, {
          data: {
              formBuilder: this.formBuilder,
              id: this.route.snapshot.params['id']
          }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.location.back();
      });
  }

}

@Component({ templateUrl: 'add-edit-intervention.component.html' })
export class AddEditInterventionComponentDialog implements OnInit {
    
  form: FormGroup;
  loading = false;
  submitted = false;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  isAddMode: any;

  id: string;
  patientId: string;
  modelId: string;
  versionId: string;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  diagnosis = new FormControl();
  filteredDiagnosis: Observable<string[]>;
  diagnosisICD: string[] = []
  allDiagnosis: string[] = ['NTHC', 'NHSRC', 'PGH - Ear Unit'];

  diagnosisOptions$: Observable<string[]>;

  @ViewChild('diagnosisInput') diagnosisInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  separatorKeysCodesRVSFirst: number[] = [ENTER, COMMA];
  rvsFirst = new FormControl();
  filteredRVSFirst: Observable<string[]>;
  rvsFirstList: string[] = []
  allRVSFirst: string[] = ['NTHC', 'NHSRC', 'PGH - Ear Unit'];

  rvsFirstOptions$: Observable<string[]>;

  @ViewChild('rvsFirstInput') rvsFirstInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoRVSFirst') matAutocompleteRVSFirst: MatAutocomplete;

  separatorKeysCodesRVSSecond: number[] = [ENTER, COMMA];
  rvsSecond = new FormControl();
  filteredRVSSecond: Observable<string[]>;
  rvsSecondList: string[] = []
  allRVSSecond: string[] = ['NTHC', 'NHSRC', 'PGH - Ear Unit'];

  rvsSecondOptions$: Observable<string[]>;

  @ViewChild('rvsSecondInput') rvsSecondInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoRVSSecond') matAutocompleteRVSSecond: MatAutocomplete;
  
  constructor(        
      public dialogRef: MatDialogRef<AddEditInterventionComponentDialog>,        
      @Inject(MAT_DIALOG_DATA) public data: {
          formBuilder: FormBuilder,
          id: string
      },
      public dialog: MatDialog,
      private interventionsService: InterventionsService, 
      private formBuilder: FormBuilder, 
      private alertService: AlertService,
      public printService: PrintService,
      public ennhsrService: EnnhsrService,
      private patientService: PatientService
  ) { 
    this.id = data.id;
    this.filteredDiagnosis = this.diagnosis.valueChanges.pipe(
      startWith(null),
      map((diagnosis: string | null) => diagnosis ? this._filter(diagnosis) : this.allDiagnosis.slice()));
    this.filteredRVSFirst = this.rvsFirst.valueChanges.pipe(
      startWith(null),
      map((rvsFirst: string | null) => rvsFirst ? this._filter(rvsFirst) : this.allRVSFirst.slice()));
    this.filteredRVSSecond = this.rvsSecond.valueChanges.pipe(
      startWith(null),
      map((rvsSecond: string | null) => rvsSecond ? this._filter(rvsSecond) : this.allRVSSecond.slice()));
  }

    ngOnInit() {
      this.patientId = this.patientService.recordSubject.getValue()["modelId"];

      this.isAddMode = !this.id;
  
      this.editable = this.id? true:false;
      this.editing = false;
      this.printable = true;
  
      this.form = this.formBuilder.group({
        patient: [''],
        dateOfImplantation: ['', Validators.required],
        hospitalName: ['', Validators.required],
        hospitalAddress: [''],
        psgcCode: [''],
        surgeonsName: ['', Validators.required],
        clinicalAudiologistsName: [''],
        laterality: [''],
        type: [''],
        model: [''],
        serialNumber: [''],
        diagnosis: [''],
        firstCaseRate: [''],
        secondCaseRate: [''],
        procedureFirst: [''],
        procedureSecond: [''],
        withComplication: [''],
        timing: [''],
        /* w24description: [''],
        w7description: [''],
        w1description: [''],
        w3description: [''],
        w6description: [''],
        g6description: [''], */
        description: [''],
        surgeonsNotes: [''],
        uploadOrTechnique: [''],
      });
  
      if (!this.isAddMode) {
        var model = this.interventionsService.recordsValue["results"].find(x => x.modelId == this.id);
        this.versionId = model["versionId"];
        this.modelId = model["modelId"];
        this.form.patchValue(Utils.mapModelToForm(model, ['dateOfImplantation']));
        this.diagnosisICD = model["dataModel"]["diagnosis"]//.substring(1, model["dataModel"]["diagnosis"].length-1).split(", ");
      }

      this.diagnosisOptions$ = this.diagnosis.valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => {
          if (value !== '') {
            return this.search(value);
          }
          return of(null);
        })
      );

      this.rvsFirstOptions$ = this.form.controls['firstCaseRate'].valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => {
          if (value !== '') {
            return this.searchRVSFirst(value);
          }
          return of(null);
        })
      );

      this.rvsSecondOptions$ = this.form.controls['secondCaseRate'].valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => {
          if (value !== '') {
            return this.searchRVSSecond(value);
          }
          return of(null);
        })
      );
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
      this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createIntervention();
        } else {
            this.updateIntervention();
        }
    }

  createIntervention() {
    this.form.get("patient").setValue(this.patientId)
    this.form.controls['diagnosis'].setValue(this.diagnosisICD);
    this.interventionsService.add(this.form.value)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Intervention added successfully', { keepAfterRouteChange: true });
                this.dialogRef.close();
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
  }
    
  updateIntervention() {
    this.form.controls['diagnosis'].setValue(this.diagnosisICD);
    this.interventionsService.update(this.form.value, this.modelId, this.versionId)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Update successful', { keepAfterRouteChange: true });
                this.dialogRef.close();
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.diagnosisICD.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.diagnosis.setValue(null);
  }

  remove(diagnosis: string): void {
    const index = this.diagnosisICD.indexOf(diagnosis);

    if (index >= 0) {
      this.diagnosisICD.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.diagnosisICD.push(event.option.viewValue);
    this.diagnosisInput.nativeElement.value = '';
    this.diagnosis.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allDiagnosis.filter(diagnosis => diagnosis.toLowerCase().indexOf(filterValue) === 0);
  }

  search(name) {
    return this.ennhsrService.icd(name).pipe()
  }

  //RVS First Case Rate

  selectedRVSFirst(event: MatAutocompleteSelectedEvent): void {

    var firstCase = event.option.viewValue;
    firstCase = firstCase.substring(0, firstCase.indexOf('- '))

    var proFirst  = event.option.viewValue;
    proFirst = proFirst.substring(proFirst.indexOf('-') + 1)

    this.form.controls['firstCaseRate'].setValue(firstCase.trim());
    this.form.controls['procedureFirst'].setValue(proFirst.trim());
  }

  searchRVSFirst(name) {
    return this.ennhsrService.rvsFirst(name).pipe()
  }

  //RVS Second Case Rate

  selectedRVSSecond(event: MatAutocompleteSelectedEvent): void {

    var secondCase = event.option.viewValue;
    secondCase = secondCase.substring(0, secondCase.indexOf('- '))

    var halfprice = parseFloat(secondCase.substring(secondCase.indexOf('PHP ') + 4))/2;
    var caseCode = secondCase.substring(0, secondCase.indexOf('P ') + 1)
    var proSecond  = event.option.viewValue;
    proSecond = proSecond.substring(proSecond.indexOf('-') + 1)

    this.form.controls['secondCaseRate'].setValue(caseCode + " " + halfprice.toFixed(2).toString());
    this.form.controls['procedureSecond'].setValue(proSecond.trim());
  }

  searchRVSSecond(name) {
    return this.ennhsrService.rvsSecond(name).pipe()
  }
}
