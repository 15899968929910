import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class NoOfScreeningFacilitiesByRegionReport {
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'National Capital Region (NCR)'
    // })
    // ncr: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Cordillera Administrative Region (CAR)'
    // })
    // car: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: ' Region I (ILOCOS REGION)'
    // })
    // region1: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: ' REGION II (CAGAYAN VALLEY)'
    // })
    // region2: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'REGION III (CENTRAL LUZON)'
    // })
    // region3: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: ' REGION IV-A (CALABARZON)'
    // })
    // region4A: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'MIMAROPA REGION'
    // })
    // mimaropa: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'REGION V (BICOL REGION)'
    // })
    // region5: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: ' REGION VI (WESTERN VISAYAS)'
    // })
    // region6: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: ' Region VII (CENTRAL VISAYAS)'
    // })
    // region7: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Region VIII (EASTERN VISAYAS)'
    // })
    // region8: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Region IX (ZAMBOANGA PENINSULA)'
    // })
    // region9: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Region X (NORTHERN MINDANAO)'
    // })
    // region10: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Region XI (DAVAO REGION)'
    // })
    // region11: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Region XII (SOCCSKSARGEN)'
    // })
    // region12: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Region XIII (Caraga)'
    // })
    // region13: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)'
    // })
    // armm: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Region'
    })
    region: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Count'
    })
    count: number;
    @autoserializeAs(String)
    @Column({
        columnName: 'Percent'
    })
    percent: number;
}
