<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Acoustic Reflex Test
                <button type="button" (click)="this.printService.print(['details', print], 'Acoustic Reflex Test')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="dateOfTest">Date of Test</label>
                            <div>
                                <input matInput [matDatepicker]="picker" formControlName="dateOfTest" [ngClass]="{ 'is-invalid': submitted && f.dateOfTest.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <div *ngIf="submitted && f.dateOfTest.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfTest.errors.required">Date of Test is required</div>
                                </div>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                        <div class="form-group col-8">
                            <label for="nameOfTester">Name of Tester</label>
                            <input type="text" formControlName="nameOfTester" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameOfTester.errors }" />
                            <div *ngIf="submitted && f.nameOfTester.errors" class="invalid-feedback">
                                <div *ngIf="f.nameOfTester.errors.required">Name of Tester is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="ipsiLeft">Ipsi Left</label>
                            <select class="form-control" formControlName="ipsiLeft">
                                <option disabled selected>Choose...</option>
                                <option>Normal</option>
                                <option>Lowered</option>
                                <option>Elevated</option>
                                <option>Absent</option>
                            </select>
                        </div>
                        <div class="form-group col-6">
                            <label for="contraLeft">Contra Left</label>
                            <select class="form-control" formControlName="contraLeft">
                                <option disabled selected>Choose...</option>
                                <option>Normal</option>
                                <option>Lowered</option>
                                <option>Elevated</option>
                                <option>Absent</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="ipsiRight">Ipsi Right</label>
                            <select class="form-control" formControlName="ipsiRight">
                                <option disabled selected>Choose...</option>
                                <option>Normal</option>
                                <option>Lowered</option>
                                <option>Elevated</option>
                                <option>Absent</option>
                            </select>
                        </div>
                        <div class="form-group col-6">
                            <label for="contraRight">Contra Right</label>
                            <select class="form-control" formControlName="contraRight">
                                <option disabled selected>Choose...</option>
                                <option>Normal</option>
                                <option>Lowered</option>
                                <option>Elevated</option>
                                <option>Absent</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="notes">Notes</label>
                            <input type="text" formControlName="notes" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="uploadFile">Upload</label>
                            <input type="file" formControlName="uploadFile" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>