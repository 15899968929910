import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  
  private printSubject = new Subject<any>();

  onPrint() {
    return this.printSubject.asObservable();
  }

  print(body: any[], title?: string) {
    this.printSubject.next({
      body: body,
      title: title
    })
  }
}
