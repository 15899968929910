import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boyGirl'
})
export class BoyGirlPipe implements PipeTransform {

  transform(value: string, args: string[]): unknown {
    if (!value) 
      return value;
		return value === 'male' ? 'boy' : value === 'female' ? 'girl' : '';
  }

}
