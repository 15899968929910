import { Pipe, PipeTransform } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PsgcService } from './psgc.service';

@Pipe({
  name: 'displayPsgc'
})
export class DisplayPsgcPipe implements PipeTransform {

  constructor(private psgcService: PsgcService) {}

  transform(value: string, args: string[]): Observable<string> {
    if (!value  || isNaN(parseInt(value)) || value.length < 9) 
      return of("no address");
    var forks = [this.psgcService.get(value)];
    if(value.substring(6) !== '000')
      forks.push(this.psgcService.get(value.substring(0,6)+'000'));
    if(value.substring(4,6) !== '00')
      forks.push(this.psgcService.get(value.substring(0,4)+'00000'));
    if(value.substring(2,4) !== '00')
      forks.push(this.psgcService.get(value.substring(0,2)+'0000000'));
    return forkJoin(forks).pipe(map(res => res.map(e => e.name).join(", ")))
  }
}
