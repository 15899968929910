import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-time',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true
  }]
})
export class TimePickerComponent implements ControlValueAccessor {
  
  valueHrs: string;
  valueMins: string;
  valueMeridian: string;
  onChange: (e: any) => void;
  onTouched: () => void;
  disabled: boolean;

  constructor() {}

  writeValue(value: string): void {
    this.valueHrs = value.split(' ')[0].split(':')[0] || "";
    this.valueMins = value.split(' ')[0].split(':')[1] || "";
    this.valueMeridian = value.split(' ')[1] || "";
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  
  update(valueHrs, valueMins, valueMeridian) {
    var val = `${valueHrs}:${valueMins} ${valueMeridian}`
    this.writeValue(val);
    this.onChange(val);
  }
}
