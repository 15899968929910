import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertService } from '@app/shared/alert/alert.service';
import { ProcessingSealDistributionService } from '../processing-seal-distribution.service';
import { SealsService } from '../../seals.service';

@Component({ template: '' })
export class AddEditSealComponent implements OnInit {
    constructor(public dialog: MatDialog,
        private location: Location,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.openDialog();
    }

    openDialog(): void {
        let id = this.route.snapshot.params['id']
        const dialogRef = this.dialog.open(AddEditSealComponentDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: id,
                isAddMode: !id
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.location.back();
        });
    }
}

@Component({ templateUrl: 'add-edit-seal.component.html' })
export class AddEditSealComponentDialog implements OnInit {

    form: FormGroup;
    loading = false;
    submitted = false;
    allSeals$ : Observable<any>;
    private sealDistribution: string;

    constructor(        
        private processingSealDistributionService: ProcessingSealDistributionService,
        private sealsService: SealsService,
        private alertService: AlertService,
        public dialogRef: MatDialogRef<AddEditSealComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder, 
            id: string,
        }
    ) {
        this.sealDistribution = data.id;
     }

    ngOnInit() {
        //this.loadSeals();
        this.form = this.data.formBuilder.group({
            batchCode: ['',Validators.required],
            serialNumber: ['',Validators.required],
            rangeFrom: ['',Validators.required],
            rangeTo: ['',Validators.required],
            single: ['']
        });

        this.form.get("serialNumber").disable();//since default is multiple, disable input for single to remove validators

        this.form.get("single").valueChanges.subscribe((single) => {
            this.form.get("serialNumber").disable();
            this.form.get("rangeFrom").disable();
            this.form.get("rangeTo").disable();
            if(single == true) {
                this.form.get("serialNumber").enable();
                this.form.get("rangeFrom").setValue(null);
                this.form.get("rangeTo").setValue(null);
            } else if (single == false) {
                this.form.get("rangeFrom").enable();
                this.form.get("rangeTo").enable();
                this.form.get("serialNumber").setValue(null);
            }
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        this.alertService.clear();
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        
        this.validateSeals(this.form.value)
    }

    validateSeals(item) {
        if(item["rangeFrom"] && (item["rangeFrom"] > item["rangeTo"])){
            this.alertService.error("Invalid seal range");
            this.loading = false;
            return;
        }
        //let allSeals;
        //this.allSeals$.pipe(first()).subscribe(value => allSeals = value)
        //var duplicate = Utils.checkIfSealExists(item,allSeals)
        //if(duplicate.length){
        //    this.alertService.error("Seal(s): " + duplicate.toString() + " already assigned");
        //    this.loading = false;
        //    return;
        //}
        this.createSeal()   
    }
    //private loadSeals() {
    //    this.loading = true;
    //    this.alertService.info('Loading seals');
    //    this.sealsService.getAllAbsolute()
    //        .pipe(first())
    //            .subscribe({
    //                next: () => {
    //                    this.alertService.info('Seal(s) loaded successfully', { keepAfterRouteChange: true });
    //                    this.loading = false;
    //                    this.allSeals$ = this.sealsService.allRecordsObservable
    //                },
    //                error: error => {
    //                    this.alertService.error(error);
    //                    this.loading = false;
    //                    this.dialogRef.close()
    //                }
    //            });
    //        
    //}
    private createSeal() {
      this.processingSealDistributionService.addSeals(this.form.value,this.sealDistribution)
         .pipe(first())
          .subscribe({
              next: () => {
                  this.alertService.success('Seal(s) added successfully', { keepAfterRouteChange: true });
                  this.dialogRef.close()
              },
              error: error => {
                  this.alertService.error(error);
                  this.loading = false;
              }
          });
  }
}
