import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class RiskFactorAggregateReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Risk Factor'
    })
    riskFactors: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Category A'
    })
    categoryA: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'A %'
    })
    categoryAPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Category B'
    })
    categoryB: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'B %'
    })
    categoryBPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Category C'
    })
    categoryC: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'C %'
    })
    categoryCPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Category D'
    })
    categoryD: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'D %'
    })
    categoryDPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Total'
    })
    total: string;
}
