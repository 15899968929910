import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ReportsComponent } from './reports/reports.component';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from '@app/shared/shared.module';

import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { ReportsModule } from './reports/reports.module';

import { HttpClientModule } from "@angular/common/http";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { TableComponent } from './reports/table/table.component';

import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    AnalyticsComponent, 
    ReportsComponent, 
    LayoutComponent, TableComponent
  ],
  imports: [
    DashboardRoutingModule,
    SharedModule,

    ReportsModule,

    CommonModule,
    FormsModule,

    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    ReactiveFormsModule,

    HttpClientModule,
    MatTableModule,
    MatSortModule,

    MatRadioModule,
  ],
  providers: [],
  bootstrap: [ReportsComponent],
})
export class DashboardModule { }
