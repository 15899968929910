import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class ActiveUsersReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Last Name'
    })
    lastName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'First Name'
    })
    firstName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Middle Name'
    })
    middleName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Accreditation Validity'
    })
    accreditationValidity: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Assigned Facility'
    })
    assignedFacility: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Role'
    })
    role: string;
}
