import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {

  constructor(
    public http: HttpClient
  ) { }

  update(announcement: any) {
    return this.http.put(`${environment.apiUrl}/dashboard/announcement`, announcement)
    .pipe(x => {
      return x;
    });
  }

}
