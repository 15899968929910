import { animate, animateChild, group, query, stagger, style, transition, trigger } from '@angular/animations';

export const navAnimations = 
  trigger('navAnimations', [
    transition(':enter',[
      style({ top: '-56px' }),
      animate(150, style({ top: '0' }))
    ]),
    transition(':leave',[
      style({ top: '0'}),
      animate(150, style({ top: '-56px' }))
    ])
  ]);

export const routeAnimations =
  trigger('routeAnimations', [
    transition('* => *', [
      query(':enter', 
        [
          style({ opacity: 0 })
        ], 
        { optional: true }
      ),
      query(':leave', 
        [
          animateChild(), 
          style({ opacity: 1 }),
          animate(150, style({ opacity: 0 }))
        ], 
        { optional: true }
      ),
      query(':enter', 
        [
          style({ opacity: 0 }),
          animate(150, style({ opacity: 1 })),
          animateChild()
        ], 
        { optional: true }
      )
    ])
  ]);