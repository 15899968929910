<div class="card my-3">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card-header d-flex justify-content-between">
            <span>Speech Therapy</span>
            <div *ngIf="validatePrivilege()">
                <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" (click)="printService.print(['details', print], 'Speech Therapy')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
                <button type="button" *ngIf="this.editable && !this.editing && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
                <button type="submit" *ngIf="this.editable && this.editing" class="btn btn-success ml-1 px-2 py-0"><i class="mdi mdi-content-save"></i></button>
                <button type="button" *ngIf="this.editable && this.editing" (click)="cancelEdit()" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
            </div>
        </div>
        <div class="card-body" #print>   
            <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
                <label>Speech Pathology Sessions</label>
                <app-table [modelName]="'speechPathologySession'" [isWriteMode]="this.editing" [data]="speechPathologySession$ | async" [pageData]="speechPathologySessionPage$ | async" [printTitle]="'Speech Pathology Sessions'" [columns]="columnsSPS" [addLink]="'speech-therapy/add-speech-pathology-session'" [editLink]="'speech-therapy/edit-speech-pathology-session'" (pageEvent)="getPage($event)" (deleteRowEvent)="deleteSpeechPathologySession($event)"></app-table>
                <label>Educational Placement</label>
                <div class="form-row ml-3">
                    <div class="form-group col-4">
                        <mat-checkbox #mainstream formControlName="educationalPlacementMainstream">
                            <label class="form-check-label" for="educationalPlacementMainstream">
                                Mainstream
                            </label>
                        </mat-checkbox>
                    </div>
                    <div *ngIf="mainstream.checked" class="form-group col-4">
                        <mat-radio-group formControlName="mainstream">
                            <mat-radio-button class="mr-3" [value]="'full'">Total</mat-radio-button>
                            <mat-radio-button class="mr-3" [value]="'partial'">Partial</mat-radio-button>
                        </mat-radio-group>
                        <br>
                        <label form="mainstreamSpecify">Specify</label>
                        <input type="text" formControlName="mainstreamSpecify" class="form-control"/>
                    </div>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-4">
                        <mat-checkbox #specialEducation formControlName="educationalPlacementSpecialEducation">
                            <label class="form-check-label" for="educationalPlacementSpecialEducation">
                                Special education
                            </label>
                        </mat-checkbox>
                    </div>
                    <div *ngIf="specialEducation.checked" class="form-group col-4">
                        <label for="specialEducationSpecify">Specify</label>
                        <input type="text" formControlName="specialEducationSpecify" class="form-control"/>
                    </div>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-4">
                        <mat-checkbox #alternativeLearningSystem formControlName="educationalPlacementALS">
                            <label class="form-check-label" for="educationalPlacementALS">
                                Alternative Learning System
                            </label>
                        </mat-checkbox>
                    </div>
                    <div *ngIf="alternativeLearningSystem.checked" class="form-group col-4">
                        <label for="alsSpecify">Specify</label>
                        <input type="text" formControlName="alsSpecify" class="form-control"/>
                    </div>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-4">
                        <mat-checkbox #na formControlName="educationalPlacementNa">
                            <label class="form-check-label" for="educationalPlacementNa">
                                Not Applicable
                            </label>
                        </mat-checkbox>
                    </div>
                    <div *ngIf="na.checked" class="form-group col-4">
                        <label for="naReason">Reason</label>
                        <input type="text" formControlName="naReason" class="form-control"/>
                    </div>
                </div>
                <label for="uploadFile">Upload</label>
                <!-- <div *ngIf="form.value.uploadFile" class="form-row ml-3">
                    <div class="form-group">
                        <a role="button" (click)="download(form.value.uploadFile)"><u>click to download file</u></a>
                    </div>
                </div> -->
                <div class="form-row ml-3">
                    <div class="form-group">
                        <!-- <input type="file" id="file" class="form-control" (change)="handleFileInput($event.target.files)"/> -->
                        <input-file formControlName="uploadFile" (change)="handleFileInput($event.target.files)"></input-file>
                        <!-- <input type="file" id="file" class="form-control" accept="application/pdf,image/jpeg" (change)="handleFileInput($event.target.files)"/> -->
                    </div>
                </div>
            </fieldset>
        </div> 
    </form>
</div>