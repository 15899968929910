import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectorService {
  private subject = new Subject<number>();

  constructor() { }

  onControl(): Observable<number> {
    return this.subject.asObservable();
  }

  scroll() {
    this.subject.next(2);
  }

  reset() {
    this.subject.next(-1);
  }

  enable() {
    this.subject.next(1);
  }

  disable() {
    this.subject.next(0);
  }
  
}
