<div class="card my-3">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card-header d-flex justify-content-between">
            <span>Cochlear Implant Programming</span>
            <div *ngIf="validatePrivilege()">
                <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" (click)="printService.print(['details', print], 'Cochlear Implant Programming')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
                <button type="button" *ngIf="this.editable && !this.editing && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
                <button type="submit" *ngIf="this.editable && this.editing" class="btn btn-success ml-1 px-2 py-0"><i class="mdi mdi-content-save"></i></button>
                <button type="button" *ngIf="this.editable && this.editing" (click)="cancelEdit()" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
            </div>
        </div>
        <div #print class="card-body">   
            <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
                <div class="form-row">
                    <div class="form-group col-12">
                        <mat-checkbox formControlName="surgeryOutside" class="mr-3">Surgery done outside Institution</mat-checkbox>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                        <label>Intra-Op Telemetry</label>
                        <app-table [modelName]="'intraOpTelemetry'" [isWriteMode]="this.editing" [data]="intraOpTelemetry$ | async" [pageData]="intraOpTelemetryPage$ | async" [printTitle]="'Intra-Op Telemetry'" [columns]="columnsIOT" [addLink]="'cochlear-implant-programming/add-intra-op-telemetry'" [editLink]="'cochlear-implant-programming/edit-intra-op-telemetry'" (pageEvent)="getPageIntraOpTelemetry($event)" (deleteRowEvent)="deleteIntraOpTelemetry($event)"></app-table>
                    </div>
                </div>
                <label>Switch-on</label>
                <div class="form-row ml-3">
                    <div class="form-group col-6 d-flex justify-content-center">
                        <label class="form-check-label" for="">
                            Left Ear
                        </label>
                    </div>
                    <div class="form-group col-6 d-flex justify-content-center">
                        <label class="form-check-label" for="">
                            Right Ear
                        </label>
                    </div>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-6">
                        <label for="dateLeftEar">Date</label>
                        <div class="form-group col-8">
                            <input matInput [matDatepicker]="picker" formControlName="dateLeftEar" [ngClass]="{ 'is-invalid': submitted && f.dateLeftEar.errors }">
                            <!-- <div *ngIf="submitted && f.dateLeftEar.errors" class="invalid-feedback">
                                <div *ngIf="f.dateLeftEar.errors.required">Date is required</div>
                            </div> -->
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <i class="mdi mdi-calendar"></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label for="dateRightEar">Date</label>
                        <div class="form-group col-8">
                            <input matInput [matDatepicker]="picker2" formControlName="dateRightEar" [ngClass]="{ 'is-invalid': submitted && f.dateRightEar.errors }">
                            <!-- <div *ngIf="submitted && f.dateRightEar.errors" class="invalid-feedback">
                                <div *ngIf="f.dateRightEar.errors.required">Date is required</div>
                            </div> -->
                            <mat-datepicker-toggle matSuffix [for]="picker2">
                                <i class="mdi mdi-calendar"></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </div>
                    </div>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-6">
                        <label for="brandLeft">Brand</label>
                        <div>
                            <mat-radio-group class="d-flex flex-column" formControlName="brandLeft">
                                <mat-radio-button class="mr-3" [value]="'Cochlear'">Cochlear</mat-radio-button>
                                <mat-radio-button [value]="'MED-EL'">MED-EL</mat-radio-button>
                                <mat-radio-button #brandLeftOthersPostOp [value]="'Other'">Other</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label for="brandRight">Brand</label>
                        <div>
                            <mat-radio-group class="d-flex flex-column" formControlName="brandRight">
                                <mat-radio-button class="mr-3" [value]="'Cochlear'">Cochlear</mat-radio-button>
                                <mat-radio-button [value]="'MED-EL'">MED-EL</mat-radio-button>
                                <mat-radio-button #brandRightOthersPostOp [value]="'Other'">Other</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-6">
                        <div *ngIf="brandLeftOthersPostOp.checked">
                            <label for="brandLeftOthersPostOp">Specify Brand on Left Ear</label>
                            <input type="text" formControlName="brandLeftOthersPostOp" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <div *ngIf="brandRightOthersPostOp.checked">
                            <label for="brandRightOthersPostOp">Specify Brand on Right Ear</label>
                            <input type="text" formControlName="brandRightOthersPostOp" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-6">
                        <label for="processorLeft">Processor</label>
                        <input type="text" formControlName="processorLeft" class="form-control"/>
                    </div>
                    <div class="form-group col-6">
                        <label for="processorRight">Processor</label>
                        <input type="text" formControlName="processorRight" class="form-control"/>
                    </div>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-12">
                        <label for="additionalNotes">Additional Notes</label>
                        <textarea class="form-control" rows="6" formControlName="additionalNotes"></textarea>
                    </div>
                </div>
                <div class="form-group">
                    <label>Sessions</label>
                    <app-table [modelName]="'sessions'" [isWriteMode]="this.editing" [printTitle]="'Cochlear Implant Programming Sessions'" [data]="sessions$ | async" [pageData]="sessionsPage$ | async" [columns]="columnsSessions" [addLink]="'cochlear-implant-programming/add-sessions'" [editLink]="'cochlear-implant-programming/edit-sessions'" (pageEvent)="getPageSessions($event)" (deleteRowEvent)="deleteSessions($event)"></app-table>
                </div>
                <div class="form-group ml-3">
                    <label>Aided Tests</label>
                    <app-table [isWriteMode]="false" [noAdd]="true" [data]="sessions$ | async" [pageData]="sessionsPage$ | async" [printTitle]="'Cochlear Implant Programming Aided Tests'" [columns]="columnsAT" [addLink]="'cochlear-implant-programming/add-aided-testing'" [editLink]="'cochlear-implant-programming/edit-aided-testing'" (pageEvent)="getPageSessions($event)" (deleteRowEvent)="deleteRecord($event)"></app-table>
                </div>
            </fieldset>
        </div> 
    </form>
</div>