<div *ngIf="isLoading" class="p-3">
    <!-- <div [style.width]="'200px'" class="d-flex justify-content-center">
        <mat-spinner [diameter]="25"></mat-spinner>
    </div> -->
    Loading...
    <mat-progress-bar *ngIf="isLoading" color="warn" mode="determinate" [value]="progress/progressTotal*100"></mat-progress-bar>
</div>

<div class="position-absolute p-3">
    <div style="width: fit-content;">
        <div *ngIf="sealInTransit$ | async as sealInTransit">
            <div *ngIf="sealInTransit.results.length > 0; else zeroShipping" class="d-flex w-100 flex-wrap">
                
                <div *ngFor="let request of sealInTransit.results">
                    <div class="card card-body d-flex p-3 mb-3 mr-3">
                       <small>{{request.dataModel.referenceID | shortId | uppercase }}</small>
                       <small><b>{{request.dataModel.dateCreated | date}}</b></small>
                       <h5 class="mr-2 mb-0"><b>{{request.dataModel.facility.facilityName}}</b></h5>
                       <span class="mb-3"><!-- <i class="mdi mdi-home mr-1"></i>{{request.dataModel.facility.fullAddress}} --></span>
                       <span class="mb-2">Seals:</span>
                       <app-table [data]="request.dataModel.sealsData" [columns]="columns" [isWriteMode]="false" [forChangeHistory]="true"></app-table>
                       <div class="ml-auto d-flex align-items-center">
                            <a class="btn btn-danger ml-auto" (click)="cancelShipping(request.modelId)">Cancel Shipping</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #zeroShipping>
            <div style="width: fit-content;" class="text-danger">
                No request in transit
            </div>
        </ng-template>
    </div>
</div>

