import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ComplicationsOutsideSurgeryService } from './complications-outside-surgery.service';
import { ExplantedDeviceService } from './explanted-device.service';

@Component({
  selector: 'app-surgical-hearing-interventions-events',
  templateUrl: './surgical-hearing-interventions-events.component.html',
  styleUrls: ['./surgical-hearing-interventions-events.component.scss']
})
export class SurgicalHearingInterventionsEventsComponent implements OnInit {

  columnsCOS = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Complications'},
    {name: 'Complications Specify', prop: 'complicationsSpecify'},
    {name: 'Hospitalized or managed in OPD', prop: 'hospitalizedOrOPD'},
    {name: 'Notes'},
  ]

  columnsExplantedDevice = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of First Implantation', prop: 'dateOfFirstImplantation'},
    {name: 'Explanted Device'},
    {name: 'Explanted Device Others', prop: 'explantedDeviceSpecify'},
    {name: 'Type and Serial Number'},
    {name: 'Reason for Explantation (Infection)', prop: 'reasonForExplantationInfection'},
    {name: 'Reason for Explantation (Device Failure)', prop: 'reasonForExplantationDeviceFailure'},
    {name: 'Reason for Explantation (Others)', prop: 'reasonForExplantationOthers'},
    {name: 'Device Failure', prop: 'deviceFailure'},
    {name: 'Others', prop: 'othersSpecify'}
  ]

  dateControlNames = ['dateOfFirstImplantation'];
  complicationsOutsideSurgery$ : Observable<any>;
  complicationsOutsideSurgeryPage$ : Observable<any>;
  explantedDevice$ : Observable<any>;
  explantedDevicePage$ : Observable<any>;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;

  id: string;

  constructor(
    private complicationsOutsideSurgeryService: ComplicationsOutsideSurgeryService,
    private explantedDeviceService: ExplantedDeviceService,
    private route: ActivatedRoute,
    private userService: UserService,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.editable = true;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )

    this.complicationsOutsideSurgeryService.getAll(this.id, 0).subscribe();
    
    this.complicationsOutsideSurgery$ = this.complicationsOutsideSurgeryService.recordsObservable.pipe(
      map(complicationsOutsideSurgery => complicationsOutsideSurgery["results"].map(x => Utils.mapModelToColumns(x)))
    )
    
    this.complicationsOutsideSurgeryPage$ = this.complicationsOutsideSurgeryService.recordsObservable
    
    this.explantedDeviceService.getAll(this.id, 0).subscribe();
    
    this.explantedDevice$ = this.explantedDeviceService.recordsObservable.pipe(
      map(explantedDevice => explantedDevice["results"].map(x => Utils.mapModelToColumns(x, this.dateControlNames)))
    )

    this.explantedDevicePage$ = this.explantedDeviceService.recordsObservable
  }

  getPageComplicationsOutsideSurgery(page: string) {
    this.complicationsOutsideSurgeryService.getAll(this.id, page).subscribe();
  }

  getPageExplantedDevice(page: string) {
    this.explantedDeviceService.getAll(this.id, page).subscribe();
  }

  deleteCOS(id: string) {
    this.complicationsOutsideSurgeryService.delete(id).pipe(first())
        .subscribe();
  }

  deleteExplantedDevice(id: string) {
    this.explantedDeviceService.delete(id).pipe(first())
        .subscribe();
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }

}
