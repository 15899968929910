<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Hearing Aid/Device Record
                <button type="button" (click)="this.printService.print(['details', print], 'Hearing Aid/Device Record')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group">
                            <mat-checkbox #notDone formControlName="notDone">
                                <label class="form-check-label" for="notDone">
                                    Not Done?
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="notDone.checked">
                        <div class="form-group col-4">
                            <div class="form-group">
                                <label for="notDoneReason">Select Reason</label>
                                <select class="form-control" formControlName="notDoneReason">
                                    <option>Meningitis</option>
                                    <option>Atresia</option>
                                    <option>Others</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="indicateEar">Indicate if Right or Left ear</label><br>
                            <mat-radio-group formControlName="indicateEar">
                                <mat-radio-button class="mr-3" [value]="'right'">Right Ear</mat-radio-button>
                                <mat-radio-button [value]="'left'">Left Ear</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-8">
                            <label for="hearingAidTrial">Hearing Aid Trial</label>
                            <input type="text" class="form-control" formControlName="hearingAidTrial" />
                        </div>
                    </div>
                    <label>Fitting</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="dateOfFitting">Date of Current Fitting</label>
                            <div>
                                <input matInput [matDatepicker]="picker" formControlName="dateOfFitting" [ngClass]="{ 'is-invalid': submitted && f.dateOfFitting.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <div *ngIf="submitted && f.dateOfFitting.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfFitting.errors.required">Date of Current Fitting is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="earAmplified">Ear Amplified</label>
                            <div>
                                <mat-radio-group formControlName="earAmplified">
                                    <mat-radio-button class="mr-3" [value]="'right'">Right Ear</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'left'">Left Ear</mat-radio-button>
                                    <!-- <mat-radio-button [value]="'same'">Both Ears</mat-radio-button> -->
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <label>Device</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-3">
                            <label for="typeOfHearingAid">Type of Hearing Aid</label>
                            <div>
                                <mat-radio-group formControlName="typeOfHearingAid">
                                    <mat-radio-button class="mr-3" [value]="'behind the ear'">Behind the Ear </mat-radio-button>
                                    <mat-radio-button #hearingOthers [value]="'others'">Others</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="form-group col-4" *ngIf="hearingOthers.checked">
                            <label for="typeOfHearingAidSpecify">Specify Type of Hearing Aid</label>
                            <input type="text" formControlName="typeOfHearingAidSpecify" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-5">
                            <label for="typeOfAmplification">Type of Amplification</label>
                            <div>
                                <mat-radio-group class="" formControlName="typeOfAmplification">
                                    <mat-radio-button class="mr-3" [value]="'digital'">Digital</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'programmable'">Programmable</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'analog'">Analog</mat-radio-button>
                                    <mat-radio-button #amplificationOthers [value]="'others'">Others</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="form-group col-4" *ngIf="amplificationOthers.checked">
                            <label for="typeOfAmplificationSpecify">Specify Type of Amplification</label>
                            <input type="text" formControlName="typeOfAmplificationSpecify" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">     
                        <div class="form-group col-4">
                            <label for="brand">Brand</label>
                            <input type="text" formControlName="brand" class="form-control" />
                        </div>
                        <div class="form-group col-4">
                            <label for="model">Model</label>
                            <input type="text" formControlName="model" class="form-control" />
                        </div>
                    </div>        
                    <div class="form-row">   
                        <div class="form-group col-4">
                            <label for="bands">Bands</label>
                            <input type="text" formControlName="bands" class="form-control" />
                        </div>
                        <div class="form-group col-4">
                            <label for="channels">Channels</label>
                            <input type="text" formControlName="channels" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="serialNumber">Serial Number</label>
                            <input type="text" formControlName="serialNumber" class="form-control" />
                        </div>
                        <div class="form-group col-4">
                            <label for="warranty">Warranty</label>
                            <input type="text" formControlName="warranty" class="form-control" />
                        </div>
                        <div class="form-group col-4">
                            <label for="batterySize">Battery Size</label>
                            <input type="text" formControlName="batterySize" class="form-control" />
                        </div>
                    </div>
                    <label>Aided Testing</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="hearingAidChanged">Hearing Aid Threshold Changed?</label><br>
                            <mat-radio-group formControlName="hearingAidChanged">
                                <mat-radio-button class="mr-3" [value]="'initial'">Initial</mat-radio-button>
                                <mat-radio-button #sameHearing class="mr-3" [value]="'same'">Same</mat-radio-button>
                                <mat-radio-button [value]="'changed'">Changed</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!sameHearing.checked">
                        <div class="form-row ml-3">
                            <div class="form-group col-4">
                                <label for="dateOfTesting">Date of Testing</label>
                                <div>
                                    <input matInput [matDatepicker]="picker2" formControlName="dateOfTesting" />
                                    <mat-datepicker-toggle matSuffix [for]="picker2">
                                        <i class="mdi mdi-calendar"></i>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-6">
                                <label for="brandLeft">Type of Aided Test</label>
                                <div>
                                    <mat-radio-group class="d-flex flex-column" formControlName="typeOfAidedTest">
                                        <mat-radio-button class="mr-3" [value]="'BOA'">BOA</mat-radio-button>
                                        <mat-radio-button class="mr-3" [value]="'VROA'">VROA</mat-radio-button>
                                        <mat-radio-button class="mr-3" [value]="'CPA'">CPA</mat-radio-button>
                                        <mat-radio-button class="mr-3" [value]="'Conventional Audiometry'">Conventional Audiometry</mat-radio-button>
                                        <mat-radio-button #aidedTypeOthers class="mr-3" [value]="'Others'">Others</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="form-row ml-5" *ngIf="aidedTypeOthers.checked">
                            <div class="form-group col-6">
                                <label for="typeOfAidedTestSpecify">Specify Type</label>
                                <input type="text" class="form-control" formControlName="typeOfAidedTestSpecify"/>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-12">
                                <label for="testingConditions">Testing Conditions</label>
                                <input type="text" class="form-control" formControlName="testingConditions"/>
                            </div>
                        </div>
                        <label class="ml-3">Aided Thresholds</label>
                        <div class="form-row ml-3">
                            <div class="form-group col-3">
                                <mat-checkbox formControlName="leftEar">
                                    Left Ear
                                </mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <label>250Hz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>500Hz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>1kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>1.5kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>2kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>3kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>4kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>6kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>8kHz</label>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-3">
                                <label>Threshold</label>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar250"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar500"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar1000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar1500"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar2000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar3000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar4000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar6000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="leftEar8000"/>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-3">
                                <label>Reached speech banana</label>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar250SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar500SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar1000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar1500SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar2000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar3000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar4000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar6000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar8000SpeechBanana"></mat-checkbox>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-3">
                                <label>Reached speech string bean</label>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar250SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar500SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar1000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar1500SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar2000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar3000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar4000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar6000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="leftEar8000SpeechStringBean"></mat-checkbox>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-3">
                                <mat-checkbox formControlName="rightEar">
                                    Right Ear
                                </mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <label>250Hz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>500Hz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>1kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>1.5kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>2kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>3kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>4kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>6kHz</label>
                            </div>
                            <div class="form-group col-1">
                                <label>8kHz</label>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-3">
                                <label>Threshold</label>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar250"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar500"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar1000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar1500"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar2000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar3000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar4000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar6000"/>
                            </div>
                            <div class="form-group col-1">
                                <input type="text" class="form-control" formControlName="rightEar8000"/>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-3">
                                <label>Reached speech banana</label>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar250SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar500SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar1000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar1500SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar2000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar3000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar4000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar6000SpeechBanana"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar8000SpeechBanana"></mat-checkbox>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-3">
                                <label>Reached speech string bean</label>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar250SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar500SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar1000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar1500SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar2000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar3000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar4000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar6000SpeechStringBean"></mat-checkbox>
                            </div>
                            <div class="form-group col-1">
                                <mat-checkbox formControlName="rightEar8000SpeechStringBean"></mat-checkbox>
                            </div>
                        </div>
                        <div class="form-row ml-3">
                            <div class="form-group col-6">
                                <label for="reliability">Reliability</label>
                                <div>
                                    <mat-radio-group class="d-flex flex-column" formControlName="reliability">
                                        <mat-radio-button class="mr-3" [value]="'Good'">Good</mat-radio-button>
                                        <mat-radio-button class="mr-3" [value]="'Fair'">Fair</mat-radio-button>
                                        <mat-radio-button [value]="'Poor'">Poor</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="uploadFile">Upload</label>
                            <input type="file" class="form-control" formControlName="uploadFile"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="notes">Notes</label>
                            <textarea class="form-control" rows="6" formControlName="notes"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>         
                </fieldset>
            </div>
        </form>
    </div>
</div>
