import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SampleReport } from '@app/dashboard/_models/sample-report';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { PrintService } from '@app/shared/print/print.service';

// Cerializr Imports
import { DeserializeArray, JsonArray } from 'cerializr';
import { map } from 'rxjs/internal/operators/map';

// Report Models
import { ActiveUsersReport } from "./reports/_models/active-users-report";
import { AgeOfPatientsWhoUnderwentCochlearImplantReport } from "./reports/_models/age-of-patients-who-underwent-cochlear-implant-report";
import { AreasOfConcernReport } from "./reports/_models/areas-of-concern-report";
import { EducationalPlacementOfPatientsReport } from "./reports/_models/educational-placement-of-patients-report";
import { FacilityStatusReport } from "./reports/_models/facility-status-report";
import { InitialScreeningResultsReport } from "./reports/_models/initial-screening-results-report";
import { MasterlistOfSurgicalInterventionReport } from "./reports/_models/masterlist-of-surgical-intervention-report";
import { InterventionApproachOfPatientsReport } from "./reports/_models/intervention-approach-of-patients-report";
import { NoOfPatientsInPathwayAReport } from "./reports/_models/no-of-patients-in-pathway-A-report";
import { NoOfPatientsWhoReachedSpeechBananaReport } from "./reports/_models/no-of-patients-who-reached-speech-banana-report";
import { NoOfPatientsWhoReachedSpeechStringBeanReport } from "./reports/_models/no-of-patients-who-reached-speech-string-bean-report";
import { NoOfPatientsWhoUnderwentCochlearImplantReport } from "./reports/_models/no-of-patients-who-underwent-cochlear-implant-report";
import { NoOfScreeningFacilitiesByRegionReport } from "./reports/_models/no-of-screening-facilities-by-region-report";
import { NoScreenedAccScreeningMethodReport } from "./reports/_models/no-screened-acc-screening-method-report";
import { PatientsReceivingSpeechAndLanguageInterventionReport } from "./reports/_models/patients-receiving-speech-and-language-intervention-report";
import { ReasonsForNotFittingHearingAidDevicesReport } from "./reports/_models/reasons-for-not-fitting-hearing-aid-devices-report";
import { RescreeningResultsReport } from "./reports/_models/rescreening-results-report";
import { RiskFactorAggregateReport } from './reports/_models/risk-factor-aggregate-report';
import { RiskFactorAggregateByFacilityReport } from './reports/_models/risk-factor-aggregate-by-facility-report';
import { ScreeningRegistryReport } from './reports/_models/screening-registry-report';
import { ScreeningResultsReport } from "./reports/_models/screening-results-report";
import { SealDistributionInventoryReport } from "./reports/_models/seal-distribution-inventory-report";
import { SexOfPatientsWhoUnderwentCochlearImplantReport } from "./reports/_models/sex-of-patients-who-underwent-cochlear-implant-report";
import { TimelinessOfAudiologicalInterventionReport } from "./reports/_models/timeliness-of-audiological-intervention-report";
import { TimelinessOfABRConfirmatoryTestingReport } from "./reports/_models/timeliness-of-abrconfirmatory-testing-report";
import { TimelinessOfScreeningReport } from './reports/_models/timeliness-of-screening-report';
import { TopFacilitiesInTotalNoOfLiveBirthsReport } from './reports/_models/top-facilities-in-total-no-of-live-births-report';
import { TopFacilitiesInTotalNoScreenedReport } from './reports/_models/top-facilities-in-total-no-screened-report';
import { TotalLiveBirthsScreenedReport } from './reports/_models/total-live-births-screened-report';
import { TotalNoOfScreeningPersonnelReport } from './reports/_models/total-no-of-screening-personnel-report';

//
import { Sync } from '@app/shared/_models/sync';
import { isJSDocThisTag } from 'typescript';
import { mergeMap } from 'rxjs/operators';
import Utils from '@app/shared/utils';
import { PsgcService } from '@app/shared/address/psgc.service';

//excel imports
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ConceptsRoutingModule } from '@app/admin/concepts/concepts-routing.module';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { createElementCssSelector } from '@angular/compiler';
// import * as logo from './mylogo.js';

import * as moment from 'moment';

import { User } from '@app/user/_models/user';
import { filter } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public user: Observable<User>;

  public sample_report: Observable<SampleReport>;

  private modelName = "sealDistribution";
  public sealDistributionSubject: BehaviorSubject<any>;
  public sealSubject: BehaviorSubject<any>;

  public liveBirth = "Live Birth"
  public status = "done"
  public refused = "refused";

  constructor(
    public http: HttpClient,
    private psgcService: PsgcService
  ) { }

  addCustomClause(report: string, filters: object) {
    let clause = [];

    let customDate: string = null;
    let customArea: string = null;
    let customFac: string = null;
    let customCat: string = null;

    function convertDateToUTC() {
      filters["from"] = moment(filters["from"], 'x').utc().format('YYYY-MM-DDTHH:mm:ss:SSS')
      filters["to"] = moment(filters["to"], 'x').utc().format('YYYY-MM-DDTHH:mm:ss:SSS')
    }

    switch (report) {
      case "topFacilitiesInTotalNoLiveBirths":
        // customDate = "meta.periodDate"
        clause.push({
          "birthOutcome": { "$eq": "Live Birth" }
        })
        break;
      case "facilityStatus":

        break;
      case "educationalPlacement":

        break;
      case "patientDetails":
        customDate = "dateOfBirth";
        break;
      case "sessions":
        customDate = "dateOfTesting";
        break;
      case "areasOfConcern":
        customDate = "dateOfScreening";
        break;
      case "comorbidity":
        customDate = "dateOfScreening";
        clause.push({
          "comorbidity": { "$ne": null }
        })
        break;
      case "interventionApproach":
        customDate = "dateOfSession";
        clause.push({
          "interventionApproach": { "$ne": null }
        })
        break;
      case "masterlistOfSurgicalIntervention":
        customDate = "dateOfImplantation";
        break;
      case "noScreenedAcc":
        customDate = "dateOfScreening";
        clause.push({
          "methodOfScreening": { "$ne": null }
        })
        break;
      case "noOfPatientsWhoUnderwentCochlearImplant":
        customDate = "dateOfFirstImplantation";
        clause.push({
          "explantedDevice": { "$ne": null }
        })
        break;
      case "noOfPatientsInPathwayA":
        customDate = "dateOfScreening";
        break;
      case "speechPathologySession":
        customDate = "dateOfSession";
        break;
      case "primaryDiagnosis":
        customDate = "dateOfScreening";
        clause.push({
          "primaryDevelopmentalDiagnosis": { "$ne": null }
        })
        break;
      case "reasonsForNotFittingHearingAidDevices":
        clause.push({
          "$and": [{ "notDoneReason": { "$ne": null } }, { "notDoneReason": { "$ne": "" } }]
        })
        break;
      case "riskFactorAggregate":
        customDate = "dateOfScreening";
        clause.push({
          "$or": [
            { "hyperbilirubinemia": { "$eq": "Yes" } },
            { "ventilation": { "$eq": "Yes" } },
            { "nicu": { "$eq": "Yes" } },
            { "ototoxicMedication": { "$eq": "Yes" } },
            { "familyHistoryHearingLoss": { "$eq": "Yes" } },
            { "craniofacialAnomalies": { "$eq": "Yes" } },
            { "featuresAssociated": { "$eq": "Yes" } },
            { "inUteroInfections": { "$eq": "Yes" } }
          ]
        })
        break;
      case "screeningRegistry":
        customDate = "dateOfScreening";
        break;
      case "screeningResultsHearing":
        customDate = "dateOfScreening";
        clause.push({
          "$or": [
            { "resultLeft": { "$ne": null } },
            { "resultRight": { "$ne": null } }
          ]
        })
        break;
      case "screeningResultsStatus":
        customDate = "meta.periodDate"
        clause.push({
          "status": { "$eq": "refused" }
        })
        break;
      case "timelinessOfAudiologicalIntervention":
        customDate = "dateOfFirstFitting";
        clause.push({
          "dateOfFirstFitting": { "$ne": null }
        })
        break;
      case "timelinessOfABRConfirmatoryTesting":
        customDate = "dateOfTest";
        clause.push({
          "dateOfTest": { "$ne": null }
        })
        break;
      case "timelinessOfScreening":
        customDate = "dateOfScreening";
        clause.push({
          "dateOfScreening": { "$ne": null }
        })
        break;
      case "topFacilitiesInTotalNoScreened":
        customDate = "meta.periodDate";
        clause.push({
          "$and": [
            { "status": { "$eq": "done" } },
            { "hSC": { "$ne": null } }
          ]
        })
        break;
      case "totalLiveBirthsScreenedPatient":
        customDate = "dateOfBirth";
        clause.push({
          "birthOutcome": { "$eq": "Live Birth" }
        })
        break;
      case "totalLiveBirthsScreenedStatus":
        customDate = "meta.periodDate"
        clause.push({
          "status": { "$eq": "done" }
        })
        break;
    }
    if (filters != null) {
      if ((filters["from"] && filters["to"] != null)) {
        if (customDate == null) {
          customDate = "meta.periodDate";
          // convertDateToUTC()
        }

        clause.push({
          [customDate]: { $gte: filters["from"], $lte: filters["to"] }
        });
      }

      if (filters["area"] != null) {
        if (customArea == null)
          customArea = "meta.psgcCode";

        switch (filters["area"]) {
          case "municipal":
            let muniLevel = filters["psgc"].substring(0, 6);
            let startsWithZeroM: string = "";

            if (filters["psgc"].startsWith("0"))
              startsWithZeroM = "|^(?=^" + filters["psgc"].substring(1, 6) + ")(?=^[0-9]{8}$)";

            clause.push({
              [customArea]: { $regex: "^(?=^" + muniLevel + ")(?=^[0-9]{9}$)" + startsWithZeroM }
            })
            break;
          case "provincial":
            let proviLevel = filters["psgc"].substring(0, 4);
            let startsWithZeroP: string = "";

            if (filters["psgc"].startsWith("0"))
              startsWithZeroP = "|^(?=^" + filters["psgc"].substring(1, 4) + ")(?=^[0-9]{8}$)";

            clause.push({
              [customArea]: { $regex: "^(?=^" + proviLevel + ")(?=^[0-9]{9}$)" + startsWithZeroP }
            })
            break;
          case "regional":
            let regioLevel = filters["psgc"].substring(0, 2);
            let startsWithZeroR: string = "";

            if (filters["psgc"].startsWith("0"))
              startsWithZeroR = "|^(?=^" + filters["psgc"].substring(1, 2) + ")(?=^[0-9]{8}$)";

            clause.push({
              [customArea]: { $regex: "^(?=^" + regioLevel + ")(?=^[0-9]{9}$)" + startsWithZeroR }
            })
            break;
          case "all regions":
            let regioLevel2 = filters["psgc"].substring(0, 2);
            let startsWithZeroR2: string = "";

            if (filters["psgc"].startsWith("0"))
              startsWithZeroR2 = "|^(?=^" + filters["psgc"].substring(1, 2) + ")(?=^[0-9]{8}$)";

            clause.push({
              [customArea]: { $regex: "^(?=^" + regioLevel2 + ")(?=^[0-9]{9}$)" + startsWithZeroR2 }
            })
            break;
        }
      }

      if (filters["facilityCode"] != null) {
        clause.push({
          "meta.facilityCode": { "$eq": filters["facilityCode"] }
        });
      }

      if (filters["category"] != null) {
        clause.push({
          "meta.category": { $in: filters["category"] }
        });
      }
    }

    if(clause.length != 0)
      clause.push({});
    
    return clause
  }

  constructQuery(filters: object) {
    let queryString: string = "?";

    if (filters["facilityCode"])
      queryString = queryString.concat("&facilityCode=" + filters["facilityCode"])

    if (filters["from"] && filters["to"])
      queryString = queryString.concat("&from=" + filters["from"] + "&to=" + filters["to"])

    if (filters["category"])
      queryString = queryString.concat("&category=" + filters["category"].toString())

    if (filters["area"]) {
      switch (filters["area"]) {
        case "municipal":
          if (filters["psgc"].startsWith("0"))
            queryString = queryString.concat("&psgc=" + filters["psgc"].substring(1, 6));
          else
            queryString = queryString.concat("&psgc=" + filters["psgc"].substring(0, 6));

          break;
        case "provincial":
          if (filters["psgc"].startsWith("0"))
            queryString = queryString.concat("&psgc=" + filters["psgc"].substring(1, 4));
          else
            queryString = queryString.concat("&psgc=" + filters["psgc"].substring(0, 4));

            // startsWithZeroP = "|^(?=^" + filters["psgc"].substring(1, 4) + ")(?=^[0-9]{8}$)";
            // %5E 1 .%7B8%2C8%7D%24

            queryString = queryString.concat("&psgc=%5E" + filters["psgc"].substring(1, 4) + ".%7B8%2C8%7D%24");

            
// 137603000

// 112402000  
// 137405000  

// 43405000
          break;
        case "regional":
          if (filters["psgc"].startsWith("0"))
            // queryString = queryString.concat("&psgc=" + filters["psgc"].substring(1, 2));
            queryString = queryString.concat("&psgc=%5E" + filters["psgc"].substring(1, 2) + ".%7B7%2C7%7D%24");
          else
            // queryString = queryString.concat("&psgc=" + filters["psgc"].substring(0, 2));
            queryString = queryString.concat("&psgc=%5E" + filters["psgc"].substring(0, 2) + ".%7B8%2C8%7D%24");

          break;
        case "all regions":
          if (filters["psgc"].startsWith("0"))
            queryString = queryString.concat("&psgc=" + filters["psgc"].substring(1, 2));
          else
            queryString = queryString.concat("&psgc=" + filters["psgc"].substring(0, 2));

          break;
      }
    }

    return queryString;
  }

  getAgeOfPatientsCochlearImplant(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/switchOn?~resolveRef=1`,
      {
        "where": {
          "$and": this.addCustomClause(null, filters)
        }
      });
  }

  getAreasOfConcern(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/pedsScreening`,
      {
        "where": {
          "$and": this.addCustomClause("areasOfConcern", filters)
        }
      });
  }

  getEducationalPlacementOfPatients(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/educationalPlacement`,
      {
        "where": {
          "$and": this.addCustomClause("educationalPlacement", filters)
        }
      });
  }

  getScreeningResultsReport() {
    return forkJoin([this.http.get(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening`), this.http.post(`${environment.apiUrl}/dashboard/models/getAll/status?~limit=1`,
      {
        "where": {
          "status": { "$eq": "refused" }
        }
      }
    )])
      .pipe(map(res => {
        let obj = {};

        obj["hearingScreening"] = res[0]["results"];
        obj["status"] = res[1]["count"];

        return obj;
      }))
  }

  getScreeningResults(filters: object) {
    return forkJoin([this.http.post(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening`,
      {
        "where": {
          "$and": this.addCustomClause("screeningResultsHearing", filters)
        }
      }), this.http.post(`${environment.apiUrl}/dashboard/models/getAll/status`,
        {
          "where": {
            "$and": this.addCustomClause("screeningResultsStatus", filters)
          }
        })])
      .pipe(map(res => {
        let obj = {};

        obj["hearingScreening"] = res[0]["results"];
        obj["status"] = res[1]["count"];

        return obj;
      }))
  }

  getScreeningResultsOptimized(filters: object) {
    return this.http.get(`${environment.apiUrl}/custom-reports/screening-results${this.constructQuery(filters)}`);
  }

  getPatientDetails(page: number, filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/patient?~resolveRef=1&~page=${page}`,
      {
        "where": {
          "$and": this.addCustomClause("patientDetails", filters)
        }
      })
      .pipe(
        mergeMap(res => {
          var resForks = res['results'].map(x => {
            if (x['dataModel'].psgcCode) {
              var psgc = Utils.psgcUtils.cleanPsgc(x['dataModel'].psgcCode)
              var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x != null ? x.name : null))]
              if (!Utils.psgcUtils.isCityMunPsgc(psgc))
                psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x != null ? x.name : null)))
              if (!Utils.psgcUtils.isProvPsgc(psgc))
                psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x != null ? x.name : null)))
              return forkJoin(psgcForks)
            }
            return of(null);
          })
          var returnRes: any;
          if (resForks.length != 0)
            returnRes = forkJoin(resForks)
          else
            returnRes = of(null)
          return returnRes
            .pipe(
              map(forkRes => {
                res['results'].forEach((e, i) => {
                  var fullAddress = [];
                  if (e['dataModel'].hospitalAddress && e['dataModel'].hospitalAddress !== "")
                    fullAddress.push(e['dataModel'].hospitalAddress);
                  if (forkRes[i])
                    fullAddress = fullAddress.concat(forkRes[i]);
                  e['dataModel']['fullAddress'] = fullAddress.join(', ');
                });
                return res;
              })
            )
        })
      );
  }

  getPatientDetailsDownload(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/patient?~resolveRef=1`,
      {
        "where": {
          "$and": this.addCustomClause(null, filters)
        }
      })
  }

  getMasterlistOfSurgicalIntervention(filters: object, page?: number) {
    return this.http.post<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/surgicalHearingIntervention?~sort=dateOfImplantation:-1&~resolveRef=1&~page=${page}`,
      {
        "where": {
          "$and": this.addCustomClause("masterlistOfSurgicalIntervention", filters)
        }
      })
      .pipe(
        mergeMap(res => {
          var resForks = res['results'].map(x => {
            if (x['dataModel'].psgcCode) {
              var psgc = Utils.psgcUtils.cleanPsgc(x['dataModel'].psgcCode)
              var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x != null ? x.name : null))]
              if (!Utils.psgcUtils.isCityMunPsgc(psgc))
                psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x != null ? x.name : null)))
              if (!Utils.psgcUtils.isProvPsgc(psgc))
                psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x != null ? x.name : null)))
              return forkJoin(psgcForks)
            }
            return of(null);
          })
          var returnRes: any;
          if (resForks.length != 0)
            returnRes = forkJoin(resForks)
          else
            returnRes = of(null)
          return returnRes
            .pipe(
              map(forkRes => {
                res['results'].forEach((e, i) => {
                  var fullAddress = [];
                  if (e['dataModel'].hospitalAddress && e['dataModel'].hospitalAddress !== "")
                    fullAddress.push(e['dataModel'].hospitalAddress);
                  if (forkRes[i])
                    fullAddress = fullAddress.concat(forkRes[i]);
                  e['dataModel']['fullAddress'] = fullAddress.join(', ');
                });
                return res;
              })
            )
        })
      );
  }

  getTopFacilitiesInTotalNoOfLiveBirthsReport() {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/patient`,
      {
        "where": {
          "birthOutcome": { "$eq": this.liveBirth }
        }
      });
  }

  getTopFacilitiesInTotalNoOfLiveBirths(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/patient`,
      {
        "where": {
          "$and": this.addCustomClause("topFacilitiesInTotalNoLiveBirths", filters)
        }
      });
  }

  getTopFacilitiesInTotalNoScreenedReport() {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/status`,
      {
        "where": {
          "status": { "$eq": "done" }
        }
      });
  }

  getTopFacilitiesInTotalNoScreened(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/status`,
      {
        "where": {
          "$and": this.addCustomClause("topFacilitiesInTotalNoScreened", filters)
        }
      });
  }

  getTotalLiveBirthsScreened(filters: object) {
    return forkJoin([this.http.post(`${environment.apiUrl}/dashboard/models/getAll/patient?~limit=1`,
      {
        "where": {
          "$and": this.addCustomClause("totalLiveBirthsScreenedPatient", filters)
        }
      }), this.http.post(`${environment.apiUrl}/dashboard/models/getAll/status?~resolveRef=1`,
        {
          "where": {
            "$and": this.addCustomClause("totalLiveBirthsScreenedStatus", filters)
          }
        })])
      .pipe(map(res => {
        let obj = {};

        obj["patient"] = res[0]["count"];
        obj["status"] = res[1]["results"];
        // obj["status"] = Array.from(res[1]["results"].reduce((m, t) => m.set(t["dataModel"]["patient"]["_modelId"], t), new Map()).values());

        return obj;
      }))
  }

  getTotalLiveBirthsScreenedOptimized(filters: object) {
    return this.http.get(`${environment.apiUrl}/custom-reports/total-live-births-screened${this.constructQuery(filters)}`);
  }

  getPedsScreening() {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/pedsScreening?~resolveRef=1`,
      {
        "where": {
          "primaryDevelopmentalDiagnosis": { "$ne": null }
        }
      });
  }

  getPrimaryDiagnosis(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/pedsScreening?~resolveRef=1`,
      {
        "where": {
          "$and": this.addCustomClause("primaryDiagnosis", filters)
        }
      });
  }

  getNoOfPatientsInPathwayA(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/pedsScreening?~resolveRef=1`,
      {
        "where": {
          "$and": this.addCustomClause("noOfPatientsInPathwayA", filters)
        }
      });
  }

  getComorbidityReport() {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/pedsScreening?~resolveRef=1`,
      {
        "where": {
          "comorbidity": { "$ne": null }
        }
      });
  }

  getComorbidity(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/pedsScreening?~resolveRef=1`,
      {
        "where": {
          "$and": this.addCustomClause("comorbidity", filters)
        }
      });
  }

  getNoOfPatientsWhoReachedSpeechBananaStringBeanReport() {
    return forkJoin([this.http.get(`${environment.apiUrl}/dashboard/models/getAll/switchOn`), this.http.get(`${environment.apiUrl}/dashboard/models/getAll/sessions`)])
      .pipe(map(res => {
        let obj = {};
        obj["switchOn"] = res[0]["results"];
        obj["sessions"] = res[1]["results"];

        var combined = [];
        for (var i = 0; i < obj["switchOn"].length; i++) {
          let comb = [];
          comb.push((obj["switchOn"][i]), obj["sessions"].filter(e => obj["switchOn"][i]["dataModel"]["patient"].includes(e["dataModel"]["patient"])));
          if (comb[1].length !== 0)
            combined.push(comb);
        }

        return combined;
      }))
  }

  getNoOfPatientsWhoReachedSpeechBananaStringBean(filters: object) {
    return forkJoin([this.http.post(`${environment.apiUrl}/dashboard/models/getAll/switchOn`,
      {
        "where": {
          "$and": this.addCustomClause(null, filters)
        }
      }), this.http.get(`${environment.apiUrl}/dashboard/models/getAll/sessions`)])
      .pipe(map(res => {
        console.log(res)

        let obj = {};
        obj["switchOn"] = res[0]["results"];
        obj["sessions"] = res[1]["results"];

        var combined = [];
        for (var i = 0; i < obj["switchOn"].length; i++) {
          let comb = [];
          comb.push((obj["switchOn"][i]), obj["sessions"].filter(e => obj["switchOn"][i]["dataModel"]["patient"].includes(e["dataModel"]["patient"])));
          if (comb[1].length !== 0)
            combined.push(comb);
        }

        return combined;
      }))
  }

  getNoOfPatientsWhoUnderwentCochlearImplant(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/explantedDevice`,
      {
        "where": {
          "$and": this.addCustomClause("noOfPatientsWhoUnderwentCochlearImplant", filters)
        }
      });
  }

  getRiskFactorAggregateReport() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening?~resolveRef=1`);
  }

  getRiskFactorAggregate(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening`,
      {
        "where": {
          "$and": this.addCustomClause("riskFactorAggregate", filters)
        }
      });
  }

  getRiskFactorAggregateByFacilityReport() {
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/patient`)
      .pipe(
        mergeMap(res => {
          var screeningForks = res['results'].map(e =>
            this.http.get(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening?patient=${e.modelId}&~sort=dateOfScreening:-1`))
          return forkJoin(screeningForks).pipe(
            map(forkRes => {
              var combined = res['results'].map((e, i) => {
                return [e, forkRes[i]['results'][0]];
              });
              return combined;
            })
          )
        })
      )
  }

  getScreeningRegistryReport() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening?~resolveRef=1&~sort=dateOfScreening:-1`);
  }

  getScreeningRegistryReportPaginated(page: number) {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening?~resolveRef=1&~page=${page}&~sort=dateOfScreening:-1`);
  }

  getScreeningRegistry(filters: object, page?: number) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening?~resolveRef=1&~page=${page}&~sort=dateOfScreening:-1`,
      {
        "where": {
          "$and": this.addCustomClause("screeningRegistry", filters)
        }
      });
  }

  getSexOfPatientsWhoUnderwentCochlearImplantReport(): Observable<SexOfPatientsWhoUnderwentCochlearImplantReport[]> {
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/patient`)
      .pipe(
        mergeMap(res => {
          var cocklearFork = res['results'].map(e =>
            this.http.get(`${environment.apiUrl}/dashboard/models/getAll/switchOn?patient=${e.modelId}&~limit=1&~sort=dateOfScreening:-1`))
          return forkJoin(cocklearFork).pipe(
            map(forkRes => {
              var combined = res['results'].map((e, i) => {
                return [e, forkRes[i]['results'][0]];
              });
              return combined;
            })
          )
        })
      )
  }

  getTimelinessOfAudiologicalInterventionReport() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/firstFitting?~resolveRef=1&~sort=dateOfFirstFitting:-1`);
  }

  getTimelinessOfAudiologicalIntervention(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/firstFitting?~resolveRef=1&~sort=dateOfFirstFitting:-1`,
      {
        "where": {
          "$and": this.addCustomClause("timelinessOfAudiologicalIntervention", filters)
        }
      });
  }

  getTimelinessOfABRConfirmatoryTestingReport() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/abrTest?~resolveRef=1&~sort=dateOfTest:-1`);
  }

  getTimelinessOfABRConfirmatoryTesting(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/abrTest?~resolveRef=1&~sort=dateOfTest:-1`,
      {
        "where": {
          "$and": this.addCustomClause("timelinessOfABRConfirmatoryTesting", filters)
        }
      });
  }

  getTimelinessOfScreeningReport() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening?~resolveRef=1`);
  }

  getTimelinessOfScreening(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening?~resolveRef=1`,
      {
        "where": {
          "$and": this.addCustomClause("timelinessOfScreening", filters)
        }
      });
  }

  getAllPatient() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/patient`);
  }

  getAllHearingAid() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/hearingAid`);
  }

  getReasonsForNotFittingHearingAidDevices(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/hearingAid`,
      {
        "where": {
          "$and": this.addCustomClause("reasonsForNotFittingHearingAidDevices", filters)
        }
      });
  }

  getAllSpeechTherapy() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/speechTherapy`);
  }

  getAllHearingScreening() {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening`);
  }

  getNoScreenedAccScreeningMethodReport() {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening`,
      {
        "where": {
          "methodOfScreening": { "$ne": null }
        }
      });
  }

  getNoScreenedAccScreeningMethod(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening`,
      {
        "where": {
          "$and": this.addCustomClause("noScreenedAcc", filters)
        }
      });
  }

  getNoScreenedAccScreeningMethodOptimized(filters: object) {
    return this.http.get(`${environment.apiUrl}/custom-reports/no-screened-acc-to${this.constructQuery(filters)}`);
  }

  getTimelinessOfScreeningOptimized(filters: object) {
    return this.http.get(`${environment.apiUrl}/custom-reports/timeliness-of-screening${this.constructQuery(filters)}`);
  }

  getAllSpeechPathologySessionPaginated(page: number) {
    return this.http.get<Sync[]>(`${environment.apiUrl}/dashboard/models/getAll/speechPathologySession?~resolveRef=1&~sort=dateOfSession:-1&~page=${page}`);
  }

  getAllSpeechPathologySession(filters: object, page?: number) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/speechPathologySession?~resolveRef=1&~sort=dateOfSession:-1&~page=${page}`,
      {
        "where": {
          "$and": this.addCustomClause("speechPathologySession", filters)
        }
      });
  }

  getAllTimelinessOfScreeningRecur(page: number, filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/hearingScreening?~resolveRef=1&~limit=100&~page=${page}`,
      {
        "where": {
          "$and": this.addCustomClause("timelinessOfScreening", filters)
        }
      });
  }

  getInterventionApproachOfPatientsReport() {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/speechPathologySession?~resolveRef=1`,
      {
        "where": {
          "interventionApproach": { "$ne": null }
        }
      });
  }

  getInterventionApproachOfPatients(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/speechPathologySession`,
      {
        "where": {
          "$and": this.addCustomClause("interventionApproach", filters)
        }
      });
  }

  getFacilityStatus(filters: object) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/facility`,
      {
        "where": {
          "$and": this.addCustomClause("facilityStatus", filters)
        }
      });
  }

  downloadFile(data, columnName, filename = 'data') {
    let header = Object.keys(JSON.parse(data)[0]);
    let csvData = this.convertToCSV(data, header, columnName);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSV(objArray, headerList, columnName) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in columnName) {
      row += columnName[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + '"' + array[i][head] + '"';
      }
      str += line + '\r\n';
    }
    return str;
  }

  exportExcel(excelData) {
    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(title);

    //Add Row and formatting
    worksheet.mergeCells('A1', 'E4');
    let titleRow = worksheet.getCell('C1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 15,
      bold: true,
      color: { argb: '000000' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.addRow([]);

    worksheet.addRow([excelData.filters]);

    // Date
    // worksheet.mergeCells('G1:H4');
    let d = new Date();
    let date = d.getMonth() + '/' + d.getDate() + '/' + d.getFullYear() + " at " + d.getHours() + ":" + d.getMinutes();
    // let dateCell = worksheet.getCell('G1');
    // dateCell.value = date;
    // dateCell.font = {
    //   name: 'Calibri',
    //   size: 12,
    //   bold: true
    // }
    // dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Blank Row 
    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '2F4F4F' },
        bgColor: { argb: '' }
      }
      cell.font = {
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);
    }
    );

    worksheet.addRow([]);

    //Footer Row
    let footerRow = worksheet.addRow(['Report generated in ENNHSR on ' + date]);
    // let footerRow = worksheet.addRow(['Report generated in ENNHSR on ' + excelData.filters]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFF' }
    };

    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title.replace(/\s+/g, '').trim().toLowerCase() + "-" + Math.floor(new Date().getTime() / 1000) + '.xlsx');
    })
  }
}
