import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { navAnimations, routeAnimations } from './app.animations';

import { UserService } from './user/user.service';
import { User } from './user/_models/user';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subscription } from 'rxjs';
import { SelectorService } from './shared/selector/selector.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AddEditComponentDialog as AccountDialog } from './admin/users/add-edit/add-edit.component';
import { AddEditComponentDialog as FeedbackDialog } from './admin/feedback/add-edit/add-edit.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { UsersService } from './admin/users/users.service';
import { AlertService } from './shared/alert/alert.service';
import { Location } from '@angular/common';

import { environment } from '@environments/environment';
import { LinkComponent } from './user/profile/link/link.component';
import { FeedbackService } from './admin/feedback/feedback.service';

@Component({ 
    selector: 'app', 
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    animations: [
        routeAnimations,
        navAnimations
    ] 
})
export class AppComponent {
    user: User;
    
    moduleLinks = [
        { title: 'Patient', fragment: 'patient' },
        { title: 'Facility', fragment: 'facility' },
        { title: 'Dashboard', fragment: 'dashboard' },
        { title: 'Admin', fragment: 'admin' }
    ];

    public isMenuCollapsed: boolean;

    public facilitySelect = new FormControl();
    private currFacility = 0;
    
    private subscription: Subscription;

    isDesktop: boolean;

    constructor(private userService: UserService, 
        public route: ActivatedRoute, 
        private bnIdle: BnNgIdleService, 
        private selector: SelectorService, 
        private router: Router,
        public dialog: MatDialog,
        private location: Location,
        private formBuilder: FormBuilder,
        private usersService: UsersService,
        private feedbacksService: FeedbackService,
        private alertService: AlertService,
        private dialogRef: MatDialog) { 
        this.isMenuCollapsed = true; 
        if(environment.isDesktop) {
            this.moduleLinks.push({ title: 'Sync', fragment: 'sync' })
        }
        this.isDesktop = environment.isDesktop;
    }
    
    ngOnInit(): void {
        this.isMenuCollapsed = true;   
        this.userService.user.subscribe(user => {
            this.user = user;
            this.facilitySelect.setValue(user ? user['facilityAndRoles'].findIndex(e => e['facilityModelId'] == user['facility']['facilityModelId']) : 0);
            // if(user) {
            //     this.subscription = this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
            //         if(isTimedOut) {
            //             this.logout()
            //             this.bnIdle.stopTimer()
            //         }
            //     });
            // }
            // else {
            //     if(this.subscription != undefined)
            //         this.subscription.unsubscribe();
            // }   
        });
    }

    logout() {
        document.getElementsByTagName("nav")[0].className = document.getElementsByTagName("nav")[0].className.replace(" sticky-top","");
        this.isMenuCollapsed = true;
        this.dialogRef.closeAll();
        this.userService.logout();
        // var token = localStorage.getItem('token')
        localStorage.clear()
        // localStorage.setItem('token', token)
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet.isActivated ? outlet.activatedRoute : "";
    }

    navclick() {
        if(!this.isMenuCollapsed){
            this.selector.disable();
            this.selector.reset();
        }
        this.isMenuCollapsed = true;
    }

    navToggle() {
        if(!this.isMenuCollapsed)
            this.selector.disable();
        this.isMenuCollapsed = !this.isMenuCollapsed;
    }

    navAnimationsDone(e: any) {
        // if(e.fromState == "void") {
        //     (e.element as Element).className += " sticky-top";
        // }
    }

    onHidden() {
        this.selector.enable();
    }

    onScroll() {
        this.selector.scroll();
    }

    openAccountDialog(): void {
        this.dialog.open(AccountDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: this.user.id,
                isAddMode: false,
                isProfile: true,
                usersService: this.usersService,
                alertService: this.alertService
            }
        });
    }

    openFeedbackDialog(): void {
        this.dialog.open(FeedbackDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: this.user.id,
                isAddMode: true,
                isProfile: true,
                feedbacksService: this.feedbacksService,
                alertService: this.alertService,
                router: this.router
            }
        });
    }

    openLinkDialog(): void {
        this.dialog.open(LinkComponent, {
            data: {
                formBuilder: this.formBuilder,
                id: this.user.id,
                usersService: this.usersService,
                alertService: this.alertService
            }
        });
    }

    changeFacility(e) { 
        if(confirm("Unsaved changes will be lost. Proceed?")){
            this.usersService.setCurrentFacility(e, this.user).subscribe(res => {
                this.user = res;
                this.userService.userSubject.next(res);
            });
            this.router.navigateByUrl('');
            this.currFacility = e;
            return
        }
        this.facilitySelect.setValue(this.currFacility);
        return;
    }

    validatePrivilege(fragment) {
        if(this.user['facility']) {
            switch(fragment){
                case 'patient': return this.user['isAdmin'] || this.user['isGuest'] || this.user['facility']['roles'].includes('Category A') || this.user['facility']['roles'].includes('Category B') || this.user['facility']['roles'].includes('Category C') || this.user['facility']['roles'].includes('Category D');
                case 'facility': return this.user['isAdmin'] || this.user['facility']['roles'].includes('Unit Manager');
                case 'dashboard': return this.user['isAdmin'] || this.user['facility']['roles'].includes('Unit Manager');
                case 'admin': return this.user['isAdmin'];
                case 'sync': return this.user['isAdmin'] || this.user['facility']['roles'].includes('Category A') || this.user['facility']['roles'].includes('Category B') || this.user['facility']['roles'].includes('Category C') || this.user['facility']['roles'].includes('Category D');
            }
        }
        return false;
    }
}