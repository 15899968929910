<nav class="form-header navbar navbar-expand w-100">
    <ul ngbNav class="navbar-nav mr-auto">
        <li class="nav-item">
            <a routerLink="./requests" class="nav-link main-nav-item" routerLinkActive="active">Requests</a>
        </li>
        <li class="nav-item">
            <a routerLink="./processing" class="nav-link main-nav-item" routerLinkActive="active">Processing</a>
        </li>
        <li class="nav-item">
            <a routerLink="./shipping" class="nav-link main-nav-item" routerLinkActive="active">In Transit</a>
        </li>
        <li class="nav-item">
            <a routerLink="./completed" class="nav-link main-nav-item" routerLinkActive="active">Completed</a>
        </li>
        <li class="nav-item">
            <a routerLink="./canceled" class="nav-link main-nav-item" routerLinkActive="active">Canceled</a>
        </li>
    </ul>
</nav>
<div [@sealAnimations]="prepareRoute(outlet)" class="w-100">
    <router-outlet #outlet="outlet"></router-outlet>
</div>