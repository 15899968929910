import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import jsPDF from 'jspdf';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreeningResultsService {

  private modelName = "screeningResults";

  public recordsSubject: BehaviorSubject<any> = new BehaviorSubject({"count":0,"offset":null,"limit":null,"results":[]});
  public records: Observable<any> = this.recordsSubject.asObservable();

  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService
  ) { }

  public get recordValue() {
    return this.recordsSubject.value;
  }

  public get recordObservable() {
    return this.records;
  }

  add(item: any){

    let doc = new jsPDF('p', 'in', 'a4');
    const w = doc.internal.pageSize.getWidth();
    const h = doc.internal.pageSize.getHeight();
    //doc.addImage(item["pdf"], 'PNG', 0, 0, 8.3, 11.7)
    doc.addImage(item["pdf"], 'PNG', 0, 0, w, h)

    const formData = new FormData();
    formData.append('recipient', item["emailAdd"]);
    formData.append('subject', "Screening Results")
    formData.append('body', "Attached to this E-mail are the Screening Results sent by " + this.userService.user);
    formData.append('attachment', doc.output('blob'));
    console.log(formData)
    return this.http.post(`${environment.apiUrl}/dashboard/email/send`, formData);
  }

  getAll(id: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?patient=${id}`)
    .pipe(
      map(res => {
        this.recordsSubject.next(res);
        return res;
      })
    );
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}`);
  }

  getByPatient(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}?patient=${id}`)
    .pipe(
      map(res => {
        this.recordsSubject.next(res);
        return res;
      })
    );
  }

  update(item: any, modelId: string, versionId: string, uploadFile?:File) {
    if(uploadFile != null) {
      let headers = new HttpHeaders({
        'X-Random': Utils.generateGuid(),
        'X-Filename':  uploadFile.name,
        'Content-Type': uploadFile.type});
       return this.http.put(`${environment.apiUrl}/file/insert`, uploadFile, { headers: headers })
       .pipe(
         mergeMap(uploadRes => {
           var original = this.recordsSubject.value;
           item["meta"] = original["dataModel"]["meta"];
           item = Utils.generateMeta(item)
           item["meta.periodDate"] = item["dateOfSession"]
           item["patient"] = original["dataModel"]["patient"];
           item["uploadFile"] = uploadRes["refId"]
           const user = this.userService.userValue;
           var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                                     Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
           var fuidx;
           sync["fieldUpdates"].forEach((element, index) => {
             if(element["fieldPath"] === "uploadFile"){
               fuidx = index;
               let fieldUpdateRefId = { ...element };
               fieldUpdateRefId["indexIds"] = [];
               fieldUpdateRefId["indexIds"].push(uploadRes["refId"]);
               fieldUpdateRefId["value"] = uploadRes["refId"];
               fieldUpdateRefId["fieldPath"] = "uploadFile[].refId";
               sync["fieldUpdates"].push(fieldUpdateRefId);
               let fieldUpdatefileName = { ...element };
               fieldUpdatefileName["indexIds"] = [];
               fieldUpdatefileName["indexIds"].push(uploadRes["refId"]);
               fieldUpdatefileName["value"] = uploadFile.name;
               fieldUpdatefileName["fieldPath"] = "uploadFile[].fileName";
               sync["fieldUpdates"].push(fieldUpdatefileName);
               let fieldUpdatefileType = { ...element };
               fieldUpdatefileType["indexIds"] = [];
               fieldUpdatefileType["indexIds"].push(uploadRes["refId"]);
               fieldUpdatefileType["value"] = uploadFile.type;
               fieldUpdatefileType["fieldPath"] = "uploadFile[].fileType";
               sync["fieldUpdates"].push(fieldUpdatefileType);
               let fieldUpdatedateUploaded = { ...element };
               fieldUpdatedateUploaded["indexIds"] = [];
               fieldUpdatedateUploaded["indexIds"].push(uploadRes["refId"]);
               fieldUpdatedateUploaded["value"] = Date.now();
               fieldUpdatedateUploaded["fieldPath"] = "uploadFile[].dateUploaded";
               sync["fieldUpdates"].push(fieldUpdatedateUploaded);
             ;}
            });
            fuidx >= 0 ? sync["fieldUpdates"].splice(fuidx,1) : "";                        
            console.log(sync)
            return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
           .pipe(
             map(res => {
               var record = this.recordsSubject.value;
               record.dataModel = item;
               record.versionId = res['updatedModelVersionIds'][this.modelName][modelId];
               this.recordsSubject.next(record);
               return res;
             })
           );
         })
       )
    } else {
      var original = this.recordsSubject.value;
      item["meta"] = original["dataModel"]["meta"];
      item = Utils.generateMeta(item)
      item["meta.periodDate"] = item["dateOfSession"]
      item["patient"] = original["dataModel"]["patient"];
      var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                                Utils.convertToSync(item, this.modelName, modelId, versionId));
      return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
      .pipe(
        map(res => {
          var record = this.recordsSubject.value;
          record.dataModel = item;
          record.versionId = res['updatedModelVersionIds'][this.modelName][modelId];
          this.recordsSubject.next(record);
          return res;
        })
      );
    }    
  }

}