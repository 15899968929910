import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnnouncementsRoutingModule } from './announcements-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AnnouncementsComponent } from './announcements.component';

@NgModule({
  declarations: [ AnnouncementsComponent ],
  imports: [
    CommonModule,
    AnnouncementsRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class AnnouncementsModule { }
