<div class="container">
    <div class="card">
        <div class="card-header">
            View History
        </div>
        <div class="card-body">
            <table mat-table [dataSource]="files" class="files-table table-bordered">
                <ng-container matColumnDef="fileName" sticky>
                    <th mat-header-cell *matHeaderCellDef class="text-center"> Name </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.fileName}} </td>
                </ng-container>
                <ng-container matColumnDef="fileType">
                    <th mat-header-cell *matHeaderCellDef class="text-center"> Type </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.fileType}} </td>
                </ng-container>
                <ng-container matColumnDef="dateUploaded">
                    <th mat-header-cell *matHeaderCellDef class="text-center"> Date Uploaded </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.dateUploaded}} </td>
                </ng-container>
                <ng-container matColumnDef="refId" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="text-center">Download</th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        <button (click)="download(element)" type="button" class="btn btn-link mr-1"><i class="mdi mdi-download"></i></button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>