import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@app/shared/utils';
import { FieldUpdate } from '@app/shared/_models/field-update';
import { Sync } from '@app/shared/_models/sync';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MachinesService {
  private modelName = "machine";
  public machineSubject: BehaviorSubject<any>;
  public machine: Observable<any>;
  
  constructor(
    public router: Router,
    public http: HttpClient
  ) {
    this.machineSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(this.modelName+'Records')));
    this.machine = this.machineSubject.asObservable();
   }
  
   public get recordsValue() {
    return this.machineSubject.value;
  }
  public get recordsObservable() {
    return this.machine;
  }

  getAll(facility?: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}${facility ? "?facility="+facility : ""}`)
    .pipe(
      map(res => {
        localStorage.setItem(this.modelName+'Records', JSON.stringify(res));
        this.machineSubject.next(res);
        return res;
      })
    );
  }

  getById(id: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}?~resolveRef=1`);
  }

  add(item: any){
    var guid = Utils.generateGuid()
    item["facility"] = JSON.parse(localStorage.getItem('user'))["facility"]["facilityModelId"];
    var sync = Utils.convertToSync(item, this.modelName, guid);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      mergeMap(res => {
        return this.getById(res['insertedModelIds'][this.modelName][guid]['assignedId'])
        .pipe(
          map(newItem => {
            var records = this.machineSubject.value;
            records['results'].splice(0,0,newItem);
            localStorage.setItem(this.modelName+'Records', JSON.stringify(records));
            this.machineSubject.next(records);
            return res;
          })
        );
      })
    );
  }

  update(item: any, modelId: string, versionId: string) {
    var original = this.machineSubject.value['results'].find(x => x.modelId === modelId);
    item["meta"] = original["dataModel"]["meta"];
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.machineSubject.value;
        var idx = records["results"].findIndex(x => x.modelId === modelId);
        records["results"][idx].dataModel = item;
        records["results"][idx].versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        localStorage.setItem(this.modelName+'Records', JSON.stringify(records));
        this.machineSubject.next(records);
        return res;
      })
    );
  }

  delete(id){
    var model = this.machineSubject.value['results'].find(x => x.modelId === id)
    let sync = new Sync();
    let field = new FieldUpdate();
    field.fieldPath = "meta.voided";
    field.value = true;
    field.modelName = this.modelName;
    field.modelId = id;
    field.dateUpdated = new Date();
    field.dateSynced = null;
    field.userId = JSON.parse(localStorage.getItem('user')).id;
    sync.fieldUpdates.push(field);
    let token = {};
    token[this.modelName] = {};
    token[this.modelName][id] = model.versionId;
    sync.syncTokens = token;
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.machineSubject.value;
        records['results'].splice(records['results'].findIndex(x => x.modelId === model.modelId),1);
        localStorage.setItem(this.modelName+'Records', JSON.stringify(records));
        this.machineSubject.next(records);
        return res;
      })
    );
  }
}
