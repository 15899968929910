import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConceptsRoutingModule } from './concepts-routing.module';
import { ListComponent } from './list/list.component';
import { AddEditComponent, AddEditComponentDialog } from './add-edit/add-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [ListComponent, AddEditComponent, AddEditComponentDialog],
  imports: [
    CommonModule,
    ConceptsRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    MatCheckboxModule
  ]
})
export class ConceptsModule { }
