<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} ABR Test
                <button type="button" (click)="this.printService.print(['details', print], 'ABR Test')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>  
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="dateOfTest">Date of Test</label>
                            <div>
                                <input matInput [matDatepicker]="picker" formControlName="dateOfTest" [ngClass]="{ 'is-invalid': submitted && f.dateOfTest.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <div *ngIf="submitted && f.dateOfTest.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfTest.errors.required">Date of Test is required</div>
                                </div>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="nameOfTester">Name of Tester</label>
                            <input type="text" formControlName="nameOfTester" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameOfTester.errors }" />
                            <div *ngIf="submitted && f.nameOfTester.errors" class="invalid-feedback">
                                <div *ngIf="f.nameOfTester.errors.required">Name of Tester is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="reader">Reader</label>
                            <input type="text" formControlName="reader" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.reader.errors }" />
                            <div *ngIf="submitted && f.reader.errors" class="invalid-feedback">
                                <div *ngIf="f.reader.errors.required">Reader is required</div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="device">Machine(serial number)</label>
                            <input type="text" formControlName="device" class="form-control" [matAutocomplete]="auto"/>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let machine of allMachine$ | async as allMachine">
                                  {{machine.dataModel.brand}}({{machine.dataModel.serialNumber}})
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="stimulus">Stimulus</label>
                            <div>
                                <mat-checkbox formControlName="stimulusClick" class="mr-3">Click</mat-checkbox>
                                <mat-checkbox formControlName="stimulusToneBurst" class="mr-3">Tone Burst</mat-checkbox>
                                <mat-checkbox formControlName="stimulusChirp">Chirp</mat-checkbox>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="testingConditions">Testing Conditions</label>
                            <div>
                                <mat-radio-group formControlName="testingConditions">
                                    <mat-radio-button class="mr-3" [value]="'Natural Sleep'">Natural Sleep</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Sedated'">Sedated</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-6 d-flex justify-content-center">
                            <mat-checkbox formControlName="leftEarDone">
                                Left Ear
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-6 d-flex justify-content-center">
                            <mat-checkbox formControlName="rightEarDone">
                                Right Ear
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="transducersLeftEar">Transducers</label>
                            <div>
                                <mat-radio-group formControlName="transducersLeftEar">
                                    <mat-radio-button class="mr-3" [value]="'Insert Earphones'">Insert Earphones</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Bone Conductor'">Bone Conductor</mat-radio-button>
                                    <mat-radio-button [value]="'Headphones'">Headphones</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="transducersRightEar">Transducers</label>
                            <div>
                                <mat-radio-group formControlName="transducersRightEar">
                                    <mat-radio-button class="mr-3" [value]="'Insert Earphones'">Insert Earphones</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Bone Conductor'">Bone Conductor</mat-radio-button>
                                    <mat-radio-button [value]="'Headphones'">Headphones</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="">Polarity</label>
                            <div>
                                <mat-checkbox formControlName="polarityLeftRarefaction" class="mr-3">
                                    <label class="form-check-label" for="privacy">
                                        Rarefaction
                                    </label>
                                </mat-checkbox>
                                <mat-checkbox formControlName="polarityLeftCondensation" class="mr-3">
                                    <label class="form-check-label" for="privacy">
                                        Condensation
                                    </label>
                                </mat-checkbox>
                                <mat-checkbox formControlName="polarityLeftAlternating">
                                    <label class="form-check-label" for="privacy">
                                        Alternating
                                    </label>
                                </mat-checkbox>
                            </div>
                            
                        </div>
                        <div class="form-group col-6">
                            <label for="">Polarity</label>
                            <div>
                                <mat-checkbox formControlName="polarityRightRarefaction" class="mr-3">
                                    <label class="form-check-label" for="privacy">
                                        Rarefaction
                                    </label>
                                </mat-checkbox>
                                <mat-checkbox formControlName="polarityRightCondensation" class="mr-3">
                                    <label class="form-check-label" for="privacy">
                                        Condensation
                                    </label>
                                </mat-checkbox>
                                <mat-checkbox formControlName="polarityRightAlternating">
                                    <label class="form-check-label" for="privacy">
                                        Alternating
                                    </label>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="findingsRightEar">Findings</label>
                            <div>
                                <mat-radio-group class="d-flex flex-column" formControlName="findingsRightEar">
                                    <mat-radio-button class="mr-3" [value]="'Normal Threshold'">Normal Threshold</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Mild'">Mild</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Mild to moderate'">Mild to moderate</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Moderate'">Moderate</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Moderate to severe'">Moderate to severe</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Severe'">Severe</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Severe to profound'">Severe to profound</mat-radio-button>
                                    <mat-radio-button [value]="'Profound'">Profound</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="findingsLeftEar">Findings</label>
                            <div>
                                <mat-radio-group class="d-flex flex-column" formControlName="findingsLeftEar">
                                    <mat-radio-button class="mr-3" [value]="'Normal Threshold'">Normal Threshold</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Mild'">Mild</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Mild to moderat'">Mild to moderate</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Moderate'">Moderate</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Moderate to severe'">Moderate to severe</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Severe'">Severe</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Severe to profound'">Severe to profound</mat-radio-button>
                                    <mat-radio-button [value]="'Profound'">Profound</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="clickThresholdLeft">Click Threshold</label>
                            <textarea class="form-control" rows="3" formControlName="clickThresholdLeft"></textarea>
                        </div>
                        <div class="form-group col-6">
                            <label for="clickThresholdRight">Click Threshold</label>
                            <textarea class="form-control" rows="3" formControlName="clickThresholdRight"></textarea>
                        </div>
                    </div>
                    <label>Findings (If tone burst)</label>
                    <div class="form-row">
                        <div class="form-group col-2">
                            <label>
                                Left Ear
                            </label>
                        </div>
                        <div class="form-group col-1">
                            <label>250Hz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>500Hz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>1kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>1.5kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>2kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>3kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>4kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>6kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>8kHz</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-2"></div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst250" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst500" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst1000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst1500" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst2000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst3000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst4000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst6000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsLeftEarToneBurst8000" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-2">
                            <label>
                                Right Ear
                            </label>
                        </div>
                        <div class="form-group col-1">
                            <label>250Hz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>500Hz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>1kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>1.5kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>2kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>3kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>4kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>6kHz</label>
                        </div>
                        <div class="form-group col-1">
                            <label>8kHz</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-2"></div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst250" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst500" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst1000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst1500" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst2000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst3000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst4000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst6000" class="form-control" />
                        </div>
                        <div class="form-group col-1">
                            <input type="text" formControlName="findingsRightEarToneBurst8000" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="notes">Notes</label>
                            <input type="text" formControlName="notes" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <label for="uploadFile">Upload</label>
                        <!-- <div *ngIf="form.value.uploadFile" class="form-row ml-3">
                            <div class="form-group">
                                <a role="button" (click)="download(form.value.uploadFile)"><u>click to download file</u></a>
                            </div>
                        </div> -->
                        <div class="form-row ml-3">
                            <div class="form-group">
                                <!-- <input type="file" id="file" class="form-control" (change)="handleFileInput($event.target.files)"/> -->
                                <input-file formControlName="uploadFile" (change)="handleFileInput($event.target.files)"></input-file>
                                <!-- <input type="file" id="file" class="form-control" accept="application/pdf,image/jpeg" (change)="handleFileInput($event.target.files)"/> -->
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>