<div class="container pb-3">
    <div class="d-flex flex-column h-100 m-3">
        <div class="mb-3">
            <button (click)="back()" class="btn btn-success"><i class="mdi mdi-arrow-left"></i> Back</button>
        </div>
        <h5><i class="mdi mdi-help-circle-outline"></i> Forgot Password</h5>
        <div class="ml-3">
            <p>Contact the Administrator for a password reset.</p>
        </div>
    </div>
</div>