import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FacilitiesService } from '../facilities.service';
import Utils from '@app/shared/utils';

@Component({ templateUrl: 'list.component.html' })
export class ListComponent implements OnInit {
    facilities$ : Observable<any>;
    facilitiesPage$ : Observable<any>;
    columns = [{ name: 'Facility Name' }, { name: 'Active' } , { name: 'NHSRC Facility Accreditation Number' , prop: 'nshrcFacilityAccreditationNumber'}, { name: 'Category' }, { name: 'Philhealth Provider Accreditation Number', prop: 'philhealthFacilityAccreditationNumber' }, { name: 'DOH Health Facility ID' }, { name: 'Street Address' }];

    constructor(private facilitiesService: FacilitiesService, private router: Router, private location: Location) {}

    ngOnInit() {
        this.facilitiesService.getAll(0).subscribe();
        this.facilities$ = this.facilitiesService.recordsObservable.pipe(
        map(facilities => facilities["results"].map(x => Utils.mapModelToForm(x)))
        )
        this.facilitiesPage$ = this.facilitiesService.recordsObservable;
    }

    deleteFacility(id: string) {
        this.facilitiesService.delete(id).pipe(first())
        .subscribe();
    }

    getPage(page: string) {
        this.facilitiesService.getAll(page).subscribe();
    }

    searchFacility(keyword: string) {
        if(keyword != '')
            this.facilitiesService.searchAndRefresh(keyword).subscribe();
        else
            this.facilitiesService.getAll(0).subscribe();
    }
}