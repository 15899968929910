import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SealsService } from '@app/admin/seal/seals.service';
import Utils from '@app/shared/utils';
import { SyncService } from '@app/sync/sync.service';
import { SealDistributionService } from '@app/unit/seal/seal-distribution.service';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, first, map, mergeMap, startWith, switchMap, take, tap } from 'rxjs/operators';
import { PatientService } from '../patient.service';
import { resultsAnimations } from './layout.animations';
import { SealSummaryService } from '@app/admin/seal/seal-summary.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    resultsAnimations
  ] 
})
export class LayoutComponent implements OnInit {
  isLoading: boolean;
  isLoadingSeals: boolean = true;
  isSynced: boolean;
  sealRequest$: Observable<any>; 
  searchControl = new FormControl();
  searchResults$: Observable<any>;
  facilityId: string;
  totalSeals: number = 0;
  consumedSeals: number = 0;
  isUnitManager: boolean = false;

  constructor(private patientService : PatientService, private userService: UserService, private sealDistributionService: SealDistributionService, private sealsService: SealsService, private syncService: SyncService, private sealSummaryService: SealSummaryService) { }

  ngOnInit(): void {
    this.isLoading = false;
    this.isSynced = JSON.parse(localStorage.getItem('user'))["linkAccountId"] === null && environment && environment.isDesktop == true ? false : true;
    this.isUnitManager = JSON.parse(localStorage.getItem('user'))['facility']['roles'].includes('Unit Manager')
    this.facilityId = JSON.parse(localStorage.getItem('user'))["facility"]["facilityModelId"];
    this.patientService.recordSubject.next(null)
    localStorage.removeItem('patient');
    this.sealsService.sealSubject.next(null)
    localStorage.removeItem('sealRecords');
   /*  this.sealDistributionService.getAll(this.facilityId,"RECEIVED").subscribe(x => {
      this.totalSeals = 0;
      this.isLoadingSeals = false;
      this.consumedSeals = 0;
      (x["results"] as []).forEach((element: any, index) => {
        element["dataModel"]["sealsData"] = []
          this.totalSeals = this.totalSeals + (element["dataModel"]["seals"]["count"])
          this.sealsService.getAllCount(element["modelId"], 1, true).subscribe( y => {
            this.consumedSeals = this.consumedSeals + y['count'];
            if(index == ((x["results"] as []).length - 1))
              this.isLoadingSeals = false;
          })
      });
    }); */
    
    this.sealSummaryService.getByFacility(this.facilityId).subscribe(
      (sealSummaryData) => {
        if(sealSummaryData['results'][0]){
          //console.log("in Home seal summary data: ", sealSummaryData);
          this.consumedSeals = sealSummaryData['results'][0]['dataModel']['used']
          this.totalSeals = sealSummaryData['results'][0]['dataModel']['total']
          //console.log("print print: ", this.sealSummaryService.recordsValue['results'][0])
        }
        this.isLoadingSeals = false;
      },
      (error) => {
        console.error('Error fetching Seal Summary model:', error);
        this.isLoadingSeals = false;
      }
      
    );
    

    this.searchResults$ = this.searchControl.valueChanges
    .pipe(
      startWith(''),
      debounceTime(300),
      mergeMap(val => {
        if(val !== '') {
          this.isLoading = true;
          return this.patientService.search(val)
            .pipe(
              first(),
              catchError(val => of({ results: [] }))
            );
        }     
        this.isLoading = false;
        return of(null);
      })
    );
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || user['facility']['roles'].includes('Category A') || user['facility']['roles'].includes('Category B') || user['facility']['roles'].includes('Category C') || user['facility']['roles'].includes('Category D');
  }
}
