import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from '@app/shared/alert/alert.service';
import { EnnhsrService } from '@app/shared/ennhsr.service';
import { first } from 'rxjs/operators';
import { AnnouncementsService } from './announcements.service';


@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private announcementsService: AnnouncementsService, private ennhsrService: EnnhsrService,  private alertService: AlertService,) { 
    
  }

  ngOnInit() {
    //this.form = this.formBuilder.group({});
    this.form = this.formBuilder.group({
      html: ['']
  });
    this.loadAnnouncements();
  }

  loadAnnouncements(){
    this.ennhsrService.getAnnouncement()
      .pipe(first())
      .subscribe(x => this.form.patchValue(x));
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    /* if (this.form.invalid) {
        return;
    } */

    this.loading = true;
    this.updateAnnouncement();
  }

  updateAnnouncement(){
    this.announcementsService.update(this.form.value["html"])
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Save successful', { keepAfterRouteChange: true });
                    this.loading = false;
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });

  }
}
