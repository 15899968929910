<div class="card my-3">
    <div class="card-header d-flex justify-content-between">
        <span>Auditory Evoked Potentials</span>
        <div *ngIf="validatePrivilege()">
            <!-- <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                             -->
            <button type="button" *ngIf="this.editable && !this.editing && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
            <button type="button" *ngIf="this.editable && this.editing" (click)="this.editing = false;" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
        </div>
    </div>
    <div class="card-body">   
        <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
            <label>ABR Tests</label>
            <app-table [modelName]="'abrTest'" [isWriteMode]="this.editing" [data]="abrTest$ | async" [pageData]="abrTestPage$ | async" [printTitle]="'ABR Tests'" [columns]="columnsABR" [addLink]="'auditory-evoked-potentials/add-abr'" [editLink]="'auditory-evoked-potentials/edit-abr'" (pageEvent)="getPageABRTest($event)" (deleteRowEvent)="deleteABRTest($event)"></app-table>
            <label>ASSR Tests</label>
            <app-table [modelName]="'assrTest'" [isWriteMode]="this.editing" [data]="assrTest$ | async" [pageData]="assrTestPage$ | async" [printTitle]="'ASSR Tests'" [columns]="columnsASSR" [addLink]="'auditory-evoked-potentials/add-assr'" [editLink]="'auditory-evoked-potentials/edit-assr'" (pageEvent)="getPageASSRTest($event)" (deleteRowEvent)="deleteASSRTest($event)"></app-table>
        </fieldset>
    </div>
</div>