<div class="container h-100 m-0 p-3">
    <div class="card p-0">
        <div *ngIf="isWriteMode" class="position-relative card-header d-flex justify-content-between pb-3" [@searchAnimations]>
            <div class="col-md-6 p-0 mr-3">
                <div class="input-group mr-3">
                    <span class="input-group-prepend d-flex align-items-center"><i class="mdi mdi-18px mdi-filter"></i></span>
                    <input class="form-control" type="text" placeholder="Filter" [formControl]="filterControl"/>
                </div>
            </div>
            <div>
                <button type="button" *ngIf="printTitle" (click)="printService.print(['details', [columns,data]], printTitle, true)" class="btn btn-primary mr-2"><i class="mdi mdi-printer"></i></button>
                <a *ngIf="!noAdd" routerLink="{{addLink}}" class="btn btn-primary text-nowrap"><i class="mdi mdi-pencil-plus"></i>New</a>
            </div>
        </div>
        <div #print class="card-body" style="min-width: 375px;">
            <!-- <div *ngIf="!isWriteMode" class="w-100 d-flex flex-row-reverse">
                <span  type="button" *ngIf="printTitle" (click)="printService.print(['details', [columns,data]], printTitle, true)" class="btn btn-link"><i class="mdi mdi-printer"></i></span>
            </div> -->
            <ngx-datatable 
                class="bootstrap striped" 
                [loadingIndicator]="!data" 
                [reorderable]="true" 
                [rows]="data" 
                [headerHeight]="57" 
                [footerHeight]="0" 
                [scrollbarH]="true" 
                (scroll)="onScroll($event)"
                [rowHeight]="forChangeHistory ? 'auto' : 57" 
                [scrollbarV]="!forChangeHistory" 
                [style.height]="forChangeHistory? '' : (80 + 57 * (data ? data.length !== 0 ? data.length : 1 : 1)) + 'px'" 
                > 
                <ngx-datatable-column class="position-relative" [sortable]="false" [resizeable]="false" [maxWidth]="opColWidth" *ngIf="isWriteMode" [@rowAnimations]>
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <a *ngIf="isEditable" routerLink="{{editRow(row.id)}}" class="btn btn-link mr-1 px-1 py-0" title="Edit">
                            <i class="mdi mdi-pencil"></i>
                        </a>
                        <button *ngIf="showHistory" (click)="viewHistory(row.id)" class="btn btn-link btn-delete-user px-1 py-0" title="History">
                            <i class="mdi mdi-history"></i>
                        </button>
                        <button *ngIf="isDeletable" (click)="deleteRow(row.id)" class="btn btn-link btn-delete-user px-1 py-0" [disabled]="row.isDeleting" title="Delete">
                            <span *ngIf="row.isDeleting" class="spinner-border spinner-border-sm"></span>
                            <i class="mdi mdi-delete"></i>
                        </button>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngFor="let column of columns" name="{{column.name}}" [width]="column.width" [prop]="column.prop ? column.prop : undefined">
                    <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <span *ngIf="!isArray(value); else array">{{displayValue(value)}}</span>
                        <ng-template #array><div *ngFor="let item of value">{{displayValue(item)}}</div></ng-template>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <div *ngIf="pageData && pageData.count > pageData.limit" class="d-flex justify-content-end mt-3">
                <a *ngIf="floor(pageData.offset/pageData.limit/10)*10-10 > 0" class="btn btn-link p-0" title="Skip Back" (click)="selectPage(floor(pageData.offset/pageData.limit/10)*10-10)">
                    <i class="mdi mdi-chevron-double-left"></i>
                </a>
                <a *ngIf="pageData.offset > 0" class="btn btn-link p-0" title="Previous" (click)="selectPage(pageData.offset/pageData.limit-1)">
                    <i class="mdi mdi-chevron-left"></i>
                </a>
                <a *ngFor="let i of setPages(pageData.count,pageData.limit,pageData.offset)" class="mx-2" [ngClass]="{'font-weight-bold': i == pageData.offset/pageData.limit}" (click)="selectPage(i)">{{i+1}}</a>
                <a *ngIf="pageData.count - (pageData.offset*(pageData.offset/pageData.limit+1)) > 0" class="btn btn-link p-0" title="Next" (click)="selectPage(pageData.offset/pageData.limit+1)">
                    <i class="mdi mdi-chevron-right"></i>
                </a>
                <a *ngIf="floor(pageData.offset/pageData.limit/10)*10+10 < ceil(pageData.count/pageData.limit)" class="btn btn-link p-0" title="Skip Forward" (click)="selectPage(floor(pageData.offset/pageData.limit/10)*10+10)">
                    <i class="mdi mdi-chevron-double-right"></i>
                </a>
            </div>
        </div>
    </div>
</div>