<div class="d-flex flex-column h-100">
    <div class="jumbotron jumbotron-fluid pb-2 pt-5 text-light">
        <div class="container">
            <h1 class="display-4 text-nowrap" style="font-weight: 700 !important;"><i class="mdi mdi-ear-hearing"></i>ENNHSR <small *ngIf="isDesktop" style="font-weight: normal;">Lite</small></h1>
            <p class="lead">Electronic National Newborn Hearing Screening Registry</p>
        </div>
      </div>
    <div class="flex-grow-1">        
        <ng-template appAuthenticate></ng-template>
    </div>
</div>

