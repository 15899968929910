<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Speech Pathology Session
                <button type="button" (click)="this.printService.print(['details', print], 'Speech Pathology Session')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="evaluationOrTherapy">Session Type</label>
                            <div>   
                                <mat-radio-group formControlName="evaluationOrTherapy" [ngClass]="{ 'is-invalid': submitted && f.evaluationOrTherapy.errors }" >
                                    <mat-radio-button class="mr-3" [value]="'Evaluation'">Evaluation</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Therapy'">Therapy</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.evaluationOrTherapy.errors" class="invalid-feedback">
                                    <div *ngIf="f.evaluationOrTherapy.errors.required">Session Type is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="numberOfSession">Number of Session attended</label>
                            <input type="number" formControlName="numberOfSession" class="form-control"/>
                        </div>
                        <div class="form-group col-4">
                            <label for="dateOfSession">Date of Session</label>
                            <div>   
                                <input matInput [matDatepicker]="picker2" formControlName="dateOfSession" [ngClass]="{ 'is-invalid': submitted && f.dateOfSession.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <div *ngIf="submitted && f.dateOfSession.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfSession.errors.required">Date of Session is required</div>
                                </div>
                                <mat-datepicker #picker2></mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="chronologicalAge">Chronological Age</label>
                            <div>   
                                <input-age disabled formControlName="chronologicalAge" class="d-flex form-control px-1"></input-age>
                            </div>
                            <label for="audition">Audition</label>
                            <div class="form-row ">
                                <div class="form-group">
                                    <div>
                                        <mat-radio-group formControlName="audition">
                                            <mat-radio-button class="mr-3" [value]="'Advanced'">Advanced</mat-radio-button>
                                            <mat-radio-button class="mr-3" [value]="'At par'">At par</mat-radio-button>
                                            <mat-radio-button [value]="'Delayed'">Delayed</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="hearingAge">Hearing Age</label>
                            <div>   
                                <input-age formControlName="hearingAge" class="d-flex form-control px-1"></input-age>
                            </div>
                            <label for="auditionHA">Audition</label>
                            <div class="form-row ">
                                <div class="form-group">
                                    <div>
                                        <mat-radio-group formControlName="auditionHA">
                                            <mat-radio-button class="mr-3" [value]="'Advanced'">Advanced</mat-radio-button>
                                            <mat-radio-button class="mr-3" [value]="'At par'">At par</mat-radio-button>
                                            <mat-radio-button [value]="'Delayed'">Delayed</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-group col-12">
                            <label for="auditionNotes">Audition notes:</label>
                            <textarea class="form-control" rows="3" formControlName="auditionNotes"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="speech">Speech</label>
                            <div class="form-row ">
                                <div class="form-group">
                                    <div>
                                        <mat-radio-group formControlName="speech">
                                            <mat-radio-button class="mr-3" [value]="'Advanced'">Advanced</mat-radio-button>
                                            <mat-radio-button class="mr-3" [value]="'At par'">At par</mat-radio-button>
                                            <mat-radio-button [value]="'Delayed'">Delayed</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="speechHA">Speech</label>
                            <div class="form-row ">
                                <div class="form-group">
                                    <div>
                                        <mat-radio-group formControlName="speechHA">
                                            <mat-radio-button class="mr-3" [value]="'Advanced'">Advanced</mat-radio-button>
                                            <mat-radio-button class="mr-3" [value]="'At par'">At par</mat-radio-button>
                                            <mat-radio-button [value]="'Delayed'">Delayed</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-group col-12">
                            <label for="speechNotes">Speech notes:</label>
                            <textarea class="form-control" rows="3" formControlName="speechNotes"></textarea>
                        </div>
                    </div>
                    <div class="form-row ">        
                        <div class="form-group col-6">
                            <label for="receptiveLanguage">Receptive Language</label>
                            <div class="form-row ">
                                <div class="form-group">
                                    <div>
                                        <mat-radio-group formControlName="receptiveLanguage">
                                            <mat-radio-button class="mr-3" [value]="'Advanced'">Advanced</mat-radio-button>
                                            <mat-radio-button class="mr-3" [value]="'At par'">At par</mat-radio-button>
                                            <mat-radio-button [value]="'Delayed'">Delayed</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="receptiveLanguageHA">Receptive Language</label>
                            <div class="form-row ">
                                <div class="form-group">
                                    <div>
                                        <mat-radio-group formControlName="receptiveLanguageHA">
                                            <mat-radio-button class="mr-3" [value]="'Advanced'">Advanced</mat-radio-button>
                                            <mat-radio-button class="mr-3" [value]="'At par'">At par</mat-radio-button>
                                            <mat-radio-button [value]="'Delayed'">Delayed</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-group col-12">
                            <label for="receptiveLanguageNotes">Receptive Language notes:</label>
                            <textarea class="form-control" rows="3" formControlName="receptiveLanguageNotes"></textarea>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-group col-6">
                            <label for="expressiveLanguage">Expressive Language</label>
                            <div class="form-row ">
                                <div class="form-group">
                                    <div>
                                        <mat-radio-group formControlName="expressiveLanguage">
                                            <mat-radio-button class="mr-3" [value]="'Advanced'">Advanced</mat-radio-button>
                                            <mat-radio-button class="mr-3" [value]="'At par'">At par</mat-radio-button>
                                            <mat-radio-button [value]="'Delayed'">Delayed</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="expressiveLanguageHA">Expressive Language</label>
                            <div class="form-row ">
                                <div class="form-group">
                                    <div>
                                        <mat-radio-group formControlName="expressiveLanguageHA">
                                            <mat-radio-button class="mr-3" [value]="'Advanced'">Advanced</mat-radio-button>
                                            <mat-radio-button class="mr-3" [value]="'At par'">At par</mat-radio-button>
                                            <mat-radio-button [value]="'Delayed'">Delayed</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-group col-12">
                            <label for="expressiveLanguageNotes">Expressive Language notes:</label>
                            <textarea class="form-control" rows="3" formControlName="expressiveLanguageNotes"></textarea>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-group col-12">
                            <label for="expressiveLanguageNotes">Recommendation/General notes:</label>
                            <textarea class="form-control" rows="3" formControlName="recommendationGeneralNotes"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="interventionApproach">Intervention approach</label>
                            <div>
                                <mat-radio-group formControlName="interventionApproach" class="d-flex flex-column">
                                    <mat-radio-button class="mr-3" [value]="'Auditory Verbal Therapy'">Auditory Verbal Therapy</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Auditory Oral Therapy'">Auditory Oral Therapy</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Total Communication'">Total Communication</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Augmentative and Alternative Communication'">Augmentative and Alternative Communication</mat-radio-button>
                                    <mat-radio-button #approachOthers [value]="'Others'">Others</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="form-group ml-3" *ngIf="approachOthers.checked">
                                <label for="interventionApproachSpecify">Specify</label>
                                <input type="text" class="form-control" formControlName="interventionApproachSpecify">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>