<!-- <h1 *ngIf="isAddMode">Add User</h1>
<h1 *ngIf="!isAddMode">Edit User</h1> -->
<div class="container">
    <div class="card">
        <div  *ngIf="!isProfile" class="card-header">
            <span *ngIf="isAddMode">Add User</span>
            <span *ngIf="!isAddMode">Edit User</span>
        </div>
        <div  *ngIf="isProfile" class="card-header">
            <span>My Profile</span>
        </div>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label>User's Name</label>
                    <div class="form-row ml-3">
                    <div class="form-group col-4">
                        <label for="firstName">First Name</label>
                        <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">First Name is required</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="middleName">Middle Name</label>
                        <input type="text" formControlName="middleName" class="form-control"/>
                    </div>
                    <div class="form-group col-4">
                        <label for="lastName">Last Name</label>
                        <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                        </div>
                    </div>
                    </div>
                </div>
                <div *ngIf="!isFacilityUsers && !isProfile" class="form-group">
                    <mat-checkbox #isAdmin formControlName="isAdmin">
                        <label class="form-check-label">
                            Is Admin?
                        </label>
                    </mat-checkbox>
                    <mat-checkbox formControlName="isGuest" class="ml-3">
                        <label class="form-check-label">
                            Guest Account?
                        </label>
                    </mat-checkbox>
                </div>
                <div *ngIf="(!isFacilityUsers && !isProfile) && !f.isGuest.value" class="form-group">
                <!-- <div *ngIf="!isFacilityUsers || !isAdmin.checked" class="form-group"> -->
                    <label>Assigned Facilities</label>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <!-- <mat-chip-list #chipListFacility aria-label="" [disabled]="f.isGuest.value? true : null"> -->
                            <mat-chip-list #chipListFacility aria-label="">
                                <mat-chip
                                  *ngFor="let facility of facilities"
                                  [selectable]="selectable"
                                  [removable]="removable"
                                  (removed)="remove(facility)">
                                  {{facility.facility}}
                                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input
                                  placeholder="Add new facility"
                                  #facilityInput
                                  [formControl]="facility"
                                  [matAutocomplete]="auto"
                                  [matChipInputFor]="chipListFacility"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                  (matChipInputTokenEnd)="add($event)"
                                  (focusout)="clearFacilityInput()">
                              </mat-chip-list>
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let facility of facilityOptions$ | async" [value]="facility">
                                  {{facility.facility}}
                                </mat-option>
                              </mat-autocomplete>
                        </div>
                    </div>
                </div>
                <div *ngIf="(!isFacilityUsers && !isProfile) && !f.isGuest.value">
                    <div *ngFor="let facility of facilities; index as i" class="form-group ml-3">
                        <label>{{facility.facility}}: Roles</label>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <!-- <mat-chip-list #chipListRoles aria-label="" [disabled]="f.isGuest.value? true : null"> -->
                                <mat-chip-list #chipListRoles aria-label="">       
                                    <mat-chip
                                        *ngFor="let x of roles[i]"
                                        [selectable]="selectable"
                                        [removable]="removable"
                                        (removed)="removeRole(x,i)">
                                        {{x}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                        </mat-chip>
                                    <input
                                      placeholder="Add new role"
                                      #roleInput
                                      [formControl]="roleCtrl"
                                      [matAutocomplete]="auto1"
                                      [matChipInputFor]="chipListRoles"
                                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                      (matChipInputTokenEnd)="addRole($event)"
                                      [hidden]="isProfile"
                                      (focusout)="clearRoleInput()">
                                  </mat-chip-list>
                                  <mat-autocomplete [attr.id]="'element-'+i" #auto1="matAutocomplete" (optionSelected)="selectedRole($event)">
                                    <mat-option *ngFor="let role of filteredRoles | async" [value]="role">
                                      {{role}}
                                    </mat-option>
                                  </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isProfile">
                    <label>Role(s) in facility</label>
                    <div class="mb-2 ml-3" *ngFor="let facility of facilities; index as i">
                        <label>{{facility.facility}}: </label>
                        <div class="ml-3" *ngFor="let x of roles[i]" >
                            {{x}}
                        </div>
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>System Credentials</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="username">Username</label>
                            <input [attr.disabled]="isProfile" type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Username is required</div>
                            </div>
                        </div>
                        <div *ngIf="!isAddMode" class="form-group col-4 ml-3">
                            <label for="password">
                                Password
                            </label>
                            <div>
                                <button type="button" (click)="resetPassword()" [disabled]="loading" class="btn btn-warning">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Reset password
                                </button>
                            </div>
                        </div>
                        <!-- <div *ngIf="isAddMode" class="form-group col-4 ml-3">
                            <div>
                                Generate Password
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="form-group ml-3">
                    <label>Linked Profiles</label>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <mat-chip-list #chipListProfile aria-label="">
                                <mat-chip
                                  *ngFor="let profile of profiles"
                                  [selectable]="selectable">
                                  <span md-highlight-text="ctrl.searchText">{{profile.profileName}} </span>
                                </mat-chip>
                              </mat-chip-list>
                        </div>
                    </div>
                </div> -->
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="nhsrcAccredNo">NHSRC Accreditation Number</label>
                        <input [attr.disabled]="isProfile" type="text" formControlName="nhsrcAccredNo" class="form-control"/>
                    </div>
                    <div class="form-group col-4">
                        <label for="nhsrcAccredDate">Accreditation Validity</label>
                        <div>
                            <input formControlName="nhsrcAccredDate" matInput [matDatepicker]="picker"/>
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <i class="mdi mdi-calendar"></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="designation">Designation</label>
                    <input type="text" formControlName="designation" class="form-control"/>
                </div>
                <div class="form-row ml-3">
                    <div class="form-group col-4">
                        <label for="profession">Profession</label>
                        <input type="text" formControlName="profession" class="form-control"/>
                    </div>
                    <div class="form-group col-4">
                        <label for="prcNo">PRC Number</label>
                        <input type="text" formControlName="prcNo" class="form-control"/>
                    </div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                    <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                </div>
            </form> 
        </div>
    </div>
</div>
<!-- <app-form [form]="form" [formTitle]="formTitle" (formSubmit)="onSubmit()" (formClose)="this.dialogRef.close()" ></app-form> -->