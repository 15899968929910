import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SealDistributionService } from './seal-distribution.service';

@Injectable({
  providedIn: 'root'
})
export class SealsService {
  private modelName = "seal";
  public sealSubject: BehaviorSubject<any>;
  public seal: Observable<any>;
  public allSealsSubject: BehaviorSubject<any>;
  public allSeals: Observable<any>;

  private requestCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public requestCount$: Observable<number> = this.requestCountSubject.asObservable();

  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService,
    private sealDistributionService: SealDistributionService
  ) {
    this.sealSubject = new BehaviorSubject<any>(null);
    this.seal = this.sealSubject.asObservable();
    this.allSealsSubject = new BehaviorSubject<any>(null);
    this.allSeals = this.allSealsSubject.asObservable();
   }

  public get recordsValue() {
    return this.sealSubject.value;
  }
  public get recordsObservable() {
    return this.seal;
  }

  public get allRecordsValue() {
    return this.allSealsSubject.value;
  }
  public get allRecordsObservable() {
    return this.allSeals;
  }

  incrementCounter(): void {
    const currentValue = this.requestCountSubject.value;
    this.requestCountSubject.next(currentValue + 1);
  }

  resetCounterObservableValue():void {
    this.requestCountSubject.next(0);
  }

  getAll(sealDistribution?: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?${sealDistribution ? "sealDistribution="+sealDistribution:""}&~resolveRef=1`)
    .pipe(
      map(res => {
        //localStorage.setItem(this.modelName+'Records', JSON.stringify(res));
        this.incrementCounter();
        this.sealSubject.next(res);
        return res;
      })
    );   
  }

  getAllCount(sealDistribution?: string, limit?: number, used?: boolean){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?${sealDistribution ? "sealDistribution="+sealDistribution:""}${limit ? "&~limit=" + limit : ""}${used ? "&patient=!": ""}`)
    .pipe(
      map(res => {
        return res;
      })
    );   
  }

  getAllAbsolute(){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?&~limit=100&~resolveRef=1`)
    .pipe(
      map(res => {
        //localStorage.setItem("allSeals", JSON.stringify(res));
        this.allSealsSubject.next(res);
        return res;
      })
    );   
  }

  getAllByFacility(facility?: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?${facility ? "_modelId="+facility:""}&~resolveRef=1`)
    .pipe(
      map(res => {
        //localStorage.setItem(this.modelName+'Records', JSON.stringify(res));
        this.sealSubject.next(res);
        return res;
      })
    );   
  }

  getAllBySerialNumber(seals: string[], batchCode: string){
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}`,
    {
        "where": {
          "$and":[{
              "serialNumber": {
                  "$in": seals
              },
              "batchCode": {
                "$eq": batchCode
            },
          }]
        }
    })
  }

  getAllBySerialNumber2(seals: string, batchCode:string){
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}`,
    {
        "where": {
          "$and":[{
          "$partialSearch": {
            "$text": seals,
            "$fields": ["serialNumber"],
            "$matchWordMiddle": false
          },
          "batchCode": {
            "$eq": batchCode
          },
          "patient": {
            "$eq": null
          }
        }]}
    })
  }

  getById(id: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}`)
  }

  add(item: any, sealDistributionId: string){
    return this.sealDistributionService.getById(sealDistributionId).pipe(mergeMap(
      sealDistribution => {
        Object.keys(item).forEach(k => (!item[k] && item[k] !== undefined) && delete item[k]);
        var tempSeals = []
        if(typeof item["serialNumber"] != 'undefined' && item["serialNumber"])
          tempSeals.push(String(item["serialNumber"]).padStart(10, '0'))
        else{
          for (let seal = parseInt(item["rangeFrom"]);seal <= parseInt(item["rangeTo"]); seal++) {
            tempSeals.push(seal.toString().padStart(10, '0'))
          }
        }
        return this.getAllBySerialNumber(tempSeals, item["batchCode"])
        .pipe(
          mergeMap(res => {
            if(res["count"] === 0){
              var groupId = Utils.generateGuid()
              if(typeof item["serialNumber"] != 'undefined' && item["serialNumber"]){
                item = Utils.generateMeta(item);
                item['meta.facilityCode'] = sealDistribution['dataModel']['meta']['facilityCode'];
                item["sealDistribution"] = sealDistributionId;
                item["groupId"] = groupId;
                item["serialNumber"] = String(item["serialNumber"]).padStart(10, '0');
                var guid = Utils.generateGuid()
                var sync = Utils.convertToSync(item, this.modelName, guid);
              } else {
                var sync = new Sync();
                var nmi = []
                for (let seal = parseInt(item["rangeFrom"]);seal <= parseInt(item["rangeTo"]); seal++) {
                  var guid = Utils.generateGuid();
                  var sealSync = {"serialNumber":seal.toString().padStart(10, '0'), "batchCode":item["batchCode"], "sealDistribution":sealDistributionId, "groupId":groupId}
                  sealSync = Utils.generateMeta(sealSync);
                  sealSync['meta.facilityCode'] = sealDistribution['dataModel']['meta']['facilityCode'];
                  var x = Utils.convertToSync(sealSync, this.modelName, guid);
                  x.fieldUpdates.forEach(item => sync.fieldUpdates.push(item))
                  nmi.push(x.newModelIds["seal"][0]) 
                }
                sync.newModelIds = {"seal":nmi};
              }
              return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
            }
            else
              return of(res);
          })
        )
    }))
    //return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    
    // .pipe(
    //   mergeMap(res => {
    //     //check for multiple insert
    //     return this.getById(res['insertedModelIds'][this.modelName][guid]['assignedId'])
    //     .pipe(
    //       map(newItem => {
    //         var records = this.sealSubject.value;
    //         records['results'].splice(0,0,newItem);
    //         localStorage.setItem(this.modelName+'Records'+sealDistribution, JSON.stringify(records));
    //         this.sealSubject.next(records);
    //         return res;
    //       })
    //     );
    //   })
    // ); 
  }

  update(item: any, modelId: string, versionId: string,original:any) {
    var original = original;
    item["meta"] = original["dataModel"]["meta"];
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = Date.now()
    const user = this.userService.userValue;
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
      /*   var record = this.recordsSubject.value;
        record.dataModel = item;
        record.versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        record.updatedBy = JSON.parse(localStorage.getItem('user')).id
        record.updatedByName = JSON.parse(localStorage.getItem('user')).firstName + " " + JSON.parse(localStorage.getItem('user')).lastName
        console.log(JSON.stringify(record))
        this.recordsSubject.next(record); */
        return res;
      })
    );
  }
}
