import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientRoutingModule } from './patient-routing.module';
import { LayoutComponent } from './layout/layout.component';

import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormsModule } from './forms/forms.module';
import { SharedModule } from '@app/shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { PatientSearch } from './patient-search/patient-search.component';
import { dateAdapterProvider, dateformatProvider } from '@app/shared/date-format';

@NgModule({
  declarations: [
    LayoutComponent,
    PatientSearch
  ],
  imports: [  
    PatientRoutingModule,
    SharedModule,

    FormsModule,

    CommonModule,
    FormsModule,
    AngularFormsModule,
    ReactiveFormsModule,

    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    dateAdapterProvider, dateformatProvider
  ]
})
export class PatientModule { }
