<div class="card my-3">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card-header d-flex justify-content-between">
            <span>Hearing Aid/Devices</span>
            <div *ngIf="validatePrivilege()">
                <!-- <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                             -->
                <button type="button" *ngIf="this.editable && !this.editing && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
                <button type="submit" *ngIf="this.editable && this.editing" class="btn btn-success ml-1 px-2 py-0"><i class="mdi mdi-content-save"></i></button>
                <button type="button" *ngIf="this.editable && this.editing" (click)="cancelEdit()" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
            </div>
        </div>
        <div class="card-body">   
            <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="dateOfFirstFitting">Date of First Fitting</label>
                            <div>
                                <input matInput [matDatepicker]="picker" formControlName="dateOfFirstFitting" [ngClass]="{ 'is-invalid': submitted && f.dateOfFirstFitting.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <div *ngIf="submitted && f.dateOfFirstFitting.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfFirstFitting.errors.required">Date of First Fitting is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Hearing Aid/Device Records</label>
                            <app-table [modelName]="'hearingAid'" [isWriteMode]="this.editing" [data]="hearingAidDevices$ | async" [pageData]="hearingAidDevicesPage$ | async" [printTitle]="'Hearing Aid/Device Records'" [columns]="columns" [addLink]="'hearing-aid-devices/add-hearing-aid-devices'" [editLink]="'hearing-aid-devices/edit-hearing-aid-devices'" (pageEvent)="getPageHearingAidDevices($event)" (deleteRowEvent)="deleteHearingAidDevices($event)">
                            </app-table>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-12">
                            <label>Aided Tests</label>
                            <app-table [isWriteMode]="false" [noAdd]="true" [printTitle]="'Hearing Aid/Device Aided Tests'" [data]="hearingAidDevices$ | async" [pageData]="hearingAidDevicesPage$ | async" [columns]="columns2" (pageEvent)="getPageHearingAidDevices($event)">
                            </app-table>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div> 
    </form>
</div>