<div [ngClass]="{'container pb-4': isAdd}">
    <div class="card my-3">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                <span *ngIf="isAdd">Add Patient</span>
                <span *ngIf="!isAdd">Patient Details</span>
                <div *ngIf="validatePrivilege()">
                    <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" (click)="printService.print((isAdd ? [print] : ['details', print]), 'Patient Details')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
                    <button type="button" *ngIf="this.editable && !this.editing" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
                    <button type="submit" *ngIf="this.editable && this.editing" class="btn btn-success ml-1 px-2 py-0"><i class="mdi mdi-content-save"></i></button>
                    <button type="button" *ngIf="this.editable && this.editing" (click)="cancelEdit()" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
                </div>
            </div>
            <div #print class="card-body ">   
                <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="patientType">Patient Type</label>
                        </div>
                        <div class="form-group col-4">
                            <label for="facilityCode">Registration Facility Name and Code</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <select class="form-control" formControlName="patientType">
                                <option disabled selected>Choose...</option>
                                <option>New</option>
                                <option>Old</option>
                            </select>
                        </div>
                        <div class="form-group col-4">
                            <input disabled type="text" class="form-control" formControlName="facilityCode" />
                        </div>
                        <div class="col-auto my-1">
                            <div class="form-check form-check-inline">
                                <mat-checkbox formControlName="patientExpired">
                                    Patient Expired
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <label>Patient's Name</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="lastName">Last</label>
                            <input type="text" class="form-control" formControlName="lastName" />
                        </div>
                        <div class="form-group col-4">
                            <label for="firstName">Given</label>
                            <input type="text" class="form-control" formControlName="firstName" />
                        </div>
                        <div class="form-group col-4">
                            <label for="middleName">Middle</label>
                            <input type="text" class="form-control" formControlName="middleName" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="sex">Sex</label>
                        <div>
                            <mat-radio-group formControlName="sex" [ngClass]="{ 'is-invalid': submitted && f.sex.errors }" >
                                <mat-radio-button class="mr-3" [value]="'male'">Male</mat-radio-button>
                                <mat-radio-button class="mr-3" [value]="'female'">Female</mat-radio-button>
                                <mat-radio-button [value]="'unknown'">Unknown</mat-radio-button>
                            </mat-radio-group>
                            <div *ngIf="submitted && f.sex.errors" class="invalid-feedback">
                                <div *ngIf="f.sex.errors.required">Sex is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <mat-checkbox #philhealth formControlName="philhealth">
                                <label class="form-check-label" for="philhealth">
                                    Philhealth   
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="philhealth.checked" class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="philhealthPIN">PIN</label>
                            <input type="text" min="0" maxlength="12" (keydown)="phoneKeyDown($event)" class="form-control" formControlName="philhealthPin" />
                        </div>
                        <div class="form-group col-4">
                            <label for="philhealthSponsor">Sponsor</label>
                            <select class="form-control" formControlName="philhealthSponsor">
                                <option>Citizen of other countries working/residing/studying in the Philippines</option>
                                <option>Enterprise Owner</option>
                                <option>Family Driver</option>
                                <option>Filipino with Dual Citizenship</option>
                                <option>Government - Casual</option>
                                <option>Government - Contractual/Project-Based</option>
                                <option>Government - Permanent/Regular</option>
                                <option>Household Help/Kasambahay</option>
                                <option>Informal Sector</option>
                                <option>Local Government Unit</option>
                                <option>Migrant Worker - Land-Based</option>
                                <option>Migrant Worker - Sea-Based</option>
                                <option>National Government Agency</option>
                                <option>Naturalized Filipino Citizen</option>
                                <option>NHTS-PR</option>
                                <option>Organized group</option>
                                <option>POSFI</option>
                                <option>Private - Casual</option>
                                <option>Private - Contractual/Project-Based</option>
                                <option>Private - Permanent/Regular</option>
                                <option>Retiree/Pensioner</option>
                                <option>Self-Earning Individual</option>
                                <option>With 120 months contribution and has reached retirement age</option>
                            </select>
                        </div>
                        <div class="form-group col-4">
                            <label for="philhealthExpiration">Expiration Date</label>
                            <input matInput [matDatepicker]="picker" formControlName="philhealthExpiration">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <i class="mdi mdi-calendar"></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="dateOfBirth">Date of Birth</label>
                            <div>
                                <input matInput [matDatepicker]="picker2" formControlName="dateOfBirth" [ngClass]="{ 'is-invalid': submitted && f.dateOfBirth.errors }" >
                                <mat-datepicker-toggle matSuffix [for]="picker2" >
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                <div *ngIf="submitted && f.dateOfBirth.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfBirth.errors.required">Date of Birth is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="timeOfBirth">Time of Birth</label>
                            <!-- <input type="text" class="form-control" formControlName="timeOfBirth" /> -->
                            <!-- <ngx-timepicker-field [controlOnly]="true" formControlName="timeOfBirth" class="form-control p-1 h-auto bg-white" [disabled]="this.editable && !this.editing ? true: null"></ngx-timepicker-field> -->
                            <input-time formControlName="timeOfBirth" class="d-flex form-control px-2"></input-time>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="birthOutcome">Birth Outcome</label>
                        </div>
                        <div class="form-group col-4">
                            
                        </div>
                        <div class="form-group col-4" *ngIf="multipleGestation.checked">
                            <label for="birthOrder">Birth Order</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <select class="form-control" formControlName="birthOutcome">
                                <option>Live Birth</option>
                                <option>Stillbirth</option>
                                <option>Abortion</option>
                            </select>
                        </div>
                        <div class="form-group col-4 d-flex align-items-end">
                            <div class="form-check form-check-inline">
                                <mat-checkbox #multipleGestation formControlName="multipleGestation">
                                    Multiple Gestation (i.e. twins, triplets)
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="form-group col-4" *ngIf="multipleGestation.checked">
                            <input type="number" min="0" class="form-control" formControlName="birthOrder" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="birthWeight">Birth Weight (in kg)</label>
                            <input type="number" min="0" step=".01" class="form-control" formControlName="birthWeight" [ngClass]="{ 'is-invalid': submitted && f.birthWeight.errors }" />
                            <div *ngIf="submitted && f.birthWeight.errors" class="invalid-feedback">
                                <div *ngIf="f.birthWeight.errors.required">Birth Weight is required</div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label for="gestationAge">Gestation Age (in weeks)</label>
                            <input type="number" min="0" class="form-control" formControlName="gestationAge" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="gestationNotes">Gestation Notes</label>
                            <textarea class="form-control" rows="3" formControlName="gestationNotes"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="facilityOfBirth">Facility of Birth</label>
                            <input type="text" class="form-control" formControlName="facilityOfBirth" />
                        </div>
                    </div>
                    <label>Mother's Name</label>
                    <div class="form-row ml-3">
                        <div class="form-group col-4">
                            <label for="motherLastName">Last</label>
                            <input type="text" class="form-control" formControlName="motherLastName" [ngClass]="{ 'is-invalid': submitted && f.motherLastName.errors }" />
                            <div *ngIf="submitted && f.motherLastName.errors" class="invalid-feedback">
                                <div *ngIf="f.motherLastName.errors.required">Last Name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="motherFirstName">Given</label>
                            <input type="text" class="form-control" formControlName="motherFirstName" [ngClass]="{ 'is-invalid': submitted && f.motherFirstName.errors }" />
                            <div *ngIf="submitted && f.motherFirstName.errors" class="invalid-feedback">
                                <div *ngIf="f.motherFirstName.errors.required">Given Name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="motherMiddleName">Middle</label>
                            <input type="text" class="form-control" formControlName="motherMiddleName" [ngClass]="{ 'is-invalid': submitted && f.motherMiddleName.errors }" />
                            <div *ngIf="submitted && f.motherMiddleName.errors" class="invalid-feedback">
                                <div *ngIf="f.motherMiddleName.errors.required">Middle Name is required</div>
                            </div>
                        </div>
                    </div>
                    <!-- <app-address [streetControlName]="'streetAddress'" [psgcControlName]="'psgcCode'"></app-address> -->
                    <app-address [psgcControl]="f.psgcCode" [streetControl]="f.streetAddress" [submitted]="submitted"></app-address>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="phoneNumber">Phone Number</label>
                            <input type="number" min="0" class="form-control" formControlName="phoneNumber" />
                        </div>
                        <!-- <div class="form-group col-4">
                            <label for="initScreenDate">Initial Screening Date</label>
                            <div>
                                <input matInput [matDatepicker]="picker3" formControlName="initScreenDate" [ngClass]="{ 'is-invalid': submitted && f.initScreenDate.errors || submitted && form.errors}" >
                                <mat-datepicker-toggle matSuffix [for]="picker3" >
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                                <div *ngIf="submitted && f.initScreenDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.initScreenDate.errors.required">Initial Screening Date is required</div>
                                </div>
                                <div *ngIf="submitted && form.errors" class="invalid-feedback">
                                    <div *ngIf="form.errors.invalidScreening">Initial Screening Date must be within 30 days from the Date of Birth</div>
                                </div>
                            </div>
                        </div> -->
                    </div>                 
                    <div class="form-group mt-3" *ngIf="isAdd">
                        <div class="d-flex justify-content-center">
                            <div class="col-md-10">
                                <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Register Patient
                                </button>
                                <div class="d-flex justify-content-around flex-wrap mt-2">
                                    <!-- <a routerLink="/register" class="">Register</a> -->
                                    <a routerLink="../">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>
