import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class InterventionApproachOfPatientsReport {
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Auditory Verbal Therapy'
    // })
    // auditoryVerbalTherapy: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Auditory Oral Therapy'
    // })
    // auditoryOralTherapy: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Total Communication'
    // })
    // totalCommunication: string;

    @autoserializeAs(String)
    @Column({
        columnName: 'Intervention Approach'
    })
    interventionApproach: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Count'
    })
    count: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Percent'
    })
    percent: string;
}
