<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} PEDS Screening
                <button type="button" (click)="this.printService.print(['details', print], 'PEDS Screening')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="dateOfScreening">Date of Screening</label>
                            <div>   
                                <input matInput [matDatepicker]="picker" formControlName="dateOfScreening" [ngClass]="{ 'is-invalid': submitted && f.dateOfScreening.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <div *ngIf="submitted && f.dateOfScreening.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfScreening.errors.required">Date of Session is required</div>
                                </div>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="currentAge">Current Age</label>
                            <input-age disabled formControlName="currentAge" class="d-flex form-control px-1"></input-age>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label for="informant">Informant</label>
                            <div>
                                <mat-radio-group formControlName="informant"  [ngClass]="{ 'is-invalid': submitted && f.informant.errors }">
                                    <mat-radio-button class="mr-3" [value]="'Mother'">Mother</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Father'">Father</mat-radio-button>
                                    <mat-radio-button class="mr-3" [value]="'Guardian'">Guardian</mat-radio-button>
                                    <mat-radio-button #others [value]="'Others'">Others</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.informant.errors" class="invalid-feedback">
                                    <div *ngIf="f.informant.errors.required">Informant is required</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="others.checked" class="form-group col-4">
                            <label for="informantSpecify">Specify</label>
                            <input type="text" formControlName="informantSpecify" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Decision Pathways</label>
                            <div class="d-flex">
                                <mat-checkbox class="mr-2" formControlName="dPA">
                                    <label class="form-check-label" for="dPA">
                                        A
                                    </label>
                                </mat-checkbox>
                                <mat-checkbox class="mr-2" formControlName="dPB">
                                    <label class="form-check-label" for="dPB">
                                        B
                                    </label>
                                </mat-checkbox>
                                <mat-checkbox class="mr-2" formControlName="dPC">
                                    <label class="form-check-label" for="dPC">
                                        C
                                    </label>
                                </mat-checkbox>
                                <mat-checkbox class="mr-2" formControlName="dPD">
                                    <label class="form-check-label" for="dPD">
                                        D
                                    </label>
                                </mat-checkbox>
                                <mat-checkbox class="mr-2" formControlName="dPE">
                                    <label class="form-check-label" for="dPE">
                                        E
                                    </label>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <label>Areas of Concern</label>
                    <div class="form-row">
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #gc formControlName="aOFGC">
                                <label class="form-check-label" for="aOFGC">
                                    1. GC - Global/Cognitive
                                </label>
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #b formControlName="aOFB">
                                <label class="form-check-label" for="aOFB">
                                    6. B - Behavior
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <div *ngIf="gc.checked" class="ml-3">
                                <label for="aOFGCReferTo">Refer to</label>
                                <input type="text" formControlName="aOFGCReferTo" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <div *ngIf="b.checked" class="ml-3">
                                <label for="aOFBReferTo">Refer to</label>
                                <input type="text" formControlName="aOFBReferTo" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #ela formControlName="aOFELA">
                                <label class="form-check-label" for="aOFELA">
                                    2. ELA - Expressive Language and Articulation
                                </label>
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #se formControlName="aOFSE">
                                <label class="form-check-label" for="aOFSE">
                                    7. SE - Social-Emotional
                                </label>
                            </mat-checkbox>                            
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <div *ngIf="ela.checked" class="ml-3">
                                <label for="aOFELAReferTo">Refer to</label>
                                <input type="text" formControlName="aOFELAReferTo" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <div *ngIf="se.checked" class="ml-3">
                            <label for="aOFSEReferTo">Refer to</label>
                            <input type="text" formControlName="aOFSEReferTo" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #rl formControlName="aOFRL">
                                <label class="form-check-label" for="aOFRL">
                                    3. RL - Receptive Language
                                </label>
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #sh formControlName="aOFSH">
                                <label class="form-check-label" for="aOFSH">
                                    8. SH - Self-Help
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <div *ngIf="rl.checked" class="ml-3">                                
                                <label for="aOFRLReferTo">Refer to</label>
                                <input type="text" formControlName="aOFRLReferTo" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <div *ngIf="sh.checked" class="ml-3">
                                <label for="aOFSHReferTo">Refer to</label>
                                <input type="text" formControlName="aOFSHReferTo" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #fm formControlName="aOFFM">
                                <label class="form-check-label" for="aOFFM">
                                    4. FM - Fine Motor
                                </label>
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #s formControlName="aOFS">
                                <label class="form-check-label" for="aOFS">
                                    9. S - School
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <div *ngIf="fm.checked" class="ml-3">
                                <label for="aOFFMReferTo">Refer to</label>
                                <input type="text" formControlName="aOFFMReferTo" class="form-control" />                                
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <div *ngIf="s.checked" class="ml-3">
                                <label for="aOFSReferTo">Refer to</label>
                                <input type="text" formControlName="aOFSReferTo" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #gm formControlName="aOFGM">
                                <label class="form-check-label" for="aOFGM">
                                    5. GM - Gross Motor
                                </label>
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-6 mb-0">
                            <mat-checkbox #oh formControlName="aOFOH">
                                <label class="form-check-label" for="aOFOH">
                                    10. O/H - Other/Health Concerns
                                </label>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <div *ngIf="gm.checked" class="ml-3">
                                <label for="aOFGMReferTo">Refer to</label>
                                <input type="text" formControlName="aOFGMReferTo" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <div *ngIf="oh.checked" class="ml-3">
                                <label for="aOFOHReferTo">Refer to</label>
                                <input type="text" formControlName="aOFOHReferTo" class="form-control" />  
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-3 d-flex align-items-end">
                            <mat-checkbox #refDevPed formControlName="referredToDevPed">
                                <label class="form-check-label" for="referredToDevPed">
                                    Referred to Dev Ped
                                </label>
                            </mat-checkbox>
                        </div>
                        <div *ngIf="refDevPed.checked" class="form-group col-4">
                            <label for="dateReferredToDevPed">Date Referred by Dev Ped</label>
                            <div>   
                                <input matInput [matDatepicker]="picker2" formControlName="dateReferredToDevPed" [ngClass]="{ 'is-invalid': submitted && f.dateReferredToDevPed.errors }" />
                                <div *ngIf="submitted && f.dateReferredToDevPed.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateReferredToDevPed.errors.required">Date Reffered to Dev Ped is required</div>
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </div>
                        </div>
                        <div *ngIf="refDevPed.checked" class="form-group col-4">
                            <label for="dateSeenByDevPed">Date seen by Dev Ped</label>
                            <div>   
                                <input matInput [matDatepicker]="picker3" formControlName="dateSeenByDevPed">
                                <mat-datepicker-toggle matSuffix [for]="picker3">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="toolsUsed">Tools Used</label>
                            <select class="form-control" formControlName="toolsUsed">
                                <option disabled selected>Choose...</option>
                                <option>Griffiths Mental Developmental scales for children (GMDS) for 0 to 6 years</option>
                                <option>Battelle Developmental Inventory (BDI) for 0 to 8 years</option>
                                <option>Vineland Adaptive and Behavior scales (VABS) from birth to 90 years</option>
                                <option #othersTools >Others</option>
                            </select>
                            <div *ngIf="othersTools.selected" class="form-group col-6">
                                <label for="toolsUsedSpecify">Specify</label>
                                <input type="text" formControlName="toolsUsedSpecify" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="primaryDevelopmentalDiagnosis">Primary Developmental Diagnosis</label>
                            <input type="text" #pDDInput formControlName="primaryDevelopmentalDiagnosis" class="form-control" [matAutocomplete]="autoPDD" [ngClass]="{ 'is-invalid': submitted && f.primaryDevelopmentalDiagnosis.errors }" />
                            <mat-autocomplete #autoPDD="matAutocomplete" (optionSelected)="selectedPDD($event)">
                              <mat-option *ngFor="let pDD of pDDOptions$ | async" [value]="pDD.id">
                                {{pDD.icdCode}} - {{pDD.description}}
                              </mat-option>
                            </mat-autocomplete>
                            <div *ngIf="submitted && f.primaryDevelopmentalDiagnosis.errors" class="invalid-feedback">
                                <div *ngIf="f.primaryDevelopmentalDiagnosis.errors.required">Primary Developmental Diagnosis is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="comorbidity">Comorbidity</label>
                            <!-- <input type="text" #comorbidityInput formControlName="comorbidity" class="form-control" [matAutocomplete]="autoComorbidity" [ngClass]="{ 'is-invalid': submitted && f.comorbidity.errors }" /> -->
                            <mat-chip-list #chipList aria-label="Comorbidity selection">
                                <mat-chip
                                  *ngFor="let comorbidity of comorbidityList"
                                  [selectable]="selectable"
                                  [removable]="removable"
                                  (removed)="remove(comorbidity)">
                                  {{comorbidity}}
                                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input
                                  placeholder="Add new Comorbidity"
                                  #comorbidityInput
                                  [formControl]="comorbidity"
                                  [matAutocomplete]="autoComorbidity"
                                  [matChipInputFor]="chipList"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodesComorbidity"
                                  (matChipInputTokenEnd)="addComorbidity($event)">
                            </mat-chip-list>
                            <mat-autocomplete #autoComorbidity="matAutocomplete" (optionSelected)="selectedComorbidity($event)">
                              <mat-option *ngFor="let comorbidity of comorbidityOptions$ | async" [value]="comorbidity.id">
                                {{comorbidity.icdCode}} - {{comorbidity.description}}
                              </mat-option>
                            </mat-autocomplete>
                            <!-- <div *ngIf="submitted && f.comorbidity.errors" class="invalid-feedback">
                                <div *ngIf="f.comorbidity.errors.required">Comorbidity is required</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4 d-flex align-items-end">
                            <mat-checkbox #refMHS formControlName="referredForMentalHealthScreening">
                                <label class="form-check-label" for="referredForMentalHealthScreening">
                                    Referred for Mental Health Screening
                                </label>
                            </mat-checkbox>
                        </div>
                        <div *ngIf="refMHS.checked" class="form-group col-4">
                            <label for="screeningDate">Screening Date</label>
                            <div>   
                                <input matInput [matDatepicker]="picker4" formControlName="screeningDate"/>
                                <div *ngIf="submitted && f.screeningDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.screeningDate.errors.required">Screening Date is required</div>
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker4">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker4></mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>