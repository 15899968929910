import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/shared/alert/alert.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { RequestsSealDistributionService } from './requests-seal-distribution.service';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  sealRequest$ : Observable<any>;
  
  constructor( private requestsSealDistributionService: RequestsSealDistributionService, private alertService: AlertService ) { }

  ngOnInit(): void {
    this.requestsSealDistributionService.getAll().subscribe();
    this.sealRequest$ =  this.requestsSealDistributionService.recordsObservable.pipe()
  }

  cancelRequest(request: any): void {
    if(confirm("Are you sure you want to cancel this request?")){
      this.requestsSealDistributionService.updateStatus(request,"ADMIN_CANCELED")
      .pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Request canceled', { keepAfterRouteChange: true });
          },
          error: error => {
              this.alertService.error(error);
          }
      });
    }
  }
}
