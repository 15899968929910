<div class="d-flex">
    <span *ngIf="files && !newFile" class="d-flex align-items-center">{{files[latestFileIndex].fileName}}</span>
    <input *ngIf="!files || newFile" [disabled]="disabled" type="file" accept="application/pdf,image/jpeg" class="form-control" (change)="onChange($event.target.files)"/>
    <button *ngIf="files && !newFile" [disabled]="disabled" (click)="download(files[latestFileIndex])" type="button" class="btn btn-link mr-1" title="download"><i class="mdi mdi-download"></i></button>
    <button *ngIf="files && !newFile" [disabled]="disabled" (click)="newFile = true" type="button" class="btn btn-link mr-1" title="new"><i class="mdi mdi-pencil-plus"></i></button>    
    <button *ngIf="files && !newFile" [disabled]="disabled" (click)="viewHistory()" type="button" class="btn btn-link mr-1" title="History"><i class="mdi mdi-history"></i></button>
    <button *ngIf="files && newFile" [disabled]="disabled" (click)="newFile = false" type="button" class="btn btn-link mr-1" title="cancel"><i class="mdi mdi-cancel"></i></button>
    
    
    
    
</div>



