<div class="container pb-3">
    <div class="d-flex flex-column h-100 m-3">
        <!-- <div class="mb-3">
            <button (click)="back()" class="btn btn-success"><i class="mdi mdi-arrow-left"></i> Back</button>
        </div> -->
        <div class="card">
            <div class="card-header">
                <span>Create Profile</span>
            </div>
            <div class="card-body">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-row ml-3">
                        <div class="form-group col-12">
                            <label for="username">Enter Profile name</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Profile name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <div class="d-flex justify-content-center">
                            <div class="col-md-10">
                                <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Register Profile
                                </button>
                                <div class="d-flex justify-content-around flex-wrap mt-2">
                                    <!-- <a routerLink="/register" class="">Register</a> -->
                                    <a routerLink="../">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>