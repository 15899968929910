import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appAuthenticate]'
})
export class AuthenticateDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
