<div class="d-flex flex-column h-100">
    <div class="subnav-panel w-100 p-3">
        <div class="container ml-0 px-0">
            <div class="col-md-8 px-0">
                <div class="d-flex">
                    <div *ngIf="!showIncoming" class="flex-grow-1 card px-2 p-1 mb-0" [ngClass]="{'bg-danger': daysSinceLastSync >= 15, 'bg-warning' : daysSinceLastSync <= 14 && daysSinceLastSync >= 8 , 'bg-success': daysSinceLastSync <= 7}">
                        <div class="d-flex align-items-center h-100 text-light">
                            <span><i class="mdi mdi-calendar-sync mr-1"></i> {{ daysSinceLastSync }} day(s) since last sync</span>
                        </div>
                    </div>
                    <div *ngIf="showIncoming" class="flex-grow-1 card bg-success px-2 p-1 mb-0">
                        <div class="d-flex align-items-center h-100 text-light">
                            <span><i class="mdi mdi-check mr-1"></i> Up to date</span>
                        </div>
                    </div>
                    <button [disabled]="!enableSync" (click)="push()" class="btn btn-primary text-nowrap ml-2"><i class="mdi mdi-cloud-sync"></i> Sync data
                        <span *ngIf="syncing" class="spinner-border spinner-border-sm mr-1"></span>
                    </button>
                   <!--  <button (click)="newSync()" class="btn btn-secondary text-nowrap ml-2"><i class="mdi mdi-cloud-sync"></i> New Sync Date</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="container flex-grow-1 ml-0 py-2">
        <div class="row h-100 p-2">
            <ng-template #loading>
                <div class="d-flex justify-content-center">
                    <span>Loading changes</span>
                </div>
                <div class="d-flex justify-content-center w-100 p-4">
                    <span class="spinner-border text-secondary"></span>
                </div>
            </ng-template>
            <ng-template #loadingBar>
                <div class="d-flex justify-content-center">
                    <span>Loading changes</span>
                </div>
                <div class="d-flex justify-content-center w-100 p-4">
                    <span class="spinner-border text-secondary"></span>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [attr.aria-valuenow]="originProgress | async" aria-valuemin="0" aria-valuemax="100" [style.width]="(originProgress | async)+'%'"></div>
                </div>
            </ng-template>
            <label><b><i class="mdi mdi-upload"></i> Outgoing Changes</b></label>
            <div *ngIf="!showIncoming" class="col-12">
                <div *ngIf="updatesLocal as ul; else loading" [@formsAnimations]>
                    <div *ngIf="ul.patient > 0 || ul.record > 0 ; else zeroResults">
                        <div class="card card-body mb-2">
                            <span *ngIf="ul.patient > 0">{{ul.patient}} patient(s) not synced</span>
                            <span *ngIf="ul.record > 0">{{ul.record}} record(s) not synced</span>
                        </div>
                        <!-- <div *ngFor=" let update of updates" class="card card-body mb-2">
                            <small>{{update.dateUpdated | date}}</small>
                            <span>{{update.fieldPath}} of {{update.modelName}} changed to {{update.value}}</span>
                        </div> -->
                        <!--  <div class="card card-body mb-2">
                            <small>1/7/2021</small>
                            <span>Change 2</span>
                        </div>
                        <div class="card card-body mb-2">
                            <small>1/6/2021</small>
                            <span>Change 1</span>
                        </div> -->
                    </div>
                </div>
            </div>
            <ng-template #zeroResults>
                <div style="width: fit-content;" class="text-danger">
                    No outgoing records to sync
                </div>
            </ng-template>
            <label><b><i class="mdi mdi-download"></i> Incoming Changes</b></label>
            <div *ngIf="!showIncoming" class="col-12">
                <div *ngIf="updatesOrigin as uo; else loadingBar" [@formsAnimations]>
                    <div *ngIf="uo.patient > 0 || uo.record > 0 ; else zeroResultsIn">
                        <div class="card card-body mb-2">
                            <span *ngIf="uo.patient > 0">{{uo.patient}} patient(s) not synced</span>
                            <span *ngIf="uo.record > 0">{{uo.record}} record(s) not synced</span>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #zeroResultsIn>
                <div style="width: fit-content;" class="text-danger">
                    No incoming records to sync
                </div>
            </ng-template>
           <!--  <div *ngIf="showIncoming" class="col-12">
                <label><b><i class="mdi mdi-download"></i> Incoming Changes</b></label>
                <div class="card card-body mb-2">
                    <small>1/5/2021</small>
                    <span>Change 5</span>
                </div>
                <div class="card card-body mb-2">
                    <small>1/4/2021</small>
                    <span>Change 4</span>
                </div>
                <div class="card card-body mb-2">
                    <small>1/3/2021</small>
                    <span>Change 3</span>
                </div>
                <div class="card card-body mb-2">
                    <small>1/2/2021</small>
                    <span>Change 2</span>
                </div>
                <div class="card card-body mb-2">
                    <small>1/1/2021</small>
                    <span>Change 1</span>
                </div>
            </div> -->
            
        </div>
    </div>
</div>