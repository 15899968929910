import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class PatientDetailsReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Last Name'
    })
    lastName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'First Name'
    })
    firstName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Middle Name'
    })
    middleName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Sex'
    })
    sex: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Philhealth'
    })
    philhealth: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Date of Birth'
    })
    dateOfBirth: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Time of Birth'
    })
    timeOfBirth: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Birth Outcome'
    })
    birthOutcome: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Birth Order'
    })
    birthOrder: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Birth Weight'
    })
    birthWeight: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Gestation Age'
    })
    gestationAge: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Facility of Birth'
    })
    facilityOfBirth: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Mothers Last Name'
    })
    mLastName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Mothers First Name'
    })
    mFirstName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Mothers Middle Name'
    })
    mMiddleName: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Address'
    })
    address: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Street Address'
    // })
    // streetAddress: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Barangay'
    // })
    // barangay: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'City / Municipality'
    // })
    // cityMun: string;
    // @autoserializeAs(String)
    // @Column({
    //     columnName: 'Province'
    // })
    // province: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Phone Number'
    })
    phoneNo: string;
}
