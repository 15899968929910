import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule  } from '@angular/material/checkbox';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EditComponent } from './edit/edit.component';
import { MatRadioModule } from '@angular/material/radio';
import {MatRippleModule} from '@angular/material/core';
import { SetPasswordComponent } from './set-password/set-password.component';

@NgModule({
  declarations: [
    LoginComponent, 
    RegisterComponent, 
    ForgotPasswordComponent, 
    EditComponent, SetPasswordComponent,
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatRippleModule
  ]
})
export class AccountModule { }
