import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  grant_type= 'client_credentials';
  client_id= 'ennhsr-client';
  client_secret= 'ennhsr-secret';

  public token: BehaviorSubject<any> = new BehaviorSubject('');

  public originToken: BehaviorSubject<any> = new BehaviorSubject('');
  
  constructor(private http: HttpClient) {}

  getToken() {
    const body = new HttpParams()
      .set('grant_type', this.grant_type)
      .set('client_id', this.client_id)
      .set('client_secret', this.client_secret);
    return this.http.post(`${environment.apiUrl}/oauth/token`,body,{headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })}).pipe(
      map(res => {         
        this.token.next(res['access_token']);
      })
    ).toPromise()
  }
}
