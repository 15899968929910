import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AcousticReflexTestService } from '../acoustic-reflex-test.service';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { first } from 'rxjs/operators';
import { PrintService } from '@app/shared/print/print.service';
import { PatientService } from '@app/patient/patient.service';

@Component({ template: '' })
export class AddEditAcousticReflexTestComponent implements OnInit {

    constructor(public dialog: MatDialog,
      private location: Location,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    let id = this.route.snapshot.params['id']
    const dialogRef = this.dialog.open(AddEditAcousticReflexTestComponentDialog, {
        data: {
            formBuilder: this.formBuilder,
            id: this.route.snapshot.params['id']
        }
    });
    dialogRef.afterClosed().subscribe(result => {
        this.location.back();
    });
  }

}

@Component({ templateUrl: 'add-edit-acoustic-reflex-test.component.html' })
export class AddEditAcousticReflexTestComponentDialog implements OnInit {
    
    form: FormGroup;
    loading = false;
    submitted = false;

    editable: boolean;
    editing: boolean;
    printable: boolean;

    isAddMode: any;

    id: string;
    patientId: string;
    modelId: string;
    versionId: string;
    
    constructor(        
        public dialogRef: MatDialogRef<AddEditAcousticReflexTestComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder,
            id: string
        },
        public dialog: MatDialog,
        private acousticReflexTestService: AcousticReflexTestService, 
        private formBuilder: FormBuilder, 
        private alertService: AlertService,
        public printService: PrintService,
        private patientService: PatientService
    ) { 
        this.id = data.id;
    }

    ngOnInit() {
      this.patientId = this.patientService.recordSubject.getValue()["modelId"];

      this.isAddMode = !this.id;
  
      this.editable = this.id? true:false;
      this.editing = false;
      this.printable = true;
  
      this.form = this.formBuilder.group({
        patient: [''],
        dateOfTest: ['', Validators.required],
        nameOfTester: ['', Validators.required],
        ipsiLeft: [''],
        contraLeft: [''],
        ipsiRight: [''],
        contraRight: [''],
        notes: [''],
        uploadFile: ['']
      });
  
      if (!this.isAddMode) {
        var model = this.acousticReflexTestService.recordsValue["results"].find(x => x.modelId == this.id);
        this.versionId = model["versionId"];
        this.modelId = model["modelId"];
        this.form.patchValue(Utils.mapModelToForm(model, ['dateOfTest']));
      }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() { 
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createAcousticReflexTest();
        } else {
            this.updateAcousticReflexTest();
        }
    }

    createAcousticReflexTest() {
        this.form.get("patient").setValue(this.patientId)
        this.acousticReflexTestService.add(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Acoustic Reflex Test added successfully', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      
    updateAcousticReflexTest() {
        this.acousticReflexTestService.update(this.form.value, this.modelId, this.versionId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close();
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}