import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AbrTestService } from './abr-test.service';
import { AssrTestService } from './assr-test.service';

@Component({
  selector: 'app-auditory-evoked-potentials',
  templateUrl: './auditory-evoked-potentials.component.html',
  styleUrls: ['./auditory-evoked-potentials.component.scss']
})
export class AuditoryEvokedPotentialsComponent implements OnInit {
  
  columnsABR = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of Test',  prop: 'dateOfTest'},
    {name: 'Name of Tester',  prop: 'nameOfTester'},
    {name: 'Reader',  prop: 'reader'},
    {name: 'Device',  prop: 'device'},
    {name: 'Stimulus (Click)',  prop: 'stimulusClick'},
    {name: 'Stimulus (Tone Burst)',  prop: 'stimulusToneBurst'},
    {name: 'Stimulus (Chirp)',  prop: 'stimulusChirp'},
    {name: 'Testing Conditions',  prop: 'testingConditions'},
    
    {name: 'Left Ear Done',  prop: 'leftEarDone'},
    {name: '- Polarity (Rarefaction)',  prop: 'polarityLeftRarefaction'},
    {name: '- Polarity (Condensation)',  prop: 'polarityLeftCondensation'},
    {name: '- Polarity (Alternating)',  prop: 'polarityLeftAlternating'},
    {name: '- Click Threshold (Left)',  prop: 'clickThresholdLeft'},
    {name: '- Findings, Others (Interwave Latency)',  prop: 'findingsOthersInterwaveLatencyLeft'},
    {name: '- Findings, Others (Interaural Latency)',  prop: 'findingsOthersInterauralLatencyLeft'},
    {name: '- 250Hz',  prop: 'findingsLeftEarToneBurst250'},
    {name: '- 500Hz',  prop: 'findingsLeftEarToneBurst500'},
    {name: '- 1kHz',  prop: 'findingsLeftEarToneBurst1000'},
    {name: '- 1.5kHz',  prop: 'findingsLeftEarToneBurst1500'},
    {name: '- 2kHz',  prop: 'findingsLeftEarToneBurst2000'},
    {name: '- 3kHz',  prop: 'findingsLeftEarToneBurst3000'},
    {name: '- 4kHz',  prop: 'findingsLeftEarToneBurst4000'},
    {name: '- 6kHz',  prop: 'findingsLeftEarToneBurst6000'},
    {name: '- 8kHz',  prop: 'findingsLeftEarToneBurst8000'},

    {name: 'Right Ear Done',  prop: 'rightEarDone'},
    {name: '- Polarity (Rarefaction)',  prop: 'polarityRightRarefaction'},
    {name: '- Polarity (Condensation)',  prop: 'polarityRightCondensation'},
    {name: '- Polarity (Alternating)',  prop: 'polarityRightAlternating'},
    {name: '- Click Threshold (Right)',  prop: 'clickThresholdRight'},
    {name: '- 250Hz',  prop: 'findingsRightEarToneBurst250'},
    {name: '- 500Hz',  prop: 'findingsRightEarToneBurst500'},
    {name: '- 1kHz',  prop: 'findingsRightEarToneBurst1000'},
    {name: '- 1.5kHz',  prop: 'findingsRightEarToneBurst1500'},
    {name: '- 2kHz',  prop: 'findingsRightEarToneBurst2000'},
    {name: '- 3kHz',  prop: 'findingsRightEarToneBurst3000'},
    {name: '- 4kHz',  prop: 'findingsRightEarToneBurst4000'},
    {name: '- 6kHz',  prop: 'findingsRightEarToneBurst6000'},
    {name: '- 8kHz',  prop: 'findingsRightEarToneBurst8000'},
    
    {name: 'Notes',  prop: 'notes'},
    //{name: 'File',  prop: 'uploadFile'}
  ]

  columnsASSR = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date of Test',  prop: 'dateOfTest'},
    {name: 'Name of Tester',  prop: 'nameOfTester'},
    {name: 'Reader',  prop: 'reader'},
    {name: 'Device',  prop: 'device'},
    {name: 'Testing Conditions',  prop: 'transducersRightEar'},
    {name: 'Left Ear Done',  prop: 'leftEarDone'},
    {name: '- Transducers',  prop: 'transducersLeftEar'},
    {name: '- 250Hz',  prop: 'findingsLeftEar250'},
    {name: '- 500Hz',  prop: 'findingsLeftEar500'},
    {name: '- 1kHz',  prop: 'findingsLeftEar1000'},
    {name: '- 1.5kHz',  prop: 'findingsLeftEar1500'},
    {name: '- 2kHz',  prop: 'findingsLeftEar2000'},
    {name: '- 3kHz',  prop: 'findingsLeftEar3000'},
    {name: '- 4kHz',  prop: 'findingsLeftEar4000'},
    {name: '- 6kHz',  prop: 'findingsLeftEar6000'},
    {name: '- 8kHz',  prop: 'findingsLeftEar8000'},
    {name: 'Right Ear Done',  prop: 'rightEarDone'},
    {name: '- Transducers',  prop: 'transducersRightEar'},
    {name: '- 250Hz',  prop: 'findingsRightEar250'},
    {name: '- 500Hz',  prop: 'findingsRightEar500'},
    {name: '- 1kHz',  prop: 'findingsRightEar1000'},
    {name: '- 1.5kHz',  prop: 'findingsRightEar1500'},
    {name: '- 2kHz',  prop: 'findingsRightEar2000'},
    {name: '- 3kHz',  prop: 'findingsRightEar3000'},
    {name: '- 4kHz',  prop: 'findingsRightEar4000'},
    {name: '- 6kHz',  prop: 'findingsRightEar6000'},
    {name: '- 8kHz',  prop: 'findingsRightEar8000'},    
    {name: '- Notes',  prop: 'notes'},
    //{name: 'File',  prop: 'uploadFile'}
  ]

  dateControlNames = ['dateOfTest'];
  abrTest$ : Observable<any>;
  abrTestPage$ : Observable<any>;
  assrTest$ : Observable<any>;
  assrTestPage$ : Observable<any>;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;

  id: string;

  constructor(
    private abrTestService: AbrTestService,
    private assrTestService: AssrTestService,
    private route: ActivatedRoute,
    private userService: UserService,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.editable = true;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )
    this.abrTestService.getAll(this.id, 0).subscribe();
    
    this.abrTest$ = this.abrTestService.recordsObservable.pipe(
      map(abrTest => abrTest["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.abrTestPage$ = this.abrTestService.recordsObservable;

    this.assrTestService.getAll(this.id, 0).subscribe();

    this.assrTest$ = this.assrTestService.recordsObservable.pipe(
      map(assrTest => assrTest["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.assrTestPage$ = this.assrTestService.recordsObservable;
  }

  getPageABRTest(page: string) {
    this.abrTestService.getAll(this.id, page).subscribe();
  }

  getPageASSRTest(page: string) {
    this.assrTestService.getAll(this.id, page).subscribe();
  }

  deleteABRTest(id: string) {
    this.abrTestService.delete(id).pipe(first())
        .subscribe();
  }

  deleteASSRTest(id: string) {
    this.assrTestService.delete(id).pipe(first())
        .subscribe();
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category B') || user['facility']['roles'].includes('Category C') || user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }

}
