import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class NoOfPatientsWhoReachedSpeechBananaReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Hearing Loss'
    })
    hearingLoss: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Switch On to 6 Months'
    })
    switchToSix: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'On to 6M %'
    })
    switchToSixPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: '6 Months (and 1 Day) to 1 Year'
    })
    sixToOneYear: string;
    @autoserializeAs(String)
    @Column({
        columnName: '6M - 1Y %'
    })
    sixToOneYearPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: '1 Year (and 1 Day) and Beyond'
    })
    oneYearAndBeyond: string;
    @autoserializeAs(String)
    @Column({
        columnName: '1Y+ %'
    })
    oneYearAndBeyondPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Total'
    })
    total: string;
}
