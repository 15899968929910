import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SelectorService } from '../selector/selector.service';
import { routeAnimations, toggleAnimations } from './sidebar.animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    routeAnimations,
    toggleAnimations
  ] 
})
export class SidebarComponent implements OnInit {
  @Input() items: any;
  @Input() template: TemplateRef<any>;
  scrWidth: number;
  opened: boolean = false;
  @Input() defaultUrl: string;
  @ViewChild('sidebar') sidebar: MatDrawer;

  constructor(public route: ActivatedRoute, private selectorService: SelectorService, private router: Router) { }

  ngOnInit(): void {
    this.opened = true;
    if(!this.template)
      this.router.navigateByUrl(this.router.url+"/"+this.items[0].fragment);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  isOpened() {
    return window.innerWidth > 767;
  }

  openedChange() {
    this.selectorService.enable();
  }

  onOpened() {
    this.selectorService.enable();
  }

  closedStart() {
    this.selectorService.disable();
    this.selectorService.reset();
    this.opened = false;
  }

  openedStart() {
    this.selectorService.disable();
    this.selectorService.reset();
    this.opened = true;
  }

  navClick() {
    if(window.innerWidth < 767) {
      this.sidebar.close();
    }
  }

  navScroll(el: any) {
    if(window.innerWidth < 767) {
      this.sidebar.close();
    }
    document.getElementById(el).scrollIntoView({behavior:"smooth"});
  }
}
