import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListComponent } from './list/list.component';
import { AddEditComponent, AddEditComponentDialog } from './add-edit/add-edit.component';
import { UsersRoutingModule } from './users-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatChipsModule } from '@angular/material/chips';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    ListComponent, 
    AddEditComponent, 
    AddEditComponentDialog
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    
    ReactiveFormsModule,
    
    SharedModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule
  ]
})
export class UsersModule { }
