import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class TimelinessOfABRConfirmatoryTestingOriginReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Facility'
    })
    facility: string;

    @autoserializeAs(String)
    @Column({
        columnName: 'Compliant'
    })
    compliant: string;

    @autoserializeAs(String)
    @Column({
        columnName: 'Non-compliant'
    })
    nonCompliant: string;

    @autoserializeAs(String)
    @Column({
        columnName: 'Total'
    })
    total: string;
}