import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PsgcService } from '@app/shared/address/psgc.service';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { environment } from '@environments/environment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RequestsSealDistributionService {
  private modelName = "sealDistribution";
  private recordName = "requestsSealDistribution";
  public sealDistributionSubject: BehaviorSubject<any>;
  public sealDistribution: Observable<any>;

  constructor(
    public router: Router,
    public http: HttpClient,
    private psgcService: PsgcService
  ) {
    this.sealDistributionSubject = new BehaviorSubject<any>(null);
    this.sealDistribution = this.sealDistributionSubject.asObservable();
   }

  public get recordsValue() {
    return this.sealDistributionSubject.value;
  }
  public get recordsObservable() {
    return this.sealDistribution;
  }

  getAll(){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?requestStatus=REQUESTED&~resolveRef=1`)
    .pipe(
      mergeMap(res => {
        let results = res['results']
        if(!(results as []).length){
          this.sealDistributionSubject.next(res);
          return results;
        }       
        var forks = results.map(x => {
          if(x.dataModel.psgcCode == null || x.dataModel.psgcCode == "" || x.dataModel.psgcCode == "None")
              return of(x);
          var psgc = Utils.psgcUtils.cleanPsgc(x.dataModel.facility.psgcCode)
          var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x?.name))]
          if(!Utils.psgcUtils.isCityMunPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x?.name)))
          if(!Utils.psgcUtils.isProvPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x?.name)))
          return forkJoin(psgcForks);
        })
        return forkJoin(forks)
          .pipe(
            map(fork => {
              res['results'].forEach(element => {
                var model = element.dataModel.facility;
                if(model.psgcCode != null && model.psgcCode != "" && model.psgcCode != "None") {
                  var fullAddress = [];
                  if(model.streetAddress && model.streetAddress !== "")
                    fullAddress.push(model.streetAddress);
                  fullAddress = fullAddress.concat(fork[res['results'].indexOf(element)]);
                  element.dataModel['facility'] ? element.dataModel['facility']['fullAddress'] = fullAddress.join(', ') : null;
                } else {
                  element.dataModel['fullAddress'] = "No address provided"
                }
              });
              //localStorage.setItem(this.recordName+'Records', JSON.stringify(res));
              this.sealDistributionSubject.next(res);
              return res;
            })
          )
      })
    );   
  }
  
  update(item: any, modelId: string, versionId: string) {
    var original = this.sealDistributionSubject.value['results'].find(x => x.modelId === modelId);
    item["meta"] = original["dataModel"]["meta"];
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.sealDistributionSubject.value;
        var idx = records["results"].findIndex(x => x.modelId === modelId);
        records["results"][idx].dataModel = item;
        records["results"][idx].versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        localStorage.setItem(this.recordName+'Records', JSON.stringify(records));
        this.sealDistributionSubject.next(records);
        return res;
      })
    );
  }

  updateStatus(modelId: string,status: string){
    var original = this.sealDistributionSubject.value['results'].find(x => x.modelId === modelId)
    original["dataModel"]["requestStatus"] = status
    original["dataModel"]["dateCanceled"] = Date.now()
    var sync = Utils.convertToSync(original["dataModel"], this.modelName,  original['modelId'], original['versionId'])
    sync.fieldUpdates.splice(0, 4);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.sealDistributionSubject.value;
        records['results'].splice(records['results'].findIndex(x => x.modelId === original.modelId),1);
        localStorage.setItem(this.recordName+'Records', JSON.stringify(records));
        this.sealDistributionSubject.next(records);
        return res;
      })
    );
  }
}
