<div class="container">
    <div class="card">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header d-flex justify-content-between">
                {{isAddMode ? 'New' : 'Edit'}} Tympanometry
                <button type="button" (click)="this.printService.print(['details', print], 'Tympanometry')" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                            
            </div>
            <div #print class="card-body"> 
                <fieldset >
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="dateOfTest">Date of Test</label>
                            <div>
                                <input matInput [matDatepicker]="picker" formControlName="dateOfTest" [ngClass]="{ 'is-invalid': submitted && f.dateOfTest.errors }" />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <i class="mdi mdi-calendar"></i>
                                </mat-datepicker-toggle>
                                <div *ngIf="submitted && f.dateOfTest.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateOfTest.errors.required">Date of Test is required</div>
                                </div>
                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                        <div class="form-group col-8">
                            <label for="nameOfTester">Name of Tester</label>
                            <input type="text" formControlName="nameOfTester" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameOfTester.errors }" />
                            <div *ngIf="submitted && f.nameOfTester.errors" class="invalid-feedback">
                                <div *ngIf="f.nameOfTester.errors.required">Name of Tester is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-3">
                            <label for="ecvRight">ECV in mL (Right)</label>
                            <input type="text" formControlName="ecvRight" class="form-control" />
                        </div>
                        <div class="form-group col-3">
                            <label for="complianceRight">Compliance in mL (Right)</label>
                            <input type="text" formControlName="complianceRight" class="form-control" />
                        </div>
                        <div class="form-group col-3">
                            <label for="peakRight">Peak in decapascals (Right)</label>
                            <input type="text" formControlName="peakRight" class="form-control" />
                        </div>
                        <div class="form-group col-3">
                            <label for="typeRight">Type (Right)</label>
                            <div>
                                <mat-radio-group formControlName="typeRight">
                                    <mat-radio-button class="mr-2" [value]="'A'">A</mat-radio-button>
                                    <mat-radio-button class="mr-2" [value]="'Ad'">Ad</mat-radio-button>
                                    <mat-radio-button class="mr-2" [value]="'As'">As</mat-radio-button>
                                    <mat-radio-button class="mr-2" [value]="'B'">B</mat-radio-button>
                                    <mat-radio-button [value]="'C'">C</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-3">
                            <label for="ecvLeft">ECV in mL (Left)</label>
                            <input type="text" formControlName="ecvLeft" class="form-control" />
                        </div>
                        <div class="form-group col-3">
                            <label for="complianceLeft">Compliance in mL (Left)</label>
                            <input type="text" formControlName="complianceLeft" class="form-control" />
                        </div>
                        <div class="form-group col-3">
                            <label for="peakLeft">Peak in decapascals (Left)</label>
                            <input type="text" formControlName="peakLeft" class="form-control" />
                        </div>
                        <div class="form-group col-3">
                            <label for="typeLeft">Type (Left)</label>
                            <div>
                                <mat-radio-group formControlName="typeLeft">
                                    <mat-radio-button class="mr-2" [value]="'A'">A</mat-radio-button>
                                    <mat-radio-button class="mr-2" [value]="'Ad'">Ad</mat-radio-button>
                                    <mat-radio-button class="mr-2" [value]="'As'">As</mat-radio-button>
                                    <mat-radio-button class="mr-2" [value]="'B'">B</mat-radio-button>
                                    <mat-radio-button [value]="'C'">C</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="notes">Notes</label>
                            <input type="text" formControlName="notes" class="form-control" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="uploadFile">Upload</label>
                            <input type="file" formControlName="uploadFile" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mt-3 col-12">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-10">
                                    <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        {{isAddMode ? 'Add' : 'Save'}} Record
                                    </button>
                                    <div class="d-flex justify-content-around flex-wrap mt-2">
                                        <a (click)="this.dialogRef.close()" class="btn btn-link">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
</div>