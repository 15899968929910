import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService } from '@app/shared/alert/alert.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ConceptsService } from '../concepts.service';
import Utils from '@app/shared/utils';

@Component({ template: '' })
export class AddEditComponent implements OnInit {
        
    constructor(public dialog: MatDialog,
        private location: Location,
        private formBuilder: FormBuilder,
        private conceptsService: ConceptsService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private router: Router,
        private matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.openDialog();
    }

    openDialog(): void {
        let id = this.route.snapshot.params['id']
        const dialogRef = this.dialog.open(AddEditComponentDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: id,
                isAddMode: !id,
                conceptsService: this.conceptsService,
                alertService: this.alertService,
                route: this.route,
                router: this.router,
                matDialog: this.matDialog
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.location.back();
        });
    }
}

@Component({ templateUrl: 'add-edit.component.html' })
export class AddEditComponentDialog implements OnInit {
    form: FormGroup;
    id: string;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    isModal: boolean;
    private versionId: string;
    private modelId: string;

    constructor(
        public dialogRef: MatDialogRef<AddEditComponentDialog>,
        private conceptsService: ConceptsService,
        private alertService: AlertService,
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder, 
            id: string, 
            isAddMode: boolean,
            conceptsService: ConceptsService,
            alertService: AlertService,
        }
    ) {
        this.id = data.id;
        this.isAddMode = data.isAddMode;
        this.conceptsService = data.conceptsService;
        this.alertService = data.alertService;
    }

    ngOnInit() {
        
        
        this.form = this.data.formBuilder.group({
            primaryName: [''],
            shortName: [''],
            description: [''],
            dataClass: [''],
            dataType: [''],
            isSet: [''],
            required: [''],
            retired: ['']
        });
        if (!this.isAddMode) {
            var model = this.conceptsService.recordsValue["results"].find(x => x.modelId == this.id);
            this.versionId = model["versionId"];
            this.modelId = model["modelId"];
            this.form.patchValue(Utils.mapModelToForm(model));
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createConcept();
        } else {
            this.updateConcept();
        }
    }

    private createConcept() {
        this.conceptsService.add(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Concept added successfully', { keepAfterRouteChange: true });
                    this.dialogRef.close()
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    private updateConcept() {
        this.conceptsService.update(this.form.value,this.modelId, this.versionId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close()
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}