import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortId'
})
export class ShortIdPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value.substring(24,36);
  }

}
