import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PsgcService } from '@app/shared/address/psgc.service';
import Utils from '@app/shared/utils';
import { FieldUpdate } from '@app/shared/_models/field-update';
import { Sync } from '@app/shared/_models/sync';
import { UnitService } from '@app/unit/unit.service';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FacilitiesService extends UnitService{
  public facilities: Observable<Sync>;
  public facilitiesSubject: BehaviorSubject<any>;
  
  constructor(
    public router: Router,
    public http: HttpClient,
    private psgcService: PsgcService,
    private userService: UserService
  ) {
    super(router, http);
    this.facilitiesSubject = new BehaviorSubject<any>(null);
    this.facilities = this.facilitiesSubject.asObservable();
  }

  public get recordsValue() {
    return this.facilitiesSubject.value;
  }

  public get recordsObservable() {
    return this.facilities;
  }

  getAll(page?) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/facility?&~resolveRef=1${page != null ? '&~page='+page : ''}`)
    .pipe(
      map(res => {
         //localStorage.setItem('facilitiesRecords', JSON.stringify(res));
         this.facilitiesSubject.next(res);
         return res;
      })
      /* mergeMap(res => {
        var resForks = res['results'].map(x => {
          var psgc = Utils.psgcUtils.cleanPsgc(x['dataModel'].psgcCode)
          var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x != null ? x.name : null))]
          if(!Utils.psgcUtils.isCityMunPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x != null ? x.name : null)))
          if(!Utils.psgcUtils.isProvPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x != null ? x.name : null)))
          return forkJoin(psgcForks)
        })
        return forkJoin(resForks)
        .pipe(
          map(forkRes => {
            res['results'].forEach((e,i) => {
              var fullAddress = [];
              if(e['dataModel'].streetAddress && e['dataModel'].streetAddress !== "")
                fullAddress.push(e['dataModel'].streetAddress);
              fullAddress = fullAddress.concat(forkRes[i]);
              e['dataModel']['fullAddress'] = fullAddress.join(', ');
            });
            localStorage.setItem('facilitiesRecords', JSON.stringify(res));
            this.facilitiesSubject.next(res);
            return res;
          })
        )
      }) */
    );
  }

  search(keyword: string) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/facility`,
      {
        "where": {
          "$and":[{
            "$partialSearch": {
              "$text": keyword,
              "$fields": ["facilityName"],
              "$matchWordMiddle": true
            },
            "active": { 
              "$eq" : true
            }
          }]
        }
    })
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  searchAndRefresh(keyword: string) {
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/facility?&~resolveRef=1&~page=0`,
      {
        "where": {
          "$partialSearch": {
            "$text": keyword,
            "$fields": ["facilityName"],
            "$matchWordMiddle": true
          }
        }
    })
    .pipe(
      mergeMap(res => {
        var resForks = res['results'].map(x => {
          var psgc = Utils.psgcUtils.cleanPsgc(x['dataModel'].psgcCode)
          var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x != null ? x.name : null))]
          if(!Utils.psgcUtils.isCityMunPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x != null ? x.name : null)))
          if(!Utils.psgcUtils.isProvPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x != null ? x.name : null)))
          return forkJoin(psgcForks)
        })
        return forkJoin(resForks)
        .pipe(
          map(forkRes => {
            res['results'].forEach((e,i) => {
              var fullAddress = [];
              if(e['dataModel'].streetAddress && e['dataModel'].streetAddress !== "")
                fullAddress.push(e['dataModel'].streetAddress);
              fullAddress = fullAddress.concat(forkRes[i]);
              e['dataModel']['fullAddress'] = fullAddress.join(', ');
            });
            //localStorage.setItem('facilitiesRecords', JSON.stringify(res));
            this.facilitiesSubject.next(res);
            return res;
          })
        )
      })
    );
  }

  public add(facility: any) {
    var guid = Utils.generateGuid();
    const user = this.userService.userValue;
    facility["meta.category"] = facility["category"];
    facility["meta.facilityCode"] = facility["nshrcFacilityAccreditationNumber"];
    facility["meta.psgcCode"] = facility["psgcCode"];
    facility["meta.periodDate"] = Date.now()
    var sync = Utils.convertToSync(facility, "facility", guid, null, user.id );
    return super.push(sync)
    .pipe(
      mergeMap(res => {
        return this.getById(res['insertedModelIds']["facility"][guid]['assignedId'])
        .pipe(
          map(newItem => {
            var records = this.facilitiesSubject.value;
            records['results'].splice(0,0,newItem);
            //localStorage.setItem('facilitiesRecords', JSON.stringify(records));
            this.facilitiesSubject.next(records);
            return res;
          })
        );
      })
    );
  }

  update(facility: any, modelId: string, versionId: string) {
    const user = this.userService.userValue;
    var original = this.facilitiesSubject.value['results'].find(x => x.modelId === modelId);
    facility["meta.category"] = facility["category"];
    facility["meta.facilityCode"] = facility["nshrcFacilityAccreditationNumber"];
    facility["meta.psgcCode"] = facility["psgcCode"];
    facility["meta.periodDate"] = Date.now()
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], 'facility', original['modelId'], original['versionId'],user.id), 
                              Utils.convertToSync(facility, 'facility', modelId, versionId,user.id));
    return super.push(sync)
    .pipe(
      mergeMap(res => {
        return this.getById(modelId)
        .pipe(
          map(res2 => {
            var records = this.facilitiesSubject.value;
            var idx = records["results"].findIndex(x => x.modelId === modelId);
            records["results"][idx].dataModel = res2["dataModel"];
            records["results"][idx].versionId = res2['versionId'];
            //localStorage.setItem('facilitiesRecords', JSON.stringify(records));
            this.facilitiesSubject.next(records);
            return records;
          })
        );
      })
    );
   }

   delete(id){
    var model = this.facilitiesSubject.value['results'].find(x => x.modelId === id)
    let sync = new Sync();
    let field = new FieldUpdate();
    field.fieldPath = "meta.voided";
    field.value = true;
    field.modelName = 'facility';
    field.modelId = id;
    field.dateUpdated = new Date();
    field.dateSynced = null;
    field.userId = JSON.parse(localStorage.getItem('user')).id;
    sync.fieldUpdates.push(field);
    let token = {};
    token['facility'] = {};
    token['facility'][id] = model.versionId;
    sync.syncTokens = token;
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var facility = this.facilitiesSubject.value;
        facility['results'].splice(facility['results'].findIndex(x => x.modelId === model.modelId),1);
        //localStorage.setItem('facilitiesRecords', JSON.stringify(facility));
        this.facilitiesSubject.next(facility);
        return res;
      })
    );

  }  
}
