import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PsgcService } from '@app/shared/address/psgc.service';
import Utils from '@app/shared/utils';
import { FieldUpdate } from '@app/shared/_models/field-update';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { customAlphabet  } from 'nanoid'
import { AlertService } from '@app/shared/alert/alert.service';

// const { customAlphabet , urlAlphabet } = require('nanoid');
const nanoid = customAlphabet('123456789ABCDEFGHIJKLMNPQRSTUVWXYZ', 11)

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private modelName = "patient";

  public recordSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public record: Observable<any> = this.recordSubject.asObservable();

  constructor(
    public router: Router,
    public http: HttpClient,    
    private userService: UserService,
    private psgcService: PsgcService,
    private alertService: AlertService
  ) { }

  add(patient: any){
    patient = Utils.generateMeta(patient)
    patient["meta.periodDate"] = Date.now()
    patient["meta.psgcCode"] = patient['psgcCode'];
    patient["patientNumber"] = nanoid();    
    const user = this.userService.userValue;
    patient["currFacility"] = user['facility']['facilityCode'];
    var sync = Utils.convertToSync(patient, "patient", Utils.generateGuid(), null, user.id);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
      /* .pipe(
      mergeMap(res => {
        var seal = JSON.parse(localStorage.getItem('sealRecords')).results.filter(seals => seals.dataModel.serialNumber === patient["serialNumber"]);
        var modelId = seal[0]["modelId"];
        var versionId = seal[0]["versionId"];
        var sync2 = {
          fieldUpdates: [{
            "dateUpdated": Date.now,
            "fieldPath": "patient",
            "modelId": modelId,
            "modelName": "seal",
            "value": res['insertedModelIds'][this.modelName][sync.newModelIds.patient[0]]['assignedId']
          }],
          "syncTokens": {
            seal: {
              [modelId] : versionId
            }
          }
        }
        return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync2)
      })
    ); */
  }

  getAll() {
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/patient`);
  }

  search(keyword: string) {
    const user = this.userService.userValue;
    var clause = [];
    clause.push({
      "$partialSearch": {
        "$text": keyword,
        "$fields": ["firstName","lastName","motherFirstName","motherLastName","serialNumber","patientNumber"],
        "$matchWordMiddle": true
      }
    })
    if(!user['isAdmin'] == true && !user['isGuest'] == true) {
      clause.push({
        "$or": [
          { "facilityCode": { "$eq" : (user['facility']['facility']+' ('+user['facility']['facilityCode']+')')}},
          { "currFacility": { "$eq" : user['facility']['facilityCode']}}
        ]
      });
    }
    return this.http.post(`${environment.apiUrl}/dashboard/models/getAll/patient?~limit=30`,
      {
        "where": {
          "$and": clause
        }
      }
    ).pipe(
      map(res => {
        if(res['count'] > 30){
          this.alertService.info("Search results limited to 30. Please specify further the patient being searched.")
        }            
        return res;
      })
    )
      /* .pipe(
        mergeMap(res => {
          let results = res['results']        
          var forks = results.map(x => {
            if(x.dataModel.psgcCode == null || x.dataModel.psgcCode == "" || x.dataModel.psgcCode == "None")
              return of(x);
            var psgc = Utils.psgcUtils.cleanPsgc(x.dataModel.psgcCode)
            var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x.name))]
            if(!Utils.psgcUtils.isCityMunPsgc(psgc))
              psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x.name)))
            if(!Utils.psgcUtils.isProvPsgc(psgc))
              psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x.name)))
            return forkJoin(psgcForks);
          })
          return forkJoin(forks)
            .pipe(
              map(fork => {
                res['results'].forEach(element => {
                  var model = element.dataModel;
                  if(model.psgcCode != null && model.psgcCode != "" && model.psgcCode != "None") {
                    var fullAddress = [];
                    if(model.streetAddress && model.streetAddress !== "")
                      fullAddress.push(model.streetAddress);
                    fullAddress = fullAddress.concat(fork[res['results'].indexOf(element)]);
                    element.dataModel['fullAddress'] = fullAddress.join(', ');
                  } else {
                    element.dataModel['fullAddress'] = "No address provided"
                  }
                });
                return res;
              })
            )
        })
      );   */   
  }
  
  getById(id: string) {
    return this.http.get<Sync>(`${environment.apiUrl}/dashboard/models/get/patient/${id}?~resolveRef=1`)
    .pipe(
      mergeMap(res => {
        if(res['dataModel'].psgcCode != null && res['dataModel'].psgcCode != "" && res['dataModel'].psgcCode != "None") {
        var psgc = Utils.psgcUtils.cleanPsgc(res['dataModel'].psgcCode)
        var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x.name))]
        if(!Utils.psgcUtils.isCityMunPsgc(psgc))
          psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x.name)))
        if(!Utils.psgcUtils.isProvPsgc(psgc))
          psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x.name)))
        return forkJoin(psgcForks)
          .pipe(
            map(fork => {
              var model = res['dataModel'];
              var fullAddress = [];
              if(model.streetAddress && model.streetAddress !== "")
                fullAddress.push(model.streetAddress);
              fullAddress = fullAddress.concat(fork);
              res['dataModel']['fullAddress'] = fullAddress.join(', ');
              // localStorage.setItem(this.modelName+'Record', JSON.stringify(res));
              this.recordSubject.next(res);
              return res;
            })
          )
        }
        // localStorage.setItem(this.modelName+'Record', JSON.stringify(res));
          res['dataModel']['fullAddress'] = "No address provided"
          this.recordSubject.next(res);
          return of(res)
      })
    );
  }

  getByPatientNumber(patNum: string) {
    return this.http.get<Sync>(`${environment.apiUrl}/dashboard/models/get/patient?~resolveRef=1&patientNumber=${patNum}`).pipe(
      mergeMap(res => {
        if(res) {
          if(res['dataModel'].psgcCode != "") {
            var psgc = Utils.psgcUtils.cleanPsgc(res['dataModel'].psgcCode)
            var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x.name))]
            if(!Utils.psgcUtils.isCityMunPsgc(psgc))
              psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x.name)))
            if(!Utils.psgcUtils.isProvPsgc(psgc))
              psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x.name)))
            return forkJoin(psgcForks)
              .pipe(
                map(fork => {
                  var model = res['dataModel'];
                  var fullAddress = [];
                  if(model.streetAddress && model.streetAddress !== "")
                    fullAddress.push(model.streetAddress);
                  fullAddress = fullAddress.concat(fork);
                  res['dataModel']['fullAddress'] = fullAddress.join(', ');
                  // localStorage.setItem(this.modelName+'Record', JSON.stringify(res));
                  this.recordSubject.next(res);
                  return res;
                })
              )
          }
          // localStorage.setItem(this.modelName+'Record', JSON.stringify(res));
          this.recordSubject.next(res);
          return of(res)          
        }
        return of(null);
      })
    )
  }

  update(item: any, modelId: string, versionId: string) {
    var original = this.recordSubject.value;
    item["meta"] = original["dataModel"]["meta"];
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = Date.now()
    item["meta.psgcCode"] = item['psgcCode'];
    const user = this.userService.userValue;
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      mergeMap(res => {
        var record = this.recordSubject.value;
        if(sync.fieldUpdates.find(x => x.fieldPath === "psgcCode") === undefined) {
          item["fullAddress"] = record.dataModel["fullAddress"]          
          var curr = record.dataModel['currFacility'];
          record.dataModel = item;
          record.dataModel['currFacility'] = curr;
          record.versionId = res['updatedModelVersionIds'][this.modelName][modelId];
          // localStorage.setItem(this.modelName+'Record', JSON.stringify(record));
          this.recordSubject.next(record);
          return of(res);
        } else {
          var psgc = Utils.psgcUtils.cleanPsgc(item['psgcCode'])
          var psgcForks = [this.psgcService.get(psgc).pipe(map(x => x.name))]
          if(!Utils.psgcUtils.isCityMunPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x.name)))
          if(!Utils.psgcUtils.isProvPsgc(psgc))
            psgcForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x.name)))
          return forkJoin(psgcForks)
            .pipe(
              map(fork => {
                var model = record['dataModel'];
                var fullAddress = [];
                if(model.streetAddress && model.streetAddress !== "")
                  fullAddress.push(model.streetAddress);
                fullAddress = fullAddress.concat(fork);
                item['fullAddress'] = fullAddress.join(', ');
                var curr = record.dataModel['currFacility'];
                record.dataModel = item;
                record.dataModel['currFacility'] = curr;
                record.versionId = res['updatedModelVersionIds'][this.modelName][modelId];
                // localStorage.setItem(this.modelName+'Record', JSON.stringify(record));
                this.recordSubject.next(record);
              })
            )
        }
      })
    );
  }

  uploadFile(file:File){
    console.log("uploadFile", file);
    let headers = new HttpHeaders({
      'X-Random': Utils.generateGuid(),
      'X-Filename':  file.name,
      'Content-Type': file.type});
    const formData: FormData = new FormData();
    formData.append('fileKey', file, file.name);
    return this.http.post(`${environment.apiUrl}/file/insert`, formData, { headers: headers });
  }

  updateFile(){

  }  

  updateFacility(patient) {
    // let newFacility = this.userService.userValue['facility']['facility']+" ("+this.userService.userValue['facility']['facilityCode']+")";
    let newFacility = this.userService.userValue['facility']['facilityCode'];
    let sync = new Sync();
    let field = new FieldUpdate();
    field.fieldPath = "currFacility";
    field.value = newFacility
    field.modelName = patient['modelName'];
    field.modelId = patient['modelId'];
    field.dateUpdated = new Date();
    field.dateSynced = null;
    field.userId = JSON.parse(localStorage.getItem('user')).id;
    sync.fieldUpdates.push(field);
    let token = {};
    token[patient['modelName']] = {};
    token[patient['modelName']][patient['modelId']] = patient['versionId'];
    sync.syncTokens = token;
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync).pipe(
      map(res=> {
        var record = this.recordSubject.value;
        var model = record['dataModel'];
        model['facilityCode'] = newFacility;
        record.versionId = res['updatedModelVersionIds'][this.modelName][patient['modelId']];
        // localStorage.setItem(this.modelName+'Record', JSON.stringify(record));
        this.recordSubject.next(record);
      })
    )
  }
  
}

