<div class="subnav-panel w-100 p-3">
    <div class="d-flex justify-content-between">
        <div class="col-lg-6 d-flex justify-content-between p-0">
            <div class="input-group mr-3">
                <span class="input-group-prepend"><i class="mdi mdi-24px mdi-magnify"></i></span>
                <input class="form-control" [formControl]="searchControl" type="text" placeholder="Search Patient">
            </div>
            <button *ngIf="validatePrivilege()" [disabled]="false" routerLink="/patient/add" class="btn btn-primary text-nowrap"><i class="mdi mdi-account-plus"></i> Register Patient</button>
        </div>
        <div *ngIf="isSynced" class="d-none d-sm-none d-md-block card bg-success px-2 p-1 mb-0">
            <div class="d-flex align-items-center text-nowrap h-100">
                <a class="text-light" routerLink="/facility/seal" [style.pointer-events]="isUnitManager ? 'auto':'none'"><i class="mdi mdi-seal mr-1"></i>Your facility has used {{consumedSeals}} out of {{totalSeals}} seals</a>
                <!-- <div *ngIf="isLoadingSeals" [style.width]="'200px'" class="d-flex justify-content-center">
                    <mat-spinner [diameter]="25"></mat-spinner>
                </div> -->
            </div>
        
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="w-100 p-4" *ngIf="isLoading">
        <span class="spinner-border text-secondary"></span>
    </div>
</ng-template>
<div *ngIf="searchResults$ | async as results; else loading" class="p-3">
    <div style="width: fit-content;" *ngIf="results.results.length > 0; else zeroResults">
        <div class="card card-body d-flex flex-column p-3 mb-3" *ngFor="let result of results.results" [routerLink]="result.modelId" [@resultsAnimations]>
            <small>{{result.dataModel.patientNumber}}</small>
            <div>
                <h5 class="mb-0"><b>{{
                    (result.dataModel.firstName && result.dataModel.firstName !== '' && result.dataModel.lastName && result.dataModel.lastName !== '') ? 
                        (result.dataModel.lastName + ', ' + result.dataModel.firstName + ' ' + (result.dataModel.middleName? result.dataModel.middleName:'')) 
                        : 'Baby ' + (result.dataModel.sex && result.dataModel.sex !== '' ? (result.dataModel.sex | boyGirl | titlecase) : '') + ' ' +
                            (result.dataModel.lastName && result.dataModel.lastName !== '' ? result.dataModel.lastName : result.dataModel.motherLastName)
                    }}</b></h5>
                <span *ngIf="!result.dataModel.firstName || result.dataModel.firstName === '' || !result.dataModel.lastName || result.dataModel.lastName === ''">(child of {{result.dataModel.motherFirstName}} {{result.dataModel.motherLastName}})</span>
            </div>
            <div class="d-flex flex-column ml-2">
                <span><i class="mdi mdi-account-details mr-1"></i> {{result.dataModel.sex | titlecase}}, Born: {{result.dataModel.dateOfBirth | date:'longDate'}} ({{result.dataModel.dateOfBirth | age}} y.o.)</span>
                <!-- <span *ngIf="result.dataModel.fullAddress"><i class="mdi mdi-home mr-1"></i> {{result.dataModel.fullAddress}}</span> -->
                <span *ngIf="result.dataModel.psgcCode | displayPsgc | async as address; else loadingAddress"><i class="mdi mdi-home mr-1"></i> {{address}}</span>
                <ng-template #loadingAddress>
                    <span *ngIf="result.dataModel.psgcCode"><i class="mdi mdi-home mr-1"></i> loading address...</span>
                </ng-template>
            </div>            
        </div>
    </div>
    <ng-template #zeroResults>
        <div style="width: fit-content;" class="text-danger">
            No results found
        </div>
    </ng-template>
</div>
