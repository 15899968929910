import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-add',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit {

  loading: boolean;
  isDesktop: boolean;

  constructor(
    private patientService: PatientService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loading = false;
    this.isDesktop = environment.isDesktop
  }

  loadPatient(serial: string) {
    this.loading = true;
    this.patientService.getByPatientNumber(serial).subscribe(res => {
      this.loading = false;
      if(res){
        //assign current facility to patient, replace old facility
        this.patientService.updateFacility(res).subscribe(res2 => {
          this.router.navigate([`../${res['modelId']}`])
        })
      } else {
        this.alertService.error('No patient found for Serial Number ' + serial);
      }
    });
  }
}
