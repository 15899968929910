import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { PrintService } from './print.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {

  printWithHeader: boolean;
  title: string;

  constructor(private printService: PrintService) { }

  ngOnInit(): void {
    this.printWithHeader = true;
  }
}
