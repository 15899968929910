export const environment = {
  production: false,
  apiUrl: 'https://ennhsr-api.telehealth.ph',
  originUrl: 'https://ennhsr-api.telehealth.ph',
  isDesktop: false,
  allRoles: [
    'Category D',
    'Category C',
    'Category B',
    'Category A',
    'Unit Manager'
  ]
};
