<mat-drawer-container class="bg-transparent">
    <mat-drawer #sidebar [opened]="isOpened()" class="mat-drawer p-0 printHidden" mode="side" (closedStart)="closedStart()" (openedStart)="openedStart()" (openedChange)="openedChange()" [ngStyle]="{'transform': isOpened() ? 'none' : 'translate3d(-100%, 0, 0)'}">
        <div class="d-flex">
            <div class="navbar navbar-dark d-flex flex-grow-1 align-items-start">
                <ul *ngIf="template; else elseNav" ngbNav class="navbar-nav flex-column w-100">
                    <li *ngFor="let link of items" class="nav-item">
                        <a (click)="navScroll(link.fragment)" class="nav-link">{{ link.title }}</a>
                    </li>
                </ul>
                <ng-template #elseNav>
                    <ul ngbNav [activeId]="route.fragment | async" class="navbar-nav flex-column w-100">
                        <li [ngbNavItem]="link.fragment" *ngFor="let link of items" class="nav-item">
                            <a ngbNavLink routerLink="./{{link.fragment}}" (click)="navClick()" class="nav-link" routerLinkActive="active">{{ link.title }}</a>
                        </li>
                    </ul>
                </ng-template>
            </div>
            <div class="p-1">
                <button type="button" (click)="sidebar.close()" class="btn btn-link text-light px-1 py-0" style="z-index: 2;" title="Collapse Sidebar"><i class="mdi mdi-arrow-collapse-left"></i></button>            
            </div>
        </div>
    </mat-drawer>  
    <div class="sidenav-content d-flex h-100">
        <div class="sidebar-toogle position-fixed p-1 h-100" *ngIf="!this.opened" [@toggleAnimations]>
            <button type="button" (click)="sidebar.open()" class="btn btn-link px-1 py-0" title="Show Sidebar"><i class="mdi mdi-format-list-bulleted-square"></i></button>
        </div>
        <ng-template #defaultTemplate>
            <div [@routeAnimations]="prepareRoute(outlet)" class="w-100">
                <router-outlet #outlet="outlet"></router-outlet>
            </div>
        </ng-template>
        <ng-template id="qweqwe" [ngTemplateOutlet]="template || defaultTemplate"></ng-template>        
    </div>  
  </mat-drawer-container>