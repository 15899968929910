import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
    
  editable: boolean;
  editing: boolean;
  printable: boolean;

  constructor(private formBuilder: FormBuilder) {
    this.editable = true;
    this.editing = false;
    this.printable = true;
  }

  ngOnInit(): void { }

  get f() { return this.form.controls; }

  onSubmit() {
  }

  onClose() {
  }
}
