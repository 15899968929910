import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@app/user/user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const user = this.userService.userValue;
      // if (user['isAdmin'] == true || 
      // user['facility']['roles'].includes('Category A') || 
      // user['facility']['roles'].includes('Category B') || 
      // user['facility']['roles'].includes('Category C') || 
      // user['facility']['roles'].includes('Category D')) {
      //     return true;
      // }
      if (user['isAdmin'] == true || 
      user['facility']['roles'].includes('Unit Manager'))
       {
          return true;
      }
      this.router.navigate(['']);
      return false;
  }
  
}
