import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/patient/patient.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { PrintService } from '@app/shared/print/print.service';
import Utils from '@app/shared/utils';
import { UserService } from '@app/user/user.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IntraOpTelemetryService } from './intra-op-telemetry.service';
import { SessionsService } from './sessions.service';
import { SwitchOnService } from './switch-on.service';

@Component({
  selector: 'app-cochlear-implant-programming',
  templateUrl: './cochlear-implant-programming.component.html',
  styleUrls: ['./cochlear-implant-programming.component.scss']
})
export class CochlearImplantProgrammingComponent implements OnInit {

  columnsIOT = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date Done'},
    {name: 'Surgeon'},
    {name: 'Hospital'},
    {name: 'Audiologist'},
    {name: 'Status'},
    {name: 'Reason'},
    {name: 'Left Ear'},
    {name: 'Right Ear'},
    {name: 'Brand Left'},
    {name: 'Brand Right'},
    {name: 'Brand Left Others Specify', prop: 'brandLeftOthersSpecify'},
    {name: 'Brand Right Others Specify', prop: 'brandRightOthersSpecify'},
    {name: 'Serial Left'},
    {name: 'Serial Right'},
    {name: 'Electrode Left 1', prop: 'elecLeft1'},
    {name: 'Electrode Left 2', prop: 'elecLeft2'},
    {name: 'Electrode Left 3', prop: 'elecLeft3'},
    {name: 'Electrode Left 4', prop: 'elecLeft4'},
    {name: 'Electrode Left 5', prop: 'elecLeft5'},
    {name: 'Electrode Left 6', prop: 'elecLeft6'},
    {name: 'Electrode Left 7', prop: 'elecLeft7'},
    {name: 'Electrode Left 8', prop: 'elecLeft8'},
    {name: 'Electrode Left 9', prop: 'elecLeft9'},
    {name: 'Electrode Left 10', prop: 'elecLeft10'},
    {name: 'Electrode Left 11', prop: 'elecLeft11'},
    {name: 'Electrode Left 12', prop: 'elecLeft12'},
    {name: 'Electrode Left 13', prop: 'elecLeft13'},
    {name: 'Electrode Left 14', prop: 'elecLeft14'},
    {name: 'Electrode Left 15', prop: 'elecLeft15'},
    {name: 'Electrode Left 16', prop: 'elecLeft16'},
    {name: 'Electrode Left 17', prop: 'elecLeft17'},
    {name: 'Electrode Left 18', prop: 'elecLeft18'},
    {name: 'Electrode Left 19', prop: 'elecLeft19'},
    {name: 'Electrode Left 20', prop: 'elecLeft20'},
    {name: 'Electrode Left 21', prop: 'elecLeft21'},
    {name: 'Electrode Left 22', prop: 'elecLeft22'},
    {name: 'Electrode Left 23', prop: 'elecLeft23'},
    {name: 'Electrode Left 24', prop: 'elecLeft24'},
    {name: 'Electrode Left - 1', prop: 'eLeft1'},
    {name: 'Electrode Left - 2', prop: 'eLeft2'},
    {name: 'Electrode Left - 3', prop: 'eLeft3'},
    {name: 'Electrode Left - 4', prop: 'eLeft4'},
    {name: 'Electrode Left - 5', prop: 'eLeft5'},
    {name: 'Electrode Left - 6', prop: 'eLeft6'},
    {name: 'Electrode Left - 7', prop: 'eLeft7'},
    {name: 'Electrode Left - 8', prop: 'eLeft8'},
    {name: 'Electrode Left - 9', prop: 'eLeft9'},
    {name: 'Electrode Left - 10', prop: 'eLeft10'},
    {name: 'Electrode Left - 11', prop: 'eLeft11'},
    {name: 'Electrode Left - 12', prop: 'eLeft12'},
    {name: 'Electrode Left - 13', prop: 'eLeft13'},
    {name: 'Electrode Left - 14', prop: 'eLeft14'},
    {name: 'Electrode Left - 15', prop: 'eLeft15'},
    {name: 'Electrode Left - 16', prop: 'eLeft16'},
    {name: 'Electrode Left - 17', prop: 'eLeft17'},
    {name: 'Electrode Left - 18', prop: 'eLeft18'},
    {name: 'Electrode Left - 19', prop: 'eLeft19'},
    {name: 'Electrode Left - 20', prop: 'eLeft20'},
    {name: 'Electrode Left - 21', prop: 'eLeft21'},
    {name: 'Electrode Left - 22', prop: 'eLeft22'},
    {name: 'Electrode Left - 23', prop: 'eLeft23'},
    {name: 'Electrode Left - 24', prop: 'eLeft24'},
    {name: 'Electrode Right 1', prop: 'elecRight1'},
    {name: 'Electrode Right 2', prop: 'elecRight2'},
    {name: 'Electrode Right 3', prop: 'elecRight3'},
    {name: 'Electrode Right 4', prop: 'elecRight4'},
    {name: 'Electrode Right 5', prop: 'elecRight5'},
    {name: 'Electrode Right 6', prop: 'elecRight6'},
    {name: 'Electrode Right 7', prop: 'elecRight7'},
    {name: 'Electrode Right 8', prop: 'elecRight8'},
    {name: 'Electrode Right 9', prop: 'elecRight9'},
    {name: 'Electrode Right 10', prop: 'elecRight10'},
    {name: 'Electrode Right 11', prop: 'elecRight11'},
    {name: 'Electrode Right 12', prop: 'elecRight12'},
    {name: 'Electrode Right 13', prop: 'elecRight13'},
    {name: 'Electrode Right 14', prop: 'elecRight14'},
    {name: 'Electrode Right 15', prop: 'elecRight15'},
    {name: 'Electrode Right 16', prop: 'elecRight16'},
    {name: 'Electrode Right 17', prop: 'elecRight17'},
    {name: 'Electrode Right 18', prop: 'elecRight18'},
    {name: 'Electrode Right 19', prop: 'elecRight19'},
    {name: 'Electrode Right 20', prop: 'elecRight20'},
    {name: 'Electrode Right 21', prop: 'elecRight21'},
    {name: 'Electrode Right 22', prop: 'elecRight22'},
    {name: 'Electrode Right 23', prop: 'elecRight23'},
    {name: 'Electrode Right 24', prop: 'elecRight24'},
    {name: 'Electrode Right - 1', prop: 'eRight1'},
    {name: 'Electrode Right - 2', prop: 'eRight2'},
    {name: 'Electrode Right - 3', prop: 'eRight3'},
    {name: 'Electrode Right - 4', prop: 'eRight4'},
    {name: 'Electrode Right - 5', prop: 'eRight5'},
    {name: 'Electrode Right - 6', prop: 'eRight6'},
    {name: 'Electrode Right - 7', prop: 'eRight7'},
    {name: 'Electrode Right - 8', prop: 'eRight8'},
    {name: 'Electrode Right - 9', prop: 'eRight9'},
    {name: 'Electrode Right - 10', prop: 'eRight10'},
    {name: 'Electrode Right - 11', prop: 'eRight11'},
    {name: 'Electrode Right - 12', prop: 'eRight12'},
    {name: 'Electrode Right - 13', prop: 'eRight13'},
    {name: 'Electrode Right - 14', prop: 'eRight14'},
    {name: 'Electrode Right - 15', prop: 'eRight15'},
    {name: 'Electrode Right - 16', prop: 'eRight16'},
    {name: 'Electrode Right - 17', prop: 'eRight17'},
    {name: 'Electrode Right - 18', prop: 'eRight18'},
    {name: 'Electrode Right - 19', prop: 'eRight19'},
    {name: 'Electrode Right - 20', prop: 'eRight20'},
    {name: 'Electrode Right - 21', prop: 'eRight21'},
    {name: 'Electrode Right - 22', prop: 'eRight22'},
    {name: 'Electrode Right - 23', prop: 'eRight23'},
    {name: 'Electrode Right - 24', prop: 'eRight24'},
    {name: 'Additional Notes'}
  ]

  columnsSessions = [
    {name: 'Updated By',  prop: 'updatedBy'},
    {name: 'Date'},
    {name: 'Audiologist'},
    {name: 'Hospital'},
    {name: 'Status'},
    {name: 'Date Done', prop: 'date'},
    {name: 'Reason'},
    {name: 'CAP Score', prop: 'capScore'},
    {name: 'PEACH Score', prop: 'peachScore'},
    {name: 'Left Ear'},
    {name: 'Right Ear'},
    {name: 'Brand Left'},
    {name: 'Brand Right'},
    {name: 'Brand Left Others Specify', prop: 'brandLeftOthersSpecify'},
    {name: 'Brand Right Others Specify', prop: 'brandRightOthersSpecify'},
    {name: 'Serial Left'},
    {name: 'Serial Right'},
    {name: 'Electrode Left 1', prop: 'elecLeft1'},
    {name: 'Electrode Left 2', prop: 'elecLeft2'},
    {name: 'Electrode Left 3', prop: 'elecLeft3'},
    {name: 'Electrode Left 4', prop: 'elecLeft4'},
    {name: 'Electrode Left 5', prop: 'elecLeft5'},
    {name: 'Electrode Left 6', prop: 'elecLeft6'},
    {name: 'Electrode Left 7', prop: 'elecLeft7'},
    {name: 'Electrode Left 8', prop: 'elecLeft8'},
    {name: 'Electrode Left 9', prop: 'elecLeft9'},
    {name: 'Electrode Left 10', prop: 'elecLeft10'},
    {name: 'Electrode Left 11', prop: 'elecLeft11'},
    {name: 'Electrode Left 12', prop: 'elecLeft12'},
    {name: 'Electrode Left 13', prop: 'elecLeft13'},
    {name: 'Electrode Left 14', prop: 'elecLeft14'},
    {name: 'Electrode Left 15', prop: 'elecLeft15'},
    {name: 'Electrode Left 16', prop: 'elecLeft16'},
    {name: 'Electrode Left 17', prop: 'elecLeft17'},
    {name: 'Electrode Left 18', prop: 'elecLeft18'},
    {name: 'Electrode Left 19', prop: 'elecLeft19'},
    {name: 'Electrode Left 20', prop: 'elecLeft20'},
    {name: 'Electrode Left 21', prop: 'elecLeft21'},
    {name: 'Electrode Left 22', prop: 'elecLeft22'},
    {name: 'Electrode Left 23', prop: 'elecLeft23'},
    {name: 'Electrode Left 24', prop: 'elecLeft24'},
    {name: 'Electrode Left - 1', prop: 'eLeft1'},
    {name: 'Electrode Left - 2', prop: 'eLeft2'},
    {name: 'Electrode Left - 3', prop: 'eLeft3'},
    {name: 'Electrode Left - 4', prop: 'eLeft4'},
    {name: 'Electrode Left - 5', prop: 'eLeft5'},
    {name: 'Electrode Left - 6', prop: 'eLeft6'},
    {name: 'Electrode Left - 7', prop: 'eLeft7'},
    {name: 'Electrode Left - 8', prop: 'eLeft8'},
    {name: 'Electrode Left - 9', prop: 'eLeft9'},
    {name: 'Electrode Left - 10', prop: 'eLeft10'},
    {name: 'Electrode Left - 11', prop: 'eLeft11'},
    {name: 'Electrode Left - 12', prop: 'eLeft12'},
    {name: 'Electrode Left - 13', prop: 'eLeft13'},
    {name: 'Electrode Left - 14', prop: 'eLeft14'},
    {name: 'Electrode Left - 15', prop: 'eLeft15'},
    {name: 'Electrode Left - 16', prop: 'eLeft16'},
    {name: 'Electrode Left - 17', prop: 'eLeft17'},
    {name: 'Electrode Left - 18', prop: 'eLeft18'},
    {name: 'Electrode Left - 19', prop: 'eLeft19'},
    {name: 'Electrode Left - 20', prop: 'eLeft20'},
    {name: 'Electrode Left - 21', prop: 'eLeft21'},
    {name: 'Electrode Left - 22', prop: 'eLeft22'},
    {name: 'Electrode Left - 23', prop: 'eLeft23'},
    {name: 'Electrode Left - 24', prop: 'eLeft24'},
    {name: 'Electrode Right 1', prop: 'elecRight1'},
    {name: 'Electrode Right 2', prop: 'elecRight2'},
    {name: 'Electrode Right 3', prop: 'elecRight3'},
    {name: 'Electrode Right 4', prop: 'elecRight4'},
    {name: 'Electrode Right 5', prop: 'elecRight5'},
    {name: 'Electrode Right 6', prop: 'elecRight6'},
    {name: 'Electrode Right 7', prop: 'elecRight7'},
    {name: 'Electrode Right 8', prop: 'elecRight8'},
    {name: 'Electrode Right 9', prop: 'elecRight9'},
    {name: 'Electrode Right 10', prop: 'elecRight10'},
    {name: 'Electrode Right 11', prop: 'elecRight11'},
    {name: 'Electrode Right 12', prop: 'elecRight12'},
    {name: 'Electrode Right 13', prop: 'elecRight13'},
    {name: 'Electrode Right 14', prop: 'elecRight14'},
    {name: 'Electrode Right 15', prop: 'elecRight15'},
    {name: 'Electrode Right 16', prop: 'elecRight16'},
    {name: 'Electrode Right 17', prop: 'elecRight17'},
    {name: 'Electrode Right 18', prop: 'elecRight18'},
    {name: 'Electrode Right 19', prop: 'elecRight19'},
    {name: 'Electrode Right 20', prop: 'elecRight20'},
    {name: 'Electrode Right 21', prop: 'elecRight21'},
    {name: 'Electrode Right 22', prop: 'elecRight22'},
    {name: 'Electrode Right 23', prop: 'elecRight23'},
    {name: 'Electrode Right 24', prop: 'elecRight24'},
    {name: 'Electrode Right - 1', prop: 'eRight1'},
    {name: 'Electrode Right - 2', prop: 'eRight2'},
    {name: 'Electrode Right - 3', prop: 'eRight3'},
    {name: 'Electrode Right - 4', prop: 'eRight4'},
    {name: 'Electrode Right - 5', prop: 'eRight5'},
    {name: 'Electrode Right - 6', prop: 'eRight6'},
    {name: 'Electrode Right - 7', prop: 'eRight7'},
    {name: 'Electrode Right - 8', prop: 'eRight8'},
    {name: 'Electrode Right - 9', prop: 'eRight9'},
    {name: 'Electrode Right - 10', prop: 'eRight10'},
    {name: 'Electrode Right - 11', prop: 'eRight11'},
    {name: 'Electrode Right - 12', prop: 'eRight12'},
    {name: 'Electrode Right - 13', prop: 'eRight13'},
    {name: 'Electrode Right - 14', prop: 'eRight14'},
    {name: 'Electrode Right - 15', prop: 'eRight15'},
    {name: 'Electrode Right - 16', prop: 'eRight16'},
    {name: 'Electrode Right - 17', prop: 'eRight17'},
    {name: 'Electrode Right - 18', prop: 'eRight18'},
    {name: 'Electrode Right - 19', prop: 'eRight19'},
    {name: 'Electrode Right - 20', prop: 'eRight20'},
    {name: 'Electrode Right - 21', prop: 'eRight21'},
    {name: 'Electrode Right - 22', prop: 'eRight22'},
    {name: 'Electrode Right - 23', prop: 'eRight23'},
    {name: 'Electrode Right - 24', prop: 'eRight24'},
    {name: 'Additional Notes'}
  ]

  columnsAT = [
    {name: 'Date of Testing'},
    {name: 'Type of Aided Test', prop: 'typeOfAT'},
    {name: 'Type of Aided Test Specify', prop: 'specifyType'},
    {name: 'Testing Conditions'},
    {name: 'Left Ear'},
    {name: 'Left Ear Threshold (250Hz)', prop: 'left250Hz'},
    {name: 'Left Ear Threshold (500Hz)', prop: 'left500Hz'},
    {name: 'Left Ear Threshold (1kHz)', prop: 'left1kHz'},
    {name: 'Left Ear Threshold (1.5kHz)', prop: 'left15kHz'},
    {name: 'Left Ear Threshold (2kHz)', prop: 'left2kHz'},
    {name: 'Left Ear Threshold (3kHz)', prop: 'left3kHz'},
    {name: 'Left Ear Threshold (4kHz)', prop: 'left4kHz'},
    {name: 'Left Ear Threshold (6kHz)', prop: 'left6kHz'},
    {name: 'Left Ear Threshold (8kHz)', prop: 'left8kHz'},
    {name: 'Left Ear Speech Banana (250Hz)', prop: 'leftSB250Hz'},
    {name: 'Left Ear Speech Banana (500Hz)', prop: 'leftSB500Hz'},
    {name: 'Left Ear Speech Banana (1kHz)', prop: 'leftSB1kHz'},
    {name: 'Left Ear Speech Banana (1.5kHz)', prop: 'leftSB15kHz'},
    {name: 'Left Ear Speech Banana (2kHz)', prop: 'leftSB2kHz'},
    {name: 'Left Ear Speech Banana (3kHz)', prop: 'leftSB3kHz'},
    {name: 'Left Ear Speech Banana (4kHz)', prop: 'leftSB4kHz'},
    {name: 'Left Ear Speech Banana (6kHz)', prop: 'leftSB6kHz'},
    {name: 'Left Ear Speech Banana (8kHz)', prop: 'leftSB8kHz'},
    {name: 'Left Ear Speech String Bean (250Hz)', prop: 'leftSSB250Hz'},
    {name: 'Left Ear Speech String Bean (500Hz)', prop: 'leftSSB500Hz'},
    {name: 'Left Ear Speech String Bean (1kHz)', prop: 'leftSSB1kHz'},
    {name: 'Left Ear Speech String Bean (1.5kHz)', prop: 'leftSSB15kHz'},
    {name: 'Left Ear Speech String Bean (2kHz)', prop: 'leftSSB2kHz'},
    {name: 'Left Ear Speech String Bean (3kHz)', prop: 'leftSSB3kHz'},
    {name: 'Left Ear Speech String Bean (4kHz)', prop: 'leftSSB4kHz'},
    {name: 'Left Ear Speech String Bean (6kHz)', prop: 'leftSSB6kHz'},
    {name: 'Left Ear Speech String Bean (8kHz)', prop: 'leftSSB8kHz'},
    {name: 'Right Ear'},
    {name: 'Right Ear Threshold (250Hz)', prop: 'right250Hz'},
    {name: 'Right Ear Threshold (500Hz)', prop: 'right500Hz'},
    {name: 'Right Ear Threshold (1kHz)', prop: 'right1kHz'},
    {name: 'Right Ear Threshold (1.5kHz)', prop: 'right15kHz'},
    {name: 'Right Ear Threshold (2kHz)', prop: 'right2kHz'},
    {name: 'Right Ear Threshold (3kHz)', prop: 'right3kHz'},
    {name: 'Right Ear Threshold (4kHz)', prop: 'right4kHz'},
    {name: 'Right Ear Threshold (6kHz)', prop: 'right6kHz'},
    {name: 'Right Ear Threshold (8kHz)', prop: 'right8kHz'},
    {name: 'Right Ear Speech Banana (250Hz)', prop: 'rightSB250Hz'},
    {name: 'Right Ear Speech Banana (500Hz)', prop: 'rightSB500Hz'},
    {name: 'Right Ear Speech Banana (1kHz)', prop: 'rightSB1kHz'},
    {name: 'Right Ear Speech Banana (1.5kHz)', prop: 'rightSB15kHz'},
    {name: 'Right Ear Speech Banana (2kHz)', prop: 'rightSB2kHz'},
    {name: 'Right Ear Speech Banana (3kHz)', prop: 'rightSB3kHz'},
    {name: 'Right Ear Speech Banana (4kHz)', prop: 'rightSB4kHz'},
    {name: 'Right Ear Speech Banana (6kHz)', prop: 'rightSB6kHz'},
    {name: 'Right Ear Speech Banana (8kHz)', prop: 'rightSB8kHz'},
    {name: 'Right Ear Speech String Bean (250Hz)', prop: 'rightSSB250Hz'},
    {name: 'Right Ear Speech String Bean (500Hz)', prop: 'rightSSB500Hz'},
    {name: 'Right Ear Speech String Bean (1kHz)', prop: 'rightSSB1kHz'},
    {name: 'Right Ear Speech String Bean (1.5kHz)', prop: 'rightSSB15kHz'},
    {name: 'Right Ear Speech String Bean (2kHz)', prop: 'rightSSB2kHz'},
    {name: 'Right Ear Speech String Bean (3kHz)', prop: 'rightSSB3kHz'},
    {name: 'Right Ear Speech String Bean (4kHz)', prop: 'rightSSB4kHz'},
    {name: 'Right Ear Speech String Bean (6kHz)', prop: 'rightSSB6kHz'},
    {name: 'Right Ear Speech String Bean (8kHz)', prop: 'rightSSB8kHz'},
    {name: 'Reliability'},
    {name: 'Upload File'},
    {name: 'Notes'}
  ]

  dateControlNames = ['date', 'dateDone', 'dateOfTesting', 'dateLeftEar', 'dateRightEar'];
  intraOpTelemetry$ : Observable<any>;
  intraOpTelemetryPage$ : Observable<any>;
  sessions$ : Observable<any>;
  sessionsPage$ : Observable<any>;
  /* aidedTests$ : Observable<any>;
  aidedTestsPage$ : Observable<any>; */

  form: FormGroup;
  loading = false;
  submitted = false;

  editable: boolean;
  editing: boolean;
  printable: boolean;

  expired$: Observable<any>;


  isAddMode: any;

  id: string;
  patientId: string;
  modelId: string;
  versionId: string;
  switchOnId: string;

  constructor(
    private formBuilder: FormBuilder,
    private switchOnService: SwitchOnService,
    private intraOpTelemetryService: IntraOpTelemetryService,
    private sessionsService: SessionsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private printService: PrintService,
    private userService: UserService,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.editable = true;
    this.editing = false;
    this.printable = true;

    this.expired$ = this.patientService.record.pipe(
      map(x => x.dataModel.patientExpired == true)
    )

    this.form = this.formBuilder.group({
      patient: [''],
      surgeryOutside: [''],
      dateLeftEar: [''],
      dateRightEar: [''],
      brandLeft: [''],
      brandRight: [''],
      brandLeftOthersPostOp: [''],
      brandRightOthersPostOp: [''],
      processorLeft: [''],
      processorRight: [''],
      additionalNotes: ['']
    });

    this.intraOpTelemetryService.getAll(this.id, 0).subscribe();
    
    this.intraOpTelemetry$ = this.intraOpTelemetryService.recordsObservable.pipe(
      map(intraOpTelemetry => intraOpTelemetry["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.intraOpTelemetryPage$ = this.intraOpTelemetryService.recordsObservable

    this.sessionsService.getAll(this.id, 0).subscribe();
    
    this.sessions$ = this.sessionsService.recordsObservable.pipe(
      map(sessions => sessions["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )

    this.sessionsPage$ = this.sessionsService.recordsObservable

    /* this.sessionsService.getAll(this.id, 0).subscribe();
    
    this.aidedTests$ = this.sessionsService.recordsObservable.pipe(
      map(aidedTests => aidedTests["results"].map(x => Utils.mapModelToColumns(x,this.dateControlNames)))
    )
    
    this.aidedTestsPage$ = this.sessionsService.recordsObservable */

    this.switchOnService.getByPatient(this.id).subscribe();

    this.switchOnService.recordObservable.subscribe(
      res => {
        if(res) {
          this.versionId = res["versionId"];
          this.modelId = res["modelId"];
          this.form.patchValue(Utils.mapModelToForm(res, this.dateControlNames));
        }
      }
    );
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    if (!this.switchOnService.recordValue) {
        this.createSwitchOn();
    } else {
        this.updateSwitchOn();
    }
  }

  getPageIntraOpTelemetry(page: string) {
    this.intraOpTelemetryService.getAll(this.id, page).subscribe();
  }

  getPageSessions(page: string) {
    this.sessionsService.getAll(this.id, page).subscribe();
  }

  /* getPageAidedTests(page: string) {
    this.sessionsService.getAll(this.id, page).subscribe();
  } */

  createSwitchOn() {
    this.form.get("patient").setValue(this.id)
    this.switchOnService.add(this.form.value)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Switch On added successfully', { keepAfterRouteChange: true });
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
  }
    
  updateSwitchOn() {
      this.switchOnService.update(this.form.value, this.modelId, this.versionId)
          .pipe(first())
          .subscribe({
              next: () => {
                  this.alertService.success('Update successful', { keepAfterRouteChange: true });
              },
              error: error => {
                  this.alertService.error(error);
                  this.loading = false;
              }
          });
  }

  deleteIntraOpTelemetry(id: string) {
    this.intraOpTelemetryService.delete(id).pipe(first())
        .subscribe();
  }

  deleteSessions(id: string) {
    this.sessionsService.delete(id).pipe(first())
        .subscribe();
  }

  deleteRecord(id: string) {
    
  }

  cancelEdit() {
    if(confirm("Unsaved changes will be lost. Proceed?")){
      this.ngOnInit();
      this.editing = false;
    }
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || ((user['facility']['roles'].includes('Category D')) && this.patientService.recordSubject.value['dataModel']['currFacility'] == user['facility']['facilityCode']);
  }

}
