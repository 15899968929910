<div class="card my-3">
    <div class="card-header d-flex justify-content-between">
        <span>Events</span>
        <div *ngIf="validatePrivilege()">
            <!-- <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                             -->
            <button type="button" *ngIf="this.editable && !this.editing && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
            <button type="button" *ngIf="this.editable && this.editing" (click)="this.editing = false;" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
        </div>
    </div>
    <div class="card-body">    
        <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
            <label>Complications Outside Surgery</label>
            <app-table [modelName]="'complicationsOutsideSurgery'" [isWriteMode]="this.editing" [data]="complicationsOutsideSurgery$ | async" [pageData]="complicationsOutsideSurgeryPage$ | async" [printTitle]="'Complications Outside Surgery'" [columns]="columnsCOS" [addLink]="'surgical-hearing-interventions-events/add-complications-outside-surgery'" [editLink]="'surgical-hearing-interventions-events/edit-complications-outside-surgery'" (pageEvent)="getPageComplicationsOutsideSurgery($event)" (deleteRowEvent)="deleteCOS($event)"></app-table>
            <label>Explanted Devices</label>
            <app-table [modelName]="'explantedDevice'" [isWriteMode]="this.editing" [data]="explantedDevice$ | async" [pageData]="explantedDevice$ | async" [printTitle]="'Explanted Devices'" [columns]="columnsExplantedDevice" [addLink]="'surgical-hearing-interventions-events/add-explanted-device'" [editLink]="'surgical-hearing-interventions-events/edit-explanted-device'" (pageEvent)="getPageExplantedDevice($event)" (deleteRowEvent)="deleteExplantedDevice($event)"></app-table>
        </fieldset>
    </div>
</div>