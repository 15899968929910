import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/admin/users/users.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { UserService } from '@app/user/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  profiles$: any;

  constructor(private usersService: UsersService, 
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.profiles$ = this.usersService.getAll()
  }

  selectProfile(profile) {
    this.userService.selectProfile(profile)
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.router.navigateByUrl(returnUrl);
  }

  deleteProfile(profile){
    this.usersService.delete(profile["id"]).pipe(first())
      .subscribe({
        next: () => {
          this.profiles$ = this.usersService.getAll()
          this.alertService.success('Profile deleted successfully', { keepAfterRouteChange: true });
        },
        error: error => {
          this.alertService.error(error);
        }
      });
  }
}
