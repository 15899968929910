import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, first, switchMap } from 'rxjs/operators';

import { UsersService } from '@app/admin/users/users.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FacilitiesService } from '@app/admin/facilities/facilities.service';
import Utils from '@app/shared/utils';

@Component({ template: '' })
export class AddEditComponent implements OnInit {
    
    constructor(public dialog: MatDialog,
        private location: Location,
        private formBuilder: FormBuilder,
        private usersService: UsersService,
        private alertService: AlertService,
        private facilitiesService: FacilitiesService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.openDialog();
    }

    openDialog(): void {
        let id = this.route.snapshot.params['id']
        let isFacilityUsers = this.route.snapshot['_routerState'].url.startsWith("/facility/users")
        const dialogRef = this.dialog.open(AddEditComponentDialog, {
            data: {
                formBuilder: this.formBuilder,
                id: id,
                isAddMode: !id,
                isFacilityUsers: isFacilityUsers,
                usersService: this.usersService,
                alertService: this.alertService,
                facilitiesService: this.facilitiesService,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.location.back();
        });
    }
}

@Component({ templateUrl: 'add-edit.component.html' })
export class AddEditComponentDialog implements OnInit {

    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    facility = new FormControl();
    filteredFacilities: Observable<string[]>;
    facilities: string[] = [];
    allFacilities: string[] = ['NTHC', 'NHSRC', 'PGH - Ear Unit'];
    facilityOptions$: Observable<string[]>;

    roleCtrl = new FormControl();
    filteredRoles: Observable<string[]>;
    roles: string[][] = [];
    allRoles: string[] = ['Category A','Category B','Category C','Category D','Unit Manager'];
    
    profiles: object = [{ 'profileName': 'jdcruz' },{ 'profileName': 'adminjcruz' }];
    
    @ViewChild('facilityInput') facilityInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    /* @ViewChild('roleInput') roleInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto1') matAutocomplete1: MatAutocomplete; */
    @ViewChildren('roleInput') roleInput: QueryList<HTMLInputElement>;
    
    @ViewChild('auto1') matAutocomplete1: MatAutocomplete;

    form: FormGroup;
    loading = false;
    submitted = false;

    id: string;
    isAddMode: boolean;
    isProfile: boolean;
    isFacilityUsers: boolean;

    constructor(        
        public dialogRef: MatDialogRef<AddEditComponentDialog>,        
        private usersService: UsersService,
        private alertService: AlertService,  
        private facilitiesService: FacilitiesService,      
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder, 
            id: string, 
            isAddMode: boolean,
            isProfile: boolean,
            isFacilityUsers:boolean,
            usersService: UsersService,
            alertService: AlertService,
        }
    ) {
        this.id = data.id;
        this.isAddMode = data.isAddMode;
        this.isProfile = data.isProfile;
        this.isFacilityUsers = data.isFacilityUsers
        this.usersService = data.usersService;
        this.alertService = data.alertService;

        this.filteredFacilities = this.facility.valueChanges.pipe(
            startWith(null),
            map((facility: string | null) => facility ? this._filter(facility) : this.allFacilities.slice()));

        this.filteredRoles = this.roleCtrl.valueChanges.pipe(
            startWith(null),
            map((role: string | null) => role ? this._filter(role) : this.allRoles.slice()));
    }

    ngOnInit() {

        const passwordValidators = [Validators.minLength(6)];
        
        this.form = this.data.formBuilder.group({
            firstName: ['', Validators.required],
            middleName: [''],
            lastName: ['', Validators.required],
            username: ['', Validators.required],
            hasTemporaryPassword:[''],
            profileName: [''],
            linkAccountId: [''],
            nhsrcAccredNo: [''],
            nhsrcAccredDate: [''],
            designation: [''],
            profession: [''],
            prcNo: [''],
            facility: [''],
            roles: [''],
            facilityAndRoles: [''],
            isAdmin: [''],
            isGuest: ['']
        });
        if (!this.isAddMode) {
            this.usersService.getById(this.id)
                .pipe(first())
                .subscribe(x => {
                    x["nhsrcAccredDate"] = x["nhsrcAccredDate"]?  new Date(x["nhsrcAccredDate"] as number) : null
                    this.form.patchValue(x)
                    this.facilities = x["facilityAndRoles"];
                    this.roles = x["facilityAndRoles"].map(r => r.roles);
                });
        } else {
            passwordValidators.push(Validators.required);
        }

        this.facilityOptions$ = this.facility.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            switchMap(value => {
              if (value !== '' && value != null) {
                return this.search(value);
              }
              else
                return of(null)
            })
          );
        
        this.form.get('isGuest').valueChanges.subscribe(x => this.assignGuestFacilityAndRole(x))
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createUser();
        } else {
            this.updateUser();
        }
    }

    private createUser() {
        this.constructFacilityAndRoles();
        this.usersService.register(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('User added successfully', { keepAfterRouteChange: true });
                    this.dialogRef.close()
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    private updateUser() {
        this.constructFacilityAndRoles();
        this.usersService.update(this.id, this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.dialogRef.close()
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    constructFacilityAndRoles(){
        var facilityAndRoles: any[] = []
        this.facilities.forEach((value,index)=>{
            var tmpRoles: string[] = []
            for (let role of this.roles[index]){
                tmpRoles.push(role)
            }
            var x = {
                "facility": value["facility"],
                "category": value["category"],
                "facilityCode": value["facilityCode"],
                "facilityModelId": value["id"]? value["id"] : value["facilityModelId"] ,
                "psgcCode": value["psgcCode"],
                "roles" : tmpRoles
            }
            facilityAndRoles.push(x)
        })
        this.form.controls['facilityAndRoles'].setValue(facilityAndRoles);
    }
    resetPassword(){
        if(confirm("Are you sure you want to reset the password?")){this.constructFacilityAndRoles();
            this.usersService.resetPassword(this.id, this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Password reset successful', { keepAfterRouteChange: true });
                    this.dialogRef.close()
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });}
    }

    clearFacilityInput(): void {
        this.facility.setValue(null);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
    
        // Add our facility
        if ((value || '').trim()) {
          this.facilities.push(value.trim());
        }
    
        // Reset the input value
        if (input) {
          input.value = '';
        }
    
        this.facility.setValue(null);
      }
    
      remove(facility: string): void {
        const index = this.facilities.indexOf(facility);
        if (index >= 0) {
          this.facilities.splice(index, 1);
          this.roles.splice(index, 1);
        }
      }
    
      selected(event: MatAutocompleteSelectedEvent): void {
        this.facilities.push(event.option.value);
        this.facilityInput.nativeElement.value = '';
        this.clearFacilityInput();
        
      }
    
      private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.allFacilities.filter(facility => facility.toLowerCase().indexOf(filterValue) === 0);
      }

      clearRoleInput(): void {
        this.roleCtrl.setValue(null);
      }

      addRole(event: MatChipInputEvent): void {
        console.log("addRole");
        const input = event.input;
        const value = event.value;
        // Add our facility
        /* if ((value || '').trim()) {
           this.roles.push(value.trim()); 
        } */
    
        // Reset the input value
      /*   if (input) {
          input.value = '';
        }
    
        this.roleCtrl.setValue(null); */
      }
    
      removeRole(role: string, i:number): void {
        const index = this.roles[i].indexOf(role);
        if (index >= 0) {
          this.roles[i].splice(index, 1);
        }
      }
    
      selectedRole(event: MatAutocompleteSelectedEvent): void {
        var i = Number(event.source["_elementRef"].nativeElement.id.substring(8))
        if(this.roles[i]){
            var x = this.roles[i].length
            this.roles[i][x] = event.option.viewValue
            
        }else{
            var tmp: string[] = []
            tmp.push(event.option.viewValue)
            this.roles.push(tmp)
        }
        if(this.roleInput.toArray()[i] != undefined){
            this.roleInput.toArray()[i].value = "";
            this.clearRoleInput();
        }
      }
    
      /* private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.allRoles.filter(role => role.toLowerCase().indexOf(filterValue) === 0);
      } */

      search(name): Observable<string[]> {
        return this.facilitiesService.search(name).pipe(
        map(facilities => facilities["results"].map(x => ({id: x.modelId, facility: x.dataModel.facilityName, category:  x.dataModel.category, facilityCode: x.dataModel.nshrcFacilityAccreditationNumber, psgcCode: x.dataModel.psgcCode}))))
      }

      assignGuestFacilityAndRole(data){
        this.loading = true;
        if(data && this.isAddMode){
            this.search('Guest').subscribe(x => this.facilities.push(x[0]))
            this.roles.push(['Guest']);
        }else{
            this.facilities = []
            this.roles = []
        }
        this.loading = false;
      }
}