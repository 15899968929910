<div class="card my-3">
    <div class="card-header d-flex justify-content-between">
        <span>Other Tests</span>
        <div *ngIf="validatePrivilege()">
            <!-- <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                             -->
            <button type="button" *ngIf="this.editable && !this.editing && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
            <button type="button" *ngIf="this.editable && this.editing" (click)="this.editing = false;" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
        </div>
    </div>
    <div class="card-body">   
        <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
            <label>Tympanometry</label>
            <app-table [modelName]="'tympanometry'" [isWriteMode]="this.editing" [data]="tympanometry$ | async" [pageData]="tympanometryPage$ | async" [printTitle]="'Tympanometry REcords'" [columns]="columnsTympanometry" [addLink]="'confirmatory-other-tests/add-tympanometry'" [editLink]="'confirmatory-other-tests/edit-tympanometry'" (pageEvent)="getPageTympanometry($event)" (deleteRowEvent)="deleteTympanometry($event)"></app-table>
            <label>Acoustic Reflex Test</label>
            <app-table [modelName]="'acousticReflexTest'" [isWriteMode]="this.editing" [data]="acousticReflexTest$ | async" [printTitle]="'Acoustic Reflex Tests'" [columns]="columnsART" [addLink]="'confirmatory-other-tests/add-acoustic-reflex-test'" [editLink]="'confirmatory-other-tests/edit-acoustic-reflex-test'" (pageEvent)="getPageAcousticReflexTest($event)" (deleteRowEvent)="deleteAcousticReflexTest($event)"></app-table>
        </fieldset>
    </div> 
</div>