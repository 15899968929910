import { Guid } from "guid-typescript";
import * as moment from "moment";
import { Moment } from "moment";
import { FieldUpdate } from "./_models/field-update";
import { Sync } from "./_models/sync";

export default class Utils {
    static getDiff(original: Sync, updated: Sync){
        var inOriginal = JSON.parse(JSON.stringify(original));
        var inUpdated = JSON.parse(JSON.stringify(updated));
        if(inOriginal.fieldUpdates.some(fieldUpdate => fieldUpdate.indexIds ) || inUpdated.fieldUpdates.some(fieldUpdate => fieldUpdate.indexIds )){
            this.getDiffArray(inOriginal, inUpdated);
        }
        let out = JSON.parse(JSON.stringify(inUpdated));
        for(var i = inUpdated.fieldUpdates.length - 1; i >= 0; i--) {
            var newField = inUpdated.fieldUpdates[i];
            if(newField.value instanceof moment)
                newField.value = (<Moment> newField.value).toDate();
            var oldField = inOriginal.fieldUpdates.find(x => x.fieldPath === newField.fieldPath);
            if(oldField && oldField.value != null && oldField.fieldPath !== 'meta') {
                if((typeof oldField.value === 'number' && typeof newField.value === 'object' ? 
                    (new Date(oldField.value)).getTime() : 
                    (typeof oldField.value === 'object' && !Array.isArray(oldField.value) ? (<Date>oldField.value).getTime() : oldField.value)) 
                    === 
                    (typeof newField.value === 'object' && !Array.isArray(newField.value) ? (<Date>newField.value).getTime() : newField.value)) {
                    out.fieldUpdates.splice(i, 1);
                }
            } else {
                if((newField.value == null && !newField.indexIds ) || newField.value === "" || newField.fieldPath === 'meta') {
                    out.fieldUpdates.splice(i, 1);
                }
            }
            
        }
        return out;
    }

    static getDiffArray(original: Sync, updated: Sync){
        var originalLength = original.fieldUpdates.filter(fieldUpdate => fieldUpdate.indexIds).length;
        var updatedLength = updated.fieldUpdates.filter(fieldUpdate => fieldUpdate.indexIds).length;
        if(originalLength > updatedLength){
            var data = original.fieldUpdates.filter(fieldUpdate => fieldUpdate.indexIds).slice(updatedLength = 1? updatedLength : updatedLength-1);
            data.forEach(fieldUpdates => fieldUpdates.value = null);
            updated.fieldUpdates = updated.fieldUpdates.concat(data);
        }
        original.fieldUpdates = original.fieldUpdates.filter(function( obj ) {
            return !obj.hasOwnProperty('indexIds');
        });
    }

    static convertToSync(model: any, modelName: string, modelId: string, versionId?: string, userId?: string, dateSynced? : Date){
        let sync = new Sync();
        for (let [key, value] of Object.entries(model)) {
            let field = new FieldUpdate();
            if(Array.isArray(value)){
                value.forEach((e,i)=> {
                    let fieldArr = new FieldUpdate();
                    fieldArr.indexIds = [];
                    fieldArr.indexIds.push(i.toString());
                    fieldArr.fieldPath = key+"[]",
                    fieldArr.value = e;
                    fieldArr.modelName = modelName;
                    fieldArr.modelId = modelId;
                    fieldArr.dateUpdated = new Date();
                    fieldArr.userId = userId;
                    fieldArr.dateSynced = dateSynced;
                    sync.fieldUpdates.push(fieldArr)
                })
            } else {
                field.fieldPath = key,
                field.value = value;
                field.modelName = modelName;
                field.modelId = modelId;
                field.dateUpdated = new Date();
                field.userId = userId;
                field.dateSynced = dateSynced;
                sync.fieldUpdates.push(field)
            }
        }
        if(!versionId) {
            let obj = {};
            obj[modelName] = [ modelId ];
            sync.newModelIds = obj;
        } else {
            let obj = {};
            obj[modelName] = {};
            obj[modelName][modelId] = versionId;
            sync.syncTokens = obj;
        }
        return sync;
    }

    static mapModelToForm(model, dates?) {
        let obj = {};
        for (let [key, value] of Object.entries(model["dataModel"])) {
            obj[key] = dates?.includes(key) && value !== null ? new Date(value as number) : value;
        }
        obj["id"] = model["modelId"];
        obj["versionId"] = model["versionId"];
        return obj;
    }

    static mapModelToColumns(model, dates?) {
        let obj = this.mapModelToForm(model, dates)
        obj["updatedBy"] = model["updatedByName"] || model['dataModel']["originUpdatedByName"] || model["createdByName"] ;
        return obj;
    }

    /* static cleanCode (model, code) {
        let obj = {}
        obj[""] = (code).slice(1,-1)
        obj["id"] = model["modelId"];
        obj["versionId"] = model["versionId"];
        return obj;
    }  */

    static psgcUtils = class {
        static cleanPsgc(code) {
            return ('0' + code).slice(-9);
        }

        static isCityMunPsgc(code) {
            if(code == '133900000')
                return false;
            return this.cleanPsgc(code).slice(6,9) === '000'
        }

        static convertToCityMunPsgc(code) {
            if(code == '133900000')
                return '133900000-1'
            return this.cleanPsgc(code).slice(0,6) + '000';
        }

        static isProvPsgc(code) {
            if(code == '133900000')
                return false;
            return this.cleanPsgc(code).slice(4,9) === '00000'
        }

        static convertToProvPsgc(code) {
            if(code == '133900000')
                return '133900000-2'
            return this.cleanPsgc(code).slice(-9).slice(0,4) + '00000';
        }

        static isRegPsgc(code) {
            return this.cleanPsgc(code).slice(2,9) === '0000000'
        }

        static convertToRegPsgc(code) {
            return this.cleanPsgc(code).slice(-9).slice(0,2) + '0000000';
        }
    }

    static generateGuid() {
        let id = Guid.create();
        return id["value"]
    }   

    static camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    static groupBy(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    };

    static getMetadataDate(field?, originalSync?) {
        var date;
        if(field["fieldPath"]?.toLowerCase().includes("date")){
            //put multiple date logic
            date = field["value"];
        }
        if(originalSync){
            originalSync["fieldUpdates"].forEach(element => {
               if(element["fieldPath"].toLowerCase().includes("date")){ 
                   //put multiple date logic
                     date = new Date(element["value"] as number);
                }
            })
        }
        return date;
    }

    static computeAge(date1, date2) {
        const timeDiff = Math.abs(date1 - date2);
        var diffMonth = Math.floor((timeDiff / 1000));
        diffMonth /= (60 * 60 * 24 * 7 * 4);
        return Math.abs(Math.floor(diffMonth));
    }

    static generateMeta(item) {
        let user = JSON.parse(localStorage.getItem('user'));
        item["meta.category"] = user.facility.category;
        item["meta.facilityCode"] = user.facility.facilityCode;
        item["meta.psgcCode"] = user.facility.psgcCode;
        item["meta.periodDate"] = "";
        return item
    }

    static checkIfSealExists(item,allSeals){
        var seals: [] = allSeals["results"]
        var tempSeals = [];
        var arrDuplicate = [];
        const batchCode = item["batchCode"];
        if(item["serialNumber"])
            tempSeals.push({"batchCode": item["batchCode"],serialNumber: String(item["serialNumber"])})
        else{
            for (var i = item["rangeFrom"]; i <= item["rangeTo"]; i++) {
                tempSeals.push({"batchCode": batchCode,serialNumber:i.toString()});
            }
        }
        tempSeals.forEach(element => {
            const found = seals.some(function (e) {
                return e["dataModel"]["serialNumber"] === element["serialNumber"] && e["dataModel"]["batchCode"] ===  element["batchCode"];
            }, element);
            if (found) arrDuplicate.push(batchCode+element["serialNumber"]);
        });
        return arrDuplicate;
    }
}
