import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/user/_models/user';
import { UserService } from '@app/user/user.service';
import { Clipboard } from "@angular/cdk/clipboard"

@Injectable({ providedIn: 'root' })
export class UsersService extends UserService{
    public user: Observable<User>;

    constructor(
        public router: Router,
        public http: HttpClient,
        public clipboard: Clipboard
    ) {
        super(router, http, clipboard);
    }
    
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/dashboard/user/getAll`);
    }
    getAllByFacility(facilityCode: string) {
        return this.http.get<User[]>(`${environment.apiUrl}/dashboard/user/getAll/facilityCode/${facilityCode}`);
    }
}
