<!-- <div class="container">
    <div class="card">
        <div class="card-header">
            View History
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12"> -->
                    <app-table [forChangeHistory]="true" [isWriteMode]="false" [noAdd]="true" [isDeletable]="false" [printTitle]="'Hearing Aid/Device Aided Tests'" [data]="data$ | async" [columns]="columns">
                    </app-table>
                <!-- </div>
            </div>
        </div>
    </div>
</div> -->