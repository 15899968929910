import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService } from '@app/shared/alert/alert.service';
import Utils from '@app/shared/utils';
import { first } from 'rxjs/operators';
import { HearingAidDevicesService } from '../hearing-aid-devices.service';
import { PrintService } from '@app/shared/print/print.service';
import { PatientService } from '@app/patient/patient.service';

@Component({ template: '' })
export class AddEditHearingAidDevicesComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
      this.openDialog();
  }

  openDialog(): void {
      const dialogRef = this.dialog.open(AddEditHearingAidDevicesComponentDialog, {
          data: {
              formBuilder: this.formBuilder,
              id: this.route.snapshot.params['id']
          }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.location.back();
      });
  }

}

@Component({ templateUrl: 'add-edit-hearing-aid-devices.component.html' })
export class AddEditHearingAidDevicesComponentDialog implements OnInit {
    
    form: FormGroup;
    loading = false;
    submitted = false;

    editable: boolean;
    editing: boolean;
    printable: boolean;

    isAddMode: any;

    id: string;
    patientId: string;
    modelId: string;
    versionId: string;

    constructor(        
        public dialogRef: MatDialogRef<AddEditHearingAidDevicesComponentDialog>,        
        @Inject(MAT_DIALOG_DATA) public data: {
            formBuilder: FormBuilder,
            id: string
        },
        public dialog: MatDialog,
        private hearingAidDevicesService: HearingAidDevicesService, 
        private formBuilder: FormBuilder, 
        private alertService: AlertService,
        public printService: PrintService,
        private patientService: PatientService
    ) { 
        this.id = data.id;
    }

    ngOnInit() {
      this.patientId = this.patientService.recordSubject.getValue()["modelId"];

      this.isAddMode = !this.id;
  
      this.editable = this.id? true:false;
      this.editing = false;
      this.printable = true;
  
      this.form = this.formBuilder.group({
        patient: [''],
        notDone: [''],
        notDoneReason: [''],
        indicateEar: [''],
        hearingAidTrial: [''],
        dateOfFitting: ['', Validators.required],
        earAmplified: [''],
        typeOfHearingAid: [''],
        typeOfHearingAidSpecify: [''],
        typeOfAmplification: [''],
        typeOfAmplificationSpecify: [''],
        brand: [''],
        model: [''],
        serialNumber: [''],
        bands: [''],
        channels: [''],
        warranty: [''],
        batterySize: [''],
        
        hearingAidChanged: [''],
        dateOfTesting: [''],
        typeOfAidedTest: [''],
        typeOfAidedTestSpecify: [''],
        testingConditions: [''],

        rightEar: [''],

        rightEar250: [''],
        rightEar500: [''],
        rightEar1000: [''],
        rightEar1500: [''],
        rightEar2000: [''],
        rightEar3000: [''],
        rightEar4000: [''],
        rightEar6000: [''],
        rightEar8000: [''],

        rightEar250SpeechBanana: [''],
        rightEar500SpeechBanana: [''],
        rightEar1000SpeechBanana: [''],
        rightEar1500SpeechBanana: [''],
        rightEar2000SpeechBanana: [''],
        rightEar3000SpeechBanana: [''],
        rightEar4000SpeechBanana: [''],
        rightEar6000SpeechBanana: [''],
        rightEar8000SpeechBanana: [''],

        rightEar250SpeechStringBean: [''],
        rightEar500SpeechStringBean: [''],
        rightEar1000SpeechStringBean: [''],
        rightEar1500SpeechStringBean: [''],
        rightEar2000SpeechStringBean: [''],
        rightEar3000SpeechStringBean: [''],
        rightEar4000SpeechStringBean: [''],
        rightEar6000SpeechStringBean: [''],
        rightEar8000SpeechStringBean: [''],

        leftEar: [''],

        leftEar250: [''],
        leftEar500: [''],
        leftEar1000: [''],
        leftEar1500: [''],
        leftEar2000: [''],
        leftEar3000: [''],
        leftEar4000: [''],
        leftEar6000: [''],
        leftEar8000: [''],

        leftEar250SpeechBanana: [''],
        leftEar500SpeechBanana: [''],
        leftEar1000SpeechBanana: [''],
        leftEar1500SpeechBanana: [''],
        leftEar2000SpeechBanana: [''],
        leftEar3000SpeechBanana: [''],
        leftEar4000SpeechBanana: [''],
        leftEar6000SpeechBanana: [''],
        leftEar8000SpeechBanana: [''],

        leftEar250SpeechStringBean: [''],
        leftEar500SpeechStringBean: [''],
        leftEar1000SpeechStringBean: [''],
        leftEar1500SpeechStringBean: [''],
        leftEar2000SpeechStringBean: [''],
        leftEar3000SpeechStringBean: [''],
        leftEar4000SpeechStringBean: [''],
        leftEar6000SpeechStringBean: [''],
        leftEar8000SpeechStringBean: [''],

        reliability: [''],
        uploadFile: [''],
        notes: [''],
      });
  
      
      if (!this.isAddMode) {
        var model = this.hearingAidDevicesService.recordsValue["results"].find(x => x.modelId == this.id);
        this.versionId = model["versionId"];
        this.modelId = model["modelId"];
        this.form.patchValue(Utils.mapModelToForm(model, ['dateOfFitting', 'dateOfTesting']));
      }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() { 
      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();
  
      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }
  
      this.loading = true;
      if (this.isAddMode) {
          this.createHearingAid();
      } else {
          this.updateHearingAid();
          console.log("Goes here");
      }
    }

    createHearingAid() {
      this.form.get("patient").setValue(this.patientId)
      this.hearingAidDevicesService.add(this.form.value)
      .pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Hearing Aid Device added successfully', { keepAfterRouteChange: true });
              this.dialogRef.close();
          },
          error: error => {
              this.alertService.error(error);
              this.loading = false;
          }
      });
    }
    
    updateHearingAid() {
      this.hearingAidDevicesService.update(this.form.value, this.modelId, this.versionId)
      .pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Update successful', { keepAfterRouteChange: true });
              this.dialogRef.close();
          },
          error: error => {
              this.alertService.error(error);
              this.loading = false;
          }
      });
    }
}
