<div class="card my-3">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card-header d-flex justify-content-between">
            <span>Hearing Screening</span>
            <div *ngIf="validatePrivilege()">
                <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" (click)="printService.print(['details', print])" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>
                <button type="button" *ngIf="this.editable && !this.editing  && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
                <button type="submit" *ngIf="this.editable && this.editing" class="btn btn-success ml-1 px-2 py-0"><i class="mdi mdi-content-save"></i></button>
                <button type="button" *ngIf="this.editable && this.editing" (click)="cancelEdit()" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
            </div>
        </div>
        <div class="card-body" #print>   
            <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
                <div class="form-row pb-2">
                    <label>Patient Screening Details</label>
                </div>
                <div class="form-group ml-3">
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="serialNumber">NHSRC Seal Control Number</label>
                            <input type="text" #sNumberInput class="form-control" formControlName="serialNumber" [matAutocomplete]="auto" (blur)="checkOptions()"/> <!-- [ngClass]="{ 'is-invalid': submitted && f.serialNumber.errors }" /> -->
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let sNumber of sNumberOptions | async" [value]="sNumber.dataModel">
                                    {{sNumber}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                        <div class="form-group col-4">
                            <label for="hSC">Hearing Screening Center</label>
                            <input disabled type="text" class="form-control" formControlName="hSC"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="status">Status</label>
                            <div class="py-2">
                                <mat-radio-group formControlName="status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                    <mat-radio-button class="mr-3" [value]="'done'">Done</mat-radio-button>
                                    <mat-radio-button class="mr-3" #notDoneReason [value]="'not done'">Not Done</mat-radio-button>
                                    <mat-radio-button #refusedReason [value]="'refused'">Refused</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                    <div *ngIf="f.status.errors.required">Status is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-4" *ngIf="notDoneReason.checked">
                            <label for="notDoneReason">Reason</label>
                            <input type="text" class="form-control" formControlName="notDoneReason"/>
                        </div>
                        <div class="form-group col-4" *ngIf="refusedReason.checked">
                            <label for="refusedReason">Reason</label>
                            <input type="text" class="form-control" formControlName="refusedReason"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="showScreening">
                    <div class="form-row">
                        <label>Add Screening</label>
                    </div>
                    <div class="form-group ml-3">
                        <app-table [modelName]="'hearingScreening'" [isWriteMode]="this.editing" [data]="hearingScreening$ | async" [pageData]="hearingScreeningPage$ | async" [printTitle]="'Hearing Screening Records'" [columns]="columns" [addLink]="'hearing-screening/add-hearing-screening'" [editLink]="'hearing-screening/edit-hearing-screening'" (pageEvent)="getPageHearingScreening($event)" (deleteRowEvent)="deleteHearingScreening($event)">
                        </app-table>
                    </div>
                </div>
            </fieldset>
        </div>
    </form>
</div>