<div *ngIf="isLoading" class="p-3">
    <div [style.width]="'200px'" class="d-flex justify-content-center">
        <mat-spinner [diameter]="25"></mat-spinner>
    </div>
</div>
<div class="position-absolute p-3">
    <button [disabled]="isLoading" routerLink="./add" class="btn btn-primary mr-2 mb-3 btn-process-add">Process New Request</button>
    <div style="width: fit-content;">
        <div *ngIf="processedRequest$ | async as processedRequest">
            <div *ngIf="processedRequest.results.length > 0 else zeroProcessing" class="d-flex w-100 flex-wrap">
                <div *ngFor="let request of processedRequest.results">
                    <div class="card card-body d-flex p-3 mb-3 mr-3">
                        <small>{{request.dataModel.referenceID | shortId | uppercase }}</small>
                        <small><b>{{request.dataModel.dateCreated | date}}</b></small>
                        <h5 class="mr-2 mb-0"><b>{{request.dataModel.facility.facilityName}}</b></h5>
                        <span class="mb-3"><!-- <i class="mdi mdi-home mr-1"></i>{{request.dataModel.facility.fullAddress}} --></span>
                        <span class="mb-2">Seals:</span>
                        <app-table [data]="request.dataModel.sealsData" [columns]="columns" [addLink]="request.addLink" [editLink]="'edit/seal'" [isDeletable]="true" [isEditable]="false"  [forChangeHistory]="true" (deleteRowEvent)="deleteSeal($event, request.modelId)"></app-table>
                       <!--  <fieldset class="mb-3" disabled>
                            <textarea class="form-control" rows="10">
                                10001-10050
                                10101-10150
                                10301-10350
                            </textarea>
                        </fieldset> -->
                        <div class="ml-auto d-flex align-items-center">
                            <span class="mr-2">{{request.dataModel.seals.results.length}}/{{request.dataModel.quantity}}</span>
                            <a class="btn btn-danger mr-2" (click)="cancelRequest(request.modelId)">Cancel</a>
                            <button  [ngClass]="{'btn btn-dark': request.dataModel.seals.results.length != request.dataModel.quantity ,'btn btn-success': !request.dataModel.seals.results.length != request.dataModel.quantity}" [disabled]="request.dataModel.seals.results.length != request.dataModel.quantity" (click)="shipRequest(request.modelId)">Ship</button>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <ng-template #zeroProcessing>
            <div style="width: fit-content;" class="text-danger">
                No request in process
            </div>
        </ng-template>
       <!--  <div class="card card-body d-flex p-3 mb-3">
            <small>A0000307096</small>
            <small><b>2/10/2021</b></small>
            <h5 class="mr-2 mb-0"><b>Facility Name</b></h5>
            <span class="mb-3"><i class="mdi mdi-home mr-1"></i> #467 Neptune St., Solar Homes, Brgy. Luna, Tondo, Manila</span>
            <span class="mb-2">Seals</span>
            <fieldset class="mb-3" disabled>
                <textarea class="form-control" rows="10">
                    10001-10050
                    10101-10150
                    10301-10350
                </textarea>
            </fieldset>
            <div class="ml-auto">
                <button class="btn btn-danger mr-2">Cancel</button>
                <button class="btn btn-success">Ship</button>
            </div>
        </div> -->
    </div>
    <router-outlet></router-outlet>
</div>
