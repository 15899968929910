<div class="card my-3">
    <div class="card-header d-flex justify-content-between">
        <span>Developmental Monitoring</span>
        <div *ngIf="validatePrivilege()">
            <!-- <button type="button" *ngIf="this.printable && this.editable ? !this.editing : true" class="btn btn-primary px-2 py-0"><i class="mdi mdi-printer"></i></button>                             -->
            <button type="button" *ngIf="this.editable && !this.editing && !(this.expired$ | async)" (click)="this.editing = true;" class="btn btn-primary ml-1 px-2 py-0"><i class="mdi mdi-pencil"></i></button>
            <button type="button" *ngIf="this.editable && this.editing" (click)="this.editing = false;" class="btn btn-danger ml-1 px-2 py-0"><i class="mdi mdi-cancel"></i></button>
        </div>
    </div>
    <div class="card-body">   
        <fieldset [attr.disabled]="this.editable && !this.editing ? true: null">
            <label>PEDS Screenings</label>
            <app-table [modelName]="'pedsScreening'" [isWriteMode]="this.editing" [data]="pedsScreening$ | async" [pageData]="pedsScreeningPage$ | async" [printTitle]="'PEDS Screenings'" [columns]="columns" [addLink]="'developmental/add-peds-screening'" [editLink]="'developmental/edit-peds-screening'" (pageEvent)="getPagePEDSScreening($event)" (deleteRowEvent)="deletePedsScreening($event)"></app-table>
        </fieldset>
    </div>
</div>