import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConceptsModule } from '@app/admin/concepts/concepts.module';
import { PsgcService } from '@app/shared/address/psgc.service';
import Utils from '@app/shared/utils';
import { FieldUpdate } from '@app/shared/_models/field-update';
import { Sync } from '@app/shared/_models/sync';
import { environment } from '@environments/environment';
import { toArray } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SealsService } from '../seals.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessingSealDistributionService {
  private modelName = "sealDistribution";
  private recordName = "processingSealDistribution";
  public sealDistributionSubject: BehaviorSubject<any>;
  public sealDistribution: Observable<any>;

  constructor(
    public router: Router,
    public http: HttpClient,
    private psgcService: PsgcService,
    private sealsService: SealsService
  ) {
    this.sealDistributionSubject = new BehaviorSubject<any>(null);
    this.sealDistribution = this.sealDistributionSubject.asObservable();
   }

  public get recordsValue() {
    return this.sealDistributionSubject.value;
  }
  public get recordsObservable() {
    return this.sealDistribution;
  }

  getAll(){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?requestStatus=PROCESSED&~resolveRef=1`)
    .pipe(
      mergeMap(res => {
        let results = res['results']
        if(!(results as []).length){
          this.sealDistributionSubject.next(res);
          return results;
        }           
        var forks = results.map(x => {
          var psgc = Utils.psgcUtils.cleanPsgc(x.dataModel.facility.psgcCode)
          var innerForks = [this.sealsService.getAll(x["modelId"])/* ,this.psgcService.get(psgc).pipe(map(x => x?.name)) */]
          /* if(!Utils.psgcUtils.isCityMunPsgc(psgc))
            innerForks.push(this.psgcService.get(Utils.psgcUtils.convertToCityMunPsgc(psgc)).pipe(map(x => x?.name)))
          if(!Utils.psgcUtils.isProvPsgc(psgc))
            innerForks.push(this.psgcService.get(Utils.psgcUtils.convertToProvPsgc(psgc)).pipe(map(x => x?.name))) */
          return forkJoin(innerForks);
        })
        return forkJoin(forks)
          .pipe(
            map(fork => {
              res['results'].forEach(element => {
                var model = element.dataModel.facility;
                var fullAddress = [];
                if(model.streetAddress && model.streetAddress !== "")
                  fullAddress.push(model.streetAddress);
                fullAddress = fullAddress.concat((fork[res['results'].indexOf(element)] as []).slice(1));
                element.dataModel['facility'] ? element.dataModel['facility']['fullAddress'] = fullAddress.join(', ') : null;
                element.dataModel['seals'] = fork[res['results'].indexOf(element)][0]
              });
              //localStorage.setItem(this.recordName+'Records', JSON.stringify(res));
              this.sealDistributionSubject.next(res);
              return res;
            })
          )
      })
    );   
  }
  
  update(item: any, modelId: string, versionId: string) {
    var original = this.sealDistributionSubject.value['results'].find(x => x.modelId === modelId);
    item["meta"] = original["dataModel"]["meta"];
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.sealDistributionSubject.value;
        var idx = records["results"].findIndex(x => x.modelId === modelId);
        records["results"][idx].dataModel = item;
        records["results"][idx].versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        //localStorage.setItem(this.recordName+'Records', JSON.stringify(records));
        this.sealDistributionSubject.next(records);
        return res;
      })
    );
  }

  updateStatus(modelId: string,status: string){
    var original = this.sealDistributionSubject.value['results'].find(x => x.modelId === modelId)
    original["dataModel"]["requestStatus"] = status
    original["dataModel"]["dateShipped"] = Date.now()
    var sync = Utils.convertToSync(original["dataModel"], this.modelName,  original['modelId'], original['versionId'])
    sync.fieldUpdates.splice(0, 4);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.sealDistributionSubject.value;
        records['results'].splice(records['results'].findIndex(x => x.modelId === original.modelId),1);
        //localStorage.setItem(this.recordName+'Records', JSON.stringify(records));
        this.sealDistributionSubject.next(records);
        return res;
      })
    );
  }

  addSeals(item: any, sealDistribution: string){
    return this.sealsService.add(item, sealDistribution).pipe(
      mergeMap(res => {
        console.log({res})
        if(res['insertedModelIds']){
          var forks = Object.entries(res['insertedModelIds']["seal"]).map(x => this.sealsService.getById(x[1]['assignedId']));
          return forkJoin(forks).pipe(
            map(res2 => {
              var records = this.sealDistributionSubject.value;
              var sealDistIdx = records['results'].findIndex(x => x.modelId == sealDistribution);
              var sealDist = records['results'][sealDistIdx];
              res2.forEach(e => {
                sealDist.dataModel.seals.results.splice(0,0, e);
              })
              records['results'][sealDistIdx] = sealDist;
              //localStorage.setItem(this.recordName+'Records', JSON.stringify(records));
              this.sealDistributionSubject.next(records);
              return res;
            })
          );
        }
        else{
          let duplicate = []
          res["results"].forEach(element => {
            duplicate.push(element["dataModel"]["batchCode"]+element["dataModel"]["serialNumber"])
          });
          return throwError("Seal(s): " + duplicate.toString() + " already assigned");
        }
      })
    );
  }

  deleteSeals(groupId, modelId){
    var sealDist = this.sealDistributionSubject.value['results'].find(x => x.modelId == modelId)
    var sealDistIdx = this.sealDistributionSubject.value['results'].indexOf(sealDist);
    var seals = sealDist['dataModel']['seals']['results'].filter(y => y.dataModel.groupId == groupId)
    let sync = new Sync();
    sync.syncTokens = { seal: {} };
    seals.forEach(element => {
      let field = new FieldUpdate();
      field.fieldPath = "meta.voided";
      field.value = true;
      field.modelName = "seal";
      field.modelId = element.modelId;
      field.dateUpdated = new Date();
      field.dateSynced = null;
      field.userId = JSON.parse(localStorage.getItem('user')).id;
      sync.fieldUpdates.push(field);
      sync.syncTokens.seal[element.modelId] = element.versionId;
    });
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.sealDistributionSubject.value;
        var sealDist = records['results'][sealDistIdx];
        for(var i=sealDist.dataModel.seals.results.length; i > 0 ; i--) {
          if(sealDist.dataModel.seals.results[i-1].dataModel.groupId == groupId){
            sealDist.dataModel.seals.results.splice(i-1,1);
          }
        }
        records['results'][sealDistIdx] = sealDist;
        //localStorage.setItem(this.recordName+'Records', JSON.stringify(records));
        this.sealDistributionSubject.next(records);
        return res;
      })
    );
  }
}
