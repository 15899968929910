<div class="container">
    <div class="card mx-5">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="card-header">
                Welcome to ENNHSR
             </div>
             <div class="card-body">
                 <fieldset>
                     <label>Please set your permanent password</label>
                     <div class="form-row ml-3">
                        <div class="form-group col-12">
                            <label for="password">Password</label>
                            <div class="input-group">
                                <span class="input-group-prepend" (click)="hide = !hide"><i class="mdi mdi-24px" [ngClass]="hide == true ? 'mdi-eye-off' : 'mdi-eye'"></i></span>
                                <input [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors || submitted && form.errors }" />                     
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Please set your permanent password</div>
                                </div>
                                <div *ngIf="submitted && form.errors" class="invalid-feedback">
                                    <div *ngIf="form.errors.matching">Passwords must match</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row ml-3">
                        <div class="form-group col-12">
                            <label for="password2">Confirm Password</label>
                            <div class="input-group">
                                <span class="input-group-prepend" (click)="hide2 = !hide2"><i class="mdi mdi-24px" [ngClass]="hide2 == true ? 'mdi-eye-off' : 'mdi-eye'"></i></span>
                                <input [type]="hide2 ? 'password' : 'text'" formControlName="password2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password2.errors || submitted && form.errors }" />                        
                                <div *ngIf="submitted && f.password2.errors" class="invalid-feedback">
                                    <div *ngIf="f.password2.errors.required">Please enter your permanent password twice for confirmation</div>
                                </div>
                                <div *ngIf="submitted && form.errors" class="invalid-feedback">
                                    <div *ngIf="form.errors.matching">Passwords must match</div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="form-group mt-3">
                         <div class="d-flex justify-content-center">
                             <div class="col-md-10">
                                 <button [disabled]="loading" class="btn btn-primary btn-block text-nowrap">
                                     <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                     Save
                                 </button>
                                 <div class="d-flex justify-content-around flex-wrap mt-2">
                                     <button class="btn btn-link" [disabled]="loading" (click)="cancel()">Cancel</button>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </fieldset>
             </div>
        </form>
    </div>
</div>