import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MachinesService } from '../machines.service';
import Utils from '@app/shared/utils';

@Component({ templateUrl: 'list.component.html' })
export class ListComponent implements OnInit {
    machines$ : Observable<any>;
    columns = [{ name: 'Type' }, { name: 'Brand' }, { name: 'Serial Number' }];
    facilityId: string;
    constructor(private machinesService: MachinesService) {}

    ngOnInit() {
        this.facilityId = JSON.parse(localStorage.getItem('user'))["facility"]["facilityModelId"];
        this.machinesService.getAll(this.facilityId).subscribe();
        this.machines$ = this.machinesService.recordsObservable.pipe(
        map(machines => machines["results"].map(x => Utils.mapModelToForm(x)))
        )
    }

    deleteMachine(id: string) {
        this.machinesService.delete(id).pipe(first())
        .subscribe();
    } 
}