import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class NoOfPatientsWhoReachedSpeechStringBeanReport {
    @autoserializeAs(String)
    @Column({
        columnName: 'Hearing Loss'
    })
    hearingLoss: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Switch On to 6 Months'
    })
    switchToSixMonths: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'On - 6M %'
    })
    switchToSixMonthsPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: '6 Months (and 1 Day) to 1 Year'
    })
    sixMonthsToOneYear: string;
    @autoserializeAs(String)
    @Column({
        columnName: '6M - 1Y %'
    })
    sixMonthsToOneYearPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: '1 Year (and 1 Day) to 2 Years'
    })
    oneYearToTwoYears: string;
    @autoserializeAs(String)
    @Column({
        columnName: '1Y - 2Y %'
    })
    oneYearToTwoYearsPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: '2 Years (and 1 Day) and Beyond'
    })
    twoYearsAndBeyond: string;
    @autoserializeAs(String)
    @Column({
        columnName: '2Y+ %'
    })
    twoYearsAndBeyondPercent: string;
    @autoserializeAs(String)
    @Column({
        columnName: 'Total'
    })
    total: string;
}
