import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SyncGuard implements CanActivate {
  constructor(
    private router: Router
) {}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  
  if(environment.isDesktop) {
    return true;
  } 

  // not logged in so redirect to login page with the return url
  this.router.navigate(['/']);//, { queryParams: { returnUrl: state.url }});
  return false;
}
  
}
