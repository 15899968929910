import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';

export const toggleAnimations =
  trigger('toggleAnimations', [
    transition(':enter', [
      style({ width: '0px' }),
      animate(150, style({ width: '24px' }))
    ]),
    transition(':leave', [
      style({ width: '24px' }),
      animate(150, style({ width: '0px' }))
    ])
  ]);
export const routeAnimations =
  trigger('routeAnimations', [
    transition('* => *', [
      query(':enter', 
        [
          style({ opacity: 0 })
        ], 
        { optional: true }
      ),
      query(':leave', 
        [
          animateChild(), 
          style({ opacity: 1 }),
          animate(150, style({ opacity: 0 }))
        ], 
        { optional: true }
      ),
      query(':enter', 
        [
          style({ opacity: 0 }),
          animate(150, style({ opacity: 1 })),
          animateChild()
        ], 
        { optional: true }
      )
    ])
  ]);