import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { UserService } from '@app/user/user.service';
import { TokenService } from './token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private userService: UserService, private tokenService: TokenService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    const user = this.userService.userValue;
    const isLoggedIn = user && user.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isDesktop = environment.isDesktop;
    if (isApiUrl) {
       request = request.clone({
            setHeaders: {
                // Authorization: `Bearer ${localStorage.getItem('token')}`
                Authorization: `Bearer ${this.tokenService.token.getValue()}`
            }
        });
    } else {
      if(isDesktop){
        request = request.clone({
          setHeaders: {
              Authorization: `Bearer ${localStorage.getItem('originToken')}`
          }
        });
      }
    }
    return next.handle(request);
  }
}
