import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

export const MY_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'MM/DD/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const dateAdapterProvider = { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }

export const dateformatProvider = { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }