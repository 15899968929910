import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './shared/fake-backend';

import { AppRoutingModule } from './app-routing.module';
//import { JwtInterceptor} from './shared/jwt.interceptor';
import { TokenInterceptor} from './shared/token.interceptor';
import { ErrorInterceptor} from './shared/error.interceptor';
import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from './shared/alert/alert.component';
import { SelectorComponent } from './shared/selector/selector.component';
import { SharedModule } from './shared/shared.module';
import { APP_INITIALIZER } from '@angular/core';
import { EnnhsrService } from './shared/ennhsr.service';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { AdminModule } from './admin/admin.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { PatientModule } from './patient/patient.module';
import { SyncModule } from './sync/sync.module';
import { UnitModule } from './unit/unit.module';
import { UserModule } from './user/user.module';
import { MatChipsModule } from '@angular/material/chips';
import { PrintDirective } from './shared/print/print.directive';
import { dateAdapterProvider, dateformatProvider } from './shared/date-format';
import { DownloadLiteComponent } from './home/download-lite/download-lite.component';
import { TokenService } from './shared/token.service';
import { FeedbackModule } from './admin/feedback/feedback.module';
import { CustomErrorHandlerService } from './shared/custom-error-handler.service';

@NgModule({
    imports: [
        AppRoutingModule,
        SharedModule,
        
        AdminModule,
        DashboardModule,
        PatientModule,
        SyncModule,
        UnitModule,
        UserModule,
        FeedbackModule,

        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        
        NgbModule,
        
        /* MatChipsModule,
        MatAutocompleteModule,
        MatIconModule */
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        SelectorComponent,
        HomeComponent,
        PrintDirective,
        DownloadLiteComponent
    ],
    providers: [
       /*  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, */
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider,
        BnNgIdleService,
        {
            provide: APP_INITIALIZER,
            useFactory: tokenFactory,
            deps: [TokenService], 
            multi: true
        },
        dateAdapterProvider, dateformatProvider,
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };

export function tokenFactory(tokenService: TokenService) {
    return () => tokenService.getToken();
}