import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SealsService } from '@app/admin/seal/seals.service';
import { PatientService } from '@app/patient/patient.service';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { UserService } from '@app/user/user.service';
import { environment } from '@environments/environment';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SealSummaryService } from '@app/admin/seal/seal-summary.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  private modelName = "status";

  public recordsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public records: Observable<any> = this.recordsSubject.asObservable();

  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService,
    private patientService: PatientService,
    private sealsService: SealsService,
    private sealSummaryService: SealSummaryService
  ) { }

  public get recordValue() {
    return this.recordsSubject.value;
  }

  public get recordObservable() {
    return this.records;
  }

  add(item: any){
    var guid = Utils.generateGuid()
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = Date.now()
    const user = this.userService.userValue;
    var sync = Utils.convertToSync(item, this.modelName, guid, null, user.id);
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
      .pipe(
        mergeMap(res2 => {
          return this.getById(res2['insertedModelIds'][this.modelName][guid]['assignedId'])
          .pipe(
            map(newItem => {
              this.recordsSubject.next(newItem);
              return res2;
            })
          );
        })
      ).pipe(
        mergeMap(res => {
          return this.sealsService.getAllBySerialNumber2(item['serialNumber'].substring(1), item['serialNumber'].charAt(0)).pipe(
            mergeMap(res3=>{
              var itemSeal = cloneDeep(res3['results'][0]['dataModel']);
              itemSeal['patient'] = item["patient"];
              return this.sealsService.update(itemSeal, res3['results'][0]['modelId'], res3['results'][0]['versionId'], res3['results'][0])
                .pipe(
                  mergeMap(updatedSeal => {
                    const facilityId = JSON.parse(localStorage.getItem('user'))["facility"]["facilityModelId"];
                    const sealSummaryRecord = this.sealSummaryService.recordsValue['results'][0]
                    const usedIncrement =  ((Number(sealSummaryRecord['dataModel']['used']) || 0) + 1).toString();
                    return this.sealSummaryService.update({used: usedIncrement},sealSummaryRecord.modelId,  sealSummaryRecord.versionId,sealSummaryRecord);
                  })
                );
              /*  var sync2 = {
                fieldUpdates: [{
                  "dateUpdated": Date.now,
                  "fieldPath": "patient",
                  "modelId": modelId,
                  "modelName": "seal",
                  "value": patient
                }],
                "syncTokens": {
                  seal: {
                    [modelId] : versionId
                  }
                }
              }
              return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync2) */
            })
          )

          /* JSON.parse(localStorage.getItem('sealDistributionRecords')).results.forEach(element => {
            element["dataModel"]["seals"]["results"].forEach(s => {
              var bCode = new String(s["dataModel"]["batchCode"])
              var sNum = new String(s["dataModel"]["serialNumber"])
              var serial = bCode.concat(sNum.toString())
              if(serial === item["serialNumber"]) {
                seal = s["dataModel"]["serialNumber"]
                modelId = s["modelId"]
                versionId = s["versionId"]
                s["dataModel"]["patient"] = patient
              }
            });
          }); */
         /*  let setLS = JSON.parse(localStorage.getItem('sealDistributionRecords'))
          setLS.results.forEach(element2 => {
            element2["dataModel"]["seals"]["results"].forEach(s2 => {
              var bCode2 = new String(s2["dataModel"]["batchCode"])
              var sNum2 = new String(s2["dataModel"]["serialNumber"])
              var serial2 = bCode2.concat(sNum2.toString())
              if(serial2 === item["serialNumber"]) {
                s2["dataModel"]["patient"] = patient
              }
            });
          });
          localStorage.setItem('sealDistributionRecords', JSON.stringify(setLS)) */
         /*  var sync2 = {
            fieldUpdates: [{
              "dateUpdated": Date.now,
              "fieldPath": "patient",
              "modelId": modelId,
              "modelName": "seal",
              "value": patient
            }],
            "syncTokens": {
              seal: {
                [modelId] : versionId
              }
            }
          }
          return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync2) */
        })
      );
      
  }

  getAll(id: string){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?patient=${id}`)
    .pipe(
      map(res => {
        this.recordsSubject.next(res);
        return res;
      })
    );
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}`);
  }

  getByPatient(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}?patient=${id}`)
    .pipe(
      map(res => {
        this.recordsSubject.next(res);
        return res;
      })
    );
  }

  update(item: any, modelId: string, versionId: string) {
    var original = this.recordsSubject.value;
    item["meta"] = original["dataModel"]["meta"];
    item = Utils.generateMeta(item)
    item["meta.periodDate"] = Date.now()
    item["patient"] = original["dataModel"]["patient"];
    const user = this.userService.userValue;
    var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                              Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var record = this.recordsSubject.value;
        record.dataModel = item;
        record.versionId = res['updatedModelVersionIds'][this.modelName][modelId];
        record.updatedBy = JSON.parse(localStorage.getItem('user')).id
        record.updatedByName = JSON.parse(localStorage.getItem('user')).firstName + " " + JSON.parse(localStorage.getItem('user')).lastName
        //console.log(JSON.stringify(record))
        this.recordsSubject.next(record);
        return res;
      })
    );
  }

}