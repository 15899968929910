import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@app/shared/utils';
import { Sync } from '@app/shared/_models/sync';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { FieldUpdate } from '@app/shared/_models/field-update';
import { map, mergeMap } from 'rxjs/operators';
import { UserService } from '@app/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AssrTestService {

  private modelName = "assrTest";

  private recordsSubject: BehaviorSubject<any> = new BehaviorSubject({"count":0,"offset":null,"limit":null,"results":[]});;
  private records: Observable<any> = this.recordsSubject.asObservable();

  constructor(
    public router: Router,
    public http: HttpClient,
    private userService: UserService
  ) { }

  public get recordsValue() {
    return this.recordsSubject.value;
  }

  public get recordsObservable() {
    return this.records;
  }

  add(item: any, uploadFile?:File){
    if(uploadFile != null) {
      let headers = new HttpHeaders({
        'X-Random': Utils.generateGuid(),
        'X-Filename':  uploadFile.name,
        'Content-Type': uploadFile.type});
       return this.http.put(`${environment.apiUrl}/file/insert`, uploadFile, { headers: headers })
       .pipe(
         mergeMap(uploadRes => {
          var guid = Utils.generateGuid()
          item = Utils.generateMeta(item)
          item["meta.periodDate"] = item["dateOfSession"]
          item["uploadFile"] = uploadRes["refId"]
          const user = this.userService.userValue;
          var sync = Utils.convertToSync(item, this.modelName, guid, null, user.id);
          var fuidx;
          sync["fieldUpdates"].forEach((element, index) => {
            if(element["fieldPath"] === "uploadFile"){
              fuidx = index;
              let fieldUpdateRefId = { ...element };
              fieldUpdateRefId["indexIds"] = [];
              fieldUpdateRefId["indexIds"].push(uploadRes["refId"]);
              fieldUpdateRefId["value"] = uploadRes["refId"];
              fieldUpdateRefId["fieldPath"] = "uploadFile[].refId";
              sync["fieldUpdates"].push(fieldUpdateRefId);
              let fieldUpdatefileName = { ...element };
              fieldUpdatefileName["indexIds"] = [];
              fieldUpdatefileName["indexIds"].push(uploadRes["refId"]);
              fieldUpdatefileName["value"] = uploadFile.name;
              fieldUpdatefileName["fieldPath"] = "uploadFile[].fileName";
              sync["fieldUpdates"].push(fieldUpdatefileName);
              let fieldUpdatefileType = { ...element };
              fieldUpdatefileType["indexIds"] = [];
              fieldUpdatefileType["indexIds"].push(uploadRes["refId"]);
              fieldUpdatefileType["value"] = uploadFile.type;
              fieldUpdatefileType["fieldPath"] = "uploadFile[].fileType";
              sync["fieldUpdates"].push(fieldUpdatefileType);
              let fieldUpdatedateUploaded = { ...element };
              fieldUpdatedateUploaded["indexIds"] = [];
              fieldUpdatedateUploaded["indexIds"].push(uploadRes["refId"]);
              fieldUpdatedateUploaded["value"] = Date.now();
              fieldUpdatedateUploaded["fieldPath"] = "uploadFile[].dateUploaded";
              sync["fieldUpdates"].push(fieldUpdatedateUploaded);
            ;}
           });
           fuidx >= 0 ? sync["fieldUpdates"].splice(fuidx,1) : "";                        
           console.log(sync)
          return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
          .pipe(
            mergeMap(res => {
              return this.getById(res['insertedModelIds'][this.modelName][guid]['assignedId'])
              .pipe(
                map(newItem => {
                  this.recordsSubject.next(newItem);
                  return res;
                })
              );
            })
          );
         })
        )
    } else {
      var guid = Utils.generateGuid()
      item = Utils.generateMeta(item)
      item["meta.periodDate"] = item["dateOfTest"]
      const user = this.userService.userValue;
      var sync = Utils.convertToSync(item, this.modelName, guid, null, user.id);
      return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
      .pipe(
        mergeMap(res => {
          return this.getById(res['insertedModelIds'][this.modelName][guid]['assignedId'])
          .pipe(
            map(newItem => {
              var records = this.recordsSubject.value;
              records['results'].splice(0,0,newItem);
              this.recordsSubject.next(records);
              return res;
            })
          );
        })
      );
    }
  }

  getAll(id: string, page?){
    return this.http.get(`${environment.apiUrl}/dashboard/models/getAll/${this.modelName}?patient=${id}${page != null ? '&~page='+page : ''}`)
    .pipe(
      map(res => {
        this.recordsSubject.next(res);
        return res;
      })
    );
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/dashboard/models/get/${this.modelName}/${id}?~resolveRef=1`);
  }

  update(item: any, modelId: string, versionId: string, uploadFile?:File) {
    if(uploadFile != null) {
      let headers = new HttpHeaders({
        'X-Random': Utils.generateGuid(),
        'X-Filename':  uploadFile.name,
        'Content-Type': uploadFile.type});
       return this.http.put(`${environment.apiUrl}/file/insert`, uploadFile, { headers: headers })
       .pipe(
         mergeMap(uploadRes => {
           var original = this.recordsSubject.value;
           item["meta"] = original["dataModel"]["meta"];
           item = Utils.generateMeta(item)
           item["meta.periodDate"] = item["dateOfSession"]
           item["patient"] = original["dataModel"]["patient"];
           item["uploadFile"] = uploadRes["refId"]
           const user = this.userService.userValue;
           var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                                     Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
           var fuidx;
           sync["fieldUpdates"].forEach((element, index) => {
             if(element["fieldPath"] === "uploadFile"){
               fuidx = index;
               let fieldUpdateRefId = { ...element };
               fieldUpdateRefId["indexIds"] = [];
               fieldUpdateRefId["indexIds"].push(uploadRes["refId"]);
               fieldUpdateRefId["value"] = uploadRes["refId"];
               fieldUpdateRefId["fieldPath"] = "uploadFile[].refId";
               sync["fieldUpdates"].push(fieldUpdateRefId);
               let fieldUpdatefileName = { ...element };
               fieldUpdatefileName["indexIds"] = [];
               fieldUpdatefileName["indexIds"].push(uploadRes["refId"]);
               fieldUpdatefileName["value"] = uploadFile.name;
               fieldUpdatefileName["fieldPath"] = "uploadFile[].fileName";
               sync["fieldUpdates"].push(fieldUpdatefileName);
               let fieldUpdatefileType = { ...element };
               fieldUpdatefileType["indexIds"] = [];
               fieldUpdatefileType["indexIds"].push(uploadRes["refId"]);
               fieldUpdatefileType["value"] = uploadFile.type;
               fieldUpdatefileType["fieldPath"] = "uploadFile[].fileType";
               sync["fieldUpdates"].push(fieldUpdatefileType);
               let fieldUpdatedateUploaded = { ...element };
               fieldUpdatedateUploaded["indexIds"] = [];
               fieldUpdatedateUploaded["indexIds"].push(uploadRes["refId"]);
               fieldUpdatedateUploaded["value"] = Date.now();
               fieldUpdatedateUploaded["fieldPath"] = "uploadFile[].dateUploaded";
               sync["fieldUpdates"].push(fieldUpdatedateUploaded);
             ;}
            });
            fuidx >= 0 ? sync["fieldUpdates"].splice(fuidx,1) : "";                        
            return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
           .pipe(
             mergeMap(res => {
              return this.getById(modelId)
              .pipe(
                map(res2 => {
                  this.recordsSubject.next(res2);
                  return res2;
                }))
             })
           );
         })
       )
    } else {
      var original = this.recordsSubject.value['results'].find(x => x.modelId === modelId);
      item["meta"] = original["dataModel"]["meta"];
      item = Utils.generateMeta(item)
      item["meta.periodDate"] = item["dateOfTest"]
      item["patient"] = original["dataModel"]["patient"];
      const user = this.userService.userValue;
      var sync = Utils.getDiff(Utils.convertToSync(original['dataModel'], this.modelName, original['modelId'], original['versionId']), 
                                Utils.convertToSync(item, this.modelName, modelId, versionId, user.id));
      return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
      .pipe(
        map(res => {
          var records = this.recordsSubject.value;
          var idx = records["results"].findIndex(x => x.modelId === modelId);
          records["results"][idx].dataModel = item;
          records["results"][idx].versionId = res['updatedModelVersionIds'][this.modelName][modelId];
          records["results"][idx].updatedBy = JSON.parse(localStorage.getItem('user')).id
          records["results"][idx].updatedByName = JSON.parse(localStorage.getItem('user')).firstName + " " + JSON.parse(localStorage.getItem('user')).lastName
          this.recordsSubject.next(records);
          return res;
        })
      );
    }
  }

  delete(id){
    var model = this.recordsSubject.value['results'].find(x => x.modelId === id)
    let sync = new Sync();
    let field = new FieldUpdate();
    field.fieldPath = "meta.voided";
    field.value = true;
    field.modelName = this.modelName;
    field.modelId = id;
    field.dateUpdated = new Date();
    field.dateSynced = null;
    field.userId = JSON.parse(localStorage.getItem('user')).id;
    sync.fieldUpdates.push(field);
    let token = {};
    token[this.modelName] = {};
    token[this.modelName][id] = model.versionId;
    sync.syncTokens = token;
    return this.http.post<Sync>(`${environment.apiUrl}/sync/push`, sync)
    .pipe(
      map(res => {
        var records = this.recordsSubject.value;
        records['results'].splice(records['results'].findIndex(x => x.modelId === model.modelId),1);
        this.recordsSubject.next(records);
        return res;
      })
    );
  }
}